import Link from "next/link";

import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import RecipeImage from "@/components/recipe/image";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";

export default function BrandedRecipeListItemWide({
  recipe = {} as Recipe,
  priority = false,
}: {
  recipe: Recipe;
  priority: boolean;
  index?: number;
}) {
  const recipeEventObj = {
    action: TRACKING_EVENTS.recipe_tile_click,
    category: TRACKING_CATEGORIES.navigation,
    label: recipe.title,
  };

  return (
    <Link
      href={getRecipeLink(recipe)}
      onClick={() => {
        gtag.event(recipeEventObj);
      }}
      className="group flex gap-2"
    >
      <div className="w-48 flex-shrink-0">
        <RecipeImage
          priority={priority}
          recipe={recipe}
          size="small"
          className="h-32"
          noRounding
        />
      </div>
      <div className="flex flex-grow-0 flex-col gap-2">
        <div className="flex text-xs uppercase tracking-widest text-theme-secondary">
          {recipe.category?.name}
        </div>

        <div className="text-lg font-bold transition-colors duration-300 group-hover:text-theme-primary">
          <SymbolizedElement title={recipe.title} />
        </div>
      </div>
    </Link>
  );
}
