"use client";

import clsx from "clsx";

import DepthTracker from "shared-components/components/depthTracker";
import FullWidthElement from "shared-components/components/fullWidthElement";

import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import SectionHeader from "@/components/SectionHeader";
import Ad from "@/components/ad";
import HighlightBar from "@/components/brand/FussyKitchen/components/highlightBar";
import HugeRecipeCard from "@/components/brand/FussyKitchen/components/hugeRecipeCard";
import HugeWideRecipeCard from "@/components/brand/FussyKitchen/components/hugeWideRecipeCard";
import { USE_EZOIC } from "shared-lib/constants";

function BrandedSection({
  className = "",
  white = false,
  name = "",
}: {
  className?: string;
  white?: boolean;
  name?: string;
}) {
  return (
    <div className="pb-8">
      <SectionHeader
        name={name}
        className={clsx(
          className,
          white ? "text-white" : "text-theme-primary",
          "text-center font-serif text-2xl print:hidden",
        )}
      />
      <HighlightBar className="mt-2" />
    </div>
  );
}

export default function HeartLandCookingHome({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const allRecipes = [...mainRecipes, ...supplementalRecipes];
  const firstRecipeCardRecipe = allRecipes[0];
  const recipesOfTheWeek = allRecipes.slice(1, 5);
  const whatsNewRecipes = allRecipes.slice(5, 11);
  const trendingRecipes = allRecipes.slice(11, 15);
  const bigRecipe = allRecipes[15];
  const favoriteRecipes = allRecipes.slice(16, 24);

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[260px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
          ezoicId="140"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_MH_HP"
          className="mx-auto mb-8 max-h-[290px] min-h-[60px] min-w-[310px] max-w-[336px] md:hidden"
          ezoicId="140"
          screen="MOBILE"
        />

        <Section className="my-8">
          {firstRecipeCardRecipe && (
            <HugeRecipeCard priority recipe={firstRecipeCardRecipe} />
          )}
        </Section>

        {USE_EZOIC && (
          <Ad
            id="ezoic_108"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="141"
          />
        )}

        <Section className="mb-8">
          <BrandedSection name="Recipes of the Week" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-col-1 sm:grid-cols-2 md:grid-cols-4"
            recipes={recipesOfTheWeek}
            trackingName="home-page"
            cardCategoryColor="text-theme-primary"
            cardTitleColor="text-black group-hover:text-theme-primary"
          />
        </Section>

        <Ad
          id="Desktop_INR1_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
          ezoicId="142"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_INR1_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
          ezoicId="142"
          screen="MOBILE"
        />

        <Section className="mb-8 bg-theme-primary py-8">
          <BrandedSection white className="z-10" name="What's New" />
          <RevisedRecipeGrid
            pageType="/"
            round
            recipes={whatsNewRecipes}
            trackingName="home-page"
            cardTitleColor="text-white"
            cardCategoryColor="text-white"
          />
        </Section>

        {USE_EZOIC && (
          <Ad
            id="ezoic_143"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="143"
          />
        )}

        <Section className="mb-8">
          <BrandedSection name="Trending Recipes" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-col-1 sm:grid-cols-2 md:grid-cols-4"
            recipes={trendingRecipes}
            trackingName="home-page"
            cardCategoryColor="text-theme-primary"
            cardTitleColor="text-black group-hover:text-theme-primary"
          />
        </Section>

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
          ezoicId="144"
          screen="DESKTOP"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
          ezoicId="144"
          screen="MOBILE"
        />

        {bigRecipe && (
          <HugeWideRecipeCard recipe={bigRecipe} className="my-8" />
        )}

        {USE_EZOIC && (
          <Ad
            id="ezoic_145"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="145"
          />
        )}

        <Section>
          <div className="mx-4 border border-black lg:px-4">
            <BrandedSection name="Favorites" className="mt-4" />
            <RevisedRecipeGrid
              pageType="/"
              gridClasses="grid-cols-2 md:grid-cols-4"
              recipes={favoriteRecipes}
              trackingName="home-page"
              cardCategoryColor="text-theme-primary"
              cardTitleColor="text-black group-hover:text-theme-primary"
            />
          </div>
        </Section>

        {USE_EZOIC && (
          <Ad
            id="ezoic_146"
            className="mx-auto mb-8 max-h-[110px] !min-h-[60px] max-w-[970px]"
            useWithPartner="EZOIC"
            ezoicId="146"
          />
        )}

        <DepthTracker viewedItem="footer" className="hidden md:block" />
      </FullWidthElement>
    </div>
  );
}
