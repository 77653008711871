"use client";
import clsx from "clsx";

import DepthTracker from "shared-components/components/depthTracker";
import FullWidthElement from "shared-components/components/fullWidthElement";

import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import SquareRecipeCard from "@/components/SquareRecipeCard";
import Ad from "@/components/ad";
import BackgroundDots from "@/components/brand/MamasCooking/backgroundDots";
import HugeWideRecipeCard from "@/components/brand/MamasCooking/components/HugeWideRecipeCard";
import BrandedSection from "@/components/brand/MamasCooking/components/brandedSection";

export default function MamasCookingHome({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const allRecipes = [...mainRecipes, ...supplementalRecipes];
  const whatsNewRecipes = allRecipes.slice(5, 11);
  const recipesOfTheWeek = allRecipes.slice(11, 15);
  const bigRecipe = allRecipes[15];
  const trendingRecipes = allRecipes.slice(16, 24);

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_MH_HP"
          className="mx-auto mb-8 max-h-[290px] min-h-[60px] min-w-[310px] max-w-[336px] md:hidden"
        />
        <Section className="px-4 py-8 lg:px-0">
          {allRecipes.length >= 5 && (
            <div className="grid gap-8 sm:grid-cols-4">
              <div className="order-2 col-span-1 sm:order-1">
                <div className="grid gap-8">
                  <SquareRecipeCard
                    recipe={allRecipes[1]}
                    hoverBorder
                    hoverBorderColor="bg-theme-secondary"
                    className="border border-theme-secondary"
                    priority
                  />
                  <SquareRecipeCard
                    recipe={allRecipes[2]}
                    hoverBorder
                    hoverBorderColor="bg-theme-secondary"
                    className="border border-theme-secondary"
                    priority
                  />
                </div>
              </div>
              <div className="order-1 col-span-2 sm:order-2">
                <SquareRecipeCard
                  recipe={allRecipes[0]}
                  hoverBorder
                  hoverBorderColor="bg-theme-secondary"
                  className="border border-theme-secondary"
                  size="large"
                  showDescription
                />
              </div>
              <div className="order-3 col-span-1">
                <div className="grid gap-8">
                  <SquareRecipeCard
                    recipe={allRecipes[3]}
                    hoverBorder
                    hoverBorderColor="bg-theme-secondary"
                    className="border border-theme-secondary"
                  />
                  <SquareRecipeCard
                    recipe={allRecipes[4]}
                    hoverBorder
                    hoverBorderColor="bg-theme-secondary"
                    className="border border-theme-secondary"
                  />
                </div>
              </div>
            </div>
          )}
        </Section>

        <Section className="relative mb-8 bg-theme-secondary py-8">
          <BackgroundDots className="absolute inset-x-0 bottom-0 hidden md:block" />
          <BrandedSection black name="What's New" />
          <RevisedRecipeGrid
            pageType="/"
            round
            recipes={whatsNewRecipes}
            trackingName="home-page"
            cardTitleColor="text-black"
          />
        </Section>

        <Ad
          id="Desktop_INR1_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR1_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />
        <Section className="py-8">
          <BrandedSection name="Recipes of the Week" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            recipes={recipesOfTheWeek}
            cardHoverBorder
            trackingName="home-page"
            cardHoverBorderColor="bg-theme-secondary"
            cardClassName="border border-theme-secondary"
          />
        </Section>

        {bigRecipe && (
          <HugeWideRecipeCard recipe={bigRecipe} className="mb-8" />
        )}

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        <Section className="py-8">
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            recipes={trendingRecipes}
            cardHoverBorder
            trackingName="home-page"
            cardHoverBorderColor="bg-theme-secondary"
            cardClassName="border border-theme-secondary"
          />
        </Section>

        <DepthTracker viewedItem="footer" className="hidden md:block" />
      </FullWidthElement>
    </div>
  );
}
