"use client";
import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import { getRecipeLink } from "@/lib/helpers/frontendFunctions";
import { getPlaintextDescription } from "@/lib/helpers/recipeFunctions";

import RecipeThumb from "./RecipeThumb";

type Props = {
  recipe: Recipe;
  trackingLabel?: string;
  bgColor?: string;
  boxStyle?: string;
  categoryColor?: string;
  categoryFontFace?: string;
  titleColor?: string;
  titleFontFace?: string;
  className?: string;
  zoom?: boolean;
  alignment?: string;
  onClick?: () => void;
  imageSize?: string;
  size?: string;
  showDescription?: boolean;
  hoverBorder?: boolean;
  hoverBorderColor?: string;
  descriptionColor?: string;
  boxBg?: string;
  hideCategory?: boolean;
  useTag?: boolean;
  priority?: boolean;
};

export default function SquareRecipeCard({
  recipe,
  trackingLabel = "",
  bgColor = "bg-transparent",
  boxStyle = "bg-white",
  categoryColor = "text-theme-gray-300",
  categoryFontFace = "font-sans",
  titleColor = "text-theme-primary group-hover:text-theme-primary",
  titleFontFace = "font-serif",
  className = "",
  zoom = true,
  alignment = "text-left",
  imageSize = "",
  size = "regular",
  showDescription = false,
  hoverBorder = false,
  hoverBorderColor = "bg-theme-primary",
  descriptionColor = "text-black",
  hideCategory = false,
  boxBg = "",
  useTag = false,
  priority = false,
  onClick = () => {
    return;
  },
}: Props) {
  const passThrough: any = {};
  if (imageSize) {
    passThrough.size = imageSize;
  }
  return (
    <Link
      href={getRecipeLink(recipe)}
      className={clsx("group relative block", bgColor, className)}
      onClick={() => {
        gtag.event({
          action: TRACKING_EVENTS.recipe_tile_click,
          category: TRACKING_CATEGORIES.navigation,
          label: trackingLabel,
        });

        logStatsig(TRACKING_EVENTS.recipe_tile_click, {
          index: trackingLabel,
        });

        ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
          index: trackingLabel,
        });
        onClick();
      }}
    >
      {hoverBorder && (
        <div
          className={clsx(
            "absolute left-1 top-1 hidden h-full w-full group-hover:block",
            hoverBorderColor,
          )}
        />
      )}

      <RecipeThumb
        recipe={recipe}
        zoom={zoom}
        className="w-full"
        priority={priority}
        {...passThrough}
      />
      <div
        className={clsx("bottom-0 left-0 right-0 bg-white sm:absolute", boxBg)}
      >
        <div
          className={clsx(
            hideCategory ? "md:min-h-[70px]" : "md:min-h-[90px]",
            size === "large" ? "p-6" : "p-2",
            alignment,
            boxStyle,
          )}
        >
          {!hideCategory && (
            <div
              className={clsx(
                "text-sm font-medium uppercase",
                size === "large" ? "text-lg" : "text-base",
                categoryColor,
                categoryFontFace,
              )}
            >
              {useTag && recipe.tags && recipe.tags.items.length > 0
                ? recipe.tags.items[0].name
                : recipe.category?.name}
            </div>
          )}
          <div
            className={clsx(
              "line-clamp-3 font-bold md:line-clamp-2",
              "group-hover:underline",
              size === "large"
                ? "text-4xl max-md:min-h-28"
                : "text-xl max-md:min-h-20",
              titleFontFace,
              titleColor,
            )}
          >
            <SymbolizedElement title={recipe.title} />
          </div>
          {showDescription && (
            <p className={clsx("mt-3 line-clamp-2", descriptionColor)}>
              <SymbolizedElement title={getPlaintextDescription(recipe)} />
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}
