import clsx from "clsx";

import SectionHeader from "@/components/SectionHeader";

export default function BrandedSection({
  className = "",
  black = false,
  name,
}: {
  className?: string;
  black?: boolean;
  name: string;
}) {
  return (
    <div
      className={clsx(
        className,
        "mb-8 pb-[3px]",
        "border-b-[1px]",
        black ? "border-black" : "border-theme-secondary",
      )}
    >
      <SectionHeader
        name={name}
        className={clsx(
          "border-b-[1px]",
          black ? "border-black" : "border-theme-secondary",
        )}
      />
    </div>
  );
}
