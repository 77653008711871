import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import RecipeImage from "@/components/recipe/image";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";
import { getPlaintextDescription } from "@/lib/helpers/recipeFunctions";

export default function HugeWideRecipeCard({
  className = "",
  recipe = {} as Recipe,
  index = 0,
  priority = false,
  onClick = () => {
    return;
  },
}) {
  return (
    <Link
      href={getRecipeLink(recipe)}
      onClick={() => {
        onClick();

        gtag.event({
          action: TRACKING_EVENTS.recipe_tile_click,
          category: TRACKING_CATEGORIES.navigation,
          label: index.toString(),
        });

        logStatsig(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });

        ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });
      }}
      className={clsx(className, "group relative mx-auto flex flex-col")}
    >
      <div className="z-10 h-full w-full">
        <RecipeImage
          priority={priority}
          recipe={recipe}
          size="large"
          noRounding
          landscape
          noZoom
          noWash
        />
      </div>

      <div className="z-20 mx-4 -mt-32 flex border-[1px] border-theme-secondary bg-white p-4 md:absolute md:bottom-1/4 md:left-24 md:mt-0 md:max-w-lg">
        <div className="border-[1px] border-theme-secondary p-2 text-center">
          <div className="w-full uppercase text-theme-gray-300">
            {recipe.category?.name}
          </div>

          <div className="text-serif inline-block pt-1 font-serif text-5xl text-theme-primary group-hover:underline">
            <SymbolizedElement title={recipe.title} />
          </div>

          <p className="my-2 line-clamp-5 text-sm text-black md:line-clamp-3">
            {recipe.richDescription && getPlaintextDescription(recipe)}
          </p>

          <button className="my-1 cursor-pointer rounded-md bg-theme-primary px-6 py-2 text-sm font-bold text-white transition-opacity duration-200 hover:opacity-80">
            Go to Recipe
          </button>
        </div>
      </div>
    </Link>
  );
}
