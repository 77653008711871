import clsx from "clsx";

export default function BackgroundDots({ className = "" }) {
  return (
    <svg
      className={clsx(className, "fill-white opacity-40")}
      // width="1920"
      // height="437"
      viewBox="0 0 1920 437"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path d="M1915.82 426.723C1912.98 426.723 1910.69 429.02 1910.69 431.861C1910.69 434.702 1912.98 437 1915.82 437C1918.66 437 1920.96 434.702 1920.96 431.861C1920.93 429.02 1918.66 426.723 1915.82 426.723Z" />
        <path d="M1897.87 426.723C1895.03 426.723 1892.73 429.02 1892.73 431.861C1892.73 434.702 1895.03 437 1897.87 437C1900.71 437 1903.01 434.702 1903.01 431.861C1903.01 429.02 1900.71 426.723 1897.87 426.723Z" />
        <path d="M1879.94 426.723C1877.1 426.723 1874.81 429.02 1874.81 431.861C1874.81 434.702 1877.1 437 1879.94 437C1882.78 437 1885.08 434.702 1885.08 431.861C1885.06 429.02 1882.78 426.723 1879.94 426.723Z" />
        <path d="M1861.99 426.723C1859.15 426.723 1856.86 429.02 1856.86 431.861C1856.86 434.702 1859.15 437 1861.99 437C1864.83 437 1867.1 434.702 1867.1 431.861C1867.13 429.02 1864.83 426.723 1861.99 426.723Z" />
        <path d="M1844.06 426.723C1841.22 426.723 1838.93 429.02 1838.93 431.861C1838.93 434.702 1841.22 437 1844.06 437C1846.9 437 1849.17 434.702 1849.17 431.861C1849.17 429.02 1846.88 426.723 1844.06 426.723Z" />
        <path d="M1826.11 426.723C1823.27 426.723 1820.98 429.02 1820.98 431.861C1820.98 434.702 1823.27 437 1826.11 437C1828.95 437 1831.22 434.702 1831.22 431.861C1831.25 429.02 1828.95 426.723 1826.11 426.723Z" />
        <path d="M1808.19 426.723C1805.35 426.723 1803.05 429.02 1803.05 431.861C1803.05 434.702 1805.35 437 1808.19 437C1811.03 437 1813.3 434.702 1813.3 431.861C1813.3 429.02 1811 426.723 1808.19 426.723Z" />
        <path d="M1790.24 426.723C1787.4 426.723 1785.1 429.02 1785.1 431.861C1785.1 434.702 1787.4 437 1790.24 437C1793.08 437 1795.37 434.702 1795.37 431.861C1795.37 429.02 1793.08 426.723 1790.24 426.723Z" />
        <path d="M1772.31 426.723C1769.47 426.723 1767.17 429.02 1767.17 431.861C1767.17 434.702 1769.47 437 1772.31 437C1775.15 437 1777.42 434.702 1777.42 431.861C1777.42 429.02 1775.12 426.723 1772.31 426.723Z" />
        <path d="M1754.36 426.723C1751.52 426.723 1749.22 429.02 1749.22 431.861C1749.22 434.702 1751.52 437 1754.36 437C1757.2 437 1759.47 434.702 1759.47 431.861C1759.49 429.02 1757.2 426.723 1754.36 426.723Z" />
        <path d="M1736.43 426.723C1733.59 426.723 1731.29 429.02 1731.29 431.861C1731.29 434.702 1733.59 437 1736.43 437C1739.27 437 1741.54 434.702 1741.54 431.861C1741.54 429.02 1739.25 426.723 1736.43 426.723Z" />
        <path d="M1718.48 426.723C1715.64 426.723 1713.34 429.02 1713.34 431.861C1713.34 434.702 1715.64 437 1718.48 437C1721.32 437 1723.59 434.702 1723.59 431.861C1723.62 429.02 1721.32 426.723 1718.48 426.723Z" />
        <path d="M1700.55 426.723C1697.71 426.723 1695.42 429.02 1695.42 431.861C1695.42 434.702 1697.71 437 1700.55 437C1703.39 437 1705.66 434.702 1705.66 431.861C1705.66 429.02 1703.37 426.723 1700.55 426.723Z" />
        <path d="M1682.6 426.723C1679.76 426.723 1677.46 429.02 1677.46 431.861C1677.46 434.702 1679.76 437 1682.6 437C1685.44 437 1687.71 434.702 1687.71 431.861C1687.74 429.02 1685.44 426.723 1682.6 426.723Z" />
        <path d="M1664.67 426.723C1661.83 426.723 1659.54 429.02 1659.54 431.861C1659.54 434.702 1661.83 437 1664.67 437C1667.51 437 1669.81 434.702 1669.81 431.861C1669.78 429.02 1667.49 426.723 1664.67 426.723Z" />
        <path d="M1646.72 426.723C1643.88 426.723 1641.59 429.02 1641.59 431.861C1641.59 434.702 1643.88 437 1646.72 437C1649.56 437 1651.83 434.702 1651.83 431.861C1651.86 429.02 1649.56 426.723 1646.72 426.723Z" />
        <path d="M1628.8 426.723C1625.96 426.723 1623.66 429.02 1623.66 431.861C1623.66 434.702 1625.96 437 1628.8 437C1631.63 437 1633.91 434.702 1633.91 431.861C1633.91 429.02 1631.61 426.723 1628.8 426.723Z" />
        <path d="M1610.84 426.723C1608 426.723 1605.71 429.02 1605.71 431.861C1605.71 434.702 1608 437 1610.84 437C1613.68 437 1615.96 434.702 1615.96 431.861C1615.98 429.02 1613.68 426.723 1610.84 426.723Z" />
        <path d="M1592.92 426.723C1590.08 426.723 1587.78 429.02 1587.78 431.861C1587.78 434.702 1590.08 437 1592.92 437C1595.76 437 1598.03 434.702 1598.03 431.861C1598.03 429.02 1595.73 426.723 1592.92 426.723Z" />
        <path d="M1574.97 426.723C1572.13 426.723 1569.83 429.02 1569.83 431.861C1569.83 434.702 1572.13 437 1574.97 437C1577.81 437 1580.08 434.702 1580.08 431.861C1580.1 429.02 1577.81 426.723 1574.97 426.723Z" />
        <path d="M1557.04 426.723C1554.2 426.723 1551.9 429.02 1551.9 431.861C1551.9 434.702 1554.2 437 1557.04 437C1559.88 437 1562.15 434.702 1562.15 431.861C1562.15 429.02 1559.85 426.723 1557.04 426.723Z" />
        <path d="M1539.09 426.723C1536.25 426.723 1533.95 429.02 1533.95 431.861C1533.95 434.702 1536.25 437 1539.09 437C1541.93 437 1544.2 434.702 1544.2 431.861C1544.22 429.02 1541.93 426.723 1539.09 426.723Z" />
        <path d="M1521.16 426.723C1518.32 426.723 1516.02 429.02 1516.02 431.861C1516.02 434.702 1518.32 437 1521.16 437C1524 437 1526.27 434.702 1526.27 431.861C1526.27 429.02 1523.98 426.723 1521.16 426.723Z" />
        <path d="M1503.21 426.723C1500.37 426.723 1498.08 429.02 1498.08 431.861C1498.08 434.702 1500.37 437 1503.21 437C1506.05 437 1508.32 434.702 1508.32 431.861C1508.32 429.02 1506.05 426.723 1503.21 426.723Z" />
        <path d="M1485.26 426.723C1482.42 426.723 1480.12 429.02 1480.12 431.861C1480.12 434.702 1482.42 437 1485.26 437C1488.1 437 1490.39 434.702 1490.39 431.861C1490.39 429.02 1488.1 426.723 1485.26 426.723Z" />
        <path d="M1467.33 426.723C1464.49 426.723 1462.2 429.02 1462.2 431.861C1462.2 434.702 1464.49 437 1467.33 437C1470.17 437 1472.47 434.702 1472.47 431.861C1472.44 429.02 1470.17 426.723 1467.33 426.723Z" />
        <path d="M1449.38 426.723C1446.54 426.723 1444.24 429.02 1444.24 431.861C1444.24 434.702 1446.54 437 1449.38 437C1452.22 437 1454.52 434.702 1454.52 431.861C1454.52 429.02 1452.22 426.723 1449.38 426.723Z" />
        <path d="M1431.45 426.723C1428.61 426.723 1426.32 429.02 1426.32 431.861C1426.32 434.702 1428.61 437 1431.45 437C1434.29 437 1436.59 434.702 1436.59 431.861C1436.56 429.02 1434.29 426.723 1431.45 426.723Z" />
        <path d="M1413.5 426.723C1410.66 426.723 1408.37 429.02 1408.37 431.861C1408.37 434.702 1410.66 437 1413.5 437C1416.34 437 1418.64 434.702 1418.64 431.861C1418.64 429.02 1416.34 426.723 1413.5 426.723Z" />
        <path d="M1395.57 426.723C1392.73 426.723 1390.44 429.02 1390.44 431.861C1390.44 434.702 1392.73 437 1395.57 437C1398.41 437 1400.71 434.702 1400.71 431.861C1400.69 429.02 1398.39 426.723 1395.57 426.723Z" />
        <path d="M1377.62 426.723C1374.78 426.723 1372.49 429.02 1372.49 431.861C1372.49 434.702 1374.78 437 1377.62 437C1380.46 437 1382.76 434.702 1382.76 431.861C1382.76 429.02 1380.46 426.723 1377.62 426.723Z" />
        <path d="M1359.7 426.723C1356.86 426.723 1354.56 429.02 1354.56 431.861C1354.56 434.702 1356.86 437 1359.7 437C1362.54 437 1364.83 434.702 1364.83 431.861C1364.81 429.02 1362.51 426.723 1359.7 426.723Z" />
        <path d="M1341.74 426.723C1338.9 426.723 1336.61 429.02 1336.61 431.861C1336.61 434.702 1338.9 437 1341.74 437C1344.58 437 1346.88 434.702 1346.88 431.861C1346.88 429.02 1344.58 426.723 1341.74 426.723Z" />
        <path d="M1323.82 426.723C1320.98 426.723 1318.68 429.02 1318.68 431.861C1318.68 434.702 1320.98 437 1323.82 437C1326.66 437 1328.95 434.702 1328.95 431.861C1328.93 429.02 1326.63 426.723 1323.82 426.723Z" />
        <path d="M1305.87 426.723C1303.03 426.723 1300.75 429.02 1300.75 431.861C1300.75 434.702 1303.05 437 1305.87 437C1308.71 437 1311 434.702 1311 431.861C1311 429.02 1308.71 426.723 1305.87 426.723Z" />
        <path d="M1287.94 426.723C1285.1 426.723 1282.83 429.02 1282.83 431.861C1282.83 434.702 1285.12 437 1287.94 437C1290.78 437 1293.08 434.702 1293.08 431.861C1293.05 429.02 1290.75 426.723 1287.94 426.723Z" />
        <path d="M1269.99 426.723C1267.15 426.723 1264.88 429.02 1264.88 431.861C1264.88 434.702 1267.17 437 1269.99 437C1272.83 437 1275.12 434.702 1275.12 431.861C1275.12 429.02 1272.83 426.723 1269.99 426.723Z" />
        <path d="M1252.06 426.723C1249.22 426.723 1246.95 429.02 1246.95 431.861C1246.95 434.702 1249.24 437 1252.06 437C1254.9 437 1257.2 434.702 1257.2 431.861C1257.17 429.02 1254.87 426.723 1252.06 426.723Z" />
        <path d="M1234.11 426.723C1231.27 426.723 1229 429.02 1229 431.861C1229 434.702 1231.3 437 1234.11 437C1236.95 437 1239.25 434.702 1239.25 431.861C1239.25 429.02 1236.95 426.723 1234.11 426.723Z" />
        <path d="M1216.18 426.723C1213.34 426.723 1211.05 429.02 1211.05 431.861C1211.05 434.702 1213.34 437 1216.18 437C1219.02 437 1221.32 434.702 1221.32 431.861C1221.29 429.02 1219 426.723 1216.18 426.723Z" />
        <path d="M1198.23 426.723C1195.39 426.723 1193.1 429.02 1193.1 431.861C1193.1 434.702 1195.39 437 1198.23 437C1201.07 437 1203.37 434.702 1203.37 431.861C1203.37 429.02 1201.07 426.723 1198.23 426.723Z" />
        <path d="M1180.3 426.723C1177.46 426.723 1175.17 429.02 1175.17 431.861C1175.17 434.702 1177.46 437 1180.3 437C1183.14 437 1185.44 434.702 1185.44 431.861C1185.42 429.02 1183.12 426.723 1180.3 426.723Z" />
        <path d="M1162.35 426.723C1159.51 426.723 1157.22 429.02 1157.22 431.861C1157.22 434.702 1159.51 437 1162.35 437C1165.19 437 1167.49 434.702 1167.49 431.861C1167.49 429.02 1165.19 426.723 1162.35 426.723Z" />
        <path d="M1144.43 426.723C1141.59 426.723 1139.29 429.02 1139.29 431.861C1139.29 434.702 1141.59 437 1144.43 437C1147.27 437 1149.56 434.702 1149.56 431.861C1149.54 429.02 1147.24 426.723 1144.43 426.723Z" />
        <path d="M1126.47 426.723C1123.63 426.723 1121.34 429.02 1121.34 431.861C1121.34 434.702 1123.63 437 1126.47 437C1129.31 437 1131.61 434.702 1131.61 431.861C1131.61 429.02 1129.31 426.723 1126.47 426.723Z" />
        <path d="M1108.55 426.723C1105.71 426.723 1103.41 429.02 1103.41 431.861C1103.41 434.702 1105.71 437 1108.55 437C1111.39 437 1113.69 434.702 1113.69 431.861C1113.66 429.02 1111.36 426.723 1108.55 426.723Z" />
        <path d="M1090.6 426.723C1087.76 426.723 1085.46 429.02 1085.46 431.861C1085.46 434.702 1087.76 437 1090.6 437C1093.44 437 1095.73 434.702 1095.73 431.861C1095.73 429.02 1093.44 426.723 1090.6 426.723Z" />
        <path d="M1072.67 426.723C1069.83 426.723 1067.56 429.02 1067.56 431.861C1067.56 434.702 1069.86 437 1072.67 437C1075.51 437 1077.81 434.702 1077.81 431.861C1077.78 429.02 1075.49 426.723 1072.67 426.723Z" />
        <path d="M1054.72 426.723C1051.88 426.723 1049.61 429.02 1049.61 431.861C1049.61 434.702 1051.9 437 1054.72 437C1057.56 437 1059.85 434.702 1059.85 431.861C1059.83 429.02 1057.56 426.723 1054.72 426.723Z" />
        <path d="M1036.77 426.723C1033.93 426.723 1031.65 429.02 1031.65 431.861C1031.65 434.702 1033.95 437 1036.77 437C1039.58 437 1041.9 434.702 1041.9 431.861C1041.9 429.02 1039.61 426.723 1036.77 426.723Z" />
        <path d="M1018.84 426.723C1016 426.723 1013.73 429.02 1013.73 431.861C1013.73 434.702 1016.03 437 1018.84 437C1021.66 437 1023.98 434.702 1023.98 431.861C1023.95 429.02 1021.68 426.723 1018.84 426.723Z" />
        <path d="M1000.89 426.723C998.049 426.723 995.777 429.02 995.777 431.861C995.777 434.702 998.073 437 1000.89 437C1003.7 437 1006.02 434.702 1006.02 431.861C1006.02 429.02 1003.73 426.723 1000.89 426.723Z" />
        <path d="M982.963 426.723C980.124 426.723 977.852 429.02 977.852 431.861C977.852 434.702 980.148 437 982.963 437C985.778 437 988.099 434.702 988.099 431.861C988.075 429.02 985.803 426.723 982.963 426.723Z" />
        <path d="M965.011 426.723C962.171 426.723 959.875 429.02 959.875 431.861C959.875 434.702 962.171 437 965.011 437C967.85 437 970.147 434.702 970.147 431.861C970.147 429.02 967.85 426.723 965.011 426.723Z" />
        <path d="M1924.78 408.985C1922.07 408.985 1919.87 411.186 1919.87 413.902C1919.87 416.617 1922.07 418.818 1924.78 418.818C1927.5 418.818 1929.7 416.617 1929.7 413.902C1929.7 411.186 1927.5 408.985 1924.78 408.985Z" />
        <path d="M1906.86 408.985C1904.14 408.985 1901.94 411.186 1901.94 413.902C1901.94 416.617 1904.14 418.818 1906.86 418.818C1909.57 418.818 1911.77 416.617 1911.77 413.902C1911.77 411.186 1909.57 408.985 1906.86 408.985Z" />
        <path d="M1888.91 408.985C1886.19 408.985 1883.99 411.186 1883.99 413.902C1883.99 416.617 1886.19 418.818 1888.91 418.818C1891.62 418.818 1893.82 416.617 1893.82 413.902C1893.82 411.186 1891.62 408.985 1888.91 408.985Z" />
        <path d="M1870.98 408.985C1868.27 408.985 1866.07 411.186 1866.07 413.902C1866.07 416.617 1868.27 418.818 1870.98 418.818C1873.69 418.818 1875.89 416.617 1875.89 413.902C1875.89 411.186 1873.69 408.985 1870.98 408.985Z" />
        <path d="M1853.03 408.985C1850.32 408.985 1848.12 411.186 1848.12 413.902C1848.12 416.617 1850.32 418.818 1853.03 418.818C1855.74 418.818 1857.94 416.617 1857.94 413.902C1857.94 411.186 1855.74 408.985 1853.03 408.985Z" />
        <path d="M1835.1 408.985C1832.39 408.985 1830.19 411.186 1830.19 413.902C1830.19 416.617 1832.39 418.818 1835.1 418.818C1837.81 418.818 1840.01 416.617 1840.01 413.902C1840.01 411.186 1837.81 408.985 1835.1 408.985Z" />
        <path d="M1817.15 408.985C1814.44 408.985 1812.24 411.186 1812.24 413.902C1812.24 416.617 1814.44 418.818 1817.15 418.818C1819.86 418.818 1822.06 416.617 1822.06 413.902C1822.06 411.186 1819.86 408.985 1817.15 408.985Z" />
        <path d="M1799.22 408.985C1796.51 408.985 1794.31 411.186 1794.31 413.902C1794.31 416.617 1796.51 418.818 1799.22 418.818C1801.94 418.818 1804.14 416.617 1804.14 413.902C1804.14 411.186 1801.94 408.985 1799.22 408.985Z" />
        <path d="M1781.27 408.985C1778.56 408.985 1776.36 411.186 1776.36 413.902C1776.36 416.617 1778.56 418.818 1781.27 418.818C1783.99 418.818 1786.19 416.617 1786.19 413.902C1786.19 411.186 1783.99 408.985 1781.27 408.985Z" />
        <path d="M1763.35 408.985C1760.63 408.985 1758.43 411.186 1758.43 413.902C1758.43 416.617 1760.63 418.818 1763.35 418.818C1766.06 418.818 1768.26 416.617 1768.26 413.902C1768.26 411.186 1766.06 408.985 1763.35 408.985Z" />
        <path d="M1745.39 408.985C1742.68 408.985 1740.48 411.186 1740.48 413.902C1740.48 416.617 1742.68 418.818 1745.39 418.818C1748.11 418.818 1750.31 416.617 1750.31 413.902C1750.31 411.186 1748.11 408.985 1745.39 408.985Z" />
        <path d="M1727.47 408.985C1724.75 408.985 1722.55 411.186 1722.55 413.902C1722.55 416.617 1724.75 418.818 1727.47 418.818C1730.18 418.818 1732.38 416.617 1732.38 413.902C1732.38 411.186 1730.18 408.985 1727.47 408.985Z" />
        <path d="M1709.51 408.985C1706.8 408.985 1704.6 411.184 1704.6 413.902C1704.6 416.619 1706.8 418.818 1709.51 418.818C1712.23 418.818 1714.43 416.619 1714.43 413.902C1714.43 411.184 1712.23 408.985 1709.51 408.985Z" />
        <path d="M1691.56 408.985C1688.85 408.985 1686.65 411.184 1686.65 413.902C1686.65 416.619 1688.85 418.818 1691.56 418.818C1694.28 418.818 1696.48 416.619 1696.48 413.902C1696.48 411.184 1694.3 408.985 1691.56 408.985Z" />
        <path d="M1673.64 408.985C1670.92 408.985 1668.72 411.184 1668.72 413.902C1668.72 416.619 1670.92 418.818 1673.64 418.818C1676.35 418.818 1678.55 416.619 1678.55 413.902C1678.55 411.184 1676.35 408.985 1673.64 408.985Z" />
        <path d="M1655.68 408.985C1652.97 408.985 1650.77 411.184 1650.77 413.902C1650.77 416.619 1652.97 418.818 1655.68 418.818C1658.4 418.818 1660.6 416.619 1660.6 413.902C1660.6 411.184 1658.43 408.985 1655.68 408.985Z" />
        <path d="M1637.76 408.985C1635.04 408.985 1632.85 411.184 1632.85 413.902C1632.85 416.619 1635.04 418.818 1637.76 418.818C1640.48 418.818 1642.67 416.619 1642.67 413.902C1642.67 411.184 1640.48 408.985 1637.76 408.985Z" />
        <path d="M1619.81 408.985C1617.09 408.985 1614.89 411.184 1614.89 413.902C1614.89 416.619 1617.09 418.818 1619.81 418.818C1622.52 418.818 1624.72 416.619 1624.72 413.902C1624.72 411.184 1622.55 408.985 1619.81 408.985Z" />
        <path d="M1601.88 408.985C1599.17 408.985 1596.97 411.184 1596.97 413.902C1596.97 416.619 1599.17 418.818 1601.88 418.818C1604.6 418.818 1606.8 416.619 1606.8 413.902C1606.8 411.184 1604.6 408.985 1601.88 408.985Z" />
        <path d="M1583.93 408.985C1581.21 408.985 1579.02 411.184 1579.02 413.902C1579.02 416.619 1581.21 418.818 1583.93 418.818C1586.65 418.818 1588.84 416.619 1588.84 413.902C1588.84 411.184 1586.65 408.985 1583.93 408.985Z" />
        <path d="M1566 408.985C1563.29 408.985 1561.09 411.184 1561.09 413.902C1561.09 416.619 1563.29 418.818 1566 418.818C1568.72 418.818 1570.92 416.619 1570.92 413.902C1570.92 411.184 1568.72 408.985 1566 408.985Z" />
        <path d="M1548.05 408.985C1545.34 408.985 1543.14 411.184 1543.14 413.902C1543.14 416.619 1545.34 418.818 1548.05 418.818C1550.77 418.818 1552.97 416.619 1552.97 413.902C1552.97 411.184 1550.77 408.985 1548.05 408.985Z" />
        <path d="M1530.12 408.985C1527.41 408.985 1525.21 411.184 1525.21 413.902C1525.21 416.619 1527.41 418.818 1530.12 418.818C1532.84 418.818 1535.04 416.619 1535.04 413.902C1535.04 411.184 1532.84 408.985 1530.12 408.985Z" />
        <path d="M1512.17 408.985C1509.46 408.985 1507.26 411.184 1507.26 413.902C1507.26 416.619 1509.46 418.818 1512.17 418.818C1514.89 418.818 1517.09 416.619 1517.09 413.902C1517.09 411.184 1514.89 408.985 1512.17 408.985Z" />
        <path d="M1494.25 408.985C1491.53 408.985 1489.33 411.184 1489.33 413.902C1489.33 416.619 1491.53 418.818 1494.25 418.818C1496.96 418.818 1499.16 416.619 1499.16 413.902C1499.16 411.184 1496.96 408.985 1494.25 408.985Z" />
        <path d="M1476.29 408.985C1473.58 408.985 1471.38 411.184 1471.38 413.902C1471.38 416.619 1473.58 418.818 1476.29 418.818C1479.01 418.818 1481.21 416.619 1481.21 413.902C1481.21 411.184 1479.01 408.985 1476.29 408.985Z" />
        <path d="M1458.37 408.985C1455.65 408.985 1453.45 411.184 1453.45 413.902C1453.45 416.619 1455.65 418.818 1458.37 418.818C1461.08 418.818 1463.28 416.619 1463.28 413.902C1463.28 411.184 1461.08 408.985 1458.37 408.985Z" />
        <path d="M1440.42 408.985C1437.7 408.985 1435.5 411.184 1435.5 413.902C1435.5 416.619 1437.7 418.818 1440.42 418.818C1443.13 418.818 1445.33 416.619 1445.33 413.902C1445.33 411.184 1443.13 408.985 1440.42 408.985Z" />
        <path d="M1422.49 408.985C1419.77 408.985 1417.58 411.184 1417.58 413.902C1417.58 416.619 1419.77 418.818 1422.49 418.818C1425.21 418.818 1427.4 416.619 1427.4 413.902C1427.4 411.184 1425.21 408.985 1422.49 408.985Z" />
        <path d="M1404.54 408.985C1401.82 408.985 1399.62 411.184 1399.62 413.902C1399.62 416.619 1401.82 418.818 1404.54 418.818C1407.25 418.818 1409.45 416.619 1409.45 413.902C1409.45 411.184 1407.25 408.985 1404.54 408.985Z" />
        <path d="M1386.61 408.985C1383.9 408.985 1381.7 411.184 1381.7 413.902C1381.7 416.619 1383.9 418.818 1386.61 418.818C1389.33 418.818 1391.53 416.619 1391.53 413.902C1391.53 411.184 1389.33 408.985 1386.61 408.985Z" />
        <path d="M1368.66 408.985C1365.94 408.985 1363.75 411.184 1363.75 413.902C1363.75 416.619 1365.94 418.818 1368.66 418.818C1371.38 418.818 1373.57 416.619 1373.57 413.902C1373.57 411.184 1371.38 408.985 1368.66 408.985Z" />
        <path d="M1350.73 408.985C1348.02 408.985 1345.82 411.184 1345.82 413.902C1345.82 416.619 1348.02 418.818 1350.73 418.818C1353.45 418.818 1355.65 416.619 1355.65 413.902C1355.65 411.184 1353.45 408.985 1350.73 408.985Z" />
        <path d="M1332.78 408.985C1330.07 408.985 1327.87 411.184 1327.87 413.902C1327.87 416.619 1330.07 418.818 1332.78 418.818C1335.5 418.818 1337.7 416.619 1337.7 413.902C1337.7 411.184 1335.5 408.985 1332.78 408.985Z" />
        <path d="M1314.85 408.985C1312.14 408.985 1309.94 411.184 1309.94 413.902C1309.94 416.619 1312.14 418.818 1314.85 418.818C1317.57 418.818 1319.77 416.619 1319.77 413.902C1319.77 411.184 1317.57 408.985 1314.85 408.985Z" />
        <path d="M1296.9 408.985C1294.19 408.985 1291.99 411.184 1291.99 413.902C1291.99 416.619 1294.19 418.818 1296.9 418.818C1299.62 418.818 1301.82 416.619 1301.82 413.902C1301.82 411.184 1299.62 408.985 1296.9 408.985Z" />
        <path d="M1278.95 408.985C1276.24 408.985 1274.04 411.184 1274.04 413.902C1274.04 416.619 1276.24 418.818 1278.95 418.818C1281.67 418.818 1283.86 416.619 1283.86 413.902C1283.86 411.184 1281.69 408.985 1278.95 408.985Z" />
        <path d="M1261.02 408.985C1258.31 408.985 1256.11 411.184 1256.11 413.902C1256.11 416.619 1258.31 418.818 1261.02 418.818C1263.74 418.818 1265.94 416.619 1265.94 413.902C1265.94 411.184 1263.74 408.985 1261.02 408.985Z" />
        <path d="M1243.07 408.985C1240.36 408.985 1238.16 411.184 1238.16 413.902C1238.16 416.619 1240.36 418.818 1243.07 418.818C1245.79 418.818 1247.99 416.619 1247.99 413.902C1247.99 411.184 1245.81 408.985 1243.07 408.985Z" />
        <path d="M1225.15 408.985C1222.43 408.985 1220.23 411.184 1220.23 413.902C1220.23 416.619 1222.43 418.818 1225.15 418.818C1227.86 418.818 1230.06 416.619 1230.06 413.902C1230.06 411.184 1227.86 408.985 1225.15 408.985Z" />
        <path d="M1207.2 408.985C1204.48 408.985 1202.28 411.184 1202.28 413.902C1202.28 416.619 1204.48 418.818 1207.2 418.818C1209.91 418.818 1212.11 416.619 1212.11 413.902C1212.11 411.184 1209.94 408.985 1207.2 408.985Z" />
        <path d="M1189.27 408.985C1186.55 408.985 1184.35 411.184 1184.35 413.902C1184.35 416.619 1186.55 418.818 1189.27 418.818C1191.98 418.818 1194.18 416.619 1194.18 413.902C1194.18 411.184 1191.98 408.985 1189.27 408.985Z" />
        <path d="M1171.32 408.985C1168.6 408.985 1166.4 411.184 1166.4 413.902C1166.4 416.619 1168.6 418.818 1171.32 418.818C1174.03 418.818 1176.23 416.619 1176.23 413.902C1176.23 411.184 1174.06 408.985 1171.32 408.985Z" />
        <path d="M1153.39 408.985C1150.67 408.985 1148.48 411.184 1148.48 413.902C1148.48 416.619 1150.67 418.818 1153.39 418.818C1156.11 418.818 1158.3 416.619 1158.3 413.902C1158.3 411.184 1156.11 408.985 1153.39 408.985Z" />
        <path d="M1135.44 408.985C1132.72 408.985 1130.52 411.184 1130.52 413.902C1130.52 416.619 1132.72 418.818 1135.44 418.818C1138.15 418.818 1140.35 416.619 1140.35 413.902C1140.35 411.184 1138.15 408.985 1135.44 408.985Z" />
        <path d="M1117.51 408.985C1114.8 408.985 1112.6 411.186 1112.6 413.902C1112.6 416.617 1114.8 418.818 1117.51 418.818C1120.23 418.818 1122.43 416.617 1122.43 413.902C1122.43 411.186 1120.23 408.985 1117.51 408.985Z" />
        <path d="M1099.56 408.985C1096.85 408.985 1094.65 411.186 1094.65 413.902C1094.65 416.617 1096.85 418.818 1099.56 418.818C1102.27 418.818 1104.47 416.617 1104.47 413.902C1104.47 411.186 1102.27 408.985 1099.56 408.985Z" />
        <path d="M1081.63 408.985C1078.92 408.985 1076.72 411.186 1076.72 413.902C1076.72 416.617 1078.92 418.818 1081.63 418.818C1084.35 418.818 1086.55 416.617 1086.55 413.902C1086.55 411.186 1084.35 408.985 1081.63 408.985Z" />
        <path d="M1063.68 408.985C1060.97 408.985 1058.77 411.186 1058.77 413.902C1058.77 416.617 1060.97 418.818 1063.68 418.818C1066.4 418.818 1068.6 416.617 1068.6 413.902C1068.6 411.186 1066.4 408.985 1063.68 408.985Z" />
        <path d="M1045.76 408.985C1043.04 408.985 1040.84 411.186 1040.84 413.902C1040.84 416.617 1043.04 418.818 1045.76 418.818C1048.47 418.818 1050.67 416.617 1050.67 413.902C1050.67 411.186 1048.47 408.985 1045.76 408.985Z" />
        <path d="M1027.8 408.985C1025.09 408.985 1022.89 411.186 1022.89 413.902C1022.89 416.617 1025.09 418.818 1027.8 418.818C1030.52 418.818 1032.72 416.617 1032.72 413.902C1032.72 411.186 1030.52 408.985 1027.8 408.985Z" />
        <path d="M1009.88 408.985C1007.16 408.985 1004.96 411.186 1004.96 413.902C1004.96 416.617 1007.16 418.818 1009.88 418.818C1012.59 418.818 1014.79 416.617 1014.79 413.902C1014.79 411.186 1012.59 408.985 1009.88 408.985Z" />
        <path d="M991.926 408.985C989.212 408.985 987.012 411.186 987.012 413.902C987.012 416.617 989.212 418.818 991.926 418.818C994.64 418.818 996.84 416.617 996.84 413.902C996.84 411.186 994.64 408.985 991.926 408.985Z" />
        <path d="M973.998 408.985C971.284 408.985 969.084 411.186 969.084 413.902C969.084 416.617 971.284 418.818 973.998 418.818C976.712 418.818 978.912 416.617 978.912 413.902C978.912 411.186 976.712 408.985 973.998 408.985Z" />
        <path d="M1915.82 391.049C1913.11 391.049 1910.91 393.25 1910.91 395.965C1910.91 398.681 1913.11 400.882 1915.82 400.882C1918.54 400.882 1920.74 398.681 1920.74 395.965C1920.74 393.25 1918.54 391.049 1915.82 391.049Z" />
        <path d="M1897.87 391.049C1895.16 391.049 1892.96 393.25 1892.96 395.965C1892.96 398.68 1895.16 400.882 1897.87 400.882C1900.58 400.882 1902.78 398.681 1902.78 395.965C1902.78 393.25 1900.58 391.049 1897.87 391.049Z" />
        <path d="M1879.94 391.049C1877.23 391.049 1875.03 393.25 1875.03 395.965C1875.03 398.68 1877.23 400.882 1879.94 400.882C1882.66 400.882 1884.86 398.681 1884.86 395.965C1884.86 393.25 1882.66 391.049 1879.94 391.049Z" />
        <path d="M1861.99 391.049C1859.28 391.049 1857.08 393.25 1857.08 395.965C1857.08 398.68 1859.28 400.882 1861.99 400.882C1864.71 400.882 1866.91 398.681 1866.91 395.965C1866.91 393.25 1864.71 391.049 1861.99 391.049Z" />
        <path d="M1844.06 391.049C1841.35 391.049 1839.15 393.25 1839.15 395.965C1839.15 398.68 1841.35 400.882 1844.06 400.882C1846.78 400.882 1848.98 398.681 1848.98 395.965C1848.98 393.25 1846.78 391.049 1844.06 391.049Z" />
        <path d="M1826.11 391.049C1823.4 391.049 1821.2 393.25 1821.2 395.965C1821.2 398.68 1823.4 400.882 1826.11 400.882C1828.83 400.882 1831.03 398.681 1831.03 395.965C1831.03 393.25 1828.83 391.049 1826.11 391.049Z" />
        <path d="M1808.19 391.049C1805.47 391.049 1803.27 393.25 1803.27 395.965C1803.27 398.68 1805.47 400.882 1808.19 400.882C1810.9 400.882 1813.1 398.681 1813.1 395.965C1813.1 393.25 1810.9 391.049 1808.19 391.049Z" />
        <path d="M1790.23 391.049C1787.52 391.049 1785.32 393.25 1785.32 395.965C1785.32 398.68 1787.52 400.882 1790.23 400.882C1792.95 400.882 1795.15 398.681 1795.15 395.965C1795.15 393.25 1792.95 391.049 1790.23 391.049Z" />
        <path d="M1772.31 391.049C1769.59 391.049 1767.39 393.25 1767.39 395.965C1767.39 398.68 1769.59 400.882 1772.31 400.882C1775.02 400.882 1777.22 398.681 1777.22 395.965C1777.22 393.25 1775.02 391.049 1772.31 391.049Z" />
        <path d="M1754.36 391.049C1751.64 391.049 1749.44 393.25 1749.44 395.965C1749.44 398.68 1751.64 400.882 1754.36 400.882C1757.07 400.882 1759.27 398.681 1759.27 395.965C1759.27 393.25 1757.07 391.049 1754.36 391.049Z" />
        <path d="M1736.43 391.049C1733.72 391.049 1731.52 393.25 1731.52 395.965C1731.52 398.68 1733.72 400.882 1736.43 400.882C1739.14 400.882 1741.34 398.681 1741.34 395.965C1741.34 393.25 1739.14 391.049 1736.43 391.049Z" />
        <path d="M1718.48 391.049C1715.77 391.049 1713.57 393.25 1713.57 395.965C1713.57 398.68 1715.77 400.882 1718.48 400.882C1721.19 400.882 1723.39 398.681 1723.39 395.965C1723.39 393.25 1721.19 391.049 1718.48 391.049Z" />
        <path d="M1700.55 391.024C1697.84 391.024 1695.64 393.223 1695.64 395.941C1695.64 398.658 1697.84 400.857 1700.55 400.857C1703.27 400.857 1705.47 398.658 1705.47 395.941C1705.47 393.223 1703.27 391.024 1700.55 391.024Z" />
        <path d="M1682.6 391.024C1679.88 391.024 1677.69 393.223 1677.69 395.941C1677.69 398.658 1679.88 400.857 1682.6 400.857C1685.32 400.857 1687.51 398.658 1687.51 395.941C1687.51 393.223 1685.32 391.024 1682.6 391.024Z" />
        <path d="M1664.67 391.024C1661.96 391.024 1659.76 393.223 1659.76 395.941C1659.76 398.658 1661.96 400.857 1664.67 400.857C1667.39 400.857 1669.59 398.658 1669.59 395.941C1669.59 393.223 1667.39 391.024 1664.67 391.024Z" />
        <path d="M1646.72 391.024C1644.01 391.024 1641.81 393.223 1641.81 395.941C1641.81 398.658 1644.01 400.857 1646.72 400.857C1649.44 400.857 1651.64 398.658 1651.64 395.941C1651.64 393.223 1649.44 391.024 1646.72 391.024Z" />
        <path d="M1628.8 391.024C1626.08 391.024 1623.88 393.223 1623.88 395.941C1623.88 398.658 1626.08 400.857 1628.8 400.857C1631.51 400.857 1633.71 398.658 1633.71 395.941C1633.71 393.223 1631.51 391.024 1628.8 391.024Z" />
        <path d="M1610.84 391.024C1608.13 391.024 1605.93 393.223 1605.93 395.941C1605.93 398.658 1608.13 400.857 1610.84 400.857C1613.56 400.857 1615.76 398.658 1615.76 395.941C1615.76 393.223 1613.56 391.024 1610.84 391.024Z" />
        <path d="M1592.92 391.024C1590.2 391.024 1588 393.223 1588 395.941C1588 398.658 1590.2 400.857 1592.92 400.857C1595.63 400.857 1597.83 398.658 1597.83 395.941C1597.83 393.223 1595.63 391.024 1592.92 391.024Z" />
        <path d="M1574.97 391.024C1572.25 391.024 1570.05 393.223 1570.05 395.941C1570.05 398.658 1572.25 400.857 1574.97 400.857C1577.68 400.857 1579.88 398.658 1579.88 395.941C1579.88 393.223 1577.68 391.024 1574.97 391.024Z" />
        <path d="M1557.04 391.024C1554.32 391.024 1552.12 393.223 1552.12 395.941C1552.12 398.658 1554.32 400.857 1557.04 400.857C1559.75 400.857 1561.95 398.658 1561.95 395.941C1561.95 393.223 1559.75 391.024 1557.04 391.024Z" />
        <path d="M1539.09 391.024C1536.37 391.024 1534.18 393.223 1534.18 395.941C1534.18 398.658 1536.37 400.857 1539.09 400.857C1541.81 400.857 1544 398.658 1544 395.941C1544 393.223 1541.81 391.024 1539.09 391.024Z" />
        <path d="M1521.16 391.024C1518.44 391.024 1516.25 393.223 1516.25 395.941C1516.25 398.658 1518.44 400.857 1521.16 400.857C1523.88 400.857 1526.07 398.658 1526.07 395.941C1526.07 393.223 1523.88 391.024 1521.16 391.024Z" />
        <path d="M1503.21 391.024C1500.49 391.024 1498.3 393.223 1498.3 395.941C1498.3 398.658 1500.49 400.857 1503.21 400.857C1505.93 400.857 1508.12 398.658 1508.12 395.941C1508.12 393.223 1505.93 391.024 1503.21 391.024Z" />
        <path d="M1485.26 391.024C1482.54 391.024 1480.34 393.223 1480.34 395.941C1480.34 398.658 1482.54 400.857 1485.26 400.857C1487.97 400.857 1490.17 398.658 1490.17 395.941C1490.17 393.223 1488 391.024 1485.26 391.024Z" />
        <path d="M1467.33 391.024C1464.61 391.024 1462.42 393.223 1462.42 395.941C1462.42 398.658 1464.61 400.857 1467.33 400.857C1470.05 400.857 1472.24 398.658 1472.24 395.941C1472.24 393.223 1470.05 391.024 1467.33 391.024Z" />
        <path d="M1449.38 391.024C1446.66 391.024 1444.47 393.223 1444.47 395.941C1444.47 398.658 1446.66 400.857 1449.38 400.857C1452.1 400.857 1454.29 398.658 1454.29 395.941C1454.29 393.223 1452.12 391.024 1449.38 391.024Z" />
        <path d="M1431.45 391.024C1428.74 391.024 1426.54 393.223 1426.54 395.941C1426.54 398.658 1428.74 400.857 1431.45 400.857C1434.17 400.857 1436.37 398.658 1436.37 395.941C1436.37 393.223 1434.17 391.024 1431.45 391.024Z" />
        <path d="M1413.5 391.024C1410.79 391.024 1408.59 393.223 1408.59 395.941C1408.59 398.658 1410.79 400.857 1413.5 400.857C1416.22 400.857 1418.42 398.658 1418.42 395.941C1418.42 393.223 1416.24 391.024 1413.5 391.024Z" />
        <path d="M1395.57 391.024C1392.86 391.024 1390.66 393.223 1390.66 395.941C1390.66 398.658 1392.86 400.857 1395.57 400.857C1398.29 400.857 1400.49 398.658 1400.49 395.941C1400.49 393.223 1398.29 391.024 1395.57 391.024Z" />
        <path d="M1377.62 391.024C1374.91 391.024 1372.71 393.223 1372.71 395.941C1372.71 398.658 1374.91 400.857 1377.62 400.857C1380.34 400.857 1382.54 398.658 1382.54 395.941C1382.54 393.223 1380.36 391.024 1377.62 391.024Z" />
        <path d="M1359.7 391.024C1356.98 391.024 1354.78 393.223 1354.78 395.941C1354.78 398.658 1356.98 400.857 1359.7 400.857C1362.41 400.857 1364.61 398.658 1364.61 395.941C1364.61 393.223 1362.41 391.024 1359.7 391.024Z" />
        <path d="M1341.74 391.024C1339.03 391.024 1336.83 393.223 1336.83 395.941C1336.83 398.658 1339.03 400.857 1341.74 400.857C1344.46 400.857 1346.66 398.658 1346.66 395.941C1346.66 393.223 1344.46 391.024 1341.74 391.024Z" />
        <path d="M1323.82 391.024C1321.1 391.024 1318.91 393.223 1318.91 395.941C1318.91 398.658 1321.1 400.857 1323.82 400.857C1326.54 400.857 1328.73 398.658 1328.73 395.941C1328.73 393.223 1326.54 391.024 1323.82 391.024Z" />
        <path d="M1305.87 391.024C1303.15 391.024 1300.95 393.223 1300.95 395.941C1300.95 398.658 1303.15 400.857 1305.87 400.857C1308.58 400.857 1310.78 398.658 1310.78 395.941C1310.78 393.223 1308.58 391.024 1305.87 391.024Z" />
        <path d="M1287.94 391.024C1285.22 391.024 1283.03 393.223 1283.03 395.941C1283.03 398.658 1285.22 400.857 1287.94 400.857C1290.66 400.857 1292.85 398.658 1292.85 395.941C1292.85 393.223 1290.66 391.024 1287.94 391.024Z" />
        <path d="M1269.99 391.024C1267.27 391.024 1265.08 393.223 1265.08 395.941C1265.08 398.658 1267.27 400.857 1269.99 400.857C1272.7 400.857 1274.9 398.658 1274.9 395.941C1274.9 393.223 1272.7 391.024 1269.99 391.024Z" />
        <path d="M1252.06 391.024C1249.35 391.024 1247.15 393.223 1247.15 395.941C1247.15 398.658 1249.35 400.857 1252.06 400.857C1254.78 400.857 1256.98 398.658 1256.98 395.941C1256.98 393.223 1254.78 391.024 1252.06 391.024Z" />
        <path d="M1234.11 391.024C1231.39 391.024 1229.2 393.223 1229.2 395.941C1229.2 398.658 1231.39 400.857 1234.11 400.857C1236.83 400.857 1239.02 398.658 1239.02 395.941C1239.02 393.223 1236.83 391.024 1234.11 391.024Z" />
        <path d="M1216.18 391.024C1213.47 391.024 1211.27 393.223 1211.27 395.941C1211.27 398.658 1213.47 400.857 1216.18 400.857C1218.9 400.857 1221.1 398.658 1221.1 395.941C1221.1 393.223 1218.9 391.024 1216.18 391.024Z" />
        <path d="M1198.23 391.024C1195.52 391.024 1193.32 393.223 1193.32 395.941C1193.32 398.658 1195.52 400.857 1198.23 400.857C1200.95 400.857 1203.15 398.658 1203.15 395.941C1203.15 393.223 1200.95 391.024 1198.23 391.024Z" />
        <path d="M1180.3 391.024C1177.59 391.024 1175.39 393.223 1175.39 395.941C1175.39 398.658 1177.59 400.857 1180.3 400.857C1183.02 400.857 1185.22 398.658 1185.22 395.941C1185.22 393.223 1183.02 391.024 1180.3 391.024Z" />
        <path d="M1162.35 391.024C1159.64 391.024 1157.44 393.223 1157.44 395.941C1157.44 398.658 1159.64 400.857 1162.35 400.857C1165.07 400.857 1167.27 398.658 1167.27 395.941C1167.27 393.223 1165.07 391.024 1162.35 391.024Z" />
        <path d="M1144.43 391.024C1141.71 391.024 1139.51 393.223 1139.51 395.941C1139.51 398.658 1141.71 400.857 1144.43 400.857C1147.14 400.857 1149.34 398.658 1149.34 395.941C1149.34 393.223 1147.14 391.024 1144.43 391.024Z" />
        <path d="M1126.48 391.049C1123.76 391.049 1121.56 393.25 1121.56 395.965C1121.56 398.68 1123.76 400.882 1126.48 400.882C1129.19 400.882 1131.39 398.68 1131.39 395.965C1131.39 393.25 1129.19 391.049 1126.48 391.049Z" />
        <path d="M1108.55 391.049C1105.84 391.049 1103.64 393.25 1103.64 395.965C1103.64 398.68 1105.84 400.882 1108.55 400.882C1111.26 400.882 1113.46 398.68 1113.46 395.965C1113.46 393.25 1111.26 391.049 1108.55 391.049Z" />
        <path d="M1090.6 391.049C1087.88 391.049 1085.68 393.25 1085.68 395.965C1085.68 398.68 1087.88 400.882 1090.6 400.882C1093.31 400.882 1095.51 398.68 1095.51 395.965C1095.51 393.25 1093.31 391.049 1090.6 391.049Z" />
        <path d="M1072.67 391.049C1069.96 391.049 1067.76 393.25 1067.76 395.965C1067.76 398.68 1069.96 400.882 1072.67 400.882C1075.38 400.882 1077.58 398.68 1077.58 395.965C1077.58 393.25 1075.38 391.049 1072.67 391.049Z" />
        <path d="M1054.72 391.049C1052.01 391.049 1049.81 393.25 1049.81 395.965C1049.81 398.68 1052.01 400.882 1054.72 400.882C1057.43 400.882 1059.63 398.68 1059.63 395.965C1059.63 393.25 1057.43 391.049 1054.72 391.049Z" />
        <path d="M1036.77 391.049C1034.05 391.049 1031.85 393.25 1031.85 395.965C1031.85 398.68 1034.05 400.882 1036.77 400.882C1039.48 400.882 1041.68 398.68 1041.68 395.965C1041.68 393.25 1039.48 391.049 1036.77 391.049Z" />
        <path d="M1018.84 391.049C1016.13 391.049 1013.93 393.25 1013.93 395.965C1013.93 398.68 1016.13 400.882 1018.84 400.882C1021.56 400.882 1023.76 398.68 1023.76 395.965C1023.76 393.25 1021.56 391.049 1018.84 391.049Z" />
        <path d="M1000.89 391.049C998.175 391.049 995.975 393.25 995.975 395.965C995.975 398.68 998.175 400.882 1000.89 400.882C1003.6 400.882 1005.8 398.68 1005.8 395.965C1005.8 393.25 1003.6 391.049 1000.89 391.049Z" />
        <path d="M982.962 391.049C980.248 391.049 978.048 393.25 978.048 395.965C978.048 398.68 980.248 400.882 982.962 400.882C985.676 400.882 987.876 398.68 987.876 395.965C987.876 393.25 985.676 391.049 982.962 391.049Z" />
        <path d="M965.011 391.049C962.297 391.049 960.097 393.25 960.097 395.965C960.097 398.68 962.297 400.882 965.011 400.882C967.725 400.882 969.925 398.68 969.925 395.965C969.925 393.25 967.725 391.049 965.011 391.049Z" />
        <path d="M1906.86 373.534C1904.39 373.534 1902.39 375.535 1902.39 378.005C1902.39 380.476 1904.39 382.477 1906.86 382.477C1909.33 382.477 1911.33 380.476 1911.33 378.005C1911.33 375.535 1909.33 373.534 1906.86 373.534Z" />
        <path d="M1888.91 373.534C1886.44 373.534 1884.44 375.535 1884.44 378.005C1884.44 380.476 1886.44 382.477 1888.91 382.477C1891.38 382.477 1893.38 380.476 1893.38 378.005C1893.38 375.535 1891.38 373.534 1888.91 373.534Z" />
        <path d="M1870.98 373.534C1868.51 373.534 1866.51 375.535 1866.51 378.005C1866.51 380.476 1868.51 382.477 1870.98 382.477C1873.45 382.477 1875.45 380.476 1875.45 378.005C1875.45 375.535 1873.45 373.534 1870.98 373.534Z" />
        <path d="M1853.03 373.533C1850.56 373.533 1848.56 375.535 1848.56 378.005C1848.56 380.476 1850.56 382.477 1853.03 382.477C1855.5 382.477 1857.5 380.476 1857.5 378.005C1857.5 375.535 1855.5 373.533 1853.03 373.533Z" />
        <path d="M1835.1 373.533C1832.63 373.533 1830.63 375.535 1830.63 378.005C1830.63 380.476 1832.63 382.477 1835.1 382.477C1837.57 382.477 1839.57 380.476 1839.57 378.005C1839.57 375.535 1837.57 373.533 1835.1 373.533Z" />
        <path d="M1817.15 373.533C1814.68 373.533 1812.68 375.535 1812.68 378.005C1812.68 380.476 1814.68 382.477 1817.15 382.477C1819.62 382.477 1821.62 380.476 1821.62 378.005C1821.62 375.535 1819.62 373.533 1817.15 373.533Z" />
        <path d="M1799.22 373.533C1796.75 373.533 1794.75 375.535 1794.75 378.005C1794.75 380.476 1796.75 382.477 1799.22 382.477C1801.69 382.477 1803.69 380.476 1803.69 378.005C1803.69 375.535 1801.69 373.533 1799.22 373.533Z" />
        <path d="M1781.27 373.533C1778.8 373.533 1776.8 375.535 1776.8 378.005C1776.8 380.476 1778.8 382.477 1781.27 382.477C1783.74 382.477 1785.74 380.476 1785.74 378.005C1785.74 375.535 1783.74 373.533 1781.27 373.533Z" />
        <path d="M1763.35 373.533C1760.88 373.533 1758.88 375.535 1758.88 378.005C1758.88 380.476 1760.88 382.477 1763.35 382.477C1765.81 382.477 1767.82 380.476 1767.82 378.005C1767.82 375.535 1765.81 373.533 1763.35 373.533Z" />
        <path d="M1745.39 373.533C1742.92 373.533 1740.92 375.535 1740.92 378.005C1740.92 380.476 1742.92 382.477 1745.39 382.477C1747.86 382.477 1749.86 380.476 1749.86 378.005C1749.86 375.535 1747.86 373.533 1745.39 373.533Z" />
        <path d="M1727.46 373.533C1725 373.533 1723 375.535 1723 378.005C1723 380.476 1725 382.477 1727.46 382.477C1729.93 382.477 1731.93 380.476 1731.93 378.005C1731.93 375.535 1729.93 373.533 1727.46 373.533Z" />
        <path d="M1709.52 373.533C1707.05 373.533 1705.05 375.535 1705.05 378.005C1705.05 380.476 1707.05 382.477 1709.52 382.477C1711.98 382.477 1713.98 380.476 1713.98 378.005C1713.98 375.535 1711.98 373.533 1709.52 373.533Z" />
        <path d="M1691.56 373.533C1689.09 373.533 1687.09 375.535 1687.09 378.005C1687.09 380.476 1689.09 382.477 1691.56 382.477C1694.03 382.477 1696.03 380.476 1696.03 378.005C1696.03 375.535 1694.06 373.533 1691.56 373.533Z" />
        <path d="M1673.64 373.533C1671.17 373.533 1669.17 375.535 1669.17 378.005C1669.17 380.476 1671.17 382.477 1673.64 382.477C1676.11 382.477 1678.11 380.476 1678.11 378.005C1678.11 375.535 1676.11 373.533 1673.64 373.533Z" />
        <path d="M1655.68 373.533C1653.22 373.533 1651.22 375.535 1651.22 378.005C1651.22 380.476 1653.22 382.477 1655.68 382.477C1658.15 382.477 1660.15 380.476 1660.15 378.005C1660.15 375.535 1658.18 373.533 1655.68 373.533Z" />
        <path d="M1637.76 373.533C1635.29 373.533 1633.29 375.535 1633.29 378.005C1633.29 380.476 1635.29 382.477 1637.76 382.477C1640.23 382.477 1642.23 380.476 1642.23 378.005C1642.23 375.535 1640.23 373.533 1637.76 373.533Z" />
        <path d="M1619.81 373.533C1617.34 373.533 1615.34 375.535 1615.34 378.005C1615.34 380.476 1617.34 382.477 1619.81 382.477C1622.28 382.477 1624.28 380.476 1624.28 378.005C1624.28 375.535 1622.3 373.533 1619.81 373.533Z" />
        <path d="M1601.88 373.533C1599.41 373.533 1597.41 375.535 1597.41 378.005C1597.41 380.476 1599.41 382.477 1601.88 382.477C1604.35 382.477 1606.35 380.476 1606.35 378.005C1606.35 375.535 1604.35 373.533 1601.88 373.533Z" />
        <path d="M1583.93 373.533C1581.46 373.533 1579.46 375.535 1579.46 378.005C1579.46 380.476 1581.46 382.477 1583.93 382.477C1586.4 382.477 1588.4 380.476 1588.4 378.005C1588.4 375.535 1586.4 373.533 1583.93 373.533Z" />
        <path d="M1566 373.533C1563.53 373.533 1561.53 375.535 1561.53 378.005C1561.53 380.476 1563.53 382.477 1566 382.477C1568.47 382.477 1570.47 380.476 1570.47 378.005C1570.47 375.535 1568.47 373.533 1566 373.533Z" />
        <path d="M1548.05 373.533C1545.58 373.533 1543.58 375.535 1543.58 378.005C1543.58 380.476 1545.58 382.477 1548.05 382.477C1550.52 382.477 1552.52 380.476 1552.52 378.005C1552.52 375.535 1550.52 373.533 1548.05 373.533Z" />
        <path d="M1530.12 373.533C1527.65 373.533 1525.65 375.535 1525.65 378.005C1525.65 380.476 1527.65 382.477 1530.12 382.477C1532.59 382.477 1534.59 380.476 1534.59 378.005C1534.59 375.535 1532.59 373.533 1530.12 373.533Z" />
        <path d="M1512.17 373.533C1509.7 373.533 1507.7 375.535 1507.7 378.005C1507.7 380.476 1509.7 382.477 1512.17 382.477C1514.64 382.477 1516.64 380.476 1516.64 378.005C1516.64 375.535 1514.64 373.533 1512.17 373.533Z" />
        <path d="M1494.25 373.533C1491.78 373.533 1489.78 375.535 1489.78 378.005C1489.78 380.476 1491.78 382.477 1494.25 382.477C1496.71 382.477 1498.71 380.476 1498.71 378.005C1498.71 375.535 1496.71 373.533 1494.25 373.533Z" />
        <path d="M1476.3 373.533C1473.83 373.533 1471.83 375.535 1471.83 378.005C1471.83 380.476 1473.83 382.477 1476.3 382.477C1478.77 382.477 1480.77 380.476 1480.77 378.005C1480.77 375.535 1478.77 373.533 1476.3 373.533Z" />
        <path d="M1458.37 373.533C1455.9 373.533 1453.9 375.535 1453.9 378.005C1453.9 380.476 1455.9 382.477 1458.37 382.477C1460.84 382.477 1462.84 380.476 1462.84 378.005C1462.84 375.535 1460.84 373.533 1458.37 373.533Z" />
        <path d="M1440.42 373.533C1437.95 373.533 1435.95 375.535 1435.95 378.005C1435.95 380.476 1437.95 382.477 1440.42 382.477C1442.88 382.477 1444.88 380.476 1444.88 378.005C1444.88 375.535 1442.88 373.533 1440.42 373.533Z" />
        <path d="M1422.49 373.533C1420.02 373.533 1418.02 375.535 1418.02 378.005C1418.02 380.476 1420.02 382.477 1422.49 382.477C1424.96 382.477 1426.96 380.476 1426.96 378.005C1426.96 375.535 1424.96 373.533 1422.49 373.533Z" />
        <path d="M1404.54 373.533C1402.07 373.533 1400.07 375.535 1400.07 378.005C1400.07 380.476 1402.07 382.477 1404.54 382.477C1407.01 382.477 1409.01 380.476 1409.01 378.005C1409.01 375.535 1407.01 373.533 1404.54 373.533Z" />
        <path d="M1386.61 373.533C1384.14 373.533 1382.14 375.535 1382.14 378.005C1382.14 380.476 1384.14 382.477 1386.61 382.477C1389.08 382.477 1391.08 380.476 1391.08 378.005C1391.08 375.535 1389.08 373.533 1386.61 373.533Z" />
        <path d="M1368.66 373.533C1366.19 373.533 1364.19 375.535 1364.19 378.005C1364.19 380.476 1366.19 382.477 1368.66 382.477C1371.13 382.477 1373.13 380.476 1373.13 378.005C1373.13 375.535 1371.13 373.533 1368.66 373.533Z" />
        <path d="M1350.73 373.533C1348.26 373.533 1346.26 375.535 1346.26 378.005C1346.26 380.476 1348.26 382.477 1350.73 382.477C1353.2 382.477 1355.2 380.476 1355.2 378.005C1355.2 375.535 1353.2 373.533 1350.73 373.533Z" />
        <path d="M1332.78 373.533C1330.31 373.533 1328.31 375.535 1328.31 378.005C1328.31 380.476 1330.31 382.477 1332.78 382.477C1335.25 382.477 1337.25 380.476 1337.25 378.005C1337.25 375.535 1335.25 373.533 1332.78 373.533Z" />
        <path d="M1314.85 373.533C1312.38 373.533 1310.38 375.535 1310.38 378.005C1310.38 380.476 1312.38 382.477 1314.85 382.477C1317.32 382.477 1319.32 380.476 1319.32 378.005C1319.32 375.535 1317.32 373.533 1314.85 373.533Z" />
        <path d="M1296.9 373.533C1294.44 373.533 1292.43 375.535 1292.43 378.005C1292.43 380.476 1294.44 382.477 1296.9 382.477C1299.37 382.477 1301.37 380.476 1301.37 378.005C1301.37 375.535 1299.37 373.533 1296.9 373.533Z" />
        <path d="M1278.95 373.533C1276.48 373.533 1274.48 375.535 1274.48 378.005C1274.48 380.476 1276.48 382.477 1278.95 382.477C1281.42 382.477 1283.42 380.476 1283.42 378.005C1283.42 375.535 1281.45 373.533 1278.95 373.533Z" />
        <path d="M1261.03 373.533C1258.56 373.533 1256.56 375.535 1256.56 378.005C1256.56 380.476 1258.56 382.477 1261.03 382.477C1263.49 382.477 1265.49 380.476 1265.49 378.005C1265.49 375.535 1263.49 373.533 1261.03 373.533Z" />
        <path d="M1243.07 373.533C1240.6 373.533 1238.6 375.535 1238.6 378.005C1238.6 380.476 1240.6 382.477 1243.07 382.477C1245.54 382.477 1247.54 380.476 1247.54 378.005C1247.54 375.535 1245.57 373.533 1243.07 373.533Z" />
        <path d="M1225.15 373.533C1222.68 373.533 1220.68 375.535 1220.68 378.005C1220.68 380.476 1222.68 382.477 1225.15 382.477C1227.61 382.477 1229.61 380.476 1229.61 378.005C1229.61 375.535 1227.61 373.533 1225.15 373.533Z" />
        <path d="M1207.2 373.533C1204.73 373.533 1202.73 375.535 1202.73 378.005C1202.73 380.476 1204.73 382.477 1207.2 382.477C1209.67 382.477 1211.67 380.476 1211.67 378.005C1211.67 375.535 1209.69 373.533 1207.2 373.533Z" />
        <path d="M1189.27 373.533C1186.8 373.533 1184.8 375.535 1184.8 378.005C1184.8 380.476 1186.8 382.477 1189.27 382.477C1191.74 382.477 1193.74 380.476 1193.74 378.005C1193.74 375.535 1191.74 373.533 1189.27 373.533Z" />
        <path d="M1171.32 373.533C1168.85 373.533 1166.85 375.535 1166.85 378.005C1166.85 380.476 1168.85 382.477 1171.32 382.477C1173.79 382.477 1175.79 380.476 1175.79 378.005C1175.79 375.535 1173.81 373.533 1171.32 373.533Z" />
        <path d="M1153.39 373.533C1150.92 373.533 1148.92 375.535 1148.92 378.005C1148.92 380.476 1150.92 382.477 1153.39 382.477C1155.86 382.477 1157.86 380.476 1157.86 378.005C1157.86 375.535 1155.86 373.533 1153.39 373.533Z" />
        <path d="M1135.44 373.533C1132.97 373.533 1130.97 375.535 1130.97 378.005C1130.97 380.476 1132.97 382.477 1135.44 382.477C1137.91 382.477 1139.91 380.476 1139.91 378.005C1139.91 375.535 1137.91 373.533 1135.44 373.533Z" />
        <path d="M1117.51 373.533C1115.04 373.533 1113.04 375.535 1113.04 378.005C1113.04 380.476 1115.04 382.477 1117.51 382.477C1119.98 382.477 1121.98 380.476 1121.98 378.005C1121.98 375.535 1119.98 373.533 1117.51 373.533Z" />
        <path d="M1099.56 373.533C1097.09 373.533 1095.09 375.535 1095.09 378.005C1095.09 380.476 1097.09 382.477 1099.56 382.477C1102.03 382.477 1104.03 380.476 1104.03 378.005C1104.03 375.535 1102.03 373.533 1099.56 373.533Z" />
        <path d="M1081.63 373.533C1079.17 373.533 1077.17 375.535 1077.17 378.005C1077.17 380.476 1079.17 382.477 1081.63 382.477C1084.1 382.477 1086.1 380.476 1086.1 378.005C1086.1 375.535 1084.1 373.533 1081.63 373.533Z" />
        <path d="M1063.68 373.533C1061.21 373.533 1059.21 375.535 1059.21 378.005C1059.21 380.476 1061.21 382.477 1063.68 382.477C1066.15 382.477 1068.15 380.476 1068.15 378.005C1068.15 375.535 1066.15 373.533 1063.68 373.533Z" />
        <path d="M1045.76 373.533C1043.29 373.533 1041.29 375.535 1041.29 378.005C1041.29 380.476 1043.29 382.477 1045.76 382.477C1048.23 382.477 1050.23 380.476 1050.23 378.005C1050.23 375.535 1048.23 373.533 1045.76 373.533Z" />
        <path d="M1027.8 373.533C1025.34 373.533 1023.34 375.535 1023.34 378.005C1023.34 380.476 1025.34 382.477 1027.8 382.477C1030.27 382.477 1032.27 380.476 1032.27 378.005C1032.27 375.535 1030.27 373.533 1027.8 373.533Z" />
        <path d="M1009.88 373.533C1007.41 373.533 1005.41 375.535 1005.41 378.005C1005.41 380.476 1007.41 382.477 1009.88 382.477C1012.35 382.477 1014.35 380.476 1014.35 378.005C1014.35 375.535 1012.35 373.533 1009.88 373.533Z" />
        <path d="M991.927 373.533C989.457 373.533 987.457 375.535 987.457 378.005C987.457 380.476 989.457 382.477 991.927 382.477C994.396 382.477 996.396 380.476 996.396 378.005C996.396 375.535 994.396 373.533 991.927 373.533Z" />
        <path d="M973.998 373.533C971.529 373.533 969.529 375.535 969.529 378.005C969.529 380.476 971.529 382.477 973.998 382.477C976.467 382.477 978.468 380.476 978.468 378.005C978.468 375.535 976.467 373.533 973.998 373.533Z" />
        <path d="M1915.82 355.845C1913.49 355.845 1911.6 357.736 1911.6 360.069C1911.6 362.402 1913.49 364.294 1915.82 364.294C1918.16 364.294 1920.05 362.402 1920.05 360.069C1920.05 357.736 1918.16 355.845 1915.82 355.845Z" />
        <path d="M1897.87 355.845C1895.54 355.845 1893.65 357.736 1893.65 360.069C1893.65 362.402 1895.54 364.294 1897.87 364.294C1900.2 364.294 1902.09 362.402 1902.09 360.069C1902.09 357.736 1900.2 355.845 1897.87 355.845Z" />
        <path d="M1879.94 355.845C1877.61 355.845 1875.72 357.736 1875.72 360.069C1875.72 362.402 1877.61 364.294 1879.94 364.294C1882.27 364.294 1884.16 362.402 1884.16 360.069C1884.16 357.736 1882.27 355.845 1879.94 355.845Z" />
        <path d="M1861.99 355.845C1859.66 355.845 1857.77 357.736 1857.77 360.069C1857.77 362.402 1859.66 364.294 1861.99 364.294C1864.32 364.294 1866.22 362.402 1866.22 360.069C1866.22 357.736 1864.32 355.845 1861.99 355.845Z" />
        <path d="M1844.06 355.845C1841.73 355.845 1839.84 357.736 1839.84 360.069C1839.84 362.402 1841.73 364.294 1844.06 364.294C1846.4 364.294 1848.29 362.402 1848.29 360.069C1848.29 357.736 1846.4 355.845 1844.06 355.845Z" />
        <path d="M1826.11 355.845C1823.78 355.845 1821.89 357.736 1821.89 360.069C1821.89 362.402 1823.78 364.294 1826.11 364.294C1828.45 364.294 1830.34 362.402 1830.34 360.069C1830.34 357.736 1828.45 355.845 1826.11 355.845Z" />
        <path d="M1808.19 355.845C1805.85 355.845 1803.96 357.736 1803.96 360.069C1803.96 362.402 1805.85 364.294 1808.19 364.294C1810.52 364.294 1812.41 362.402 1812.41 360.069C1812.41 357.736 1810.52 355.845 1808.19 355.845Z" />
        <path d="M1790.23 355.845C1787.9 355.845 1786.01 357.736 1786.01 360.069C1786.01 362.402 1787.9 364.294 1790.23 364.294C1792.57 364.294 1794.46 362.402 1794.46 360.069C1794.46 357.736 1792.57 355.845 1790.23 355.845Z" />
        <path d="M1772.31 355.845C1769.98 355.845 1768.09 357.736 1768.09 360.069C1768.09 362.402 1769.98 364.294 1772.31 364.294C1774.64 364.294 1776.53 362.402 1776.53 360.069C1776.53 357.736 1774.64 355.845 1772.31 355.845Z" />
        <path d="M1754.36 355.845C1752.02 355.845 1750.13 357.736 1750.13 360.069C1750.13 362.402 1752.02 364.294 1754.36 364.294C1756.69 364.294 1758.58 362.402 1758.58 360.069C1758.58 357.736 1756.69 355.845 1754.36 355.845Z" />
        <path d="M1736.43 355.845C1734.1 355.845 1732.21 357.736 1732.21 360.069C1732.21 362.402 1734.1 364.294 1736.43 364.294C1738.76 364.294 1740.65 362.402 1740.65 360.069C1740.65 357.736 1738.76 355.845 1736.43 355.845Z" />
        <path d="M1718.48 355.845C1716.15 355.845 1714.26 357.736 1714.26 360.069C1714.26 362.402 1716.15 364.294 1718.48 364.294C1720.81 364.294 1722.7 362.402 1722.7 360.069C1722.7 357.736 1720.81 355.845 1718.48 355.845Z" />
        <path d="M1700.55 355.845C1698.22 355.845 1696.33 357.736 1696.33 360.069C1696.33 362.402 1698.22 364.294 1700.55 364.294C1702.88 364.294 1704.77 362.402 1704.77 360.069C1704.77 357.736 1702.88 355.845 1700.55 355.845Z" />
        <path d="M1682.6 355.845C1680.27 355.845 1678.38 357.736 1678.38 360.069C1678.38 362.402 1680.27 364.294 1682.6 364.294C1684.93 364.294 1686.82 362.402 1686.82 360.069C1686.82 357.736 1684.93 355.845 1682.6 355.845Z" />
        <path d="M1664.67 355.82C1662.33 355.82 1660.45 357.722 1660.45 360.045C1660.45 362.392 1662.35 364.269 1664.67 364.269C1667.02 364.269 1668.9 362.367 1668.9 360.045C1668.9 357.722 1667 355.82 1664.67 355.82Z" />
        <path d="M1646.72 355.82C1644.38 355.82 1642.5 357.722 1642.5 360.045C1642.5 362.392 1644.4 364.269 1646.72 364.269C1649.07 364.269 1650.95 362.367 1650.95 360.045C1650.95 357.722 1649.07 355.82 1646.72 355.82Z" />
        <path d="M1628.79 355.82C1626.45 355.82 1624.57 357.722 1624.57 360.045C1624.57 362.392 1626.47 364.269 1628.79 364.269C1631.14 364.269 1633.02 362.367 1633.02 360.045C1633.02 357.722 1631.12 355.82 1628.79 355.82Z" />
        <path d="M1610.85 355.82C1608.5 355.82 1606.62 357.722 1606.62 360.045C1606.62 362.392 1608.52 364.269 1610.85 364.269C1613.19 364.269 1615.07 362.367 1615.07 360.045C1615.07 357.722 1613.19 355.82 1610.85 355.82Z" />
        <path d="M1592.92 355.82C1590.57 355.82 1588.69 357.722 1588.69 360.045C1588.69 362.392 1590.6 364.269 1592.92 364.269C1595.26 364.269 1597.14 362.367 1597.14 360.045C1597.14 357.722 1595.24 355.82 1592.92 355.82Z" />
        <path d="M1574.97 355.82C1572.62 355.82 1570.74 357.722 1570.74 360.045C1570.74 362.392 1572.64 364.269 1574.97 364.269C1577.31 364.269 1579.19 362.367 1579.19 360.045C1579.19 357.722 1577.31 355.82 1574.97 355.82Z" />
        <path d="M1557.04 355.82C1554.69 355.82 1552.82 357.722 1552.82 360.045C1552.82 362.392 1554.72 364.269 1557.04 364.269C1559.39 364.269 1561.26 362.367 1561.26 360.045C1561.26 357.722 1559.36 355.82 1557.04 355.82Z" />
        <path d="M1539.09 355.82C1536.74 355.82 1534.87 357.722 1534.87 360.045C1534.87 362.392 1536.77 364.269 1539.09 364.269C1541.43 364.269 1543.31 362.367 1543.31 360.045C1543.31 357.722 1541.43 355.82 1539.09 355.82Z" />
        <path d="M1521.16 355.82C1518.82 355.82 1516.94 357.722 1516.94 360.045C1516.94 362.392 1518.84 364.269 1521.16 364.269C1523.51 364.269 1525.38 362.367 1525.38 360.045C1525.38 357.722 1523.48 355.82 1521.16 355.82Z" />
        <path d="M1503.21 355.82C1500.86 355.82 1498.99 357.722 1498.99 360.045C1498.99 362.392 1500.89 364.269 1503.21 364.269C1505.55 364.269 1507.43 362.367 1507.43 360.045C1507.43 357.722 1505.55 355.82 1503.21 355.82Z" />
        <path d="M1485.26 355.82C1482.91 355.82 1481.03 357.722 1481.03 360.045C1481.03 362.392 1482.93 364.269 1485.26 364.269C1487.6 364.269 1489.48 362.367 1489.48 360.045C1489.5 357.722 1487.6 355.82 1485.26 355.82Z" />
        <path d="M1467.33 355.82C1464.99 355.82 1463.11 357.722 1463.11 360.045C1463.11 362.392 1465.01 364.269 1467.33 364.269C1469.68 364.269 1471.55 362.367 1471.55 360.045C1471.55 357.722 1469.68 355.82 1467.33 355.82Z" />
        <path d="M1449.38 355.82C1447.03 355.82 1445.16 357.722 1445.16 360.045C1445.16 362.392 1447.06 364.269 1449.38 364.269C1451.73 364.269 1453.6 362.367 1453.6 360.045C1453.63 357.722 1451.73 355.82 1449.38 355.82Z" />
        <path d="M1431.45 355.82C1429.11 355.82 1427.23 357.722 1427.23 360.045C1427.23 362.392 1429.13 364.269 1431.45 364.269C1433.8 364.269 1435.68 362.367 1435.68 360.045C1435.68 357.722 1433.77 355.82 1431.45 355.82Z" />
        <path d="M1413.5 355.82C1411.15 355.82 1409.28 357.722 1409.28 360.045C1409.28 362.391 1411.18 364.269 1413.5 364.269C1415.85 364.269 1417.72 362.367 1417.72 360.045C1417.75 357.722 1415.85 355.82 1413.5 355.82Z" />
        <path d="M1395.57 355.82C1393.23 355.82 1391.35 357.722 1391.35 360.045C1391.35 362.391 1393.25 364.269 1395.57 364.269C1397.92 364.269 1399.8 362.367 1399.8 360.045C1399.8 357.722 1397.9 355.82 1395.57 355.82Z" />
        <path d="M1377.62 355.82C1375.28 355.82 1373.4 357.722 1373.4 360.045C1373.4 362.391 1375.3 364.269 1377.62 364.269C1379.97 364.269 1381.85 362.367 1381.85 360.045C1381.87 357.722 1379.97 355.82 1377.62 355.82Z" />
        <path d="M1359.7 355.82C1357.35 355.82 1355.47 357.722 1355.47 360.045C1355.47 362.391 1357.38 364.269 1359.7 364.269C1362.04 364.269 1363.92 362.367 1363.92 360.045C1363.92 357.722 1362.02 355.82 1359.7 355.82Z" />
        <path d="M1341.75 355.82C1339.4 355.82 1337.52 357.722 1337.52 360.045C1337.52 362.391 1339.42 364.269 1341.75 364.269C1344.09 364.269 1345.97 362.367 1345.97 360.045C1345.99 357.722 1344.09 355.82 1341.75 355.82Z" />
        <path d="M1323.82 355.82C1321.47 355.82 1319.59 357.722 1319.59 360.045C1319.59 362.391 1321.5 364.269 1323.82 364.269C1326.16 364.269 1328.04 362.367 1328.04 360.045C1328.04 357.722 1326.14 355.82 1323.82 355.82Z" />
        <path d="M1305.87 355.82C1303.52 355.82 1301.65 357.722 1301.65 360.045C1301.65 362.391 1303.55 364.269 1305.87 364.269C1308.21 364.269 1310.09 362.367 1310.09 360.045C1310.11 357.722 1308.21 355.82 1305.87 355.82Z" />
        <path d="M1287.94 355.82C1285.59 355.82 1283.72 357.722 1283.72 360.045C1283.72 362.391 1285.62 364.269 1287.94 364.269C1290.28 364.269 1292.16 362.367 1292.16 360.045C1292.16 357.722 1290.26 355.82 1287.94 355.82Z" />
        <path d="M1269.99 355.82C1267.64 355.82 1265.77 357.722 1265.77 360.045C1265.77 362.391 1267.67 364.269 1269.99 364.269C1272.33 364.269 1274.21 362.367 1274.21 360.045C1274.21 357.722 1272.33 355.82 1269.99 355.82Z" />
        <path d="M1252.06 355.82C1249.72 355.82 1247.84 357.722 1247.84 360.045C1247.84 362.391 1249.74 364.269 1252.06 364.269C1254.41 364.269 1256.28 362.367 1256.28 360.045C1256.28 357.722 1254.38 355.82 1252.06 355.82Z" />
        <path d="M1234.11 355.82C1231.76 355.82 1229.89 357.722 1229.89 360.045C1229.89 362.391 1231.79 364.269 1234.11 364.269C1236.46 364.269 1238.33 362.367 1238.33 360.045C1238.33 357.722 1236.46 355.82 1234.11 355.82Z" />
        <path d="M1216.18 355.82C1213.84 355.82 1211.96 357.722 1211.96 360.045C1211.96 362.391 1213.86 364.269 1216.18 364.269C1218.53 364.269 1220.41 362.367 1220.41 360.045C1220.41 357.722 1218.5 355.82 1216.18 355.82Z" />
        <path d="M1198.23 355.82C1195.89 355.82 1194.01 357.722 1194.01 360.045C1194.01 362.391 1195.91 364.269 1198.23 364.269C1200.58 364.269 1202.45 362.367 1202.45 360.045C1202.45 357.722 1200.58 355.82 1198.23 355.82Z" />
        <path d="M1180.31 355.82C1177.96 355.82 1176.08 357.722 1176.08 360.045C1176.08 362.391 1177.99 364.269 1180.31 364.269C1182.65 364.269 1184.53 362.367 1184.53 360.045C1184.53 357.722 1182.63 355.82 1180.31 355.82Z" />
        <path d="M1162.35 355.845C1160.02 355.845 1158.13 357.736 1158.13 360.069C1158.13 362.402 1160.02 364.294 1162.35 364.294C1164.69 364.294 1166.58 362.402 1166.58 360.069C1166.58 357.736 1164.69 355.845 1162.35 355.845Z" />
        <path d="M1144.43 355.845C1142.09 355.845 1140.2 357.736 1140.2 360.069C1140.2 362.402 1142.09 364.294 1144.43 364.294C1146.76 364.294 1148.65 362.402 1148.65 360.069C1148.65 357.736 1146.76 355.845 1144.43 355.845Z" />
        <path d="M1126.48 355.845C1124.14 355.845 1122.25 357.736 1122.25 360.069C1122.25 362.402 1124.14 364.294 1126.48 364.294C1128.81 364.294 1130.7 362.402 1130.7 360.069C1130.7 357.736 1128.81 355.845 1126.48 355.845Z" />
        <path d="M1108.55 355.845C1106.22 355.845 1104.33 357.736 1104.33 360.069C1104.33 362.402 1106.22 364.294 1108.55 364.294C1110.88 364.294 1112.77 362.402 1112.77 360.069C1112.77 357.736 1110.88 355.845 1108.55 355.845Z" />
        <path d="M1090.6 355.845C1088.26 355.845 1086.37 357.736 1086.37 360.069C1086.37 362.402 1088.26 364.294 1090.6 364.294C1092.93 364.294 1094.82 362.402 1094.82 360.069C1094.82 357.736 1092.93 355.845 1090.6 355.845Z" />
        <path d="M1072.67 355.845C1070.34 355.845 1068.45 357.736 1068.45 360.069C1068.45 362.402 1070.34 364.294 1072.67 364.294C1075 364.294 1076.89 362.402 1076.89 360.069C1076.89 357.736 1075 355.845 1072.67 355.845Z" />
        <path d="M1054.72 355.845C1052.39 355.845 1050.5 357.736 1050.5 360.069C1050.5 362.402 1052.39 364.294 1054.72 364.294C1057.05 364.294 1058.94 362.402 1058.94 360.069C1058.94 357.736 1057.05 355.845 1054.72 355.845Z" />
        <path d="M1036.77 355.845C1034.44 355.845 1032.54 357.736 1032.54 360.069C1032.54 362.402 1034.44 364.294 1036.77 364.294C1039.1 364.294 1040.99 362.402 1040.99 360.069C1040.99 357.736 1039.1 355.845 1036.77 355.845Z" />
        <path d="M1018.84 355.845C1016.51 355.845 1014.62 357.736 1014.62 360.069C1014.62 362.402 1016.51 364.294 1018.84 364.294C1021.17 364.294 1023.06 362.402 1023.06 360.069C1023.06 357.736 1021.17 355.845 1018.84 355.845Z" />
        <path d="M1000.89 355.845C998.558 355.845 996.667 357.736 996.667 360.069C996.667 362.402 998.558 364.294 1000.89 364.294C1003.22 364.294 1005.11 362.402 1005.11 360.069C1005.11 357.736 1003.22 355.845 1000.89 355.845Z" />
        <path d="M982.961 355.845C980.629 355.845 978.739 357.736 978.739 360.069C978.739 362.402 980.629 364.294 982.961 364.294C985.293 364.294 987.184 362.402 987.184 360.069C987.184 357.736 985.293 355.845 982.961 355.845Z" />
        <path d="M965.01 355.845C962.678 355.845 960.788 357.736 960.788 360.069C960.788 362.402 962.678 364.294 965.01 364.294C967.342 364.294 969.233 362.402 969.233 360.069C969.233 357.736 967.342 355.845 965.01 355.845Z" />
        <path d="M1906.86 338.032C1904.61 338.032 1902.78 339.857 1902.78 342.109C1902.78 344.36 1904.61 346.185 1906.86 346.185C1909.11 346.185 1910.93 344.36 1910.93 342.109C1910.93 339.857 1909.11 338.032 1906.86 338.032Z" />
        <path d="M1888.91 338.032C1886.66 338.032 1884.83 339.857 1884.83 342.109C1884.83 344.36 1886.66 346.185 1888.91 346.185C1891.16 346.185 1892.98 344.36 1892.98 342.109C1892.98 339.857 1891.16 338.032 1888.91 338.032Z" />
        <path d="M1870.98 338.032C1868.73 338.032 1866.9 339.857 1866.9 342.109C1866.9 344.36 1868.73 346.185 1870.98 346.185C1873.23 346.185 1875.05 344.36 1875.05 342.109C1875.05 339.857 1873.23 338.032 1870.98 338.032Z" />
        <path d="M1853.03 338.032C1850.78 338.032 1848.96 339.857 1848.96 342.109C1848.96 344.36 1850.78 346.185 1853.03 346.185C1855.28 346.185 1857.1 344.36 1857.1 342.109C1857.1 339.857 1855.28 338.032 1853.03 338.032Z" />
        <path d="M1835.1 338.032C1832.85 338.032 1831.03 339.857 1831.03 342.109C1831.03 344.36 1832.85 346.185 1835.1 346.185C1837.35 346.185 1839.18 344.36 1839.18 342.109C1839.18 339.857 1837.35 338.032 1835.1 338.032Z" />
        <path d="M1817.15 338.032C1814.9 338.032 1813.08 339.857 1813.08 342.109C1813.08 344.36 1814.9 346.185 1817.15 346.185C1819.4 346.185 1821.22 344.36 1821.22 342.109C1821.22 339.857 1819.4 338.032 1817.15 338.032Z" />
        <path d="M1799.22 338.032C1796.97 338.032 1795.15 339.857 1795.15 342.109C1795.15 344.36 1796.97 346.185 1799.22 346.185C1801.47 346.185 1803.3 344.36 1803.3 342.109C1803.3 339.857 1801.47 338.032 1799.22 338.032Z" />
        <path d="M1781.27 338.032C1779.02 338.032 1777.2 339.857 1777.2 342.109C1777.2 344.36 1779.02 346.185 1781.27 346.185C1783.52 346.185 1785.35 344.36 1785.35 342.109C1785.35 339.857 1783.52 338.032 1781.27 338.032Z" />
        <path d="M1763.35 338.032C1761.1 338.032 1759.27 339.857 1759.27 342.109C1759.27 344.36 1761.1 346.185 1763.35 346.185C1765.6 346.185 1767.42 344.36 1767.42 342.109C1767.42 339.857 1765.6 338.032 1763.35 338.032Z" />
        <path d="M1745.39 338.032C1743.14 338.032 1741.32 339.857 1741.32 342.109C1741.32 344.36 1743.14 346.185 1745.39 346.185C1747.64 346.185 1749.47 344.36 1749.47 342.109C1749.47 339.857 1747.64 338.032 1745.39 338.032Z" />
        <path d="M1727.47 338.032C1725.22 338.032 1723.39 339.857 1723.39 342.109C1723.39 344.36 1725.22 346.185 1727.47 346.185C1729.72 346.185 1731.54 344.36 1731.54 342.109C1731.54 339.857 1729.72 338.032 1727.47 338.032Z" />
        <path d="M1709.52 338.032C1707.26 338.032 1705.44 339.857 1705.44 342.109C1705.44 344.36 1707.26 346.185 1709.52 346.185C1711.77 346.185 1713.59 344.36 1713.59 342.109C1713.59 339.857 1711.77 338.032 1709.52 338.032Z" />
        <path d="M1691.56 338.032C1689.31 338.032 1687.49 339.857 1687.49 342.109C1687.49 344.36 1689.31 346.185 1691.56 346.185C1693.81 346.185 1695.64 344.36 1695.64 342.109C1695.64 339.857 1693.81 338.032 1691.56 338.032Z" />
        <path d="M1673.64 338.032C1671.39 338.032 1669.56 339.857 1669.56 342.109C1669.56 344.36 1671.39 346.185 1673.64 346.185C1675.89 346.185 1677.71 344.36 1677.71 342.109C1677.71 339.857 1675.89 338.032 1673.64 338.032Z" />
        <path d="M1655.68 338.032C1653.43 338.032 1651.61 339.857 1651.61 342.109C1651.61 344.36 1653.43 346.185 1655.68 346.185C1657.93 346.185 1659.76 344.36 1659.76 342.109C1659.76 339.857 1657.93 338.032 1655.68 338.032Z" />
        <path d="M1637.76 338.032C1635.51 338.032 1633.68 339.857 1633.68 342.109C1633.68 344.36 1635.51 346.185 1637.76 346.185C1640.01 346.185 1641.83 344.36 1641.83 342.109C1641.83 339.857 1640.01 338.032 1637.76 338.032Z" />
        <path d="M1619.81 338.032C1617.56 338.032 1615.73 339.857 1615.73 342.109C1615.73 344.36 1617.56 346.185 1619.81 346.185C1622.06 346.185 1623.88 344.36 1623.88 342.109C1623.88 339.857 1622.06 338.032 1619.81 338.032Z" />
        <path d="M1601.88 338.032C1599.63 338.032 1597.81 339.857 1597.81 342.109C1597.81 344.36 1599.63 346.185 1601.88 346.185C1604.13 346.185 1605.95 344.36 1605.95 342.109C1605.95 339.857 1604.13 338.032 1601.88 338.032Z" />
        <path d="M1583.93 338.032C1581.68 338.032 1579.86 339.857 1579.86 342.109C1579.86 344.36 1581.68 346.185 1583.93 346.185C1586.18 346.185 1588 344.36 1588 342.109C1588 339.857 1586.18 338.032 1583.93 338.032Z" />
        <path d="M1566 338.032C1563.75 338.032 1561.93 339.857 1561.93 342.109C1561.93 344.36 1563.75 346.185 1566 346.185C1568.25 346.185 1570.08 344.36 1570.08 342.109C1570.08 339.857 1568.25 338.032 1566 338.032Z" />
        <path d="M1548.05 338.032C1545.8 338.032 1543.98 339.86 1543.98 342.109C1543.98 344.357 1545.8 346.185 1548.05 346.185C1550.3 346.185 1552.13 344.357 1552.13 342.109C1552.13 339.86 1550.32 338.032 1548.05 338.032Z" />
        <path d="M1530.12 338.032C1527.88 338.032 1526.05 339.86 1526.05 342.109C1526.05 344.357 1527.88 346.185 1530.12 346.185C1532.37 346.185 1534.2 344.357 1534.2 342.109C1534.2 339.86 1532.37 338.032 1530.12 338.032Z" />
        <path d="M1512.17 338.032C1509.93 338.032 1508.1 339.86 1508.1 342.109C1508.1 344.357 1509.93 346.185 1512.17 346.185C1514.42 346.185 1516.25 344.357 1516.25 342.109C1516.25 339.86 1514.42 338.032 1512.17 338.032Z" />
        <path d="M1494.25 338.032C1492 338.032 1490.17 339.86 1490.17 342.109C1490.17 344.357 1492 346.185 1494.25 346.185C1496.49 346.185 1498.32 344.357 1498.32 342.109C1498.32 339.86 1496.49 338.032 1494.25 338.032Z" />
        <path d="M1476.29 338.032C1474.05 338.032 1472.22 339.86 1472.22 342.109C1472.22 344.357 1474.05 346.185 1476.29 346.185C1478.54 346.185 1480.37 344.357 1480.37 342.109C1480.37 339.86 1478.54 338.032 1476.29 338.032Z" />
        <path d="M1458.37 338.032C1456.12 338.032 1454.29 339.86 1454.29 342.109C1454.29 344.357 1456.12 346.185 1458.37 346.185C1460.61 346.185 1462.44 344.357 1462.44 342.109C1462.44 339.86 1460.61 338.032 1458.37 338.032Z" />
        <path d="M1440.41 338.032C1438.17 338.032 1436.34 339.86 1436.34 342.109C1436.34 344.357 1438.17 346.185 1440.41 346.185C1442.66 346.185 1444.49 344.357 1444.49 342.109C1444.49 339.86 1442.66 338.032 1440.41 338.032Z" />
        <path d="M1422.49 338.032C1420.24 338.032 1418.42 339.86 1418.42 342.109C1418.42 344.357 1420.24 346.185 1422.49 346.185C1424.74 346.185 1426.56 344.357 1426.56 342.109C1426.56 339.86 1424.74 338.032 1422.49 338.032Z" />
        <path d="M1404.54 338.032C1402.29 338.032 1400.46 339.86 1400.46 342.109C1400.46 344.357 1402.29 346.185 1404.54 346.185C1406.78 346.185 1408.61 344.357 1408.61 342.109C1408.61 339.86 1406.78 338.032 1404.54 338.032Z" />
        <path d="M1386.61 338.032C1384.37 338.032 1382.54 339.86 1382.54 342.109C1382.54 344.357 1384.37 346.185 1386.61 346.185C1388.86 346.185 1390.69 344.357 1390.69 342.109C1390.69 339.86 1388.86 338.032 1386.61 338.032Z" />
        <path d="M1368.66 338.032C1366.41 338.032 1364.59 339.86 1364.59 342.109C1364.59 344.357 1366.41 346.185 1368.66 346.185C1370.91 346.185 1372.73 344.357 1372.73 342.109C1372.73 339.86 1370.91 338.032 1368.66 338.032Z" />
        <path d="M1350.73 338.032C1348.49 338.032 1346.66 339.86 1346.66 342.109C1346.66 344.357 1348.49 346.185 1350.73 346.185C1352.98 346.185 1354.81 344.357 1354.81 342.109C1354.81 339.86 1352.98 338.032 1350.73 338.032Z" />
        <path d="M1332.78 338.032C1330.53 338.032 1328.71 339.86 1328.71 342.109C1328.71 344.357 1330.53 346.185 1332.78 346.185C1335.03 346.185 1336.86 344.357 1336.86 342.109C1336.86 339.86 1335.03 338.032 1332.78 338.032Z" />
        <path d="M1314.86 338.032C1312.61 338.032 1310.78 339.86 1310.78 342.109C1310.78 344.357 1312.61 346.185 1314.86 346.185C1317.1 346.185 1318.93 344.357 1318.93 342.109C1318.93 339.86 1317.1 338.032 1314.86 338.032Z" />
        <path d="M1296.9 338.032C1294.66 338.032 1292.83 339.86 1292.83 342.109C1292.83 344.357 1294.66 346.185 1296.9 346.185C1299.15 346.185 1300.98 344.357 1300.98 342.109C1300.98 339.86 1299.15 338.032 1296.9 338.032Z" />
        <path d="M1278.95 338.032C1276.7 338.032 1274.88 339.857 1274.88 342.109C1274.88 344.36 1276.7 346.185 1278.95 346.185C1281.2 346.185 1283.03 344.36 1283.03 342.109C1283.03 339.857 1281.2 338.032 1278.95 338.032Z" />
        <path d="M1261.02 338.032C1258.77 338.032 1256.95 339.857 1256.95 342.109C1256.95 344.36 1258.77 346.185 1261.02 346.185C1263.28 346.185 1265.1 344.36 1265.1 342.109C1265.1 339.857 1263.28 338.032 1261.02 338.032Z" />
        <path d="M1243.07 338.032C1240.82 338.032 1239 339.857 1239 342.109C1239 344.36 1240.82 346.185 1243.07 346.185C1245.32 346.185 1247.15 344.36 1247.15 342.109C1247.15 339.857 1245.32 338.032 1243.07 338.032Z" />
        <path d="M1225.15 338.032C1222.9 338.032 1221.07 339.857 1221.07 342.109C1221.07 344.36 1222.9 346.185 1225.15 346.185C1227.4 346.185 1229.22 344.36 1229.22 342.109C1229.22 339.857 1227.4 338.032 1225.15 338.032Z" />
        <path d="M1207.19 338.032C1204.94 338.032 1203.12 339.857 1203.12 342.109C1203.12 344.36 1204.94 346.185 1207.19 346.185C1209.44 346.185 1211.27 344.36 1211.27 342.109C1211.27 339.857 1209.44 338.032 1207.19 338.032Z" />
        <path d="M1189.27 338.032C1187.02 338.032 1185.19 339.857 1185.19 342.109C1185.19 344.36 1187.02 346.185 1189.27 346.185C1191.52 346.185 1193.34 344.36 1193.34 342.109C1193.34 339.857 1191.52 338.032 1189.27 338.032Z" />
        <path d="M1171.32 338.032C1169.07 338.032 1167.24 339.857 1167.24 342.109C1167.24 344.36 1169.07 346.185 1171.32 346.185C1173.57 346.185 1175.39 344.36 1175.39 342.109C1175.39 339.857 1173.57 338.032 1171.32 338.032Z" />
        <path d="M1153.39 338.032C1151.14 338.032 1149.32 339.857 1149.32 342.109C1149.32 344.36 1151.14 346.185 1153.39 346.185C1155.64 346.185 1157.46 344.36 1157.46 342.109C1157.46 339.857 1155.64 338.032 1153.39 338.032Z" />
        <path d="M1135.44 338.032C1133.19 338.032 1131.36 339.857 1131.36 342.109C1131.36 344.36 1133.19 346.185 1135.44 346.185C1137.69 346.185 1139.51 344.36 1139.51 342.109C1139.51 339.857 1137.69 338.032 1135.44 338.032Z" />
        <path d="M1117.51 338.032C1115.26 338.032 1113.44 339.857 1113.44 342.109C1113.44 344.36 1115.26 346.185 1117.51 346.185C1119.76 346.185 1121.59 344.36 1121.59 342.109C1121.59 339.857 1119.76 338.032 1117.51 338.032Z" />
        <path d="M1099.56 338.032C1097.31 338.032 1095.49 339.857 1095.49 342.109C1095.49 344.36 1097.31 346.185 1099.56 346.185C1101.81 346.185 1103.64 344.36 1103.64 342.109C1103.64 339.857 1101.81 338.032 1099.56 338.032Z" />
        <path d="M1081.63 338.032C1079.38 338.032 1077.56 339.857 1077.56 342.109C1077.56 344.36 1079.38 346.185 1081.63 346.185C1083.88 346.185 1085.71 344.36 1085.71 342.109C1085.71 339.857 1083.88 338.032 1081.63 338.032Z" />
        <path d="M1063.68 338.032C1061.43 338.032 1059.61 339.857 1059.61 342.109C1059.61 344.36 1061.43 346.185 1063.68 346.185C1065.93 346.185 1067.76 344.36 1067.76 342.109C1067.76 339.857 1065.93 338.032 1063.68 338.032Z" />
        <path d="M1045.75 338.032C1043.5 338.032 1041.68 339.857 1041.68 342.109C1041.68 344.36 1043.5 346.185 1045.75 346.185C1048.01 346.185 1049.83 344.36 1049.83 342.109C1049.83 339.857 1048.01 338.032 1045.75 338.032Z" />
        <path d="M1027.8 338.032C1025.55 338.032 1023.73 339.857 1023.73 342.109C1023.73 344.36 1025.55 346.185 1027.8 346.185C1030.05 346.185 1031.88 344.36 1031.88 342.109C1031.88 339.857 1030.05 338.032 1027.8 338.032Z" />
        <path d="M1009.88 338.032C1007.63 338.032 1005.8 339.857 1005.8 342.109C1005.8 344.36 1007.63 346.185 1009.88 346.185C1012.13 346.185 1013.95 344.36 1013.95 342.109C1013.95 339.857 1012.13 338.032 1009.88 338.032Z" />
        <path d="M991.926 338.032C989.676 338.032 987.852 339.857 987.852 342.109C987.852 344.36 989.676 346.185 991.926 346.185C994.176 346.185 996.001 344.36 996.001 342.109C996.001 339.857 994.176 338.032 991.926 338.032Z" />
        <path d="M973.999 338.032C971.749 338.032 969.925 339.857 969.925 342.109C969.925 344.36 971.749 346.185 973.999 346.185C976.249 346.185 978.074 344.36 978.074 342.109C978.074 339.857 976.249 338.032 973.999 338.032Z" />
        <path d="M1915.82 320.319C1913.7 320.319 1912 322.023 1912 324.148C1912 326.273 1913.7 327.977 1915.82 327.977C1917.95 327.977 1919.65 326.273 1919.65 324.148C1919.65 322.048 1917.95 320.319 1915.82 320.319Z" />
        <path d="M1897.87 320.319C1895.75 320.319 1894.04 322.023 1894.04 324.148C1894.04 326.273 1895.75 327.977 1897.87 327.977C1899.99 327.977 1901.7 326.273 1901.7 324.148C1901.72 322.048 1899.99 320.319 1897.87 320.319Z" />
        <path d="M1879.94 320.319C1877.82 320.319 1876.12 322.023 1876.12 324.148C1876.12 326.273 1877.82 327.977 1879.94 327.977C1882.07 327.977 1883.77 326.273 1883.77 324.148C1883.77 322.048 1882.07 320.319 1879.94 320.319Z" />
        <path d="M1861.99 320.319C1859.87 320.319 1858.16 322.023 1858.16 324.148C1858.16 326.273 1859.87 327.977 1861.99 327.977C1864.12 327.977 1865.82 326.273 1865.82 324.148C1865.84 322.048 1864.12 320.319 1861.99 320.319Z" />
        <path d="M1844.07 320.319C1841.94 320.319 1840.24 322.023 1840.24 324.148C1840.24 326.273 1841.94 327.977 1844.07 327.977C1846.19 327.977 1847.89 326.273 1847.89 324.148C1847.89 322.048 1846.19 320.319 1844.07 320.319Z" />
        <path d="M1826.11 320.319C1823.99 320.319 1822.29 322.023 1822.29 324.148C1822.29 326.273 1823.99 327.977 1826.11 327.977C1828.24 327.977 1829.94 326.273 1829.94 324.148C1829.94 322.048 1828.24 320.319 1826.11 320.319Z" />
        <path d="M1808.19 320.319C1806.06 320.319 1804.36 322.023 1804.36 324.148C1804.36 326.273 1806.06 327.977 1808.19 327.977C1810.31 327.977 1812.01 326.273 1812.01 324.148C1812.01 322.048 1810.31 320.319 1808.19 320.319Z" />
        <path d="M1790.23 320.319C1788.11 320.319 1786.41 322.023 1786.41 324.148C1786.41 326.273 1788.11 327.977 1790.23 327.977C1792.36 327.977 1794.06 326.273 1794.06 324.148C1794.06 322.048 1792.36 320.319 1790.23 320.319Z" />
        <path d="M1772.31 320.319C1770.18 320.319 1768.48 322.023 1768.48 324.148C1768.48 326.273 1770.18 327.977 1772.31 327.977C1774.43 327.977 1776.14 326.273 1776.14 324.148C1776.14 322.048 1774.41 320.319 1772.31 320.319Z" />
        <path d="M1754.36 320.319C1752.23 320.319 1750.53 322.023 1750.53 324.148C1750.53 326.273 1752.23 327.977 1754.36 327.977C1756.48 327.977 1758.18 326.273 1758.18 324.148C1758.18 322.048 1756.48 320.319 1754.36 320.319Z" />
        <path d="M1736.43 320.319C1734.31 320.319 1732.6 322.023 1732.6 324.148C1732.6 326.273 1734.31 327.977 1736.43 327.977C1738.55 327.977 1740.26 326.273 1740.26 324.148C1740.26 322.048 1738.53 320.319 1736.43 320.319Z" />
        <path d="M1718.48 320.319C1716.35 320.319 1714.65 322.023 1714.65 324.148C1714.65 326.273 1716.35 327.977 1718.48 327.977C1720.6 327.977 1722.31 326.273 1722.31 324.148C1722.31 322.048 1720.6 320.319 1718.48 320.319Z" />
        <path d="M1700.55 320.319C1698.43 320.319 1696.72 322.023 1696.72 324.148C1696.72 326.273 1698.43 327.977 1700.55 327.977C1702.67 327.977 1704.38 326.273 1704.38 324.148C1704.38 322.048 1702.65 320.319 1700.55 320.319Z" />
        <path d="M1682.6 320.319C1680.48 320.319 1678.77 322.023 1678.77 324.148C1678.77 326.273 1680.48 327.977 1682.6 327.977C1684.72 327.977 1686.43 326.273 1686.43 324.148C1686.43 322.048 1684.72 320.319 1682.6 320.319Z" />
        <path d="M1664.67 320.319C1662.55 320.319 1660.85 322.023 1660.85 324.148C1660.85 326.273 1662.55 327.977 1664.67 327.977C1666.8 327.977 1668.5 326.273 1668.5 324.148C1668.5 322.048 1666.77 320.319 1664.67 320.319Z" />
        <path d="M1646.72 320.319C1644.6 320.319 1642.9 322.023 1642.9 324.148C1642.9 326.273 1644.6 327.977 1646.72 327.977C1648.85 327.977 1650.55 326.273 1650.55 324.148C1650.55 322.048 1648.85 320.319 1646.72 320.319Z" />
        <path d="M1628.79 320.319C1626.67 320.319 1624.97 322.023 1624.97 324.148C1624.97 326.273 1626.67 327.977 1628.79 327.977C1630.92 327.977 1632.62 326.273 1632.62 324.148C1632.62 322.048 1630.89 320.319 1628.79 320.319Z" />
        <path d="M1610.84 320.319C1608.72 320.319 1607.02 322.023 1607.02 324.148C1607.02 326.273 1608.72 327.977 1610.84 327.977C1612.97 327.977 1614.67 326.273 1614.67 324.148C1614.67 322.048 1612.97 320.319 1610.84 320.319Z" />
        <path d="M1592.92 320.319C1590.79 320.319 1589.09 322.023 1589.09 324.148C1589.09 326.273 1590.79 327.977 1592.92 327.977C1595.04 327.977 1596.74 326.273 1596.74 324.148C1596.74 322.048 1595.02 320.319 1592.92 320.319Z" />
        <path d="M1574.97 320.319C1572.84 320.319 1571.14 322.023 1571.14 324.148C1571.14 326.273 1572.84 327.977 1574.97 327.977C1577.09 327.977 1578.79 326.273 1578.79 324.148C1578.79 322.048 1577.09 320.319 1574.97 320.319Z" />
        <path d="M1557.04 320.319C1554.92 320.319 1553.21 322.023 1553.21 324.148C1553.21 326.273 1554.92 327.977 1557.04 327.977C1559.16 327.977 1560.87 326.273 1560.87 324.148C1560.87 322.048 1559.14 320.319 1557.04 320.319Z" />
        <path d="M1539.09 320.319C1536.96 320.319 1535.26 322.023 1535.26 324.148C1535.26 326.273 1536.96 327.977 1539.09 327.977C1541.21 327.977 1542.92 326.273 1542.92 324.148C1542.92 322.048 1541.21 320.319 1539.09 320.319Z" />
        <path d="M1521.16 320.319C1519.04 320.319 1517.33 322.023 1517.33 324.148C1517.33 326.273 1519.04 327.977 1521.16 327.977C1523.28 327.977 1524.99 326.273 1524.99 324.148C1524.99 322.048 1523.26 320.319 1521.16 320.319Z" />
        <path d="M1503.21 320.319C1501.08 320.319 1499.38 322.023 1499.38 324.148C1499.38 326.273 1501.08 327.977 1503.21 327.977C1505.33 327.977 1507.04 326.273 1507.04 324.148C1507.04 322.048 1505.33 320.319 1503.21 320.319Z" />
        <path d="M1485.26 320.319C1483.14 320.319 1481.43 322.023 1481.43 324.148C1481.43 326.273 1483.14 327.977 1485.26 327.977C1487.38 327.977 1489.09 326.273 1489.09 324.148C1489.11 322.048 1487.38 320.319 1485.26 320.319Z" />
        <path d="M1467.33 320.319C1465.21 320.319 1463.5 322.023 1463.5 324.148C1463.5 326.273 1465.21 327.977 1467.33 327.977C1469.45 327.977 1471.16 326.273 1471.16 324.148C1471.16 322.048 1469.45 320.319 1467.33 320.319Z" />
        <path d="M1449.38 320.319C1447.26 320.319 1445.55 322.023 1445.55 324.148C1445.55 326.273 1447.26 327.977 1449.38 327.977C1451.5 327.977 1453.21 326.273 1453.21 324.148C1453.23 322.048 1451.5 320.319 1449.38 320.319Z" />
        <path d="M1431.45 320.319C1429.33 320.319 1427.63 322.023 1427.63 324.148C1427.63 326.273 1429.33 327.977 1431.45 327.977C1433.58 327.977 1435.28 326.273 1435.28 324.148C1435.28 322.048 1433.58 320.319 1431.45 320.319Z" />
        <path d="M1413.5 320.319C1411.38 320.319 1409.67 322.023 1409.67 324.148C1409.67 326.273 1411.38 327.977 1413.5 327.977C1415.62 327.977 1417.33 326.273 1417.33 324.148C1417.35 322.048 1415.62 320.319 1413.5 320.319Z" />
        <path d="M1395.58 320.319C1393.45 320.319 1391.75 322.023 1391.75 324.148C1391.75 326.273 1393.45 327.977 1395.58 327.977C1397.7 327.977 1399.4 326.273 1399.4 324.148C1399.4 322.048 1397.7 320.319 1395.58 320.319Z" />
        <path d="M1377.62 320.319C1375.5 320.319 1373.8 322.023 1373.8 324.148C1373.8 326.273 1375.5 327.977 1377.62 327.977C1379.75 327.977 1381.45 326.273 1381.45 324.148C1381.45 322.048 1379.75 320.319 1377.62 320.319Z" />
        <path d="M1359.7 320.319C1357.57 320.319 1355.87 322.023 1355.87 324.148C1355.87 326.273 1357.57 327.977 1359.7 327.977C1361.82 327.977 1363.53 326.273 1363.53 324.148C1363.53 322.048 1361.82 320.319 1359.7 320.319Z" />
        <path d="M1341.74 320.319C1339.62 320.319 1337.92 322.023 1337.92 324.148C1337.92 326.273 1339.62 327.977 1341.74 327.977C1343.87 327.977 1345.57 326.273 1345.57 324.148C1345.57 322.048 1343.87 320.319 1341.74 320.319Z" />
        <path d="M1323.82 320.319C1321.69 320.319 1319.99 322.023 1319.99 324.148C1319.99 326.273 1321.69 327.977 1323.82 327.977C1325.94 327.977 1327.64 326.273 1327.64 324.148C1327.64 322.048 1325.92 320.319 1323.82 320.319Z" />
        <path d="M1305.87 320.319C1303.74 320.319 1302.04 322.023 1302.04 324.148C1302.04 326.273 1303.74 327.977 1305.87 327.977C1307.99 327.977 1309.69 326.273 1309.69 324.148C1309.69 322.048 1307.99 320.319 1305.87 320.319Z" />
        <path d="M1287.94 320.319C1285.82 320.319 1284.11 322.023 1284.11 324.148C1284.11 326.273 1285.82 327.977 1287.94 327.977C1290.06 327.977 1291.77 326.273 1291.77 324.148C1291.77 322.048 1290.04 320.319 1287.94 320.319Z" />
        <path d="M1269.99 320.319C1267.86 320.319 1266.16 322.023 1266.16 324.148C1266.16 326.273 1267.86 327.977 1269.99 327.977C1272.11 327.977 1273.82 326.273 1273.82 324.148C1273.82 322.048 1272.11 320.319 1269.99 320.319Z" />
        <path d="M1252.06 320.319C1249.94 320.319 1248.23 322.023 1248.23 324.148C1248.23 326.273 1249.94 327.977 1252.06 327.977C1254.18 327.977 1255.89 326.273 1255.89 324.148C1255.89 322.048 1254.16 320.319 1252.06 320.319Z" />
        <path d="M1234.11 320.319C1231.99 320.319 1230.28 322.023 1230.28 324.148C1230.28 326.273 1231.99 327.977 1234.11 327.977C1236.23 327.977 1237.94 326.273 1237.94 324.148C1237.94 322.048 1236.23 320.319 1234.11 320.319Z" />
        <path d="M1216.18 320.319C1214.06 320.319 1212.36 322.023 1212.36 324.148C1212.36 326.273 1214.06 327.977 1216.18 327.977C1218.31 327.977 1220.01 326.273 1220.01 324.148C1220.01 322.048 1218.28 320.319 1216.18 320.319Z" />
        <path d="M1198.23 320.319C1196.11 320.319 1194.4 322.023 1194.4 324.148C1194.4 326.273 1196.11 327.977 1198.23 327.977C1200.35 327.977 1202.06 326.273 1202.06 324.148C1202.06 322.048 1200.35 320.319 1198.23 320.319Z" />
        <path d="M1180.31 320.319C1178.18 320.319 1176.48 322.023 1176.48 324.148C1176.48 326.273 1178.18 327.977 1180.31 327.977C1182.43 327.977 1184.13 326.273 1184.13 324.148C1184.13 322.048 1182.4 320.319 1180.31 320.319Z" />
        <path d="M1162.35 320.319C1160.23 320.319 1158.53 322.023 1158.53 324.148C1158.53 326.273 1160.23 327.977 1162.35 327.977C1164.48 327.977 1166.18 326.273 1166.18 324.148C1166.18 322.048 1164.48 320.319 1162.35 320.319Z" />
        <path d="M1144.43 320.319C1142.3 320.319 1140.6 322.023 1140.6 324.148C1140.6 326.273 1142.3 327.977 1144.43 327.977C1146.55 327.977 1148.26 326.273 1148.26 324.148C1148.26 322.048 1146.53 320.319 1144.43 320.319Z" />
        <path d="M1126.48 320.319C1124.35 320.319 1122.65 322.023 1122.65 324.148C1122.65 326.273 1124.35 327.977 1126.48 327.977C1128.6 327.977 1130.3 326.273 1130.3 324.148C1130.3 322.048 1128.6 320.319 1126.48 320.319Z" />
        <path d="M1108.55 320.319C1106.43 320.319 1104.72 322.023 1104.72 324.148C1104.72 326.273 1106.43 327.977 1108.55 327.977C1110.67 327.977 1112.38 326.273 1112.38 324.148C1112.38 322.048 1110.65 320.319 1108.55 320.319Z" />
        <path d="M1090.6 320.319C1088.47 320.319 1086.77 322.023 1086.77 324.148C1086.77 326.273 1088.47 327.977 1090.6 327.977C1092.72 327.977 1094.42 326.273 1094.42 324.148C1094.42 322.048 1092.72 320.319 1090.6 320.319Z" />
        <path d="M1072.67 320.319C1070.55 320.319 1068.84 322.023 1068.84 324.148C1068.84 326.273 1070.55 327.977 1072.67 327.977C1074.79 327.977 1076.5 326.273 1076.5 324.148C1076.5 322.048 1074.77 320.319 1072.67 320.319Z" />
        <path d="M1054.72 320.319C1052.6 320.319 1050.89 322.023 1050.89 324.148C1050.89 326.273 1052.6 327.977 1054.72 327.977C1056.84 327.977 1058.55 326.273 1058.55 324.148C1058.55 322.048 1056.84 320.319 1054.72 320.319Z" />
        <path d="M1036.77 320.319C1034.64 320.319 1032.94 322.023 1032.94 324.148C1032.94 326.273 1034.64 327.977 1036.77 327.977C1038.89 327.977 1040.59 326.273 1040.59 324.148C1040.62 322.048 1038.89 320.319 1036.77 320.319Z" />
        <path d="M1018.84 320.319C1016.72 320.319 1015.01 322.023 1015.01 324.148C1015.01 326.273 1016.72 327.977 1018.84 327.977C1020.96 327.977 1022.67 326.273 1022.67 324.148C1022.67 322.048 1020.96 320.319 1018.84 320.319Z" />
        <path d="M1000.89 320.319C998.766 320.319 997.062 322.023 997.062 324.148C997.062 326.273 998.766 327.977 1000.89 327.977C1003.01 327.977 1004.72 326.273 1004.72 324.148C1004.74 322.048 1003.01 320.319 1000.89 320.319Z" />
        <path d="M982.961 320.319C980.838 320.319 979.134 322.023 979.134 324.148C979.134 326.273 980.838 327.977 982.961 327.977C985.085 327.977 986.788 326.273 986.788 324.148C986.788 322.048 985.085 320.319 982.961 320.319Z" />
        <path d="M965.012 320.319C962.888 320.319 961.184 322.023 961.184 324.148C961.184 326.273 962.888 327.977 965.012 327.977C967.135 327.977 968.839 326.273 968.839 324.148C968.864 322.048 967.135 320.319 965.012 320.319Z" />
        <path d="M1906.86 302.383C1904.73 302.383 1903.03 304.087 1903.03 306.212C1903.03 308.336 1904.73 310.041 1906.86 310.041C1908.98 310.041 1910.68 308.336 1910.68 306.212C1910.68 304.087 1908.96 302.383 1906.86 302.383Z" />
        <path d="M1888.91 302.383C1886.78 302.383 1885.08 304.087 1885.08 306.212C1885.08 308.336 1886.78 310.041 1888.91 310.041C1891.03 310.041 1892.73 308.336 1892.73 306.212C1892.73 304.087 1891.03 302.383 1888.91 302.383Z" />
        <path d="M1870.98 302.383C1868.86 302.383 1867.15 304.087 1867.15 306.212C1867.15 308.336 1868.86 310.041 1870.98 310.041C1873.1 310.041 1874.81 308.336 1874.81 306.212C1874.81 304.087 1873.08 302.383 1870.98 302.383Z" />
        <path d="M1853.03 302.382C1850.9 302.382 1849.2 304.087 1849.2 306.212C1849.2 308.336 1850.9 310.041 1853.03 310.041C1855.15 310.041 1856.85 308.336 1856.85 306.212C1856.85 304.087 1855.15 302.382 1853.03 302.382Z" />
        <path d="M1835.1 302.382C1832.98 302.382 1831.27 304.087 1831.27 306.212C1831.27 308.336 1832.98 310.041 1835.1 310.041C1837.23 310.041 1838.93 308.336 1838.93 306.212C1838.93 304.087 1837.2 302.382 1835.1 302.382Z" />
        <path d="M1817.15 302.382C1815.03 302.382 1813.32 304.087 1813.32 306.212C1813.32 308.336 1815.03 310.041 1817.15 310.041C1819.27 310.041 1820.98 308.336 1820.98 306.212C1820.98 304.087 1819.27 302.382 1817.15 302.382Z" />
        <path d="M1799.22 302.382C1797.1 302.382 1795.4 304.087 1795.4 306.212C1795.4 308.336 1797.1 310.041 1799.22 310.041C1801.35 310.041 1803.05 308.336 1803.05 306.212C1803.05 304.087 1801.32 302.382 1799.22 302.382Z" />
        <path d="M1781.27 302.382C1779.15 302.382 1777.44 304.087 1777.44 306.212C1777.44 308.336 1779.15 310.041 1781.27 310.041C1783.39 310.041 1785.1 308.336 1785.1 306.212C1785.1 304.087 1783.39 302.382 1781.27 302.382Z" />
        <path d="M1763.34 302.382C1761.22 302.382 1759.52 304.087 1759.52 306.212C1759.52 308.336 1761.22 310.041 1763.34 310.041C1765.47 310.041 1767.17 308.336 1767.17 306.212C1767.17 304.087 1765.44 302.382 1763.34 302.382Z" />
        <path d="M1745.39 302.382C1743.27 302.382 1741.57 304.087 1741.57 306.212C1741.57 308.336 1743.27 310.041 1745.39 310.041C1747.52 310.041 1749.22 308.336 1749.22 306.212C1749.22 304.087 1747.52 302.382 1745.39 302.382Z" />
        <path d="M1727.47 302.382C1725.34 302.382 1723.64 304.087 1723.64 306.212C1723.64 308.336 1725.34 310.041 1727.47 310.041C1729.59 310.041 1731.29 308.336 1731.29 306.212C1731.29 304.087 1729.57 302.382 1727.47 302.382Z" />
        <path d="M1709.52 302.382C1707.39 302.382 1705.69 304.087 1705.69 306.212C1705.69 308.336 1707.39 310.041 1709.52 310.041C1711.64 310.041 1713.34 308.336 1713.34 306.212C1713.34 304.087 1711.64 302.382 1709.52 302.382Z" />
        <path d="M1691.56 302.382C1689.44 302.382 1687.74 304.087 1687.74 306.212C1687.74 308.336 1689.44 310.041 1691.56 310.041C1693.69 310.041 1695.39 308.336 1695.39 306.212C1695.42 304.087 1693.69 302.382 1691.56 302.382Z" />
        <path d="M1673.64 302.382C1671.51 302.382 1669.81 304.087 1669.81 306.212C1669.81 308.336 1671.51 310.041 1673.64 310.041C1675.76 310.041 1677.47 308.336 1677.47 306.212C1677.47 304.087 1675.76 302.382 1673.64 302.382Z" />
        <path d="M1655.68 302.382C1653.56 302.382 1651.86 304.087 1651.86 306.212C1651.86 308.336 1653.56 310.041 1655.68 310.041C1657.81 310.041 1659.51 308.336 1659.51 306.212C1659.54 304.087 1657.81 302.382 1655.68 302.382Z" />
        <path d="M1637.76 302.382C1635.63 302.382 1633.93 304.087 1633.93 306.212C1633.93 308.336 1635.63 310.041 1637.76 310.041C1639.88 310.041 1641.59 308.336 1641.59 306.212C1641.59 304.087 1639.88 302.382 1637.76 302.382Z" />
        <path d="M1619.81 302.382C1617.68 302.382 1615.98 304.087 1615.98 306.212C1615.98 308.336 1617.68 310.041 1619.81 310.041C1621.93 310.041 1623.63 308.336 1623.63 306.212C1623.63 304.087 1621.93 302.382 1619.81 302.382Z" />
        <path d="M1601.88 302.382C1599.76 302.382 1598.06 304.087 1598.06 306.212C1598.06 308.336 1599.76 310.041 1601.88 310.041C1604.01 310.041 1605.71 308.336 1605.71 306.212C1605.71 304.087 1604.01 302.382 1601.88 302.382Z" />
        <path d="M1583.93 302.382C1581.81 302.382 1580.1 304.087 1580.1 306.212C1580.1 308.336 1581.81 310.041 1583.93 310.041C1586.05 310.041 1587.76 308.336 1587.76 306.212C1587.76 304.087 1586.05 302.382 1583.93 302.382Z" />
        <path d="M1566 302.382C1563.88 302.382 1562.17 304.087 1562.17 306.212C1562.17 308.336 1563.88 310.041 1566 310.041C1568.13 310.041 1569.83 308.336 1569.83 306.212C1569.83 304.087 1568.1 302.382 1566 302.382Z" />
        <path d="M1548.05 302.382C1545.93 302.382 1544.22 304.087 1544.22 306.212C1544.22 308.336 1545.93 310.041 1548.05 310.041C1550.17 310.041 1551.88 308.336 1551.88 306.212C1551.88 304.087 1550.17 302.382 1548.05 302.382Z" />
        <path d="M1530.12 302.382C1528 302.382 1526.3 304.087 1526.3 306.212C1526.3 308.336 1528 310.041 1530.12 310.041C1532.25 310.041 1533.95 308.336 1533.95 306.212C1533.95 304.087 1532.22 302.382 1530.12 302.382Z" />
        <path d="M1512.17 302.382C1510.05 302.382 1508.35 304.087 1508.35 306.212C1508.35 308.336 1510.05 310.041 1512.17 310.041C1514.3 310.041 1516 308.336 1516 306.212C1516 304.087 1514.3 302.382 1512.17 302.382Z" />
        <path d="M1494.25 302.382C1492.12 302.382 1490.42 304.087 1490.42 306.212C1490.42 308.336 1492.12 310.041 1494.25 310.041C1496.37 310.041 1498.07 308.336 1498.07 306.212C1498.07 304.087 1496.34 302.382 1494.25 302.382Z" />
        <path d="M1476.29 302.382C1474.17 302.382 1472.47 304.087 1472.47 306.212C1472.47 308.336 1474.17 310.041 1476.29 310.041C1478.42 310.041 1480.12 308.336 1480.12 306.212C1480.12 304.087 1478.42 302.382 1476.29 302.382Z" />
        <path d="M1458.37 302.382C1456.24 302.382 1454.54 304.087 1454.54 306.212C1454.54 308.336 1456.24 310.041 1458.37 310.041C1460.49 310.041 1462.19 308.336 1462.19 306.212C1462.19 304.087 1460.47 302.382 1458.37 302.382Z" />
        <path d="M1440.42 302.382C1438.29 302.382 1436.59 304.087 1436.59 306.212C1436.59 308.336 1438.29 310.041 1440.42 310.041C1442.54 310.041 1444.24 308.336 1444.24 306.212C1444.24 304.087 1442.54 302.382 1440.42 302.382Z" />
        <path d="M1422.49 302.382C1420.37 302.382 1418.66 304.087 1418.66 306.212C1418.66 308.336 1420.37 310.041 1422.49 310.041C1424.61 310.041 1426.32 308.336 1426.32 306.212C1426.32 304.087 1424.59 302.382 1422.49 302.382Z" />
        <path d="M1404.54 302.382C1402.41 302.382 1400.71 304.087 1400.71 306.212C1400.71 308.336 1402.41 310.041 1404.54 310.041C1406.66 310.041 1408.37 308.336 1408.37 306.212C1408.37 304.087 1406.66 302.382 1404.54 302.382Z" />
        <path d="M1386.61 302.382C1384.49 302.382 1382.78 304.087 1382.78 306.212C1382.78 308.336 1384.49 310.041 1386.61 310.041C1388.73 310.041 1390.44 308.336 1390.44 306.212C1390.44 304.087 1388.71 302.382 1386.61 302.382Z" />
        <path d="M1368.66 302.382C1366.54 302.382 1364.83 304.087 1364.83 306.212C1364.83 308.336 1366.54 310.041 1368.66 310.041C1370.78 310.041 1372.49 308.336 1372.49 306.212C1372.49 304.087 1370.78 302.382 1368.66 302.382Z" />
        <path d="M1350.73 302.382C1348.61 302.382 1346.9 304.087 1346.9 306.212C1346.9 308.336 1348.61 310.041 1350.73 310.041C1352.86 310.041 1354.56 308.336 1354.56 306.212C1354.56 304.087 1352.83 302.382 1350.73 302.382Z" />
        <path d="M1332.78 302.382C1330.66 302.382 1328.96 304.087 1328.96 306.212C1328.96 308.336 1330.66 310.041 1332.78 310.041C1334.91 310.041 1336.61 308.336 1336.61 306.212C1336.61 304.087 1334.91 302.382 1332.78 302.382Z" />
        <path d="M1314.85 302.382C1312.73 302.382 1311.03 304.087 1311.03 306.212C1311.03 308.336 1312.73 310.041 1314.85 310.041C1316.98 310.041 1318.68 308.336 1318.68 306.212C1318.68 304.087 1316.95 302.382 1314.85 302.382Z" />
        <path d="M1296.9 302.382C1294.78 302.382 1293.08 304.087 1293.08 306.212C1293.08 308.336 1294.78 310.041 1296.9 310.041C1299.03 310.041 1300.73 308.336 1300.73 306.212C1300.73 304.087 1299.03 302.382 1296.9 302.382Z" />
        <path d="M1278.95 302.382C1276.83 302.382 1275.12 304.087 1275.12 306.212C1275.12 308.336 1276.83 310.041 1278.95 310.041C1281.08 310.041 1282.78 308.336 1282.78 306.212C1282.8 304.087 1281.08 302.382 1278.95 302.382Z" />
        <path d="M1261.03 302.382C1258.9 302.382 1257.2 304.087 1257.2 306.212C1257.2 308.336 1258.9 310.041 1261.03 310.041C1263.15 310.041 1264.85 308.336 1264.85 306.212C1264.85 304.087 1263.15 302.382 1261.03 302.382Z" />
        <path d="M1243.07 302.382C1240.95 302.382 1239.25 304.087 1239.25 306.212C1239.25 308.336 1240.95 310.041 1243.07 310.041C1245.2 310.041 1246.9 308.336 1246.9 306.212C1246.93 304.087 1245.2 302.382 1243.07 302.382Z" />
        <path d="M1225.15 302.382C1223.02 302.382 1221.32 304.087 1221.32 306.212C1221.32 308.336 1223.02 310.041 1225.15 310.041C1227.27 310.041 1228.97 308.336 1228.97 306.212C1228.97 304.087 1227.27 302.382 1225.15 302.382Z" />
        <path d="M1207.2 302.382C1205.07 302.382 1203.37 304.087 1203.37 306.212C1203.37 308.336 1205.07 310.041 1207.2 310.041C1209.32 310.041 1211.02 308.336 1211.02 306.212C1211.05 304.087 1209.32 302.382 1207.2 302.382Z" />
        <path d="M1189.27 302.382C1187.14 302.382 1185.44 304.087 1185.44 306.212C1185.44 308.336 1187.14 310.041 1189.27 310.041C1191.39 310.041 1193.1 308.336 1193.1 306.212C1193.1 304.087 1191.39 302.382 1189.27 302.382Z" />
        <path d="M1171.32 302.382C1169.19 302.382 1167.49 304.087 1167.49 306.212C1167.49 308.336 1169.19 310.041 1171.32 310.041C1173.44 310.041 1175.14 308.336 1175.14 306.212C1175.17 304.087 1173.44 302.382 1171.32 302.382Z" />
        <path d="M1153.39 302.382C1151.27 302.382 1149.56 304.087 1149.56 306.212C1149.56 308.336 1151.27 310.041 1153.39 310.041C1155.51 310.041 1157.22 308.336 1157.22 306.212C1157.22 304.087 1155.51 302.382 1153.39 302.382Z" />
        <path d="M1135.44 302.382C1133.31 302.382 1131.61 304.087 1131.61 306.212C1131.61 308.336 1133.31 310.041 1135.44 310.041C1137.56 310.041 1139.27 308.336 1139.27 306.212C1139.27 304.087 1137.56 302.382 1135.44 302.382Z" />
        <path d="M1117.51 302.382C1115.39 302.382 1113.69 304.087 1113.69 306.212C1113.69 308.336 1115.39 310.041 1117.51 310.041C1119.64 310.041 1121.34 308.336 1121.34 306.212C1121.34 304.087 1119.61 302.382 1117.51 302.382Z" />
        <path d="M1099.56 302.382C1097.44 302.382 1095.73 304.087 1095.73 306.212C1095.73 308.336 1097.44 310.041 1099.56 310.041C1101.68 310.041 1103.39 308.336 1103.39 306.212C1103.39 304.087 1101.68 302.382 1099.56 302.382Z" />
        <path d="M1081.63 302.382C1079.51 302.382 1077.8 304.087 1077.8 306.212C1077.8 308.336 1079.51 310.041 1081.63 310.041C1083.76 310.041 1085.46 308.336 1085.46 306.212C1085.46 304.087 1083.73 302.382 1081.63 302.382Z" />
        <path d="M1063.68 302.382C1061.56 302.382 1059.86 304.087 1059.86 306.212C1059.86 308.336 1061.56 310.041 1063.68 310.041C1065.81 310.041 1067.51 308.336 1067.51 306.212C1067.51 304.087 1065.81 302.382 1063.68 302.382Z" />
        <path d="M1045.76 302.382C1043.63 302.382 1041.93 304.087 1041.93 306.212C1041.93 308.336 1043.63 310.041 1045.76 310.041C1047.88 310.041 1049.58 308.336 1049.58 306.212C1049.58 304.087 1047.85 302.382 1045.76 302.382Z" />
        <path d="M1027.8 302.382C1025.68 302.382 1023.98 304.087 1023.98 306.212C1023.98 308.336 1025.68 310.041 1027.8 310.041C1029.93 310.041 1031.63 308.336 1031.63 306.212C1031.63 304.087 1029.93 302.382 1027.8 302.382Z" />
        <path d="M1009.88 302.382C1007.75 302.382 1006.05 304.087 1006.05 306.212C1006.05 308.336 1007.75 310.041 1009.88 310.041C1012 310.041 1013.7 308.336 1013.7 306.212C1013.7 304.087 1011.98 302.382 1009.88 302.382Z" />
        <path d="M991.927 302.382C989.803 302.382 988.1 304.087 988.1 306.212C988.1 308.336 989.803 310.041 991.927 310.041C994.051 310.041 995.754 308.336 995.754 306.212C995.754 304.087 994.051 302.382 991.927 302.382Z" />
        <path d="M973.999 302.382C971.875 302.382 970.171 304.087 970.171 306.212C970.171 308.336 971.875 310.041 973.999 310.041C976.122 310.041 977.826 308.336 977.826 306.212C977.826 304.087 976.097 302.382 973.999 302.382Z" />
        <path d="M1915.82 284.892C1913.94 284.892 1912.44 286.399 1912.44 288.277C1912.44 290.154 1913.94 291.661 1915.82 291.661C1917.7 291.661 1919.2 290.154 1919.2 288.277C1919.2 286.399 1917.7 284.892 1915.82 284.892Z" />
        <path d="M1897.87 284.892C1895.99 284.892 1894.49 286.399 1894.49 288.277C1894.49 290.154 1895.99 291.661 1897.87 291.661C1899.75 291.661 1901.25 290.154 1901.25 288.277C1901.25 286.399 1899.75 284.892 1897.87 284.892Z" />
        <path d="M1879.94 284.892C1878.07 284.892 1876.56 286.399 1876.56 288.277C1876.56 290.154 1878.07 291.661 1879.94 291.661C1881.82 291.661 1883.32 290.154 1883.32 288.277C1883.32 286.399 1881.82 284.892 1879.94 284.892Z" />
        <path d="M1861.99 284.892C1860.11 284.892 1858.61 286.399 1858.61 288.277C1858.61 290.154 1860.11 291.661 1861.99 291.661C1863.87 291.661 1865.37 290.154 1865.37 288.277C1865.37 286.399 1863.87 284.892 1861.99 284.892Z" />
        <path d="M1844.06 284.892C1842.19 284.892 1840.68 286.399 1840.68 288.277C1840.68 290.154 1842.19 291.661 1844.06 291.661C1845.94 291.661 1847.45 290.154 1847.45 288.277C1847.45 286.399 1845.94 284.892 1844.06 284.892Z" />
        <path d="M1826.11 284.892C1824.24 284.892 1822.73 286.399 1822.73 288.277C1822.73 290.154 1824.24 291.661 1826.11 291.661C1827.99 291.661 1829.5 290.154 1829.5 288.277C1829.5 286.399 1827.99 284.892 1826.11 284.892Z" />
        <path d="M1808.19 284.892C1806.31 284.892 1804.8 286.399 1804.8 288.277C1804.8 290.154 1806.31 291.661 1808.19 291.661C1810.06 291.661 1811.57 290.154 1811.57 288.277C1811.57 286.399 1810.04 284.892 1808.19 284.892Z" />
        <path d="M1790.24 284.892C1788.36 284.892 1786.85 286.399 1786.85 288.277C1786.85 290.154 1788.36 291.661 1790.24 291.661C1792.11 291.661 1793.62 290.154 1793.62 288.277C1793.62 286.399 1792.11 284.892 1790.24 284.892Z" />
        <path d="M1772.31 284.892C1770.43 284.892 1768.93 286.399 1768.93 288.277C1768.93 290.154 1770.43 291.661 1772.31 291.661C1774.19 291.661 1775.69 290.154 1775.69 288.277C1775.69 286.399 1774.16 284.892 1772.31 284.892Z" />
        <path d="M1754.36 284.892C1752.48 284.892 1750.97 286.399 1750.97 288.277C1750.97 290.154 1752.48 291.661 1754.36 291.661C1756.23 291.661 1757.74 290.154 1757.74 288.277C1757.74 286.399 1756.23 284.892 1754.36 284.892Z" />
        <path d="M1736.43 284.892C1734.55 284.892 1733.05 286.399 1733.05 288.277C1733.05 290.154 1734.55 291.661 1736.43 291.661C1738.31 291.661 1739.81 290.154 1739.81 288.277C1739.81 286.399 1738.28 284.892 1736.43 284.892Z" />
        <path d="M1718.48 284.892C1716.6 284.892 1715.1 286.399 1715.1 288.277C1715.1 290.154 1716.6 291.661 1718.48 291.661C1720.36 291.661 1721.86 290.154 1721.86 288.277C1721.86 286.399 1720.36 284.892 1718.48 284.892Z" />
        <path d="M1700.55 284.892C1698.68 284.892 1697.17 286.399 1697.17 288.277C1697.17 290.154 1698.68 291.661 1700.55 291.661C1702.43 291.661 1703.94 290.154 1703.94 288.277C1703.94 286.399 1702.41 284.892 1700.55 284.892Z" />
        <path d="M1682.6 284.892C1680.72 284.892 1679.22 286.399 1679.22 288.277C1679.22 290.154 1680.72 291.661 1682.6 291.661C1684.48 291.661 1685.98 290.154 1685.98 288.277C1685.98 286.399 1684.48 284.892 1682.6 284.892Z" />
        <path d="M1664.67 284.892C1662.8 284.892 1661.29 286.399 1661.29 288.277C1661.29 290.154 1662.8 291.661 1664.67 291.661C1666.55 291.661 1668.06 290.154 1668.06 288.277C1668.06 286.399 1666.52 284.892 1664.67 284.892Z" />
        <path d="M1646.72 284.892C1644.85 284.892 1643.34 286.399 1643.34 288.277C1643.34 290.154 1644.85 291.661 1646.72 291.661C1648.6 291.661 1650.11 290.154 1650.11 288.277C1650.11 286.399 1648.6 284.892 1646.72 284.892Z" />
        <path d="M1628.79 284.892C1626.92 284.892 1625.41 286.399 1625.41 288.277C1625.41 290.154 1626.92 291.661 1628.79 291.661C1630.67 291.661 1632.18 290.154 1632.18 288.277C1632.18 286.399 1630.65 284.892 1628.79 284.892Z" />
        <path d="M1610.85 284.892C1608.97 284.892 1607.46 286.399 1607.46 288.277C1607.46 290.154 1608.97 291.661 1610.85 291.661C1612.72 291.661 1614.23 290.154 1614.23 288.277C1614.23 286.399 1612.72 284.892 1610.85 284.892Z" />
        <path d="M1592.92 284.892C1591.04 284.892 1589.53 286.399 1589.53 288.277C1589.53 290.154 1591.04 291.661 1592.92 291.661C1594.79 291.661 1596.3 290.154 1596.3 288.277C1596.3 286.399 1594.77 284.892 1592.92 284.892Z" />
        <path d="M1574.97 284.892C1573.09 284.892 1571.58 286.399 1571.58 288.277C1571.58 290.154 1573.09 291.661 1574.97 291.661C1576.84 291.661 1578.35 290.154 1578.35 288.277C1578.35 286.399 1576.84 284.892 1574.97 284.892Z" />
        <path d="M1557.04 284.892C1555.16 284.892 1553.66 286.399 1553.66 288.277C1553.66 290.154 1555.16 291.661 1557.04 291.661C1558.92 291.661 1560.42 290.154 1560.42 288.277C1560.42 286.399 1558.89 284.892 1557.04 284.892Z" />
        <path d="M1539.09 284.892C1537.21 284.892 1535.7 286.399 1535.7 288.277C1535.7 290.154 1537.21 291.661 1539.09 291.661C1540.96 291.661 1542.47 290.154 1542.47 288.277C1542.47 286.399 1540.96 284.892 1539.09 284.892Z" />
        <path d="M1521.16 284.892C1519.29 284.892 1517.78 286.399 1517.78 288.277C1517.78 290.154 1519.29 291.661 1521.16 291.661C1523.04 291.661 1524.54 290.154 1524.54 288.277C1524.54 286.399 1523.01 284.892 1521.16 284.892Z" />
        <path d="M1503.21 284.892C1501.33 284.892 1499.83 286.399 1499.83 288.277C1499.83 290.154 1501.33 291.661 1503.21 291.661C1505.09 291.661 1506.59 290.154 1506.59 288.277C1506.59 286.399 1505.09 284.892 1503.21 284.892Z" />
        <path d="M1485.26 284.892C1483.38 284.892 1481.87 286.399 1481.87 288.277C1481.87 290.154 1483.38 291.661 1485.26 291.661C1487.13 291.661 1488.64 290.154 1488.64 288.277C1488.64 286.399 1487.13 284.892 1485.26 284.892Z" />
        <path d="M1467.33 284.892C1465.45 284.892 1463.95 286.399 1463.95 288.277C1463.95 290.154 1465.45 291.661 1467.33 291.661C1469.21 291.661 1470.71 290.154 1470.71 288.277C1470.71 286.399 1469.21 284.892 1467.33 284.892Z" />
        <path d="M1449.38 284.892C1447.5 284.892 1446 286.399 1446 288.277C1446 290.154 1447.5 291.661 1449.38 291.661C1451.26 291.661 1452.76 290.154 1452.76 288.277C1452.76 286.399 1451.26 284.892 1449.38 284.892Z" />
        <path d="M1431.45 284.892C1429.58 284.892 1428.07 286.399 1428.07 288.277C1428.07 290.154 1429.58 291.661 1431.45 291.661C1433.33 291.661 1434.84 290.154 1434.84 288.277C1434.84 286.399 1433.33 284.892 1431.45 284.892Z" />
        <path d="M1413.5 284.892C1411.62 284.892 1410.12 286.399 1410.12 288.277C1410.12 290.154 1411.62 291.661 1413.5 291.661C1415.38 291.661 1416.88 290.154 1416.88 288.277C1416.88 286.399 1415.38 284.892 1413.5 284.892Z" />
        <path d="M1395.57 284.892C1393.7 284.892 1392.19 286.399 1392.19 288.277C1392.19 290.154 1393.7 291.661 1395.57 291.661C1397.45 291.661 1398.96 290.154 1398.96 288.277C1398.96 286.399 1397.45 284.892 1395.57 284.892Z" />
        <path d="M1377.62 284.892C1375.75 284.892 1374.24 286.399 1374.24 288.277C1374.24 290.154 1375.75 291.661 1377.62 291.661C1379.5 291.661 1381.01 290.154 1381.01 288.277C1381.01 286.399 1379.5 284.892 1377.62 284.892Z" />
        <path d="M1359.7 284.892C1357.82 284.892 1356.31 286.399 1356.31 288.277C1356.31 290.154 1357.82 291.661 1359.7 291.661C1361.57 291.661 1363.08 290.154 1363.08 288.277C1363.08 286.399 1361.55 284.892 1359.7 284.892Z" />
        <path d="M1341.75 284.892C1339.87 284.892 1338.36 286.399 1338.36 288.277C1338.36 290.154 1339.87 291.661 1341.75 291.661C1343.62 291.661 1345.13 290.154 1345.13 288.277C1345.13 286.399 1343.62 284.892 1341.75 284.892Z" />
        <path d="M1323.82 284.892C1321.94 284.892 1320.43 286.399 1320.43 288.277C1320.43 290.154 1321.94 291.661 1323.82 291.661C1325.69 291.661 1327.2 290.154 1327.2 288.277C1327.2 286.399 1325.67 284.892 1323.82 284.892Z" />
        <path d="M1305.87 284.892C1303.99 284.892 1302.48 286.399 1302.48 288.277C1302.48 290.154 1303.99 291.661 1305.87 291.661C1307.74 291.661 1309.25 290.154 1309.25 288.277C1309.25 286.399 1307.74 284.892 1305.87 284.892Z" />
        <path d="M1287.94 284.892C1286.06 284.892 1284.56 286.399 1284.56 288.277C1284.56 290.154 1286.06 291.661 1287.94 291.661C1289.82 291.661 1291.32 290.154 1291.32 288.277C1291.32 286.399 1289.79 284.892 1287.94 284.892Z" />
        <path d="M1269.99 284.892C1268.11 284.892 1266.61 286.399 1266.61 288.277C1266.61 290.154 1268.11 291.661 1269.99 291.661C1271.86 291.661 1273.37 290.154 1273.37 288.277C1273.37 286.399 1271.86 284.892 1269.99 284.892Z" />
        <path d="M1252.06 284.892C1250.19 284.892 1248.68 286.399 1248.68 288.277C1248.68 290.154 1250.19 291.661 1252.06 291.661C1253.94 291.661 1255.44 290.154 1255.44 288.277C1255.44 286.399 1253.91 284.892 1252.06 284.892Z" />
        <path d="M1234.11 284.892C1232.23 284.892 1230.73 286.399 1230.73 288.277C1230.73 290.154 1232.23 291.661 1234.11 291.661C1235.99 291.661 1237.49 290.154 1237.49 288.277C1237.49 286.399 1235.99 284.892 1234.11 284.892Z" />
        <path d="M1216.18 284.892C1214.31 284.892 1212.8 286.399 1212.8 288.277C1212.8 290.154 1214.31 291.661 1216.18 291.661C1218.06 291.661 1219.57 290.154 1219.57 288.277C1219.57 286.399 1218.04 284.892 1216.18 284.892Z" />
        <path d="M1198.23 284.892C1196.35 284.892 1194.85 286.399 1194.85 288.277C1194.85 290.154 1196.35 291.661 1198.23 291.661C1200.11 291.661 1201.61 290.154 1201.61 288.277C1201.61 286.399 1200.11 284.892 1198.23 284.892Z" />
        <path d="M1180.31 284.892C1178.43 284.892 1176.92 286.399 1176.92 288.277C1176.92 290.154 1178.43 291.661 1180.31 291.661C1182.18 291.661 1183.69 290.154 1183.69 288.277C1183.69 286.399 1182.16 284.892 1180.31 284.892Z" />
        <path d="M1162.35 284.892C1160.48 284.892 1158.97 286.399 1158.97 288.277C1158.97 290.154 1160.48 291.661 1162.35 291.661C1164.23 291.661 1165.74 290.154 1165.74 288.277C1165.74 286.399 1164.23 284.892 1162.35 284.892Z" />
        <path d="M1144.43 284.892C1142.55 284.892 1141.04 286.399 1141.04 288.277C1141.04 290.154 1142.55 291.661 1144.43 291.661C1146.3 291.661 1147.81 290.154 1147.81 288.277C1147.81 286.399 1146.28 284.892 1144.43 284.892Z" />
        <path d="M1126.48 284.892C1124.6 284.892 1123.09 286.399 1123.09 288.277C1123.09 290.154 1124.6 291.661 1126.48 291.661C1128.35 291.661 1129.86 290.154 1129.86 288.277C1129.86 286.399 1128.35 284.892 1126.48 284.892Z" />
        <path d="M1108.55 284.892C1106.67 284.892 1105.16 286.399 1105.16 288.277C1105.16 290.154 1106.67 291.661 1108.55 291.661C1110.42 291.661 1111.93 290.154 1111.93 288.277C1111.93 286.399 1110.4 284.892 1108.55 284.892Z" />
        <path d="M1090.6 284.892C1088.72 284.892 1087.21 286.399 1087.21 288.277C1087.21 290.154 1088.72 291.661 1090.6 291.661C1092.47 291.661 1093.98 290.154 1093.98 288.277C1093.98 286.399 1092.47 284.892 1090.6 284.892Z" />
        <path d="M1072.67 284.892C1070.79 284.892 1069.29 286.399 1069.29 288.277C1069.29 290.154 1070.79 291.661 1072.67 291.661C1074.55 291.661 1076.05 290.154 1076.05 288.277C1076.05 286.399 1074.52 284.892 1072.67 284.892Z" />
        <path d="M1054.72 284.892C1052.84 284.892 1051.34 286.399 1051.34 288.277C1051.34 290.154 1052.84 291.661 1054.72 291.661C1056.6 291.661 1058.1 290.154 1058.1 288.277C1058.1 286.399 1056.6 284.892 1054.72 284.892Z" />
        <path d="M1036.77 284.892C1034.89 284.892 1033.38 286.399 1033.38 288.277C1033.38 290.154 1034.89 291.661 1036.77 291.661C1038.64 291.661 1040.15 290.154 1040.15 288.277C1040.15 286.399 1038.64 284.892 1036.77 284.892Z" />
        <path d="M1018.84 284.892C1016.96 284.892 1015.46 286.399 1015.46 288.277C1015.46 290.154 1016.96 291.661 1018.84 291.661C1020.72 291.661 1022.22 290.154 1022.22 288.277C1022.22 286.399 1020.72 284.892 1018.84 284.892Z" />
        <path d="M1000.89 284.892C999.014 284.892 997.507 286.399 997.507 288.277C997.507 290.154 999.014 291.661 1000.89 291.661C1002.77 291.661 1004.27 290.154 1004.27 288.277C1004.27 286.399 1002.77 284.892 1000.89 284.892Z" />
        <path d="M982.962 284.892C981.085 284.892 979.579 286.399 979.579 288.277C979.579 290.154 981.085 291.661 982.962 291.661C984.838 291.661 986.344 290.154 986.344 288.277C986.344 286.399 984.838 284.892 982.962 284.892Z" />
        <path d="M965.011 284.892C963.134 284.892 961.628 286.399 961.628 288.277C961.628 290.154 963.134 291.661 965.011 291.661C966.887 291.661 968.393 290.154 968.393 288.277C968.393 286.399 966.887 284.892 965.011 284.892Z" />
        <path d="M1906.86 266.932C1904.98 266.932 1903.47 268.439 1903.47 270.317C1903.47 272.194 1904.98 273.701 1906.86 273.701C1908.73 273.701 1910.24 272.194 1910.24 270.317C1910.24 268.439 1908.71 266.932 1906.86 266.932Z" />
        <path d="M1888.91 266.932C1887.03 266.932 1885.52 268.439 1885.52 270.317C1885.52 272.194 1887.03 273.701 1888.91 273.701C1890.78 273.701 1892.29 272.194 1892.29 270.317C1892.29 268.439 1890.78 266.932 1888.91 266.932Z" />
        <path d="M1870.98 266.932C1869.1 266.932 1867.6 268.439 1867.6 270.317C1867.6 272.194 1869.1 273.701 1870.98 273.701C1872.86 273.701 1874.36 272.194 1874.36 270.317C1874.36 268.439 1872.83 266.932 1870.98 266.932Z" />
        <path d="M1853.03 266.932C1851.15 266.932 1849.65 268.439 1849.65 270.317C1849.65 272.194 1851.15 273.701 1853.03 273.701C1854.91 273.701 1856.41 272.194 1856.41 270.317C1856.41 268.439 1854.91 266.932 1853.03 266.932Z" />
        <path d="M1835.1 266.932C1833.23 266.932 1831.72 268.439 1831.72 270.317C1831.72 272.194 1833.23 273.701 1835.1 273.701C1836.98 273.701 1838.48 272.194 1838.48 270.317C1838.48 268.439 1836.95 266.932 1835.1 266.932Z" />
        <path d="M1817.15 266.932C1815.27 266.932 1813.77 268.439 1813.77 270.317C1813.77 272.194 1815.27 273.701 1817.15 273.701C1819.03 273.701 1820.53 272.194 1820.53 270.317C1820.53 268.439 1819.03 266.932 1817.15 266.932Z" />
        <path d="M1799.22 266.932C1797.35 266.932 1795.84 268.439 1795.84 270.317C1795.84 272.194 1797.35 273.701 1799.22 273.701C1801.1 273.701 1802.61 272.194 1802.61 270.317C1802.61 268.439 1801.08 266.932 1799.22 266.932Z" />
        <path d="M1781.27 266.932C1779.39 266.932 1777.89 268.439 1777.89 270.317C1777.89 272.194 1779.39 273.701 1781.27 273.701C1783.15 273.701 1784.65 272.194 1784.65 270.317C1784.65 268.439 1783.15 266.932 1781.27 266.932Z" />
        <path d="M1763.35 266.932C1761.47 266.932 1759.96 268.439 1759.96 270.317C1759.96 272.194 1761.47 273.701 1763.35 273.701C1765.22 273.701 1766.73 272.194 1766.73 270.317C1766.73 268.439 1765.2 266.932 1763.35 266.932Z" />
        <path d="M1745.39 266.932C1743.52 266.932 1742.01 268.439 1742.01 270.317C1742.01 272.194 1743.52 273.701 1745.39 273.701C1747.27 273.701 1748.78 272.194 1748.78 270.317C1748.78 268.439 1747.27 266.932 1745.39 266.932Z" />
        <path d="M1727.47 266.932C1725.59 266.932 1724.08 268.439 1724.08 270.317C1724.08 272.194 1725.59 273.701 1727.47 273.701C1729.34 273.701 1730.85 272.194 1730.85 270.317C1730.83 268.439 1729.32 266.932 1727.47 266.932Z" />
        <path d="M1709.51 266.932C1707.64 266.932 1706.13 268.439 1706.13 270.317C1706.13 272.194 1707.64 273.701 1709.51 273.701C1711.39 273.701 1712.9 272.194 1712.9 270.317C1712.9 268.439 1711.39 266.932 1709.51 266.932Z" />
        <path d="M1691.56 266.932C1689.69 266.932 1688.18 268.439 1688.18 270.317C1688.18 272.194 1689.69 273.701 1691.56 273.701C1693.44 273.701 1694.95 272.194 1694.95 270.317C1694.95 268.439 1693.44 266.932 1691.56 266.932Z" />
        <path d="M1673.64 266.932C1671.76 266.932 1670.25 268.439 1670.25 270.317C1670.25 272.194 1671.76 273.701 1673.64 273.701C1675.51 273.701 1677.02 272.194 1677.02 270.317C1677.02 268.439 1675.51 266.932 1673.64 266.932Z" />
        <path d="M1655.69 266.932C1653.81 266.932 1652.3 268.439 1652.3 270.317C1652.3 272.194 1653.81 273.701 1655.69 273.701C1657.56 273.701 1659.07 272.194 1659.07 270.317C1659.07 268.439 1657.56 266.932 1655.69 266.932Z" />
        <path d="M1637.76 266.932C1635.88 266.932 1634.37 268.439 1634.37 270.317C1634.37 272.194 1635.88 273.701 1637.76 273.701C1639.63 273.701 1641.14 272.194 1641.14 270.317C1641.14 268.439 1639.63 266.932 1637.76 266.932Z" />
        <path d="M1619.81 266.932C1617.93 266.932 1616.43 268.439 1616.43 270.317C1616.43 272.194 1617.93 273.701 1619.81 273.701C1621.68 273.701 1623.19 272.194 1623.19 270.317C1623.19 268.439 1621.68 266.932 1619.81 266.932Z" />
        <path d="M1601.88 266.932C1600 266.932 1598.5 268.439 1598.5 270.317C1598.5 272.194 1600 273.701 1601.88 273.701C1603.76 273.701 1605.26 272.194 1605.26 270.317C1605.26 268.439 1603.76 266.932 1601.88 266.932Z" />
        <path d="M1583.93 266.932C1582.05 266.932 1580.55 268.439 1580.55 270.317C1580.55 272.194 1582.05 273.701 1583.93 273.701C1585.81 273.701 1587.31 272.194 1587.31 270.317C1587.31 268.439 1585.81 266.932 1583.93 266.932Z" />
        <path d="M1566 266.932C1564.13 266.932 1562.62 268.439 1562.62 270.317C1562.62 272.194 1564.13 273.701 1566 273.701C1567.88 273.701 1569.38 272.194 1569.38 270.317C1569.38 268.439 1567.85 266.932 1566 266.932Z" />
        <path d="M1548.05 266.932C1546.18 266.932 1544.67 268.439 1544.67 270.317C1544.67 272.194 1546.18 273.701 1548.05 273.701C1549.93 273.701 1551.44 272.194 1551.44 270.317C1551.44 268.439 1549.93 266.932 1548.05 266.932Z" />
        <path d="M1530.12 266.932C1528.25 266.932 1526.74 268.439 1526.74 270.317C1526.74 272.194 1528.25 273.701 1530.12 273.701C1532 273.701 1533.51 272.194 1533.51 270.317C1533.51 268.439 1531.98 266.932 1530.12 266.932Z" />
        <path d="M1512.17 266.932C1510.29 266.932 1508.79 268.439 1508.79 270.317C1508.79 272.194 1510.29 273.701 1512.17 273.701C1514.05 273.701 1515.55 272.194 1515.55 270.317C1515.55 268.439 1514.05 266.932 1512.17 266.932Z" />
        <path d="M1494.25 266.932C1492.37 266.932 1490.86 268.439 1490.86 270.317C1490.86 272.194 1492.37 273.701 1494.25 273.701C1496.12 273.701 1497.63 272.194 1497.63 270.317C1497.63 268.439 1496.1 266.932 1494.25 266.932Z" />
        <path d="M1476.3 266.932C1474.42 266.932 1472.91 268.439 1472.91 270.317C1472.91 272.194 1474.42 273.701 1476.3 273.701C1478.17 273.701 1479.68 272.194 1479.68 270.317C1479.68 268.439 1478.17 266.932 1476.3 266.932Z" />
        <path d="M1458.37 266.932C1456.49 266.932 1454.99 268.439 1454.99 270.317C1454.99 272.194 1456.49 273.701 1458.37 273.701C1460.25 273.701 1461.75 272.194 1461.75 270.317C1461.75 268.439 1460.22 266.932 1458.37 266.932Z" />
        <path d="M1440.42 266.932C1438.54 266.932 1437.03 268.439 1437.03 270.317C1437.03 272.194 1438.54 273.701 1440.42 273.701C1442.29 273.701 1443.8 272.194 1443.8 270.317C1443.8 268.439 1442.29 266.932 1440.42 266.932Z" />
        <path d="M1422.49 266.932C1420.61 266.932 1419.11 268.439 1419.11 270.317C1419.11 272.194 1420.61 273.701 1422.49 273.701C1424.37 273.701 1425.87 272.194 1425.87 270.317C1425.87 268.439 1424.34 266.932 1422.49 266.932Z" />
        <path d="M1404.54 266.932C1402.66 266.932 1401.16 268.439 1401.16 270.317C1401.16 272.194 1402.66 273.701 1404.54 273.701C1406.42 273.701 1407.92 272.194 1407.92 270.317C1407.92 268.439 1406.42 266.932 1404.54 266.932Z" />
        <path d="M1386.61 266.932C1384.74 266.932 1383.23 268.439 1383.23 270.317C1383.23 272.194 1384.74 273.701 1386.61 273.701C1388.49 273.701 1389.99 272.194 1389.99 270.317C1389.99 268.439 1388.46 266.932 1386.61 266.932Z" />
        <path d="M1368.66 266.932C1366.78 266.932 1365.28 268.439 1365.28 270.317C1365.28 272.194 1366.78 273.701 1368.66 273.701C1370.54 273.701 1372.04 272.194 1372.04 270.317C1372.04 268.439 1370.54 266.932 1368.66 266.932Z" />
        <path d="M1350.73 266.932C1348.86 266.932 1347.35 268.439 1347.35 270.317C1347.35 272.194 1348.86 273.701 1350.73 273.701C1352.61 273.701 1354.12 272.194 1354.12 270.317C1354.12 268.439 1352.58 266.932 1350.73 266.932Z" />
        <path d="M1332.78 266.932C1330.9 266.932 1329.4 268.439 1329.4 270.317C1329.4 272.194 1330.9 273.701 1332.78 273.701C1334.66 273.701 1336.16 272.194 1336.16 270.317C1336.16 268.439 1334.66 266.932 1332.78 266.932Z" />
        <path d="M1314.85 266.932C1312.98 266.932 1311.47 268.439 1311.47 270.317C1311.47 272.194 1312.98 273.701 1314.85 273.701C1316.73 273.701 1318.24 272.194 1318.24 270.317C1318.24 268.439 1316.71 266.932 1314.85 266.932Z" />
        <path d="M1296.9 266.932C1295.03 266.932 1293.52 268.439 1293.52 270.317C1293.52 272.194 1295.03 273.701 1296.9 273.701C1298.78 273.701 1300.29 272.194 1300.29 270.317C1300.29 268.439 1298.78 266.932 1296.9 266.932Z" />
        <path d="M1278.95 266.932C1277.08 266.932 1275.57 268.439 1275.57 270.317C1275.57 272.194 1277.08 273.701 1278.95 273.701C1280.83 273.701 1282.34 272.194 1282.34 270.317C1282.34 268.439 1280.83 266.932 1278.95 266.932Z" />
        <path d="M1261.02 266.932C1259.15 266.932 1257.64 268.439 1257.64 270.317C1257.64 272.194 1259.15 273.701 1261.02 273.701C1262.9 273.701 1264.41 272.194 1264.41 270.317C1264.41 268.439 1262.9 266.932 1261.02 266.932Z" />
        <path d="M1243.07 266.932C1241.19 266.932 1239.69 268.439 1239.69 270.317C1239.69 272.194 1241.19 273.701 1243.07 273.701C1244.95 273.701 1246.45 272.194 1246.45 270.317C1246.45 268.439 1244.95 266.932 1243.07 266.932Z" />
        <path d="M1225.15 266.932C1223.27 266.932 1221.76 268.439 1221.76 270.317C1221.76 272.194 1223.27 273.701 1225.15 273.701C1227.02 273.701 1228.53 272.194 1228.53 270.317C1228.53 268.439 1227.02 266.932 1225.15 266.932Z" />
        <path d="M1207.2 266.932C1205.32 266.932 1203.81 268.439 1203.81 270.317C1203.81 272.194 1205.32 273.701 1207.2 273.701C1209.07 273.701 1210.58 272.194 1210.58 270.317C1210.58 268.439 1209.07 266.932 1207.2 266.932Z" />
        <path d="M1189.27 266.932C1187.39 266.932 1185.89 268.439 1185.89 270.317C1185.89 272.194 1187.39 273.701 1189.27 273.701C1191.15 273.701 1192.65 272.194 1192.65 270.317C1192.65 268.439 1191.15 266.932 1189.27 266.932Z" />
        <path d="M1171.32 266.932C1169.44 266.932 1167.93 268.439 1167.93 270.317C1167.93 272.194 1169.44 273.701 1171.32 273.701C1173.19 273.701 1174.7 272.194 1174.7 270.317C1174.7 268.439 1173.19 266.932 1171.32 266.932Z" />
        <path d="M1153.39 266.932C1151.51 266.932 1150.01 268.439 1150.01 270.316C1150.01 272.194 1151.51 273.701 1153.39 273.701C1155.27 273.701 1156.77 272.194 1156.77 270.316C1156.77 268.439 1155.27 266.932 1153.39 266.932Z" />
        <path d="M1135.44 266.932C1133.56 266.932 1132.06 268.439 1132.06 270.316C1132.06 272.194 1133.56 273.701 1135.44 273.701C1137.31 273.701 1138.82 272.194 1138.82 270.316C1138.82 268.439 1137.31 266.932 1135.44 266.932Z" />
        <path d="M1117.51 266.932C1115.64 266.932 1114.13 268.439 1114.13 270.316C1114.13 272.194 1115.64 273.701 1117.51 273.701C1119.39 273.701 1120.9 272.194 1120.9 270.316C1120.9 268.439 1119.37 266.932 1117.51 266.932Z" />
        <path d="M1099.56 266.932C1097.68 266.932 1096.18 268.439 1096.18 270.316C1096.18 272.194 1097.68 273.701 1099.56 273.701C1101.44 273.701 1102.94 272.194 1102.94 270.316C1102.94 268.439 1101.44 266.932 1099.56 266.932Z" />
        <path d="M1081.63 266.932C1079.76 266.932 1078.25 268.439 1078.25 270.316C1078.25 272.194 1079.76 273.701 1081.63 273.701C1083.51 273.701 1085.02 272.194 1085.02 270.316C1085.02 268.439 1083.49 266.932 1081.63 266.932Z" />
        <path d="M1063.68 266.932C1061.81 266.932 1060.3 268.439 1060.3 270.316C1060.3 272.194 1061.81 273.701 1063.68 273.701C1065.56 273.701 1067.07 272.194 1067.07 270.316C1067.07 268.439 1065.56 266.932 1063.68 266.932Z" />
        <path d="M1045.75 266.932C1043.88 266.932 1042.37 268.439 1042.37 270.316C1042.37 272.194 1043.88 273.701 1045.75 273.701C1047.63 273.701 1049.14 272.194 1049.14 270.316C1049.14 268.439 1047.61 266.932 1045.75 266.932Z" />
        <path d="M1027.81 266.932C1025.93 266.932 1024.42 268.439 1024.42 270.316C1024.42 272.194 1025.93 273.701 1027.81 273.701C1029.68 273.701 1031.19 272.194 1031.19 270.316C1031.19 268.439 1029.68 266.932 1027.81 266.932Z" />
        <path d="M1009.88 266.932C1008 266.932 1006.49 268.439 1006.49 270.316C1006.49 272.194 1008 273.701 1009.88 273.701C1011.75 273.701 1013.26 272.194 1013.26 270.316C1013.26 268.439 1011.73 266.932 1009.88 266.932Z" />
        <path d="M991.926 266.932C990.049 266.932 988.543 268.439 988.543 270.316C988.543 272.194 990.049 273.701 991.926 273.701C993.802 273.701 995.309 272.194 995.309 270.316C995.309 268.439 993.802 266.932 991.926 266.932Z" />
        <path d="M973.999 266.932C972.122 266.932 970.616 268.439 970.616 270.316C970.616 272.194 972.122 273.701 973.999 273.701C975.876 273.701 977.382 272.194 977.382 270.316C977.382 268.439 975.851 266.932 973.999 266.932Z" />
        <path d="M1915.82 249.391C1914.17 249.391 1912.84 250.725 1912.84 252.38C1912.84 254.036 1914.17 255.37 1915.82 255.37C1917.48 255.37 1918.81 254.036 1918.81 252.38C1918.81 250.725 1917.48 249.391 1915.82 249.391Z" />
        <path d="M1897.87 249.391C1896.22 249.391 1894.88 250.725 1894.88 252.38C1894.88 254.036 1896.22 255.37 1897.87 255.37C1899.52 255.37 1900.86 254.036 1900.86 252.38C1900.86 250.725 1899.52 249.391 1897.87 249.391Z" />
        <path d="M1879.94 249.391C1878.29 249.391 1876.95 250.725 1876.95 252.38C1876.95 254.036 1878.29 255.37 1879.94 255.37C1881.6 255.37 1882.93 254.036 1882.93 252.38C1882.93 250.725 1881.6 249.391 1879.94 249.391Z" />
        <path d="M1861.99 249.391C1860.34 249.391 1859 250.725 1859 252.38C1859 254.036 1860.34 255.37 1861.99 255.37C1863.65 255.37 1864.98 254.036 1864.98 252.38C1864.98 250.725 1863.65 249.391 1861.99 249.391Z" />
        <path d="M1844.06 249.391C1842.41 249.391 1841.08 250.725 1841.08 252.38C1841.08 254.036 1842.41 255.37 1844.06 255.37C1845.72 255.37 1847.05 254.036 1847.05 252.38C1847.05 250.725 1845.72 249.391 1844.06 249.391Z" />
        <path d="M1826.11 249.391C1824.46 249.391 1823.13 250.725 1823.13 252.38C1823.13 254.036 1824.46 255.37 1826.11 255.37C1827.77 255.37 1829.1 254.036 1829.1 252.38C1829.1 250.725 1827.77 249.391 1826.11 249.391Z" />
        <path d="M1808.19 249.391C1806.53 249.391 1805.2 250.725 1805.2 252.38C1805.2 254.036 1806.53 255.37 1808.19 255.37C1809.84 255.37 1811.17 254.036 1811.17 252.38C1811.17 250.725 1809.84 249.391 1808.19 249.391Z" />
        <path d="M1790.23 249.391C1788.58 249.391 1787.25 250.725 1787.25 252.38C1787.25 254.036 1788.58 255.37 1790.23 255.37C1791.89 255.37 1793.22 254.036 1793.22 252.38C1793.22 250.725 1791.89 249.391 1790.23 249.391Z" />
        <path d="M1772.31 249.391C1770.65 249.391 1769.32 250.725 1769.32 252.38C1769.32 254.036 1770.65 255.37 1772.31 255.37C1773.96 255.37 1775.3 254.036 1775.3 252.38C1775.3 250.725 1773.96 249.391 1772.31 249.391Z" />
        <path d="M1754.36 249.391C1752.7 249.391 1751.37 250.725 1751.37 252.38C1751.37 254.036 1752.7 255.37 1754.36 255.37C1756.01 255.37 1757.34 254.036 1757.34 252.38C1757.34 250.725 1756.01 249.391 1754.36 249.391Z" />
        <path d="M1736.43 249.391C1734.77 249.391 1733.44 250.725 1733.44 252.38C1733.44 254.036 1734.77 255.37 1736.43 255.37C1738.08 255.37 1739.42 254.036 1739.42 252.38C1739.42 250.725 1738.08 249.391 1736.43 249.391Z" />
        <path d="M1718.48 249.391C1716.82 249.391 1715.49 250.725 1715.49 252.38C1715.49 254.036 1716.82 255.37 1718.48 255.37C1720.13 255.37 1721.47 254.036 1721.47 252.38C1721.47 250.725 1720.13 249.391 1718.48 249.391Z" />
        <path d="M1700.55 249.391C1698.9 249.391 1697.56 250.725 1697.56 252.38C1697.56 254.036 1698.9 255.37 1700.55 255.37C1702.2 255.37 1703.54 254.036 1703.54 252.38C1703.54 250.725 1702.18 249.391 1700.55 249.391Z" />
        <path d="M1682.6 249.391C1680.95 249.391 1679.61 250.725 1679.61 252.38C1679.61 254.036 1680.95 255.37 1682.6 255.37C1684.26 255.37 1685.59 254.036 1685.59 252.38C1685.59 250.725 1684.26 249.391 1682.6 249.391Z" />
        <path d="M1664.67 249.391C1663.02 249.391 1661.69 250.725 1661.69 252.38C1661.69 254.036 1663.02 255.37 1664.67 255.37C1666.33 255.37 1667.66 254.036 1667.66 252.38C1667.66 250.725 1666.3 249.391 1664.67 249.391Z" />
        <path d="M1646.72 249.391C1645.07 249.391 1643.74 250.725 1643.74 252.38C1643.74 254.036 1645.07 255.37 1646.72 255.37C1648.38 255.37 1649.71 254.036 1649.71 252.38C1649.71 250.725 1648.38 249.391 1646.72 249.391Z" />
        <path d="M1628.79 249.391C1627.14 249.391 1625.81 250.725 1625.81 252.38C1625.81 254.036 1627.14 255.37 1628.79 255.37C1630.45 255.37 1631.78 254.036 1631.78 252.38C1631.78 250.725 1630.42 249.391 1628.79 249.391Z" />
        <path d="M1610.85 249.391C1609.19 249.391 1607.86 250.725 1607.86 252.38C1607.86 254.036 1609.19 255.37 1610.85 255.37C1612.5 255.37 1613.83 254.036 1613.83 252.38C1613.83 250.725 1612.5 249.391 1610.85 249.391Z" />
        <path d="M1592.92 249.391C1591.26 249.391 1589.93 250.725 1589.93 252.38C1589.93 254.036 1591.26 255.37 1592.92 255.37C1594.57 255.37 1595.9 254.036 1595.9 252.38C1595.9 250.725 1594.55 249.391 1592.92 249.391Z" />
        <path d="M1574.97 249.391C1573.31 249.391 1571.98 250.725 1571.98 252.38C1571.98 254.036 1573.31 255.37 1574.97 255.37C1576.62 255.37 1577.95 254.036 1577.95 252.38C1577.95 250.725 1576.62 249.391 1574.97 249.391Z" />
        <path d="M1557.04 249.391C1555.38 249.391 1554.05 250.725 1554.05 252.38C1554.05 254.036 1555.38 255.37 1557.04 255.37C1558.69 255.37 1560.03 254.036 1560.03 252.38C1560.03 250.725 1558.67 249.391 1557.04 249.391Z" />
        <path d="M1539.09 249.391C1537.43 249.391 1536.1 250.725 1536.1 252.38C1536.1 254.036 1537.43 255.37 1539.09 255.37C1540.74 255.37 1542.08 254.036 1542.08 252.38C1542.08 250.725 1540.74 249.391 1539.09 249.391Z" />
        <path d="M1521.16 249.391C1519.51 249.391 1518.17 250.725 1518.17 252.38C1518.17 254.036 1519.51 255.37 1521.16 255.37C1522.82 255.37 1524.15 254.036 1524.15 252.38C1524.15 250.725 1522.79 249.391 1521.16 249.391Z" />
        <path d="M1503.21 249.391C1501.56 249.391 1500.22 250.725 1500.22 252.38C1500.22 254.035 1501.56 255.37 1503.21 255.37C1504.86 255.37 1506.2 254.035 1506.2 252.38C1506.2 250.725 1504.86 249.391 1503.21 249.391Z" />
        <path d="M1485.26 249.391C1483.6 249.391 1482.27 250.725 1482.27 252.38C1482.27 254.035 1483.6 255.37 1485.26 255.37C1486.91 255.37 1488.24 254.035 1488.24 252.38C1488.24 250.725 1486.91 249.391 1485.26 249.391Z" />
        <path d="M1467.33 249.391C1465.68 249.391 1464.34 250.725 1464.34 252.38C1464.34 254.035 1465.68 255.37 1467.33 255.37C1468.99 255.37 1470.32 254.035 1470.32 252.38C1470.32 250.725 1468.99 249.391 1467.33 249.391Z" />
        <path d="M1449.38 249.391C1447.73 249.391 1446.39 250.725 1446.39 252.38C1446.39 254.035 1447.73 255.37 1449.38 255.37C1451.03 255.37 1452.37 254.035 1452.37 252.38C1452.37 250.725 1451.03 249.391 1449.38 249.391Z" />
        <path d="M1431.45 249.391C1429.8 249.391 1428.46 250.725 1428.46 252.38C1428.46 254.035 1429.8 255.37 1431.45 255.37C1433.11 255.37 1434.44 254.035 1434.44 252.38C1434.44 250.725 1433.11 249.391 1431.45 249.391Z" />
        <path d="M1413.5 249.391C1411.85 249.391 1410.51 250.725 1410.51 252.38C1410.51 254.035 1411.85 255.37 1413.5 255.37C1415.16 255.37 1416.49 254.035 1416.49 252.38C1416.49 250.725 1415.16 249.391 1413.5 249.391Z" />
        <path d="M1395.57 249.391C1393.92 249.391 1392.59 250.725 1392.59 252.38C1392.59 254.035 1393.92 255.37 1395.57 255.37C1397.23 255.37 1398.56 254.035 1398.56 252.38C1398.56 250.725 1397.23 249.391 1395.57 249.391Z" />
        <path d="M1377.62 249.391C1375.97 249.391 1374.64 250.725 1374.64 252.38C1374.64 254.035 1375.97 255.37 1377.62 255.37C1379.28 255.37 1380.61 254.035 1380.61 252.38C1380.61 250.725 1379.28 249.391 1377.62 249.391Z" />
        <path d="M1359.7 249.391C1358.04 249.391 1356.71 250.725 1356.71 252.38C1356.71 254.035 1358.04 255.37 1359.7 255.37C1361.35 255.37 1362.68 254.035 1362.68 252.38C1362.68 250.725 1361.35 249.391 1359.7 249.391Z" />
        <path d="M1341.75 249.391C1340.09 249.391 1338.76 250.725 1338.76 252.38C1338.76 254.035 1340.09 255.37 1341.75 255.37C1343.4 255.37 1344.73 254.035 1344.73 252.38C1344.73 250.725 1343.4 249.391 1341.75 249.391Z" />
        <path d="M1323.82 249.391C1322.16 249.391 1320.83 250.725 1320.83 252.38C1320.83 254.035 1322.16 255.37 1323.82 255.37C1325.47 255.37 1326.81 254.035 1326.81 252.38C1326.81 250.725 1325.47 249.391 1323.82 249.391Z" />
        <path d="M1305.87 249.391C1304.21 249.391 1302.88 250.725 1302.88 252.38C1302.88 254.035 1304.21 255.37 1305.87 255.37C1307.52 255.37 1308.85 254.035 1308.85 252.38C1308.85 250.725 1307.52 249.391 1305.87 249.391Z" />
        <path d="M1287.94 249.391C1286.28 249.391 1284.95 250.725 1284.95 252.38C1284.95 254.035 1286.28 255.37 1287.94 255.37C1289.59 255.37 1290.93 254.035 1290.93 252.38C1290.93 250.725 1289.59 249.391 1287.94 249.391Z" />
        <path d="M1269.99 249.391C1268.33 249.391 1267 250.725 1267 252.38C1267 254.035 1268.33 255.37 1269.99 255.37C1271.64 255.37 1272.98 254.035 1272.98 252.38C1272.98 250.725 1271.64 249.391 1269.99 249.391Z" />
        <path d="M1252.06 249.391C1250.41 249.391 1249.07 250.725 1249.07 252.38C1249.07 254.035 1250.41 255.37 1252.06 255.37C1253.72 255.37 1255.05 254.035 1255.05 252.38C1255.05 250.725 1253.69 249.391 1252.06 249.391Z" />
        <path d="M1234.11 249.391C1232.46 249.391 1231.12 250.725 1231.12 252.38C1231.12 254.035 1232.46 255.37 1234.11 255.37C1235.77 255.37 1237.1 254.035 1237.1 252.38C1237.1 250.725 1235.77 249.391 1234.11 249.391Z" />
        <path d="M1216.18 249.391C1214.53 249.391 1213.2 250.725 1213.2 252.38C1213.2 254.035 1214.53 255.37 1216.18 255.37C1217.84 255.37 1219.17 254.035 1219.17 252.38C1219.17 250.725 1217.81 249.391 1216.18 249.391Z" />
        <path d="M1198.23 249.391C1196.58 249.391 1195.24 250.725 1195.24 252.38C1195.24 254.035 1196.58 255.37 1198.23 255.37C1199.89 255.37 1201.22 254.035 1201.22 252.38C1201.22 250.725 1199.89 249.391 1198.23 249.391Z" />
        <path d="M1180.3 249.391C1178.65 249.391 1177.32 250.725 1177.32 252.38C1177.32 254.035 1178.65 255.37 1180.3 255.37C1181.96 255.37 1183.29 254.035 1183.29 252.38C1183.29 250.725 1181.93 249.391 1180.3 249.391Z" />
        <path d="M1162.35 249.391C1160.7 249.391 1159.37 250.725 1159.37 252.38C1159.37 254.035 1160.7 255.37 1162.35 255.37C1164.01 255.37 1165.34 254.035 1165.34 252.38C1165.34 250.725 1164.01 249.391 1162.35 249.391Z" />
        <path d="M1144.43 249.391C1142.77 249.391 1141.44 250.725 1141.44 252.38C1141.44 254.035 1142.77 255.37 1144.43 255.37C1146.08 255.37 1147.41 254.035 1147.41 252.38C1147.41 250.725 1146.06 249.391 1144.43 249.391Z" />
        <path d="M1126.48 249.391C1124.82 249.391 1123.49 250.725 1123.49 252.38C1123.49 254.035 1124.82 255.37 1126.48 255.37C1128.13 255.37 1129.46 254.035 1129.46 252.38C1129.46 250.725 1128.13 249.391 1126.48 249.391Z" />
        <path d="M1108.55 249.391C1106.89 249.391 1105.56 250.725 1105.56 252.38C1105.56 254.035 1106.89 255.37 1108.55 255.37C1110.2 255.37 1111.54 254.035 1111.54 252.38C1111.54 250.725 1110.18 249.391 1108.55 249.391Z" />
        <path d="M1090.6 249.391C1088.94 249.391 1087.61 250.725 1087.61 252.38C1087.61 254.035 1088.94 255.37 1090.6 255.37C1092.25 255.37 1093.59 254.035 1093.59 252.38C1093.59 250.725 1092.25 249.391 1090.6 249.391Z" />
        <path d="M1072.67 249.391C1071.02 249.391 1069.68 250.725 1069.68 252.38C1069.68 254.035 1071.02 255.37 1072.67 255.37C1074.33 255.37 1075.66 254.035 1075.66 252.38C1075.66 250.725 1074.3 249.391 1072.67 249.391Z" />
        <path d="M1054.72 249.391C1053.06 249.391 1051.73 250.725 1051.73 252.38C1051.73 254.035 1053.06 255.37 1054.72 255.37C1056.37 255.37 1057.71 254.035 1057.71 252.38C1057.71 250.725 1056.37 249.391 1054.72 249.391Z" />
        <path d="M1036.77 249.391C1035.11 249.391 1033.78 250.725 1033.78 252.38C1033.78 254.035 1035.11 255.37 1036.77 255.37C1038.42 255.37 1039.76 254.035 1039.76 252.38C1039.76 250.725 1038.42 249.391 1036.77 249.391Z" />
        <path d="M1018.84 249.391C1017.19 249.391 1015.85 250.725 1015.85 252.38C1015.85 254.035 1017.19 255.37 1018.84 255.37C1020.5 255.37 1021.83 254.035 1021.83 252.38C1021.83 250.725 1020.5 249.391 1018.84 249.391Z" />
        <path d="M1000.89 249.391C999.236 249.391 997.902 250.725 997.902 252.38C997.902 254.035 999.236 255.37 1000.89 255.37C1002.54 255.37 1003.88 254.035 1003.88 252.38C1003.88 250.725 1002.54 249.391 1000.89 249.391Z" />
        <path d="M982.961 249.391C981.307 249.391 979.973 250.725 979.973 252.38C979.973 254.035 981.307 255.37 982.961 255.37C984.616 255.37 985.949 254.035 985.949 252.38C985.949 250.725 984.616 249.391 982.961 249.391Z" />
        <path d="M965.01 249.391C963.356 249.391 962.022 250.725 962.022 252.38C962.022 254.035 963.356 255.37 965.01 255.37C966.665 255.37 967.998 254.035 967.998 252.38C967.998 250.725 966.665 249.391 965.01 249.391Z" />
        <path d="M1906.86 231.677C1905.35 231.677 1904.12 232.912 1904.12 234.419C1904.12 235.926 1905.35 237.161 1906.86 237.161C1908.36 237.161 1909.6 235.926 1909.6 234.419C1909.57 232.912 1908.36 231.677 1906.86 231.677Z" />
        <path d="M1888.91 231.677C1887.4 231.677 1886.17 232.912 1886.17 234.419C1886.17 235.926 1887.4 237.161 1888.91 237.161C1890.41 237.161 1891.65 235.926 1891.65 234.419C1891.65 232.912 1890.41 231.677 1888.91 231.677Z" />
        <path d="M1870.98 231.677C1869.47 231.677 1868.24 232.912 1868.24 234.419C1868.24 235.926 1869.47 237.161 1870.98 237.161C1872.49 237.161 1873.72 235.926 1873.72 234.419C1873.7 232.912 1872.49 231.677 1870.98 231.677Z" />
        <path d="M1853.03 231.677C1851.52 231.677 1850.29 232.912 1850.29 234.419C1850.29 235.926 1851.52 237.161 1853.03 237.161C1854.53 237.161 1855.77 235.926 1855.77 234.419C1855.77 232.912 1854.53 231.677 1853.03 231.677Z" />
        <path d="M1835.1 231.677C1833.59 231.677 1832.36 232.912 1832.36 234.419C1832.36 235.926 1833.59 237.161 1835.1 237.161C1836.61 237.161 1837.84 235.926 1837.84 234.419C1837.82 232.912 1836.61 231.677 1835.1 231.677Z" />
        <path d="M1817.15 231.677C1815.64 231.677 1814.41 232.912 1814.41 234.419C1814.41 235.926 1815.64 237.161 1817.15 237.161C1818.66 237.161 1819.89 235.926 1819.89 234.419C1819.89 232.912 1818.66 231.677 1817.15 231.677Z" />
        <path d="M1799.22 231.677C1797.72 231.677 1796.48 232.912 1796.48 234.419C1796.48 235.926 1797.72 237.161 1799.22 237.161C1800.73 237.161 1801.96 235.926 1801.96 234.419C1801.94 232.912 1800.73 231.677 1799.22 231.677Z" />
        <path d="M1781.27 231.677C1779.77 231.677 1778.53 232.912 1778.53 234.419C1778.53 235.926 1779.77 237.161 1781.27 237.161C1782.78 237.161 1784.01 235.926 1784.01 234.419C1784.01 232.912 1782.78 231.677 1781.27 231.677Z" />
        <path d="M1763.34 231.677C1761.84 231.677 1760.6 232.912 1760.6 234.419C1760.6 235.926 1761.84 237.161 1763.34 237.161C1764.85 237.161 1766.08 235.926 1766.08 234.419C1766.06 232.912 1764.85 231.677 1763.34 231.677Z" />
        <path d="M1745.39 231.677C1743.89 231.677 1742.65 232.912 1742.65 234.419C1742.65 235.926 1743.89 237.161 1745.39 237.161C1746.9 237.161 1748.14 235.926 1748.14 234.419C1748.14 232.912 1746.9 231.677 1745.39 231.677Z" />
        <path d="M1727.47 231.677C1725.96 231.677 1724.72 232.912 1724.72 234.419C1724.72 235.926 1725.96 237.161 1727.47 237.161C1728.97 237.161 1730.21 235.926 1730.21 234.419C1730.18 232.912 1728.97 231.677 1727.47 231.677Z" />
        <path d="M1709.52 231.677C1708.01 231.677 1706.78 232.912 1706.78 234.419C1706.78 235.926 1708.01 237.161 1709.52 237.161C1711.02 237.161 1712.26 235.926 1712.26 234.419C1712.26 232.912 1711.02 231.677 1709.52 231.677Z" />
        <path d="M1691.56 231.677C1690.06 231.677 1688.82 232.912 1688.82 234.419C1688.82 235.926 1690.06 237.161 1691.56 237.161C1693.07 237.161 1694.3 235.926 1694.3 234.419C1694.3 232.912 1693.09 231.677 1691.56 231.677Z" />
        <path d="M1673.64 231.677C1672.13 231.677 1670.9 232.912 1670.9 234.419C1670.9 235.926 1672.13 237.161 1673.64 237.161C1675.15 237.161 1676.38 235.926 1676.38 234.419C1676.38 232.912 1675.15 231.677 1673.64 231.677Z" />
        <path d="M1655.69 231.677C1654.18 231.677 1652.95 232.912 1652.95 234.419C1652.95 235.926 1654.18 237.161 1655.69 237.161C1657.19 237.161 1658.43 235.926 1658.43 234.419C1658.43 232.912 1657.22 231.677 1655.69 231.677Z" />
        <path d="M1637.76 231.677C1636.25 231.677 1635.02 232.912 1635.02 234.419C1635.02 235.926 1636.25 237.161 1637.76 237.161C1639.26 237.161 1640.5 235.926 1640.5 234.419C1640.5 232.912 1639.26 231.677 1637.76 231.677Z" />
        <path d="M1619.81 231.677C1618.3 231.677 1617.07 232.912 1617.07 234.419C1617.07 235.926 1618.3 237.161 1619.81 237.161C1621.31 237.161 1622.55 235.926 1622.55 234.419C1622.55 232.912 1621.34 231.677 1619.81 231.677Z" />
        <path d="M1601.88 231.677C1600.38 231.677 1599.14 232.912 1599.14 234.419C1599.14 235.926 1600.38 237.161 1601.88 237.161C1603.39 237.161 1604.62 235.926 1604.62 234.419C1604.62 232.912 1603.39 231.677 1601.88 231.677Z" />
        <path d="M1583.93 231.677C1582.42 231.677 1581.19 232.912 1581.19 234.419C1581.19 235.926 1582.42 237.161 1583.93 237.161C1585.44 237.161 1586.67 235.926 1586.67 234.419C1586.67 232.912 1585.46 231.677 1583.93 231.677Z" />
        <path d="M1566 231.677C1564.5 231.677 1563.26 232.912 1563.26 234.419C1563.26 235.926 1564.5 237.161 1566 237.161C1567.51 237.161 1568.74 235.926 1568.74 234.419C1568.74 232.912 1567.51 231.677 1566 231.677Z" />
        <path d="M1548.05 231.677C1546.54 231.677 1545.31 232.912 1545.31 234.419C1545.31 235.926 1546.54 237.161 1548.05 237.161C1549.56 237.161 1550.79 235.926 1550.79 234.419C1550.79 232.912 1549.56 231.677 1548.05 231.677Z" />
        <path d="M1530.12 231.677C1528.62 231.677 1527.38 232.912 1527.38 234.419C1527.38 235.926 1528.62 237.161 1530.12 237.161C1531.63 237.161 1532.87 235.926 1532.87 234.419C1532.87 232.912 1531.63 231.677 1530.12 231.677Z" />
        <path d="M1512.17 231.677C1510.67 231.677 1509.43 232.912 1509.43 234.419C1509.43 235.926 1510.67 237.161 1512.17 237.161C1513.68 237.161 1514.91 235.926 1514.91 234.419C1514.91 232.912 1513.68 231.677 1512.17 231.677Z" />
        <path d="M1494.25 231.677C1492.74 231.677 1491.51 232.912 1491.51 234.419C1491.51 235.926 1492.74 237.161 1494.25 237.161C1495.75 237.161 1496.99 235.926 1496.99 234.419C1496.99 232.912 1495.75 231.677 1494.25 231.677Z" />
        <path d="M1476.29 231.677C1474.79 231.677 1473.55 232.912 1473.55 234.419C1473.55 235.926 1474.79 237.161 1476.29 237.161C1477.8 237.161 1479.03 235.926 1479.03 234.419C1479.03 232.912 1477.8 231.677 1476.29 231.677Z" />
        <path d="M1458.37 231.677C1456.86 231.677 1455.63 232.912 1455.63 234.419C1455.63 235.926 1456.86 237.161 1458.37 237.161C1459.87 237.161 1461.11 235.926 1461.11 234.419C1461.08 232.912 1459.87 231.677 1458.37 231.677Z" />
        <path d="M1440.42 231.677C1438.91 231.677 1437.68 232.912 1437.68 234.419C1437.68 235.926 1438.91 237.161 1440.42 237.161C1441.92 237.161 1443.16 235.926 1443.16 234.419C1443.16 232.912 1441.92 231.677 1440.42 231.677Z" />
        <path d="M1422.49 231.677C1420.98 231.677 1419.75 232.912 1419.75 234.419C1419.75 235.926 1420.98 237.161 1422.49 237.161C1424 237.161 1425.23 235.926 1425.23 234.419C1425.21 232.912 1424 231.677 1422.49 231.677Z" />
        <path d="M1404.54 231.677C1403.03 231.677 1401.8 232.912 1401.8 234.419C1401.8 235.926 1403.03 237.161 1404.54 237.161C1406.04 237.161 1407.28 235.926 1407.28 234.419C1407.28 232.912 1406.04 231.677 1404.54 231.677Z" />
        <path d="M1386.61 231.677C1385.1 231.677 1383.87 232.912 1383.87 234.419C1383.87 235.926 1385.1 237.161 1386.61 237.161C1388.12 237.161 1389.35 235.926 1389.35 234.419C1389.33 232.912 1388.12 231.677 1386.61 231.677Z" />
        <path d="M1368.66 231.677C1367.15 231.677 1365.92 232.912 1365.92 234.419C1365.92 235.926 1367.15 237.161 1368.66 237.161C1370.16 237.161 1371.4 235.926 1371.4 234.419C1371.4 232.912 1370.16 231.677 1368.66 231.677Z" />
        <path d="M1350.73 231.677C1349.23 231.677 1347.99 232.912 1347.99 234.419C1347.99 235.926 1349.23 237.161 1350.73 237.161C1352.24 237.161 1353.47 235.926 1353.47 234.419C1353.45 232.912 1352.24 231.677 1350.73 231.677Z" />
        <path d="M1332.78 231.677C1331.28 231.677 1330.04 232.912 1330.04 234.419C1330.04 235.926 1331.28 237.161 1332.78 237.161C1334.29 237.161 1335.52 235.926 1335.52 234.419C1335.52 232.912 1334.29 231.677 1332.78 231.677Z" />
        <path d="M1314.85 231.677C1313.35 231.677 1312.11 232.912 1312.11 234.419C1312.11 235.926 1313.35 237.161 1314.85 237.161C1316.36 237.161 1317.6 235.926 1317.6 234.419C1317.57 232.912 1316.36 231.677 1314.85 231.677Z" />
        <path d="M1296.9 231.677C1295.4 231.677 1294.16 232.912 1294.16 234.419C1294.16 235.926 1295.4 237.161 1296.9 237.161C1298.41 237.161 1299.64 235.926 1299.64 234.419C1299.64 232.912 1298.41 231.677 1296.9 231.677Z" />
        <path d="M1278.95 231.677C1277.45 231.677 1276.21 232.912 1276.21 234.419C1276.21 235.926 1277.45 237.161 1278.95 237.161C1280.46 237.161 1281.69 235.926 1281.69 234.419C1281.69 232.912 1280.48 231.677 1278.95 231.677Z" />
        <path d="M1261.02 231.677C1259.52 231.677 1258.28 232.912 1258.28 234.419C1258.28 235.926 1259.52 237.161 1261.02 237.161C1262.53 237.161 1263.77 235.926 1263.77 234.419C1263.77 232.912 1262.53 231.677 1261.02 231.677Z" />
        <path d="M1243.07 231.677C1241.57 231.677 1240.33 232.912 1240.33 234.419C1240.33 235.926 1241.57 237.161 1243.07 237.161C1244.58 237.161 1245.81 235.926 1245.81 234.419C1245.81 232.912 1244.6 231.677 1243.07 231.677Z" />
        <path d="M1225.15 231.677C1223.64 231.677 1222.41 232.912 1222.41 234.419C1222.41 235.926 1223.64 237.161 1225.15 237.161C1226.65 237.161 1227.89 235.926 1227.89 234.419C1227.89 232.912 1226.65 231.677 1225.15 231.677Z" />
        <path d="M1207.2 231.677C1205.69 231.677 1204.45 232.912 1204.45 234.419C1204.45 235.926 1205.69 237.161 1207.2 237.161C1208.7 237.161 1209.94 235.926 1209.94 234.419C1209.94 232.912 1208.73 231.677 1207.2 231.677Z" />
        <path d="M1189.27 231.677C1187.76 231.677 1186.53 232.912 1186.53 234.419C1186.53 235.926 1187.76 237.161 1189.27 237.161C1190.78 237.161 1192.01 235.926 1192.01 234.419C1192.01 232.912 1190.78 231.677 1189.27 231.677Z" />
        <path d="M1171.32 231.677C1169.81 231.677 1168.58 232.912 1168.58 234.419C1168.58 235.926 1169.81 237.161 1171.32 237.161C1172.82 237.161 1174.06 235.926 1174.06 234.419C1174.06 232.912 1172.85 231.677 1171.32 231.677Z" />
        <path d="M1153.39 231.677C1151.89 231.677 1150.65 232.912 1150.65 234.419C1150.65 235.926 1151.89 237.161 1153.39 237.161C1154.9 237.161 1156.13 235.926 1156.13 234.419C1156.13 232.912 1154.9 231.677 1153.39 231.677Z" />
        <path d="M1135.44 231.677C1133.93 231.677 1132.7 232.912 1132.7 234.419C1132.7 235.926 1133.93 237.161 1135.44 237.161C1136.94 237.161 1138.18 235.926 1138.18 234.419C1138.18 232.912 1136.97 231.677 1135.44 231.677Z" />
        <path d="M1117.51 231.677C1116.01 231.677 1114.77 232.912 1114.77 234.419C1114.77 235.926 1116.01 237.161 1117.51 237.161C1119.02 237.161 1120.25 235.926 1120.25 234.419C1120.25 232.912 1119.02 231.677 1117.51 231.677Z" />
        <path d="M1099.56 231.677C1098.05 231.677 1096.82 232.912 1096.82 234.419C1096.82 235.926 1098.05 237.161 1099.56 237.161C1101.07 237.161 1102.3 235.926 1102.3 234.419C1102.3 232.912 1101.07 231.677 1099.56 231.677Z" />
        <path d="M1081.63 231.677C1080.13 231.677 1078.89 232.912 1078.89 234.419C1078.89 235.926 1080.13 237.161 1081.63 237.161C1083.14 237.161 1084.37 235.926 1084.37 234.419C1084.37 232.912 1083.14 231.677 1081.63 231.677Z" />
        <path d="M1063.68 231.677C1062.18 231.677 1060.94 232.912 1060.94 234.419C1060.94 235.926 1062.18 237.161 1063.68 237.161C1065.19 237.161 1066.42 235.926 1066.42 234.419C1066.42 232.912 1065.19 231.677 1063.68 231.677Z" />
        <path d="M1045.75 231.677C1044.25 231.677 1043.01 232.912 1043.01 234.419C1043.01 235.926 1044.25 237.161 1045.75 237.161C1047.26 237.161 1048.5 235.926 1048.5 234.419C1048.5 232.912 1047.26 231.677 1045.75 231.677Z" />
        <path d="M1027.8 231.677C1026.3 231.677 1025.06 232.912 1025.06 234.419C1025.06 235.926 1026.3 237.161 1027.8 237.161C1029.31 237.161 1030.54 235.926 1030.54 234.419C1030.54 232.912 1029.31 231.677 1027.8 231.677Z" />
        <path d="M1009.88 231.677C1008.37 231.677 1007.14 232.912 1007.14 234.419C1007.14 235.926 1008.37 237.161 1009.88 237.161C1011.38 237.161 1012.62 235.926 1012.62 234.419C1012.59 232.912 1011.38 231.677 1009.88 231.677Z" />
        <path d="M991.926 231.677C990.42 231.677 989.185 232.912 989.185 234.419C989.185 235.926 990.42 237.161 991.926 237.161C993.432 237.161 994.667 235.926 994.667 234.419C994.667 232.912 993.432 231.677 991.926 231.677Z" />
        <path d="M973.999 231.677C972.493 231.677 971.258 232.912 971.258 234.419C971.258 235.926 972.493 237.161 973.999 237.161C975.506 237.161 976.74 235.926 976.74 234.419C976.716 232.912 975.506 231.677 973.999 231.677Z" />
        <path d="M1915.82 213.742C1914.31 213.742 1913.08 214.977 1913.08 216.484C1913.08 217.991 1914.31 219.226 1915.82 219.226C1917.33 219.226 1918.56 217.991 1918.56 216.484C1918.56 214.952 1917.33 213.742 1915.82 213.742Z" />
        <path d="M1897.87 213.742C1896.36 213.742 1895.13 214.977 1895.13 216.484C1895.13 217.991 1896.36 219.226 1897.87 219.226C1899.38 219.226 1900.61 217.991 1900.61 216.484C1900.61 214.952 1899.4 213.742 1897.87 213.742Z" />
        <path d="M1879.94 213.742C1878.44 213.742 1877.2 214.977 1877.2 216.484C1877.2 217.991 1878.44 219.226 1879.94 219.226C1881.45 219.226 1882.68 217.991 1882.68 216.484C1882.68 214.952 1881.45 213.742 1879.94 213.742Z" />
        <path d="M1861.99 213.741C1860.49 213.741 1859.25 214.977 1859.25 216.484C1859.25 217.991 1860.49 219.226 1861.99 219.226C1863.5 219.226 1864.73 217.991 1864.73 216.484C1864.73 214.952 1863.52 213.741 1861.99 213.741Z" />
        <path d="M1844.06 213.741C1842.56 213.741 1841.32 214.977 1841.32 216.484C1841.32 217.991 1842.56 219.226 1844.06 219.226C1845.57 219.226 1846.81 217.991 1846.81 216.484C1846.81 214.952 1845.57 213.741 1844.06 213.741Z" />
        <path d="M1826.12 213.741C1824.61 213.741 1823.37 214.977 1823.37 216.484C1823.37 217.991 1824.61 219.226 1826.12 219.226C1827.62 219.226 1828.86 217.991 1828.86 216.484C1828.86 214.952 1827.65 213.741 1826.12 213.741Z" />
        <path d="M1808.19 213.741C1806.68 213.741 1805.45 214.977 1805.45 216.484C1805.45 217.991 1806.68 219.226 1808.19 219.226C1809.69 219.226 1810.93 217.991 1810.93 216.484C1810.93 214.952 1809.69 213.741 1808.19 213.741Z" />
        <path d="M1790.23 213.741C1788.73 213.741 1787.49 214.977 1787.49 216.484C1787.49 217.991 1788.73 219.226 1790.23 219.226C1791.74 219.226 1792.98 217.991 1792.98 216.484C1792.98 214.952 1791.77 213.741 1790.23 213.741Z" />
        <path d="M1772.31 213.741C1770.8 213.741 1769.57 214.977 1769.57 216.484C1769.57 217.991 1770.8 219.226 1772.31 219.226C1773.82 219.226 1775.05 217.991 1775.05 216.484C1775.05 214.952 1773.82 213.741 1772.31 213.741Z" />
        <path d="M1754.36 213.741C1752.85 213.741 1751.62 214.977 1751.62 216.484C1751.62 217.991 1752.85 219.226 1754.36 219.226C1755.86 219.226 1757.1 217.991 1757.1 216.484C1757.1 214.952 1755.86 213.741 1754.36 213.741Z" />
        <path d="M1736.43 213.741C1734.93 213.741 1733.69 214.977 1733.69 216.484C1733.69 217.991 1734.93 219.226 1736.43 219.226C1737.94 219.226 1739.17 217.991 1739.17 216.484C1739.17 214.952 1737.94 213.741 1736.43 213.741Z" />
        <path d="M1718.48 213.741C1716.97 213.741 1715.74 214.977 1715.74 216.484C1715.74 217.991 1716.97 219.226 1718.48 219.226C1719.99 219.226 1721.22 217.991 1721.22 216.484C1721.22 214.952 1719.99 213.741 1718.48 213.741Z" />
        <path d="M1700.55 213.741C1699.05 213.741 1697.81 214.977 1697.81 216.484C1697.81 217.991 1699.05 219.226 1700.55 219.226C1702.06 219.226 1703.29 217.991 1703.29 216.484C1703.29 214.952 1702.06 213.741 1700.55 213.741Z" />
        <path d="M1682.6 213.741C1681.09 213.741 1679.86 214.977 1679.86 216.484C1679.86 217.991 1681.09 219.226 1682.6 219.226C1684.11 219.226 1685.34 217.991 1685.34 216.484C1685.34 214.952 1684.11 213.741 1682.6 213.741Z" />
        <path d="M1664.67 213.741C1663.17 213.741 1661.93 214.977 1661.93 216.484C1661.93 217.991 1663.17 219.226 1664.67 219.226C1666.18 219.226 1667.41 217.991 1667.41 216.484C1667.39 214.952 1666.18 213.741 1664.67 213.741Z" />
        <path d="M1646.72 213.742C1645.22 213.742 1643.98 214.977 1643.98 216.484C1643.98 217.991 1645.22 219.226 1646.72 219.226C1648.23 219.226 1649.46 217.991 1649.46 216.484C1649.46 214.952 1648.23 213.742 1646.72 213.742Z" />
        <path d="M1628.8 213.742C1627.29 213.742 1626.05 214.977 1626.05 216.484C1626.05 217.991 1627.29 219.226 1628.8 219.226C1630.3 219.226 1631.54 217.991 1631.54 216.484C1631.51 214.952 1630.3 213.742 1628.8 213.742Z" />
        <path d="M1610.84 213.741C1609.34 213.741 1608.1 214.977 1608.1 216.484C1608.1 217.991 1609.34 219.226 1610.84 219.226C1612.35 219.226 1613.58 217.991 1613.58 216.484C1613.58 214.952 1612.35 213.741 1610.84 213.741Z" />
        <path d="M1592.92 213.741C1591.41 213.741 1590.18 214.977 1590.18 216.484C1590.18 217.991 1591.41 219.226 1592.92 219.226C1594.42 219.226 1595.66 217.991 1595.66 216.484C1595.63 214.952 1594.42 213.741 1592.92 213.741Z" />
        <path d="M1574.96 213.741C1573.46 213.741 1572.22 214.977 1572.22 216.484C1572.22 217.991 1573.46 219.226 1574.96 219.226C1576.47 219.226 1577.71 217.991 1577.71 216.484C1577.71 214.952 1576.47 213.741 1574.96 213.741Z" />
        <path d="M1557.04 213.741C1555.53 213.741 1554.3 214.977 1554.3 216.484C1554.3 217.991 1555.53 219.226 1557.04 219.226C1558.55 219.226 1559.78 217.991 1559.78 216.484C1559.76 214.952 1558.55 213.741 1557.04 213.741Z" />
        <path d="M1539.09 213.741C1537.58 213.741 1536.35 214.977 1536.35 216.484C1536.35 217.991 1537.58 219.226 1539.09 219.226C1540.59 219.226 1541.83 217.991 1541.83 216.484C1541.83 214.952 1540.59 213.741 1539.09 213.741Z" />
        <path d="M1521.16 213.741C1519.65 213.741 1518.42 214.977 1518.42 216.484C1518.42 217.991 1519.65 219.226 1521.16 219.226C1522.66 219.226 1523.9 217.991 1523.9 216.484C1523.87 214.952 1522.66 213.741 1521.16 213.741Z" />
        <path d="M1503.21 213.741C1501.7 213.741 1500.47 214.977 1500.47 216.484C1500.47 217.991 1501.7 219.226 1503.21 219.226C1504.72 219.226 1505.95 217.991 1505.95 216.484C1505.95 214.952 1504.72 213.741 1503.21 213.741Z" />
        <path d="M1485.26 213.741C1483.75 213.741 1482.52 214.977 1482.52 216.484C1482.52 217.991 1483.75 219.226 1485.26 219.226C1486.76 219.226 1488 217.991 1488 216.484C1488 214.952 1486.79 213.741 1485.26 213.741Z" />
        <path d="M1467.33 213.741C1465.83 213.741 1464.59 214.977 1464.59 216.484C1464.59 217.991 1465.83 219.226 1467.33 219.226C1468.84 219.226 1470.07 217.991 1470.07 216.484C1470.07 214.952 1468.84 213.741 1467.33 213.741Z" />
        <path d="M1449.38 213.741C1447.87 213.741 1446.64 214.977 1446.64 216.484C1446.64 217.991 1447.87 219.226 1449.38 219.226C1450.89 219.226 1452.12 217.991 1452.12 216.484C1452.12 214.952 1450.91 213.741 1449.38 213.741Z" />
        <path d="M1431.45 213.741C1429.95 213.741 1428.71 214.977 1428.71 216.484C1428.71 217.991 1429.95 219.226 1431.45 219.226C1432.96 219.226 1434.19 217.991 1434.19 216.484C1434.19 214.952 1432.96 213.741 1431.45 213.741Z" />
        <path d="M1413.5 213.742C1411.99 213.742 1410.76 214.977 1410.76 216.484C1410.76 217.991 1411.99 219.226 1413.5 219.226C1415.01 219.226 1416.24 217.991 1416.24 216.484C1416.24 214.952 1415.03 213.742 1413.5 213.742Z" />
        <path d="M1395.57 213.742C1394.07 213.742 1392.83 214.977 1392.83 216.484C1392.83 217.991 1394.07 219.226 1395.57 219.226C1397.08 219.226 1398.32 217.991 1398.32 216.484C1398.32 214.952 1397.08 213.742 1395.57 213.742Z" />
        <path d="M1377.62 213.741C1376.12 213.741 1374.88 214.977 1374.88 216.484C1374.88 217.991 1376.12 219.226 1377.62 219.226C1379.13 219.226 1380.36 217.991 1380.36 216.484C1380.36 214.952 1379.15 213.741 1377.62 213.741Z" />
        <path d="M1359.7 213.741C1358.19 213.741 1356.96 214.977 1356.96 216.484C1356.96 217.991 1358.19 219.226 1359.7 219.226C1361.2 219.226 1362.44 217.991 1362.44 216.484C1362.44 214.952 1361.2 213.741 1359.7 213.741Z" />
        <path d="M1341.74 213.741C1340.24 213.741 1339 214.977 1339 216.484C1339 217.991 1340.24 219.226 1341.74 219.226C1343.25 219.226 1344.48 217.991 1344.48 216.484C1344.48 214.952 1343.28 213.741 1341.74 213.741Z" />
        <path d="M1323.82 213.742C1322.31 213.742 1321.08 214.977 1321.08 216.484C1321.08 217.991 1322.31 219.226 1323.82 219.226C1325.33 219.226 1326.56 217.991 1326.56 216.484C1326.56 214.952 1325.33 213.742 1323.82 213.742Z" />
        <path d="M1305.87 213.742C1304.36 213.742 1303.13 214.977 1303.13 216.484C1303.13 217.991 1304.36 219.226 1305.87 219.226C1307.37 219.226 1308.61 217.991 1308.61 216.484C1308.61 214.952 1307.37 213.742 1305.87 213.742Z" />
        <path d="M1287.94 213.742C1286.43 213.742 1285.2 214.977 1285.2 216.484C1285.2 217.991 1286.43 219.226 1287.94 219.226C1289.45 219.226 1290.68 217.991 1290.68 216.484C1290.68 214.952 1289.45 213.742 1287.94 213.742Z" />
        <path d="M1269.99 213.742C1268.48 213.742 1267.25 214.977 1267.25 216.484C1267.25 217.991 1268.48 219.226 1269.99 219.226C1271.49 219.226 1272.73 217.991 1272.73 216.484C1272.73 214.952 1271.49 213.742 1269.99 213.742Z" />
        <path d="M1252.06 213.742C1250.56 213.742 1249.32 214.977 1249.32 216.484C1249.32 217.991 1250.56 219.226 1252.06 219.226C1253.57 219.226 1254.8 217.991 1254.8 216.484C1254.8 214.952 1253.57 213.742 1252.06 213.742Z" />
        <path d="M1234.11 213.741C1232.6 213.741 1231.37 214.977 1231.37 216.484C1231.37 217.991 1232.6 219.226 1234.11 219.226C1235.62 219.226 1236.85 217.991 1236.85 216.484C1236.85 214.952 1235.62 213.741 1234.11 213.741Z" />
        <path d="M1216.18 213.742C1214.68 213.742 1213.44 214.977 1213.44 216.484C1213.44 217.991 1214.68 219.226 1216.18 219.226C1217.69 219.226 1218.92 217.991 1218.92 216.484C1218.9 214.952 1217.69 213.742 1216.18 213.742Z" />
        <path d="M1198.23 213.741C1196.73 213.741 1195.49 214.977 1195.49 216.484C1195.49 217.991 1196.73 219.226 1198.23 219.226C1199.74 219.226 1200.97 217.991 1200.97 216.484C1200.97 214.952 1199.74 213.741 1198.23 213.741Z" />
        <path d="M1180.3 213.742C1178.8 213.742 1177.56 214.977 1177.56 216.484C1177.56 217.991 1178.8 219.226 1180.3 219.226C1181.81 219.226 1183.05 217.991 1183.05 216.484C1183.02 214.952 1181.81 213.742 1180.3 213.742Z" />
        <path d="M1162.35 213.742C1160.85 213.742 1159.61 214.977 1159.61 216.484C1159.61 217.991 1160.85 219.226 1162.35 219.226C1163.86 219.226 1165.09 217.991 1165.09 216.484C1165.09 214.952 1163.86 213.742 1162.35 213.742Z" />
        <path d="M1144.43 213.742C1142.92 213.742 1141.69 214.977 1141.69 216.484C1141.69 217.991 1142.92 219.226 1144.43 219.226C1145.93 219.226 1147.17 217.991 1147.17 216.484C1147.14 214.952 1145.93 213.742 1144.43 213.742Z" />
        <path d="M1126.47 213.741C1124.97 213.741 1123.73 214.977 1123.73 216.484C1123.73 217.991 1124.97 219.226 1126.47 219.226C1127.98 219.226 1129.22 217.991 1129.22 216.484C1129.22 214.952 1127.98 213.741 1126.47 213.741Z" />
        <path d="M1108.55 213.741C1107.04 213.741 1105.81 214.977 1105.81 216.484C1105.81 217.991 1107.04 219.226 1108.55 219.226C1110.05 219.226 1111.29 217.991 1111.29 216.484C1111.26 214.952 1110.05 213.741 1108.55 213.741Z" />
        <path d="M1090.6 213.742C1089.09 213.742 1087.86 214.977 1087.86 216.484C1087.86 217.991 1089.09 219.226 1090.6 219.226C1092.1 219.226 1093.34 217.991 1093.34 216.484C1093.34 214.952 1092.1 213.742 1090.6 213.742Z" />
        <path d="M1072.67 213.742C1071.16 213.742 1069.93 214.977 1069.93 216.484C1069.93 217.991 1071.16 219.226 1072.67 219.226C1074.18 219.226 1075.41 217.991 1075.41 216.484C1075.39 214.952 1074.18 213.742 1072.67 213.742Z" />
        <path d="M1054.72 213.742C1053.21 213.742 1051.98 214.977 1051.98 216.484C1051.98 217.991 1053.21 219.226 1054.72 219.226C1056.23 219.226 1057.46 217.991 1057.46 216.484C1057.46 214.952 1056.23 213.742 1054.72 213.742Z" />
        <path d="M1036.77 213.741C1035.26 213.741 1034.03 214.977 1034.03 216.484C1034.03 217.991 1035.26 219.226 1036.77 219.226C1038.27 219.226 1039.51 217.991 1039.51 216.484C1039.51 214.952 1038.3 213.741 1036.77 213.741Z" />
        <path d="M1018.84 213.741C1017.33 213.741 1016.1 214.977 1016.1 216.484C1016.1 217.991 1017.33 219.226 1018.84 219.226C1020.35 219.226 1021.58 217.991 1021.58 216.484C1021.58 214.952 1020.35 213.741 1018.84 213.741Z" />
        <path d="M1000.89 213.741C999.384 213.741 998.149 214.977 998.149 216.484C998.149 217.991 999.384 219.226 1000.89 219.226C1002.4 219.226 1003.63 217.991 1003.63 216.484C1003.63 214.952 1002.42 213.741 1000.89 213.741Z" />
        <path d="M982.962 213.741C981.456 213.741 980.221 214.977 980.221 216.484C980.221 217.991 981.456 219.226 982.962 219.226C984.468 219.226 985.703 217.991 985.703 216.484C985.703 214.952 984.468 213.741 982.962 213.741Z" />
        <path d="M965.011 213.741C963.505 213.741 962.27 214.977 962.27 216.484C962.27 217.991 963.505 219.226 965.011 219.226C966.517 219.226 967.752 217.991 967.752 216.484C967.752 214.952 966.542 213.741 965.011 213.741Z" />
        <path d="M1906.86 196.176C1905.57 196.176 1904.51 197.214 1904.51 198.523C1904.51 199.808 1905.55 200.87 1906.86 200.87C1908.14 200.87 1909.2 199.833 1909.2 198.523C1909.18 197.239 1908.14 196.176 1906.86 196.176Z" />
        <path d="M1888.91 196.176C1887.62 196.176 1886.56 197.214 1886.56 198.523C1886.56 199.808 1887.6 200.87 1888.91 200.87C1890.22 200.87 1891.25 199.833 1891.25 198.523C1891.25 197.239 1890.19 196.176 1888.91 196.176Z" />
        <path d="M1870.98 196.176C1869.7 196.176 1868.63 197.214 1868.63 198.523C1868.63 199.808 1869.67 200.87 1870.98 200.87C1872.29 200.87 1873.33 199.833 1873.33 198.523C1873.3 197.238 1872.26 196.176 1870.98 196.176Z" />
        <path d="M1853.03 196.176C1851.74 196.176 1850.68 197.214 1850.68 198.523C1850.68 199.808 1851.72 200.87 1853.03 200.87C1854.34 200.87 1855.37 199.833 1855.37 198.523C1855.37 197.238 1854.31 196.176 1853.03 196.176Z" />
        <path d="M1835.1 196.176C1833.82 196.176 1832.76 197.214 1832.76 198.523C1832.76 199.808 1833.79 200.87 1835.1 200.87C1836.39 200.87 1837.45 199.833 1837.45 198.523C1837.42 197.238 1836.39 196.176 1835.1 196.176Z" />
        <path d="M1817.15 196.176C1815.87 196.176 1814.8 197.214 1814.8 198.523C1814.8 199.808 1815.84 200.87 1817.15 200.87C1818.43 200.87 1819.5 199.833 1819.5 198.523C1819.5 197.238 1818.43 196.176 1817.15 196.176Z" />
        <path d="M1799.22 196.176C1797.94 196.176 1796.88 197.214 1796.88 198.523C1796.88 199.808 1797.92 200.87 1799.22 200.87C1800.51 200.87 1801.57 199.833 1801.57 198.523C1801.55 197.238 1800.51 196.176 1799.22 196.176Z" />
        <path d="M1781.27 196.176C1779.99 196.176 1778.93 197.214 1778.93 198.523C1778.93 199.808 1779.96 200.87 1781.27 200.87C1782.56 200.87 1783.62 199.833 1783.62 198.523C1783.62 197.238 1782.56 196.176 1781.27 196.176Z" />
        <path d="M1763.34 196.176C1762.06 196.176 1761 197.214 1761 198.523C1761 199.808 1762.04 200.87 1763.34 200.87C1764.63 200.87 1765.69 199.833 1765.69 198.523C1765.67 197.238 1764.63 196.176 1763.34 196.176Z" />
        <path d="M1745.39 196.176C1744.11 196.176 1743.05 197.214 1743.05 198.523C1743.05 199.808 1744.09 200.87 1745.39 200.87C1746.68 200.87 1747.74 199.833 1747.74 198.523C1747.74 197.238 1746.68 196.176 1745.39 196.176Z" />
        <path d="M1727.47 196.176C1726.18 196.176 1725.12 197.214 1725.12 198.523C1725.12 199.808 1726.16 200.87 1727.47 200.87C1728.75 200.87 1729.81 199.833 1729.81 198.523C1729.79 197.238 1728.75 196.176 1727.47 196.176Z" />
        <path d="M1709.52 196.176C1708.23 196.176 1707.17 197.214 1707.17 198.523C1707.17 199.808 1708.21 200.87 1709.52 200.87C1710.8 200.87 1711.86 199.833 1711.86 198.523C1711.86 197.238 1710.8 196.176 1709.52 196.176Z" />
        <path d="M1691.56 196.176C1690.28 196.176 1689.22 197.214 1689.22 198.523C1689.22 199.808 1690.25 200.87 1691.56 200.87C1692.85 200.87 1693.91 199.833 1693.91 198.523C1693.91 197.238 1692.87 196.176 1691.56 196.176Z" />
        <path d="M1673.63 196.176C1672.35 196.176 1671.29 197.214 1671.29 198.523C1671.29 199.808 1672.33 200.87 1673.63 200.87C1674.92 200.87 1675.98 199.833 1675.98 198.523C1675.98 197.238 1674.92 196.176 1673.63 196.176Z" />
        <path d="M1655.69 196.176C1654.4 196.176 1653.34 197.214 1653.34 198.523C1653.34 199.808 1654.38 200.87 1655.69 200.87C1656.97 200.87 1658.03 199.833 1658.03 198.523C1658.03 197.238 1656.99 196.176 1655.69 196.176Z" />
        <path d="M1637.76 196.176C1636.48 196.176 1635.41 197.214 1635.41 198.523C1635.41 199.808 1636.45 200.87 1637.76 200.87C1639.04 200.87 1640.1 199.833 1640.1 198.523C1640.1 197.238 1639.04 196.176 1637.76 196.176Z" />
        <path d="M1619.81 196.176C1618.52 196.176 1617.46 197.214 1617.46 198.523C1617.46 199.808 1618.5 200.87 1619.81 200.87C1621.09 200.87 1622.15 199.833 1622.15 198.523C1622.15 197.238 1621.12 196.176 1619.81 196.176Z" />
        <path d="M1601.88 196.176C1600.6 196.176 1599.53 197.214 1599.53 198.523C1599.53 199.808 1600.57 200.87 1601.88 200.87C1603.16 200.87 1604.23 199.833 1604.23 198.523C1604.23 197.238 1603.16 196.176 1601.88 196.176Z" />
        <path d="M1583.93 196.176C1582.64 196.176 1581.58 197.214 1581.58 198.523C1581.58 199.808 1582.62 200.87 1583.93 200.87C1585.21 200.87 1586.27 199.833 1586.27 198.523C1586.27 197.238 1585.24 196.176 1583.93 196.176Z" />
        <path d="M1566 196.176C1564.72 196.176 1563.66 197.214 1563.66 198.523C1563.66 199.808 1564.69 200.87 1566 200.87C1567.29 200.87 1568.35 199.833 1568.35 198.523C1568.35 197.238 1567.29 196.176 1566 196.176Z" />
        <path d="M1548.05 196.176C1546.77 196.176 1545.71 197.214 1545.71 198.523C1545.71 199.808 1546.74 200.87 1548.05 200.87C1549.33 200.87 1550.4 199.833 1550.4 198.523C1550.4 197.238 1549.36 196.176 1548.05 196.176Z" />
        <path d="M1530.12 196.176C1528.84 196.176 1527.78 197.214 1527.78 198.523C1527.78 199.808 1528.82 200.87 1530.12 200.87C1531.41 200.87 1532.47 199.833 1532.47 198.523C1532.45 197.238 1531.41 196.176 1530.12 196.176Z" />
        <path d="M1512.17 196.176C1510.89 196.176 1509.83 197.214 1509.83 198.523C1509.83 199.808 1510.86 200.87 1512.17 200.87C1513.46 200.87 1514.52 199.833 1514.52 198.523C1514.52 197.238 1513.48 196.176 1512.17 196.176Z" />
        <path d="M1494.25 196.176C1492.96 196.176 1491.9 197.214 1491.9 198.523C1491.9 199.808 1492.94 200.87 1494.25 200.87C1495.53 200.87 1496.59 199.833 1496.59 198.523C1496.57 197.238 1495.53 196.176 1494.25 196.176Z" />
        <path d="M1476.29 196.176C1475.01 196.176 1473.95 197.214 1473.95 198.523C1473.95 199.808 1474.99 200.87 1476.29 200.87C1477.58 200.87 1478.64 199.833 1478.64 198.523C1478.64 197.238 1477.6 196.176 1476.29 196.176Z" />
        <path d="M1458.37 196.176C1457.08 196.176 1456.02 197.214 1456.02 198.523C1456.02 199.808 1457.06 200.87 1458.37 200.87C1459.65 200.87 1460.71 199.833 1460.71 198.523C1460.69 197.238 1459.65 196.176 1458.37 196.176Z" />
        <path d="M1440.42 196.176C1439.13 196.176 1438.07 197.214 1438.07 198.523C1438.07 199.808 1439.11 200.87 1440.42 200.87C1441.7 200.87 1442.76 199.833 1442.76 198.523C1442.76 197.238 1441.7 196.176 1440.42 196.176Z" />
        <path d="M1422.49 196.176C1421.21 196.176 1420.15 197.214 1420.15 198.523C1420.15 199.808 1421.18 200.87 1422.49 200.87C1423.78 200.87 1424.84 199.833 1424.84 198.523C1424.81 197.238 1423.78 196.176 1422.49 196.176Z" />
        <path d="M1404.54 196.176C1403.25 196.176 1402.19 197.214 1402.19 198.523C1402.19 199.808 1403.23 200.87 1404.54 200.87C1405.82 200.87 1406.88 199.832 1406.88 198.523C1406.88 197.238 1405.82 196.176 1404.54 196.176Z" />
        <path d="M1386.61 196.176C1385.33 196.176 1384.26 197.214 1384.26 198.523C1384.26 199.808 1385.3 200.87 1386.61 200.87C1387.89 200.87 1388.96 199.833 1388.96 198.523C1388.93 197.238 1387.89 196.176 1386.61 196.176Z" />
        <path d="M1368.66 196.176C1367.37 196.176 1366.31 197.214 1366.31 198.523C1366.31 199.808 1367.35 200.87 1368.66 200.87C1369.94 200.87 1371 199.833 1371 198.523C1371 197.238 1369.94 196.176 1368.66 196.176Z" />
        <path d="M1350.73 196.176C1349.45 196.176 1348.39 197.214 1348.39 198.523C1348.39 199.808 1349.42 200.87 1350.73 200.87C1352.02 200.87 1353.08 199.833 1353.08 198.523C1353.05 197.238 1352.02 196.176 1350.73 196.176Z" />
        <path d="M1332.78 196.176C1331.5 196.176 1330.44 197.214 1330.44 198.523C1330.44 199.808 1331.47 200.87 1332.78 200.87C1334.07 200.87 1335.13 199.833 1335.13 198.523C1335.13 197.238 1334.07 196.176 1332.78 196.176Z" />
        <path d="M1314.85 196.176C1313.57 196.176 1312.51 197.214 1312.51 198.523C1312.51 199.808 1313.55 200.87 1314.85 200.87C1316.14 200.87 1317.2 199.832 1317.2 198.523C1317.18 197.238 1316.14 196.176 1314.85 196.176Z" />
        <path d="M1296.9 196.176C1295.62 196.176 1294.56 197.214 1294.56 198.523C1294.56 199.808 1295.6 200.87 1296.9 200.87C1298.19 200.87 1299.25 199.832 1299.25 198.523C1299.25 197.238 1298.19 196.176 1296.9 196.176Z" />
        <path d="M1278.95 196.176C1277.67 196.176 1276.61 197.214 1276.61 198.523C1276.61 199.808 1277.64 200.87 1278.95 200.87C1280.24 200.87 1281.3 199.833 1281.3 198.523C1281.3 197.238 1280.26 196.176 1278.95 196.176Z" />
        <path d="M1261.02 196.176C1259.74 196.176 1258.68 197.214 1258.68 198.523C1258.68 199.808 1259.72 200.87 1261.02 200.87C1262.31 200.87 1263.37 199.833 1263.37 198.523C1263.37 197.238 1262.31 196.176 1261.02 196.176Z" />
        <path d="M1243.07 196.176C1241.79 196.176 1240.73 197.214 1240.73 198.523C1240.73 199.808 1241.76 200.87 1243.07 200.87C1244.36 200.87 1245.42 199.832 1245.42 198.523C1245.42 197.238 1244.38 196.176 1243.07 196.176Z" />
        <path d="M1225.15 196.176C1223.86 196.176 1222.8 197.214 1222.8 198.523C1222.8 199.808 1223.84 200.87 1225.15 200.87C1226.43 200.87 1227.49 199.832 1227.49 198.523C1227.49 197.238 1226.43 196.176 1225.15 196.176Z" />
        <path d="M1207.2 196.176C1205.91 196.176 1204.85 197.214 1204.85 198.523C1204.85 199.808 1205.89 200.87 1207.2 200.87C1208.48 200.87 1209.54 199.832 1209.54 198.523C1209.54 197.238 1208.5 196.176 1207.2 196.176Z" />
        <path d="M1189.27 196.176C1187.98 196.176 1186.92 197.214 1186.92 198.523C1186.92 199.808 1187.96 200.87 1189.27 200.87C1190.55 200.87 1191.61 199.833 1191.61 198.523C1191.61 197.238 1190.55 196.176 1189.27 196.176Z" />
        <path d="M1171.32 196.176C1170.03 196.176 1168.97 197.214 1168.97 198.523C1168.97 199.808 1170.01 200.87 1171.32 200.87C1172.6 200.87 1173.66 199.833 1173.66 198.523C1173.66 197.238 1172.62 196.176 1171.32 196.176Z" />
        <path d="M1153.39 196.176C1152.11 196.176 1151.05 197.214 1151.05 198.523C1151.05 199.808 1152.08 200.87 1153.39 200.87C1154.67 200.87 1155.74 199.832 1155.74 198.523C1155.74 197.238 1154.67 196.176 1153.39 196.176Z" />
        <path d="M1135.44 196.176C1134.16 196.176 1133.09 197.214 1133.09 198.523C1133.09 199.808 1134.13 200.87 1135.44 200.87C1136.72 200.87 1137.79 199.832 1137.79 198.523C1137.79 197.238 1136.75 196.176 1135.44 196.176Z" />
        <path d="M1117.51 196.176C1116.23 196.176 1115.17 197.214 1115.17 198.523C1115.17 199.808 1116.2 200.87 1117.51 200.87C1118.8 200.87 1119.86 199.832 1119.86 198.523C1119.86 197.238 1118.8 196.176 1117.51 196.176Z" />
        <path d="M1099.56 196.176C1098.28 196.176 1097.21 197.214 1097.21 198.523C1097.21 199.808 1098.25 200.87 1099.56 200.87C1100.84 200.87 1101.91 199.832 1101.91 198.523C1101.91 197.238 1100.87 196.176 1099.56 196.176Z" />
        <path d="M1081.63 196.176C1080.35 196.176 1079.29 197.214 1079.29 198.523C1079.29 199.808 1080.33 200.87 1081.63 200.87C1082.92 200.87 1083.98 199.832 1083.98 198.523C1083.95 197.238 1082.92 196.176 1081.63 196.176Z" />
        <path d="M1063.68 196.176C1062.4 196.176 1061.34 197.214 1061.34 198.523C1061.34 199.808 1062.37 200.87 1063.68 200.87C1064.97 200.87 1066.03 199.832 1066.03 198.523C1066.03 197.238 1064.99 196.176 1063.68 196.176Z" />
        <path d="M1045.76 196.176C1044.47 196.176 1043.41 197.214 1043.41 198.523C1043.41 199.808 1044.45 200.87 1045.76 200.87C1047.04 200.87 1048.1 199.832 1048.1 198.523C1048.08 197.238 1047.04 196.176 1045.76 196.176Z" />
        <path d="M1027.8 196.176C1026.52 196.176 1025.46 197.214 1025.46 198.523C1025.46 199.808 1026.49 200.87 1027.8 200.87C1029.09 200.87 1030.15 199.832 1030.15 198.523C1030.15 197.238 1029.11 196.176 1027.8 196.176Z" />
        <path d="M1009.88 196.176C1008.59 196.176 1007.53 197.214 1007.53 198.523C1007.53 199.808 1008.57 200.87 1009.88 200.87C1011.16 200.87 1012.22 199.832 1012.22 198.523C1012.2 197.238 1011.16 196.176 1009.88 196.176Z" />
        <path d="M991.926 196.176C990.642 196.176 989.58 197.214 989.58 198.523C989.58 199.808 990.617 200.87 991.926 200.87C993.21 200.87 994.272 199.832 994.272 198.523C994.272 197.238 993.21 196.176 991.926 196.176Z" />
        <path d="M973.999 196.176C972.715 196.176 971.653 197.214 971.653 198.523C971.653 199.808 972.691 200.87 973.999 200.87C975.308 200.87 976.345 199.832 976.345 198.523C976.32 197.238 975.283 196.176 973.999 196.176Z" />
        <path d="M1915.82 178.241C1914.54 178.241 1913.47 179.278 1913.47 180.588C1913.47 181.872 1914.51 182.935 1915.82 182.935C1917.1 182.935 1918.17 181.897 1918.17 180.588C1918.17 179.278 1917.1 178.241 1915.82 178.241Z" />
        <path d="M1897.87 178.241C1896.59 178.241 1895.52 179.278 1895.52 180.588C1895.52 181.872 1896.56 182.935 1897.87 182.935C1899.15 182.935 1900.22 181.897 1900.22 180.588C1900.22 179.278 1899.18 178.241 1897.87 178.241Z" />
        <path d="M1879.94 178.241C1878.66 178.241 1877.6 179.278 1877.6 180.588C1877.6 181.872 1878.63 182.935 1879.94 182.935C1881.25 182.935 1882.29 181.897 1882.29 180.588C1882.29 179.278 1881.23 178.241 1879.94 178.241Z" />
        <path d="M1861.99 178.241C1860.71 178.241 1859.65 179.278 1859.65 180.588C1859.65 181.872 1860.68 182.935 1861.99 182.935C1863.3 182.935 1864.34 181.897 1864.34 180.588C1864.34 179.278 1863.3 178.241 1861.99 178.241Z" />
        <path d="M1844.06 178.241C1842.78 178.241 1841.72 179.278 1841.72 180.588C1841.72 181.872 1842.76 182.935 1844.06 182.935C1845.37 182.935 1846.41 181.897 1846.41 180.588C1846.41 179.278 1845.35 178.241 1844.06 178.241Z" />
        <path d="M1826.11 178.241C1824.83 178.241 1823.77 179.278 1823.77 180.588C1823.77 181.872 1824.8 182.935 1826.11 182.935C1827.4 182.935 1828.46 181.897 1828.46 180.588C1828.46 179.278 1827.42 178.241 1826.11 178.241Z" />
        <path d="M1808.19 178.241C1806.9 178.241 1805.84 179.278 1805.84 180.588C1805.84 181.872 1806.88 182.935 1808.19 182.935C1809.47 182.935 1810.53 181.897 1810.53 180.588C1810.53 179.278 1809.47 178.241 1808.19 178.241Z" />
        <path d="M1790.24 178.241C1788.95 178.241 1787.89 179.278 1787.89 180.588C1787.89 181.872 1788.93 182.935 1790.24 182.935C1791.52 182.935 1792.58 181.897 1792.58 180.588C1792.58 179.278 1791.54 178.241 1790.24 178.241Z" />
        <path d="M1772.31 178.241C1771.03 178.241 1769.96 179.278 1769.96 180.588C1769.96 181.872 1771 182.935 1772.31 182.935C1773.59 182.935 1774.65 181.897 1774.65 180.588C1774.63 179.278 1773.59 178.241 1772.31 178.241Z" />
        <path d="M1754.36 178.241C1753.07 178.241 1752.01 179.278 1752.01 180.588C1752.01 181.872 1753.05 182.935 1754.36 182.935C1755.64 182.935 1756.7 181.897 1756.7 180.588C1756.7 179.278 1755.67 178.241 1754.36 178.241Z" />
        <path d="M1736.43 178.241C1735.15 178.241 1734.08 179.278 1734.08 180.588C1734.08 181.872 1735.12 182.935 1736.43 182.935C1737.71 182.935 1738.78 181.897 1738.78 180.588C1738.75 179.278 1737.71 178.241 1736.43 178.241Z" />
        <path d="M1718.48 178.241C1717.19 178.241 1716.13 179.278 1716.13 180.588C1716.13 181.872 1717.17 182.935 1718.48 182.935C1719.76 182.935 1720.82 181.897 1720.82 180.588C1720.82 179.278 1719.79 178.241 1718.48 178.241Z" />
        <path d="M1700.55 178.241C1699.27 178.241 1698.21 179.278 1698.21 180.588C1698.21 181.872 1699.24 182.935 1700.55 182.935C1701.84 182.935 1702.9 181.897 1702.9 180.588C1702.87 179.278 1701.84 178.241 1700.55 178.241Z" />
        <path d="M1682.6 178.241C1681.32 178.241 1680.26 179.278 1680.26 180.588C1680.26 181.872 1681.29 182.935 1682.6 182.935C1683.88 182.935 1684.95 181.897 1684.95 180.588C1684.95 179.278 1683.88 178.241 1682.6 178.241Z" />
        <path d="M1664.67 178.241C1663.39 178.241 1662.33 179.278 1662.33 180.588C1662.33 181.872 1663.37 182.935 1664.67 182.935C1665.96 182.935 1667.02 181.897 1667.02 180.588C1667 179.278 1665.96 178.241 1664.67 178.241Z" />
        <path d="M1646.72 178.241C1645.44 178.241 1644.38 179.278 1644.38 180.588C1644.38 181.872 1645.41 182.935 1646.72 182.935C1648.01 182.935 1649.07 181.897 1649.07 180.588C1649.07 179.278 1648.01 178.241 1646.72 178.241Z" />
        <path d="M1628.79 178.241C1627.51 178.241 1626.45 179.278 1626.45 180.588C1626.45 181.872 1627.49 182.935 1628.79 182.935C1630.08 182.935 1631.14 181.897 1631.14 180.588C1631.12 179.278 1630.08 178.241 1628.79 178.241Z" />
        <path d="M1610.84 178.241C1609.56 178.241 1608.5 179.278 1608.5 180.588C1608.5 181.872 1609.54 182.935 1610.84 182.935C1612.13 182.935 1613.19 181.897 1613.19 180.588C1613.19 179.278 1612.13 178.241 1610.84 178.241Z" />
        <path d="M1592.92 178.241C1591.63 178.241 1590.57 179.278 1590.57 180.588C1590.57 181.872 1591.61 182.935 1592.92 182.935C1594.2 182.935 1595.26 181.897 1595.26 180.588C1595.24 179.278 1594.2 178.241 1592.92 178.241Z" />
        <path d="M1574.97 178.241C1573.68 178.241 1572.62 179.278 1572.62 180.588C1572.62 181.872 1573.66 182.935 1574.97 182.935C1576.25 182.935 1577.31 181.897 1577.31 180.588C1577.31 179.278 1576.25 178.241 1574.97 178.241Z" />
        <path d="M1557.04 178.241C1555.76 178.241 1554.69 179.278 1554.69 180.588C1554.69 181.872 1555.73 182.935 1557.04 182.935C1558.32 182.935 1559.39 181.897 1559.39 180.588C1559.36 179.278 1558.32 178.241 1557.04 178.241Z" />
        <path d="M1539.09 178.241C1537.8 178.241 1536.74 179.278 1536.74 180.588C1536.74 181.872 1537.78 182.935 1539.09 182.935C1540.37 182.935 1541.43 181.897 1541.43 180.588C1541.43 179.278 1540.37 178.241 1539.09 178.241Z" />
        <path d="M1521.16 178.241C1519.88 178.241 1518.82 179.278 1518.82 180.588C1518.82 181.872 1519.85 182.935 1521.16 182.935C1522.45 182.935 1523.51 181.897 1523.51 180.588C1523.48 179.278 1522.45 178.241 1521.16 178.241Z" />
        <path d="M1503.21 178.241C1501.92 178.241 1500.86 179.278 1500.86 180.588C1500.86 181.872 1501.9 182.935 1503.21 182.935C1504.49 182.935 1505.55 181.897 1505.55 180.588C1505.55 179.278 1504.49 178.241 1503.21 178.241Z" />
        <path d="M1485.26 178.241C1483.97 178.241 1482.91 179.278 1482.91 180.588C1482.91 181.872 1483.95 182.935 1485.26 182.935C1486.54 182.935 1487.6 181.897 1487.6 180.588C1487.6 179.278 1486.57 178.241 1485.26 178.241Z" />
        <path d="M1467.33 178.241C1466.05 178.241 1464.99 179.278 1464.99 180.588C1464.99 181.872 1466.02 182.935 1467.33 182.935C1468.62 182.935 1469.68 181.897 1469.68 180.588C1469.68 179.278 1468.62 178.241 1467.33 178.241Z" />
        <path d="M1449.38 178.241C1448.09 178.241 1447.03 179.278 1447.03 180.588C1447.03 181.872 1448.07 182.935 1449.38 182.935C1450.66 182.935 1451.72 181.897 1451.72 180.588C1451.72 179.278 1450.69 178.241 1449.38 178.241Z" />
        <path d="M1431.45 178.241C1430.17 178.241 1429.11 179.278 1429.11 180.588C1429.11 181.872 1430.14 182.935 1431.45 182.935C1432.74 182.935 1433.8 181.897 1433.8 180.588C1433.8 179.278 1432.74 178.241 1431.45 178.241Z" />
        <path d="M1413.5 178.241C1412.22 178.241 1411.15 179.278 1411.15 180.588C1411.15 181.872 1412.19 182.935 1413.5 182.935C1414.78 182.935 1415.85 181.897 1415.85 180.588C1415.85 179.278 1414.81 178.241 1413.5 178.241Z" />
        <path d="M1395.57 178.241C1394.29 178.241 1393.23 179.278 1393.23 180.588C1393.23 181.872 1394.27 182.935 1395.57 182.935C1396.86 182.935 1397.92 181.897 1397.92 180.588C1397.92 179.278 1396.86 178.241 1395.57 178.241Z" />
        <path d="M1377.62 178.241C1376.34 178.241 1375.28 179.278 1375.28 180.588C1375.28 181.872 1376.31 182.935 1377.62 182.935C1378.91 182.935 1379.97 181.897 1379.97 180.588C1379.97 179.278 1378.93 178.241 1377.62 178.241Z" />
        <path d="M1359.7 178.241C1358.41 178.241 1357.35 179.278 1357.35 180.588C1357.35 181.872 1358.39 182.935 1359.7 182.935C1360.98 182.935 1362.04 181.897 1362.04 180.588C1362.04 179.278 1360.98 178.241 1359.7 178.241Z" />
        <path d="M1341.75 178.241C1340.46 178.241 1339.4 179.278 1339.4 180.588C1339.4 181.872 1340.44 182.935 1341.75 182.935C1343.03 182.935 1344.09 181.897 1344.09 180.588C1344.09 179.278 1343.05 178.241 1341.75 178.241Z" />
        <path d="M1323.82 178.241C1322.53 178.241 1321.47 179.278 1321.47 180.588C1321.47 181.872 1322.51 182.935 1323.82 182.935C1325.1 182.935 1326.16 181.897 1326.16 180.588C1326.14 179.278 1325.1 178.241 1323.82 178.241Z" />
        <path d="M1305.87 178.241C1304.58 178.241 1303.52 179.278 1303.52 180.588C1303.52 181.872 1304.56 182.935 1305.87 182.935C1307.15 182.935 1308.21 181.897 1308.21 180.588C1308.21 179.278 1307.18 178.241 1305.87 178.241Z" />
        <path d="M1287.94 178.241C1286.66 178.241 1285.6 179.278 1285.6 180.588C1285.6 181.872 1286.63 182.935 1287.94 182.935C1289.23 182.935 1290.29 181.897 1290.29 180.588C1290.26 179.278 1289.23 178.241 1287.94 178.241Z" />
        <path d="M1269.99 178.241C1268.7 178.241 1267.64 179.278 1267.64 180.588C1267.64 181.872 1268.68 182.935 1269.99 182.935C1271.27 182.935 1272.33 181.897 1272.33 180.588C1272.33 179.278 1271.3 178.241 1269.99 178.241Z" />
        <path d="M1252.06 178.241C1250.78 178.241 1249.72 179.278 1249.72 180.588C1249.72 181.872 1250.75 182.935 1252.06 182.935C1253.35 182.935 1254.41 181.897 1254.41 180.588C1254.38 179.278 1253.35 178.241 1252.06 178.241Z" />
        <path d="M1234.11 178.241C1232.82 178.241 1231.76 179.278 1231.76 180.588C1231.76 181.872 1232.8 182.935 1234.11 182.935C1235.39 182.935 1236.45 181.897 1236.45 180.588C1236.45 179.278 1235.39 178.241 1234.11 178.241Z" />
        <path d="M1216.18 178.241C1214.9 178.241 1213.84 179.278 1213.84 180.588C1213.84 181.872 1214.88 182.935 1216.18 182.935C1217.47 182.935 1218.53 181.897 1218.53 180.588C1218.51 179.278 1217.47 178.241 1216.18 178.241Z" />
        <path d="M1198.23 178.241C1196.95 178.241 1195.89 179.278 1195.89 180.588C1195.89 181.872 1196.92 182.935 1198.23 182.935C1199.52 182.935 1200.58 181.897 1200.58 180.588C1200.58 179.278 1199.52 178.241 1198.23 178.241Z" />
        <path d="M1180.31 178.241C1179.02 178.241 1177.96 179.278 1177.96 180.588C1177.96 181.872 1179 182.935 1180.31 182.935C1181.59 182.935 1182.65 181.897 1182.65 180.588C1182.63 179.278 1181.59 178.241 1180.31 178.241Z" />
        <path d="M1162.35 178.241C1161.07 178.241 1160.01 179.278 1160.01 180.588C1160.01 181.872 1161.04 182.935 1162.35 182.935C1163.64 182.935 1164.7 181.897 1164.7 180.588C1164.7 179.278 1163.64 178.241 1162.35 178.241Z" />
        <path d="M1144.43 178.241C1143.14 178.241 1142.08 179.278 1142.08 180.588C1142.08 181.872 1143.12 182.935 1144.43 182.935C1145.71 182.935 1146.77 181.897 1146.77 180.588C1146.75 179.278 1145.71 178.241 1144.43 178.241Z" />
        <path d="M1126.47 178.241C1125.19 178.241 1124.13 179.278 1124.13 180.588C1124.13 181.872 1125.17 182.935 1126.47 182.935C1127.76 182.935 1128.82 181.897 1128.82 180.588C1128.82 179.278 1127.76 178.241 1126.47 178.241Z" />
        <path d="M1108.55 178.241C1107.26 178.241 1106.2 179.278 1106.2 180.588C1106.2 181.872 1107.24 182.935 1108.55 182.935C1109.83 182.935 1110.89 181.897 1110.89 180.588C1110.87 179.278 1109.83 178.241 1108.55 178.241Z" />
        <path d="M1090.6 178.241C1089.31 178.241 1088.25 179.278 1088.25 180.588C1088.25 181.872 1089.29 182.935 1090.6 182.935C1091.88 182.935 1092.94 181.897 1092.94 180.588C1092.94 179.278 1091.88 178.241 1090.6 178.241Z" />
        <path d="M1072.67 178.241C1071.39 178.241 1070.32 179.278 1070.32 180.588C1070.32 181.872 1071.36 182.935 1072.67 182.935C1073.95 182.935 1075.02 181.897 1075.02 180.588C1074.99 179.278 1073.95 178.241 1072.67 178.241Z" />
        <path d="M1054.72 178.241C1053.43 178.241 1052.37 179.278 1052.37 180.588C1052.37 181.872 1053.41 182.935 1054.72 182.935C1056 182.935 1057.06 181.897 1057.06 180.588C1057.06 179.278 1056 178.241 1054.72 178.241Z" />
        <path d="M1036.77 178.241C1035.48 178.241 1034.42 179.278 1034.42 180.588C1034.42 181.872 1035.46 182.935 1036.77 182.935C1038.05 182.935 1039.11 181.897 1039.11 180.588C1039.11 179.278 1038.08 178.241 1036.77 178.241Z" />
        <path d="M1018.84 178.241C1017.56 178.241 1016.5 179.278 1016.5 180.588C1016.5 181.872 1017.53 182.935 1018.84 182.935C1020.12 182.935 1021.19 181.897 1021.19 180.588C1021.19 179.278 1020.12 178.241 1018.84 178.241Z" />
        <path d="M1000.89 178.241C999.606 178.241 998.544 179.278 998.544 180.588C998.544 181.872 999.581 182.935 1000.89 182.935C1002.17 182.935 1003.24 181.897 1003.24 180.588C1003.24 179.278 1002.2 178.241 1000.89 178.241Z" />
        <path d="M982.963 178.241C981.679 178.241 980.617 179.278 980.617 180.588C980.617 181.872 981.655 182.935 982.963 182.935C984.272 182.935 985.309 181.897 985.309 180.588C985.309 179.278 984.247 178.241 982.963 178.241Z" />
        <path d="M965.011 178.241C963.727 178.241 962.665 179.278 962.665 180.588C962.665 181.872 963.702 182.935 965.011 182.935C966.319 182.935 967.356 181.897 967.356 180.588C967.356 179.278 966.319 178.241 965.011 178.241Z" />
        <path d="M1906.86 160.724C1905.82 160.724 1904.96 161.564 1904.96 162.627C1904.96 163.664 1905.8 164.529 1906.86 164.529C1907.9 164.529 1908.76 163.689 1908.76 162.627C1908.73 161.589 1907.9 160.724 1906.86 160.724Z" />
        <path d="M1888.91 160.724C1887.87 160.724 1887.01 161.564 1887.01 162.627C1887.01 163.664 1887.85 164.529 1888.91 164.529C1889.94 164.529 1890.81 163.689 1890.81 162.627C1890.81 161.589 1889.94 160.724 1888.91 160.724Z" />
        <path d="M1870.98 160.724C1869.94 160.724 1869.08 161.564 1869.08 162.627C1869.08 163.664 1869.92 164.529 1870.98 164.529C1872.04 164.529 1872.88 163.689 1872.88 162.627C1872.86 161.589 1872.02 160.724 1870.98 160.724Z" />
        <path d="M1853.03 160.724C1851.99 160.724 1851.13 161.564 1851.13 162.627C1851.13 163.664 1851.97 164.529 1853.03 164.529C1854.09 164.529 1854.93 163.689 1854.93 162.627C1854.93 161.589 1854.06 160.724 1853.03 160.724Z" />
        <path d="M1835.1 160.724C1834.07 160.724 1833.2 161.564 1833.2 162.627C1833.2 163.664 1834.04 164.529 1835.1 164.529C1836.14 164.529 1837 163.689 1837 162.627C1836.98 161.589 1836.14 160.724 1835.1 160.724Z" />
        <path d="M1817.15 160.724C1816.11 160.724 1815.25 161.564 1815.25 162.627C1815.25 163.664 1816.09 164.529 1817.15 164.529C1818.19 164.529 1819.05 163.689 1819.05 162.627C1819.05 161.589 1818.19 160.724 1817.15 160.724Z" />
        <path d="M1799.22 160.724C1798.19 160.724 1797.32 161.564 1797.32 162.627C1797.32 163.664 1798.16 164.529 1799.22 164.529C1800.26 164.529 1801.12 163.689 1801.12 162.627C1801.1 161.589 1800.26 160.724 1799.22 160.724Z" />
        <path d="M1781.27 160.724C1780.24 160.724 1779.37 161.564 1779.37 162.627C1779.37 163.664 1780.21 164.529 1781.27 164.529C1782.31 164.529 1783.17 163.689 1783.17 162.627C1783.17 161.589 1782.31 160.724 1781.27 160.724Z" />
        <path d="M1763.35 160.724C1762.31 160.724 1761.44 161.564 1761.44 162.627C1761.44 163.664 1762.28 164.529 1763.35 164.529C1764.38 164.529 1765.25 163.689 1765.25 162.627C1765.22 161.589 1764.38 160.724 1763.35 160.724Z" />
        <path d="M1745.39 160.724C1744.36 160.724 1743.49 161.564 1743.49 162.627C1743.49 163.664 1744.33 164.529 1745.39 164.529C1746.46 164.529 1747.3 163.689 1747.3 162.627C1747.3 161.589 1746.43 160.724 1745.39 160.724Z" />
        <path d="M1727.47 160.724C1726.43 160.724 1725.56 161.564 1725.56 162.627C1725.56 163.664 1726.4 164.529 1727.47 164.529C1728.53 164.529 1729.37 163.689 1729.37 162.627C1729.34 161.589 1728.5 160.724 1727.47 160.724Z" />
        <path d="M1709.51 160.724C1708.48 160.724 1707.61 161.564 1707.61 162.627C1707.61 163.664 1708.45 164.529 1709.51 164.529C1710.55 164.529 1711.42 163.689 1711.42 162.627C1711.42 161.589 1710.55 160.724 1709.51 160.724Z" />
        <path d="M1691.56 160.724C1690.53 160.724 1689.66 161.564 1689.66 162.627C1689.66 163.664 1690.5 164.529 1691.56 164.529C1692.6 164.529 1693.47 163.689 1693.47 162.627C1693.47 161.589 1692.63 160.724 1691.56 160.724Z" />
        <path d="M1673.64 160.724C1672.6 160.724 1671.74 161.564 1671.74 162.627C1671.74 163.664 1672.58 164.529 1673.64 164.529C1674.68 164.529 1675.54 163.689 1675.54 162.627C1675.52 161.589 1674.68 160.724 1673.64 160.724Z" />
        <path d="M1655.69 160.724C1654.65 160.724 1653.78 161.564 1653.78 162.627C1653.78 163.664 1654.62 164.529 1655.69 164.529C1656.72 164.529 1657.59 163.689 1657.59 162.627C1657.59 161.589 1656.75 160.724 1655.69 160.724Z" />
        <path d="M1637.76 160.724C1636.72 160.724 1635.86 161.564 1635.86 162.627C1635.86 163.664 1636.7 164.529 1637.76 164.529C1638.8 164.529 1639.66 163.689 1639.66 162.627C1639.64 161.589 1638.8 160.724 1637.76 160.724Z" />
        <path d="M1619.81 160.724C1618.77 160.724 1617.91 161.564 1617.91 162.627C1617.91 163.664 1618.75 164.529 1619.81 164.529C1620.87 164.529 1621.71 163.689 1621.71 162.627C1621.71 161.589 1620.87 160.724 1619.81 160.724Z" />
        <path d="M1601.88 160.724C1600.84 160.724 1599.98 161.564 1599.98 162.627C1599.98 163.664 1600.82 164.529 1601.88 164.529C1602.92 164.529 1603.78 163.689 1603.78 162.627C1603.76 161.589 1602.92 160.724 1601.88 160.724Z" />
        <path d="M1583.93 160.724C1582.89 160.724 1582.03 161.564 1582.03 162.627C1582.03 163.664 1582.87 164.529 1583.93 164.529C1584.99 164.529 1585.83 163.689 1585.83 162.627C1585.83 161.589 1584.99 160.724 1583.93 160.724Z" />
        <path d="M1566 160.724C1564.97 160.724 1564.1 161.564 1564.1 162.627C1564.1 163.664 1564.94 164.529 1566 164.529C1567.04 164.529 1567.9 163.689 1567.9 162.627C1567.88 161.589 1567.04 160.724 1566 160.724Z" />
        <path d="M1548.05 160.724C1547.01 160.724 1546.15 161.564 1546.15 162.627C1546.15 163.664 1546.99 164.529 1548.05 164.529C1549.09 164.529 1549.95 163.689 1549.95 162.627C1549.95 161.589 1549.11 160.724 1548.05 160.724Z" />
        <path d="M1530.12 160.724C1529.09 160.724 1528.22 161.564 1528.22 162.627C1528.22 163.664 1529.06 164.529 1530.12 164.529C1531.16 164.529 1532.02 163.689 1532.02 162.627C1532 161.589 1531.16 160.724 1530.12 160.724Z" />
        <path d="M1512.17 160.724C1511.13 160.724 1510.27 161.564 1510.27 162.627C1510.27 163.664 1511.11 164.529 1512.17 164.529C1513.21 164.529 1514.07 163.689 1514.07 162.627C1514.07 161.589 1513.23 160.724 1512.17 160.724Z" />
        <path d="M1494.25 160.724C1493.21 160.724 1492.34 161.564 1492.34 162.627C1492.34 163.664 1493.18 164.529 1494.25 164.529C1495.28 164.529 1496.15 163.689 1496.15 162.627C1496.12 161.589 1495.28 160.724 1494.25 160.724Z" />
        <path d="M1476.29 160.724C1475.26 160.724 1474.39 161.564 1474.39 162.627C1474.39 163.664 1475.23 164.529 1476.29 164.529C1477.33 164.529 1478.2 163.689 1478.2 162.627C1478.2 161.589 1477.36 160.724 1476.29 160.724Z" />
        <path d="M1458.37 160.724C1457.33 160.724 1456.46 161.564 1456.46 162.627C1456.46 163.664 1457.3 164.529 1458.37 164.529C1459.4 164.529 1460.27 163.689 1460.27 162.627C1460.24 161.589 1459.4 160.724 1458.37 160.724Z" />
        <path d="M1440.42 160.724C1439.38 160.724 1438.52 161.564 1438.52 162.627C1438.52 163.664 1439.35 164.529 1440.42 164.529C1441.45 164.529 1442.32 163.689 1442.32 162.627C1442.32 161.589 1441.45 160.724 1440.42 160.724Z" />
        <path d="M1422.49 160.724C1421.45 160.724 1420.59 161.564 1420.59 162.627C1420.59 163.664 1421.43 164.529 1422.49 164.529C1423.53 164.529 1424.39 163.689 1424.39 162.627C1424.36 161.589 1423.53 160.724 1422.49 160.724Z" />
        <path d="M1404.54 160.724C1403.5 160.724 1402.64 161.564 1402.64 162.627C1402.64 163.664 1403.48 164.529 1404.54 164.529C1405.58 164.529 1406.44 163.689 1406.44 162.627C1406.44 161.589 1405.58 160.724 1404.54 160.724Z" />
        <path d="M1386.61 160.724C1385.57 160.724 1384.71 161.564 1384.71 162.627C1384.71 163.664 1385.55 164.529 1386.61 164.529C1387.65 164.529 1388.51 163.689 1388.51 162.627C1388.49 161.589 1387.65 160.724 1386.61 160.724Z" />
        <path d="M1368.66 160.724C1367.62 160.724 1366.76 161.564 1366.76 162.627C1366.76 163.664 1367.6 164.529 1368.66 164.529C1369.7 164.529 1370.56 163.689 1370.56 162.627C1370.56 161.589 1369.7 160.724 1368.66 160.724Z" />
        <path d="M1350.73 160.724C1349.7 160.724 1348.83 161.564 1348.83 162.627C1348.83 163.664 1349.67 164.529 1350.73 164.529C1351.77 164.529 1352.63 163.689 1352.63 162.627C1352.61 161.589 1351.77 160.724 1350.73 160.724Z" />
        <path d="M1332.78 160.724C1331.74 160.724 1330.88 161.564 1330.88 162.627C1330.88 163.664 1331.72 164.529 1332.78 164.529C1333.82 164.529 1334.68 163.689 1334.68 162.627C1334.68 161.589 1333.82 160.724 1332.78 160.724Z" />
        <path d="M1314.86 160.724C1313.82 160.724 1312.95 161.564 1312.95 162.627C1312.95 163.664 1313.79 164.529 1314.86 164.529C1315.89 164.529 1316.76 163.689 1316.76 162.627C1316.73 161.589 1315.89 160.724 1314.86 160.724Z" />
        <path d="M1296.9 160.724C1295.87 160.724 1295 161.564 1295 162.627C1295 163.664 1295.84 164.529 1296.9 164.529C1297.94 164.529 1298.81 163.689 1298.81 162.627C1298.81 161.589 1297.94 160.724 1296.9 160.724Z" />
        <path d="M1278.95 160.724C1277.91 160.724 1277.05 161.564 1277.05 162.627C1277.05 163.664 1277.89 164.529 1278.95 164.529C1279.99 164.529 1280.85 163.689 1280.85 162.627C1280.85 161.589 1280.01 160.724 1278.95 160.724Z" />
        <path d="M1261.02 160.724C1259.99 160.724 1259.12 161.564 1259.12 162.627C1259.12 163.664 1259.96 164.529 1261.02 164.529C1262.06 164.529 1262.93 163.689 1262.93 162.627C1262.93 161.589 1262.06 160.724 1261.02 160.724Z" />
        <path d="M1243.07 160.724C1242.04 160.724 1241.17 161.564 1241.17 162.627C1241.17 163.664 1242.01 164.529 1243.07 164.529C1244.14 164.529 1244.97 163.689 1244.97 162.627C1244.97 161.589 1244.14 160.724 1243.07 160.724Z" />
        <path d="M1225.15 160.724C1224.11 160.724 1223.25 161.564 1223.25 162.627C1223.25 163.664 1224.09 164.529 1225.15 164.529C1226.21 164.529 1227.05 163.689 1227.05 162.627C1227.02 161.589 1226.18 160.724 1225.15 160.724Z" />
        <path d="M1207.19 160.724C1206.16 160.724 1205.29 161.564 1205.29 162.627C1205.29 163.664 1206.13 164.529 1207.19 164.529C1208.26 164.529 1209.1 163.689 1209.1 162.627C1209.1 161.589 1208.26 160.724 1207.19 160.724Z" />
        <path d="M1189.27 160.724C1188.23 160.724 1187.37 161.564 1187.37 162.627C1187.37 163.664 1188.21 164.529 1189.27 164.529C1190.33 164.529 1191.17 163.689 1191.17 162.627C1191.14 161.589 1190.3 160.724 1189.27 160.724Z" />
        <path d="M1171.32 160.724C1170.28 160.724 1169.42 161.564 1169.42 162.627C1169.42 163.664 1170.25 164.529 1171.32 164.529C1172.38 164.529 1173.22 163.689 1173.22 162.627C1173.22 161.589 1172.38 160.724 1171.32 160.724Z" />
        <path d="M1153.39 160.724C1152.35 160.724 1151.49 161.564 1151.49 162.627C1151.49 163.664 1152.33 164.529 1153.39 164.529C1154.43 164.529 1155.29 163.689 1155.29 162.627C1155.27 161.589 1154.43 160.724 1153.39 160.724Z" />
        <path d="M1135.44 160.724C1134.4 160.724 1133.54 161.564 1133.54 162.627C1133.54 163.664 1134.38 164.529 1135.44 164.529C1136.48 164.529 1137.34 163.689 1137.34 162.627C1137.34 161.589 1136.5 160.724 1135.44 160.724Z" />
        <path d="M1117.51 160.724C1116.47 160.724 1115.61 161.564 1115.61 162.627C1115.61 163.664 1116.45 164.529 1117.51 164.529C1118.55 164.529 1119.41 163.689 1119.41 162.627C1119.39 161.589 1118.55 160.724 1117.51 160.724Z" />
        <path d="M1099.56 160.724C1098.52 160.724 1097.66 161.564 1097.66 162.627C1097.66 163.664 1098.5 164.529 1099.56 164.529C1100.6 164.529 1101.46 163.689 1101.46 162.627C1101.46 161.589 1100.62 160.724 1099.56 160.724Z" />
        <path d="M1081.63 160.724C1080.6 160.724 1079.73 161.564 1079.73 162.627C1079.73 163.664 1080.57 164.529 1081.63 164.529C1082.67 164.529 1083.54 163.689 1083.54 162.627C1083.51 161.589 1082.67 160.724 1081.63 160.724Z" />
        <path d="M1063.68 160.724C1062.65 160.724 1061.78 161.564 1061.78 162.627C1061.78 163.664 1062.62 164.529 1063.68 164.529C1064.72 164.529 1065.58 163.689 1065.58 162.627C1065.58 161.589 1064.74 160.724 1063.68 160.724Z" />
        <path d="M1045.76 160.724C1044.72 160.724 1043.85 161.564 1043.85 162.627C1043.85 163.664 1044.69 164.529 1045.76 164.529C1046.79 164.529 1047.66 163.689 1047.66 162.627C1047.63 161.589 1046.79 160.724 1045.76 160.724Z" />
        <path d="M1027.8 160.724C1026.77 160.724 1025.9 161.564 1025.9 162.627C1025.9 163.664 1026.74 164.529 1027.8 164.529C1028.84 164.529 1029.71 163.689 1029.71 162.627C1029.71 161.589 1028.84 160.724 1027.8 160.724Z" />
        <path d="M1009.88 160.724C1008.84 160.724 1007.98 161.564 1007.98 162.627C1007.98 163.664 1008.82 164.529 1009.88 164.529C1010.91 164.529 1011.78 163.689 1011.78 162.627C1011.75 161.589 1010.91 160.724 1009.88 160.724Z" />
        <path d="M991.927 160.724C990.89 160.724 990.025 161.564 990.025 162.627C990.025 163.664 990.865 164.529 991.927 164.529C992.988 164.529 993.828 163.689 993.828 162.627C993.828 161.589 992.964 160.724 991.927 160.724Z" />
        <path d="M974 160.724C972.962 160.724 972.098 161.564 972.098 162.627C972.098 163.664 972.938 164.529 974 164.529C975.061 164.529 975.901 163.689 975.901 162.627C975.876 161.589 975.037 160.724 974 160.724Z" />
        <path d="M1915.82 142.986C1914.88 142.986 1914.14 143.752 1914.14 144.666C1914.14 145.605 1914.91 146.346 1915.82 146.346C1916.76 146.346 1917.5 145.58 1917.5 144.666C1917.5 143.752 1916.76 142.986 1915.82 142.986Z" />
        <path d="M1897.87 142.986C1896.93 142.986 1896.19 143.752 1896.19 144.666C1896.19 145.605 1896.96 146.346 1897.87 146.346C1898.81 146.346 1899.55 145.58 1899.55 144.666C1899.57 143.752 1898.81 142.986 1897.87 142.986Z" />
        <path d="M1879.94 142.986C1879 142.986 1878.26 143.752 1878.26 144.666C1878.26 145.605 1879.03 146.346 1879.94 146.346C1880.86 146.346 1881.62 145.58 1881.62 144.666C1881.62 143.752 1880.88 142.986 1879.94 142.986Z" />
        <path d="M1861.99 142.986C1861.05 142.986 1860.31 143.752 1860.31 144.666C1860.31 145.605 1861.08 146.346 1861.99 146.346C1862.9 146.346 1863.67 145.58 1863.67 144.666C1863.7 143.752 1862.93 142.986 1861.99 142.986Z" />
        <path d="M1844.07 142.986C1843.13 142.986 1842.39 143.752 1842.39 144.666C1842.39 145.605 1843.15 146.346 1844.07 146.346C1844.98 146.346 1845.74 145.58 1845.74 144.666C1845.74 143.752 1845 142.986 1844.07 142.986Z" />
        <path d="M1826.11 142.986C1825.17 142.986 1824.43 143.752 1824.43 144.666C1824.43 145.605 1825.2 146.346 1826.11 146.346C1827.05 146.346 1827.79 145.58 1827.79 144.666C1827.82 143.752 1827.05 142.986 1826.11 142.986Z" />
        <path d="M1808.19 142.986C1807.25 142.986 1806.48 143.752 1806.48 144.666C1806.48 145.605 1807.25 146.346 1808.19 146.346C1809.13 146.346 1809.87 145.58 1809.87 144.666C1809.87 143.752 1809.13 142.986 1808.19 142.986Z" />
        <path d="M1790.23 142.986C1789.3 142.986 1788.56 143.752 1788.56 144.666C1788.56 145.605 1789.32 146.346 1790.23 146.346C1791.17 146.346 1791.94 145.58 1791.94 144.666C1791.94 143.752 1791.17 142.986 1790.23 142.986Z" />
        <path d="M1772.31 142.986C1771.37 142.986 1770.63 143.752 1770.63 144.666C1770.63 145.605 1771.39 146.346 1772.31 146.346C1773.25 146.346 1773.99 145.58 1773.99 144.666C1773.99 143.752 1773.25 142.986 1772.31 142.986Z" />
        <path d="M1754.36 142.986C1753.42 142.986 1752.68 143.752 1752.68 144.666C1752.68 145.605 1753.44 146.346 1754.36 146.346C1755.27 146.346 1756.04 145.58 1756.04 144.666C1756.06 143.752 1755.29 142.986 1754.36 142.986Z" />
        <path d="M1736.43 142.986C1735.49 142.986 1734.75 143.752 1734.75 144.666C1734.75 145.605 1735.52 146.346 1736.43 146.346C1737.34 146.346 1738.11 145.58 1738.11 144.666C1738.11 143.752 1737.37 142.986 1736.43 142.986Z" />
        <path d="M1718.48 142.986C1717.54 142.986 1716.8 143.752 1716.8 144.666C1716.8 145.605 1717.56 146.346 1718.48 146.346C1719.39 146.346 1720.16 145.58 1720.16 144.666C1720.18 143.752 1719.42 142.986 1718.48 142.986Z" />
        <path d="M1700.55 142.986C1699.61 142.986 1698.85 143.752 1698.85 144.666C1698.85 145.605 1699.61 146.346 1700.55 146.346C1701.49 146.346 1702.23 145.58 1702.23 144.666C1702.23 143.752 1701.49 142.986 1700.55 142.986Z" />
        <path d="M1682.6 142.986C1681.66 142.986 1680.9 143.752 1680.9 144.666C1680.9 145.605 1681.66 146.346 1682.6 146.346C1683.54 146.346 1684.28 145.58 1684.28 144.666C1684.3 143.752 1683.54 142.986 1682.6 142.986Z" />
        <path d="M1664.67 142.986C1663.73 142.986 1662.99 143.752 1662.99 144.666C1662.99 145.605 1663.76 146.346 1664.67 146.346C1665.61 146.346 1666.35 145.58 1666.35 144.666C1666.35 143.752 1665.61 142.986 1664.67 142.986Z" />
        <path d="M1646.72 142.986C1645.79 142.986 1645.04 143.752 1645.04 144.666C1645.04 145.605 1645.81 146.346 1646.72 146.346C1647.64 146.346 1648.4 145.58 1648.4 144.666C1648.43 143.752 1647.66 142.986 1646.72 142.986Z" />
        <path d="M1628.8 142.986C1627.86 142.986 1627.12 143.752 1627.12 144.666C1627.12 145.605 1627.88 146.346 1628.8 146.346C1629.71 146.346 1630.47 145.58 1630.47 144.666C1630.47 143.752 1629.71 142.986 1628.8 142.986Z" />
        <path d="M1610.84 142.986C1609.91 142.986 1609.16 143.752 1609.16 144.666C1609.16 145.605 1609.93 146.346 1610.84 146.346C1611.76 146.346 1612.52 145.58 1612.52 144.666C1612.55 143.752 1611.78 142.986 1610.84 142.986Z" />
        <path d="M1592.92 142.986C1591.98 142.986 1591.24 143.752 1591.24 144.666C1591.24 145.605 1592 146.346 1592.92 146.346C1593.83 146.346 1594.6 145.58 1594.6 144.666C1594.6 143.752 1593.83 142.986 1592.92 142.986Z" />
        <path d="M1574.97 142.986C1574.03 142.986 1573.29 143.752 1573.29 144.666C1573.29 145.605 1574.05 146.346 1574.97 146.346C1575.88 146.346 1576.65 145.58 1576.65 144.666C1576.67 143.752 1575.9 142.986 1574.97 142.986Z" />
        <path d="M1557.04 142.986C1556.1 142.986 1555.36 143.752 1555.36 144.666C1555.36 145.605 1556.13 146.346 1557.04 146.346C1557.95 146.346 1558.72 145.58 1558.72 144.666C1558.72 143.752 1557.95 142.986 1557.04 142.986Z" />
        <path d="M1539.09 142.986C1538.15 142.986 1537.41 143.752 1537.41 144.666C1537.41 145.605 1538.17 146.346 1539.09 146.346C1540 146.346 1540.77 145.58 1540.77 144.666C1540.79 143.752 1540.03 142.986 1539.09 142.986Z" />
        <path d="M1521.16 142.986C1520.22 142.986 1519.48 143.752 1519.48 144.666C1519.48 145.605 1520.25 146.346 1521.16 146.346C1522.1 146.346 1522.84 145.58 1522.84 144.666C1522.84 143.752 1522.07 142.986 1521.16 142.986Z" />
        <path d="M1503.21 142.986C1502.27 142.986 1501.51 143.752 1501.51 144.666C1501.51 145.605 1502.27 146.346 1503.21 146.346C1504.15 146.346 1504.89 145.58 1504.89 144.666C1504.89 143.752 1504.15 142.986 1503.21 142.986Z" />
        <path d="M1485.26 142.986C1484.32 142.986 1483.58 143.752 1483.58 144.666C1483.58 145.605 1484.34 146.346 1485.26 146.346C1486.19 146.346 1486.94 145.58 1486.94 144.666C1486.96 143.752 1486.19 142.986 1485.26 142.986Z" />
        <path d="M1467.33 142.986C1466.39 142.986 1465.65 143.752 1465.65 144.666C1465.65 145.605 1466.42 146.346 1467.33 146.346C1468.27 146.346 1469.01 145.58 1469.01 144.666C1469.01 143.752 1468.27 142.986 1467.33 142.986Z" />
        <path d="M1449.38 142.986C1448.44 142.986 1447.7 143.752 1447.7 144.666C1447.7 145.605 1448.47 146.346 1449.38 146.346C1450.29 146.346 1451.06 145.58 1451.06 144.666C1451.08 143.752 1450.32 142.986 1449.38 142.986Z" />
        <path d="M1431.45 142.986C1430.51 142.986 1429.77 143.752 1429.77 144.666C1429.77 145.605 1430.54 146.346 1431.45 146.346C1432.39 146.346 1433.13 145.58 1433.13 144.666C1433.13 143.752 1432.39 142.986 1431.45 142.986Z" />
        <path d="M1413.5 142.986C1412.56 142.986 1411.82 143.752 1411.82 144.666C1411.82 145.605 1412.59 146.346 1413.5 146.346C1414.44 146.346 1415.18 145.58 1415.18 144.666C1415.2 143.752 1414.44 142.986 1413.5 142.986Z" />
        <path d="M1395.57 142.986C1394.64 142.986 1393.9 143.752 1393.9 144.666C1393.9 145.605 1394.66 146.346 1395.57 146.346C1396.49 146.346 1397.25 145.58 1397.25 144.666C1397.25 143.752 1396.51 142.986 1395.57 142.986Z" />
        <path d="M1377.62 142.986C1376.69 142.986 1375.94 143.752 1375.94 144.666C1375.94 145.605 1376.71 146.346 1377.62 146.346C1378.54 146.346 1379.3 145.58 1379.3 144.666C1379.33 143.752 1378.56 142.986 1377.62 142.986Z" />
        <path d="M1359.7 142.986C1358.76 142.986 1358.02 143.752 1358.02 144.666C1358.02 145.605 1358.78 146.346 1359.7 146.346C1360.61 146.346 1361.37 145.58 1361.37 144.666C1361.37 143.752 1360.63 142.986 1359.7 142.986Z" />
        <path d="M1341.75 142.986C1340.81 142.986 1340.07 143.752 1340.07 144.666C1340.07 145.605 1340.83 146.346 1341.75 146.346C1342.68 146.346 1343.42 145.58 1343.42 144.666C1343.45 143.752 1342.68 142.986 1341.75 142.986Z" />
        <path d="M1323.82 142.986C1322.88 142.986 1322.14 143.752 1322.14 144.666C1322.14 145.605 1322.9 146.346 1323.82 146.346C1324.76 146.346 1325.5 145.58 1325.5 144.666C1325.5 143.752 1324.76 142.986 1323.82 142.986Z" />
        <path d="M1305.87 142.986C1304.93 142.986 1304.19 143.752 1304.19 144.666C1304.19 145.605 1304.95 146.346 1305.87 146.346C1306.8 146.346 1307.57 145.58 1307.57 144.666C1307.57 143.752 1306.8 142.986 1305.87 142.986Z" />
        <path d="M1287.94 142.986C1287 142.986 1286.26 143.752 1286.26 144.666C1286.26 145.605 1287.03 146.346 1287.94 146.346C1288.85 146.346 1289.62 145.58 1289.62 144.666C1289.62 143.752 1288.88 142.986 1287.94 142.986Z" />
        <path d="M1269.99 142.986C1269.05 142.986 1268.31 143.752 1268.31 144.666C1268.31 145.605 1269.08 146.346 1269.99 146.346C1270.9 146.346 1271.67 145.58 1271.67 144.666C1271.69 143.752 1270.93 142.986 1269.99 142.986Z" />
        <path d="M1252.06 142.986C1251.12 142.986 1250.38 143.752 1250.38 144.666C1250.38 145.605 1251.15 146.346 1252.06 146.346C1252.97 146.346 1253.74 145.58 1253.74 144.666C1253.74 143.752 1253 142.986 1252.06 142.986Z" />
        <path d="M1234.11 142.986C1233.17 142.986 1232.43 143.752 1232.43 144.666C1232.43 145.605 1233.2 146.346 1234.11 146.346C1235.02 146.346 1235.79 145.58 1235.79 144.666C1235.81 143.752 1235.05 142.986 1234.11 142.986Z" />
        <path d="M1216.18 142.986C1215.24 142.986 1214.5 143.752 1214.5 144.666C1214.5 145.605 1215.27 146.346 1216.18 146.346C1217.1 146.346 1217.86 145.58 1217.86 144.666C1217.86 143.752 1217.1 142.986 1216.18 142.986Z" />
        <path d="M1198.23 142.986C1197.29 142.986 1196.55 143.752 1196.55 144.666C1196.55 145.605 1197.32 146.346 1198.23 146.346C1199.15 146.346 1199.91 145.58 1199.91 144.666C1199.94 143.752 1199.17 142.986 1198.23 142.986Z" />
        <path d="M1180.31 142.986C1179.37 142.986 1178.63 143.752 1178.63 144.666C1178.63 145.605 1179.39 146.346 1180.31 146.346C1181.22 146.346 1181.98 145.58 1181.98 144.666C1181.98 143.752 1181.22 142.986 1180.31 142.986Z" />
        <path d="M1162.35 142.986C1161.42 142.986 1160.67 143.752 1160.67 144.666C1160.67 145.605 1161.44 146.346 1162.35 146.346C1163.27 146.346 1164.03 145.58 1164.03 144.666C1164.06 143.752 1163.29 142.986 1162.35 142.986Z" />
        <path d="M1144.43 142.986C1143.49 142.986 1142.75 143.752 1142.75 144.666C1142.75 145.605 1143.51 146.346 1144.43 146.346C1145.34 146.346 1146.1 145.58 1146.1 144.666C1146.1 143.752 1145.34 142.986 1144.43 142.986Z" />
        <path d="M1126.48 142.986C1125.54 142.986 1124.8 143.752 1124.8 144.666C1124.8 145.605 1125.56 146.346 1126.48 146.346C1127.39 146.346 1128.16 145.58 1128.16 144.666C1128.18 143.752 1127.41 142.986 1126.48 142.986Z" />
        <path d="M1108.55 142.986C1107.61 142.986 1106.87 143.752 1106.87 144.666C1106.87 145.605 1107.63 146.346 1108.55 146.346C1109.49 146.346 1110.23 145.58 1110.23 144.666C1110.23 143.752 1109.46 142.986 1108.55 142.986Z" />
        <path d="M1090.6 142.986C1089.66 142.986 1088.89 143.752 1088.89 144.666C1088.89 145.605 1089.66 146.346 1090.6 146.346C1091.53 146.346 1092.28 145.58 1092.28 144.666C1092.3 143.752 1091.53 142.986 1090.6 142.986Z" />
        <path d="M1072.67 142.986C1071.73 142.986 1070.99 143.752 1070.99 144.666C1070.99 145.605 1071.76 146.346 1072.67 146.346C1073.61 146.346 1074.37 145.58 1074.37 144.666C1074.35 143.752 1073.58 142.986 1072.67 142.986Z" />
        <path d="M1054.72 142.986C1053.78 142.986 1053.04 143.752 1053.04 144.666C1053.04 145.605 1053.81 146.346 1054.72 146.346C1055.66 146.346 1056.4 145.58 1056.4 144.666C1056.4 143.752 1055.66 142.986 1054.72 142.986Z" />
        <path d="M1036.77 142.986C1035.83 142.986 1035.09 143.752 1035.09 144.666C1035.09 145.605 1035.85 146.346 1036.77 146.346C1037.68 146.346 1038.45 145.58 1038.45 144.666C1038.47 143.752 1037.71 142.986 1036.77 142.986Z" />
        <path d="M1018.84 142.986C1017.9 142.986 1017.16 143.752 1017.16 144.666C1017.16 145.605 1017.93 146.346 1018.84 146.346C1019.75 146.346 1020.52 145.58 1020.52 144.666C1020.52 143.752 1019.78 142.986 1018.84 142.986Z" />
        <path d="M1000.89 142.986C999.95 142.986 999.209 143.752 999.209 144.666C999.209 145.605 999.975 146.346 1000.89 146.346C1001.8 146.346 1002.57 145.58 1002.57 144.666C1002.59 143.752 1001.83 142.986 1000.89 142.986Z" />
        <path d="M982.962 142.986C982.024 142.986 981.283 143.752 981.283 144.666C981.283 145.605 982.049 146.346 982.962 146.346C983.876 146.346 984.641 145.58 984.641 144.666C984.641 143.752 983.901 142.986 982.962 142.986Z" />
        <path d="M965.011 142.986C964.073 142.986 963.332 143.752 963.332 144.666C963.332 145.605 964.097 146.346 965.011 146.346C965.925 146.346 966.69 145.58 966.69 144.666C966.715 143.752 965.949 142.986 965.011 142.986Z" />
        <path d="M1906.86 125.224C1906.04 125.224 1905.38 125.891 1905.38 126.706C1905.38 127.521 1906.04 128.188 1906.86 128.188C1907.67 128.188 1908.34 127.521 1908.34 126.706C1908.34 125.915 1907.67 125.224 1906.86 125.224Z" />
        <path d="M1888.91 125.224C1888.09 125.224 1887.42 125.891 1887.42 126.706C1887.42 127.521 1888.09 128.188 1888.91 128.188C1889.72 128.188 1890.39 127.521 1890.39 126.706C1890.41 125.915 1889.75 125.224 1888.91 125.224Z" />
        <path d="M1870.98 125.224C1870.17 125.224 1869.5 125.891 1869.5 126.706C1869.5 127.521 1870.17 128.188 1870.98 128.188C1871.8 128.188 1872.46 127.521 1872.46 126.706C1872.46 125.915 1871.8 125.224 1870.98 125.224Z" />
        <path d="M1853.03 125.224C1852.22 125.224 1851.55 125.891 1851.55 126.706C1851.55 127.521 1852.22 128.188 1853.03 128.188C1853.85 128.188 1854.51 127.521 1854.51 126.706C1854.54 125.915 1853.87 125.224 1853.03 125.224Z" />
        <path d="M1835.1 125.224C1834.29 125.224 1833.62 125.891 1833.62 126.706C1833.62 127.521 1834.29 128.188 1835.1 128.188C1835.92 128.188 1836.58 127.521 1836.58 126.706C1836.58 125.915 1835.92 125.224 1835.1 125.224Z" />
        <path d="M1817.15 125.224C1816.33 125.224 1815.67 125.891 1815.67 126.706C1815.67 127.521 1816.33 128.188 1817.15 128.188C1817.96 128.188 1818.63 127.521 1818.63 126.706C1818.66 125.915 1817.96 125.224 1817.15 125.224Z" />
        <path d="M1799.22 125.224C1798.41 125.224 1797.74 125.891 1797.74 126.706C1797.74 127.521 1798.41 128.188 1799.22 128.188C1800.04 128.188 1800.7 127.521 1800.7 126.706C1800.7 125.915 1800.04 125.224 1799.22 125.224Z" />
        <path d="M1781.27 125.224C1780.46 125.224 1779.79 125.891 1779.79 126.706C1779.79 127.521 1780.46 128.188 1781.27 128.188C1782.09 128.188 1782.75 127.521 1782.75 126.706C1782.75 125.915 1782.09 125.224 1781.27 125.224Z" />
        <path d="M1763.35 125.224C1762.53 125.224 1761.86 125.891 1761.86 126.706C1761.86 127.521 1762.53 128.188 1763.35 128.188C1764.16 128.188 1764.83 127.521 1764.83 126.706C1764.83 125.915 1764.16 125.224 1763.35 125.224Z" />
        <path d="M1745.39 125.224C1744.58 125.224 1743.91 125.891 1743.91 126.706C1743.91 127.521 1744.58 128.188 1745.39 128.188C1746.21 128.188 1746.87 127.521 1746.87 126.706C1746.87 125.915 1746.21 125.224 1745.39 125.224Z" />
        <path d="M1727.47 125.224C1726.65 125.224 1725.98 125.891 1725.98 126.706C1725.98 127.521 1726.65 128.188 1727.47 128.188C1728.28 128.188 1728.95 127.521 1728.95 126.706C1728.95 125.915 1728.28 125.224 1727.47 125.224Z" />
        <path d="M1709.52 125.224C1708.7 125.224 1708.03 125.891 1708.03 126.706C1708.03 127.521 1708.7 128.188 1709.52 128.188C1710.33 128.188 1711 127.521 1711 126.706C1711 125.915 1710.33 125.224 1709.52 125.224Z" />
        <path d="M1691.56 125.224C1690.75 125.224 1690.08 125.891 1690.08 126.706C1690.08 127.521 1690.75 128.188 1691.56 128.188C1692.38 128.188 1693.04 127.521 1693.04 126.706C1693.07 125.915 1692.4 125.224 1691.56 125.224Z" />
        <path d="M1673.64 125.224C1672.82 125.224 1672.16 125.891 1672.16 126.706C1672.16 127.521 1672.82 128.188 1673.64 128.188C1674.45 128.188 1675.12 127.521 1675.12 126.706C1675.12 125.915 1674.45 125.224 1673.64 125.224Z" />
        <path d="M1655.69 125.224C1654.87 125.224 1654.2 125.891 1654.2 126.706C1654.2 127.521 1654.87 128.188 1655.69 128.188C1656.5 128.188 1657.17 127.521 1657.17 126.706C1657.19 125.915 1656.53 125.224 1655.69 125.224Z" />
        <path d="M1637.76 125.224C1636.94 125.224 1636.28 125.891 1636.28 126.706C1636.28 127.521 1636.94 128.188 1637.76 128.188C1638.57 128.188 1639.24 127.521 1639.24 126.706C1639.24 125.915 1638.57 125.224 1637.76 125.224Z" />
        <path d="M1619.81 125.224C1618.99 125.224 1618.32 125.891 1618.32 126.706C1618.32 127.521 1618.99 128.188 1619.81 128.188C1620.62 128.188 1621.29 127.521 1621.29 126.706C1621.31 125.915 1620.65 125.224 1619.81 125.224Z" />
        <path d="M1601.88 125.224C1601.07 125.224 1600.4 125.891 1600.4 126.706C1600.4 127.521 1601.07 128.188 1601.88 128.188C1602.7 128.188 1603.36 127.521 1603.36 126.706C1603.36 125.915 1602.7 125.224 1601.88 125.224Z" />
        <path d="M1583.93 125.224C1583.11 125.224 1582.45 125.891 1582.45 126.706C1582.45 127.521 1583.11 128.188 1583.93 128.188C1584.74 128.188 1585.41 127.521 1585.41 126.706C1585.43 125.915 1584.77 125.224 1583.93 125.224Z" />
        <path d="M1566 125.224C1565.19 125.224 1564.52 125.891 1564.52 126.706C1564.52 127.521 1565.19 128.188 1566 128.188C1566.82 128.188 1567.48 127.521 1567.48 126.706C1567.48 125.915 1566.82 125.224 1566 125.224Z" />
        <path d="M1548.05 125.224C1547.23 125.224 1546.57 125.891 1546.57 126.706C1546.57 127.521 1547.23 128.188 1548.05 128.188C1548.86 128.188 1549.53 127.521 1549.53 126.706C1549.56 125.915 1548.89 125.224 1548.05 125.224Z" />
        <path d="M1530.12 125.224C1529.31 125.224 1528.64 125.891 1528.64 126.706C1528.64 127.521 1529.31 128.188 1530.12 128.188C1530.94 128.188 1531.6 127.521 1531.6 126.706C1531.6 125.915 1530.94 125.224 1530.12 125.224Z" />
        <path d="M1512.17 125.224C1511.36 125.224 1510.69 125.891 1510.69 126.706C1510.69 127.521 1511.36 128.188 1512.17 128.188C1512.99 128.188 1513.65 127.521 1513.65 126.706C1513.68 125.915 1513.01 125.224 1512.17 125.224Z" />
        <path d="M1494.25 125.224C1493.43 125.224 1492.76 125.891 1492.76 126.706C1492.76 127.521 1493.43 128.188 1494.25 128.188C1495.06 128.188 1495.73 127.521 1495.73 126.706C1495.73 125.915 1495.06 125.224 1494.25 125.224Z" />
        <path d="M1476.29 125.224C1475.48 125.224 1474.81 125.891 1474.81 126.706C1474.81 127.521 1475.48 128.188 1476.29 128.188C1477.11 128.188 1477.78 127.521 1477.78 126.706C1477.8 125.915 1477.13 125.224 1476.29 125.224Z" />
        <path d="M1458.37 125.224C1457.55 125.224 1456.88 125.891 1456.88 126.706C1456.88 127.521 1457.55 128.188 1458.37 128.188C1459.18 128.188 1459.85 127.521 1459.85 126.706C1459.85 125.915 1459.18 125.224 1458.37 125.224Z" />
        <path d="M1440.42 125.224C1439.6 125.224 1438.93 125.891 1438.93 126.706C1438.93 127.521 1439.6 128.188 1440.42 128.188C1441.23 128.188 1441.9 127.521 1441.9 126.706C1441.92 125.915 1441.26 125.224 1440.42 125.224Z" />
        <path d="M1422.49 125.224C1421.67 125.224 1421.01 125.891 1421.01 126.706C1421.01 127.521 1421.67 128.188 1422.49 128.188C1423.3 128.188 1423.97 127.521 1423.97 126.706C1423.97 125.915 1423.3 125.224 1422.49 125.224Z" />
        <path d="M1404.54 125.224C1403.72 125.224 1403.06 125.891 1403.06 126.706C1403.06 127.521 1403.72 128.188 1404.54 128.188C1405.35 128.188 1406.02 127.521 1406.02 126.706C1406.04 125.915 1405.38 125.224 1404.54 125.224Z" />
        <path d="M1386.61 125.224C1385.8 125.224 1385.13 125.891 1385.13 126.706C1385.13 127.521 1385.8 128.188 1386.61 128.188C1387.43 128.188 1388.09 127.521 1388.09 126.706C1388.09 125.915 1387.43 125.224 1386.61 125.224Z" />
        <path d="M1368.66 125.224C1367.85 125.224 1367.18 125.891 1367.18 126.706C1367.18 127.521 1367.85 128.188 1368.66 128.188C1369.48 128.188 1370.14 127.521 1370.14 126.706C1370.17 125.915 1369.48 125.224 1368.66 125.224Z" />
        <path d="M1350.73 125.224C1349.92 125.224 1349.25 125.891 1349.25 126.706C1349.25 127.521 1349.92 128.188 1350.73 128.188C1351.55 128.188 1352.21 127.521 1352.21 126.706C1352.21 125.915 1351.55 125.224 1350.73 125.224Z" />
        <path d="M1332.78 125.224C1331.97 125.224 1331.3 125.891 1331.3 126.706C1331.3 127.521 1331.97 128.188 1332.78 128.188C1333.6 128.188 1334.26 127.521 1334.26 126.706C1334.26 125.915 1333.6 125.224 1332.78 125.224Z" />
        <path d="M1314.86 125.224C1314.04 125.224 1313.37 125.891 1313.37 126.706C1313.37 127.521 1314.04 128.188 1314.86 128.188C1315.67 128.188 1316.34 127.521 1316.34 126.706C1316.34 125.915 1315.67 125.224 1314.86 125.224Z" />
        <path d="M1296.9 125.224C1296.09 125.224 1295.42 125.891 1295.42 126.706C1295.42 127.521 1296.09 128.188 1296.9 128.188C1297.72 128.188 1298.39 127.521 1298.39 126.706C1298.39 125.915 1297.72 125.224 1296.9 125.224Z" />
        <path d="M1278.95 125.224C1278.14 125.224 1277.47 125.891 1277.47 126.706C1277.47 127.521 1278.14 128.188 1278.95 128.188C1279.77 128.188 1280.43 127.521 1280.43 126.706C1280.46 125.915 1279.79 125.224 1278.95 125.224Z" />
        <path d="M1261.02 125.224C1260.21 125.224 1259.54 125.891 1259.54 126.706C1259.54 127.521 1260.21 128.188 1261.02 128.188C1261.84 128.188 1262.51 127.521 1262.51 126.706C1262.51 125.915 1261.84 125.224 1261.02 125.224Z" />
        <path d="M1243.07 125.224C1242.26 125.224 1241.59 125.891 1241.59 126.706C1241.59 127.521 1242.26 128.188 1243.07 128.188C1243.89 128.188 1244.55 127.521 1244.55 126.706C1244.58 125.915 1243.91 125.224 1243.07 125.224Z" />
        <path d="M1225.15 125.224C1224.33 125.224 1223.67 125.891 1223.67 126.706C1223.67 127.521 1224.33 128.188 1225.15 128.188C1225.96 128.188 1226.63 127.521 1226.63 126.706C1226.63 125.915 1225.96 125.224 1225.15 125.224Z" />
        <path d="M1207.2 125.224C1206.38 125.224 1205.71 125.891 1205.71 126.706C1205.71 127.521 1206.38 128.188 1207.2 128.188C1208.01 128.188 1208.68 127.521 1208.68 126.706C1208.7 125.915 1208.03 125.224 1207.2 125.224Z" />
        <path d="M1189.27 125.224C1188.45 125.224 1187.79 125.891 1187.79 126.706C1187.79 127.521 1188.45 128.188 1189.27 128.188C1190.08 128.188 1190.75 127.521 1190.75 126.706C1190.75 125.915 1190.08 125.224 1189.27 125.224Z" />
        <path d="M1171.32 125.224C1170.5 125.224 1169.84 125.891 1169.84 126.706C1169.84 127.521 1170.5 128.188 1171.32 128.188C1172.13 128.188 1172.8 127.521 1172.8 126.706C1172.82 125.915 1172.16 125.224 1171.32 125.224Z" />
        <path d="M1153.39 125.224C1152.58 125.224 1151.91 125.891 1151.91 126.706C1151.91 127.521 1152.58 128.188 1153.39 128.188C1154.2 128.188 1154.87 127.521 1154.87 126.706C1154.87 125.915 1154.2 125.224 1153.39 125.224Z" />
        <path d="M1135.44 125.224C1134.62 125.224 1133.96 125.891 1133.96 126.706C1133.96 127.521 1134.62 128.188 1135.44 128.188C1136.25 128.188 1136.92 127.521 1136.92 126.706C1136.94 125.915 1136.28 125.224 1135.44 125.224Z" />
        <path d="M1117.51 125.224C1116.7 125.224 1116.03 125.891 1116.03 126.706C1116.03 127.521 1116.7 128.188 1117.51 128.188C1118.33 128.188 1118.99 127.521 1118.99 126.706C1118.99 125.915 1118.33 125.224 1117.51 125.224Z" />
        <path d="M1099.56 125.224C1098.75 125.224 1098.08 125.891 1098.08 126.706C1098.08 127.521 1098.75 128.188 1099.56 128.188C1100.38 128.188 1101.04 127.521 1101.04 126.706C1101.07 125.915 1100.4 125.224 1099.56 125.224Z" />
        <path d="M1081.63 125.224C1080.82 125.224 1080.15 125.891 1080.15 126.706C1080.15 127.521 1080.82 128.188 1081.63 128.188C1082.45 128.188 1083.12 127.521 1083.12 126.706C1083.12 125.915 1082.45 125.224 1081.63 125.224Z" />
        <path d="M1063.68 125.224C1062.87 125.224 1062.2 125.891 1062.2 126.706C1062.2 127.521 1062.87 128.188 1063.68 128.188C1064.5 128.188 1065.16 127.521 1065.16 126.706C1065.19 125.915 1064.52 125.224 1063.68 125.224Z" />
        <path d="M1045.76 125.224C1044.94 125.224 1044.27 125.891 1044.27 126.706C1044.27 127.521 1044.94 128.188 1045.76 128.188C1046.57 128.188 1047.24 127.521 1047.24 126.706C1047.24 125.915 1046.57 125.224 1045.76 125.224Z" />
        <path d="M1027.8 125.224C1026.99 125.224 1026.32 125.891 1026.32 126.706C1026.32 127.521 1026.99 128.188 1027.8 128.188C1028.62 128.188 1029.28 127.521 1029.28 126.706C1029.31 125.915 1028.64 125.224 1027.8 125.224Z" />
        <path d="M1009.88 125.224C1009.06 125.224 1008.4 125.891 1008.4 126.706C1008.4 127.521 1009.06 128.188 1009.88 128.188C1010.69 128.188 1011.36 127.521 1011.36 126.706C1011.36 125.915 1010.69 125.224 1009.88 125.224Z" />
        <path d="M991.926 125.224C991.111 125.224 990.445 125.891 990.445 126.706C990.445 127.521 991.111 128.188 991.926 128.188C992.741 128.188 993.408 127.521 993.408 126.706C993.432 125.915 992.766 125.224 991.926 125.224Z" />
        <path d="M974 125.224C973.185 125.224 972.518 125.891 972.518 126.706C972.518 127.521 973.185 128.188 974 128.188C974.815 128.188 975.481 127.521 975.481 126.706C975.481 125.915 974.815 125.224 974 125.224Z" />
        <path d="M1915.82 107.485C1915.11 107.485 1914.54 108.061 1914.54 108.77C1914.54 109.48 1915.11 110.055 1915.82 110.055C1916.53 110.055 1917.11 109.48 1917.11 108.77C1917.11 108.061 1916.53 107.485 1915.82 107.485Z" />
        <path d="M1897.87 107.485C1897.16 107.485 1896.59 108.061 1896.59 108.77C1896.59 109.48 1897.16 110.055 1897.87 110.055C1898.58 110.055 1899.16 109.48 1899.16 108.77C1899.16 108.061 1898.58 107.485 1897.87 107.485Z" />
        <path d="M1879.94 107.485C1879.24 107.485 1878.66 108.061 1878.66 108.77C1878.66 109.48 1879.24 110.055 1879.94 110.055C1880.65 110.055 1881.23 109.48 1881.23 108.77C1881.23 108.061 1880.65 107.485 1879.94 107.485Z" />
        <path d="M1861.99 107.485C1861.28 107.485 1860.71 108.06 1860.71 108.77C1860.71 109.479 1861.28 110.055 1861.99 110.055C1862.7 110.055 1863.28 109.479 1863.28 108.77C1863.28 108.06 1862.7 107.485 1861.99 107.485Z" />
        <path d="M1844.06 107.485C1843.36 107.485 1842.78 108.06 1842.78 108.77C1842.78 109.479 1843.36 110.055 1844.06 110.055C1844.77 110.055 1845.35 109.479 1845.35 108.77C1845.35 108.06 1844.77 107.485 1844.06 107.485Z" />
        <path d="M1826.11 107.485C1825.4 107.485 1824.83 108.06 1824.83 108.77C1824.83 109.479 1825.4 110.055 1826.11 110.055C1826.82 110.055 1827.4 109.479 1827.4 108.77C1827.4 108.06 1826.82 107.485 1826.11 107.485Z" />
        <path d="M1808.19 107.485C1807.48 107.485 1806.9 108.06 1806.9 108.77C1806.9 109.479 1807.48 110.055 1808.19 110.055C1808.9 110.055 1809.47 109.479 1809.47 108.77C1809.47 108.06 1808.9 107.485 1808.19 107.485Z" />
        <path d="M1790.23 107.485C1789.53 107.485 1788.95 108.06 1788.95 108.77C1788.95 109.479 1789.53 110.055 1790.23 110.055C1790.94 110.055 1791.52 109.479 1791.52 108.77C1791.52 108.06 1790.94 107.485 1790.23 107.485Z" />
        <path d="M1772.31 107.485C1771.6 107.485 1771.02 108.06 1771.02 108.77C1771.02 109.479 1771.6 110.055 1772.31 110.055C1773.02 110.055 1773.59 109.479 1773.59 108.77C1773.59 108.06 1773.02 107.485 1772.31 107.485Z" />
        <path d="M1754.36 107.485C1753.65 107.485 1753.07 108.06 1753.07 108.77C1753.07 109.479 1753.65 110.055 1754.36 110.055C1755.07 110.055 1755.64 109.479 1755.64 108.77C1755.64 108.06 1755.07 107.485 1754.36 107.485Z" />
        <path d="M1736.43 107.485C1735.72 107.485 1735.14 108.06 1735.14 108.77C1735.14 109.479 1735.72 110.055 1736.43 110.055C1737.14 110.055 1737.71 109.479 1737.71 108.77C1737.71 108.06 1737.14 107.485 1736.43 107.485Z" />
        <path d="M1718.48 107.485C1717.77 107.485 1717.2 108.06 1717.2 108.77C1717.2 109.479 1717.77 110.055 1718.48 110.055C1719.19 110.055 1719.76 109.479 1719.76 108.77C1719.76 108.06 1719.19 107.485 1718.48 107.485Z" />
        <path d="M1700.55 107.485C1699.84 107.485 1699.27 108.06 1699.27 108.77C1699.27 109.479 1699.84 110.055 1700.55 110.055C1701.26 110.055 1701.83 109.479 1701.83 108.77C1701.83 108.06 1701.26 107.485 1700.55 107.485Z" />
        <path d="M1682.6 107.485C1681.89 107.485 1681.32 108.06 1681.32 108.77C1681.32 109.479 1681.89 110.055 1682.6 110.055C1683.31 110.055 1683.89 109.479 1683.89 108.77C1683.89 108.06 1683.31 107.485 1682.6 107.485Z" />
        <path d="M1664.67 107.485C1663.96 107.485 1663.39 108.06 1663.39 108.77C1663.39 109.479 1663.96 110.055 1664.67 110.055C1665.38 110.055 1665.96 109.479 1665.96 108.77C1665.96 108.06 1665.38 107.485 1664.67 107.485Z" />
        <path d="M1646.72 107.485C1646.01 107.485 1645.44 108.06 1645.44 108.77C1645.44 109.479 1646.01 110.055 1646.72 110.055C1647.43 110.055 1648.01 109.479 1648.01 108.77C1648.01 108.06 1647.43 107.485 1646.72 107.485Z" />
        <path d="M1628.8 107.485C1628.09 107.485 1627.51 108.06 1627.51 108.77C1627.51 109.479 1628.09 110.055 1628.8 110.055C1629.5 110.055 1630.08 109.479 1630.08 108.77C1630.08 108.06 1629.5 107.485 1628.8 107.485Z" />
        <path d="M1610.84 107.485C1610.14 107.485 1609.56 108.06 1609.56 108.77C1609.56 109.479 1610.14 110.055 1610.84 110.055C1611.55 110.055 1612.13 109.479 1612.13 108.77C1612.13 108.06 1611.55 107.485 1610.84 107.485Z" />
        <path d="M1592.92 107.485C1592.21 107.485 1591.63 108.06 1591.63 108.77C1591.63 109.479 1592.21 110.055 1592.92 110.055C1593.63 110.055 1594.2 109.479 1594.2 108.77C1594.2 108.06 1593.63 107.485 1592.92 107.485Z" />
        <path d="M1574.96 107.485C1574.26 107.485 1573.68 108.06 1573.68 108.77C1573.68 109.479 1574.26 110.055 1574.96 110.055C1575.67 110.055 1576.25 109.479 1576.25 108.77C1576.25 108.06 1575.67 107.485 1574.96 107.485Z" />
        <path d="M1557.04 107.485C1556.33 107.485 1555.76 108.06 1555.76 108.77C1555.76 109.479 1556.33 110.055 1557.04 110.055C1557.75 110.055 1558.32 109.479 1558.32 108.77C1558.32 108.06 1557.75 107.485 1557.04 107.485Z" />
        <path d="M1539.09 107.485C1538.38 107.485 1537.8 108.06 1537.8 108.77C1537.8 109.479 1538.38 110.055 1539.09 110.055C1539.8 110.055 1540.37 109.479 1540.37 108.77C1540.37 108.06 1539.8 107.485 1539.09 107.485Z" />
        <path d="M1521.16 107.485C1520.45 107.485 1519.88 108.06 1519.88 108.77C1519.88 109.479 1520.45 110.055 1521.16 110.055C1521.87 110.055 1522.44 109.479 1522.44 108.77C1522.44 108.06 1521.87 107.485 1521.16 107.485Z" />
        <path d="M1503.21 107.485C1502.5 107.485 1501.93 108.06 1501.93 108.77C1501.93 109.479 1502.5 110.055 1503.21 110.055C1503.92 110.055 1504.49 109.479 1504.49 108.77C1504.49 108.06 1503.92 107.485 1503.21 107.485Z" />
        <path d="M1485.26 107.485C1484.55 107.485 1483.97 108.06 1483.97 108.77C1483.97 109.479 1484.55 110.055 1485.26 110.055C1485.97 110.055 1486.54 109.479 1486.54 108.77C1486.54 108.06 1485.97 107.485 1485.26 107.485Z" />
        <path d="M1467.33 107.485C1466.62 107.485 1466.05 108.06 1466.05 108.77C1466.05 109.479 1466.62 110.055 1467.33 110.055C1468.04 110.055 1468.62 109.479 1468.62 108.77C1468.62 108.06 1468.04 107.485 1467.33 107.485Z" />
        <path d="M1449.38 107.485C1448.67 107.485 1448.1 108.06 1448.1 108.77C1448.1 109.479 1448.67 110.055 1449.38 110.055C1450.09 110.055 1450.66 109.479 1450.66 108.77C1450.66 108.06 1450.09 107.485 1449.38 107.485Z" />
        <path d="M1431.45 107.485C1430.74 107.485 1430.17 108.06 1430.17 108.77C1430.17 109.479 1430.74 110.055 1431.45 110.055C1432.16 110.055 1432.74 109.479 1432.74 108.77C1432.74 108.06 1432.16 107.485 1431.45 107.485Z" />
        <path d="M1413.5 107.485C1412.79 107.485 1412.22 108.06 1412.22 108.77C1412.22 109.479 1412.79 110.055 1413.5 110.055C1414.21 110.055 1414.79 109.479 1414.79 108.77C1414.79 108.06 1414.21 107.485 1413.5 107.485Z" />
        <path d="M1395.58 107.485C1394.87 107.485 1394.29 108.06 1394.29 108.77C1394.29 109.479 1394.87 110.055 1395.58 110.055C1396.29 110.055 1396.86 109.479 1396.86 108.77C1396.86 108.06 1396.29 107.485 1395.58 107.485Z" />
        <path d="M1377.62 107.485C1376.91 107.485 1376.34 108.06 1376.34 108.77C1376.34 109.479 1376.91 110.055 1377.62 110.055C1378.33 110.055 1378.91 109.479 1378.91 108.77C1378.91 108.06 1378.33 107.485 1377.62 107.485Z" />
        <path d="M1359.7 107.485C1358.99 107.485 1358.41 108.06 1358.41 108.77C1358.41 109.479 1358.99 110.055 1359.7 110.055C1360.41 110.055 1360.98 109.479 1360.98 108.77C1360.98 108.06 1360.41 107.485 1359.7 107.485Z" />
        <path d="M1341.74 107.485C1341.04 107.485 1340.46 108.06 1340.46 108.77C1340.46 109.479 1341.04 110.055 1341.74 110.055C1342.45 110.055 1343.03 109.479 1343.03 108.77C1343.03 108.06 1342.45 107.485 1341.74 107.485Z" />
        <path d="M1323.82 107.485C1323.11 107.485 1322.53 108.06 1322.53 108.77C1322.53 109.479 1323.11 110.055 1323.82 110.055C1324.53 110.055 1325.1 109.479 1325.1 108.77C1325.1 108.06 1324.53 107.485 1323.82 107.485Z" />
        <path d="M1305.87 107.485C1305.16 107.485 1304.58 108.06 1304.58 108.77C1304.58 109.479 1305.16 110.055 1305.87 110.055C1306.58 110.055 1307.15 109.479 1307.15 108.77C1307.15 108.06 1306.58 107.485 1305.87 107.485Z" />
        <path d="M1287.94 107.485C1287.23 107.485 1286.66 108.06 1286.66 108.77C1286.66 109.479 1287.23 110.055 1287.94 110.055C1288.65 110.055 1289.22 109.479 1289.22 108.77C1289.22 108.06 1288.65 107.485 1287.94 107.485Z" />
        <path d="M1269.99 107.485C1269.28 107.485 1268.7 108.06 1268.7 108.77C1268.7 109.479 1269.28 110.055 1269.99 110.055C1270.7 110.055 1271.27 109.479 1271.27 108.77C1271.27 108.06 1270.7 107.485 1269.99 107.485Z" />
        <path d="M1252.06 107.485C1251.35 107.485 1250.78 108.06 1250.78 108.77C1250.78 109.479 1251.35 110.055 1252.06 110.055C1252.77 110.055 1253.34 109.479 1253.34 108.77C1253.34 108.06 1252.77 107.485 1252.06 107.485Z" />
        <path d="M1234.11 107.485C1233.4 107.485 1232.83 108.06 1232.83 108.77C1232.83 109.479 1233.4 110.055 1234.11 110.055C1234.82 110.055 1235.39 109.479 1235.39 108.77C1235.39 108.06 1234.82 107.485 1234.11 107.485Z" />
        <path d="M1216.18 107.485C1215.47 107.485 1214.9 108.06 1214.9 108.77C1214.9 109.479 1215.47 110.055 1216.18 110.055C1216.89 110.055 1217.47 109.479 1217.47 108.77C1217.47 108.06 1216.89 107.485 1216.18 107.485Z" />
        <path d="M1198.23 107.485C1197.52 107.485 1196.95 108.06 1196.95 108.77C1196.95 109.479 1197.52 110.055 1198.23 110.055C1198.94 110.055 1199.52 109.479 1199.52 108.77C1199.52 108.06 1198.94 107.485 1198.23 107.485Z" />
        <path d="M1180.31 107.485C1179.6 107.485 1179.02 108.06 1179.02 108.77C1179.02 109.479 1179.6 110.055 1180.31 110.055C1181.01 110.055 1181.59 109.479 1181.59 108.77C1181.59 108.06 1181.01 107.485 1180.31 107.485Z" />
        <path d="M1162.35 107.485C1161.64 107.485 1161.07 108.06 1161.07 108.77C1161.07 109.479 1161.64 110.055 1162.35 110.055C1163.06 110.055 1163.64 109.479 1163.64 108.77C1163.64 108.06 1163.06 107.485 1162.35 107.485Z" />
        <path d="M1144.43 107.485C1143.72 107.485 1143.14 108.06 1143.14 108.77C1143.14 109.479 1143.72 110.055 1144.43 110.055C1145.13 110.055 1145.71 109.479 1145.71 108.77C1145.71 108.06 1145.13 107.485 1144.43 107.485Z" />
        <path d="M1126.48 107.485C1125.77 107.485 1125.19 108.06 1125.19 108.77C1125.19 109.479 1125.77 110.055 1126.48 110.055C1127.19 110.055 1127.76 109.479 1127.76 108.77C1127.76 108.06 1127.19 107.485 1126.48 107.485Z" />
        <path d="M1108.55 107.485C1107.84 107.485 1107.27 108.06 1107.27 108.77C1107.27 109.479 1107.84 110.055 1108.55 110.055C1109.26 110.055 1109.83 109.479 1109.83 108.77C1109.83 108.06 1109.26 107.485 1108.55 107.485Z" />
        <path d="M1090.6 107.485C1089.89 107.485 1089.31 108.06 1089.31 108.77C1089.31 109.479 1089.89 110.055 1090.6 110.055C1091.31 110.055 1091.88 109.479 1091.88 108.77C1091.88 108.06 1091.31 107.485 1090.6 107.485Z" />
        <path d="M1072.67 107.485C1071.96 107.485 1071.39 108.06 1071.39 108.77C1071.39 109.479 1071.96 110.055 1072.67 110.055C1073.38 110.055 1073.95 109.479 1073.95 108.77C1073.95 108.06 1073.38 107.485 1072.67 107.485Z" />
        <path d="M1054.72 107.485C1054.01 107.485 1053.44 108.06 1053.44 108.77C1053.44 109.479 1054.01 110.055 1054.72 110.055C1055.43 110.055 1056 109.479 1056 108.77C1056 108.06 1055.43 107.485 1054.72 107.485Z" />
        <path d="M1036.77 107.485C1036.06 107.485 1035.48 108.06 1035.48 108.77C1035.48 109.479 1036.06 110.055 1036.77 110.055C1037.48 110.055 1038.05 109.479 1038.05 108.77C1038.05 108.06 1037.48 107.485 1036.77 107.485Z" />
        <path d="M1018.84 107.485C1018.13 107.485 1017.56 108.06 1017.56 108.77C1017.56 109.479 1018.13 110.055 1018.84 110.055C1019.55 110.055 1020.13 109.479 1020.13 108.77C1020.13 108.06 1019.55 107.485 1018.84 107.485Z" />
        <path d="M1000.89 107.485C1000.18 107.485 999.605 108.06 999.605 108.77C999.605 109.479 1000.18 110.055 1000.89 110.055C1001.6 110.055 1002.17 109.479 1002.17 108.77C1002.17 108.06 1001.6 107.485 1000.89 107.485Z" />
        <path d="M982.962 107.485C982.253 107.485 981.678 108.06 981.678 108.77C981.678 109.479 982.253 110.055 982.962 110.055C983.671 110.055 984.246 109.479 984.246 108.77C984.246 108.06 983.671 107.485 982.962 107.485Z" />
        <path d="M965.011 107.485C964.302 107.485 963.727 108.06 963.727 108.77C963.727 109.479 964.302 110.055 965.011 110.055C965.72 110.055 966.295 109.479 966.295 108.77C966.295 108.06 965.72 107.485 965.011 107.485Z" />
        <path d="M1906.86 89.5496C1906.15 89.5496 1905.57 90.1248 1905.57 90.8343C1905.57 91.5438 1906.15 92.119 1906.86 92.119C1907.57 92.119 1908.14 91.5438 1908.14 90.8343C1908.14 90.1248 1907.57 89.5496 1906.86 89.5496Z" />
        <path d="M1888.91 89.5496C1888.2 89.5496 1887.62 90.1248 1887.62 90.8343C1887.62 91.5438 1888.2 92.119 1888.91 92.119C1889.61 92.119 1890.19 91.5438 1890.19 90.8343C1890.19 90.1248 1889.61 89.5496 1888.91 89.5496Z" />
        <path d="M1870.98 89.5496C1870.27 89.5496 1869.7 90.1248 1869.7 90.8343C1869.7 91.5438 1870.27 92.119 1870.98 92.119C1871.69 92.119 1872.26 91.5438 1872.26 90.8343C1872.26 90.1248 1871.69 89.5496 1870.98 89.5496Z" />
        <path d="M1853.03 89.5496C1852.32 89.5496 1851.74 90.1248 1851.74 90.8343C1851.74 91.5438 1852.32 92.119 1853.03 92.119C1853.74 92.119 1854.31 91.5438 1854.31 90.8343C1854.31 90.1248 1853.74 89.5496 1853.03 89.5496Z" />
        <path d="M1835.1 89.5496C1834.39 89.5496 1833.82 90.1248 1833.82 90.8343C1833.82 91.5438 1834.39 92.119 1835.1 92.119C1835.81 92.119 1836.39 91.5438 1836.39 90.8343C1836.39 90.1248 1835.81 89.5496 1835.1 89.5496Z" />
        <path d="M1817.15 89.5496C1816.44 89.5496 1815.87 90.1248 1815.87 90.8343C1815.87 91.5438 1816.44 92.119 1817.15 92.119C1817.86 92.119 1818.43 91.5438 1818.43 90.8343C1818.43 90.1248 1817.86 89.5496 1817.15 89.5496Z" />
        <path d="M1799.22 89.5496C1798.51 89.5496 1797.94 90.1248 1797.94 90.8343C1797.94 91.5438 1798.51 92.119 1799.22 92.119C1799.93 92.119 1800.51 91.5438 1800.51 90.8343C1800.51 90.1248 1799.93 89.5496 1799.22 89.5496Z" />
        <path d="M1781.27 89.5496C1780.56 89.5496 1779.99 90.1248 1779.99 90.8343C1779.99 91.5438 1780.56 92.119 1781.27 92.119C1781.98 92.119 1782.56 91.5438 1782.56 90.8343C1782.56 90.1248 1781.98 89.5496 1781.27 89.5496Z" />
        <path d="M1763.35 89.5498C1762.64 89.5498 1762.06 90.1249 1762.06 90.8344C1762.06 91.5439 1762.64 92.1191 1763.35 92.1191C1764.05 92.1191 1764.63 91.5439 1764.63 90.8344C1764.63 90.1249 1764.05 89.5498 1763.35 89.5498Z" />
        <path d="M1745.39 89.5496C1744.69 89.5496 1744.11 90.1248 1744.11 90.8343C1744.11 91.5438 1744.69 92.119 1745.39 92.119C1746.1 92.119 1746.68 91.5438 1746.68 90.8343C1746.68 90.1248 1746.1 89.5496 1745.39 89.5496Z" />
        <path d="M1727.47 89.5496C1726.76 89.5496 1726.18 90.1248 1726.18 90.8343C1726.18 91.5438 1726.76 92.119 1727.47 92.119C1728.17 92.119 1728.75 91.5438 1728.75 90.8343C1728.75 90.1248 1728.17 89.5496 1727.47 89.5496Z" />
        <path d="M1709.51 89.5496C1708.81 89.5496 1708.23 90.1248 1708.23 90.8343C1708.23 91.5438 1708.81 92.119 1709.51 92.119C1710.22 92.119 1710.8 91.5438 1710.8 90.8343C1710.8 90.1248 1710.22 89.5496 1709.51 89.5496Z" />
        <path d="M1691.56 89.5498C1690.85 89.5498 1690.28 90.1249 1690.28 90.8344C1690.28 91.5439 1690.85 92.1191 1691.56 92.1191C1692.27 92.1191 1692.85 91.5439 1692.85 90.8344C1692.85 90.1249 1692.27 89.5498 1691.56 89.5498Z" />
        <path d="M1673.64 89.5498C1672.93 89.5498 1672.35 90.1249 1672.35 90.8344C1672.35 91.5439 1672.93 92.1191 1673.64 92.1191C1674.35 92.1191 1674.92 91.5439 1674.92 90.8344C1674.92 90.1249 1674.35 89.5498 1673.64 89.5498Z" />
        <path d="M1655.69 89.5496C1654.98 89.5496 1654.4 90.1248 1654.4 90.8343C1654.4 91.5438 1654.98 92.119 1655.69 92.119C1656.4 92.119 1656.97 91.5438 1656.97 90.8343C1656.97 90.1248 1656.4 89.5496 1655.69 89.5496Z" />
        <path d="M1637.76 89.5496C1637.05 89.5496 1636.48 90.1248 1636.48 90.8343C1636.48 91.5438 1637.05 92.119 1637.76 92.119C1638.47 92.119 1639.04 91.5438 1639.04 90.8343C1639.04 90.1248 1638.47 89.5496 1637.76 89.5496Z" />
        <path d="M1619.81 89.5496C1619.1 89.5496 1618.52 90.1248 1618.52 90.8343C1618.52 91.5438 1619.1 92.119 1619.81 92.119C1620.52 92.119 1621.09 91.5438 1621.09 90.8343C1621.09 90.1248 1620.52 89.5496 1619.81 89.5496Z" />
        <path d="M1601.88 89.5496C1601.17 89.5496 1600.6 90.1248 1600.6 90.8343C1600.6 91.5438 1601.17 92.119 1601.88 92.119C1602.59 92.119 1603.17 91.5438 1603.17 90.8343C1603.17 90.1248 1602.59 89.5496 1601.88 89.5496Z" />
        <path d="M1583.93 89.5496C1583.22 89.5496 1582.64 90.1248 1582.64 90.8343C1582.64 91.5438 1583.22 92.119 1583.93 92.119C1584.64 92.119 1585.21 91.5438 1585.21 90.8343C1585.21 90.1248 1584.64 89.5496 1583.93 89.5496Z" />
        <path d="M1566 89.5496C1565.29 89.5496 1564.72 90.1248 1564.72 90.8343C1564.72 91.5438 1565.29 92.119 1566 92.119C1566.71 92.119 1567.29 91.5438 1567.29 90.8343C1567.29 90.1248 1566.71 89.5496 1566 89.5496Z" />
        <path d="M1548.05 89.5496C1547.34 89.5496 1546.77 90.1248 1546.77 90.8343C1546.77 91.5438 1547.34 92.119 1548.05 92.119C1548.76 92.119 1549.33 91.5438 1549.33 90.8343C1549.33 90.1248 1548.76 89.5496 1548.05 89.5496Z" />
        <path d="M1530.12 89.5496C1529.41 89.5496 1528.84 90.1248 1528.84 90.8343C1528.84 91.5438 1529.41 92.119 1530.12 92.119C1530.83 92.119 1531.41 91.5438 1531.41 90.8343C1531.41 90.1248 1530.83 89.5496 1530.12 89.5496Z" />
        <path d="M1512.17 89.5496C1511.46 89.5496 1510.89 90.1248 1510.89 90.8343C1510.89 91.5438 1511.46 92.1189 1512.17 92.1189C1512.88 92.1189 1513.46 91.5438 1513.46 90.8343C1513.46 90.1248 1512.88 89.5496 1512.17 89.5496Z" />
        <path d="M1494.25 89.5496C1493.54 89.5496 1492.96 90.1248 1492.96 90.8343C1492.96 91.5438 1493.54 92.1189 1494.25 92.1189C1494.95 92.1189 1495.53 91.5438 1495.53 90.8343C1495.53 90.1248 1494.95 89.5496 1494.25 89.5496Z" />
        <path d="M1476.29 89.5496C1475.58 89.5496 1475.01 90.1248 1475.01 90.8343C1475.01 91.5438 1475.58 92.1189 1476.29 92.1189C1477 92.1189 1477.58 91.5438 1477.58 90.8343C1477.58 90.1248 1477 89.5496 1476.29 89.5496Z" />
        <path d="M1458.37 89.5498C1457.66 89.5498 1457.08 90.1249 1457.08 90.8344C1457.08 91.5439 1457.66 92.1191 1458.37 92.1191C1459.08 92.1191 1459.65 91.5439 1459.65 90.8344C1459.65 90.1249 1459.08 89.5498 1458.37 89.5498Z" />
        <path d="M1440.42 89.5498C1439.71 89.5498 1439.13 90.1249 1439.13 90.8344C1439.13 91.5439 1439.71 92.1191 1440.42 92.1191C1441.13 92.1191 1441.7 91.5439 1441.7 90.8344C1441.7 90.1249 1441.13 89.5498 1440.42 89.5498Z" />
        <path d="M1422.49 89.5249C1421.77 89.5249 1421.21 90.0931 1421.21 90.8095C1421.21 91.526 1421.77 92.0942 1422.49 92.0942C1423.21 92.0942 1423.77 91.526 1423.77 90.8095C1423.77 90.1178 1423.21 89.5249 1422.49 89.5249Z" />
        <path d="M1404.54 89.5496C1403.83 89.5496 1403.25 90.1248 1403.25 90.8343C1403.25 91.5438 1403.83 92.1189 1404.54 92.1189C1405.25 92.1189 1405.82 91.5438 1405.82 90.8343C1405.82 90.1248 1405.25 89.5496 1404.54 89.5496Z" />
        <path d="M1386.61 89.5496C1385.9 89.5496 1385.33 90.1248 1385.33 90.8343C1385.33 91.5438 1385.9 92.1189 1386.61 92.1189C1387.32 92.1189 1387.89 91.5438 1387.89 90.8343C1387.89 90.1248 1387.32 89.5496 1386.61 89.5496Z" />
        <path d="M1368.66 89.5498C1367.95 89.5498 1367.38 90.1249 1367.38 90.8344C1367.38 91.5439 1367.95 92.1191 1368.66 92.1191C1369.37 92.1191 1369.94 91.5439 1369.94 90.8344C1369.94 90.1249 1369.37 89.5498 1368.66 89.5498Z" />
        <path d="M1350.73 89.5498C1350.02 89.5498 1349.45 90.1249 1349.45 90.8344C1349.45 91.5439 1350.02 92.1191 1350.73 92.1191C1351.44 92.1191 1352.02 91.5439 1352.02 90.8344C1352.02 90.1249 1351.44 89.5498 1350.73 89.5498Z" />
        <path d="M1332.78 89.5496C1332.07 89.5496 1331.5 90.1248 1331.5 90.8343C1331.5 91.5438 1332.07 92.1189 1332.78 92.1189C1333.49 92.1189 1334.07 91.5438 1334.07 90.8343C1334.07 90.1248 1333.49 89.5496 1332.78 89.5496Z" />
        <path d="M1314.85 89.5496C1314.15 89.5496 1313.57 90.1248 1313.57 90.8343C1313.57 91.5438 1314.15 92.1189 1314.85 92.1189C1315.56 92.1189 1316.14 91.5438 1316.14 90.8343C1316.14 90.1248 1315.56 89.5496 1314.85 89.5496Z" />
        <path d="M1296.9 89.5496C1296.19 89.5496 1295.62 90.1248 1295.62 90.8343C1295.62 91.5438 1296.19 92.1189 1296.9 92.1189C1297.61 92.1189 1298.19 91.5438 1298.19 90.8343C1298.19 90.1248 1297.61 89.5496 1296.9 89.5496Z" />
        <path d="M1278.95 89.5498C1278.24 89.5498 1277.67 90.1249 1277.67 90.8344C1277.67 91.5439 1278.24 92.1191 1278.95 92.1191C1279.66 92.1191 1280.24 91.5439 1280.24 90.8344C1280.24 90.1249 1279.66 89.5498 1278.95 89.5498Z" />
        <path d="M1261.02 89.5498C1260.32 89.5498 1259.74 90.1249 1259.74 90.8344C1259.74 91.5439 1260.32 92.1191 1261.02 92.1191C1261.73 92.1191 1262.31 91.5439 1262.31 90.8344C1262.31 90.1249 1261.73 89.5498 1261.02 89.5498Z" />
        <path d="M1243.07 89.5496C1242.36 89.5496 1241.79 90.1248 1241.79 90.8343C1241.79 91.5438 1242.36 92.1189 1243.07 92.1189C1243.78 92.1189 1244.36 91.5438 1244.36 90.8343C1244.36 90.1248 1243.78 89.5496 1243.07 89.5496Z" />
        <path d="M1225.15 89.5496C1224.44 89.5496 1223.86 90.1248 1223.86 90.8343C1223.86 91.5438 1224.44 92.1189 1225.15 92.1189C1225.86 92.1189 1226.43 91.5438 1226.43 90.8343C1226.43 90.1248 1225.86 89.5496 1225.15 89.5496Z" />
        <path d="M1207.19 89.5498C1206.49 89.5498 1205.91 90.1249 1205.91 90.8344C1205.91 91.5439 1206.49 92.1191 1207.19 92.1191C1207.9 92.1191 1208.48 91.5439 1208.48 90.8344C1208.48 90.1249 1207.9 89.5498 1207.19 89.5498Z" />
        <path d="M1189.27 89.5496C1188.56 89.5496 1187.99 90.1248 1187.99 90.8343C1187.99 91.5438 1188.56 92.1189 1189.27 92.1189C1189.98 92.1189 1190.55 91.5438 1190.55 90.8343C1190.55 90.1248 1189.98 89.5496 1189.27 89.5496Z" />
        <path d="M1171.32 89.5496C1170.61 89.5496 1170.03 90.1248 1170.03 90.8343C1170.03 91.5438 1170.61 92.1189 1171.32 92.1189C1172.03 92.1189 1172.6 91.5438 1172.6 90.8343C1172.6 90.1248 1172.03 89.5496 1171.32 89.5496Z" />
        <path d="M1153.39 89.5497C1152.68 89.5497 1152.11 90.1249 1152.11 90.8344C1152.11 91.5439 1152.68 92.119 1153.39 92.119C1154.1 92.119 1154.67 91.5439 1154.67 90.8344C1154.67 90.1249 1154.1 89.5497 1153.39 89.5497Z" />
        <path d="M1135.44 89.5497C1134.73 89.5497 1134.15 90.1249 1134.15 90.8344C1134.15 91.5439 1134.73 92.119 1135.44 92.119C1136.15 92.119 1136.72 91.5439 1136.72 90.8344C1136.72 90.1249 1136.15 89.5497 1135.44 89.5497Z" />
        <path d="M1117.51 89.5497C1116.8 89.5497 1116.23 90.1249 1116.23 90.8344C1116.23 91.5439 1116.8 92.119 1117.51 92.119C1118.22 92.119 1118.8 91.5439 1118.8 90.8344C1118.8 90.1249 1118.22 89.5497 1117.51 89.5497Z" />
        <path d="M1099.56 89.5497C1098.85 89.5497 1098.28 90.1249 1098.28 90.8344C1098.28 91.5439 1098.85 92.119 1099.56 92.119C1100.27 92.119 1100.84 91.5439 1100.84 90.8344C1100.84 90.1249 1100.27 89.5497 1099.56 89.5497Z" />
        <path d="M1081.63 89.5496C1080.92 89.5496 1080.35 90.1248 1080.35 90.8343C1080.35 91.5438 1080.92 92.1189 1081.63 92.1189C1082.34 92.1189 1082.92 91.5438 1082.92 90.8343C1082.92 90.1248 1082.34 89.5496 1081.63 89.5496Z" />
        <path d="M1063.68 89.5496C1062.97 89.5496 1062.4 90.1248 1062.4 90.8343C1062.4 91.5438 1062.97 92.1189 1063.68 92.1189C1064.39 92.1189 1064.97 91.5438 1064.97 90.8343C1064.97 90.1248 1064.39 89.5496 1063.68 89.5496Z" />
        <path d="M1045.75 89.5496C1045.05 89.5496 1044.47 90.1248 1044.47 90.8343C1044.47 91.5438 1045.05 92.1189 1045.75 92.1189C1046.46 92.1189 1047.04 91.5438 1047.04 90.8343C1047.04 90.1248 1046.46 89.5496 1045.75 89.5496Z" />
        <path d="M1027.8 89.5497C1027.1 89.5497 1026.52 90.1249 1026.52 90.8344C1026.52 91.5439 1027.1 92.119 1027.8 92.119C1028.51 92.119 1029.09 91.5439 1029.09 90.8344C1029.09 90.1249 1028.51 89.5497 1027.8 89.5497Z" />
        <path d="M1009.88 89.5496C1009.17 89.5496 1008.59 90.1248 1008.59 90.8343C1008.59 91.5438 1009.17 92.1189 1009.88 92.1189C1010.59 92.1189 1011.16 91.5438 1011.16 90.8343C1011.16 90.1248 1010.59 89.5496 1009.88 89.5496Z" />
        <path d="M991.926 89.5497C991.217 89.5497 990.642 90.1249 990.642 90.8344C990.642 91.5439 991.217 92.119 991.926 92.119C992.635 92.119 993.21 91.5439 993.21 90.8344C993.21 90.1249 992.635 89.5497 991.926 89.5497Z" />
        <path d="M973.998 89.5496C973.289 89.5496 972.714 90.1248 972.714 90.8343C972.714 91.5438 973.289 92.1189 973.998 92.1189C974.707 92.1189 975.282 91.5438 975.282 90.8343C975.282 90.1248 974.707 89.5496 973.998 89.5496Z" />
        <path d="M1915.82 72.0335C1915.35 72.0335 1914.98 72.4041 1914.98 72.8735C1914.98 73.3429 1915.35 73.7136 1915.82 73.7136C1916.29 73.7136 1916.66 73.3429 1916.66 72.8735C1916.66 72.4041 1916.29 72.0335 1915.82 72.0335Z" />
        <path d="M1897.87 72.0335C1897.4 72.0335 1897.03 72.4041 1897.03 72.8735C1897.03 73.3429 1897.4 73.7136 1897.87 73.7136C1898.34 73.7136 1898.71 73.3429 1898.71 72.8735C1898.73 72.4041 1898.34 72.0335 1897.87 72.0335Z" />
        <path d="M1879.94 72.0335C1879.47 72.0335 1879.1 72.4041 1879.1 72.8735C1879.1 73.3429 1879.47 73.7136 1879.94 73.7136C1880.41 73.7136 1880.78 73.3429 1880.78 72.8735C1880.78 72.4041 1880.41 72.0335 1879.94 72.0335Z" />
        <path d="M1861.99 72.0335C1861.52 72.0335 1861.15 72.4041 1861.15 72.8735C1861.15 73.3429 1861.52 73.7136 1861.99 73.7136C1862.46 73.7136 1862.83 73.3429 1862.83 72.8735C1862.86 72.4041 1862.46 72.0335 1861.99 72.0335Z" />
        <path d="M1844.07 72.0335C1843.6 72.0335 1843.23 72.4041 1843.23 72.8735C1843.23 73.3429 1843.6 73.7136 1844.07 73.7136C1844.53 73.7136 1844.9 73.3429 1844.9 72.8735C1844.9 72.4041 1844.53 72.0335 1844.07 72.0335Z" />
        <path d="M1826.12 72.0335C1825.65 72.0335 1825.28 72.4041 1825.28 72.8735C1825.28 73.3429 1825.65 73.7136 1826.12 73.7136C1826.58 73.7136 1826.95 73.3429 1826.95 72.8735C1826.98 72.4041 1826.58 72.0335 1826.12 72.0335Z" />
        <path d="M1808.19 72.0335C1807.72 72.0335 1807.35 72.4041 1807.35 72.8735C1807.35 73.3429 1807.72 73.7136 1808.19 73.7136C1808.66 73.7136 1809.03 73.3429 1809.03 72.8735C1809.03 72.4041 1808.66 72.0335 1808.19 72.0335Z" />
        <path d="M1790.23 72.0335C1789.76 72.0335 1789.39 72.4041 1789.39 72.8735C1789.39 73.3429 1789.76 73.7136 1790.23 73.7136C1790.7 73.7136 1791.07 73.3429 1791.07 72.8735C1791.1 72.4041 1790.7 72.0335 1790.23 72.0335Z" />
        <path d="M1772.31 72.0335C1771.84 72.0335 1771.47 72.4041 1771.47 72.8735C1771.47 73.3429 1771.84 73.7136 1772.31 73.7136C1772.78 73.7136 1773.15 73.3429 1773.15 72.8735C1773.15 72.4041 1772.78 72.0335 1772.31 72.0335Z" />
        <path d="M1754.36 72.0335C1753.89 72.0335 1753.52 72.4041 1753.52 72.8735C1753.52 73.3429 1753.89 73.7136 1754.36 73.7136C1754.83 73.7136 1755.2 73.3429 1755.2 72.8735C1755.22 72.4041 1754.83 72.0335 1754.36 72.0335Z" />
        <path d="M1736.43 72.0335C1735.96 72.0335 1735.59 72.4041 1735.59 72.8735C1735.59 73.3429 1735.96 73.7136 1736.43 73.7136C1736.9 73.7136 1737.27 73.3429 1737.27 72.8735C1737.27 72.4041 1736.9 72.0335 1736.43 72.0335Z" />
        <path d="M1718.48 72.0335C1718.01 72.0335 1717.64 72.4041 1717.64 72.8735C1717.64 73.3429 1718.01 73.7136 1718.48 73.7136C1718.95 73.7136 1719.32 73.3429 1719.32 72.8735C1719.32 72.4041 1718.95 72.0335 1718.48 72.0335Z" />
        <path d="M1700.55 72.0335C1700.08 72.0335 1699.71 72.4041 1699.71 72.8735C1699.71 73.3429 1700.08 73.7136 1700.55 73.7136C1701.02 73.7136 1701.39 73.3429 1701.39 72.8735C1701.39 72.4041 1701.02 72.0335 1700.55 72.0335Z" />
        <path d="M1682.6 72.0335C1682.13 72.0335 1681.76 72.4041 1681.76 72.8735C1681.76 73.3429 1682.13 73.7136 1682.6 73.7136C1683.07 73.7136 1683.44 73.3429 1683.44 72.8735C1683.44 72.4041 1683.07 72.0335 1682.6 72.0335Z" />
        <path d="M1664.67 72.0335C1664.2 72.0335 1663.83 72.4041 1663.83 72.8735C1663.83 73.3429 1664.2 73.7136 1664.67 73.7136C1665.14 73.7136 1665.51 73.3429 1665.51 72.8735C1665.51 72.4041 1665.14 72.0335 1664.67 72.0335Z" />
        <path d="M1646.72 72.0335C1646.25 72.0335 1645.88 72.4041 1645.88 72.8735C1645.88 73.3429 1646.25 73.7136 1646.72 73.7136C1647.19 73.7136 1647.56 73.3429 1647.56 72.8735C1647.56 72.4041 1647.19 72.0335 1646.72 72.0335Z" />
        <path d="M1628.8 72.0335C1628.33 72.0335 1627.96 72.404 1627.96 72.8734C1627.96 73.3429 1628.33 73.7135 1628.8 73.7135C1629.27 73.7135 1629.64 73.3429 1629.64 72.8734C1629.64 72.404 1629.27 72.0335 1628.8 72.0335Z" />
        <path d="M1610.84 72.0335C1610.37 72.0335 1610 72.4041 1610 72.8735C1610 73.3429 1610.37 73.7136 1610.84 73.7136C1611.31 73.7136 1611.68 73.3429 1611.68 72.8735C1611.68 72.4041 1611.31 72.0335 1610.84 72.0335Z" />
        <path d="M1592.92 72.0335C1592.45 72.0335 1592.08 72.4041 1592.08 72.8735C1592.08 73.3429 1592.45 73.7136 1592.92 73.7136C1593.39 73.7136 1593.76 73.3429 1593.76 72.8735C1593.76 72.4041 1593.39 72.0335 1592.92 72.0335Z" />
        <path d="M1574.97 72.0335C1574.5 72.0335 1574.13 72.4041 1574.13 72.8735C1574.13 73.3429 1574.5 73.7136 1574.97 73.7136C1575.43 73.7136 1575.8 73.3429 1575.8 72.8735C1575.8 72.4041 1575.43 72.0335 1574.97 72.0335Z" />
        <path d="M1557.04 72.0335C1556.57 72.0335 1556.2 72.4041 1556.2 72.8735C1556.2 73.3429 1556.57 73.7136 1557.04 73.7136C1557.51 73.7136 1557.88 73.3429 1557.88 72.8735C1557.88 72.4041 1557.48 72.0335 1557.04 72.0335Z" />
        <path d="M1539.09 72.0335C1538.62 72.0335 1538.25 72.4041 1538.25 72.8735C1538.25 73.3429 1538.62 73.7136 1539.09 73.7136C1539.56 73.7136 1539.93 73.3429 1539.93 72.8735C1539.93 72.4041 1539.56 72.0335 1539.09 72.0335Z" />
        <path d="M1521.16 72.0335C1520.69 72.0335 1520.32 72.4041 1520.32 72.8735C1520.32 73.3429 1520.69 73.7136 1521.16 73.7136C1521.63 73.7136 1522 73.3429 1522 72.8735C1522 72.4041 1521.61 72.0335 1521.16 72.0335Z" />
        <path d="M1503.21 72.0335C1502.74 72.0335 1502.37 72.404 1502.37 72.8734C1502.37 73.3429 1502.74 73.7135 1503.21 73.7135C1503.68 73.7135 1504.05 73.3429 1504.05 72.8734C1504.05 72.404 1503.68 72.0335 1503.21 72.0335Z" />
        <path d="M1485.26 72.0335C1484.79 72.0335 1484.42 72.404 1484.42 72.8734C1484.42 73.3429 1484.79 73.7135 1485.26 73.7135C1485.73 73.7135 1486.1 73.3429 1486.1 72.8734C1486.12 72.404 1485.73 72.0335 1485.26 72.0335Z" />
        <path d="M1467.33 72.0335C1466.86 72.0335 1466.49 72.404 1466.49 72.8734C1466.49 73.3429 1466.86 73.7135 1467.33 73.7135C1467.8 73.7135 1468.17 73.3429 1468.17 72.8734C1468.17 72.404 1467.8 72.0335 1467.33 72.0335Z" />
        <path d="M1449.38 72.0335C1448.91 72.0335 1448.54 72.404 1448.54 72.8734C1448.54 73.3429 1448.91 73.7135 1449.38 73.7135C1449.85 73.7135 1450.22 73.3429 1450.22 72.8734C1450.24 72.404 1449.85 72.0335 1449.38 72.0335Z" />
        <path d="M1431.45 72.0335C1430.98 72.0335 1430.61 72.404 1430.61 72.8734C1430.61 73.3429 1430.98 73.7135 1431.45 73.7135C1431.92 73.7135 1432.29 73.3429 1432.29 72.8734C1432.29 72.404 1431.92 72.0335 1431.45 72.0335Z" />
        <path d="M1413.5 72.0335C1413.03 72.0335 1412.66 72.404 1412.66 72.8734C1412.66 73.3429 1413.03 73.7135 1413.5 73.7135C1413.97 73.7135 1414.34 73.3429 1414.34 72.8734C1414.37 72.404 1413.97 72.0335 1413.5 72.0335Z" />
        <path d="M1395.57 72.0335C1395.1 72.0335 1394.73 72.404 1394.73 72.8734C1394.73 73.3429 1395.1 73.7135 1395.57 73.7135C1396.04 73.7135 1396.41 73.3429 1396.41 72.8734C1396.41 72.404 1396.04 72.0335 1395.57 72.0335Z" />
        <path d="M1377.62 72.0335C1377.15 72.0335 1376.78 72.404 1376.78 72.8734C1376.78 73.3429 1377.15 73.7135 1377.62 73.7135C1378.09 73.7135 1378.46 73.3429 1378.46 72.8734C1378.49 72.404 1378.09 72.0335 1377.62 72.0335Z" />
        <path d="M1359.7 72.0335C1359.23 72.0335 1358.86 72.404 1358.86 72.8734C1358.86 73.3429 1359.23 73.7135 1359.7 73.7135C1360.16 73.7135 1360.54 73.3429 1360.54 72.8734C1360.54 72.404 1360.16 72.0335 1359.7 72.0335Z" />
        <path d="M1341.75 72.0335C1341.28 72.0335 1340.91 72.404 1340.91 72.8734C1340.91 73.3429 1341.28 73.7135 1341.75 73.7135C1342.21 73.7135 1342.58 73.3429 1342.58 72.8734C1342.61 72.404 1342.21 72.0335 1341.75 72.0335Z" />
        <path d="M1323.82 72.0335C1323.35 72.0335 1322.98 72.404 1322.98 72.8734C1322.98 73.3429 1323.35 73.7135 1323.82 73.7135C1324.29 73.7135 1324.66 73.3429 1324.66 72.8734C1324.66 72.404 1324.29 72.0335 1323.82 72.0335Z" />
        <path d="M1305.87 72.0335C1305.4 72.0335 1305.03 72.404 1305.03 72.8734C1305.03 73.3429 1305.4 73.7135 1305.87 73.7135C1306.34 73.7135 1306.71 73.3429 1306.71 72.8734C1306.73 72.404 1306.34 72.0335 1305.87 72.0335Z" />
        <path d="M1287.94 72.0335C1287.47 72.0335 1287.1 72.404 1287.1 72.8734C1287.1 73.3429 1287.47 73.7135 1287.94 73.7135C1288.41 73.7135 1288.78 73.3429 1288.78 72.8734C1288.78 72.404 1288.41 72.0335 1287.94 72.0335Z" />
        <path d="M1269.99 72.0335C1269.52 72.0335 1269.15 72.404 1269.15 72.8734C1269.15 73.3429 1269.52 73.7135 1269.99 73.7135C1270.46 73.7135 1270.83 73.3429 1270.83 72.8734C1270.83 72.404 1270.46 72.0335 1269.99 72.0335Z" />
        <path d="M1252.06 72.0335C1251.59 72.0335 1251.22 72.404 1251.22 72.8734C1251.22 73.3429 1251.59 73.7135 1252.06 73.7135C1252.53 73.7135 1252.9 73.3429 1252.9 72.8734C1252.9 72.404 1252.53 72.0335 1252.06 72.0335Z" />
        <path d="M1234.11 72.0335C1233.64 72.0335 1233.27 72.404 1233.27 72.8734C1233.27 73.3429 1233.64 73.7135 1234.11 73.7135C1234.58 73.7135 1234.95 73.3429 1234.95 72.8734C1234.95 72.404 1234.58 72.0335 1234.11 72.0335Z" />
        <path d="M1216.18 72.0335C1215.71 72.0335 1215.34 72.404 1215.34 72.8734C1215.34 73.3429 1215.71 73.7135 1216.18 73.7135C1216.65 73.7135 1217.02 73.3429 1217.02 72.8734C1217.02 72.404 1216.65 72.0335 1216.18 72.0335Z" />
        <path d="M1198.23 72.0335C1197.76 72.0335 1197.39 72.404 1197.39 72.8734C1197.39 73.3429 1197.76 73.7135 1198.23 73.7135C1198.7 73.7135 1199.07 73.3429 1199.07 72.8734C1199.07 72.404 1198.7 72.0335 1198.23 72.0335Z" />
        <path d="M1180.31 72.0335C1179.84 72.0335 1179.47 72.404 1179.47 72.8734C1179.47 73.3429 1179.84 73.7135 1180.31 73.7135C1180.77 73.7135 1181.15 73.3429 1181.15 72.8734C1181.15 72.404 1180.77 72.0335 1180.31 72.0335Z" />
        <path d="M1162.35 72.0335C1161.88 72.0335 1161.51 72.404 1161.51 72.8734C1161.51 73.3429 1161.88 73.7135 1162.35 73.7135C1162.82 73.7135 1163.19 73.3429 1163.19 72.8734C1163.19 72.404 1162.82 72.0335 1162.35 72.0335Z" />
        <path d="M1144.43 72.0335C1143.96 72.0335 1143.59 72.404 1143.59 72.8734C1143.59 73.3429 1143.96 73.7135 1144.43 73.7135C1144.9 73.7135 1145.27 73.3429 1145.27 72.8734C1145.27 72.404 1144.9 72.0335 1144.43 72.0335Z" />
        <path d="M1126.48 72.0334C1126.01 72.0334 1125.64 72.404 1125.64 72.8734C1125.64 73.3428 1126.01 73.7134 1126.48 73.7134C1126.94 73.7134 1127.31 73.3428 1127.31 72.8734C1127.31 72.404 1126.94 72.0334 1126.48 72.0334Z" />
        <path d="M1108.55 72.0334C1108.08 72.0334 1107.71 72.404 1107.71 72.8734C1107.71 73.3428 1108.08 73.7134 1108.55 73.7134C1109.02 73.7134 1109.39 73.3428 1109.39 72.8734C1109.39 72.404 1108.99 72.0334 1108.55 72.0334Z" />
        <path d="M1090.6 72.0334C1090.13 72.0334 1089.76 72.404 1089.76 72.8734C1089.76 73.3428 1090.13 73.7134 1090.6 73.7134C1091.07 73.7134 1091.44 73.3428 1091.44 72.8734C1091.44 72.404 1091.07 72.0334 1090.6 72.0334Z" />
        <path d="M1072.67 72.0334C1072.2 72.0334 1071.83 72.404 1071.83 72.8734C1071.83 73.3428 1072.2 73.7134 1072.67 73.7134C1073.14 73.7134 1073.51 73.3428 1073.51 72.8734C1073.51 72.404 1073.12 72.0334 1072.67 72.0334Z" />
        <path d="M1054.72 72.0335C1054.25 72.0335 1053.88 72.404 1053.88 72.8734C1053.88 73.3429 1054.25 73.7135 1054.72 73.7135C1055.19 73.7135 1055.56 73.3429 1055.56 72.8734C1055.56 72.404 1055.19 72.0335 1054.72 72.0335Z" />
        <path d="M1036.77 72.0335C1036.3 72.0335 1035.93 72.404 1035.93 72.8734C1035.93 73.3429 1036.3 73.7135 1036.77 73.7135C1037.24 73.7135 1037.61 73.3429 1037.61 72.8734C1037.63 72.404 1037.24 72.0335 1036.77 72.0335Z" />
        <path d="M1018.84 72.0334C1018.37 72.0334 1018 72.404 1018 72.8734C1018 73.3428 1018.37 73.7134 1018.84 73.7134C1019.31 73.7134 1019.68 73.3428 1019.68 72.8734C1019.68 72.404 1019.31 72.0334 1018.84 72.0334Z" />
        <path d="M1000.89 72.0335C1000.42 72.0335 1000.05 72.404 1000.05 72.8734C1000.05 73.3429 1000.42 73.7135 1000.89 73.7135C1001.36 73.7135 1001.73 73.3429 1001.73 72.8734C1001.75 72.404 1001.36 72.0335 1000.89 72.0335Z" />
        <path d="M982.963 72.0334C982.493 72.0334 982.123 72.404 982.123 72.8734C982.123 73.3428 982.493 73.7134 982.963 73.7134C983.432 73.7134 983.802 73.3428 983.802 72.8734C983.802 72.404 983.432 72.0334 982.963 72.0334Z" />
        <path d="M965.012 72.0335C964.543 72.0335 964.173 72.404 964.173 72.8734C964.173 73.3429 964.543 73.7135 965.012 73.7135C965.481 73.7135 965.852 73.3429 965.852 72.8734C965.876 72.404 965.481 72.0335 965.012 72.0335Z" />
        <path d="M1906.86 54.2954C1906.5 54.2954 1906.22 54.583 1906.22 54.9378C1906.22 55.2925 1906.5 55.5801 1906.86 55.5801C1907.21 55.5801 1907.5 55.2925 1907.5 54.9378C1907.5 54.583 1907.21 54.2954 1906.86 54.2954Z" />
        <path d="M1888.91 54.2954C1888.55 54.2954 1888.26 54.583 1888.26 54.9378C1888.26 55.2925 1888.55 55.5801 1888.91 55.5801C1889.26 55.5801 1889.55 55.2925 1889.55 54.9378C1889.55 54.583 1889.26 54.2954 1888.91 54.2954Z" />
        <path d="M1870.98 54.2954C1870.63 54.2954 1870.34 54.583 1870.34 54.9378C1870.34 55.2925 1870.63 55.5801 1870.98 55.5801C1871.34 55.5801 1871.62 55.2925 1871.62 54.9378C1871.62 54.583 1871.34 54.2954 1870.98 54.2954Z" />
        <path d="M1853.03 54.2954C1852.67 54.2954 1852.39 54.583 1852.39 54.9378C1852.39 55.2925 1852.67 55.5801 1853.03 55.5801C1853.38 55.5801 1853.67 55.2925 1853.67 54.9378C1853.67 54.583 1853.38 54.2954 1853.03 54.2954Z" />
        <path d="M1835.1 54.2954C1834.75 54.2954 1834.46 54.583 1834.46 54.9378C1834.46 55.2925 1834.75 55.5801 1835.1 55.5801C1835.46 55.5801 1835.74 55.2925 1835.74 54.9378C1835.74 54.583 1835.46 54.2954 1835.1 54.2954Z" />
        <path d="M1817.15 54.2955C1816.8 54.2955 1816.51 54.5831 1816.51 54.9379C1816.51 55.2927 1816.8 55.5802 1817.15 55.5802C1817.5 55.5802 1817.79 55.2927 1817.79 54.9379C1817.79 54.5831 1817.5 54.2955 1817.15 54.2955Z" />
        <path d="M1799.22 54.2954C1798.87 54.2954 1798.58 54.583 1798.58 54.9378C1798.58 55.2925 1798.87 55.5801 1799.22 55.5801C1799.58 55.5801 1799.87 55.2925 1799.87 54.9378C1799.87 54.583 1799.58 54.2954 1799.22 54.2954Z" />
        <path d="M1781.27 54.2954C1780.92 54.2954 1780.63 54.583 1780.63 54.9378C1780.63 55.2925 1780.92 55.5801 1781.27 55.5801C1781.63 55.5801 1781.91 55.2925 1781.91 54.9378C1781.91 54.583 1781.63 54.2954 1781.27 54.2954Z" />
        <path d="M1763.35 54.2954C1762.99 54.2954 1762.7 54.583 1762.7 54.9378C1762.7 55.2925 1762.99 55.5801 1763.35 55.5801C1763.7 55.5801 1763.99 55.2925 1763.99 54.9378C1763.99 54.583 1763.7 54.2954 1763.35 54.2954Z" />
        <path d="M1745.39 54.2955C1745.04 54.2955 1744.75 54.5831 1744.75 54.9379C1744.75 55.2927 1745.04 55.5802 1745.39 55.5802C1745.75 55.5802 1746.04 55.2927 1746.04 54.9379C1746.04 54.5831 1745.75 54.2955 1745.39 54.2955Z" />
        <path d="M1727.47 54.2954C1727.11 54.2954 1726.83 54.583 1726.83 54.9378C1726.83 55.2925 1727.11 55.5801 1727.47 55.5801C1727.82 55.5801 1728.11 55.2925 1728.11 54.9378C1728.11 54.583 1727.82 54.2954 1727.47 54.2954Z" />
        <path d="M1709.51 54.2954C1709.16 54.2954 1708.87 54.583 1708.87 54.9378C1708.87 55.2925 1709.16 55.5801 1709.51 55.5801C1709.87 55.5801 1710.16 55.2925 1710.16 54.9378C1710.16 54.583 1709.87 54.2954 1709.51 54.2954Z" />
        <path d="M1691.56 54.2954C1691.21 54.2954 1690.92 54.583 1690.92 54.9378C1690.92 55.2925 1691.21 55.5801 1691.56 55.5801C1691.92 55.5801 1692.21 55.2925 1692.21 54.9378C1692.21 54.583 1691.92 54.2954 1691.56 54.2954Z" />
        <path d="M1673.64 54.2954C1673.28 54.2954 1672.99 54.583 1672.99 54.9378C1672.99 55.2925 1673.28 55.5801 1673.64 55.5801C1673.99 55.5801 1674.28 55.2925 1674.28 54.9378C1674.28 54.583 1673.99 54.2954 1673.64 54.2954Z" />
        <path d="M1655.69 54.2954C1655.33 54.2954 1655.04 54.583 1655.04 54.9378C1655.04 55.2925 1655.33 55.5801 1655.69 55.5801C1656.04 55.5801 1656.33 55.2925 1656.33 54.9378C1656.33 54.583 1656.04 54.2954 1655.69 54.2954Z" />
        <path d="M1637.76 54.2954C1637.4 54.2954 1637.12 54.583 1637.12 54.9378C1637.12 55.2925 1637.4 55.5801 1637.76 55.5801C1638.11 55.5801 1638.4 55.2925 1638.4 54.9378C1638.4 54.583 1638.11 54.2954 1637.76 54.2954Z" />
        <path d="M1619.81 54.2954C1619.45 54.2954 1619.17 54.583 1619.17 54.9378C1619.17 55.2925 1619.45 55.5801 1619.81 55.5801C1620.16 55.5801 1620.45 55.2925 1620.45 54.9378C1620.45 54.583 1620.16 54.2954 1619.81 54.2954Z" />
        <path d="M1601.88 54.2954C1601.53 54.2954 1601.24 54.583 1601.24 54.9378C1601.24 55.2925 1601.53 55.5801 1601.88 55.5801C1602.23 55.5801 1602.52 55.2925 1602.52 54.9378C1602.52 54.583 1602.23 54.2954 1601.88 54.2954Z" />
        <path d="M1583.93 54.2955C1583.57 54.2955 1583.29 54.5831 1583.29 54.9379C1583.29 55.2927 1583.57 55.5802 1583.93 55.5802C1584.28 55.5802 1584.57 55.2927 1584.57 54.9379C1584.57 54.5831 1584.28 54.2955 1583.93 54.2955Z" />
        <path d="M1566 54.2955C1565.65 54.2955 1565.36 54.5831 1565.36 54.9379C1565.36 55.2927 1565.65 55.5802 1566 55.5802C1566.36 55.5802 1566.64 55.2927 1566.64 54.9379C1566.64 54.5831 1566.36 54.2955 1566 54.2955Z" />
        <path d="M1548.05 54.2954C1547.7 54.2954 1547.41 54.583 1547.41 54.9378C1547.41 55.2925 1547.7 55.5801 1548.05 55.5801C1548.41 55.5801 1548.69 55.2925 1548.69 54.9378C1548.69 54.583 1548.41 54.2954 1548.05 54.2954Z" />
        <path d="M1530.13 54.2954C1529.77 54.2954 1529.48 54.583 1529.48 54.9378C1529.48 55.2925 1529.77 55.5801 1530.13 55.5801C1530.48 55.5801 1530.77 55.2925 1530.77 54.9378C1530.77 54.583 1530.48 54.2954 1530.13 54.2954Z" />
        <path d="M1512.17 54.2955C1511.82 54.2955 1511.53 54.5831 1511.53 54.9379C1511.53 55.2927 1511.82 55.5802 1512.17 55.5802C1512.53 55.5802 1512.81 55.2927 1512.81 54.9379C1512.81 54.5831 1512.53 54.2955 1512.17 54.2955Z" />
        <path d="M1494.25 54.2955C1493.89 54.2955 1493.6 54.5831 1493.6 54.9379C1493.6 55.2927 1493.89 55.5802 1494.25 55.5802C1494.6 55.5802 1494.89 55.2927 1494.89 54.9379C1494.89 54.5831 1494.6 54.2955 1494.25 54.2955Z" />
        <path d="M1476.29 54.2953C1475.94 54.2953 1475.65 54.583 1475.65 54.9377C1475.65 55.2925 1475.94 55.58 1476.29 55.58C1476.65 55.58 1476.94 55.2925 1476.94 54.9377C1476.94 54.583 1476.65 54.2953 1476.29 54.2953Z" />
        <path d="M1458.37 54.2953C1458.01 54.2953 1457.73 54.583 1457.73 54.9377C1457.73 55.2925 1458.01 55.58 1458.37 55.58C1458.72 55.58 1459.01 55.2925 1459.01 54.9377C1459.01 54.583 1458.72 54.2953 1458.37 54.2953Z" />
        <path d="M1440.42 54.2953C1440.06 54.2953 1439.77 54.583 1439.77 54.9377C1439.77 55.2925 1440.06 55.58 1440.42 55.58C1440.77 55.58 1441.06 55.2925 1441.06 54.9377C1441.06 54.583 1440.77 54.2953 1440.42 54.2953Z" />
        <path d="M1422.49 54.2953C1422.14 54.2953 1421.85 54.5918 1421.85 54.9377C1421.85 55.2836 1422.14 55.58 1422.49 55.58C1422.83 55.58 1423.13 55.2836 1423.13 54.9377C1423.13 54.5671 1422.83 54.2953 1422.49 54.2953Z" />
        <path d="M1404.54 54.2953C1404.18 54.2953 1403.9 54.583 1403.9 54.9377C1403.9 55.2925 1404.18 55.58 1404.54 55.58C1404.89 55.58 1405.18 55.2925 1405.18 54.9377C1405.18 54.583 1404.89 54.2953 1404.54 54.2953Z" />
        <path d="M1386.61 54.2953C1386.26 54.2953 1385.97 54.583 1385.97 54.9377C1385.97 55.2925 1386.26 55.58 1386.61 55.58C1386.97 55.58 1387.25 55.2925 1387.25 54.9377C1387.25 54.583 1386.97 54.2953 1386.61 54.2953Z" />
        <path d="M1368.66 54.2953C1368.31 54.2953 1368.02 54.583 1368.02 54.9377C1368.02 55.2925 1368.31 55.58 1368.66 55.58C1369.02 55.58 1369.3 55.2925 1369.3 54.9377C1369.3 54.583 1369.02 54.2953 1368.66 54.2953Z" />
        <path d="M1350.73 54.2953C1350.38 54.2953 1350.09 54.583 1350.09 54.9377C1350.09 55.2925 1350.38 55.58 1350.73 55.58C1351.09 55.58 1351.38 55.2925 1351.38 54.9377C1351.38 54.583 1351.09 54.2953 1350.73 54.2953Z" />
        <path d="M1332.78 54.2953C1332.43 54.2953 1332.14 54.583 1332.14 54.9377C1332.14 55.2925 1332.43 55.58 1332.78 55.58C1333.14 55.58 1333.42 55.2925 1333.42 54.9377C1333.42 54.583 1333.14 54.2953 1332.78 54.2953Z" />
        <path d="M1314.86 54.2953C1314.5 54.2953 1314.21 54.583 1314.21 54.9377C1314.21 55.2925 1314.5 55.58 1314.86 55.58C1315.21 55.58 1315.5 55.2925 1315.5 54.9377C1315.5 54.583 1315.21 54.2953 1314.86 54.2953Z" />
        <path d="M1296.9 54.2953C1296.55 54.2953 1296.26 54.583 1296.26 54.9377C1296.26 55.2925 1296.55 55.58 1296.9 55.58C1297.26 55.58 1297.54 55.2925 1297.54 54.9377C1297.54 54.583 1297.26 54.2953 1296.9 54.2953Z" />
        <path d="M1278.95 54.2955C1278.6 54.2955 1278.31 54.5831 1278.31 54.9379C1278.31 55.2927 1278.6 55.5802 1278.95 55.5802C1279.31 55.5802 1279.59 55.2927 1279.59 54.9379C1279.59 54.5831 1279.31 54.2955 1278.95 54.2955Z" />
        <path d="M1261.02 54.2955C1260.67 54.2955 1260.38 54.5831 1260.38 54.9379C1260.38 55.2927 1260.67 55.5802 1261.02 55.5802C1261.38 55.5802 1261.67 55.2927 1261.67 54.9379C1261.67 54.5831 1261.38 54.2955 1261.02 54.2955Z" />
        <path d="M1243.07 54.2953C1242.72 54.2953 1242.43 54.583 1242.43 54.9377C1242.43 55.2925 1242.72 55.58 1243.07 55.58C1243.43 55.58 1243.72 55.2925 1243.72 54.9377C1243.72 54.583 1243.43 54.2953 1243.07 54.2953Z" />
        <path d="M1225.15 54.2953C1224.79 54.2953 1224.51 54.583 1224.51 54.9377C1224.51 55.2925 1224.79 55.58 1225.15 55.58C1225.5 55.58 1225.79 55.2925 1225.79 54.9377C1225.79 54.583 1225.5 54.2953 1225.15 54.2953Z" />
        <path d="M1207.19 54.2953C1206.84 54.2953 1206.55 54.583 1206.55 54.9377C1206.55 55.2925 1206.84 55.58 1207.19 55.58C1207.55 55.58 1207.84 55.2925 1207.84 54.9377C1207.84 54.583 1207.55 54.2953 1207.19 54.2953Z" />
        <path d="M1189.27 54.2953C1188.91 54.2953 1188.63 54.583 1188.63 54.9377C1188.63 55.2925 1188.91 55.58 1189.27 55.58C1189.62 55.58 1189.91 55.2925 1189.91 54.9377C1189.91 54.583 1189.62 54.2953 1189.27 54.2953Z" />
        <path d="M1171.32 54.2955C1170.96 54.2955 1170.68 54.5831 1170.68 54.9379C1170.68 55.2927 1170.96 55.5802 1171.32 55.5802C1171.67 55.5802 1171.96 55.2927 1171.96 54.9379C1171.96 54.5831 1171.67 54.2955 1171.32 54.2955Z" />
        <path d="M1153.39 54.2953C1153.04 54.2953 1152.75 54.583 1152.75 54.9377C1152.75 55.2925 1153.04 55.58 1153.39 55.58C1153.74 55.58 1154.03 55.2925 1154.03 54.9377C1154.03 54.583 1153.74 54.2953 1153.39 54.2953Z" />
        <path d="M1135.44 54.2953C1135.08 54.2953 1134.8 54.583 1134.8 54.9377C1134.8 55.2925 1135.08 55.58 1135.44 55.58C1135.79 55.58 1136.08 55.2925 1136.08 54.9377C1136.08 54.583 1135.79 54.2953 1135.44 54.2953Z" />
        <path d="M1117.51 54.2955C1117.16 54.2955 1116.87 54.5831 1116.87 54.9378C1116.87 55.2926 1117.16 55.5801 1117.51 55.5801C1117.87 55.5801 1118.15 55.2926 1118.15 54.9378C1118.15 54.5831 1117.87 54.2955 1117.51 54.2955Z" />
        <path d="M1099.56 54.2953C1099.21 54.2953 1098.92 54.583 1098.92 54.9377C1098.92 55.2925 1099.21 55.58 1099.56 55.58C1099.92 55.58 1100.2 55.2925 1100.2 54.9377C1100.2 54.583 1099.92 54.2953 1099.56 54.2953Z" />
        <path d="M1081.63 54.2953C1081.28 54.2953 1080.99 54.583 1080.99 54.9377C1080.99 55.2925 1081.28 55.58 1081.63 55.58C1081.99 55.58 1082.28 55.2925 1082.28 54.9377C1082.28 54.583 1081.99 54.2953 1081.63 54.2953Z" />
        <path d="M1063.68 54.2953C1063.33 54.2953 1063.04 54.583 1063.04 54.9377C1063.04 55.2925 1063.33 55.58 1063.68 55.58C1064.04 55.58 1064.32 55.2925 1064.32 54.9377C1064.32 54.583 1064.04 54.2953 1063.68 54.2953Z" />
        <path d="M1045.76 54.2955C1045.4 54.2955 1045.11 54.5831 1045.11 54.9378C1045.11 55.2926 1045.4 55.5801 1045.76 55.5801C1046.11 55.5801 1046.4 55.2926 1046.4 54.9378C1046.4 54.5831 1046.11 54.2955 1045.76 54.2955Z" />
        <path d="M1027.8 54.2955C1027.45 54.2955 1027.16 54.5831 1027.16 54.9378C1027.16 55.2926 1027.45 55.5801 1027.8 55.5801C1028.16 55.5801 1028.45 55.2926 1028.45 54.9378C1028.45 54.5831 1028.16 54.2955 1027.8 54.2955Z" />
        <path d="M1009.88 54.2955C1009.52 54.2955 1009.24 54.5831 1009.24 54.9378C1009.24 55.2926 1009.52 55.5801 1009.88 55.5801C1010.23 55.5801 1010.52 55.2926 1010.52 54.9378C1010.52 54.5831 1010.23 54.2955 1009.88 54.2955Z" />
        <path d="M991.927 54.2953C991.572 54.2953 991.285 54.583 991.285 54.9377C991.285 55.2925 991.572 55.58 991.927 55.58C992.281 55.58 992.569 55.2925 992.569 54.9377C992.569 54.583 992.281 54.2953 991.927 54.2953Z" />
        <path d="M974 54.2955C973.645 54.2955 973.358 54.5831 973.358 54.9378C973.358 55.2926 973.645 55.5801 974 55.5801C974.354 55.5801 974.642 55.2926 974.642 54.9378C974.642 54.5831 974.354 54.2955 974 54.2955Z" />
        <path d="M1915.82 36.5819C1915.6 36.5819 1915.43 36.7548 1915.43 36.9771C1915.43 37.1995 1915.6 37.3724 1915.82 37.3724C1916.04 37.3724 1916.22 37.1995 1916.22 36.9771C1916.22 36.7548 1916.04 36.5819 1915.82 36.5819Z" />
        <path d="M1897.87 36.5819C1897.65 36.5819 1897.47 36.7548 1897.47 36.9771C1897.47 37.1995 1897.65 37.3724 1897.87 37.3724C1898.09 37.3724 1898.26 37.1995 1898.26 36.9771C1898.29 36.7548 1898.09 36.5819 1897.87 36.5819Z" />
        <path d="M1879.94 36.5819C1879.72 36.5819 1879.55 36.7548 1879.55 36.9771C1879.55 37.1995 1879.72 37.3724 1879.94 37.3724C1880.17 37.3724 1880.34 37.1995 1880.34 36.9771C1880.34 36.7548 1880.17 36.5819 1879.94 36.5819Z" />
        <path d="M1861.99 36.5819C1861.77 36.5819 1861.6 36.7548 1861.6 36.9771C1861.6 37.1995 1861.77 37.3724 1861.99 37.3724C1862.21 37.3724 1862.39 37.1995 1862.39 36.9771C1862.41 36.7548 1862.21 36.5819 1861.99 36.5819Z" />
        <path d="M1844.07 36.5819C1843.84 36.5819 1843.67 36.7548 1843.67 36.9771C1843.67 37.1995 1843.84 37.3724 1844.07 37.3724C1844.29 37.3724 1844.46 37.1995 1844.46 36.9771C1844.46 36.7548 1844.29 36.5819 1844.07 36.5819Z" />
        <path d="M1826.11 36.5819C1825.89 36.5819 1825.72 36.7548 1825.72 36.9771C1825.72 37.1995 1825.89 37.3724 1826.11 37.3724C1826.34 37.3724 1826.51 37.1995 1826.51 36.9771C1826.51 36.7548 1826.34 36.5819 1826.11 36.5819Z" />
        <path d="M1808.19 36.5819C1807.96 36.5819 1807.79 36.7548 1807.79 36.9771C1807.79 37.1995 1807.96 37.3724 1808.19 37.3724C1808.41 37.3724 1808.58 37.1995 1808.58 36.9771C1808.58 36.7548 1808.41 36.5819 1808.19 36.5819Z" />
        <path d="M1790.24 36.5819C1790.01 36.5819 1789.84 36.7548 1789.84 36.9771C1789.84 37.1995 1790.01 37.3724 1790.24 37.3724C1790.46 37.3724 1790.63 37.1995 1790.63 36.9771C1790.63 36.7548 1790.46 36.5819 1790.24 36.5819Z" />
        <path d="M1772.31 36.5819C1772.09 36.5819 1771.91 36.7548 1771.91 36.9771C1771.91 37.1995 1772.09 37.3724 1772.31 37.3724C1772.53 37.3724 1772.7 37.1995 1772.7 36.9771C1772.7 36.7548 1772.53 36.5819 1772.31 36.5819Z" />
        <path d="M1754.36 36.5819C1754.14 36.5819 1753.96 36.7548 1753.96 36.9771C1753.96 37.1995 1754.14 37.3724 1754.36 37.3724C1754.58 37.3724 1754.75 37.1995 1754.75 36.9771C1754.75 36.7548 1754.58 36.5819 1754.36 36.5819Z" />
        <path d="M1736.43 36.5819C1736.21 36.5819 1736.04 36.7548 1736.04 36.9771C1736.04 37.1995 1736.21 37.3724 1736.43 37.3724C1736.65 37.3724 1736.83 37.1995 1736.83 36.9771C1736.83 36.7548 1736.65 36.5819 1736.43 36.5819Z" />
        <path d="M1718.48 36.5819C1718.26 36.5819 1718.08 36.7548 1718.08 36.9771C1718.08 37.1995 1718.26 37.3724 1718.48 37.3724C1718.7 37.3724 1718.87 37.1995 1718.87 36.9771C1718.87 36.7548 1718.7 36.5819 1718.48 36.5819Z" />
        <path d="M1700.55 36.5819C1700.33 36.5819 1700.16 36.7548 1700.16 36.9771C1700.16 37.1995 1700.33 37.3724 1700.55 37.3724C1700.77 37.3724 1700.95 37.1995 1700.95 36.9771C1700.95 36.7548 1700.77 36.5819 1700.55 36.5819Z" />
        <path d="M1682.6 36.5819C1682.38 36.5819 1682.21 36.7548 1682.21 36.9771C1682.21 37.1995 1682.38 37.3724 1682.6 37.3724C1682.82 37.3724 1683 37.1995 1683 36.9771C1683 36.7548 1682.82 36.5819 1682.6 36.5819Z" />
        <path d="M1664.67 36.5819C1664.45 36.5819 1664.28 36.7548 1664.28 36.9771C1664.28 37.1995 1664.45 37.3724 1664.67 37.3724C1664.9 37.3724 1665.07 37.1995 1665.07 36.9771C1665.07 36.7548 1664.9 36.5819 1664.67 36.5819Z" />
        <path d="M1646.72 36.5819C1646.5 36.5819 1646.33 36.7548 1646.33 36.9771C1646.33 37.1995 1646.5 37.3724 1646.72 37.3724C1646.94 37.3724 1647.12 37.1995 1647.12 36.9771C1647.12 36.7548 1646.94 36.5819 1646.72 36.5819Z" />
        <path d="M1628.8 36.5819C1628.57 36.5819 1628.4 36.7548 1628.4 36.9771C1628.4 37.1995 1628.57 37.3724 1628.8 37.3724C1629.02 37.3724 1629.19 37.1995 1629.19 36.9771C1629.19 36.7548 1629.02 36.5819 1628.8 36.5819Z" />
        <path d="M1610.84 36.5819C1610.62 36.5819 1610.45 36.7548 1610.45 36.9771C1610.45 37.1995 1610.62 37.3724 1610.84 37.3724C1611.07 37.3724 1611.24 37.1995 1611.24 36.9771C1611.24 36.7548 1611.07 36.5819 1610.84 36.5819Z" />
        <path d="M1592.92 36.5819C1592.69 36.5819 1592.52 36.7548 1592.52 36.9771C1592.52 37.1995 1592.69 37.3724 1592.92 37.3724C1593.14 37.3724 1593.31 37.1995 1593.31 36.9771C1593.31 36.7548 1593.11 36.5819 1592.92 36.5819Z" />
        <path d="M1574.97 36.5819C1574.74 36.5819 1574.57 36.7548 1574.57 36.9771C1574.57 37.1995 1574.74 37.3724 1574.97 37.3724C1575.19 37.3724 1575.36 37.1995 1575.36 36.9771C1575.36 36.7548 1575.19 36.5819 1574.97 36.5819Z" />
        <path d="M1557.04 36.5819C1556.82 36.5819 1556.64 36.7548 1556.64 36.9771C1556.64 37.1995 1556.82 37.3724 1557.04 37.3724C1557.26 37.3724 1557.43 37.1995 1557.43 36.9771C1557.43 36.7548 1557.24 36.5819 1557.04 36.5819Z" />
        <path d="M1539.09 36.5819C1538.87 36.5819 1538.69 36.7548 1538.69 36.9771C1538.69 37.1995 1538.87 37.3724 1539.09 37.3724C1539.31 37.3724 1539.48 37.1995 1539.48 36.9771C1539.48 36.7548 1539.31 36.5819 1539.09 36.5819Z" />
        <path d="M1521.16 36.5819C1520.94 36.5819 1520.77 36.7548 1520.77 36.9771C1520.77 37.1995 1520.94 37.3724 1521.16 37.3724C1521.38 37.3724 1521.56 37.1995 1521.56 36.9771C1521.56 36.7548 1521.36 36.5819 1521.16 36.5819Z" />
        <path d="M1503.21 36.5818C1502.99 36.5818 1502.81 36.7547 1502.81 36.9771C1502.81 37.1994 1502.99 37.3724 1503.21 37.3724C1503.43 37.3724 1503.6 37.1994 1503.6 36.9771C1503.6 36.7547 1503.43 36.5818 1503.21 36.5818Z" />
        <path d="M1485.26 36.5818C1485.04 36.5818 1484.86 36.7547 1484.86 36.9771C1484.86 37.1994 1485.04 37.3724 1485.26 37.3724C1485.48 37.3724 1485.65 37.1994 1485.65 36.9771C1485.68 36.7547 1485.48 36.5818 1485.26 36.5818Z" />
        <path d="M1467.33 36.5818C1467.11 36.5818 1466.94 36.7547 1466.94 36.9771C1466.94 37.1994 1467.11 37.3724 1467.33 37.3724C1467.55 37.3724 1467.73 37.1994 1467.73 36.9771C1467.73 36.7547 1467.55 36.5818 1467.33 36.5818Z" />
        <path d="M1449.38 36.5818C1449.16 36.5818 1448.98 36.7547 1448.98 36.9771C1448.98 37.1994 1449.16 37.3724 1449.38 37.3724C1449.6 37.3724 1449.77 37.1994 1449.77 36.9771C1449.8 36.7547 1449.6 36.5818 1449.38 36.5818Z" />
        <path d="M1431.45 36.5818C1431.23 36.5818 1431.06 36.7547 1431.06 36.9771C1431.06 37.1994 1431.23 37.3724 1431.45 37.3724C1431.68 37.3724 1431.85 37.1994 1431.85 36.9771C1431.85 36.7547 1431.68 36.5818 1431.45 36.5818Z" />
        <path d="M1413.5 36.5818C1413.28 36.5818 1413.11 36.7547 1413.11 36.9771C1413.11 37.1994 1413.28 37.3724 1413.5 37.3724C1413.72 37.3724 1413.9 37.1994 1413.9 36.9771C1413.92 36.7547 1413.72 36.5818 1413.5 36.5818Z" />
        <path d="M1395.58 36.5818C1395.35 36.5818 1395.18 36.7547 1395.18 36.9771C1395.18 37.1994 1395.35 37.3724 1395.58 37.3724C1395.8 37.3724 1395.97 37.1994 1395.97 36.9771C1395.97 36.7547 1395.8 36.5818 1395.58 36.5818Z" />
        <path d="M1377.62 36.5818C1377.4 36.5818 1377.23 36.7547 1377.23 36.9771C1377.23 37.1994 1377.4 37.3724 1377.62 37.3724C1377.84 37.3724 1378.02 37.1994 1378.02 36.9771C1378.02 36.7547 1377.84 36.5818 1377.62 36.5818Z" />
        <path d="M1359.7 36.5818C1359.47 36.5818 1359.3 36.7547 1359.3 36.9771C1359.3 37.1994 1359.47 37.3724 1359.7 37.3724C1359.92 37.3724 1360.09 37.1994 1360.09 36.9771C1360.09 36.7547 1359.92 36.5818 1359.7 36.5818Z" />
        <path d="M1341.75 36.5818C1341.52 36.5818 1341.35 36.7547 1341.35 36.9771C1341.35 37.1994 1341.52 37.3724 1341.75 37.3724C1341.97 37.3724 1342.14 37.1994 1342.14 36.9771C1342.14 36.7547 1341.97 36.5818 1341.75 36.5818Z" />
        <path d="M1323.82 36.5818C1323.6 36.5818 1323.42 36.7547 1323.42 36.9771C1323.42 37.1994 1323.6 37.3724 1323.82 37.3724C1324.04 37.3724 1324.21 37.1994 1324.21 36.9771C1324.21 36.7547 1324.04 36.5818 1323.82 36.5818Z" />
        <path d="M1305.87 36.5818C1305.65 36.5818 1305.47 36.7547 1305.47 36.9771C1305.47 37.1994 1305.65 37.3724 1305.87 37.3724C1306.09 37.3724 1306.26 37.1994 1306.26 36.9771C1306.26 36.7547 1306.09 36.5818 1305.87 36.5818Z" />
        <path d="M1287.94 36.5818C1287.72 36.5818 1287.54 36.7547 1287.54 36.9771C1287.54 37.1994 1287.72 37.3724 1287.94 37.3724C1288.16 37.3724 1288.33 37.1994 1288.33 36.9771C1288.33 36.7547 1288.16 36.5818 1287.94 36.5818Z" />
        <path d="M1269.99 36.5818C1269.77 36.5818 1269.59 36.7547 1269.59 36.9771C1269.59 37.1994 1269.77 37.3724 1269.99 37.3724C1270.21 37.3724 1270.38 37.1994 1270.38 36.9771C1270.38 36.7547 1270.21 36.5818 1269.99 36.5818Z" />
        <path d="M1252.06 36.5818C1251.84 36.5818 1251.67 36.7547 1251.67 36.9771C1251.67 37.1994 1251.84 37.3724 1252.06 37.3724C1252.28 37.3724 1252.46 37.1994 1252.46 36.9771C1252.46 36.7547 1252.28 36.5818 1252.06 36.5818Z" />
        <path d="M1234.11 36.5818C1233.89 36.5818 1233.72 36.7547 1233.72 36.9771C1233.72 37.1994 1233.89 37.3724 1234.11 37.3724C1234.33 37.3724 1234.51 37.1994 1234.51 36.9771C1234.51 36.7547 1234.33 36.5818 1234.11 36.5818Z" />
        <path d="M1216.18 36.5818C1215.96 36.5818 1215.79 36.7547 1215.79 36.9771C1215.79 37.1994 1215.96 37.3724 1216.18 37.3724C1216.41 37.3724 1216.58 37.1994 1216.58 36.9771C1216.58 36.7547 1216.41 36.5818 1216.18 36.5818Z" />
        <path d="M1198.23 36.5818C1198.01 36.5818 1197.84 36.7547 1197.84 36.9771C1197.84 37.1994 1198.01 37.3724 1198.23 37.3724C1198.45 37.3724 1198.63 37.1994 1198.63 36.9771C1198.63 36.7547 1198.45 36.5818 1198.23 36.5818Z" />
        <path d="M1180.3 36.5818C1180.08 36.5818 1179.91 36.7547 1179.91 36.9771C1179.91 37.1994 1180.08 37.3724 1180.3 37.3724C1180.53 37.3724 1180.7 37.1994 1180.7 36.9771C1180.7 36.7547 1180.53 36.5818 1180.3 36.5818Z" />
        <path d="M1162.35 36.5818C1162.13 36.5818 1161.96 36.7547 1161.96 36.9771C1161.96 37.1994 1162.13 37.3724 1162.35 37.3724C1162.58 37.3724 1162.75 37.1994 1162.75 36.9771C1162.75 36.7547 1162.58 36.5818 1162.35 36.5818Z" />
        <path d="M1144.43 36.5818C1144.2 36.5818 1144.03 36.7547 1144.03 36.9771C1144.03 37.1994 1144.2 37.3724 1144.43 37.3724C1144.65 37.3724 1144.82 37.1994 1144.82 36.9771C1144.82 36.7547 1144.62 36.5818 1144.43 36.5818Z" />
        <path d="M1126.48 36.5818C1126.25 36.5818 1126.08 36.7547 1126.08 36.9771C1126.08 37.1994 1126.25 37.3724 1126.48 37.3724C1126.7 37.3724 1126.87 37.1994 1126.87 36.9771C1126.87 36.7547 1126.7 36.5818 1126.48 36.5818Z" />
        <path d="M1108.55 36.5818C1108.33 36.5818 1108.15 36.7547 1108.15 36.9771C1108.15 37.1994 1108.33 37.3724 1108.55 37.3724C1108.77 37.3724 1108.94 37.1994 1108.94 36.9771C1108.94 36.7547 1108.75 36.5818 1108.55 36.5818Z" />
        <path d="M1090.6 36.5818C1090.37 36.5818 1090.2 36.7547 1090.2 36.9771C1090.2 37.1994 1090.37 37.3724 1090.6 37.3724C1090.82 37.3724 1090.99 37.1994 1090.99 36.9771C1090.99 36.7547 1090.82 36.5818 1090.6 36.5818Z" />
        <path d="M1072.67 36.5818C1072.45 36.5818 1072.28 36.7547 1072.28 36.9771C1072.28 37.1994 1072.45 37.3724 1072.67 37.3724C1072.89 37.3724 1073.07 37.1994 1073.07 36.9771C1073.07 36.7547 1072.87 36.5818 1072.67 36.5818Z" />
        <path d="M1054.72 36.5818C1054.5 36.5818 1054.32 36.7547 1054.32 36.9771C1054.32 37.1994 1054.5 37.3724 1054.72 37.3724C1054.94 37.3724 1055.12 37.1994 1055.12 36.9771C1055.12 36.7547 1054.94 36.5818 1054.72 36.5818Z" />
        <path d="M1036.77 36.5818C1036.54 36.5818 1036.37 36.7547 1036.37 36.9771C1036.37 37.1994 1036.54 37.3724 1036.77 37.3724C1036.99 37.3724 1037.16 37.1994 1037.16 36.9771C1037.19 36.7547 1036.99 36.5818 1036.77 36.5818Z" />
        <path d="M1018.84 36.5818C1018.62 36.5818 1018.45 36.7547 1018.45 36.9771C1018.45 37.1994 1018.62 37.3724 1018.84 37.3724C1019.06 37.3724 1019.24 37.1994 1019.24 36.9771C1019.24 36.7547 1019.06 36.5818 1018.84 36.5818Z" />
        <path d="M1000.89 36.5818C1000.67 36.5818 1000.49 36.7547 1000.49 36.9771C1000.49 37.1994 1000.67 37.3724 1000.89 37.3724C1001.11 37.3724 1001.28 37.1994 1001.28 36.9771C1001.31 36.7547 1001.11 36.5818 1000.89 36.5818Z" />
        <path d="M982.963 36.5818C982.741 36.5818 982.568 36.7547 982.568 36.9771C982.568 37.1994 982.741 37.3724 982.963 37.3724C983.185 37.3724 983.358 37.1994 983.358 36.9771C983.358 36.7547 983.185 36.5818 982.963 36.5818Z" />
        <path d="M965.01 36.5818C964.788 36.5818 964.615 36.7547 964.615 36.9771C964.615 37.1994 964.788 37.3724 965.01 37.3724C965.232 37.3724 965.405 37.1994 965.405 36.9771C965.43 36.7547 965.232 36.5818 965.01 36.5818Z" />
        <path d="M1906.86 18.8442C1906.76 18.8442 1906.66 18.943 1906.66 19.0418C1906.66 19.1406 1906.76 19.2394 1906.86 19.2394C1906.96 19.2394 1907.06 19.1406 1907.06 19.0418C1907.06 18.9183 1906.96 18.8442 1906.86 18.8442Z" />
        <path d="M1888.91 18.8442C1888.81 18.8442 1888.71 18.943 1888.71 19.0418C1888.71 19.1406 1888.81 19.2394 1888.91 19.2394C1889 19.2394 1889.1 19.1406 1889.1 19.0418C1889.1 18.9183 1889.03 18.8442 1888.91 18.8442Z" />
        <path d="M1870.98 18.8442C1870.88 18.8442 1870.78 18.943 1870.78 19.0418C1870.78 19.1406 1870.88 19.2394 1870.98 19.2394C1871.08 19.2394 1871.18 19.1406 1871.18 19.0418C1871.18 18.9183 1871.08 18.8442 1870.98 18.8442Z" />
        <path d="M1853.03 18.8441C1852.93 18.8441 1852.83 18.943 1852.83 19.0418C1852.83 19.1406 1852.93 19.2393 1853.03 19.2393C1853.13 19.2393 1853.23 19.1406 1853.23 19.0418C1853.23 18.9183 1853.15 18.8441 1853.03 18.8441Z" />
        <path d="M1835.1 18.8441C1835 18.8441 1834.9 18.943 1834.9 19.0418C1834.9 19.1406 1835 19.2393 1835.1 19.2393C1835.2 19.2393 1835.3 19.1406 1835.3 19.0418C1835.3 18.9183 1835.2 18.8441 1835.1 18.8441Z" />
        <path d="M1817.15 18.8441C1817.05 18.8441 1816.95 18.943 1816.95 19.0418C1816.95 19.1406 1817.05 19.2393 1817.15 19.2393C1817.25 19.2393 1817.35 19.1406 1817.35 19.0418C1817.35 18.9183 1817.27 18.8441 1817.15 18.8441Z" />
        <path d="M1799.22 18.8441C1799.12 18.8441 1799.02 18.943 1799.02 19.0418C1799.02 19.1406 1799.12 19.2393 1799.22 19.2393C1799.32 19.2393 1799.42 19.1406 1799.42 19.0418C1799.42 18.9183 1799.32 18.8441 1799.22 18.8441Z" />
        <path d="M1781.27 18.8441C1781.17 18.8441 1781.08 18.943 1781.08 19.0418C1781.08 19.1406 1781.17 19.2393 1781.27 19.2393C1781.37 19.2393 1781.47 19.1406 1781.47 19.0418C1781.47 18.9183 1781.4 18.8441 1781.27 18.8441Z" />
        <path d="M1763.34 18.8441C1763.25 18.8441 1763.15 18.943 1763.15 19.0418C1763.15 19.1406 1763.25 19.2393 1763.34 19.2393C1763.44 19.2393 1763.54 19.1406 1763.54 19.0418C1763.54 18.9183 1763.44 18.8441 1763.34 18.8441Z" />
        <path d="M1745.39 18.8441C1745.29 18.8441 1745.2 18.943 1745.2 19.0418C1745.2 19.1406 1745.29 19.2393 1745.39 19.2393C1745.49 19.2393 1745.59 19.1406 1745.59 19.0418C1745.59 18.9183 1745.49 18.8441 1745.39 18.8441Z" />
        <path d="M1727.47 18.8441C1727.37 18.8441 1727.27 18.943 1727.27 19.0418C1727.27 19.1406 1727.37 19.2393 1727.47 19.2393C1727.57 19.2393 1727.66 19.1406 1727.66 19.0418C1727.66 18.9183 1727.57 18.8441 1727.47 18.8441Z" />
        <path d="M1709.51 18.8441C1709.42 18.8441 1709.32 18.943 1709.32 19.0418C1709.32 19.1406 1709.42 19.2393 1709.51 19.2393C1709.61 19.2393 1709.71 19.1406 1709.71 19.0418C1709.71 18.9183 1709.61 18.8441 1709.51 18.8441Z" />
        <path d="M1691.56 18.8441C1691.47 18.8441 1691.37 18.943 1691.37 19.0418C1691.37 19.1406 1691.47 19.2393 1691.56 19.2393C1691.66 19.2393 1691.76 19.1406 1691.76 19.0418C1691.79 18.9183 1691.69 18.8441 1691.56 18.8441Z" />
        <path d="M1673.64 18.8441C1673.54 18.8441 1673.44 18.943 1673.44 19.0418C1673.44 19.1406 1673.54 19.2393 1673.64 19.2393C1673.74 19.2393 1673.83 19.1406 1673.83 19.0418C1673.83 18.9183 1673.74 18.8441 1673.64 18.8441Z" />
        <path d="M1655.69 18.8441C1655.59 18.8441 1655.49 18.943 1655.49 19.0418C1655.49 19.1406 1655.59 19.2393 1655.69 19.2393C1655.78 19.2393 1655.88 19.1406 1655.88 19.0418C1655.88 18.9183 1655.81 18.8441 1655.69 18.8441Z" />
        <path d="M1637.76 18.8441C1637.66 18.8441 1637.56 18.943 1637.56 19.0418C1637.56 19.1406 1637.66 19.2393 1637.76 19.2393C1637.86 19.2393 1637.96 19.1406 1637.96 19.0418C1637.96 18.9183 1637.86 18.8441 1637.76 18.8441Z" />
        <path d="M1619.81 18.8441C1619.71 18.8441 1619.61 18.943 1619.61 19.0418C1619.61 19.1406 1619.71 19.2393 1619.81 19.2393C1619.91 19.2393 1620.01 19.1406 1620.01 19.0418C1620.01 18.9183 1619.93 18.8441 1619.81 18.8441Z" />
        <path d="M1601.88 18.8441C1601.78 18.8441 1601.68 18.943 1601.68 19.0418C1601.68 19.1406 1601.78 19.2393 1601.88 19.2393C1601.98 19.2393 1602.08 19.1406 1602.08 19.0418C1602.08 18.9183 1601.98 18.8441 1601.88 18.8441Z" />
        <path d="M1583.93 18.8441C1583.83 18.8441 1583.73 18.943 1583.73 19.0418C1583.73 19.1406 1583.83 19.2393 1583.93 19.2393C1584.03 19.2393 1584.13 19.1406 1584.13 19.0418C1584.13 18.9183 1584.05 18.8441 1583.93 18.8441Z" />
        <path d="M1566 18.8441C1565.9 18.8441 1565.81 18.943 1565.81 19.0418C1565.81 19.1406 1565.9 19.2393 1566 19.2393C1566.1 19.2393 1566.2 19.1406 1566.2 19.0418C1566.2 18.9183 1566.1 18.8441 1566 18.8441Z" />
        <path d="M1548.05 18.8441C1547.95 18.8441 1547.85 18.943 1547.85 19.0418C1547.85 19.1406 1547.95 19.2393 1548.05 19.2393C1548.15 19.2393 1548.25 19.1406 1548.25 19.0418C1548.25 18.9183 1548.17 18.8441 1548.05 18.8441Z" />
        <path d="M1530.13 18.8441C1530.03 18.8441 1529.93 18.943 1529.93 19.0418C1529.93 19.1406 1530.03 19.2393 1530.13 19.2393C1530.22 19.2393 1530.32 19.1406 1530.32 19.0418C1530.32 18.9183 1530.22 18.8441 1530.13 18.8441Z" />
        <path d="M1512.17 18.8441C1512.07 18.8441 1511.98 18.943 1511.98 19.0418C1511.98 19.1406 1512.07 19.2393 1512.17 19.2393C1512.27 19.2393 1512.37 19.1406 1512.37 19.0418C1512.37 18.9183 1512.3 18.8441 1512.17 18.8441Z" />
        <path d="M1494.25 18.8441C1494.15 18.8441 1494.05 18.943 1494.05 19.0418C1494.05 19.1406 1494.15 19.2393 1494.25 19.2393C1494.34 19.2393 1494.44 19.1406 1494.44 19.0418C1494.44 18.9183 1494.34 18.8441 1494.25 18.8441Z" />
        <path d="M1476.3 18.8441C1476.2 18.8441 1476.1 18.943 1476.1 19.0418C1476.1 19.1406 1476.2 19.2393 1476.3 19.2393C1476.39 19.2393 1476.49 19.1406 1476.49 19.0418C1476.49 18.9183 1476.42 18.8441 1476.3 18.8441Z" />
        <path d="M1458.37 18.8441C1458.27 18.8441 1458.17 18.943 1458.17 19.0418C1458.17 19.1406 1458.27 19.2393 1458.37 19.2393C1458.47 19.2393 1458.56 19.1406 1458.56 19.0418C1458.56 18.9183 1458.47 18.8441 1458.37 18.8441Z" />
        <path d="M1440.42 18.8441C1440.32 18.8441 1440.22 18.943 1440.22 19.0418C1440.22 19.1406 1440.32 19.2393 1440.42 19.2393C1440.52 19.2393 1440.61 19.1406 1440.61 19.0418C1440.61 18.9183 1440.54 18.8441 1440.42 18.8441Z" />
        <path d="M1422.49 18.8441C1422.39 18.8441 1422.29 18.943 1422.29 19.0418C1422.29 19.1406 1422.39 19.2393 1422.49 19.2393C1422.59 19.2393 1422.69 19.1406 1422.69 19.0418C1422.69 18.9183 1422.59 18.8441 1422.49 18.8441Z" />
        <path d="M1404.54 18.8441C1404.44 18.8441 1404.34 18.943 1404.34 19.0418C1404.34 19.1406 1404.44 19.2393 1404.54 19.2393C1404.64 19.2393 1404.74 19.1406 1404.74 19.0418C1404.74 18.9183 1404.66 18.8441 1404.54 18.8441Z" />
        <path d="M1386.61 18.8441C1386.51 18.8441 1386.41 18.943 1386.41 19.0418C1386.41 19.1406 1386.51 19.2393 1386.61 19.2393C1386.71 19.2393 1386.81 19.1406 1386.81 19.0418C1386.81 18.9183 1386.71 18.8441 1386.61 18.8441Z" />
        <path d="M1368.66 18.8441C1368.56 18.8441 1368.46 18.943 1368.46 19.0418C1368.46 19.1406 1368.56 19.2393 1368.66 19.2393C1368.76 19.2393 1368.86 19.1406 1368.86 19.0418C1368.86 18.9183 1368.78 18.8441 1368.66 18.8441Z" />
        <path d="M1350.73 18.8441C1350.63 18.8441 1350.54 18.943 1350.54 19.0418C1350.54 19.1406 1350.63 19.2393 1350.73 19.2393C1350.83 19.2393 1350.93 19.1406 1350.93 19.0418C1350.93 18.9183 1350.83 18.8441 1350.73 18.8441Z" />
        <path d="M1332.78 18.8441C1332.68 18.8441 1332.58 18.943 1332.58 19.0418C1332.58 19.1406 1332.68 19.2393 1332.78 19.2393C1332.88 19.2393 1332.98 19.1406 1332.98 19.0418C1332.98 18.9183 1332.9 18.8441 1332.78 18.8441Z" />
        <path d="M1314.85 18.8441C1314.76 18.8441 1314.66 18.943 1314.66 19.0418C1314.66 19.1406 1314.76 19.2393 1314.85 19.2393C1314.95 19.2393 1315.05 19.1406 1315.05 19.0418C1315.05 18.9183 1314.95 18.8441 1314.85 18.8441Z" />
        <path d="M1296.9 18.8441C1296.8 18.8441 1296.71 18.943 1296.71 19.0418C1296.71 19.1406 1296.8 19.2393 1296.9 19.2393C1297 19.2393 1297.1 19.1406 1297.1 19.0418C1297.1 18.9183 1297 18.8441 1296.9 18.8441Z" />
        <path d="M1278.95 18.8441C1278.85 18.8441 1278.75 18.943 1278.75 19.0418C1278.75 19.1406 1278.85 19.2393 1278.95 19.2393C1279.05 19.2393 1279.15 19.1406 1279.15 19.0418C1279.17 18.9183 1279.07 18.8441 1278.95 18.8441Z" />
        <path d="M1261.03 18.8441C1260.93 18.8441 1260.83 18.943 1260.83 19.0418C1260.83 19.1406 1260.93 19.2393 1261.03 19.2393C1261.12 19.2393 1261.22 19.1406 1261.22 19.0418C1261.22 18.9183 1261.12 18.8441 1261.03 18.8441Z" />
        <path d="M1243.07 18.8441C1242.98 18.8441 1242.88 18.943 1242.88 19.0418C1242.88 19.1406 1242.98 19.2393 1243.07 19.2393C1243.17 19.2393 1243.27 19.1406 1243.27 19.0418C1243.3 18.9183 1243.2 18.8441 1243.07 18.8441Z" />
        <path d="M1225.15 18.8441C1225.05 18.8441 1224.95 18.943 1224.95 19.0418C1224.95 19.1406 1225.05 19.2393 1225.15 19.2393C1225.25 19.2393 1225.35 19.1406 1225.35 19.0418C1225.35 18.9183 1225.25 18.8441 1225.15 18.8441Z" />
        <path d="M1207.2 18.8441C1207.1 18.8441 1207 18.943 1207 19.0418C1207 19.1406 1207.1 19.2393 1207.2 19.2393C1207.29 19.2393 1207.39 19.1406 1207.39 19.0418C1207.39 18.9183 1207.32 18.8441 1207.2 18.8441Z" />
        <path d="M1189.27 18.8441C1189.17 18.8441 1189.07 18.943 1189.07 19.0418C1189.07 19.1406 1189.17 19.2393 1189.27 19.2393C1189.37 19.2393 1189.47 19.1406 1189.47 19.0418C1189.47 18.9183 1189.37 18.8441 1189.27 18.8441Z" />
        <path d="M1171.32 18.8441C1171.22 18.8441 1171.12 18.943 1171.12 19.0418C1171.12 19.1406 1171.22 19.2393 1171.32 19.2393C1171.42 19.2393 1171.51 19.1406 1171.51 19.0418C1171.51 18.9183 1171.44 18.8441 1171.32 18.8441Z" />
        <path d="M1153.39 18.8441C1153.29 18.8441 1153.19 18.9429 1153.19 19.0417C1153.19 19.1405 1153.29 19.2393 1153.39 19.2393C1153.49 19.2393 1153.59 19.1405 1153.59 19.0417C1153.59 18.9182 1153.49 18.8441 1153.39 18.8441Z" />
        <path d="M1135.44 18.8441C1135.34 18.8441 1135.24 18.9429 1135.24 19.0417C1135.24 19.1405 1135.34 19.2393 1135.44 19.2393C1135.54 19.2393 1135.64 19.1405 1135.64 19.0417C1135.64 18.9182 1135.56 18.8441 1135.44 18.8441Z" />
        <path d="M1117.51 18.8441C1117.41 18.8441 1117.31 18.9429 1117.31 19.0417C1117.31 19.1405 1117.41 19.2393 1117.51 19.2393C1117.61 19.2393 1117.71 19.1405 1117.71 19.0417C1117.71 18.9182 1117.61 18.8441 1117.51 18.8441Z" />
        <path d="M1099.56 18.8441C1099.46 18.8441 1099.36 18.9429 1099.36 19.0417C1099.36 19.1405 1099.46 19.2393 1099.56 19.2393C1099.66 19.2393 1099.76 19.1405 1099.76 19.0417C1099.76 18.9182 1099.68 18.8441 1099.56 18.8441Z" />
        <path d="M1081.63 18.8441C1081.53 18.8441 1081.44 18.9429 1081.44 19.0417C1081.44 19.1405 1081.53 19.2393 1081.63 19.2393C1081.73 19.2393 1081.83 19.1405 1081.83 19.0417C1081.83 18.9182 1081.73 18.8441 1081.63 18.8441Z" />
        <path d="M1063.68 18.8441C1063.58 18.8441 1063.48 18.9429 1063.48 19.0417C1063.48 19.1405 1063.58 19.2393 1063.68 19.2393C1063.78 19.2393 1063.88 19.1405 1063.88 19.0417C1063.88 18.9182 1063.81 18.8441 1063.68 18.8441Z" />
        <path d="M1045.76 18.8441C1045.66 18.8441 1045.56 18.9429 1045.56 19.0417C1045.56 19.1405 1045.66 19.2393 1045.76 19.2393C1045.85 19.2393 1045.95 19.1405 1045.95 19.0417C1045.95 18.9182 1045.85 18.8441 1045.76 18.8441Z" />
        <path d="M1027.8 18.8441C1027.7 18.8441 1027.61 18.9429 1027.61 19.0417C1027.61 19.1405 1027.7 19.2393 1027.8 19.2393C1027.9 19.2393 1028 19.1405 1028 19.0417C1028 18.9182 1027.93 18.8441 1027.8 18.8441Z" />
        <path d="M1009.88 18.8441C1009.78 18.8441 1009.68 18.9429 1009.68 19.0417C1009.68 19.1405 1009.78 19.2393 1009.88 19.2393C1009.98 19.2393 1010.07 19.1405 1010.07 19.0417C1010.07 18.9182 1009.98 18.8441 1009.88 18.8441Z" />
        <path d="M991.925 18.8441C991.827 18.8441 991.728 18.9429 991.728 19.0417C991.728 19.1405 991.827 19.2393 991.925 19.2393C992.024 19.2393 992.123 19.1405 992.123 19.0417C992.123 18.9182 992.049 18.8441 991.925 18.8441Z" />
        <path d="M973.999 18.8441C973.9 18.8441 973.801 18.9429 973.801 19.0417C973.801 19.1405 973.9 19.2393 973.999 19.2393C974.097 19.2393 974.196 19.1405 974.196 19.0417C974.196 18.9182 974.097 18.8441 973.999 18.8441Z" />
        <path d="M1915.82 0.883513C1915.72 0.883513 1915.62 0.982302 1915.62 1.08112C1915.62 1.17994 1915.72 1.27881 1915.82 1.27881C1915.92 1.27881 1916.02 1.17994 1916.02 1.08112C1916.02 0.982302 1915.92 0.883513 1915.82 0.883513Z" />
        <path d="M1897.87 0.883513C1897.77 0.883513 1897.67 0.982302 1897.67 1.08112C1897.67 1.17994 1897.77 1.27881 1897.87 1.27881C1897.97 1.27881 1898.07 1.17994 1898.07 1.08112C1898.07 0.982302 1897.99 0.883513 1897.87 0.883513Z" />
        <path d="M1879.94 0.883513C1879.84 0.883513 1879.75 0.982302 1879.75 1.08112C1879.75 1.17994 1879.84 1.27881 1879.94 1.27881C1880.04 1.27881 1880.14 1.17994 1880.14 1.08112C1880.14 0.982302 1880.04 0.883513 1879.94 0.883513Z" />
        <path d="M1861.99 0.883513C1861.89 0.883513 1861.79 0.982302 1861.79 1.08112C1861.79 1.17994 1861.89 1.27881 1861.99 1.27881C1862.09 1.27881 1862.19 1.17994 1862.19 1.08112C1862.19 0.982302 1862.12 0.883513 1861.99 0.883513Z" />
        <path d="M1844.07 0.883513C1843.97 0.883513 1843.87 0.982302 1843.87 1.08112C1843.87 1.17994 1843.97 1.27881 1844.07 1.27881C1844.16 1.27881 1844.26 1.17994 1844.26 1.08112C1844.26 0.982302 1844.16 0.883513 1844.07 0.883513Z" />
        <path d="M1826.11 0.883513C1826.01 0.883513 1825.92 0.982302 1825.92 1.08112C1825.92 1.17994 1826.01 1.27881 1826.11 1.27881C1826.21 1.27881 1826.31 1.17994 1826.31 1.08112C1826.31 0.982302 1826.24 0.883513 1826.11 0.883513Z" />
        <path d="M1808.19 0.883513C1808.09 0.883513 1807.99 0.982302 1807.99 1.08112C1807.99 1.17994 1808.09 1.27881 1808.19 1.27881C1808.29 1.27881 1808.38 1.17994 1808.38 1.08112C1808.38 0.982302 1808.29 0.883513 1808.19 0.883513Z" />
        <path d="M1790.24 0.883513C1790.14 0.883513 1790.04 0.982302 1790.04 1.08112C1790.04 1.17994 1790.14 1.27881 1790.24 1.27881C1790.33 1.27881 1790.43 1.17994 1790.43 1.08112C1790.43 0.982302 1790.36 0.883513 1790.24 0.883513Z" />
        <path d="M1772.31 0.883513C1772.21 0.883513 1772.11 0.982302 1772.11 1.08112C1772.11 1.17994 1772.21 1.27881 1772.31 1.27881C1772.41 1.27881 1772.51 1.17994 1772.51 1.08112C1772.51 0.982302 1772.41 0.883513 1772.31 0.883513Z" />
        <path d="M1754.36 0.883513C1754.26 0.883513 1754.16 0.982302 1754.16 1.08112C1754.16 1.17994 1754.26 1.27881 1754.36 1.27881C1754.46 1.27881 1754.56 1.17994 1754.56 1.08112C1754.56 0.982302 1754.48 0.883513 1754.36 0.883513Z" />
        <path d="M1736.43 0.883513C1736.33 0.883513 1736.23 0.982302 1736.23 1.08112C1736.23 1.17994 1736.33 1.27881 1736.43 1.27881C1736.53 1.27881 1736.63 1.17994 1736.63 1.08112C1736.63 0.982302 1736.53 0.883513 1736.43 0.883513Z" />
        <path d="M1718.48 0.883513C1718.38 0.883513 1718.28 0.982302 1718.28 1.08112C1718.28 1.17994 1718.38 1.27881 1718.48 1.27881C1718.58 1.27881 1718.68 1.17994 1718.68 1.08112C1718.68 0.982302 1718.6 0.883513 1718.48 0.883513Z" />
        <path d="M1700.55 0.883513C1700.45 0.883513 1700.35 0.982302 1700.35 1.08112C1700.35 1.17994 1700.45 1.27881 1700.55 1.27881C1700.65 1.27881 1700.75 1.17994 1700.75 1.08112C1700.75 0.982302 1700.65 0.883513 1700.55 0.883513Z" />
        <path d="M1682.6 0.883513C1682.5 0.883513 1682.4 0.982302 1682.4 1.08112C1682.4 1.17994 1682.5 1.27881 1682.6 1.27881C1682.7 1.27881 1682.8 1.17994 1682.8 1.08112C1682.8 0.982302 1682.72 0.883513 1682.6 0.883513Z" />
        <path d="M1664.67 0.883513C1664.58 0.883513 1664.48 0.982302 1664.48 1.08112C1664.48 1.17994 1664.58 1.27881 1664.67 1.27881C1664.77 1.27881 1664.87 1.17994 1664.87 1.08112C1664.87 0.982302 1664.77 0.883513 1664.67 0.883513Z" />
        <path d="M1646.72 0.883513C1646.62 0.883513 1646.52 0.982302 1646.52 1.08112C1646.52 1.17994 1646.62 1.27881 1646.72 1.27881C1646.82 1.27881 1646.92 1.17994 1646.92 1.08112C1646.92 0.982302 1646.84 0.883513 1646.72 0.883513Z" />
        <path d="M1628.8 0.883513C1628.7 0.883513 1628.6 0.982302 1628.6 1.08112C1628.6 1.17994 1628.7 1.27881 1628.8 1.27881C1628.89 1.27881 1628.99 1.17994 1628.99 1.08112C1628.99 0.982302 1628.89 0.883513 1628.8 0.883513Z" />
        <path d="M1610.84 0.883513C1610.74 0.883513 1610.65 0.982302 1610.65 1.08112C1610.65 1.17994 1610.74 1.27881 1610.84 1.27881C1610.94 1.27881 1611.04 1.17994 1611.04 1.08112C1611.04 0.982302 1610.97 0.883513 1610.84 0.883513Z" />
        <path d="M1592.92 0.883513C1592.82 0.883513 1592.72 0.982302 1592.72 1.08112C1592.72 1.17994 1592.82 1.27881 1592.92 1.27881C1593.02 1.27881 1593.12 1.17994 1593.12 1.08112C1593.12 0.982302 1593.02 0.883513 1592.92 0.883513Z" />
        <path d="M1574.97 0.883513C1574.87 0.883513 1574.77 0.982302 1574.77 1.08112C1574.77 1.17994 1574.87 1.27881 1574.97 1.27881C1575.06 1.27881 1575.16 1.17994 1575.16 1.08112C1575.16 0.982302 1575.09 0.883513 1574.97 0.883513Z" />
        <path d="M1557.04 0.883513C1556.94 0.883513 1556.84 0.982302 1556.84 1.08112C1556.84 1.17994 1556.94 1.27881 1557.04 1.27881C1557.14 1.27881 1557.23 1.17994 1557.23 1.08112C1557.23 0.982302 1557.14 0.883513 1557.04 0.883513Z" />
        <path d="M1539.09 0.883513C1538.99 0.883513 1538.89 0.982302 1538.89 1.08112C1538.89 1.17994 1538.99 1.27881 1539.09 1.27881C1539.19 1.27881 1539.29 1.17994 1539.29 1.08112C1539.29 0.982302 1539.19 0.883513 1539.09 0.883513Z" />
        <path d="M1521.16 0.883513C1521.06 0.883513 1520.96 0.982302 1520.96 1.08112C1520.96 1.17994 1521.06 1.27881 1521.16 1.27881C1521.26 1.27881 1521.36 1.17994 1521.36 1.08112C1521.36 0.982302 1521.26 0.883513 1521.16 0.883513Z" />
        <path d="M1503.21 0.883452C1503.11 0.883452 1503.01 0.98224 1503.01 1.08106C1503.01 1.17988 1503.11 1.27875 1503.21 1.27875C1503.31 1.27875 1503.41 1.17988 1503.41 1.08106C1503.41 0.982241 1503.31 0.883452 1503.21 0.883452Z" />
        <path d="M1485.26 0.883452C1485.16 0.883452 1485.06 0.98224 1485.06 1.08106C1485.06 1.17988 1485.16 1.27875 1485.26 1.27875C1485.36 1.27875 1485.46 1.17988 1485.46 1.08106C1485.46 0.982241 1485.38 0.883452 1485.26 0.883452Z" />
        <path d="M1467.33 0.883452C1467.23 0.883452 1467.13 0.98224 1467.13 1.08106C1467.13 1.17988 1467.23 1.27875 1467.33 1.27875C1467.43 1.27875 1467.53 1.17988 1467.53 1.08106C1467.53 0.982241 1467.43 0.883452 1467.33 0.883452Z" />
        <path d="M1449.38 0.883452C1449.28 0.883452 1449.18 0.98224 1449.18 1.08106C1449.18 1.17988 1449.28 1.27875 1449.38 1.27875C1449.48 1.27875 1449.58 1.17988 1449.58 1.08106C1449.58 0.982241 1449.5 0.883452 1449.38 0.883452Z" />
        <path d="M1431.45 0.883452C1431.35 0.883452 1431.26 0.98224 1431.26 1.08106C1431.26 1.17988 1431.35 1.27875 1431.45 1.27875C1431.55 1.27875 1431.65 1.17988 1431.65 1.08106C1431.65 0.982241 1431.55 0.883452 1431.45 0.883452Z" />
        <path d="M1413.5 0.883452C1413.4 0.883452 1413.3 0.98224 1413.3 1.08106C1413.3 1.17988 1413.4 1.27875 1413.5 1.27875C1413.6 1.27875 1413.7 1.17988 1413.7 1.08106C1413.7 0.982241 1413.63 0.883452 1413.5 0.883452Z" />
        <path d="M1395.58 0.883452C1395.48 0.883452 1395.38 0.98224 1395.38 1.08106C1395.38 1.17988 1395.48 1.27875 1395.58 1.27875C1395.67 1.27875 1395.77 1.17988 1395.77 1.08106C1395.77 0.982241 1395.67 0.883452 1395.58 0.883452Z" />
        <path d="M1377.62 0.883452C1377.53 0.883452 1377.43 0.98224 1377.43 1.08106C1377.43 1.17988 1377.53 1.27875 1377.62 1.27875C1377.72 1.27875 1377.82 1.17988 1377.82 1.08106C1377.82 0.982241 1377.75 0.883452 1377.62 0.883452Z" />
        <path d="M1359.7 0.883452C1359.6 0.883452 1359.5 0.98224 1359.5 1.08106C1359.5 1.17988 1359.6 1.27875 1359.7 1.27875C1359.79 1.27875 1359.89 1.17988 1359.89 1.08106C1359.89 0.982241 1359.79 0.883452 1359.7 0.883452Z" />
        <path d="M1341.75 0.883452C1341.65 0.883452 1341.55 0.98224 1341.55 1.08106C1341.55 1.17988 1341.65 1.27875 1341.75 1.27875C1341.84 1.27875 1341.94 1.17988 1341.94 1.08106C1341.94 0.982241 1341.87 0.883452 1341.75 0.883452Z" />
        <path d="M1323.82 0.883452C1323.72 0.883452 1323.62 0.98224 1323.62 1.08106C1323.62 1.17988 1323.72 1.27875 1323.82 1.27875C1323.92 1.27875 1324.02 1.17988 1324.02 1.08106C1324.02 0.982241 1323.92 0.883452 1323.82 0.883452Z" />
        <path d="M1305.87 0.883452C1305.77 0.883452 1305.67 0.98224 1305.67 1.08106C1305.67 1.17988 1305.77 1.27875 1305.87 1.27875C1305.96 1.27875 1306.06 1.17988 1306.06 1.08106C1306.06 0.982241 1305.99 0.883452 1305.87 0.883452Z" />
        <path d="M1287.94 0.883452C1287.84 0.883452 1287.74 0.98224 1287.74 1.08106C1287.74 1.17988 1287.84 1.27875 1287.94 1.27875C1288.04 1.27875 1288.14 1.17988 1288.14 1.08106C1288.14 0.982241 1288.04 0.883452 1287.94 0.883452Z" />
        <path d="M1269.99 0.883452C1269.89 0.883452 1269.79 0.98224 1269.79 1.08106C1269.79 1.17988 1269.89 1.27875 1269.99 1.27875C1270.09 1.27875 1270.19 1.17988 1270.19 1.08106C1270.19 0.982241 1270.11 0.883452 1269.99 0.883452Z" />
        <path d="M1252.06 0.883452C1251.96 0.883452 1251.86 0.98224 1251.86 1.08106C1251.86 1.17988 1251.96 1.27875 1252.06 1.27875C1252.16 1.27875 1252.26 1.17988 1252.26 1.08106C1252.26 0.982241 1252.16 0.883452 1252.06 0.883452Z" />
        <path d="M1234.11 0.883452C1234.01 0.883452 1233.91 0.98224 1233.91 1.08106C1233.91 1.17988 1234.01 1.27875 1234.11 1.27875C1234.21 1.27875 1234.31 1.17988 1234.31 1.08106C1234.31 0.982241 1234.23 0.883452 1234.11 0.883452Z" />
        <path d="M1216.18 0.883452C1216.08 0.883452 1215.99 0.98224 1215.99 1.08106C1215.99 1.17988 1216.08 1.27875 1216.18 1.27875C1216.28 1.27875 1216.38 1.17988 1216.38 1.08106C1216.38 0.982241 1216.28 0.883452 1216.18 0.883452Z" />
        <path d="M1198.23 0.883452C1198.13 0.883452 1198.03 0.98224 1198.03 1.08106C1198.03 1.17988 1198.13 1.27875 1198.23 1.27875C1198.33 1.27875 1198.43 1.17988 1198.43 1.08106C1198.43 0.982241 1198.36 0.883452 1198.23 0.883452Z" />
        <path d="M1180.3 0.883452C1180.21 0.883452 1180.11 0.98224 1180.11 1.08106C1180.11 1.17988 1180.21 1.27875 1180.3 1.27875C1180.4 1.27875 1180.5 1.17988 1180.5 1.08106C1180.5 0.982241 1180.4 0.883452 1180.3 0.883452Z" />
        <path d="M1162.35 0.883452C1162.25 0.883452 1162.16 0.98224 1162.16 1.08106C1162.16 1.17988 1162.25 1.27875 1162.35 1.27875C1162.45 1.27875 1162.55 1.17988 1162.55 1.08106C1162.55 0.982241 1162.48 0.883452 1162.35 0.883452Z" />
        <path d="M1144.43 0.883452C1144.33 0.883452 1144.23 0.98224 1144.23 1.08106C1144.23 1.17988 1144.33 1.27875 1144.43 1.27875C1144.53 1.27875 1144.62 1.17988 1144.62 1.08106C1144.62 0.982241 1144.53 0.883452 1144.43 0.883452Z" />
        <path d="M1126.48 0.883452C1126.38 0.883452 1126.28 0.98224 1126.28 1.08106C1126.28 1.17988 1126.38 1.27875 1126.48 1.27875C1126.57 1.27875 1126.67 1.17988 1126.67 1.08106C1126.67 0.982241 1126.6 0.883452 1126.48 0.883452Z" />
        <path d="M1108.55 0.883452C1108.45 0.883452 1108.35 0.98224 1108.35 1.08106C1108.35 1.17988 1108.45 1.27875 1108.55 1.27875C1108.65 1.27875 1108.75 1.17988 1108.75 1.08106C1108.75 0.982241 1108.65 0.883452 1108.55 0.883452Z" />
        <path d="M1090.6 0.883452C1090.5 0.883452 1090.4 0.98224 1090.4 1.08106C1090.4 1.17988 1090.5 1.27875 1090.6 1.27875C1090.69 1.27875 1090.79 1.17988 1090.79 1.08106C1090.79 0.982241 1090.69 0.883452 1090.6 0.883452Z" />
        <path d="M1072.67 0.883452C1072.57 0.883452 1072.47 0.98224 1072.47 1.08106C1072.47 1.17988 1072.57 1.27875 1072.67 1.27875C1072.77 1.27875 1072.87 1.17988 1072.87 1.08106C1072.87 0.982241 1072.77 0.883452 1072.67 0.883452Z" />
        <path d="M1054.72 0.883452C1054.62 0.883452 1054.52 0.98224 1054.52 1.08106C1054.52 1.17988 1054.62 1.27875 1054.72 1.27875C1054.82 1.27875 1054.92 1.17988 1054.92 1.08106C1054.92 0.982241 1054.82 0.883452 1054.72 0.883452Z" />
        <path d="M1036.77 0.883452C1036.67 0.883452 1036.57 0.98224 1036.57 1.08106C1036.57 1.17988 1036.67 1.27875 1036.77 1.27875C1036.87 1.27875 1036.96 1.17988 1036.96 1.08106C1036.96 0.982241 1036.89 0.883452 1036.77 0.883452Z" />
        <path d="M1018.84 0.883452C1018.74 0.883452 1018.64 0.98224 1018.64 1.08106C1018.64 1.17988 1018.74 1.27875 1018.84 1.27875C1018.94 1.27875 1019.04 1.17988 1019.04 1.08106C1019.04 0.982241 1018.94 0.883452 1018.84 0.883452Z" />
        <path d="M1000.89 0.883452C1000.79 0.883452 1000.69 0.98224 1000.69 1.08106C1000.69 1.17988 1000.79 1.27875 1000.89 1.27875C1000.99 1.27875 1001.09 1.17988 1001.09 1.08106C1001.09 0.982241 1001.01 0.883452 1000.89 0.883452Z" />
        <path d="M982.963 0.883452C982.864 0.883452 982.765 0.98224 982.765 1.08106C982.765 1.17988 982.864 1.27875 982.963 1.27875C983.062 1.27875 983.16 1.17988 983.16 1.08106C983.16 0.982241 983.062 0.883452 982.963 0.883452Z" />
        <path d="M965.01 0.883452C964.911 0.883452 964.813 0.98224 964.813 1.08106C964.813 1.17988 964.911 1.27875 965.01 1.27875C965.109 1.27875 965.208 1.17988 965.208 1.08106C965.208 0.982241 965.134 0.883452 965.01 0.883452Z" />
      </g>
      <g opacity="0.8">
        <path d="M945.972 426.723C943.133 426.723 940.836 429.02 940.836 431.861C940.836 434.702 943.133 437 945.972 437C948.812 437 951.108 434.702 951.108 431.861C951.108 429.02 948.812 426.723 945.972 426.723Z" />
        <path d="M928.047 426.723C925.207 426.723 922.911 429.02 922.911 431.861C922.911 434.702 925.207 437 928.047 437C930.887 437 933.183 434.702 933.183 431.861C933.158 429.02 930.862 426.723 928.047 426.723Z" />
        <path d="M910.094 426.723C907.255 426.723 904.958 429.02 904.958 431.861C904.958 434.702 907.255 437 910.094 437C912.934 437 915.23 434.702 915.23 431.861C915.23 429.02 912.934 426.723 910.094 426.723Z" />
        <path d="M892.168 426.723C889.328 426.723 887.032 429.02 887.032 431.861C887.032 434.702 889.328 437 892.168 437C895.007 437 897.304 434.702 897.304 431.861C897.279 429.02 894.983 426.723 892.168 426.723Z" />
        <path d="M874.215 426.723C871.375 426.723 869.079 429.02 869.079 431.861C869.079 434.702 871.375 437 874.215 437C877.055 437 879.351 434.678 879.351 431.861C879.351 429.02 877.055 426.723 874.215 426.723Z" />
        <path d="M856.29 426.723C853.45 426.723 851.154 429.02 851.154 431.861C851.154 434.702 853.45 437 856.29 437C859.13 437 861.426 434.702 861.426 431.861C861.401 429.02 859.105 426.723 856.29 426.723Z" />
        <path d="M838.339 426.723C835.499 426.723 833.203 429.02 833.203 431.861C833.203 434.702 835.499 437 838.339 437C841.179 437 843.475 434.702 843.475 431.861C843.475 429.02 841.179 426.723 838.339 426.723Z" />
        <path d="M820.411 426.723C817.571 426.723 815.275 429.02 815.275 431.861C815.275 434.702 817.571 437 820.411 437C823.25 437 825.547 434.702 825.547 431.861C825.522 429.02 823.226 426.723 820.411 426.723Z" />
        <path d="M802.458 426.723C799.618 426.723 797.347 429.02 797.347 431.861C797.347 434.702 799.643 437 802.458 437C805.298 437 807.594 434.702 807.594 431.861C807.594 429.02 805.298 426.723 802.458 426.723Z" />
        <path d="M784.533 426.723C781.693 426.723 779.422 429.02 779.422 431.861C779.422 434.702 781.718 437 784.533 437C787.373 437 789.669 434.702 789.669 431.861C789.644 429.02 787.348 426.723 784.533 426.723Z" />
        <path d="M766.58 426.723C763.74 426.723 761.444 429.02 761.444 431.861C761.444 434.702 763.74 437 766.58 437C769.42 437 771.716 434.702 771.716 431.861C771.716 429.02 769.42 426.723 766.58 426.723Z" />
        <path d="M748.655 426.723C745.816 426.723 743.544 429.02 743.544 431.861C743.544 434.702 745.84 437 748.655 437C751.495 437 753.791 434.702 753.791 431.861C753.767 429.02 751.47 426.723 748.655 426.723Z" />
        <path d="M730.702 426.723C727.863 426.723 725.591 429.02 725.591 431.861C725.591 434.702 727.888 437 730.702 437C733.542 437 735.839 434.702 735.839 431.861C735.839 429.02 733.542 426.723 730.702 426.723Z" />
        <path d="M712.776 426.723C709.936 426.723 707.64 429.02 707.64 431.861C707.64 434.702 709.936 437 712.776 437C715.615 437 717.912 434.702 717.912 431.861C717.887 429.02 715.591 426.723 712.776 426.723Z" />
        <path d="M694.825 426.723C691.985 426.723 689.689 429.02 689.689 431.861C689.689 434.702 691.985 437 694.825 437C697.664 437 699.961 434.702 699.961 431.861C699.961 429.02 697.664 426.723 694.825 426.723Z" />
        <path d="M676.874 426.723C674.034 426.723 671.738 429.02 671.738 431.861C671.738 434.702 674.034 437 676.874 437C679.713 437 682.01 434.702 682.01 431.861C682.01 429.02 679.713 426.723 676.874 426.723Z" />
        <path d="M658.947 426.723C656.107 426.723 653.811 429.02 653.811 431.861C653.811 434.702 656.107 437 658.947 437C661.787 437 664.083 434.702 664.083 431.861C664.058 429.02 661.787 426.723 658.947 426.723Z" />
        <path d="M640.994 426.723C638.155 426.723 635.858 429.02 635.858 431.861C635.858 434.702 638.155 437 640.994 437C643.834 437 646.13 434.702 646.13 431.861C646.13 429.02 643.834 426.723 640.994 426.723Z" />
        <path d="M623.069 426.723C620.23 426.723 617.933 429.02 617.933 431.861C617.933 434.702 620.23 437 623.069 437C625.909 437 628.205 434.702 628.205 431.861C628.181 429.02 625.909 426.723 623.069 426.723Z" />
        <path d="M605.117 426.723C602.277 426.723 599.981 429.02 599.981 431.861C599.981 434.702 602.277 437 605.117 437C607.957 437 610.228 434.702 610.228 431.861C610.253 429.02 607.957 426.723 605.117 426.723Z" />
        <path d="M587.189 426.723C584.349 426.723 582.053 429.02 582.053 431.861C582.053 434.702 584.349 437 587.189 437C590.028 437 592.3 434.702 592.3 431.861C592.3 429.02 590.004 426.723 587.189 426.723Z" />
        <path d="M569.237 426.723C566.398 426.723 564.101 429.02 564.101 431.861C564.101 434.702 566.398 437 569.237 437C572.077 437 574.349 434.702 574.349 431.861C574.373 429.02 572.077 426.723 569.237 426.723Z" />
        <path d="M551.311 426.723C548.471 426.723 546.175 429.02 546.175 431.861C546.175 434.702 548.471 437 551.311 437C554.151 437 556.422 434.702 556.422 431.861C556.422 429.02 554.126 426.723 551.311 426.723Z" />
        <path d="M533.361 426.723C530.522 426.723 528.225 429.02 528.225 431.861C528.225 434.702 530.522 437 533.361 437C536.201 437 538.497 434.702 538.497 431.861C538.497 429.02 536.201 426.723 533.361 426.723Z" />
        <path d="M515.433 426.723C512.594 426.723 510.297 429.02 510.297 431.861C510.297 434.702 512.594 437 515.433 437C518.273 437 520.545 434.702 520.545 431.861C520.545 429.02 518.248 426.723 515.433 426.723Z" />
        <path d="M497.482 426.723C494.642 426.723 492.346 429.02 492.346 431.861C492.346 434.702 494.642 437 497.482 437C500.322 437 502.593 434.702 502.593 431.861C502.618 429.02 500.322 426.723 497.482 426.723Z" />
        <path d="M479.556 426.723C476.716 426.723 474.419 429.02 474.419 431.861C474.419 434.702 476.716 437 479.556 437C482.395 437 484.667 434.702 484.667 431.861C484.667 429.02 482.37 426.723 479.556 426.723Z" />
        <path d="M461.604 426.723C458.765 426.723 456.468 429.02 456.468 431.861C456.468 434.702 458.765 437 461.604 437C464.444 437 466.716 434.702 466.716 431.861C466.74 429.02 464.444 426.723 461.604 426.723Z" />
        <path d="M443.678 426.723C440.838 426.723 438.542 429.02 438.542 431.861C438.542 434.702 440.838 437 443.678 437C446.517 437 448.789 434.702 448.789 431.861C448.789 429.02 446.493 426.723 443.678 426.723Z" />
        <path d="M425.725 426.723C422.885 426.723 420.589 429.02 420.589 431.861C420.589 434.702 422.885 437 425.725 437C428.564 437 430.836 434.702 430.836 431.861C430.861 429.02 428.564 426.723 425.725 426.723Z" />
        <path d="M407.798 426.723C404.958 426.723 402.662 429.02 402.662 431.861C402.662 434.702 404.958 437 407.798 437C410.638 437 412.934 434.702 412.934 431.861C412.909 429.02 410.613 426.723 407.798 426.723Z" />
        <path d="M389.847 426.723C387.008 426.723 384.711 429.02 384.711 431.861C384.711 434.702 387.008 437 389.847 437C392.687 437 394.959 434.702 394.959 431.861C394.983 429.02 392.687 426.723 389.847 426.723Z" />
        <path d="M371.921 426.723C369.081 426.723 366.785 429.02 366.785 431.861C366.785 434.702 369.081 437 371.921 437C374.76 437 377.032 434.702 377.032 431.861C377.032 429.02 374.736 426.723 371.921 426.723Z" />
        <path d="M353.969 426.723C351.13 426.723 348.833 429.02 348.833 431.861C348.833 434.702 351.13 437 353.969 437C356.809 437 359.081 434.702 359.081 431.861C359.106 429.02 356.809 426.723 353.969 426.723Z" />
        <path d="M336.041 426.723C333.202 426.723 330.905 429.02 330.905 431.861C330.905 434.702 333.202 437 336.041 437C338.881 437 341.153 434.702 341.153 431.861C341.153 429.02 338.856 426.723 336.041 426.723Z" />
        <path d="M318.092 426.723C315.252 426.723 312.956 429.02 312.956 431.861C312.956 434.702 315.252 437 318.092 437C320.932 437 323.203 434.702 323.203 431.861C323.228 429.02 320.932 426.723 318.092 426.723Z" />
        <path d="M300.165 426.723C297.326 426.723 295.029 429.02 295.029 431.861C295.029 434.702 297.326 437 300.165 437C303.005 437 305.277 434.702 305.277 431.861C305.277 429.02 302.98 426.723 300.165 426.723Z" />
        <path d="M282.212 426.723C279.373 426.723 277.076 429.02 277.076 431.861C277.076 434.702 279.373 437 282.212 437C285.052 437 287.324 434.702 287.324 431.861C287.348 429.02 285.052 426.723 282.212 426.723Z" />
        <path d="M264.286 426.723C261.446 426.723 259.15 429.02 259.15 431.861C259.15 434.702 261.446 437 264.286 437C267.126 437 269.397 434.702 269.397 431.861C269.397 429.02 267.101 426.723 264.286 426.723Z" />
        <path d="M246.337 426.723C243.497 426.723 241.201 429.02 241.201 431.861C241.201 434.702 243.497 437 246.337 437C249.176 437 251.448 434.702 251.448 431.861C251.448 429.02 249.176 426.723 246.337 426.723Z" />
        <path d="M228.383 426.723C225.544 426.723 223.247 429.02 223.247 431.861C223.247 434.702 225.544 437 228.383 437C231.223 437 233.52 434.702 233.52 431.861C233.52 429.02 231.223 426.723 228.383 426.723Z" />
        <path d="M210.457 426.723C207.617 426.723 205.321 429.02 205.321 431.861C205.321 434.702 207.617 437 210.457 437C213.296 437 215.593 434.702 215.593 431.861C215.568 429.02 213.296 426.723 210.457 426.723Z" />
        <path d="M192.506 426.723C189.666 426.723 187.37 429.02 187.37 431.861C187.37 434.702 189.666 437 192.506 437C195.345 437 197.642 434.702 197.642 431.861C197.642 429.02 195.345 426.723 192.506 426.723Z" />
        <path d="M174.577 426.723C171.738 426.723 169.441 429.02 169.441 431.861C169.441 434.702 171.738 437 174.577 437C177.417 437 179.713 434.702 179.713 431.861C179.689 429.02 177.417 426.723 174.577 426.723Z" />
        <path d="M156.628 426.723C153.788 426.723 151.492 429.02 151.492 431.861C151.492 434.702 153.788 437 156.628 437C159.468 437 161.764 434.702 161.764 431.861C161.764 429.02 159.468 426.723 156.628 426.723Z" />
        <path d="M138.7 426.723C135.86 426.723 133.564 429.02 133.564 431.861C133.564 434.702 135.86 437 138.7 437C141.539 437 143.836 434.702 143.836 431.861C143.811 429.02 141.515 426.723 138.7 426.723Z" />
        <path d="M120.749 426.723C117.909 426.723 115.613 429.02 115.613 431.861C115.613 434.702 117.909 437 120.749 437C123.588 437 125.885 434.702 125.885 431.861C125.885 429.02 123.588 426.723 120.749 426.723Z" />
        <path d="M102.822 426.723C99.9823 426.723 97.686 429.02 97.686 431.861C97.686 434.702 99.9823 437 102.822 437C105.662 437 107.958 434.702 107.958 431.861C107.933 429.02 105.637 426.723 102.822 426.723Z" />
        <path d="M84.8693 426.723C82.0297 426.723 79.7333 429.02 79.7333 431.861C79.7333 434.702 82.0297 437 84.8693 437C87.709 437 90.0054 434.702 90.0054 431.861C90.0054 429.02 87.709 426.723 84.8693 426.723Z" />
        <path d="M66.9442 426.723C64.1046 426.723 61.8082 429.02 61.8082 431.861C61.8082 434.702 64.1046 437 66.9442 437C69.7839 437 72.0803 434.702 72.0803 431.861C72.0556 429.02 69.7592 426.723 66.9442 426.723Z" />
        <path d="M48.9917 426.723C46.152 426.723 43.8803 429.02 43.8803 431.861C43.8803 434.702 46.1767 437 48.9917 437C51.8313 437 54.1277 434.702 54.1277 431.861C54.1277 429.02 51.8313 426.723 48.9917 426.723Z" />
        <path d="M31.0649 426.723C28.2252 426.723 25.9536 429.02 25.9536 431.861C25.9536 434.702 28.2499 437 31.0649 437C33.9045 437 36.2009 434.702 36.2009 431.861C36.1762 429.02 33.8798 426.723 31.0649 426.723Z" />
        <path d="M13.114 426.723C10.2743 426.723 8.00256 429.02 8.00256 431.861C8.00256 434.702 10.299 437 13.114 437C15.9536 437 18.25 434.702 18.25 431.861C18.25 429.02 15.9536 426.723 13.114 426.723Z" />
        <path d="M-4.81465 426.723C-7.65435 426.723 -9.92612 429.02 -9.92612 431.861C-9.92612 434.702 -7.62966 437 -4.81465 437C-1.97496 437 0.321533 434.702 0.321534 431.861C0.296841 429.02 -1.99965 426.723 -4.81465 426.723Z" />
        <path d="M954.961 408.985C952.245 408.985 950.047 411.184 950.047 413.902C950.047 416.619 952.245 418.818 954.961 418.818C957.677 418.818 959.875 416.619 959.875 413.902C959.875 411.184 957.677 408.985 954.961 408.985Z" />
        <path d="M937.01 408.985C934.294 408.985 932.096 411.184 932.096 413.902C932.096 416.619 934.294 418.818 937.01 418.818C939.726 418.818 941.924 416.619 941.924 413.902C941.924 411.184 939.726 408.985 937.01 408.985Z" />
        <path d="M919.059 408.985C916.343 408.985 914.145 411.184 914.145 413.902C914.145 416.619 916.343 418.818 919.059 418.818C921.775 418.818 923.973 416.619 923.973 413.902C923.973 411.184 921.8 408.985 919.059 408.985Z" />
        <path d="M901.132 408.985C898.416 408.985 896.218 411.184 896.218 413.902C896.218 416.619 898.416 418.818 901.132 418.818C903.848 418.818 906.046 416.619 906.046 413.902C906.046 411.184 903.848 408.985 901.132 408.985Z" />
        <path d="M883.18 408.985C880.463 408.985 878.266 411.184 878.266 413.902C878.266 416.619 880.463 418.818 883.18 418.818C885.896 418.818 888.093 416.619 888.093 413.902C888.093 411.184 885.92 408.985 883.18 408.985Z" />
        <path d="M865.253 408.985C862.539 408.985 860.339 411.186 860.339 413.902C860.339 416.617 862.539 418.818 865.253 418.818C867.967 418.818 870.167 416.617 870.167 413.902C870.167 411.186 867.967 408.985 865.253 408.985Z" />
        <path d="M847.302 408.985C844.588 408.985 842.388 411.186 842.388 413.902C842.388 416.617 844.588 418.818 847.302 418.818C850.015 418.818 852.215 416.617 852.215 413.902C852.215 411.186 850.015 408.985 847.302 408.985Z" />
        <path d="M829.375 408.985C826.661 408.985 824.461 411.186 824.461 413.902C824.461 416.617 826.661 418.818 829.375 418.818C832.089 418.818 834.289 416.617 834.289 413.902C834.289 411.186 832.089 408.985 829.375 408.985Z" />
        <path d="M811.424 408.985C808.71 408.985 806.51 411.186 806.51 413.902C806.51 416.617 808.71 418.818 811.424 418.818C814.138 418.818 816.338 416.617 816.338 413.902C816.338 411.186 814.138 408.985 811.424 408.985Z" />
        <path d="M793.496 408.985C790.782 408.985 788.582 411.186 788.582 413.902C788.582 416.617 790.782 418.818 793.496 418.818C796.209 418.818 798.409 416.617 798.409 413.902C798.409 411.186 796.209 408.985 793.496 408.985Z" />
        <path d="M775.543 408.985C772.829 408.985 770.629 411.186 770.629 413.902C770.629 416.617 772.829 418.818 775.543 418.818C778.257 418.818 780.457 416.617 780.457 413.902C780.457 411.186 778.257 408.985 775.543 408.985Z" />
        <path d="M757.618 408.985C754.904 408.985 752.704 411.186 752.704 413.902C752.704 416.617 754.904 418.818 757.618 418.818C760.332 418.818 762.532 416.617 762.532 413.902C762.532 411.186 760.332 408.985 757.618 408.985Z" />
        <path d="M739.667 408.985C736.953 408.985 734.753 411.186 734.753 413.902C734.753 416.617 736.953 418.818 739.667 418.818C742.381 418.818 744.581 416.617 744.581 413.902C744.581 411.186 742.381 408.985 739.667 408.985Z" />
        <path d="M721.74 408.985C719.026 408.985 716.826 411.186 716.826 413.902C716.826 416.617 719.026 418.818 721.74 418.818C724.454 418.818 726.654 416.617 726.654 413.902C726.654 411.186 724.454 408.985 721.74 408.985Z" />
        <path d="M703.787 408.985C701.074 408.985 698.874 411.186 698.874 413.902C698.874 416.617 701.074 418.818 703.787 418.818C706.501 418.818 708.701 416.617 708.701 413.902C708.701 411.186 706.501 408.985 703.787 408.985Z" />
        <path d="M685.861 408.985C683.147 408.985 680.947 411.186 680.947 413.902C680.947 416.617 683.147 418.818 685.861 418.818C688.575 418.818 690.775 416.617 690.775 413.902C690.775 411.186 688.575 408.985 685.861 408.985Z" />
        <path d="M667.91 408.985C665.196 408.985 662.996 411.186 662.996 413.902C662.996 416.617 665.196 418.818 667.91 418.818C670.624 418.818 672.824 416.617 672.824 413.902C672.824 411.186 670.624 408.985 667.91 408.985Z" />
        <path d="M649.983 408.985C647.269 408.985 645.069 411.186 645.069 413.902C645.069 416.617 647.269 418.818 649.983 418.818C652.697 418.818 654.897 416.617 654.897 413.902C654.897 411.186 652.697 408.985 649.983 408.985Z" />
        <path d="M632.032 408.985C629.318 408.985 627.118 411.186 627.118 413.902C627.118 416.617 629.318 418.818 632.032 418.818C634.746 418.818 636.946 416.617 636.946 413.902C636.946 411.186 634.746 408.985 632.032 408.985Z" />
        <path d="M614.105 408.985C611.392 408.985 609.192 411.186 609.192 413.902C609.192 416.617 611.392 418.818 614.105 418.818C616.819 418.818 619.019 416.617 619.019 413.902C619.019 411.186 616.819 408.985 614.105 408.985Z" />
        <path d="M596.154 408.985C593.441 408.985 591.241 411.186 591.241 413.902C591.241 416.617 593.441 418.818 596.154 418.818C598.868 418.818 601.068 416.617 601.068 413.902C601.068 411.186 598.868 408.985 596.154 408.985Z" />
        <path d="M578.226 408.985C575.512 408.985 573.312 411.186 573.312 413.902C573.312 416.617 575.512 418.818 578.226 418.818C580.94 418.818 583.14 416.617 583.14 413.902C583.14 411.186 580.94 408.985 578.226 408.985Z" />
        <path d="M560.275 408.985C557.561 408.985 555.361 411.186 555.361 413.902C555.361 416.617 557.561 418.818 560.275 418.818C562.989 418.818 565.189 416.617 565.189 413.902C565.189 411.186 562.989 408.985 560.275 408.985Z" />
        <path d="M542.348 408.985C539.634 408.985 537.434 411.186 537.434 413.902C537.434 416.617 539.634 418.818 542.348 418.818C545.062 418.818 547.262 416.617 547.262 413.902C547.262 411.186 545.062 408.985 542.348 408.985Z" />
        <path d="M524.397 408.985C521.683 408.985 519.483 411.186 519.483 413.902C519.483 416.617 521.683 418.818 524.397 418.818C527.111 418.818 529.311 416.617 529.311 413.902C529.311 411.186 527.111 408.985 524.397 408.985Z" />
        <path d="M506.471 408.985C503.757 408.985 501.557 411.186 501.557 413.901C501.557 416.617 503.757 418.818 506.471 418.818C509.184 418.818 511.384 416.617 511.384 413.901C511.384 411.186 509.184 408.985 506.471 408.985Z" />
        <path d="M488.518 408.985C485.804 408.985 483.604 411.186 483.604 413.901C483.604 416.617 485.804 418.818 488.518 418.818C491.232 418.818 493.432 416.617 493.432 413.901C493.432 411.186 491.232 408.985 488.518 408.985Z" />
        <path d="M470.593 408.985C467.879 408.985 465.679 411.186 465.679 413.901C465.679 416.617 467.879 418.818 470.593 418.818C473.307 418.818 475.507 416.617 475.507 413.901C475.507 411.186 473.307 408.985 470.593 408.985Z" />
        <path d="M452.64 408.985C449.924 408.985 447.726 411.184 447.726 413.901C447.726 416.619 449.924 418.818 452.64 418.818C455.356 418.818 457.554 416.619 457.554 413.901C457.554 411.184 455.356 408.985 452.64 408.985Z" />
        <path d="M434.689 408.985C431.973 408.985 429.775 411.184 429.775 413.901C429.775 416.619 431.973 418.818 434.689 418.818C437.405 418.818 439.603 416.619 439.603 413.901C439.603 411.184 437.43 408.985 434.689 408.985Z" />
        <path d="M416.762 408.985C414.046 408.985 411.849 411.184 411.849 413.901C411.849 416.619 414.046 418.818 416.762 418.818C419.479 418.818 421.676 416.619 421.676 413.901C421.676 411.184 419.479 408.985 416.762 408.985Z" />
        <path d="M398.81 408.985C396.094 408.985 393.896 411.184 393.896 413.901C393.896 416.619 396.094 418.818 398.81 418.818C401.526 418.818 403.724 416.619 403.724 413.901C403.724 411.184 401.551 408.985 398.81 408.985Z" />
        <path d="M380.885 408.985C378.169 408.985 375.971 411.184 375.971 413.901C375.971 416.619 378.169 418.818 380.885 418.818C383.601 418.818 385.799 416.619 385.799 413.901C385.799 411.184 383.601 408.985 380.885 408.985Z" />
        <path d="M362.932 408.985C360.216 408.985 358.018 411.184 358.018 413.901C358.018 416.619 360.216 418.818 362.932 418.818C365.648 418.818 367.846 416.619 367.846 413.901C367.846 411.184 365.673 408.985 362.932 408.985Z" />
        <path d="M345.007 408.985C342.291 408.985 340.093 411.184 340.093 413.901C340.093 416.619 342.291 418.818 345.007 418.818C347.723 418.818 349.921 416.619 349.921 413.901C349.921 411.184 347.723 408.985 345.007 408.985Z" />
        <path d="M327.054 408.985C324.338 408.985 322.14 411.184 322.14 413.901C322.14 416.619 324.338 418.818 327.054 418.818C329.77 418.818 331.968 416.619 331.968 413.901C331.968 411.184 329.77 408.985 327.054 408.985Z" />
        <path d="M309.128 408.985C306.411 408.985 304.214 411.184 304.214 413.901C304.214 416.619 306.411 418.818 309.128 418.818C311.844 418.818 314.042 416.619 314.042 413.901C314.042 411.184 311.844 408.985 309.128 408.985Z" />
        <path d="M291.177 408.985C288.461 408.985 286.263 411.184 286.263 413.901C286.263 416.619 288.461 418.818 291.177 418.818C293.893 418.818 296.091 416.619 296.091 413.901C296.091 411.184 293.893 408.985 291.177 408.985Z" />
        <path d="M273.248 408.985C270.532 408.985 268.334 411.184 268.334 413.901C268.334 416.619 270.532 418.818 273.248 418.818C275.964 418.818 278.162 416.619 278.162 413.901C278.162 411.184 275.964 408.985 273.248 408.985Z" />
        <path d="M255.299 408.985C252.583 408.985 250.385 411.184 250.385 413.901C250.385 416.619 252.583 418.818 255.299 418.818C258.015 418.818 260.213 416.619 260.213 413.901C260.213 411.184 258.015 408.985 255.299 408.985Z" />
        <path d="M237.371 408.985C234.654 408.985 232.457 411.184 232.457 413.901C232.457 416.619 234.654 418.818 237.371 418.818C240.087 418.818 242.284 416.619 242.284 413.901C242.284 411.184 240.087 408.985 237.371 408.985Z" />
        <path d="M219.419 408.985C216.703 408.985 214.506 411.184 214.506 413.901C214.506 416.619 216.703 418.818 219.419 418.818C222.136 418.818 224.333 416.619 224.333 413.901C224.333 411.184 222.136 408.985 219.419 408.985Z" />
        <path d="M201.493 408.985C198.777 408.985 196.579 411.184 196.579 413.901C196.579 416.619 198.777 418.818 201.493 418.818C204.209 418.818 206.407 416.619 206.407 413.901C206.407 411.184 204.209 408.985 201.493 408.985Z" />
        <path d="M183.542 408.985C180.825 408.985 178.628 411.184 178.628 413.901C178.628 416.619 180.825 418.818 183.542 418.818C186.258 418.818 188.456 416.619 188.456 413.901C188.456 411.184 186.258 408.985 183.542 408.985Z" />
        <path d="M165.615 408.985C162.899 408.985 160.701 411.184 160.701 413.901C160.701 416.619 162.899 418.818 165.615 418.818C168.331 418.818 170.529 416.619 170.529 413.901C170.529 411.184 168.331 408.985 165.615 408.985Z" />
        <path d="M147.662 408.985C144.946 408.985 142.748 411.184 142.748 413.901C142.748 416.619 144.946 418.818 147.662 418.818C150.378 418.818 152.576 416.619 152.576 413.901C152.576 411.184 150.378 408.985 147.662 408.985Z" />
        <path d="M129.737 408.985C127.021 408.985 124.823 411.184 124.823 413.901C124.823 416.619 127.021 418.818 129.737 418.818C132.454 418.818 134.651 416.619 134.651 413.901C134.651 411.184 132.454 408.985 129.737 408.985Z" />
        <path d="M111.785 408.985C109.068 408.985 106.871 411.184 106.871 413.901C106.871 416.619 109.068 418.818 111.785 418.818C114.501 418.818 116.698 416.619 116.698 413.901C116.698 411.184 114.501 408.985 111.785 408.985Z" />
        <path d="M93.858 408.985C91.1418 408.985 88.9441 411.184 88.9441 413.901C88.9441 416.619 91.1418 418.818 93.858 418.818C96.5741 418.818 98.7717 416.619 98.7717 413.901C98.7717 411.184 96.5741 408.985 93.858 408.985Z" />
        <path d="M75.907 408.985C73.1909 408.985 70.9933 411.184 70.9933 413.901C70.9933 416.619 73.1909 418.818 75.907 418.818C78.6232 418.818 80.8208 416.619 80.8208 413.901C80.8208 411.184 78.6232 408.985 75.907 408.985Z" />
        <path d="M57.9804 408.985C55.2642 408.985 53.0666 411.184 53.0666 413.901C53.0666 416.619 55.2642 418.818 57.9804 418.818C60.6966 418.818 62.8943 416.619 62.8943 413.901C62.8943 411.184 60.6966 408.985 57.9804 408.985Z" />
        <path d="M40.0293 408.985C37.3132 408.985 35.1156 411.184 35.1156 413.901C35.1156 416.619 37.3132 418.818 40.0293 418.818C42.7455 418.818 44.9431 416.619 44.9431 413.901C44.9431 411.184 42.7455 408.985 40.0293 408.985Z" />
        <path d="M22.0767 408.985C19.3605 408.985 17.1628 411.184 17.1628 413.901C17.1628 416.619 19.3605 418.818 22.0767 418.818C24.7929 418.818 26.9905 416.619 26.9905 413.901C26.9905 411.184 24.8176 408.985 22.0767 408.985Z" />
        <path d="M4.14995 408.985C1.43378 408.985 -0.763819 411.184 -0.763819 413.901C-0.76382 416.619 1.43378 418.818 4.14995 418.818C6.86612 418.818 9.06372 416.619 9.06372 413.901C9.06372 411.184 6.86612 408.985 4.14995 408.985Z" />
        <path d="M945.972 391.024C943.256 391.024 941.058 393.223 941.058 395.941C941.058 398.658 943.256 400.857 945.972 400.857C948.688 400.857 950.886 398.658 950.886 395.941C950.886 393.223 948.688 391.024 945.972 391.024Z" />
        <path d="M928.044 391.024C925.328 391.024 923.13 393.223 923.13 395.941C923.13 398.658 925.328 400.857 928.044 400.857C930.76 400.857 932.958 398.658 932.958 395.941C932.958 393.223 930.76 391.024 928.044 391.024Z" />
        <path d="M910.095 391.024C907.378 391.024 905.181 393.223 905.181 395.941C905.181 398.658 907.378 400.857 910.095 400.857C912.811 400.857 915.008 398.658 915.008 395.941C915.008 393.223 912.811 391.024 910.095 391.024Z" />
        <path d="M892.168 391.024C889.452 391.024 887.254 393.223 887.254 395.941C887.254 398.658 889.452 400.857 892.168 400.857C894.884 400.857 897.082 398.658 897.082 395.941C897.082 393.223 894.884 391.024 892.168 391.024Z" />
        <path d="M874.217 391.024C871.501 391.024 869.303 393.223 869.303 395.941C869.303 398.658 871.501 400.857 874.217 400.857C876.933 400.857 879.131 398.658 879.131 395.941C879.131 393.223 876.933 391.024 874.217 391.024Z" />
        <path d="M856.289 391.049C853.575 391.049 851.375 393.25 851.375 395.965C851.375 398.68 853.575 400.882 856.289 400.882C859.002 400.882 861.202 398.68 861.202 395.965C861.202 393.25 859.002 391.049 856.289 391.049Z" />
        <path d="M838.336 391.049C835.622 391.049 833.422 393.25 833.422 395.965C833.422 398.68 835.622 400.882 838.336 400.882C841.05 400.882 843.25 398.68 843.25 395.965C843.25 393.25 841.05 391.049 838.336 391.049Z" />
        <path d="M820.411 391.049C817.697 391.049 815.497 393.25 815.497 395.965C815.497 398.68 817.697 400.882 820.411 400.882C823.125 400.882 825.325 398.68 825.325 395.965C825.325 393.25 823.125 391.049 820.411 391.049Z" />
        <path d="M802.46 391.049C799.746 391.049 797.546 393.25 797.546 395.965C797.546 398.68 799.746 400.882 802.46 400.882C805.174 400.882 807.374 398.68 807.374 395.965C807.374 393.25 805.174 391.049 802.46 391.049Z" />
        <path d="M784.533 391.049C781.819 391.049 779.619 393.25 779.619 395.965C779.619 398.68 781.819 400.882 784.533 400.882C787.247 400.882 789.447 398.68 789.447 395.965C789.447 393.25 787.247 391.049 784.533 391.049Z" />
        <path d="M766.58 391.049C763.867 391.049 761.667 393.25 761.667 395.965C761.667 398.68 763.867 400.882 766.58 400.882C769.294 400.882 771.494 398.68 771.494 395.965C771.494 393.25 769.294 391.049 766.58 391.049Z" />
        <path d="M748.655 391.049C745.942 391.049 743.742 393.25 743.742 395.965C743.742 398.68 745.942 400.882 748.655 400.882C751.369 400.882 753.569 398.68 753.569 395.965C753.569 393.25 751.369 391.049 748.655 391.049Z" />
        <path d="M730.703 391.049C727.989 391.049 725.789 393.25 725.789 395.965C725.789 398.68 727.989 400.882 730.703 400.882C733.416 400.882 735.616 398.68 735.616 395.965C735.616 393.25 733.416 391.049 730.703 391.049Z" />
        <path d="M712.776 391.049C710.062 391.049 707.862 393.25 707.862 395.965C707.862 398.68 710.062 400.882 712.776 400.882C715.49 400.882 717.69 398.68 717.69 395.965C717.69 393.25 715.49 391.049 712.776 391.049Z" />
        <path d="M694.825 391.049C692.111 391.049 689.911 393.25 689.911 395.965C689.911 398.68 692.111 400.882 694.825 400.882C697.539 400.882 699.739 398.68 699.739 395.965C699.739 393.25 697.539 391.049 694.825 391.049Z" />
        <path d="M676.874 391.049C674.16 391.049 671.96 393.25 671.96 395.965C671.96 398.68 674.16 400.882 676.874 400.882C679.588 400.882 681.788 398.68 681.788 395.965C681.788 393.25 679.588 391.049 676.874 391.049Z" />
        <path d="M658.947 391.049C656.234 391.049 654.034 393.25 654.034 395.965C654.034 398.68 656.234 400.882 658.947 400.882C661.661 400.882 663.861 398.68 663.861 395.965C663.861 393.25 661.661 391.049 658.947 391.049Z" />
        <path d="M640.995 391.049C638.281 391.049 636.081 393.25 636.081 395.965C636.081 398.68 638.281 400.881 640.995 400.881C643.708 400.881 645.908 398.68 645.908 395.965C645.908 393.25 643.708 391.049 640.995 391.049Z" />
        <path d="M623.07 391.049C620.356 391.049 618.156 393.25 618.156 395.965C618.156 398.68 620.356 400.881 623.07 400.881C625.783 400.881 627.983 398.68 627.983 395.965C627.983 393.25 625.783 391.049 623.07 391.049Z" />
        <path d="M605.117 391.049C602.403 391.049 600.203 393.25 600.203 395.965C600.203 398.68 602.403 400.881 605.117 400.881C607.831 400.881 610.031 398.68 610.031 395.965C610.031 393.25 607.831 391.049 605.117 391.049Z" />
        <path d="M587.19 391.049C584.476 391.049 582.276 393.25 582.276 395.965C582.276 398.68 584.476 400.881 587.19 400.881C589.904 400.881 592.104 398.68 592.104 395.965C592.104 393.25 589.904 391.049 587.19 391.049Z" />
        <path d="M569.239 391.049C566.525 391.049 564.325 393.25 564.325 395.965C564.325 398.68 566.525 400.881 569.239 400.881C571.953 400.881 574.153 398.68 574.153 395.965C574.153 393.25 571.953 391.049 569.239 391.049Z" />
        <path d="M551.311 391.049C548.597 391.049 546.397 393.25 546.397 395.965C546.397 398.68 548.597 400.881 551.311 400.881C554.025 400.881 556.225 398.68 556.225 395.965C556.225 393.25 554.025 391.049 551.311 391.049Z" />
        <path d="M533.36 391.049C530.646 391.049 528.446 393.25 528.446 395.965C528.446 398.68 530.646 400.881 533.36 400.881C536.074 400.881 538.274 398.68 538.274 395.965C538.274 393.25 536.074 391.049 533.36 391.049Z" />
        <path d="M515.433 391.049C512.719 391.049 510.519 393.25 510.519 395.965C510.519 398.68 512.719 400.881 515.433 400.881C518.147 400.881 520.347 398.68 520.347 395.965C520.347 393.25 518.147 391.049 515.433 391.049Z" />
        <path d="M497.482 391.049C494.768 391.049 492.568 393.25 492.568 395.965C492.568 398.68 494.768 400.881 497.482 400.881C500.196 400.881 502.396 398.68 502.396 395.965C502.396 393.25 500.196 391.049 497.482 391.049Z" />
        <path d="M479.555 391.049C476.842 391.049 474.642 393.25 474.642 395.965C474.642 398.68 476.842 400.881 479.555 400.881C482.269 400.881 484.469 398.68 484.469 395.965C484.469 393.25 482.269 391.049 479.555 391.049Z" />
        <path d="M461.604 391.049C458.891 391.049 456.69 393.25 456.69 395.965C456.69 398.68 458.891 400.881 461.604 400.881C464.318 400.881 466.518 398.68 466.518 395.965C466.518 393.25 464.318 391.049 461.604 391.049Z" />
        <path d="M443.678 391.024C440.962 391.024 438.764 393.223 438.764 395.941C438.764 398.658 440.962 400.857 443.678 400.857C446.394 400.857 448.592 398.658 448.592 395.941C448.592 393.223 446.394 391.024 443.678 391.024Z" />
        <path d="M425.725 391.024C423.009 391.024 420.811 393.223 420.811 395.941C420.811 398.658 423.009 400.857 425.725 400.857C428.441 400.857 430.639 398.658 430.639 395.941C430.639 393.223 428.441 391.024 425.725 391.024Z" />
        <path d="M407.8 391.024C405.084 391.024 402.886 393.223 402.886 395.941C402.886 398.658 405.084 400.857 407.8 400.857C410.516 400.857 412.714 398.658 412.714 395.941C412.714 393.223 410.516 391.024 407.8 391.024Z" />
        <path d="M389.847 391.024C387.131 391.024 384.933 393.223 384.933 395.941C384.933 398.658 387.131 400.857 389.847 400.857C392.563 400.857 394.761 398.658 394.761 395.941C394.761 393.223 392.563 391.024 389.847 391.024Z" />
        <path d="M371.921 391.024C369.204 391.024 367.007 393.223 367.007 395.941C367.007 398.658 369.204 400.857 371.921 400.857C374.637 400.857 376.834 398.658 376.834 395.941C376.834 393.223 374.637 391.024 371.921 391.024Z" />
        <path d="M353.97 391.024C351.253 391.024 349.056 393.223 349.056 395.941C349.056 398.658 351.253 400.857 353.97 400.857C356.686 400.857 358.883 398.658 358.883 395.941C358.883 393.223 356.686 391.024 353.97 391.024Z" />
        <path d="M336.043 391.024C333.327 391.024 331.129 393.223 331.129 395.941C331.129 398.658 333.327 400.857 336.043 400.857C338.759 400.857 340.957 398.658 340.957 395.941C340.957 393.223 338.759 391.024 336.043 391.024Z" />
        <path d="M318.092 391.024C315.376 391.024 313.178 393.223 313.178 395.941C313.178 398.658 315.376 400.857 318.092 400.857C320.808 400.857 323.006 398.658 323.006 395.941C323.006 393.223 320.808 391.024 318.092 391.024Z" />
        <path d="M300.164 391.024C297.447 391.024 295.25 393.223 295.25 395.941C295.25 398.658 297.447 400.857 300.164 400.857C302.88 400.857 305.077 398.658 305.077 395.941C305.077 393.223 302.88 391.024 300.164 391.024Z" />
        <path d="M282.214 391.024C279.498 391.024 277.3 393.223 277.3 395.941C277.3 398.658 279.498 400.857 282.214 400.857C284.93 400.857 287.128 398.658 287.128 395.941C287.128 393.223 284.93 391.024 282.214 391.024Z" />
        <path d="M264.286 391.024C261.569 391.024 259.372 393.223 259.372 395.941C259.372 398.658 261.569 400.857 264.286 400.857C267.002 400.857 269.199 398.658 269.199 395.941C269.199 393.223 267.002 391.024 264.286 391.024Z" />
        <path d="M246.335 391.024C243.619 391.024 241.421 393.223 241.421 395.941C241.421 398.658 243.619 400.857 246.335 400.857C249.051 400.857 251.249 398.658 251.249 395.941C251.249 393.223 249.051 391.024 246.335 391.024Z" />
        <path d="M228.384 391.024C225.668 391.024 223.47 393.223 223.47 395.941C223.47 398.658 225.668 400.857 228.384 400.857C231.1 400.857 233.298 398.658 233.298 395.941C233.298 393.223 231.125 391.024 228.384 391.024Z" />
        <path d="M210.455 391.024C207.739 391.024 205.541 393.223 205.541 395.941C205.541 398.658 207.739 400.857 210.455 400.857C213.171 400.857 215.369 398.658 215.369 395.941C215.369 393.223 213.171 391.024 210.455 391.024Z" />
        <path d="M192.506 391.024C189.79 391.024 187.592 393.223 187.592 395.941C187.592 398.658 189.79 400.857 192.506 400.857C195.222 400.857 197.42 398.658 197.42 395.941C197.42 393.223 195.247 391.024 192.506 391.024Z" />
        <path d="M174.578 391.024C171.861 391.024 169.664 393.223 169.664 395.941C169.664 398.658 171.861 400.857 174.578 400.857C177.294 400.857 179.491 398.658 179.491 395.941C179.491 393.223 177.294 391.024 174.578 391.024Z" />
        <path d="M156.627 391.024C153.911 391.024 151.713 393.223 151.713 395.941C151.713 398.658 153.911 400.857 156.627 400.857C159.343 400.857 161.541 398.658 161.541 395.941C161.541 393.223 159.368 391.024 156.627 391.024Z" />
        <path d="M138.7 391.024C135.984 391.024 133.786 393.223 133.786 395.941C133.786 398.658 135.984 400.857 138.7 400.857C141.416 400.857 143.614 398.658 143.614 395.941C143.614 393.223 141.416 391.024 138.7 391.024Z" />
        <path d="M120.747 391.024C118.031 391.024 115.833 393.223 115.833 395.94C115.833 398.658 118.031 400.857 120.747 400.857C123.463 400.857 125.661 398.658 125.661 395.94C125.661 393.223 123.488 391.024 120.747 391.024Z" />
        <path d="M102.822 391.024C100.106 391.024 97.9084 393.223 97.9084 395.94C97.9084 398.658 100.106 400.857 102.822 400.857C105.538 400.857 107.736 398.658 107.736 395.94C107.736 393.223 105.538 391.024 102.822 391.024Z" />
        <path d="M84.8697 391.024C82.1535 391.024 79.9559 393.223 79.9559 395.94C79.9559 398.658 82.1535 400.857 84.8697 400.857C87.5858 400.857 89.7834 398.658 89.7834 395.94C89.7834 393.223 87.5858 391.024 84.8697 391.024Z" />
        <path d="M66.9445 391.024C64.2283 391.024 62.0306 393.223 62.0306 395.94C62.0306 398.658 64.2283 400.857 66.9445 400.857C69.6607 400.857 71.8584 398.658 71.8584 395.94C71.8584 393.223 69.6607 391.024 66.9445 391.024Z" />
        <path d="M48.9917 391.024C46.2756 391.024 44.078 393.223 44.078 395.94C44.078 398.658 46.2756 400.857 48.9917 400.857C51.7079 400.857 53.9055 398.658 53.9055 395.94C53.9055 393.223 51.7079 391.024 48.9917 391.024Z" />
        <path d="M31.0651 391.024C28.349 391.024 26.1514 393.223 26.1514 395.94C26.1514 398.658 28.349 400.857 31.0651 400.857C33.7813 400.857 35.979 398.658 35.979 395.94C35.979 393.223 33.7813 391.024 31.0651 391.024Z" />
        <path d="M13.1141 391.024C10.3979 391.024 8.20029 393.223 8.20029 395.94C8.20029 398.658 10.3979 400.857 13.1141 400.857C15.8302 400.857 18.0278 398.658 18.0278 395.94C18.0278 393.223 15.8302 391.024 13.1141 391.024Z" />
        <path d="M-4.8126 391.024C-7.5288 391.024 -9.72644 393.223 -9.72644 395.94C-9.72644 398.658 -7.52881 400.857 -4.8126 400.857C-2.09639 400.857 0.101319 398.658 0.101319 395.94C0.101319 393.223 -2.09639 391.024 -4.8126 391.024Z" />
        <path d="M954.96 373.533C952.49 373.533 950.49 375.535 950.49 378.005C950.49 380.476 952.49 382.477 954.96 382.477C957.429 382.477 959.429 380.476 959.429 378.005C959.429 375.535 957.429 373.533 954.96 373.533Z" />
        <path d="M937.009 373.533C934.539 373.533 932.539 375.535 932.539 378.005C932.539 380.476 934.539 382.477 937.009 382.477C939.478 382.477 941.478 380.476 941.478 378.005C941.478 375.535 939.478 373.533 937.009 373.533Z" />
        <path d="M919.058 373.533C916.589 373.533 914.588 375.535 914.588 378.005C914.588 380.476 916.589 382.477 919.058 382.477C921.527 382.477 923.527 380.476 923.527 378.005C923.527 375.535 921.552 373.533 919.058 373.533Z" />
        <path d="M901.131 373.533C898.662 373.533 896.662 375.535 896.662 378.005C896.662 380.476 898.662 382.477 901.131 382.477C903.6 382.477 905.6 380.476 905.6 378.005C905.6 375.535 903.6 373.533 901.131 373.533Z" />
        <path d="M883.18 373.533C880.711 373.533 878.711 375.535 878.711 378.005C878.711 380.476 880.711 382.477 883.18 382.477C885.649 382.477 887.649 380.476 887.649 378.005C887.649 375.535 885.674 373.533 883.18 373.533Z" />
        <path d="M865.251 373.533C862.782 373.533 860.782 375.535 860.782 378.005C860.782 380.476 862.782 382.477 865.251 382.477C867.721 382.477 869.721 380.476 869.721 378.005C869.721 375.535 867.721 373.533 865.251 373.533Z" />
        <path d="M847.302 373.533C844.833 373.533 842.833 375.535 842.833 378.005C842.833 380.476 844.833 382.477 847.302 382.477C849.772 382.477 851.772 380.476 851.772 378.005C851.772 375.535 849.796 373.533 847.302 373.533Z" />
        <path d="M829.374 373.533C826.905 373.533 824.904 375.535 824.904 378.005C824.904 380.476 826.905 382.477 829.374 382.477C831.843 382.477 833.843 380.476 833.843 378.005C833.843 375.535 831.843 373.533 829.374 373.533Z" />
        <path d="M811.423 373.533C808.954 373.533 806.954 375.535 806.954 378.005C806.954 380.476 808.954 382.477 811.423 382.477C813.892 382.477 815.892 380.476 815.892 378.005C815.892 375.535 813.917 373.533 811.423 373.533Z" />
        <path d="M793.496 373.533C791.027 373.533 789.027 375.535 789.027 378.005C789.027 380.476 791.027 382.477 793.496 382.477C795.965 382.477 797.965 380.476 797.965 378.005C797.965 375.535 795.965 373.533 793.496 373.533Z" />
        <path d="M775.547 373.533C773.078 373.533 771.078 375.535 771.078 378.005C771.078 380.476 773.078 382.477 775.547 382.477C778.016 382.477 780.016 380.476 780.016 378.005C780.016 375.535 778.016 373.533 775.547 373.533Z" />
        <path d="M757.618 373.533C755.149 373.533 753.149 375.535 753.149 378.005C753.149 380.476 755.149 382.477 757.618 382.477C760.088 382.477 762.088 380.476 762.088 378.005C762.088 375.535 760.088 373.533 757.618 373.533Z" />
        <path d="M739.666 373.533C737.196 373.533 735.196 375.535 735.196 378.005C735.196 380.476 737.196 382.477 739.666 382.477C742.135 382.477 744.135 380.476 744.135 378.005C744.135 375.535 742.135 373.533 739.666 373.533Z" />
        <path d="M721.741 373.533C719.271 373.533 717.271 375.535 717.271 378.005C717.271 380.476 719.271 382.477 721.741 382.477C724.21 382.477 726.21 380.476 726.21 378.005C726.21 375.535 724.21 373.533 721.741 373.533Z" />
        <path d="M703.788 373.533C701.319 373.533 699.319 375.535 699.319 378.005C699.319 380.476 701.319 382.477 703.788 382.477C706.257 382.477 708.257 380.476 708.257 378.005C708.257 375.535 706.257 373.533 703.788 373.533Z" />
        <path d="M685.863 373.533C683.394 373.533 681.394 375.535 681.394 378.005C681.394 380.476 683.394 382.477 685.863 382.477C688.332 382.477 690.332 380.476 690.332 378.005C690.332 375.535 688.332 373.533 685.863 373.533Z" />
        <path d="M667.91 373.533C665.441 373.533 663.441 375.535 663.441 378.005C663.441 380.476 665.441 382.477 667.91 382.477C670.38 382.477 672.38 380.476 672.38 378.005C672.38 375.535 670.38 373.533 667.91 373.533Z" />
        <path d="M649.984 373.533C647.514 373.533 645.514 375.535 645.514 378.005C645.514 380.476 647.514 382.477 649.984 382.477C652.453 382.477 654.453 380.476 654.453 378.005C654.453 375.535 652.453 373.533 649.984 373.533Z" />
        <path d="M632.033 373.533C629.563 373.533 627.563 375.535 627.563 378.005C627.563 380.476 629.563 382.477 632.033 382.477C634.502 382.477 636.502 380.476 636.502 378.005C636.502 375.535 634.502 373.533 632.033 373.533Z" />
        <path d="M614.106 373.533C611.637 373.533 609.637 375.535 609.637 378.005C609.637 380.476 611.637 382.477 614.106 382.477C616.575 382.477 618.575 380.476 618.575 378.005C618.575 375.535 616.575 373.533 614.106 373.533Z" />
        <path d="M596.155 373.533C593.686 373.533 591.686 375.534 591.686 378.005C591.686 380.475 593.686 382.477 596.155 382.477C598.624 382.477 600.624 380.475 600.624 378.005C600.624 375.534 598.624 373.533 596.155 373.533Z" />
        <path d="M578.227 373.533C575.757 373.533 573.757 375.534 573.757 378.005C573.757 380.475 575.757 382.477 578.227 382.477C580.696 382.477 582.696 380.475 582.696 378.005C582.696 375.534 580.696 373.533 578.227 373.533Z" />
        <path d="M560.274 373.533C557.805 373.533 555.804 375.534 555.804 378.005C555.804 380.475 557.805 382.477 560.274 382.477C562.743 382.477 564.743 380.475 564.743 378.005C564.743 375.534 562.743 373.533 560.274 373.533Z" />
        <path d="M542.349 373.533C539.879 373.533 537.879 375.534 537.879 378.005C537.879 380.475 539.879 382.477 542.349 382.477C544.818 382.477 546.818 380.475 546.818 378.005C546.818 375.534 544.818 373.533 542.349 373.533Z" />
        <path d="M524.396 373.533C521.927 373.533 519.927 375.534 519.927 378.005C519.927 380.475 521.927 382.477 524.396 382.477C526.865 382.477 528.865 380.475 528.865 378.005C528.865 375.534 526.865 373.533 524.396 373.533Z" />
        <path d="M506.471 373.533C504.002 373.533 502.002 375.534 502.002 378.005C502.002 380.475 504.002 382.477 506.471 382.477C508.94 382.477 510.94 380.475 510.94 378.005C510.94 375.534 508.94 373.533 506.471 373.533Z" />
        <path d="M488.518 373.533C486.049 373.533 484.049 375.534 484.049 378.005C484.049 380.475 486.049 382.477 488.518 382.477C490.988 382.477 492.988 380.475 492.988 378.005C492.988 375.534 490.988 373.533 488.518 373.533Z" />
        <path d="M470.59 373.533C468.121 373.533 466.121 375.534 466.121 378.005C466.121 380.475 468.121 382.477 470.59 382.477C473.059 382.477 475.059 380.475 475.059 378.005C475.059 375.534 473.059 373.533 470.59 373.533Z" />
        <path d="M452.641 373.533C450.171 373.533 448.171 375.534 448.171 378.005C448.171 380.475 450.171 382.477 452.641 382.477C455.11 382.477 457.11 380.475 457.11 378.005C457.11 375.534 455.11 373.533 452.641 373.533Z" />
        <path d="M434.688 373.533C432.219 373.533 430.219 375.534 430.219 378.005C430.219 380.475 432.219 382.477 434.688 382.477C437.157 382.477 439.157 380.475 439.157 378.005C439.157 375.534 437.182 373.533 434.688 373.533Z" />
        <path d="M416.763 373.533C414.294 373.533 412.294 375.534 412.294 378.005C412.294 380.475 414.294 382.477 416.763 382.477C419.232 382.477 421.232 380.475 421.232 378.005C421.232 375.534 419.232 373.533 416.763 373.533Z" />
        <path d="M398.81 373.533C396.341 373.533 394.341 375.534 394.341 378.005C394.341 380.475 396.341 382.477 398.81 382.477C401.28 382.477 403.28 380.475 403.28 378.005C403.28 375.534 401.304 373.533 398.81 373.533Z" />
        <path d="M380.885 373.533C378.416 373.533 376.416 375.534 376.416 378.005C376.416 380.475 378.416 382.477 380.885 382.477C383.355 382.477 385.355 380.475 385.355 378.005C385.355 375.534 383.355 373.533 380.885 373.533Z" />
        <path d="M362.933 373.533C360.463 373.533 358.463 375.534 358.463 378.005C358.463 380.475 360.463 382.477 362.933 382.477C365.402 382.477 367.402 380.475 367.402 378.005C367.402 375.534 365.427 373.533 362.933 373.533Z" />
        <path d="M345.006 373.533C342.537 373.533 340.536 375.534 340.536 378.005C340.536 380.475 342.537 382.477 345.006 382.477C347.475 382.477 349.475 380.475 349.475 378.005C349.475 375.534 347.475 373.533 345.006 373.533Z" />
        <path d="M327.055 373.533C324.586 373.533 322.585 375.534 322.585 378.005C322.585 380.475 324.586 382.477 327.055 382.477C329.524 382.477 331.524 380.475 331.524 378.005C331.524 375.534 329.524 373.533 327.055 373.533Z" />
        <path d="M309.126 373.533C306.657 373.533 304.657 375.534 304.657 378.005C304.657 380.475 306.657 382.477 309.126 382.477C311.596 382.477 313.596 380.475 313.596 378.005C313.596 375.534 311.596 373.533 309.126 373.533Z" />
        <path d="M291.177 373.533C288.708 373.533 286.708 375.534 286.708 378.005C286.708 380.475 288.708 382.477 291.177 382.477C293.646 382.477 295.646 380.475 295.646 378.005C295.646 375.534 293.646 373.533 291.177 373.533Z" />
        <path d="M273.249 373.533C270.779 373.533 268.779 375.534 268.779 378.005C268.779 380.475 270.779 382.477 273.249 382.477C275.718 382.477 277.718 380.475 277.718 378.005C277.718 375.534 275.718 373.533 273.249 373.533Z" />
        <path d="M255.298 373.533C252.828 373.533 250.828 375.534 250.828 378.005C250.828 380.475 252.828 382.477 255.298 382.477C257.767 382.477 259.767 380.475 259.767 378.005C259.767 375.534 257.767 373.533 255.298 373.533Z" />
        <path d="M237.371 373.533C234.902 373.533 232.902 375.534 232.902 378.005C232.902 380.475 234.902 382.477 237.371 382.477C239.84 382.477 241.84 380.475 241.84 378.005C241.84 375.534 239.84 373.533 237.371 373.533Z" />
        <path d="M219.422 373.533C216.953 373.533 214.952 375.534 214.952 378.005C214.952 380.475 216.953 382.477 219.422 382.477C221.891 382.477 223.891 380.475 223.891 378.005C223.891 375.534 221.891 373.533 219.422 373.533Z" />
        <path d="M201.493 373.533C199.024 373.533 197.024 375.534 197.024 378.005C197.024 380.475 199.024 382.477 201.493 382.477C203.963 382.477 205.963 380.475 205.963 378.005C205.963 375.534 203.963 373.533 201.493 373.533Z" />
        <path d="M183.541 373.533C181.071 373.533 179.071 375.534 179.071 378.005C179.071 380.475 181.071 382.477 183.541 382.477C186.01 382.477 188.01 380.475 188.01 378.005C188.01 375.534 186.01 373.533 183.541 373.533Z" />
        <path d="M165.616 373.533C163.146 373.533 161.146 375.534 161.146 378.005C161.146 380.475 163.146 382.477 165.616 382.477C168.085 382.477 170.085 380.475 170.085 378.005C170.085 375.534 168.085 373.533 165.616 373.533Z" />
        <path d="M147.663 373.533C145.194 373.533 143.194 375.534 143.194 378.005C143.194 380.475 145.194 382.477 147.663 382.477C150.132 382.477 152.132 380.475 152.132 378.005C152.132 375.534 150.132 373.533 147.663 373.533Z" />
        <path d="M129.738 373.533C127.269 373.533 125.268 375.534 125.268 378.005C125.268 380.475 127.269 382.477 129.738 382.477C132.207 382.477 134.207 380.475 134.207 378.005C134.207 375.534 132.207 373.533 129.738 373.533Z" />
        <path d="M111.785 373.533C109.316 373.533 107.316 375.534 107.316 378.005C107.316 380.475 109.316 382.477 111.785 382.477C114.255 382.477 116.255 380.475 116.255 378.005C116.255 375.534 114.255 373.533 111.785 373.533Z" />
        <path d="M93.8585 373.533C91.3893 373.533 89.3891 375.534 89.3891 378.005C89.3891 380.475 91.3893 382.477 93.8585 382.477C96.3278 382.477 98.3279 380.475 98.3279 378.005C98.3279 375.534 96.3278 373.533 93.8585 373.533Z" />
        <path d="M75.9076 373.533C73.4384 373.533 71.4383 375.534 71.4383 378.005C71.4383 380.475 73.4384 382.477 75.9076 382.477C78.3769 382.477 80.377 380.475 80.377 378.005C80.377 375.534 78.3769 373.533 75.9076 373.533Z" />
        <path d="M57.979 373.533C55.5097 373.533 53.5096 375.534 53.5096 378.005C53.5096 380.475 55.5097 382.477 57.979 382.477C60.4483 382.477 62.4485 380.475 62.4485 378.005C62.4485 375.534 60.4483 373.533 57.979 373.533Z" />
        <path d="M40.0298 373.533C37.5605 373.533 35.5604 375.534 35.5604 378.005C35.5604 380.475 37.5605 382.477 40.0298 382.477C42.4991 382.477 44.4993 380.475 44.4993 378.005C44.4993 375.534 42.4991 373.533 40.0298 373.533Z" />
        <path d="M22.0771 373.533C19.6078 373.533 17.6077 375.534 17.6077 378.005C17.6077 380.475 19.6078 382.477 22.0771 382.477C24.5463 382.477 26.5464 380.475 26.5464 378.005C26.5464 375.534 24.571 373.533 22.0771 373.533Z" />
        <path d="M4.1505 373.533C1.68123 373.533 -0.318877 375.534 -0.318877 378.005C-0.318877 380.475 1.68123 382.477 4.1505 382.477C6.61977 382.477 8.61987 380.475 8.61987 378.005C8.61987 375.534 6.61977 373.533 4.1505 373.533Z" />
        <path d="M945.973 355.82C943.627 355.82 941.751 357.722 941.751 360.045C941.751 362.391 943.652 364.269 945.973 364.269C948.319 364.269 950.196 362.367 950.196 360.045C950.22 357.722 948.319 355.82 945.973 355.82Z" />
        <path d="M928.045 355.82C925.699 355.82 923.823 357.722 923.823 360.045C923.823 362.391 925.724 364.269 928.045 364.269C930.391 364.269 932.267 362.367 932.267 360.045C932.267 357.722 930.366 355.82 928.045 355.82Z" />
        <path d="M910.094 355.845C907.762 355.845 905.872 357.736 905.872 360.069C905.872 362.402 907.762 364.294 910.094 364.294C912.426 364.294 914.317 362.402 914.317 360.069C914.317 357.736 912.426 355.845 910.094 355.845Z" />
        <path d="M892.167 355.845C889.835 355.845 887.945 357.736 887.945 360.069C887.945 362.402 889.835 364.294 892.167 364.294C894.499 364.294 896.39 362.402 896.39 360.069C896.39 357.736 894.499 355.845 892.167 355.845Z" />
        <path d="M874.216 355.845C871.884 355.845 869.994 357.736 869.994 360.069C869.994 362.402 871.884 364.294 874.216 364.294C876.548 364.294 878.439 362.402 878.439 360.069C878.439 357.736 876.548 355.845 874.216 355.845Z" />
        <path d="M856.29 355.845C853.958 355.845 852.067 357.736 852.067 360.069C852.067 362.402 853.958 364.294 856.29 364.294C858.622 364.294 860.512 362.402 860.512 360.069C860.512 357.736 858.622 355.845 856.29 355.845Z" />
        <path d="M838.339 355.845C836.007 355.845 834.116 357.736 834.116 360.069C834.116 362.402 836.007 364.294 838.339 364.294C840.671 364.294 842.561 362.402 842.561 360.069C842.561 357.736 840.671 355.845 838.339 355.845Z" />
        <path d="M820.412 355.845C818.08 355.845 816.19 357.736 816.19 360.069C816.19 362.402 818.08 364.294 820.412 364.294C822.744 364.294 824.635 362.402 824.635 360.069C824.635 357.736 822.744 355.845 820.412 355.845Z" />
        <path d="M802.459 355.845C800.127 355.845 798.237 357.736 798.237 360.069C798.237 362.402 800.127 364.294 802.459 364.294C804.791 364.294 806.682 362.402 806.682 360.069C806.682 357.736 804.791 355.845 802.459 355.845Z" />
        <path d="M784.533 355.845C782.201 355.845 780.31 357.736 780.31 360.069C780.31 362.402 782.201 364.294 784.533 364.294C786.865 364.294 788.755 362.402 788.755 360.069C788.755 357.736 786.865 355.845 784.533 355.845Z" />
        <path d="M766.582 355.845C764.25 355.845 762.359 357.736 762.359 360.069C762.359 362.402 764.25 364.294 766.582 364.294C768.914 364.294 770.804 362.402 770.804 360.069C770.804 357.736 768.914 355.845 766.582 355.845Z" />
        <path d="M748.655 355.845C746.323 355.845 744.432 357.736 744.432 360.069C744.432 362.402 746.323 364.294 748.655 364.294C750.987 364.294 752.877 362.402 752.877 360.069C752.877 357.736 750.987 355.845 748.655 355.845Z" />
        <path d="M730.704 355.845C728.372 355.845 726.481 357.736 726.481 360.069C726.481 362.402 728.372 364.294 730.704 364.294C733.036 364.294 734.926 362.402 734.926 360.069C734.926 357.736 733.036 355.845 730.704 355.845Z" />
        <path d="M712.776 355.845C710.444 355.845 708.553 357.736 708.553 360.069C708.553 362.402 710.444 364.294 712.776 364.294C715.107 364.294 716.998 362.402 716.998 360.069C716.998 357.736 715.107 355.845 712.776 355.845Z" />
        <path d="M694.826 355.845C692.494 355.845 690.604 357.736 690.604 360.069C690.604 362.402 692.494 364.294 694.826 364.294C697.158 364.294 699.049 362.402 699.049 360.069C699.049 357.736 697.158 355.845 694.826 355.845Z" />
        <path d="M676.873 355.845C674.541 355.845 672.651 357.736 672.651 360.069C672.651 362.402 674.541 364.294 676.873 364.294C679.205 364.294 681.096 362.402 681.096 360.069C681.096 357.736 679.205 355.845 676.873 355.845Z" />
        <path d="M658.948 355.845C656.617 355.845 654.726 357.736 654.726 360.069C654.726 362.402 656.617 364.294 658.948 364.294C661.28 364.294 663.171 362.402 663.171 360.069C663.171 357.736 661.28 355.845 658.948 355.845Z" />
        <path d="M640.996 355.845C638.664 355.845 636.773 357.736 636.773 360.069C636.773 362.402 638.664 364.294 640.996 364.294C643.328 364.294 645.218 362.402 645.218 360.069C645.218 357.736 643.328 355.845 640.996 355.845Z" />
        <path d="M623.067 355.845C620.735 355.845 618.845 357.736 618.845 360.069C618.845 362.402 620.735 364.294 623.067 364.294C625.399 364.294 627.29 362.402 627.29 360.069C627.29 357.736 625.399 355.845 623.067 355.845Z" />
        <path d="M605.118 355.845C602.786 355.845 600.896 357.736 600.896 360.069C600.896 362.402 602.786 364.294 605.118 364.294C607.45 364.294 609.34 362.402 609.34 360.069C609.34 357.736 607.45 355.845 605.118 355.845Z" />
        <path d="M587.19 355.845C584.858 355.845 582.967 357.736 582.967 360.069C582.967 362.402 584.858 364.294 587.19 364.294C589.522 364.294 591.412 362.402 591.412 360.069C591.412 357.736 589.522 355.845 587.19 355.845Z" />
        <path d="M569.239 355.845C566.907 355.845 565.016 357.736 565.016 360.069C565.016 362.402 566.907 364.294 569.239 364.294C571.571 364.294 573.461 362.402 573.461 360.069C573.461 357.736 571.571 355.845 569.239 355.845Z" />
        <path d="M551.312 355.845C548.98 355.845 547.09 357.736 547.09 360.069C547.09 362.402 548.98 364.294 551.312 364.294C553.644 364.294 555.534 362.402 555.534 360.069C555.534 357.736 553.644 355.845 551.312 355.845Z" />
        <path d="M533.359 355.845C531.027 355.845 529.137 357.736 529.137 360.069C529.137 362.402 531.027 364.294 533.359 364.294C535.691 364.294 537.582 362.402 537.582 360.069C537.582 357.736 535.691 355.845 533.359 355.845Z" />
        <path d="M515.434 355.845C513.102 355.845 511.212 357.736 511.212 360.069C511.212 362.402 513.102 364.294 515.434 364.294C517.766 364.294 519.657 362.402 519.657 360.069C519.657 357.736 517.766 355.845 515.434 355.845Z" />
        <path d="M497.482 355.845C495.15 355.845 493.259 357.736 493.259 360.069C493.259 362.402 495.15 364.294 497.482 364.294C499.814 364.294 501.704 362.402 501.704 360.069C501.704 357.736 499.814 355.845 497.482 355.845Z" />
        <path d="M479.556 355.845C477.224 355.845 475.334 357.736 475.334 360.069C475.334 362.402 477.224 364.294 479.556 364.294C481.888 364.294 483.779 362.402 483.779 360.069C483.779 357.736 481.888 355.845 479.556 355.845Z" />
        <path d="M461.604 355.845C459.272 355.845 457.381 357.736 457.381 360.069C457.381 362.402 459.272 364.294 461.604 364.294C463.936 364.294 465.826 362.402 465.826 360.069C465.826 357.736 463.936 355.845 461.604 355.845Z" />
        <path d="M443.675 355.845C441.343 355.845 439.453 357.736 439.453 360.069C439.453 362.402 441.343 364.294 443.675 364.294C446.007 364.294 447.898 362.402 447.898 360.069C447.898 357.736 446.007 355.845 443.675 355.845Z" />
        <path d="M425.726 355.845C423.394 355.845 421.504 357.736 421.504 360.069C421.504 362.402 423.394 364.294 425.726 364.294C428.058 364.294 429.948 362.402 429.948 360.069C429.948 357.736 428.058 355.845 425.726 355.845Z" />
        <path d="M407.799 355.82C405.454 355.82 403.577 357.722 403.577 360.044C403.577 362.391 405.478 364.269 407.799 364.269C410.145 364.269 412.022 362.367 412.022 360.044C412.022 357.722 410.121 355.82 407.799 355.82Z" />
        <path d="M389.848 355.82C387.503 355.82 385.626 357.722 385.626 360.044C385.626 362.391 387.527 364.269 389.848 364.269C392.194 364.269 394.071 362.367 394.071 360.044C394.071 357.722 392.194 355.82 389.848 355.82Z" />
        <path d="M371.92 355.82C369.574 355.82 367.698 357.722 367.698 360.044C367.698 362.391 369.599 364.269 371.92 364.269C374.266 364.269 376.142 362.367 376.142 360.044C376.142 357.722 374.241 355.82 371.92 355.82Z" />
        <path d="M353.971 355.82C351.625 355.82 349.748 357.722 349.748 360.044C349.748 362.391 351.65 364.269 353.971 364.269C356.317 364.269 358.193 362.367 358.193 360.044C358.193 357.722 356.317 355.82 353.971 355.82Z" />
        <path d="M336.042 355.82C333.697 355.82 331.82 357.722 331.82 360.044C331.82 362.391 333.721 364.269 336.042 364.269C338.388 364.269 340.265 362.367 340.265 360.044C340.265 357.722 338.363 355.82 336.042 355.82Z" />
        <path d="M318.091 355.82C315.745 355.82 313.869 357.722 313.869 360.044C313.869 362.391 315.77 364.269 318.091 364.269C320.437 364.269 322.314 362.367 322.314 360.044C322.314 357.722 320.437 355.82 318.091 355.82Z" />
        <path d="M300.165 355.82C297.819 355.82 295.942 357.722 295.942 360.044C295.942 362.391 297.843 364.269 300.165 364.269C302.51 364.269 304.387 362.367 304.387 360.044C304.387 357.722 302.486 355.82 300.165 355.82Z" />
        <path d="M282.214 355.82C279.868 355.82 277.991 357.722 277.991 360.044C277.991 362.391 279.893 364.269 282.214 364.269C284.559 364.269 286.436 362.367 286.436 360.044C286.436 357.722 284.559 355.82 282.214 355.82Z" />
        <path d="M264.287 355.82C261.941 355.82 260.064 357.722 260.064 360.044C260.064 362.391 261.966 364.269 264.287 364.269C266.633 364.269 268.509 362.367 268.509 360.044C268.509 357.722 266.608 355.82 264.287 355.82Z" />
        <path d="M246.334 355.82C243.988 355.82 242.112 357.722 242.112 360.044C242.112 362.391 244.013 364.269 246.334 364.269C248.68 364.269 250.557 362.367 250.557 360.044C250.557 357.722 248.68 355.82 246.334 355.82Z" />
        <path d="M228.381 355.82C226.035 355.82 224.159 357.722 224.159 360.044C224.159 362.391 226.06 364.269 228.381 364.269C230.727 364.269 232.604 362.367 232.604 360.044C232.628 357.722 230.727 355.82 228.381 355.82Z" />
        <path d="M210.456 355.82C208.111 355.82 206.234 357.722 206.234 360.044C206.234 362.391 208.135 364.269 210.456 364.269C212.802 364.269 214.679 362.367 214.679 360.044C214.679 357.722 212.802 355.82 210.456 355.82Z" />
        <path d="M192.505 355.82C190.16 355.82 188.283 357.722 188.283 360.044C188.283 362.391 190.184 364.269 192.505 364.269C194.851 364.269 196.728 362.367 196.728 360.044C196.752 357.722 194.851 355.82 192.505 355.82Z" />
        <path d="M174.579 355.82C172.233 355.82 170.356 357.722 170.356 360.044C170.356 362.391 172.258 364.269 174.579 364.269C176.925 364.269 178.801 362.367 178.801 360.044C178.801 357.722 176.9 355.82 174.579 355.82Z" />
        <path d="M156.626 355.82C154.28 355.82 152.403 357.722 152.403 360.044C152.403 362.391 154.305 364.269 156.626 364.269C158.972 364.269 160.848 362.367 160.848 360.044C160.873 357.722 158.972 355.82 156.626 355.82Z" />
        <path d="M138.699 355.82C136.354 355.82 134.477 357.722 134.477 360.044C134.477 362.391 136.378 364.269 138.699 364.269C141.045 364.269 142.922 362.367 142.922 360.044C142.922 357.722 141.02 355.82 138.699 355.82Z" />
        <path d="M120.748 355.82C118.402 355.82 116.526 357.722 116.526 360.044C116.526 362.391 118.427 364.269 120.748 364.269C123.094 364.269 124.97 362.367 124.97 360.044C124.995 357.722 123.094 355.82 120.748 355.82Z" />
        <path d="M102.822 355.82C100.476 355.82 98.5994 357.722 98.5994 360.044C98.5994 362.391 100.501 364.269 102.822 364.269C105.168 364.269 107.044 362.367 107.044 360.044C107.044 357.722 105.143 355.82 102.822 355.82Z" />
        <path d="M84.8703 355.82C82.5245 355.82 80.6479 357.722 80.6479 360.044C80.6479 362.391 82.5492 364.269 84.8703 364.269C87.2161 364.269 89.0928 362.367 89.0928 360.044C89.1175 357.722 87.2161 355.82 84.8703 355.82Z" />
        <path d="M66.9421 355.82C64.5964 355.82 62.7198 357.722 62.7198 360.044C62.7198 362.391 64.6211 364.269 66.9421 364.269C69.2879 364.269 71.1646 362.367 71.1646 360.044C71.1646 357.722 69.2632 355.82 66.9421 355.82Z" />
        <path d="M48.9927 355.82C46.6469 355.82 44.7703 357.722 44.7703 360.044C44.7703 362.391 46.6716 364.269 48.9927 364.269C51.3385 364.269 53.2151 362.367 53.2151 360.044C53.2398 357.722 51.3385 355.82 48.9927 355.82Z" />
        <path d="M31.0644 355.82C28.7186 355.82 26.8419 357.722 26.8419 360.044C26.8419 362.391 28.7433 364.269 31.0644 364.269C33.4102 364.269 35.2869 362.367 35.2869 360.044C35.2869 357.722 33.3855 355.82 31.0644 355.82Z" />
        <path d="M13.1135 355.82C10.7677 355.82 8.89112 357.722 8.89112 360.044C8.89112 362.391 10.7924 364.269 13.1135 364.269C15.4593 364.269 17.3359 362.367 17.3359 360.044C17.3359 357.722 15.4593 355.82 13.1135 355.82Z" />
        <path d="M954.961 338.032C952.711 338.032 950.887 339.857 950.887 342.109C950.887 344.36 952.711 346.185 954.961 346.185C957.211 346.185 959.035 344.36 959.035 342.109C959.035 339.857 957.211 338.032 954.961 338.032Z" />
        <path d="M937.01 338.032C934.76 338.032 932.936 339.857 932.936 342.109C932.936 344.36 934.76 346.185 937.01 346.185C939.26 346.185 941.084 344.36 941.084 342.109C941.084 339.857 939.26 338.032 937.01 338.032Z" />
        <path d="M919.057 338.032C916.807 338.032 914.983 339.857 914.983 342.109C914.983 344.36 916.807 346.185 919.057 346.185C921.308 346.185 923.132 344.36 923.132 342.109C923.132 339.857 921.308 338.032 919.057 338.032Z" />
        <path d="M901.132 338.032C898.882 338.032 897.058 339.857 897.058 342.109C897.058 344.36 898.882 346.185 901.132 346.185C903.382 346.185 905.207 344.36 905.207 342.109C905.207 339.857 903.382 338.032 901.132 338.032Z" />
        <path d="M883.18 338.032C880.929 338.032 879.105 339.857 879.105 342.109C879.105 344.36 880.929 346.185 883.18 346.185C885.43 346.185 887.254 344.36 887.254 342.109C887.254 339.857 885.43 338.032 883.18 338.032Z" />
        <path d="M865.251 338.032C863.001 338.032 861.177 339.857 861.177 342.108C861.177 344.36 863.001 346.185 865.251 346.185C867.501 346.185 869.325 344.36 869.325 342.108C869.325 339.857 867.501 338.032 865.251 338.032Z" />
        <path d="M847.302 338.032C845.052 338.032 843.228 339.857 843.228 342.108C843.228 344.36 845.052 346.185 847.302 346.185C849.552 346.185 851.376 344.36 851.376 342.108C851.376 339.857 849.552 338.032 847.302 338.032Z" />
        <path d="M829.373 338.032C827.123 338.032 825.299 339.857 825.299 342.108C825.299 344.36 827.123 346.185 829.373 346.185C831.624 346.185 833.448 344.36 833.448 342.108C833.448 339.857 831.624 338.032 829.373 338.032Z" />
        <path d="M811.423 338.032C809.172 338.032 807.348 339.857 807.348 342.108C807.348 344.36 809.172 346.185 811.423 346.185C813.673 346.185 815.497 344.36 815.497 342.108C815.497 339.857 813.673 338.032 811.423 338.032Z" />
        <path d="M793.496 338.032C791.246 338.032 789.422 339.857 789.422 342.108C789.422 344.36 791.246 346.185 793.496 346.185C795.746 346.185 797.57 344.36 797.57 342.108C797.57 339.857 795.746 338.032 793.496 338.032Z" />
        <path d="M775.543 338.032C773.293 338.032 771.469 339.857 771.469 342.108C771.469 344.36 773.293 346.185 775.543 346.185C777.793 346.185 779.617 344.36 779.617 342.108C779.617 339.857 777.793 338.032 775.543 338.032Z" />
        <path d="M757.618 338.032C755.368 338.032 753.544 339.857 753.544 342.108C753.544 344.36 755.368 346.185 757.618 346.185C759.868 346.185 761.692 344.36 761.692 342.108C761.692 339.857 759.868 338.032 757.618 338.032Z" />
        <path d="M739.665 338.032C737.415 338.032 735.591 339.857 735.591 342.108C735.591 344.36 737.415 346.185 739.665 346.185C741.916 346.185 743.74 344.36 743.74 342.108C743.74 339.857 741.916 338.032 739.665 338.032Z" />
        <path d="M721.74 338.032C719.49 338.032 717.666 339.857 717.666 342.108C717.666 344.36 719.49 346.185 721.74 346.185C723.991 346.185 725.815 344.36 725.815 342.108C725.815 339.857 723.991 338.032 721.74 338.032Z" />
        <path d="M703.788 338.032C701.537 338.032 699.713 339.857 699.713 342.108C699.713 344.36 701.537 346.185 703.788 346.185C706.038 346.185 707.862 344.36 707.862 342.108C707.862 339.857 706.038 338.032 703.788 338.032Z" />
        <path d="M685.861 338.032C683.611 338.032 681.787 339.857 681.787 342.108C681.787 344.36 683.611 346.185 685.861 346.185C688.111 346.185 689.935 344.36 689.935 342.108C689.935 339.857 688.111 338.032 685.861 338.032Z" />
        <path d="M667.91 338.032C665.66 338.032 663.836 339.857 663.836 342.108C663.836 344.36 665.66 346.185 667.91 346.185C670.16 346.185 671.984 344.36 671.984 342.108C671.984 339.857 670.16 338.032 667.91 338.032Z" />
        <path d="M649.983 338.032C647.733 338.032 645.909 339.857 645.909 342.108C645.909 344.36 647.733 346.185 649.983 346.185C652.233 346.185 654.057 344.36 654.057 342.108C654.057 339.857 652.233 338.032 649.983 338.032Z" />
        <path d="M632.032 338.032C629.782 338.032 627.958 339.857 627.958 342.108C627.958 344.36 629.782 346.185 632.032 346.185C634.282 346.185 636.107 344.36 636.107 342.108C636.107 339.857 634.282 338.032 632.032 338.032Z" />
        <path d="M614.104 338.032C611.854 338.032 610.03 339.857 610.03 342.108C610.03 344.36 611.854 346.185 614.104 346.185C616.354 346.185 618.178 344.36 618.178 342.108C618.178 339.857 616.354 338.032 614.104 338.032Z" />
        <path d="M596.155 338.032C593.904 338.032 592.08 339.857 592.08 342.108C592.08 344.36 593.904 346.185 596.155 346.185C598.405 346.185 600.229 344.36 600.229 342.108C600.229 339.857 598.405 338.032 596.155 338.032Z" />
        <path d="M578.226 338.032C575.976 338.032 574.152 339.857 574.152 342.108C574.152 344.36 575.976 346.185 578.226 346.185C580.476 346.185 582.3 344.36 582.3 342.108C582.3 339.857 580.476 338.032 578.226 338.032Z" />
        <path d="M560.275 338.032C558.025 338.032 556.201 339.857 556.201 342.108C556.201 344.36 558.025 346.185 560.275 346.185C562.525 346.185 564.349 344.36 564.349 342.108C564.349 339.857 562.525 338.032 560.275 338.032Z" />
        <path d="M542.349 338.032C540.098 338.032 538.274 339.857 538.274 342.108C538.274 344.36 540.098 346.185 542.349 346.185C544.599 346.185 546.423 344.36 546.423 342.108C546.423 339.857 544.599 338.032 542.349 338.032Z" />
        <path d="M524.399 338.032C522.149 338.032 520.325 339.857 520.325 342.108C520.325 344.36 522.149 346.185 524.399 346.185C526.649 346.185 528.474 344.36 528.474 342.108C528.474 339.857 526.649 338.032 524.399 338.032Z" />
        <path d="M506.471 338.032C504.221 338.032 502.397 339.857 502.397 342.108C502.397 344.36 504.221 346.185 506.471 346.185C508.721 346.185 510.545 344.36 510.545 342.108C510.545 339.857 508.721 338.032 506.471 338.032Z" />
        <path d="M488.52 338.032C486.27 338.032 484.446 339.857 484.446 342.108C484.446 344.36 486.27 346.185 488.52 346.185C490.77 346.185 492.594 344.36 492.594 342.108C492.594 339.857 490.77 338.032 488.52 338.032Z" />
        <path d="M470.593 338.032C468.343 338.032 466.519 339.857 466.519 342.108C466.519 344.36 468.343 346.185 470.593 346.185C472.843 346.185 474.667 344.36 474.667 342.108C474.667 339.857 472.843 338.032 470.593 338.032Z" />
        <path d="M452.64 338.032C450.39 338.032 448.566 339.857 448.566 342.108C448.566 344.36 450.39 346.185 452.64 346.185C454.891 346.185 456.715 344.36 456.715 342.108C456.715 339.857 454.891 338.032 452.64 338.032Z" />
        <path d="M434.689 338.032C432.439 338.032 430.615 339.857 430.615 342.108C430.615 344.36 432.439 346.185 434.689 346.185C436.94 346.185 438.764 344.36 438.764 342.108C438.764 339.857 436.94 338.032 434.689 338.032Z" />
        <path d="M416.763 338.032C414.512 338.032 412.688 339.857 412.688 342.108C412.688 344.36 414.512 346.185 416.763 346.185C419.013 346.185 420.837 344.36 420.837 342.108C420.837 339.857 419.013 338.032 416.763 338.032Z" />
        <path d="M398.81 338.032C396.56 338.032 394.736 339.857 394.736 342.108C394.736 344.36 396.56 346.185 398.81 346.185C401.06 346.185 402.884 344.36 402.884 342.108C402.884 339.857 401.06 338.032 398.81 338.032Z" />
        <path d="M380.883 338.032C378.633 338.032 376.809 339.857 376.809 342.108C376.809 344.36 378.633 346.185 380.883 346.185C383.133 346.185 384.958 344.36 384.958 342.108C384.958 339.857 383.133 338.032 380.883 338.032Z" />
        <path d="M362.932 338.032C360.682 338.032 358.858 339.857 358.858 342.108C358.858 344.36 360.682 346.185 362.932 346.185C365.182 346.185 367.006 344.36 367.006 342.108C367.006 339.857 365.182 338.032 362.932 338.032Z" />
        <path d="M345.006 338.032C342.755 338.032 340.931 339.857 340.931 342.108C340.931 344.36 342.755 346.185 345.006 346.185C347.256 346.185 349.08 344.36 349.08 342.108C349.08 339.857 347.256 338.032 345.006 338.032Z" />
        <path d="M327.055 338.032C324.805 338.032 322.98 339.857 322.98 342.108C322.98 344.36 324.805 346.185 327.055 346.185C329.305 346.185 331.129 344.36 331.129 342.108C331.129 339.857 329.305 338.032 327.055 338.032Z" />
        <path d="M309.128 338.032C306.878 338.032 305.054 339.857 305.054 342.108C305.054 344.36 306.878 346.185 309.128 346.185C311.378 346.185 313.202 344.36 313.202 342.108C313.202 339.857 311.378 338.032 309.128 338.032Z" />
        <path d="M291.177 338.032C288.93 338.032 287.103 339.86 287.103 342.108C287.103 344.357 288.93 346.185 291.177 346.185C293.424 346.185 295.251 344.357 295.251 342.108C295.251 339.86 293.449 338.032 291.177 338.032Z" />
        <path d="M273.249 338.032C271.002 338.032 269.174 339.86 269.174 342.108C269.174 344.357 271.002 346.185 273.249 346.185C275.496 346.185 277.323 344.357 277.323 342.108C277.323 339.86 275.496 338.032 273.249 338.032Z" />
        <path d="M255.299 338.032C253.052 338.032 251.225 339.86 251.225 342.108C251.225 344.357 253.052 346.185 255.299 346.185C257.546 346.185 259.373 344.357 259.373 342.108C259.373 339.86 257.546 338.032 255.299 338.032Z" />
        <path d="M237.373 338.032C235.125 338.032 233.298 339.86 233.298 342.108C233.298 344.357 235.125 346.185 237.373 346.185C239.62 346.185 241.447 344.357 241.447 342.108C241.447 339.86 239.62 338.032 237.373 338.032Z" />
        <path d="M219.42 338.032C217.173 338.032 215.345 339.86 215.345 342.108C215.345 344.357 217.173 346.185 219.42 346.185C221.667 346.185 223.494 344.357 223.494 342.108C223.494 339.86 221.667 338.032 219.42 338.032Z" />
        <path d="M201.493 338.032C199.246 338.032 197.419 339.86 197.419 342.108C197.419 344.357 199.246 346.185 201.493 346.185C203.74 346.185 205.567 344.357 205.567 342.108C205.567 339.86 203.74 338.032 201.493 338.032Z" />
        <path d="M183.54 338.032C181.293 338.032 179.466 339.86 179.466 342.108C179.466 344.357 181.293 346.185 183.54 346.185C185.787 346.185 187.615 344.357 187.615 342.108C187.615 339.86 185.787 338.032 183.54 338.032Z" />
        <path d="M165.615 338.032C163.368 338.032 161.541 339.86 161.541 342.108C161.541 344.357 163.368 346.185 165.615 346.185C167.862 346.185 169.689 344.357 169.689 342.108C169.689 339.86 167.862 338.032 165.615 338.032Z" />
        <path d="M147.663 338.032C145.416 338.032 143.588 339.86 143.588 342.108C143.588 344.357 145.416 346.185 147.663 346.185C149.91 346.185 151.737 344.357 151.737 342.108C151.737 339.86 149.91 338.032 147.663 338.032Z" />
        <path d="M129.738 338.032C127.491 338.032 125.663 339.86 125.663 342.108C125.663 344.357 127.491 346.185 129.738 346.185C131.985 346.185 133.812 344.357 133.812 342.108C133.812 339.86 131.985 338.032 129.738 338.032Z" />
        <path d="M111.785 338.032C109.538 338.032 107.711 339.86 107.711 342.108C107.711 344.357 109.538 346.185 111.785 346.185C114.032 346.185 115.859 344.357 115.859 342.108C115.859 339.86 114.032 338.032 111.785 338.032Z" />
        <path d="M93.8581 338.032C91.6111 338.032 89.7838 339.86 89.7838 342.108C89.7838 344.357 91.6111 346.185 93.8581 346.185C96.1052 346.185 97.9324 344.357 97.9324 342.108C97.9324 339.86 96.1052 338.032 93.8581 338.032Z" />
        <path d="M75.9071 338.032C73.6601 338.032 71.8329 339.86 71.8329 342.108C71.8329 344.357 73.6601 346.185 75.9071 346.185C78.1542 346.185 79.9814 344.357 79.9814 342.108C79.9814 339.86 78.1542 338.032 75.9071 338.032Z" />
        <path d="M57.9804 338.032C55.7333 338.032 53.9061 339.86 53.9061 342.108C53.9061 344.357 55.7333 346.185 57.9804 346.185C60.2274 346.185 62.0547 344.357 62.0547 342.108C62.0547 339.86 60.2274 338.032 57.9804 338.032Z" />
        <path d="M40.0278 338.032C37.7808 338.032 35.9536 339.86 35.9536 342.108C35.9536 344.357 37.7808 346.185 40.0278 346.185C42.2748 346.185 44.1021 344.357 44.1021 342.108C44.1021 339.86 42.2748 338.032 40.0278 338.032Z" />
        <path d="M22.0766 338.032C19.8265 338.032 18.0024 339.857 18.0024 342.108C18.0024 344.36 19.8265 346.185 22.0766 346.185C24.3268 346.185 26.1509 344.36 26.1509 342.108C26.1509 339.857 24.3268 338.032 22.0766 338.032Z" />
        <path d="M4.15007 338.032C1.89989 338.032 0.0757728 339.857 0.0757726 342.108C0.0757724 344.36 1.89989 346.185 4.15007 346.185C6.40025 346.185 8.22437 344.36 8.22437 342.108C8.22437 339.857 6.40025 338.032 4.15007 338.032Z" />
        <path d="M945.973 320.319C943.85 320.319 942.146 322.023 942.146 324.148C942.146 326.273 943.85 327.977 945.973 327.977C948.097 327.977 949.801 326.273 949.801 324.148C949.801 322.048 948.097 320.319 945.973 320.319Z" />
        <path d="M928.045 320.319C925.921 320.319 924.217 322.023 924.217 324.148C924.217 326.273 925.921 327.977 928.045 327.977C930.168 327.977 931.872 326.273 931.872 324.148C931.872 322.048 930.144 320.319 928.045 320.319Z" />
        <path d="M910.094 320.319C907.97 320.319 906.266 322.023 906.266 324.148C906.266 326.273 907.97 327.977 910.094 327.977C912.217 327.977 913.921 326.273 913.921 324.148C913.921 322.048 912.217 320.319 910.094 320.319Z" />
        <path d="M892.167 320.319C890.043 320.319 888.34 322.023 888.34 324.148C888.34 326.273 890.043 327.977 892.167 327.977C894.291 327.977 895.994 326.273 895.994 324.148C895.994 322.048 894.266 320.319 892.167 320.319Z" />
        <path d="M874.216 320.319C872.093 320.319 870.389 322.023 870.389 324.148C870.389 326.273 872.093 327.977 874.216 327.977C876.34 327.977 878.043 326.273 878.043 324.148C878.043 322.048 876.34 320.319 874.216 320.319Z" />
        <path d="M856.289 320.319C854.166 320.319 852.462 322.023 852.462 324.148C852.462 326.273 854.166 327.977 856.289 327.977C858.413 327.977 860.117 326.273 860.117 324.148C860.117 322.048 858.388 320.319 856.289 320.319Z" />
        <path d="M838.337 320.319C836.213 320.319 834.509 322.023 834.509 324.148C834.509 326.273 836.213 327.977 838.337 327.977C840.46 327.977 842.164 326.273 842.164 324.148C842.164 322.048 840.46 320.319 838.337 320.319Z" />
        <path d="M820.41 320.319C818.286 320.319 816.582 322.023 816.582 324.148C816.582 326.273 818.286 327.977 820.41 327.977C822.533 327.977 824.237 326.273 824.237 324.148C824.237 322.048 822.509 320.319 820.41 320.319Z" />
        <path d="M802.459 320.319C800.335 320.319 798.632 322.023 798.632 324.148C798.632 326.273 800.335 327.977 802.459 327.977C804.582 327.977 806.286 326.273 806.286 324.148C806.286 322.048 804.582 320.319 802.459 320.319Z" />
        <path d="M784.534 320.319C782.41 320.319 780.707 322.023 780.707 324.148C780.707 326.273 782.41 327.977 784.534 327.977C786.658 327.977 788.361 326.273 788.361 324.148C788.361 322.048 786.633 320.319 784.534 320.319Z" />
        <path d="M766.581 320.319C764.458 320.319 762.754 322.023 762.754 324.148C762.754 326.273 764.458 327.977 766.581 327.977C768.705 327.977 770.409 326.273 770.409 324.148C770.409 322.048 768.705 320.319 766.581 320.319Z" />
        <path d="M748.653 320.319C746.529 320.319 744.825 322.023 744.825 324.148C744.825 326.273 746.529 327.977 748.653 327.977C750.776 327.977 752.48 326.273 752.48 324.148C752.48 322.048 750.752 320.319 748.653 320.319Z" />
        <path d="M730.704 320.319C728.58 320.319 726.876 322.023 726.876 324.148C726.876 326.273 728.58 327.977 730.704 327.977C732.827 327.977 734.531 326.273 734.531 324.148C734.531 322.048 732.827 320.319 730.704 320.319Z" />
        <path d="M712.775 320.319C710.652 320.319 708.948 322.023 708.948 324.148C708.948 326.273 710.652 327.977 712.775 327.977C714.899 327.977 716.603 326.273 716.603 324.148C716.603 322.048 714.874 320.319 712.775 320.319Z" />
        <path d="M694.826 320.319C692.702 320.319 690.999 322.023 690.999 324.148C690.999 326.273 692.702 327.977 694.826 327.977C696.949 327.977 698.653 326.273 698.653 324.148C698.653 322.048 696.949 320.319 694.826 320.319Z" />
        <path d="M676.875 320.319C674.751 320.319 673.047 322.023 673.047 324.148C673.047 326.273 674.751 327.977 676.875 327.977C678.998 327.977 680.702 326.273 680.702 324.148C680.727 322.048 678.998 320.319 676.875 320.319Z" />
        <path d="M658.948 320.319C656.825 320.319 655.121 322.023 655.121 324.148C655.121 326.273 656.825 327.977 658.948 327.977C661.072 327.977 662.776 326.273 662.776 324.148C662.776 322.048 661.072 320.319 658.948 320.319Z" />
        <path d="M640.995 320.319C638.872 320.319 637.168 322.023 637.168 324.148C637.168 326.273 638.872 327.977 640.995 327.977C643.119 327.977 644.823 326.273 644.823 324.148C644.847 322.048 643.119 320.319 640.995 320.319Z" />
        <path d="M623.069 320.319C620.945 320.319 619.241 322.023 619.241 324.148C619.241 326.273 620.945 327.977 623.069 327.977C625.192 327.977 626.896 326.273 626.896 324.148C626.896 322.048 625.192 320.319 623.069 320.319Z" />
        <path d="M605.117 320.319C602.994 320.319 601.29 322.023 601.29 324.148C601.29 326.273 602.994 327.977 605.117 327.977C607.241 327.977 608.945 326.273 608.945 324.148C608.97 322.048 607.241 320.319 605.117 320.319Z" />
        <path d="M587.191 320.319C585.068 320.319 583.364 322.023 583.364 324.148C583.364 326.273 585.068 327.977 587.191 327.977C589.315 327.977 591.018 326.273 591.018 324.148C591.018 322.048 589.315 320.319 587.191 320.319Z" />
        <path d="M569.24 320.319C567.116 320.319 565.413 322.023 565.413 324.148C565.413 326.273 567.116 327.977 569.24 327.977C571.364 327.977 573.067 326.273 573.067 324.148C573.067 322.048 571.364 320.319 569.24 320.319Z" />
        <path d="M551.312 320.319C549.188 320.319 547.484 322.023 547.484 324.148C547.484 326.273 549.188 327.977 551.312 327.977C553.435 327.977 555.139 326.273 555.139 324.148C555.139 322.048 553.435 320.319 551.312 320.319Z" />
        <path d="M533.359 320.319C531.235 320.319 529.532 322.023 529.532 324.148C529.532 326.273 531.235 327.977 533.359 327.977C535.483 327.977 537.186 326.273 537.186 324.148C537.186 322.048 535.483 320.319 533.359 320.319Z" />
        <path d="M515.434 320.319C513.31 320.319 511.607 322.023 511.607 324.148C511.607 326.273 513.31 327.977 515.434 327.977C517.557 327.977 519.261 326.273 519.261 324.148C519.261 322.048 517.533 320.319 515.434 320.319Z" />
        <path d="M497.481 320.319C495.358 320.319 493.654 322.023 493.654 324.148C493.654 326.273 495.358 327.977 497.481 327.977C499.605 327.977 501.308 326.273 501.308 324.148C501.308 322.048 499.605 320.319 497.481 320.319Z" />
        <path d="M479.556 320.319C477.433 320.319 475.729 322.023 475.729 324.148C475.729 326.273 477.433 327.977 479.556 327.977C481.68 327.977 483.384 326.273 483.384 324.148C483.384 322.048 481.655 320.319 479.556 320.319Z" />
        <path d="M461.603 320.319C459.48 320.319 457.776 322.023 457.776 324.148C457.776 326.273 459.48 327.977 461.603 327.977C463.727 327.977 465.431 326.273 465.431 324.148C465.431 322.048 463.727 320.319 461.603 320.319Z" />
        <path d="M443.675 320.319C441.551 320.319 439.848 322.023 439.848 324.148C439.848 326.273 441.551 327.977 443.675 327.977C445.799 327.977 447.502 326.273 447.502 324.148C447.502 322.048 445.774 320.319 443.675 320.319Z" />
        <path d="M425.726 320.319C423.602 320.319 421.898 322.023 421.898 324.148C421.898 326.273 423.602 327.977 425.726 327.977C427.849 327.977 429.553 326.273 429.553 324.148C429.553 322.048 427.849 320.319 425.726 320.319Z" />
        <path d="M407.799 320.319C405.676 320.319 403.972 322.023 403.972 324.148C403.972 326.273 405.676 327.977 407.799 327.977C409.923 327.977 411.626 326.273 411.626 324.148C411.626 322.048 409.898 320.319 407.799 320.319Z" />
        <path d="M389.848 320.319C387.725 320.319 386.021 322.023 386.021 324.148C386.021 326.273 387.725 327.977 389.848 327.977C391.972 327.977 393.676 326.273 393.676 324.148C393.676 322.048 391.972 320.319 389.848 320.319Z" />
        <path d="M371.92 320.319C369.796 320.319 368.092 322.023 368.092 324.148C368.092 326.273 369.796 327.977 371.92 327.977C374.043 327.977 375.747 326.273 375.747 324.148C375.747 322.048 374.019 320.319 371.92 320.319Z" />
        <path d="M353.969 320.319C351.845 320.319 350.141 322.023 350.141 324.148C350.141 326.273 351.845 327.977 353.969 327.977C356.092 327.977 357.796 326.273 357.796 324.148C357.796 322.048 356.092 320.319 353.969 320.319Z" />
        <path d="M336.042 320.319C333.918 320.319 332.215 322.023 332.215 324.148C332.215 326.273 333.918 327.977 336.042 327.977C338.165 327.977 339.869 326.273 339.869 324.148C339.869 322.048 338.141 320.319 336.042 320.319Z" />
        <path d="M318.091 320.319C315.968 320.319 314.264 322.023 314.264 324.148C314.264 326.273 315.968 327.977 318.091 327.977C320.215 327.977 321.918 326.273 321.918 324.148C321.918 322.048 320.215 320.319 318.091 320.319Z" />
        <path d="M300.164 320.319C298.041 320.319 296.337 322.023 296.337 324.148C296.337 326.273 298.041 327.977 300.164 327.977C302.288 327.977 303.992 326.273 303.992 324.148C303.992 322.048 302.263 320.319 300.164 320.319Z" />
        <path d="M282.213 320.319C280.09 320.319 278.386 322.023 278.386 324.148C278.386 326.273 280.09 327.977 282.213 327.977C284.337 327.977 286.041 326.273 286.041 324.148C286.041 322.048 284.337 320.319 282.213 320.319Z" />
        <path d="M264.285 320.319C262.161 320.319 260.457 322.023 260.457 324.148C260.457 326.273 262.161 327.977 264.285 327.977C266.408 327.977 268.112 326.273 268.112 324.148C268.112 322.048 266.384 320.319 264.285 320.319Z" />
        <path d="M246.334 320.319C244.21 320.319 242.506 322.023 242.506 324.148C242.506 326.273 244.21 327.977 246.334 327.977C248.457 327.977 250.161 326.273 250.161 324.148C250.161 322.048 248.457 320.319 246.334 320.319Z" />
        <path d="M228.384 320.319C226.261 320.319 224.557 322.023 224.557 324.148C224.557 326.273 226.261 327.977 228.384 327.977C230.508 327.977 232.212 326.273 232.212 324.148C232.236 322.048 230.508 320.319 228.384 320.319Z" />
        <path d="M210.456 320.319C208.332 320.319 206.629 322.023 206.629 324.148C206.629 326.273 208.332 327.977 210.456 327.977C212.58 327.977 214.283 326.273 214.283 324.148C214.283 322.048 212.58 320.319 210.456 320.319Z" />
        <path d="M192.507 320.319C190.383 320.319 188.679 322.023 188.679 324.148C188.679 326.273 190.383 327.977 192.507 327.977C194.63 327.977 196.334 326.273 196.334 324.148C196.359 322.048 194.63 320.319 192.507 320.319Z" />
        <path d="M174.578 320.319C172.455 320.319 170.751 322.023 170.751 324.148C170.751 326.273 172.455 327.977 174.578 327.977C176.702 327.977 178.406 326.273 178.406 324.148C178.406 322.048 176.702 320.319 174.578 320.319Z" />
        <path d="M156.626 320.319C154.502 320.319 152.798 322.023 152.798 324.148C152.798 326.273 154.502 327.977 156.626 327.977C158.749 327.977 160.453 326.273 160.453 324.148C160.478 322.048 158.749 320.319 156.626 320.319Z" />
        <path d="M138.701 320.319C136.577 320.319 134.873 322.023 134.873 324.148C134.873 326.273 136.577 327.977 138.701 327.977C140.824 327.977 142.528 326.273 142.528 324.148C142.528 322.048 140.824 320.319 138.701 320.319Z" />
        <path d="M120.748 320.319C118.625 320.319 116.921 322.023 116.921 324.148C116.921 326.273 118.625 327.977 120.748 327.977C122.872 327.977 124.575 326.273 124.575 324.148C124.575 322.048 122.872 320.319 120.748 320.319Z" />
        <path d="M102.823 320.319C100.699 320.319 98.9957 322.023 98.9957 324.148C98.9957 326.273 100.699 327.977 102.823 327.977C104.947 327.977 106.65 326.273 106.65 324.148C106.65 322.048 104.947 320.319 102.823 320.319Z" />
        <path d="M84.8704 320.319C82.7468 320.319 81.043 322.023 81.043 324.148C81.043 326.273 82.7468 327.977 84.8704 327.977C86.994 327.977 88.6978 326.273 88.6978 324.148C88.6978 322.048 86.994 320.319 84.8704 320.319Z" />
        <path d="M66.942 320.319C64.8185 320.319 63.1147 322.023 63.1147 324.148C63.1147 326.273 64.8185 327.977 66.942 327.977C69.0655 327.977 70.7693 326.273 70.7693 324.148C70.7693 322.048 69.0408 320.319 66.942 320.319Z" />
        <path d="M48.991 320.319C46.8675 320.319 45.1637 322.023 45.1637 324.148C45.1637 326.273 46.8675 327.977 48.991 327.977C51.1146 327.977 52.8184 326.273 52.8184 324.148C52.8184 322.048 51.1146 320.319 48.991 320.319Z" />
        <path d="M31.0642 320.319C28.9407 320.319 27.2369 322.023 27.2369 324.148C27.2369 326.273 28.9407 327.977 31.0642 327.977C33.1878 327.977 34.8916 326.273 34.8916 324.148C34.8916 322.048 33.1631 320.319 31.0642 320.319Z" />
        <path d="M13.1132 320.319C10.9897 320.319 9.28598 322.023 9.28598 324.148C9.28598 326.273 10.9897 327.977 13.1132 327.977C15.2367 327.977 16.9404 326.273 16.9404 324.148C16.9404 322.048 15.2367 320.319 13.1132 320.319Z" />
        <path d="M954.96 302.382C952.836 302.382 951.133 304.087 951.133 306.212C951.133 308.336 952.836 310.041 954.96 310.041C957.084 310.041 958.787 308.336 958.787 306.212C958.787 304.087 957.059 302.382 954.96 302.382Z" />
        <path d="M937.009 302.382C934.886 302.382 933.182 304.087 933.182 306.212C933.182 308.336 934.886 310.041 937.009 310.041C939.133 310.041 940.836 308.336 940.836 306.212C940.836 304.087 939.133 302.382 937.009 302.382Z" />
        <path d="M919.058 302.382C916.934 302.382 915.23 304.087 915.23 306.212C915.23 308.336 916.934 310.041 919.058 310.041C921.181 310.041 922.885 308.336 922.885 306.212C922.91 304.087 921.181 302.382 919.058 302.382Z" />
        <path d="M901.133 302.382C899.009 302.382 897.306 304.087 897.306 306.212C897.306 308.336 899.009 310.041 901.133 310.041C903.257 310.041 904.96 308.336 904.96 306.212C904.96 304.087 903.257 302.382 901.133 302.382Z" />
        <path d="M883.18 302.382C881.056 302.382 879.353 304.087 879.353 306.212C879.353 308.336 881.056 310.041 883.18 310.041C885.304 310.041 887.007 308.336 887.007 306.212C887.032 304.087 885.304 302.382 883.18 302.382Z" />
        <path d="M865.252 302.382C863.128 302.382 861.425 304.087 861.425 306.212C861.425 308.336 863.128 310.041 865.252 310.041C867.375 310.041 869.079 308.336 869.079 306.212C869.079 304.087 867.375 302.382 865.252 302.382Z" />
        <path d="M847.302 302.382C845.179 302.382 843.475 304.087 843.475 306.212C843.475 308.336 845.179 310.041 847.302 310.041C849.426 310.041 851.13 308.336 851.13 306.212C851.154 304.087 849.426 302.382 847.302 302.382Z" />
        <path d="M829.374 302.382C827.251 302.382 825.547 304.087 825.547 306.212C825.547 308.336 827.251 310.041 829.374 310.041C831.498 310.041 833.202 308.336 833.202 306.212C833.202 304.087 831.498 302.382 829.374 302.382Z" />
        <path d="M811.425 302.382C809.301 302.382 807.597 304.087 807.597 306.212C807.597 308.336 809.301 310.041 811.425 310.041C813.548 310.041 815.252 308.336 815.252 306.212C815.277 304.087 813.548 302.382 811.425 302.382Z" />
        <path d="M793.497 302.382C791.373 302.382 789.669 304.087 789.669 306.212C789.669 308.336 791.373 310.041 793.497 310.041C795.62 310.041 797.324 308.336 797.324 306.212C797.324 304.087 795.62 302.382 793.497 302.382Z" />
        <path d="M775.544 302.382C773.42 302.382 771.716 304.087 771.716 306.212C771.716 308.336 773.42 310.041 775.544 310.041C777.667 310.041 779.371 308.336 779.371 306.212C779.371 304.087 777.667 302.382 775.544 302.382Z" />
        <path d="M757.619 302.382C755.495 302.382 753.791 304.087 753.791 306.212C753.791 308.336 755.495 310.041 757.619 310.041C759.742 310.041 761.446 308.336 761.446 306.212C761.446 304.087 759.718 302.382 757.619 302.382Z" />
        <path d="M739.666 302.382C737.542 302.382 735.839 304.087 735.839 306.212C735.839 308.336 737.542 310.041 739.666 310.041C741.79 310.041 743.493 308.336 743.493 306.212C743.493 304.087 741.79 302.382 739.666 302.382Z" />
        <path d="M721.741 302.382C719.617 302.382 717.914 304.087 717.914 306.212C717.914 308.336 719.617 310.041 721.741 310.041C723.865 310.041 725.568 308.336 725.568 306.212C725.568 304.087 723.84 302.382 721.741 302.382Z" />
        <path d="M703.788 302.382C701.665 302.382 699.961 304.087 699.961 306.212C699.961 308.336 701.665 310.041 703.788 310.041C705.912 310.041 707.616 308.336 707.616 306.212C707.616 304.087 705.912 302.382 703.788 302.382Z" />
        <path d="M685.86 302.382C683.736 302.382 682.033 304.087 682.033 306.212C682.033 308.336 683.736 310.041 685.86 310.041C687.983 310.041 689.687 308.336 689.687 306.212C689.687 304.087 687.959 302.382 685.86 302.382Z" />
        <path d="M667.911 302.382C665.787 302.382 664.083 304.087 664.083 306.212C664.083 308.336 665.787 310.041 667.911 310.041C670.034 310.041 671.738 308.336 671.738 306.212C671.738 304.087 670.034 302.382 667.911 302.382Z" />
        <path d="M649.982 302.382C647.859 302.382 646.155 304.087 646.155 306.212C646.155 308.336 647.859 310.041 649.982 310.041C652.106 310.041 653.81 308.336 653.81 306.212C653.81 304.087 652.081 302.382 649.982 302.382Z" />
        <path d="M632.033 302.382C629.909 302.382 628.206 304.087 628.206 306.212C628.206 308.336 629.909 310.041 632.033 310.041C634.157 310.041 635.86 308.336 635.86 306.212C635.86 304.087 634.157 302.382 632.033 302.382Z" />
        <path d="M614.105 302.382C611.981 302.382 610.277 304.087 610.277 306.212C610.277 308.336 611.981 310.041 614.105 310.041C616.228 310.041 617.932 308.336 617.932 306.212C617.932 304.087 616.203 302.382 614.105 302.382Z" />
        <path d="M596.152 302.382C594.028 302.382 592.325 304.087 592.325 306.212C592.325 308.336 594.028 310.041 596.152 310.041C598.275 310.041 599.979 308.336 599.979 306.212C599.979 304.087 598.275 302.382 596.152 302.382Z" />
        <path d="M578.227 302.382C576.103 302.382 574.4 304.087 574.4 306.212C574.4 308.336 576.103 310.041 578.227 310.041C580.35 310.041 582.054 308.336 582.054 306.212C582.054 304.087 580.326 302.382 578.227 302.382Z" />
        <path d="M560.274 302.382C558.151 302.382 556.447 304.087 556.447 306.212C556.447 308.336 558.151 310.041 560.274 310.041C562.398 310.041 564.101 308.336 564.101 306.212C564.101 304.087 562.398 302.382 560.274 302.382Z" />
        <path d="M542.349 302.382C540.225 302.382 538.522 304.087 538.522 306.212C538.522 308.336 540.225 310.041 542.349 310.041C544.473 310.041 546.176 308.336 546.176 306.212C546.176 304.087 544.448 302.382 542.349 302.382Z" />
        <path d="M524.396 302.382C522.273 302.382 520.569 304.087 520.569 306.212C520.569 308.336 522.273 310.041 524.396 310.041C526.52 310.041 528.224 308.336 528.224 306.212C528.224 304.087 526.52 302.382 524.396 302.382Z" />
        <path d="M506.47 302.382C504.346 302.382 502.642 304.087 502.642 306.212C502.642 308.336 504.346 310.041 506.47 310.041C508.593 310.041 510.297 308.336 510.297 306.212C510.297 304.087 508.569 302.382 506.47 302.382Z" />
        <path d="M488.519 302.382C486.395 302.382 484.691 304.087 484.691 306.212C484.691 308.336 486.395 310.041 488.519 310.041C490.642 310.041 492.346 308.336 492.346 306.212C492.346 304.087 490.642 302.382 488.519 302.382Z" />
        <path d="M470.592 302.382C468.468 302.382 466.765 304.087 466.765 306.212C466.765 308.336 468.468 310.041 470.592 310.041C472.716 310.041 474.419 308.336 474.419 306.212C474.419 304.087 472.691 302.382 470.592 302.382Z" />
        <path d="M452.641 302.382C450.517 302.382 448.814 304.087 448.814 306.212C448.814 308.336 450.517 310.041 452.641 310.041C454.765 310.041 456.468 308.336 456.468 306.212C456.468 304.087 454.765 302.382 452.641 302.382Z" />
        <path d="M434.69 302.382C432.566 302.382 430.862 304.087 430.862 306.212C430.862 308.336 432.566 310.041 434.69 310.041C436.813 310.041 438.517 308.336 438.517 306.212C438.542 304.087 436.813 302.382 434.69 302.382Z" />
        <path d="M416.763 302.382C414.64 302.382 412.936 304.087 412.936 306.212C412.936 308.336 414.64 310.041 416.763 310.041C418.887 310.041 420.591 308.336 420.591 306.212C420.591 304.087 418.887 302.382 416.763 302.382Z" />
        <path d="M398.81 302.382C396.687 302.382 394.983 304.087 394.983 306.212C394.983 308.336 396.687 310.041 398.81 310.041C400.934 310.041 402.638 308.336 402.638 306.212C402.662 304.087 400.934 302.382 398.81 302.382Z" />
        <path d="M380.884 302.382C378.76 302.382 377.056 304.087 377.056 306.212C377.056 308.336 378.76 310.041 380.884 310.041C383.007 310.041 384.711 308.336 384.711 306.212C384.711 304.087 383.007 302.382 380.884 302.382Z" />
        <path d="M362.933 302.382C360.809 302.382 359.106 304.087 359.106 306.212C359.106 308.336 360.809 310.041 362.933 310.041C365.056 310.041 366.76 308.336 366.76 306.212C366.76 304.087 365.056 302.382 362.933 302.382Z" />
        <path d="M345.008 302.382C342.884 302.382 341.181 304.087 341.181 306.212C341.181 308.336 342.884 310.041 345.008 310.041C347.132 310.041 348.835 308.336 348.835 306.212C348.835 304.087 347.132 302.382 345.008 302.382Z" />
        <path d="M327.055 302.382C324.932 302.382 323.228 304.087 323.228 306.212C323.228 308.336 324.932 310.041 327.055 310.041C329.179 310.041 330.883 308.336 330.883 306.212C330.883 304.087 329.179 302.382 327.055 302.382Z" />
        <path d="M309.127 302.382C307.003 302.382 305.299 304.087 305.299 306.212C305.299 308.336 307.003 310.041 309.127 310.041C311.25 310.041 312.954 308.336 312.954 306.212C312.954 304.087 311.226 302.382 309.127 302.382Z" />
        <path d="M291.176 302.382C289.052 302.382 287.348 304.087 287.348 306.212C287.348 308.336 289.052 310.041 291.176 310.041C293.299 310.041 295.003 308.336 295.003 306.212C295.003 304.087 293.299 302.382 291.176 302.382Z" />
        <path d="M273.249 302.382C271.126 302.382 269.422 304.087 269.422 306.212C269.422 308.336 271.126 310.041 273.249 310.041C275.373 310.041 277.076 308.336 277.076 306.212C277.076 304.087 275.348 302.382 273.249 302.382Z" />
        <path d="M255.298 302.382C253.175 302.382 251.471 304.087 251.471 306.212C251.471 308.336 253.175 310.041 255.298 310.041C257.422 310.041 259.125 308.336 259.125 306.212C259.125 304.087 257.422 302.382 255.298 302.382Z" />
        <path d="M237.371 302.382C235.248 302.382 233.544 304.087 233.544 306.212C233.544 308.336 235.248 310.041 237.371 310.041C239.495 310.041 241.199 308.336 241.199 306.212C241.199 304.087 239.47 302.382 237.371 302.382Z" />
        <path d="M219.419 302.382C217.295 302.382 215.591 304.087 215.591 306.212C215.591 308.336 217.295 310.041 219.419 310.041C221.542 310.041 223.246 308.336 223.246 306.212C223.246 304.087 221.542 302.382 219.419 302.382Z" />
        <path d="M201.492 302.382C199.368 302.382 197.665 304.087 197.665 306.212C197.665 308.336 199.368 310.041 201.492 310.041C203.616 310.041 205.319 308.336 205.319 306.212C205.319 304.087 203.591 302.382 201.492 302.382Z" />
        <path d="M183.541 302.382C181.417 302.382 179.714 304.087 179.714 306.212C179.714 308.336 181.417 310.041 183.541 310.041C185.664 310.041 187.368 308.336 187.368 306.212C187.368 304.087 185.664 302.382 183.541 302.382Z" />
        <path d="M165.616 302.382C163.492 302.382 161.789 304.087 161.789 306.212C161.789 308.336 163.492 310.041 165.616 310.041C167.74 310.041 169.443 308.336 169.443 306.212C169.443 304.087 167.715 302.382 165.616 302.382Z" />
        <path d="M147.663 302.382C145.54 302.382 143.836 304.087 143.836 306.212C143.836 308.336 145.54 310.041 147.663 310.041C149.787 310.041 151.491 308.336 151.491 306.212C151.491 304.087 149.787 302.382 147.663 302.382Z" />
        <path d="M129.737 302.382C127.613 302.382 125.909 304.087 125.909 306.212C125.909 308.336 127.613 310.041 129.737 310.041C131.86 310.041 133.564 308.336 133.564 306.212C133.564 304.087 131.836 302.382 129.737 302.382Z" />
        <path d="M111.786 302.382C109.662 302.382 107.958 304.087 107.958 306.212C107.958 308.336 109.662 310.041 111.786 310.041C113.909 310.041 115.613 308.336 115.613 306.212C115.613 304.087 113.909 302.382 111.786 302.382Z" />
        <path d="M93.857 302.382C91.7334 302.382 90.0296 304.087 90.0296 306.212C90.0296 308.336 91.7334 310.041 93.857 310.041C95.9806 310.041 97.6843 308.336 97.6843 306.212C97.6843 304.087 95.9559 302.382 93.857 302.382Z" />
        <path d="M75.9077 302.382C73.7841 302.382 72.0804 304.087 72.0804 306.212C72.0804 308.336 73.7841 310.041 75.9077 310.041C78.0313 310.041 79.7351 308.336 79.7351 306.212C79.7351 304.087 78.0313 302.382 75.9077 302.382Z" />
        <path d="M57.9795 302.382C55.856 302.382 54.1522 304.087 54.1522 306.212C54.1522 308.336 55.856 310.041 57.9795 310.041C60.1031 310.041 61.8069 308.336 61.8069 306.212C61.8069 304.087 60.0784 302.382 57.9795 302.382Z" />
        <path d="M40.0283 302.382C37.9048 302.382 36.201 304.087 36.201 306.212C36.201 308.336 37.9048 310.041 40.0283 310.041C42.1519 310.041 43.8557 308.336 43.8557 306.212C43.8557 304.087 42.1519 302.382 40.0283 302.382Z" />
        <path d="M22.0773 302.382C19.9537 302.382 18.2499 304.087 18.2499 306.212C18.2499 308.336 19.9537 310.041 22.0773 310.041C24.2008 310.041 25.9045 308.336 25.9045 306.212C25.9292 304.087 24.2008 302.382 22.0773 302.382Z" />
        <path d="M4.15075 302.382C2.02721 302.382 0.323474 304.087 0.323474 306.212C0.323474 308.336 2.02721 310.041 4.15075 310.041C6.27429 310.041 7.97803 308.336 7.97803 306.212C7.97803 304.087 6.27429 302.382 4.15075 302.382Z" />
        <path d="M945.974 284.892C944.097 284.892 942.591 286.399 942.591 288.277C942.591 290.154 944.097 291.661 945.974 291.661C947.85 291.661 949.357 290.154 949.357 288.277C949.357 286.399 947.85 284.892 945.974 284.892Z" />
        <path d="M928.045 284.892C926.169 284.892 924.662 286.399 924.662 288.277C924.662 290.154 926.169 291.661 928.045 291.661C929.922 291.661 931.428 290.154 931.428 288.277C931.428 286.399 929.897 284.892 928.045 284.892Z" />
        <path d="M910.094 284.892C908.218 284.892 906.711 286.399 906.711 288.277C906.711 290.154 908.218 291.661 910.094 291.661C911.971 291.661 913.477 290.154 913.477 288.277C913.477 286.399 911.971 284.892 910.094 284.892Z" />
        <path d="M892.168 284.892C890.291 284.892 888.785 286.399 888.785 288.277C888.785 290.154 890.291 291.661 892.168 291.661C894.044 291.661 895.551 290.154 895.551 288.277C895.551 286.399 894.02 284.892 892.168 284.892Z" />
        <path d="M874.215 284.892C872.338 284.892 870.832 286.399 870.832 288.277C870.832 290.154 872.338 291.661 874.215 291.661C876.092 291.661 877.598 290.154 877.598 288.277C877.598 286.399 876.092 284.892 874.215 284.892Z" />
        <path d="M856.29 284.892C854.413 284.892 852.907 286.399 852.907 288.277C852.907 290.154 854.413 291.661 856.29 291.661C858.166 291.661 859.673 290.154 859.673 288.277C859.673 286.399 858.142 284.892 856.29 284.892Z" />
        <path d="M838.339 284.892C836.462 284.892 834.956 286.399 834.956 288.277C834.956 290.154 836.462 291.661 838.339 291.661C840.215 291.661 841.722 290.154 841.722 288.277C841.722 286.399 840.215 284.892 838.339 284.892Z" />
        <path d="M820.412 284.892C818.536 284.892 817.029 286.399 817.029 288.277C817.029 290.154 818.536 291.661 820.412 291.661C822.289 291.661 823.795 290.154 823.795 288.277C823.795 286.399 822.264 284.892 820.412 284.892Z" />
        <path d="M802.459 284.892C800.583 284.892 799.077 286.399 799.077 288.277C799.077 290.154 800.583 291.661 802.459 291.661C804.336 291.661 805.842 290.154 805.842 288.277C805.842 286.399 804.336 284.892 802.459 284.892Z" />
        <path d="M784.533 284.892C782.656 284.892 781.15 286.399 781.15 288.277C781.15 290.154 782.656 291.661 784.533 291.661C786.409 291.661 787.916 290.154 787.916 288.277C787.916 286.399 786.385 284.892 784.533 284.892Z" />
        <path d="M766.582 284.892C764.705 284.892 763.199 286.399 763.199 288.277C763.199 290.154 764.705 291.661 766.582 291.661C768.458 291.661 769.965 290.154 769.965 288.277C769.965 286.399 768.458 284.892 766.582 284.892Z" />
        <path d="M748.655 284.892C746.778 284.892 745.272 286.399 745.272 288.277C745.272 290.154 746.778 291.661 748.655 291.661C750.532 291.661 752.038 290.154 752.038 288.277C752.038 286.399 750.507 284.892 748.655 284.892Z" />
        <path d="M730.704 284.892C728.828 284.892 727.321 286.399 727.321 288.277C727.321 290.154 728.828 291.661 730.704 291.661C732.581 291.661 734.087 290.154 734.087 288.277C734.087 286.399 732.581 284.892 730.704 284.892Z" />
        <path d="M712.776 284.892C710.899 284.892 709.393 286.399 709.393 288.277C709.393 290.154 710.899 291.661 712.776 291.661C714.652 291.661 716.159 290.154 716.159 288.277C716.159 286.399 714.628 284.892 712.776 284.892Z" />
        <path d="M694.825 284.892C692.948 284.892 691.442 286.399 691.442 288.277C691.442 290.154 692.948 291.661 694.825 291.661C696.701 291.661 698.208 290.154 698.208 288.277C698.208 286.399 696.701 284.892 694.825 284.892Z" />
        <path d="M676.874 284.892C674.997 284.892 673.491 286.399 673.491 288.277C673.491 290.154 674.997 291.661 676.874 291.661C678.75 291.661 680.257 290.154 680.257 288.277C680.257 286.399 678.75 284.892 676.874 284.892Z" />
        <path d="M658.947 284.892C657.07 284.892 655.564 286.399 655.564 288.277C655.564 290.154 657.07 291.661 658.947 291.661C660.824 291.661 662.33 290.154 662.33 288.277C662.33 286.399 660.824 284.892 658.947 284.892Z" />
        <path d="M640.996 284.892C639.119 284.892 637.613 286.399 637.613 288.277C637.613 290.154 639.119 291.661 640.996 291.661C642.873 291.661 644.379 290.154 644.379 288.277C644.379 286.399 642.873 284.892 640.996 284.892Z" />
        <path d="M623.067 284.892C621.191 284.892 619.685 286.399 619.685 288.277C619.685 290.154 621.191 291.661 623.067 291.661C624.944 291.661 626.45 290.154 626.45 288.277C626.45 286.399 624.944 284.892 623.067 284.892Z" />
        <path d="M605.117 284.892C603.24 284.892 601.734 286.399 601.734 288.277C601.734 290.154 603.24 291.661 605.117 291.661C606.993 291.661 608.499 290.154 608.499 288.277C608.499 286.399 606.993 284.892 605.117 284.892Z" />
        <path d="M587.19 284.892C585.313 284.892 583.807 286.399 583.807 288.277C583.807 290.154 585.313 291.661 587.19 291.661C589.066 291.661 590.573 290.154 590.573 288.277C590.573 286.399 589.066 284.892 587.19 284.892Z" />
        <path d="M569.239 284.892C567.362 284.892 565.856 286.399 565.856 288.277C565.856 290.154 567.362 291.661 569.239 291.661C571.115 291.661 572.622 290.154 572.622 288.277C572.622 286.399 571.115 284.892 569.239 284.892Z" />
        <path d="M551.312 284.892C549.436 284.892 547.929 286.399 547.929 288.277C547.929 290.154 549.436 291.661 551.312 291.661C553.189 291.661 554.695 290.154 554.695 288.277C554.695 286.399 553.164 284.892 551.312 284.892Z" />
        <path d="M533.361 284.892C531.485 284.892 529.978 286.399 529.978 288.277C529.978 290.154 531.485 291.661 533.361 291.661C535.238 291.661 536.744 290.154 536.744 288.277C536.744 286.399 535.238 284.892 533.361 284.892Z" />
        <path d="M515.434 284.892C513.558 284.892 512.051 286.399 512.051 288.277C512.051 290.154 513.558 291.661 515.434 291.661C517.311 291.661 518.817 290.154 518.817 288.277C518.817 286.399 517.286 284.892 515.434 284.892Z" />
        <path d="M497.482 284.892C495.605 284.892 494.099 286.399 494.099 288.277C494.099 290.154 495.605 291.661 497.482 291.661C499.358 291.661 500.865 290.154 500.865 288.277C500.865 286.399 499.358 284.892 497.482 284.892Z" />
        <path d="M479.557 284.892C477.68 284.892 476.174 286.399 476.174 288.277C476.174 290.154 477.68 291.661 479.557 291.661C481.433 291.661 482.94 290.154 482.94 288.277C482.94 286.399 481.409 284.892 479.557 284.892Z" />
        <path d="M461.604 284.892C459.727 284.892 458.221 286.399 458.221 288.277C458.221 290.154 459.727 291.661 461.604 291.661C463.481 291.661 464.987 290.154 464.987 288.277C464.987 286.399 463.481 284.892 461.604 284.892Z" />
        <path d="M443.679 284.892C441.802 284.892 440.296 286.399 440.296 288.277C440.296 290.154 441.802 291.661 443.679 291.661C445.556 291.661 447.062 290.154 447.062 288.277C447.062 286.399 445.531 284.892 443.679 284.892Z" />
        <path d="M425.726 284.892C423.85 284.892 422.343 286.399 422.343 288.277C422.343 290.154 423.85 291.661 425.726 291.661C427.603 291.661 429.109 290.154 429.109 288.277C429.109 286.399 427.603 284.892 425.726 284.892Z" />
        <path d="M407.798 284.892C405.921 284.892 404.415 286.399 404.415 288.277C404.415 290.154 405.921 291.661 407.798 291.661C409.675 291.661 411.181 290.154 411.181 288.277C411.181 286.399 409.65 284.892 407.798 284.892Z" />
        <path d="M389.849 284.892C387.972 284.892 386.466 286.399 386.466 288.277C386.466 290.154 387.972 291.661 389.849 291.661C391.725 291.661 393.232 290.154 393.232 288.277C393.232 286.399 391.725 284.892 389.849 284.892Z" />
        <path d="M371.92 284.892C370.044 284.892 368.537 286.399 368.537 288.277C368.537 290.154 370.044 291.661 371.92 291.661C373.797 291.661 375.303 290.154 375.303 288.277C375.303 286.399 373.772 284.892 371.92 284.892Z" />
        <path d="M353.971 284.892C352.094 284.892 350.588 286.399 350.588 288.277C350.588 290.154 352.094 291.661 353.971 291.661C355.848 291.661 357.354 290.154 357.354 288.277C357.354 286.399 355.848 284.892 353.971 284.892Z" />
        <path d="M336.043 284.892C334.166 284.892 332.66 286.399 332.66 288.277C332.66 290.154 334.166 291.661 336.043 291.661C337.919 291.661 339.425 290.154 339.425 288.277C339.425 286.399 337.894 284.892 336.043 284.892Z" />
        <path d="M318.091 284.892C316.215 284.892 314.709 286.399 314.709 288.277C314.709 290.154 316.215 291.661 318.091 291.661C319.968 291.661 321.474 290.154 321.474 288.277C321.474 286.399 319.968 284.892 318.091 284.892Z" />
        <path d="M300.165 284.892C298.288 284.892 296.782 286.399 296.782 288.277C296.782 290.154 298.288 291.661 300.165 291.661C302.041 291.661 303.548 290.154 303.548 288.277C303.548 286.399 302.017 284.892 300.165 284.892Z" />
        <path d="M282.212 284.892C280.336 284.892 278.829 286.399 278.829 288.277C278.829 290.154 280.336 291.661 282.212 291.661C284.089 291.661 285.595 290.154 285.595 288.277C285.595 286.399 284.089 284.892 282.212 284.892Z" />
        <path d="M264.287 284.892C262.41 284.892 260.904 286.399 260.904 288.277C260.904 290.154 262.41 291.661 264.287 291.661C266.164 291.661 267.67 290.154 267.67 288.277C267.67 286.399 266.139 284.892 264.287 284.892Z" />
        <path d="M246.334 284.892C244.458 284.892 242.952 286.399 242.952 288.277C242.952 290.154 244.458 291.661 246.334 291.661C248.211 291.661 249.717 290.154 249.717 288.277C249.717 286.399 248.211 284.892 246.334 284.892Z" />
        <path d="M228.382 284.892C226.505 284.892 224.999 286.399 224.999 288.277C224.999 290.154 226.505 291.661 228.382 291.661C230.258 291.661 231.765 290.154 231.765 288.277C231.765 286.399 230.258 284.892 228.382 284.892Z" />
        <path d="M210.457 284.892C208.58 284.892 207.074 286.399 207.074 288.277C207.074 290.154 208.58 291.661 210.457 291.661C212.333 291.661 213.84 290.154 213.84 288.277C213.84 286.399 212.333 284.892 210.457 284.892Z" />
        <path d="M192.504 284.892C190.627 284.892 189.121 286.399 189.121 288.277C189.121 290.154 190.627 291.661 192.504 291.661C194.38 291.661 195.887 290.154 195.887 288.277C195.887 286.399 194.38 284.892 192.504 284.892Z" />
        <path d="M174.579 284.892C172.702 284.892 171.196 286.399 171.196 288.277C171.196 290.154 172.702 291.661 174.579 291.661C176.456 291.661 177.962 290.154 177.962 288.277C177.962 286.399 176.456 284.892 174.579 284.892Z" />
        <path d="M156.626 284.892C154.75 284.892 153.243 286.399 153.243 288.277C153.243 290.154 154.75 291.661 156.626 291.661C158.503 291.661 160.009 290.154 160.009 288.277C160.009 286.399 158.503 284.892 156.626 284.892Z" />
        <path d="M138.698 284.892C136.821 284.892 135.315 286.399 135.315 288.277C135.315 290.154 136.821 291.661 138.698 291.661C140.575 291.661 142.081 290.154 142.081 288.277C142.081 286.399 140.575 284.892 138.698 284.892Z" />
        <path d="M120.749 284.892C118.872 284.892 117.366 286.399 117.366 288.277C117.366 290.154 118.872 291.661 120.749 291.661C122.625 291.661 124.131 290.154 124.131 288.277C124.131 286.399 122.625 284.892 120.749 284.892Z" />
        <path d="M102.822 284.892C100.945 284.892 99.439 286.399 99.439 288.277C99.439 290.154 100.945 291.661 102.822 291.661C104.699 291.661 106.205 290.154 106.205 288.277C106.205 286.399 104.674 284.892 102.822 284.892Z" />
        <path d="M84.8708 284.892C82.9942 284.892 81.488 286.399 81.488 288.277C81.488 290.154 82.9942 291.661 84.8708 291.661C86.7474 291.661 88.2537 290.154 88.2537 288.277C88.2537 286.399 86.7474 284.892 84.8708 284.892Z" />
        <path d="M66.9424 284.892C65.0658 284.892 63.5595 286.399 63.5595 288.277C63.5595 290.154 65.0658 291.661 66.9424 291.661C68.819 291.661 70.3252 290.154 70.3252 288.277C70.3252 286.399 68.7943 284.892 66.9424 284.892Z" />
        <path d="M48.9931 284.892C47.1165 284.892 45.6103 286.399 45.6103 288.277C45.6103 290.154 47.1165 291.661 48.9931 291.661C50.8698 291.661 52.376 290.154 52.376 288.277C52.376 286.399 50.8698 284.892 48.9931 284.892Z" />
        <path d="M31.0646 284.892C29.1879 284.892 27.6816 286.399 27.6816 288.277C27.6816 290.154 29.1879 291.661 31.0646 291.661C32.9412 291.661 34.4475 290.154 34.4475 288.277C34.4475 286.399 32.9166 284.892 31.0646 284.892Z" />
        <path d="M13.1136 284.892C11.237 284.892 9.73071 286.399 9.73071 288.277C9.73071 290.154 11.237 291.661 13.1136 291.661C14.9903 291.661 16.4966 290.154 16.4966 288.277C16.4966 286.399 14.9903 284.892 13.1136 284.892Z" />
        <path d="M954.961 266.932C953.084 266.932 951.578 268.439 951.578 270.316C951.578 272.194 953.084 273.701 954.961 273.701C956.837 273.701 958.343 272.194 958.343 270.316C958.343 268.439 956.812 266.932 954.961 266.932Z" />
        <path d="M937.009 266.932C935.133 266.932 933.627 268.439 933.627 270.316C933.627 272.194 935.133 273.701 937.009 273.701C938.886 273.701 940.392 272.194 940.392 270.316C940.392 268.439 938.886 266.932 937.009 266.932Z" />
        <path d="M919.058 266.932C917.182 266.932 915.676 268.439 915.676 270.316C915.676 272.194 917.182 273.701 919.058 273.701C920.935 273.701 922.441 272.194 922.441 270.316C922.441 268.439 920.935 266.932 919.058 266.932Z" />
        <path d="M901.13 266.932C899.253 266.932 897.747 268.439 897.747 270.316C897.747 272.194 899.253 273.701 901.13 273.701C903.007 273.701 904.513 272.194 904.513 270.316C904.513 268.439 903.007 266.932 901.13 266.932Z" />
        <path d="M883.179 266.932C881.302 266.932 879.796 268.439 879.796 270.316C879.796 272.194 881.302 273.701 883.179 273.701C885.056 273.701 886.562 272.194 886.562 270.316C886.562 268.439 885.056 266.932 883.179 266.932Z" />
        <path d="M865.252 266.932C863.376 266.932 861.869 268.439 861.869 270.316C861.869 272.194 863.376 273.701 865.252 273.701C867.129 273.701 868.635 272.194 868.635 270.316C868.635 268.439 867.129 266.932 865.252 266.932Z" />
        <path d="M847.301 266.932C845.425 266.932 843.919 268.439 843.919 270.316C843.919 272.194 845.425 273.701 847.301 273.701C849.178 273.701 850.684 272.194 850.684 270.316C850.684 268.439 849.178 266.932 847.301 266.932Z" />
        <path d="M829.375 266.932C827.498 266.932 825.992 268.439 825.992 270.316C825.992 272.194 827.498 273.701 829.375 273.701C831.251 273.701 832.758 272.194 832.758 270.316C832.758 268.439 831.251 266.932 829.375 266.932Z" />
        <path d="M811.422 266.932C809.545 266.932 808.039 268.439 808.039 270.316C808.039 272.194 809.545 273.701 811.422 273.701C813.299 273.701 814.805 272.194 814.805 270.316C814.805 268.439 813.299 266.932 811.422 266.932Z" />
        <path d="M793.497 266.932C791.62 266.932 790.114 268.439 790.114 270.316C790.114 272.194 791.62 273.701 793.497 273.701C795.374 273.701 796.88 272.194 796.88 270.316C796.88 268.439 795.374 266.932 793.497 266.932Z" />
        <path d="M775.544 266.932C773.668 266.932 772.161 268.439 772.161 270.316C772.161 272.194 773.668 273.701 775.544 273.701C777.421 273.701 778.927 272.194 778.927 270.316C778.927 268.439 777.421 266.932 775.544 266.932Z" />
        <path d="M757.619 266.932C755.743 266.932 754.236 268.439 754.236 270.316C754.236 272.194 755.743 273.701 757.619 273.701C759.496 273.701 761.002 272.194 761.002 270.316C761.002 268.439 759.471 266.932 757.619 266.932Z" />
        <path d="M739.667 266.932C737.79 266.932 736.284 268.439 736.284 270.316C736.284 272.194 737.79 273.701 739.667 273.701C741.543 273.701 743.049 272.194 743.049 270.316C743.049 268.439 741.543 266.932 739.667 266.932Z" />
        <path d="M721.74 266.932C719.863 266.932 718.357 268.439 718.357 270.316C718.357 272.194 719.863 273.701 721.74 273.701C723.616 273.701 725.123 272.194 725.123 270.316C725.123 268.439 723.592 266.932 721.74 266.932Z" />
        <path d="M703.789 266.932C701.912 266.932 700.406 268.439 700.406 270.316C700.406 272.194 701.912 273.701 703.789 273.701C705.666 273.701 707.172 272.194 707.172 270.316C707.172 268.439 705.666 266.932 703.789 266.932Z" />
        <path d="M685.862 266.932C683.986 266.932 682.479 268.439 682.479 270.316C682.479 272.194 683.986 273.701 685.862 273.701C687.739 273.701 689.245 272.194 689.245 270.316C689.245 268.439 687.714 266.932 685.862 266.932Z" />
        <path d="M667.911 266.932C666.035 266.932 664.528 268.439 664.528 270.316C664.528 272.194 666.035 273.701 667.911 273.701C669.788 273.701 671.294 272.194 671.294 270.316C671.294 268.439 669.788 266.932 667.911 266.932Z" />
        <path d="M649.983 266.932C648.106 266.932 646.6 268.439 646.6 270.316C646.6 272.194 648.106 273.701 649.983 273.701C651.859 273.701 653.366 272.194 653.366 270.316C653.366 268.439 651.835 266.932 649.983 266.932Z" />
        <path d="M632.032 266.932C630.155 266.932 628.649 268.439 628.649 270.316C628.649 272.194 630.155 273.701 632.032 273.701C633.908 273.701 635.415 272.194 635.415 270.316C635.415 268.439 633.908 266.932 632.032 266.932Z" />
        <path d="M614.105 266.932C612.228 266.932 610.722 268.439 610.722 270.316C610.722 272.194 612.228 273.701 614.105 273.701C615.982 273.701 617.488 272.194 617.488 270.316C617.488 268.439 615.957 266.932 614.105 266.932Z" />
        <path d="M596.154 266.932C594.277 266.932 592.771 268.439 592.771 270.316C592.771 272.194 594.277 273.701 596.154 273.701C598.031 273.701 599.537 272.194 599.537 270.316C599.537 268.439 598.031 266.932 596.154 266.932Z" />
        <path d="M578.227 266.932C576.351 266.932 574.844 268.439 574.844 270.316C574.844 272.194 576.351 273.701 578.227 273.701C580.104 273.701 581.61 272.194 581.61 270.316C581.61 268.439 580.079 266.932 578.227 266.932Z" />
        <path d="M560.275 266.932C558.398 266.932 556.892 268.439 556.892 270.316C556.892 272.194 558.398 273.701 560.275 273.701C562.151 273.701 563.657 272.194 563.657 270.316C563.657 268.439 562.151 266.932 560.275 266.932Z" />
        <path d="M542.35 266.932C540.473 266.932 538.967 268.439 538.967 270.316C538.967 272.194 540.473 273.701 542.35 273.701C544.226 273.701 545.733 272.194 545.733 270.316C545.733 268.439 544.202 266.932 542.35 266.932Z" />
        <path d="M524.397 266.932C522.52 266.932 521.014 268.439 521.014 270.316C521.014 272.194 522.52 273.701 524.397 273.701C526.274 273.701 527.78 272.194 527.78 270.316C527.78 268.439 526.274 266.932 524.397 266.932Z" />
        <path d="M506.472 266.932C504.595 266.932 503.089 268.439 503.089 270.316C503.089 272.194 504.595 273.701 506.472 273.701C508.349 273.701 509.855 272.194 509.855 270.316C509.855 268.439 508.324 266.932 506.472 266.932Z" />
        <path d="M488.519 266.932C486.643 266.932 485.136 268.439 485.136 270.316C485.136 272.194 486.643 273.701 488.519 273.701C490.396 273.701 491.902 272.194 491.902 270.316C491.902 268.439 490.396 266.932 488.519 266.932Z" />
        <path d="M470.593 266.932C468.716 266.932 467.21 268.439 467.21 270.316C467.21 272.194 468.716 273.701 470.593 273.701C472.469 273.701 473.975 272.194 473.975 270.316C473.951 268.439 472.445 266.932 470.593 266.932Z" />
        <path d="M452.64 266.932C450.763 266.932 449.257 268.439 449.257 270.316C449.257 272.194 450.763 273.701 452.64 273.701C454.516 273.701 456.023 272.194 456.023 270.316C456.023 268.439 454.516 266.932 452.64 266.932Z" />
        <path d="M434.689 266.932C432.812 266.932 431.306 268.439 431.306 270.316C431.306 272.194 432.812 273.701 434.689 273.701C436.565 273.701 438.072 272.194 438.072 270.316C438.072 268.439 436.565 266.932 434.689 266.932Z" />
        <path d="M416.762 266.932C414.886 266.932 413.379 268.439 413.379 270.316C413.379 272.194 414.886 273.701 416.762 273.701C418.639 273.701 420.145 272.194 420.145 270.316C420.145 268.439 418.639 266.932 416.762 266.932Z" />
        <path d="M398.811 266.932C396.934 266.932 395.428 268.439 395.428 270.316C395.428 272.194 396.934 273.701 398.811 273.701C400.688 273.701 402.194 272.194 402.194 270.316C402.194 268.439 400.688 266.932 398.811 266.932Z" />
        <path d="M380.883 266.932C379.006 266.932 377.5 268.439 377.5 270.316C377.5 272.194 379.006 273.701 380.883 273.701C382.759 273.701 384.266 272.194 384.266 270.316C384.266 268.439 382.759 266.932 380.883 266.932Z" />
        <path d="M362.933 266.932C361.057 266.932 359.551 268.439 359.551 270.316C359.551 272.194 361.057 273.701 362.933 273.701C364.81 273.701 366.316 272.194 366.316 270.316C366.316 268.439 364.81 266.932 362.933 266.932Z" />
        <path d="M345.005 266.932C343.128 266.932 341.622 268.439 341.622 270.316C341.622 272.194 343.128 273.701 345.005 273.701C346.882 273.701 348.388 272.194 348.388 270.316C348.388 268.439 346.882 266.932 345.005 266.932Z" />
        <path d="M327.056 266.932C325.179 266.932 323.673 268.439 323.673 270.316C323.673 272.194 325.179 273.701 327.056 273.701C328.932 273.701 330.439 272.194 330.439 270.316C330.439 268.439 328.932 266.932 327.056 266.932Z" />
        <path d="M309.127 266.932C307.251 266.932 305.744 268.439 305.744 270.316C305.744 272.194 307.251 273.701 309.127 273.701C311.004 273.701 312.51 272.194 312.51 270.316C312.51 268.439 310.979 266.932 309.127 266.932Z" />
        <path d="M291.178 266.932C289.301 266.932 287.795 268.439 287.795 270.316C287.795 272.194 289.301 273.701 291.178 273.701C293.055 273.701 294.561 272.194 294.561 270.316C294.561 268.439 293.055 266.932 291.178 266.932Z" />
        <path d="M273.25 266.932C271.373 266.932 269.867 268.439 269.867 270.316C269.867 272.194 271.373 273.701 273.25 273.701C275.126 273.701 276.633 272.194 276.633 270.316C276.633 268.439 275.102 266.932 273.25 266.932Z" />
        <path d="M255.297 266.932C253.42 266.932 251.914 268.439 251.914 270.316C251.914 272.194 253.42 273.701 255.297 273.701C257.173 273.701 258.68 272.194 258.68 270.316C258.68 268.439 257.173 266.932 255.297 266.932Z" />
        <path d="M237.372 266.932C235.495 266.932 233.989 268.439 233.989 270.316C233.989 272.194 235.495 273.701 237.372 273.701C239.249 273.701 240.755 272.194 240.755 270.316C240.755 268.439 239.224 266.932 237.372 266.932Z" />
        <path d="M219.421 266.932C217.544 266.932 216.038 268.439 216.038 270.316C216.038 272.194 217.544 273.701 219.421 273.701C221.297 273.701 222.804 272.194 222.804 270.316C222.804 268.439 221.297 266.932 219.421 266.932Z" />
        <path d="M201.494 266.932C199.617 266.932 198.111 268.439 198.111 270.316C198.111 272.194 199.617 273.701 201.494 273.701C203.371 273.701 204.877 272.194 204.877 270.316C204.877 268.439 203.346 266.932 201.494 266.932Z" />
        <path d="M183.541 266.932C181.665 266.932 180.159 268.439 180.159 270.316C180.159 272.194 181.665 273.701 183.541 273.701C185.418 273.701 186.924 272.194 186.924 270.316C186.924 268.439 185.418 266.932 183.541 266.932Z" />
        <path d="M165.615 266.932C163.738 266.932 162.232 268.439 162.232 270.316C162.232 272.194 163.738 273.701 165.615 273.701C167.491 273.701 168.998 272.194 168.998 270.316C168.998 268.439 167.467 266.932 165.615 266.932Z" />
        <path d="M147.664 266.932C145.787 266.932 144.281 268.439 144.281 270.316C144.281 272.194 145.787 273.701 147.664 273.701C149.54 273.701 151.047 272.194 151.047 270.316C151.047 268.439 149.54 266.932 147.664 266.932Z" />
        <path d="M129.737 266.932C127.86 266.932 126.354 268.439 126.354 270.316C126.354 272.194 127.86 273.701 129.737 273.701C131.614 273.701 133.12 272.194 133.12 270.316C133.12 268.439 131.589 266.932 129.737 266.932Z" />
        <path d="M111.786 266.932C109.909 266.932 108.403 268.439 108.403 270.316C108.403 272.194 109.909 273.701 111.786 273.701C113.663 273.701 115.169 272.194 115.169 270.316C115.169 268.439 113.663 266.932 111.786 266.932Z" />
        <path d="M93.8576 266.932C91.9809 266.932 90.4746 268.439 90.4746 270.316C90.4746 272.194 91.9809 273.701 93.8576 273.701C95.7342 273.701 97.2405 272.194 97.2405 270.316C97.2405 268.439 95.7095 266.932 93.8576 266.932Z" />
        <path d="M75.905 266.932C74.0284 266.932 72.5222 268.439 72.5222 270.316C72.5222 272.194 74.0284 273.701 75.905 273.701C77.7816 273.701 79.2878 272.194 79.2878 270.316C79.2878 268.439 77.7816 266.932 75.905 266.932Z" />
        <path d="M57.9798 266.932C56.1032 266.932 54.5969 268.439 54.5969 270.316C54.5969 272.194 56.1032 273.701 57.9798 273.701C59.8565 273.701 61.3628 272.194 61.3628 270.316C61.3628 268.439 59.8318 266.932 57.9798 266.932Z" />
        <path d="M40.029 266.932C38.1524 266.932 36.6462 268.439 36.6462 270.316C36.6462 272.194 38.1524 273.701 40.029 273.701C41.9056 273.701 43.4119 272.194 43.4119 270.316C43.4119 268.439 41.9057 266.932 40.029 266.932Z" />
        <path d="M22.0781 266.932C20.2014 266.932 18.6952 268.439 18.6952 270.316C18.6952 272.194 20.2014 273.701 22.0781 273.701C23.9547 273.701 25.4609 272.194 25.4609 270.316C25.4609 268.439 23.9547 266.932 22.0781 266.932Z" />
        <path d="M4.14964 266.932C2.27303 266.932 0.766816 268.439 0.766816 270.316C0.766816 272.194 2.27303 273.701 4.14964 273.701C6.02625 273.701 7.53247 272.194 7.53247 270.316C7.53247 268.439 6.02626 266.932 4.14964 266.932Z" />
        <path d="M945.973 249.391C944.319 249.391 942.986 250.725 942.986 252.38C942.986 254.035 944.319 255.369 945.973 255.369C947.628 255.369 948.961 254.035 948.961 252.38C948.961 250.725 947.628 249.391 945.973 249.391Z" />
        <path d="M928.045 249.391C926.391 249.391 925.057 250.725 925.057 252.38C925.057 254.035 926.391 255.369 928.045 255.369C929.699 255.369 931.033 254.035 931.033 252.38C931.033 250.725 929.699 249.391 928.045 249.391Z" />
        <path d="M910.094 249.391C908.44 249.391 907.106 250.725 907.106 252.38C907.106 254.035 908.44 255.369 910.094 255.369C911.748 255.369 913.082 254.035 913.082 252.38C913.082 250.725 911.748 249.391 910.094 249.391Z" />
        <path d="M892.167 249.391C890.513 249.391 889.18 250.725 889.18 252.38C889.18 254.035 890.513 255.369 892.167 255.369C893.822 255.369 895.155 254.035 895.155 252.38C895.155 250.725 893.797 249.391 892.167 249.391Z" />
        <path d="M874.216 249.391C872.562 249.391 871.228 250.725 871.228 252.38C871.228 254.035 872.562 255.369 874.216 255.369C875.871 255.369 877.204 254.035 877.204 252.38C877.204 250.725 875.871 249.391 874.216 249.391Z" />
        <path d="M856.29 249.391C854.635 249.391 853.302 250.725 853.302 252.38C853.302 254.035 854.635 255.369 856.29 255.369C857.944 255.369 859.277 254.035 859.277 252.38C859.277 250.725 857.919 249.391 856.29 249.391Z" />
        <path d="M838.337 249.391C836.682 249.391 835.349 250.725 835.349 252.38C835.349 254.035 836.682 255.369 838.337 255.369C839.991 255.369 841.325 254.035 841.325 252.38C841.325 250.725 839.991 249.391 838.337 249.391Z" />
        <path d="M820.412 249.391C818.757 249.391 817.424 250.725 817.424 252.38C817.424 254.035 818.757 255.369 820.412 255.369C822.066 255.369 823.4 254.035 823.4 252.38C823.4 250.725 822.042 249.391 820.412 249.391Z" />
        <path d="M802.459 249.391C800.805 249.391 799.471 250.725 799.471 252.38C799.471 254.035 800.805 255.369 802.459 255.369C804.114 255.369 805.447 254.035 805.447 252.38C805.447 250.725 804.114 249.391 802.459 249.391Z" />
        <path d="M784.532 249.391C782.878 249.391 781.545 250.725 781.545 252.38C781.545 254.035 782.878 255.369 784.532 255.369C786.187 255.369 787.52 254.035 787.52 252.38C787.52 250.725 786.162 249.391 784.532 249.391Z" />
        <path d="M766.581 249.391C764.927 249.391 763.594 250.725 763.594 252.38C763.594 254.035 764.927 255.369 766.581 255.369C768.236 255.369 769.569 254.035 769.569 252.38C769.569 250.725 768.236 249.391 766.581 249.391Z" />
        <path d="M748.656 249.391C747.002 249.391 745.669 250.725 745.669 252.38C745.669 254.035 747.002 255.369 748.656 255.369C750.311 255.369 751.644 254.035 751.644 252.38C751.644 250.725 750.286 249.391 748.656 249.391Z" />
        <path d="M730.704 249.391C729.049 249.391 727.716 250.725 727.716 252.38C727.716 254.035 729.049 255.369 730.704 255.369C732.358 255.369 733.692 254.035 733.692 252.38C733.692 250.725 732.358 249.391 730.704 249.391Z" />
        <path d="M712.775 249.391C711.121 249.391 709.787 250.725 709.787 252.38C709.787 254.035 711.121 255.369 712.775 255.369C714.43 255.369 715.763 254.035 715.763 252.38C715.763 250.725 714.405 249.391 712.775 249.391Z" />
        <path d="M694.826 249.391C693.172 249.391 691.838 250.725 691.838 252.38C691.838 254.035 693.172 255.369 694.826 255.369C696.48 255.369 697.814 254.035 697.814 252.38C697.814 250.725 696.48 249.391 694.826 249.391Z" />
        <path d="M676.873 249.391C675.219 249.391 673.886 250.725 673.886 252.38C673.886 254.035 675.219 255.369 676.873 255.369C678.528 255.369 679.861 254.035 679.861 252.38C679.861 250.725 678.528 249.391 676.873 249.391Z" />
        <path d="M658.948 249.391C657.294 249.391 655.961 250.725 655.961 252.38C655.961 254.035 657.294 255.369 658.948 255.369C660.603 255.369 661.936 254.035 661.936 252.38C661.936 250.725 660.603 249.391 658.948 249.391Z" />
        <path d="M640.996 249.391C639.341 249.391 638.008 250.725 638.008 252.38C638.008 254.035 639.341 255.369 640.996 255.369C642.65 255.369 643.983 254.035 643.983 252.38C643.983 250.725 642.65 249.391 640.996 249.391Z" />
        <path d="M623.067 249.391C621.413 249.391 620.079 250.725 620.079 252.38C620.079 254.035 621.413 255.369 623.067 255.369C624.722 255.369 626.055 254.035 626.055 252.38C626.055 250.725 624.722 249.391 623.067 249.391Z" />
        <path d="M605.116 249.391C603.462 249.391 602.128 250.725 602.128 252.38C602.128 254.035 603.462 255.369 605.116 255.369C606.771 255.369 608.104 254.035 608.104 252.38C608.104 250.725 606.771 249.391 605.116 249.391Z" />
        <path d="M587.19 249.391C585.535 249.391 584.202 250.725 584.202 252.38C584.202 254.035 585.535 255.369 587.19 255.369C588.844 255.369 590.177 254.035 590.177 252.38C590.177 250.725 588.844 249.391 587.19 249.391Z" />
        <path d="M569.239 249.391C567.584 249.391 566.251 250.725 566.251 252.38C566.251 254.035 567.584 255.369 569.239 255.369C570.893 255.369 572.226 254.035 572.226 252.38C572.226 250.725 570.893 249.391 569.239 249.391Z" />
        <path d="M551.312 249.391C549.657 249.391 548.324 250.725 548.324 252.38C548.324 254.035 549.657 255.369 551.312 255.369C552.966 255.369 554.3 254.035 554.3 252.38C554.3 250.725 552.966 249.391 551.312 249.391Z" />
        <path d="M533.359 249.391C531.705 249.391 530.371 250.725 530.371 252.38C530.371 254.035 531.705 255.369 533.359 255.369C535.014 255.369 536.347 254.035 536.347 252.38C536.347 250.725 535.014 249.391 533.359 249.391Z" />
        <path d="M515.434 249.391C513.78 249.391 512.446 250.725 512.446 252.38C512.446 254.035 513.78 255.369 515.434 255.369C517.088 255.369 518.422 254.035 518.422 252.38C518.422 250.725 517.088 249.391 515.434 249.391Z" />
        <path d="M497.481 249.391C495.827 249.391 494.494 250.725 494.494 252.38C494.494 254.035 495.827 255.369 497.481 255.369C499.136 255.369 500.469 254.035 500.469 252.38C500.469 250.725 499.136 249.391 497.481 249.391Z" />
        <path d="M479.555 249.391C477.9 249.391 476.567 250.725 476.567 252.38C476.567 254.035 477.9 255.369 479.555 255.369C481.209 255.369 482.542 254.035 482.542 252.38C482.542 250.725 481.209 249.391 479.555 249.391Z" />
        <path d="M461.604 249.391C459.949 249.391 458.616 250.725 458.616 252.38C458.616 254.035 459.949 255.369 461.604 255.369C463.258 255.369 464.591 254.035 464.591 252.38C464.591 250.725 463.258 249.391 461.604 249.391Z" />
        <path d="M443.675 249.391C442.021 249.391 440.687 250.725 440.687 252.38C440.687 254.035 442.021 255.369 443.675 255.369C445.33 255.369 446.663 254.035 446.663 252.38C446.663 250.725 445.305 249.391 443.675 249.391Z" />
        <path d="M425.726 249.391C424.072 249.391 422.738 250.725 422.738 252.38C422.738 254.035 424.072 255.369 425.726 255.369C427.38 255.369 428.714 254.035 428.714 252.38C428.714 250.725 427.38 249.391 425.726 249.391Z" />
        <path d="M407.799 249.391C406.145 249.391 404.811 250.725 404.811 252.38C404.811 254.035 406.145 255.369 407.799 255.369C409.454 255.369 410.787 254.035 410.787 252.38C410.787 250.725 409.429 249.391 407.799 249.391Z" />
        <path d="M389.848 249.391C388.194 249.391 386.861 250.725 386.861 252.38C386.861 254.035 388.194 255.369 389.848 255.369C391.503 255.369 392.836 254.035 392.836 252.38C392.836 250.725 391.503 249.391 389.848 249.391Z" />
        <path d="M371.92 249.391C370.265 249.391 368.932 250.725 368.932 252.38C368.932 254.035 370.265 255.369 371.92 255.369C373.574 255.369 374.908 254.035 374.908 252.38C374.908 250.725 373.549 249.391 371.92 249.391Z" />
        <path d="M353.971 249.391C352.316 249.391 350.983 250.725 350.983 252.38C350.983 254.035 352.316 255.369 353.971 255.369C355.625 255.369 356.958 254.035 356.958 252.38C356.958 250.725 355.625 249.391 353.971 249.391Z" />
        <path d="M336.042 249.391C334.388 249.391 333.054 250.725 333.054 252.38C333.054 254.035 334.388 255.369 336.042 255.369C337.697 255.369 339.03 254.035 339.03 252.38C339.03 250.725 337.672 249.391 336.042 249.391Z" />
        <path d="M318.091 249.391C316.437 249.391 315.103 250.725 315.103 252.38C315.103 254.035 316.437 255.369 318.091 255.369C319.745 255.369 321.079 254.035 321.079 252.38C321.079 250.725 319.745 249.391 318.091 249.391Z" />
        <path d="M300.165 249.391C298.51 249.391 297.177 250.725 297.177 252.38C297.177 254.035 298.51 255.369 300.165 255.369C301.819 255.369 303.153 254.035 303.153 252.38C303.153 250.725 301.795 249.391 300.165 249.391Z" />
        <path d="M282.213 249.391C280.559 249.391 279.226 250.725 279.226 252.38C279.226 254.035 280.559 255.369 282.213 255.369C283.868 255.369 285.201 254.035 285.201 252.38C285.201 250.725 283.868 249.391 282.213 249.391Z" />
        <path d="M264.287 249.391C262.632 249.391 261.299 250.725 261.299 252.38C261.299 254.035 262.632 255.369 264.287 255.369C265.941 255.369 267.275 254.035 267.275 252.38C267.275 250.725 265.917 249.391 264.287 249.391Z" />
        <path d="M246.336 249.391C244.682 249.391 243.348 250.725 243.348 252.38C243.348 254.035 244.682 255.369 246.336 255.369C247.99 255.369 249.324 254.035 249.324 252.38C249.324 250.725 247.99 249.391 246.336 249.391Z" />
        <path d="M228.381 249.391C226.727 249.391 225.394 250.725 225.394 252.38C225.394 254.035 226.727 255.369 228.381 255.369C230.036 255.369 231.369 254.035 231.369 252.38C231.369 250.725 230.036 249.391 228.381 249.391Z" />
        <path d="M210.456 249.391C208.802 249.391 207.468 250.725 207.468 252.38C207.468 254.035 208.802 255.369 210.456 255.369C212.111 255.369 213.444 254.035 213.444 252.38C213.444 250.725 212.111 249.391 210.456 249.391Z" />
        <path d="M192.505 249.391C190.851 249.391 189.518 250.725 189.518 252.38C189.518 254.035 190.851 255.369 192.505 255.369C194.16 255.369 195.493 254.035 195.493 252.38C195.493 250.725 194.16 249.391 192.505 249.391Z" />
        <path d="M174.577 249.391C172.922 249.391 171.589 250.725 171.589 252.38C171.589 254.035 172.922 255.369 174.577 255.369C176.231 255.369 177.565 254.035 177.565 252.38C177.565 250.725 176.231 249.391 174.577 249.391Z" />
        <path d="M156.628 249.391C154.973 249.391 153.64 250.725 153.64 252.38C153.64 254.035 154.973 255.369 156.628 255.369C158.282 255.369 159.615 254.035 159.615 252.38C159.615 250.725 158.282 249.391 156.628 249.391Z" />
        <path d="M138.699 249.391C137.045 249.391 135.711 250.725 135.711 252.38C135.711 254.035 137.045 255.369 138.699 255.369C140.354 255.369 141.687 254.035 141.687 252.38C141.687 250.725 140.354 249.391 138.699 249.391Z" />
        <path d="M120.748 249.391C119.094 249.391 117.761 250.725 117.761 252.38C117.761 254.035 119.094 255.369 120.748 255.369C122.403 255.369 123.736 254.035 123.736 252.38C123.736 250.725 122.403 249.391 120.748 249.391Z" />
        <path d="M102.821 249.391C101.167 249.391 99.8337 250.725 99.8337 252.38C99.8337 254.035 101.167 255.369 102.821 255.369C104.476 255.369 105.809 254.035 105.809 252.38C105.809 250.725 104.476 249.391 102.821 249.391Z" />
        <path d="M84.8707 249.391C83.2163 249.391 81.883 250.725 81.883 252.38C81.883 254.035 83.2163 255.369 84.8707 255.369C86.525 255.369 87.8584 254.035 87.8584 252.38C87.8584 250.725 86.525 249.391 84.8707 249.391Z" />
        <path d="M66.944 249.391C65.2896 249.391 63.9562 250.725 63.9562 252.38C63.9562 254.035 65.2896 255.369 66.944 255.369C68.5984 255.369 69.9319 254.035 69.9319 252.38C69.9319 250.725 68.5984 249.391 66.944 249.391Z" />
        <path d="M48.9915 249.391C47.3371 249.391 46.0038 250.725 46.0038 252.38C46.0038 254.035 47.3371 255.369 48.9915 255.369C50.6459 255.369 51.9792 254.035 51.9792 252.38C51.9792 250.725 50.6459 249.391 48.9915 249.391Z" />
        <path d="M31.0644 249.391C29.4099 249.391 28.0765 250.725 28.0765 252.38C28.0765 254.035 29.4099 255.369 31.0644 255.369C32.7188 255.369 34.0522 254.035 34.0522 252.38C34.0522 250.725 32.7188 249.391 31.0644 249.391Z" />
        <path d="M13.1133 249.391C11.4589 249.391 10.1255 250.725 10.1255 252.38C10.1255 254.035 11.4589 255.369 13.1133 255.369C14.7677 255.369 16.1011 254.035 16.1011 252.38C16.1011 250.725 14.7677 249.391 13.1133 249.391Z" />
        <path d="M954.961 231.677C953.455 231.677 952.22 232.912 952.22 234.419C952.22 235.926 953.455 237.161 954.961 237.161C956.467 237.161 957.702 235.926 957.702 234.419C957.677 232.912 956.467 231.677 954.961 231.677Z" />
        <path d="M937.008 231.677C935.502 231.677 934.267 232.912 934.267 234.419C934.267 235.926 935.502 237.161 937.008 237.161C938.514 237.161 939.749 235.926 939.749 234.419C939.749 232.912 938.514 231.677 937.008 231.677Z" />
        <path d="M919.059 231.677C917.553 231.677 916.318 232.912 916.318 234.419C916.318 235.926 917.553 237.161 919.059 237.161C920.565 237.161 921.8 235.926 921.8 234.419C921.8 232.912 920.59 231.677 919.059 231.677Z" />
        <path d="M901.13 231.677C899.624 231.677 898.389 232.912 898.389 234.419C898.389 235.926 899.624 237.161 901.13 237.161C902.637 237.161 903.871 235.926 903.871 234.419C903.871 232.912 902.637 231.677 901.13 231.677Z" />
        <path d="M883.179 231.677C881.673 231.677 880.439 232.912 880.439 234.419C880.439 235.926 881.673 237.161 883.179 237.161C884.686 237.161 885.92 235.926 885.92 234.419C885.92 232.912 884.71 231.677 883.179 231.677Z" />
        <path d="M865.253 231.677C863.747 231.677 862.512 232.912 862.512 234.419C862.512 235.926 863.747 237.161 865.253 237.161C866.759 237.161 867.994 235.926 867.994 234.419C867.994 232.912 866.759 231.677 865.253 231.677Z" />
        <path d="M847.3 231.677C845.794 231.677 844.559 232.912 844.559 234.419C844.559 235.926 845.794 237.161 847.3 237.161C848.806 237.161 850.041 235.926 850.041 234.419C850.041 232.912 848.831 231.677 847.3 231.677Z" />
        <path d="M829.375 231.677C827.869 231.677 826.634 232.912 826.634 234.419C826.634 235.926 827.869 237.161 829.375 237.161C830.881 237.161 832.116 235.926 832.116 234.419C832.116 232.912 830.881 231.677 829.375 231.677Z" />
        <path d="M811.424 231.677C809.918 231.677 808.683 232.912 808.683 234.419C808.683 235.926 809.918 237.161 811.424 237.161C812.93 237.161 814.165 235.926 814.165 234.419C814.165 232.912 812.955 231.677 811.424 231.677Z" />
        <path d="M793.497 231.677C791.991 231.677 790.756 232.912 790.756 234.419C790.756 235.926 791.991 237.161 793.497 237.161C795.004 237.161 796.238 235.926 796.238 234.419C796.238 232.912 795.004 231.677 793.497 231.677Z" />
        <path d="M775.545 231.677C774.038 231.677 772.804 232.912 772.804 234.419C772.804 235.926 774.038 237.161 775.545 237.161C777.051 237.161 778.286 235.926 778.286 234.419C778.286 232.912 777.076 231.677 775.545 231.677Z" />
        <path d="M757.618 231.677C756.112 231.677 754.877 232.912 754.877 234.419C754.877 235.926 756.112 237.161 757.618 237.161C759.124 237.161 760.359 235.926 760.359 234.419C760.359 232.912 759.124 231.677 757.618 231.677Z" />
        <path d="M739.667 231.677C738.161 231.677 736.926 232.912 736.926 234.419C736.926 235.926 738.161 237.161 739.667 237.161C741.173 237.161 742.408 235.926 742.408 234.419C742.408 232.912 741.173 231.677 739.667 231.677Z" />
        <path d="M721.742 231.677C720.236 231.677 719.001 232.912 719.001 234.419C719.001 235.926 720.236 237.161 721.742 237.161C723.248 237.161 724.483 235.926 724.483 234.419C724.483 232.912 723.248 231.677 721.742 231.677Z" />
        <path d="M703.789 231.677C702.283 231.677 701.048 232.912 701.048 234.419C701.048 235.926 702.283 237.161 703.789 237.161C705.296 237.161 706.53 235.926 706.53 234.419C706.53 232.912 705.296 231.677 703.789 231.677Z" />
        <path d="M685.861 231.677C684.355 231.677 683.12 232.912 683.12 234.419C683.12 235.926 684.355 237.161 685.861 237.161C687.367 237.161 688.602 235.926 688.602 234.419C688.602 232.912 687.367 231.677 685.861 231.677Z" />
        <path d="M667.91 231.677C666.404 231.677 665.169 232.912 665.169 234.419C665.169 235.926 666.404 237.161 667.91 237.161C669.416 237.161 670.651 235.926 670.651 234.419C670.651 232.912 669.416 231.677 667.91 231.677Z" />
        <path d="M649.983 231.677C648.477 231.677 647.242 232.912 647.242 234.419C647.242 235.926 648.477 237.161 649.983 237.161C651.489 237.161 652.724 235.926 652.724 234.419C652.699 232.912 651.489 231.677 649.983 231.677Z" />
        <path d="M632.032 231.677C630.526 231.677 629.291 232.912 629.291 234.419C629.291 235.926 630.526 237.161 632.032 237.161C633.538 237.161 634.773 235.926 634.773 234.419C634.773 232.912 633.538 231.677 632.032 231.677Z" />
        <path d="M614.105 231.677C612.599 231.677 611.365 232.912 611.365 234.419C611.365 235.926 612.599 237.161 614.105 237.161C615.612 237.161 616.846 235.926 616.846 234.419C616.822 232.912 615.612 231.677 614.105 231.677Z" />
        <path d="M596.153 231.677C594.646 231.677 593.412 232.912 593.412 234.419C593.412 235.926 594.646 237.161 596.153 237.161C597.659 237.161 598.893 235.926 598.893 234.419C598.893 232.912 597.659 231.677 596.153 231.677Z" />
        <path d="M578.226 231.677C576.72 231.677 575.485 232.912 575.485 234.419C575.485 235.926 576.72 237.161 578.226 237.161C579.732 237.161 580.967 235.926 580.967 234.419C580.942 232.912 579.732 231.677 578.226 231.677Z" />
        <path d="M560.275 231.677C558.769 231.677 557.534 232.912 557.534 234.419C557.534 235.926 558.769 237.161 560.275 237.161C561.781 237.161 563.016 235.926 563.016 234.419C563.016 232.912 561.781 231.677 560.275 231.677Z" />
        <path d="M542.348 231.677C540.842 231.677 539.607 232.912 539.607 234.419C539.607 235.926 540.842 237.161 542.348 237.161C543.854 237.161 545.089 235.926 545.089 234.419C545.064 232.912 543.854 231.677 542.348 231.677Z" />
        <path d="M524.397 231.677C522.891 231.677 521.656 232.912 521.656 234.419C521.656 235.926 522.891 237.161 524.397 237.161C525.904 237.161 527.138 235.926 527.138 234.419C527.138 232.912 525.904 231.677 524.397 231.677Z" />
        <path d="M506.469 231.677C504.963 231.677 503.728 232.912 503.728 234.419C503.728 235.926 504.963 237.161 506.469 237.161C507.975 237.161 509.21 235.926 509.21 234.419C509.185 232.912 507.975 231.677 506.469 231.677Z" />
        <path d="M488.52 231.677C487.013 231.677 485.779 232.912 485.779 234.419C485.779 235.926 487.013 237.161 488.52 237.161C490.026 237.161 491.26 235.926 491.26 234.419C491.26 232.912 490.026 231.677 488.52 231.677Z" />
        <path d="M470.591 231.677C469.085 231.677 467.85 232.912 467.85 234.419C467.85 235.926 469.085 237.161 470.591 237.161C472.097 237.161 473.332 235.926 473.332 234.419C473.307 232.912 472.097 231.677 470.591 231.677Z" />
        <path d="M452.642 231.677C451.136 231.677 449.901 232.912 449.901 234.419C449.901 235.926 451.136 237.161 452.642 237.161C454.148 237.161 455.383 235.926 455.383 234.419C455.383 232.912 454.148 231.677 452.642 231.677Z" />
        <path d="M434.689 231.677C433.183 231.677 431.948 232.912 431.948 234.419C431.948 235.926 433.183 237.161 434.689 237.161C436.195 237.161 437.43 235.926 437.43 234.419C437.43 232.912 436.22 231.677 434.689 231.677Z" />
        <path d="M416.764 231.677C415.258 231.677 414.023 232.912 414.023 234.419C414.023 235.926 415.258 237.161 416.764 237.161C418.27 237.161 419.505 235.926 419.505 234.419C419.505 232.912 418.27 231.677 416.764 231.677Z" />
        <path d="M398.812 231.677C397.305 231.677 396.071 232.912 396.071 234.419C396.071 235.926 397.305 237.161 398.812 237.161C400.318 237.161 401.552 235.926 401.552 234.419C401.552 232.912 400.342 231.677 398.812 231.677Z" />
        <path d="M380.883 231.677C379.377 231.677 378.142 232.912 378.142 234.419C378.142 235.926 379.377 237.161 380.883 237.161C382.389 237.161 383.624 235.926 383.624 234.419C383.624 232.912 382.389 231.677 380.883 231.677Z" />
        <path d="M362.934 231.677C361.428 231.677 360.193 232.912 360.193 234.419C360.193 235.926 361.428 237.161 362.934 237.161C364.44 237.161 365.675 235.926 365.675 234.419C365.675 232.912 364.465 231.677 362.934 231.677Z" />
        <path d="M345.007 231.677C343.501 231.677 342.266 232.912 342.266 234.419C342.266 235.926 343.501 237.161 345.007 237.161C346.513 237.161 347.748 235.926 347.748 234.419C347.748 232.912 346.513 231.677 345.007 231.677Z" />
        <path d="M327.056 231.677C325.55 231.677 324.315 232.912 324.315 234.419C324.315 235.926 325.55 237.161 327.056 237.161C328.562 237.161 329.797 235.926 329.797 234.419C329.797 232.912 328.587 231.677 327.056 231.677Z" />
        <path d="M309.128 231.677C307.621 231.677 306.387 232.912 306.387 234.419C306.387 235.926 307.621 237.161 309.128 237.161C310.634 237.161 311.868 235.926 311.868 234.419C311.868 232.912 310.634 231.677 309.128 231.677Z" />
        <path d="M291.177 231.677C289.67 231.677 288.436 232.912 288.436 234.419C288.436 235.926 289.67 237.161 291.177 237.161C292.683 237.161 293.917 235.926 293.917 234.419C293.917 232.912 292.683 231.677 291.177 231.677Z" />
        <path d="M273.25 231.677C271.744 231.677 270.509 232.912 270.509 234.419C270.509 235.926 271.744 237.161 273.25 237.161C274.756 237.161 275.991 235.926 275.991 234.419C275.991 232.912 274.756 231.677 273.25 231.677Z" />
        <path d="M255.297 231.677C253.791 231.677 252.556 232.912 252.556 234.419C252.556 235.926 253.791 237.161 255.297 237.161C256.803 237.161 258.038 235.926 258.038 234.419C258.038 232.912 256.803 231.677 255.297 231.677Z" />
        <path d="M237.372 231.677C235.866 231.677 234.631 232.912 234.631 234.419C234.631 235.926 235.866 237.161 237.372 237.161C238.878 237.161 240.113 235.926 240.113 234.419C240.113 232.912 238.878 231.677 237.372 231.677Z" />
        <path d="M219.419 231.677C217.913 231.677 216.679 232.912 216.679 234.419C216.679 235.926 217.913 237.161 219.419 237.161C220.926 237.161 222.16 235.926 222.16 234.419C222.16 232.912 220.926 231.677 219.419 231.677Z" />
        <path d="M201.493 231.677C199.987 231.677 198.752 232.912 198.752 234.419C198.752 235.926 199.987 237.161 201.493 237.161C202.999 237.161 204.234 235.926 204.234 234.419C204.209 232.912 202.999 231.677 201.493 231.677Z" />
        <path d="M183.542 231.677C182.036 231.677 180.801 232.912 180.801 234.419C180.801 235.926 182.036 237.161 183.542 237.161C185.048 237.161 186.283 235.926 186.283 234.419C186.283 232.912 185.048 231.677 183.542 231.677Z" />
        <path d="M165.615 231.677C164.109 231.677 162.874 232.912 162.874 234.419C162.874 235.926 164.109 237.161 165.615 237.161C167.121 237.161 168.356 235.926 168.356 234.419C168.331 232.912 167.121 231.677 165.615 231.677Z" />
        <path d="M147.664 231.677C146.158 231.677 144.923 232.912 144.923 234.419C144.923 235.926 146.158 237.161 147.664 237.161C149.17 237.161 150.405 235.926 150.405 234.419C150.405 232.912 149.17 231.677 147.664 231.677Z" />
        <path d="M129.736 231.677C128.23 231.677 126.995 232.912 126.995 234.419C126.995 235.926 128.23 237.161 129.736 237.161C131.242 237.161 132.477 235.926 132.477 234.419C132.452 232.912 131.242 231.677 129.736 231.677Z" />
        <path d="M111.783 231.677C110.277 231.677 109.042 232.912 109.042 234.419C109.042 235.926 110.277 237.161 111.783 237.161C113.289 237.161 114.524 235.926 114.524 234.419C114.524 232.912 113.289 231.677 111.783 231.677Z" />
        <path d="M93.858 231.677C92.3518 231.677 91.1171 232.912 91.1171 234.419C91.1171 235.926 92.3518 237.161 93.858 237.161C95.3643 237.161 96.5989 235.926 96.5989 234.419C96.5742 232.912 95.3643 231.677 93.858 231.677Z" />
        <path d="M75.9068 231.677C74.4006 231.677 73.166 232.912 73.166 234.419C73.166 235.926 74.4006 237.161 75.9068 237.161C77.413 237.161 78.6477 235.926 78.6477 234.419C78.6477 232.912 77.413 231.677 75.9068 231.677Z" />
        <path d="M57.9803 231.677C56.474 231.677 55.2394 232.912 55.2394 234.419C55.2394 235.926 56.474 237.161 57.9803 237.161C59.4865 237.161 60.7212 235.926 60.7212 234.419C60.6965 232.912 59.4865 231.677 57.9803 231.677Z" />
        <path d="M40.0274 231.677C38.5211 231.677 37.2865 232.912 37.2865 234.419C37.2865 235.926 38.5211 237.161 40.0274 237.161C41.5336 237.161 42.7683 235.926 42.7683 234.419C42.7683 232.912 41.5336 231.677 40.0274 231.677Z" />
        <path d="M22.0782 231.677C20.572 231.677 19.3373 232.912 19.3373 234.419C19.3373 235.926 20.572 237.161 22.0782 237.161C23.5845 237.161 24.8191 235.926 24.8191 234.419C24.8191 232.912 23.6092 231.677 22.0782 231.677Z" />
        <path d="M4.14999 231.677C2.64377 231.677 1.40919 232.912 1.40919 234.419C1.40919 235.926 2.64377 237.161 4.14999 237.161C5.65622 237.161 6.89087 235.926 6.89087 234.419C6.89087 232.912 5.65622 231.677 4.14999 231.677Z" />
        <path d="M945.972 213.741C944.466 213.741 943.232 214.977 943.232 216.484C943.232 217.991 944.466 219.226 945.972 219.226C947.479 219.226 948.713 217.991 948.713 216.484C948.713 214.952 947.479 213.741 945.972 213.741Z" />
        <path d="M928.046 213.741C926.539 213.741 925.305 214.977 925.305 216.484C925.305 217.991 926.539 219.226 928.046 219.226C929.552 219.226 930.786 217.991 930.786 216.484C930.786 214.952 929.552 213.741 928.046 213.741Z" />
        <path d="M910.095 213.741C908.588 213.741 907.354 214.977 907.354 216.484C907.354 217.991 908.588 219.226 910.095 219.226C911.601 219.226 912.836 217.991 912.836 216.484C912.836 214.952 911.601 213.741 910.095 213.741Z" />
        <path d="M892.168 213.741C890.662 213.741 889.427 214.977 889.427 216.484C889.427 217.991 890.662 219.226 892.168 219.226C893.674 219.226 894.909 217.991 894.909 216.484C894.909 214.952 893.674 213.741 892.168 213.741Z" />
        <path d="M874.215 213.741C872.709 213.741 871.474 214.977 871.474 216.484C871.474 217.991 872.709 219.226 874.215 219.226C875.721 219.226 876.956 217.991 876.956 216.484C876.956 214.952 875.721 213.741 874.215 213.741Z" />
        <path d="M856.288 213.741C854.782 213.741 853.548 214.977 853.548 216.484C853.548 217.991 854.782 219.226 856.288 219.226C857.795 219.226 859.029 217.991 859.029 216.484C859.005 214.952 857.795 213.741 856.288 213.741Z" />
        <path d="M838.337 213.741C836.831 213.741 835.597 214.977 835.597 216.484C835.597 217.991 836.831 219.226 838.337 219.226C839.844 219.226 841.078 217.991 841.078 216.484C841.078 214.952 839.844 213.741 838.337 213.741Z" />
        <path d="M820.411 213.741C818.904 213.741 817.67 214.977 817.67 216.484C817.67 217.991 818.904 219.226 820.411 219.226C821.917 219.226 823.152 217.991 823.152 216.484C823.127 214.952 821.917 213.741 820.411 213.741Z" />
        <path d="M802.46 213.741C800.954 213.741 799.719 214.977 799.719 216.484C799.719 217.991 800.954 219.226 802.46 219.226C803.966 219.226 805.201 217.991 805.201 216.484C805.201 214.952 803.966 213.741 802.46 213.741Z" />
        <path d="M784.533 213.741C783.027 213.741 781.792 214.977 781.792 216.484C781.792 217.991 783.027 219.226 784.533 219.226C786.039 219.226 787.274 217.991 787.274 216.484C787.249 214.952 786.039 213.741 784.533 213.741Z" />
        <path d="M766.582 213.741C765.076 213.741 763.841 214.977 763.841 216.484C763.841 217.991 765.076 219.226 766.582 219.226C768.088 219.226 769.323 217.991 769.323 216.484C769.323 214.952 768.088 213.741 766.582 213.741Z" />
        <path d="M748.654 213.741C747.147 213.741 745.913 214.977 745.913 216.484C745.913 217.991 747.147 219.226 748.654 219.226C750.16 219.226 751.395 217.991 751.395 216.484C751.37 214.952 750.16 213.741 748.654 213.741Z" />
        <path d="M730.704 213.741C729.198 213.741 727.964 214.977 727.964 216.484C727.964 217.991 729.198 219.226 730.704 219.226C732.211 219.226 733.445 217.991 733.445 216.484C733.445 214.952 732.211 213.741 730.704 213.741Z" />
        <path d="M712.776 213.741C711.27 213.741 710.035 214.977 710.035 216.484C710.035 217.991 711.27 219.226 712.776 219.226C714.282 219.226 715.517 217.991 715.517 216.484C715.492 214.952 714.282 213.741 712.776 213.741Z" />
        <path d="M694.825 213.741C693.319 213.741 692.084 214.977 692.084 216.484C692.084 217.991 693.319 219.226 694.825 219.226C696.331 219.226 697.566 217.991 697.566 216.484C697.566 214.952 696.331 213.741 694.825 213.741Z" />
        <path d="M676.874 213.741C675.368 213.741 674.133 214.977 674.133 216.484C674.133 217.991 675.368 219.226 676.874 219.226C678.38 219.226 679.615 217.991 679.615 216.484C679.615 214.952 678.405 213.741 676.874 213.741Z" />
        <path d="M658.946 213.741C657.439 213.741 656.205 214.977 656.205 216.484C656.205 217.991 657.439 219.226 658.946 219.226C660.452 219.226 661.686 217.991 661.686 216.484C661.686 214.952 660.452 213.741 658.946 213.741Z" />
        <path d="M640.996 213.741C639.49 213.741 638.255 214.977 638.255 216.484C638.255 217.991 639.49 219.226 640.996 219.226C642.503 219.226 643.737 217.991 643.737 216.484C643.737 214.952 642.527 213.741 640.996 213.741Z" />
        <path d="M623.068 213.741C621.562 213.741 620.327 214.977 620.327 216.484C620.327 217.991 621.562 219.226 623.068 219.226C624.574 219.226 625.809 217.991 625.809 216.484C625.809 214.952 624.574 213.741 623.068 213.741Z" />
        <path d="M605.117 213.741C603.611 213.741 602.376 214.977 602.376 216.484C602.376 217.991 603.611 219.226 605.117 219.226C606.623 219.226 607.858 217.991 607.858 216.484C607.858 214.952 606.648 213.741 605.117 213.741Z" />
        <path d="M587.19 213.741C585.684 213.741 584.449 214.977 584.449 216.484C584.449 217.991 585.684 219.226 587.19 219.226C588.696 219.226 589.931 217.991 589.931 216.484C589.931 214.952 588.696 213.741 587.19 213.741Z" />
        <path d="M569.241 213.741C567.735 213.741 566.5 214.977 566.5 216.484C566.5 217.991 567.735 219.226 569.241 219.226C570.747 219.226 571.982 217.991 571.982 216.484C571.982 214.952 570.772 213.741 569.241 213.741Z" />
        <path d="M551.312 213.741C549.806 213.741 548.572 214.977 548.572 216.484C548.572 217.991 549.806 219.226 551.312 219.226C552.819 219.226 554.053 217.991 554.053 216.484C554.053 214.952 552.819 213.741 551.312 213.741Z" />
        <path d="M533.36 213.741C531.853 213.741 530.619 214.977 530.619 216.484C530.619 217.991 531.853 219.226 533.36 219.226C534.866 219.226 536.101 217.991 536.101 216.484C536.101 214.952 534.891 213.741 533.36 213.741Z" />
        <path d="M515.435 213.741C513.928 213.741 512.694 214.977 512.694 216.484C512.694 217.991 513.928 219.226 515.435 219.226C516.941 219.226 518.176 217.991 518.176 216.484C518.176 214.952 516.941 213.741 515.435 213.741Z" />
        <path d="M497.482 213.741C495.976 213.741 494.741 214.977 494.741 216.484C494.741 217.991 495.976 219.226 497.482 219.226C498.988 219.226 500.223 217.991 500.223 216.484C500.223 214.952 498.988 213.741 497.482 213.741Z" />
        <path d="M479.557 213.741C478.051 213.741 476.816 214.977 476.816 216.484C476.816 217.991 478.051 219.226 479.557 219.226C481.063 219.226 482.298 217.991 482.298 216.484C482.298 214.952 481.063 213.741 479.557 213.741Z" />
        <path d="M461.604 213.741C460.098 213.741 458.863 214.977 458.863 216.484C458.863 217.991 460.098 219.226 461.604 219.226C463.111 219.226 464.345 217.991 464.345 216.484C464.345 214.952 463.111 213.741 461.604 213.741Z" />
        <path d="M443.678 213.741C442.171 213.741 440.937 214.977 440.937 216.484C440.937 217.991 442.171 219.226 443.678 219.226C445.184 219.226 446.419 217.991 446.419 216.484C446.419 214.952 445.184 213.741 443.678 213.741Z" />
        <path d="M425.725 213.741C424.219 213.741 422.984 214.977 422.984 216.484C422.984 217.991 424.219 219.226 425.725 219.226C427.231 219.226 428.466 217.991 428.466 216.484C428.466 214.952 427.231 213.741 425.725 213.741Z" />
        <path d="M407.798 213.741C406.292 213.741 405.057 214.977 405.057 216.484C405.057 217.991 406.292 219.226 407.798 219.226C409.305 219.226 410.539 217.991 410.539 216.484C410.514 214.952 409.305 213.741 407.798 213.741Z" />
        <path d="M389.847 213.741C388.341 213.741 387.106 214.977 387.106 216.484C387.106 217.991 388.341 219.226 389.847 219.226C391.353 219.226 392.588 217.991 392.588 216.484C392.588 214.952 391.353 213.741 389.847 213.741Z" />
        <path d="M371.921 213.741C370.414 213.741 369.18 214.977 369.18 216.484C369.18 217.991 370.414 219.226 371.921 219.226C373.427 219.226 374.661 217.991 374.661 216.484C374.637 214.952 373.427 213.741 371.921 213.741Z" />
        <path d="M353.968 213.741C352.462 213.741 351.227 214.977 351.227 216.484C351.227 217.991 352.462 219.226 353.968 219.226C355.474 219.226 356.709 217.991 356.709 216.484C356.709 214.952 355.474 213.741 353.968 213.741Z" />
        <path d="M336.043 213.741C334.537 213.741 333.302 214.977 333.302 216.484C333.302 217.991 334.537 219.226 336.043 219.226C337.549 219.226 338.784 217.991 338.784 216.484C338.759 214.952 337.549 213.741 336.043 213.741Z" />
        <path d="M318.09 213.741C316.584 213.741 315.349 214.977 315.349 216.484C315.349 217.991 316.584 219.226 318.09 219.226C319.596 219.226 320.831 217.991 320.831 216.484C320.831 214.952 319.596 213.741 318.09 213.741Z" />
        <path d="M300.165 213.741C298.659 213.741 297.424 214.977 297.424 216.484C297.424 217.991 298.659 219.226 300.165 219.226C301.671 219.226 302.906 217.991 302.906 216.484C302.881 214.952 301.671 213.741 300.165 213.741Z" />
        <path d="M282.213 213.741C280.706 213.741 279.472 214.977 279.472 216.484C279.472 217.991 280.706 219.226 282.213 219.226C283.719 219.226 284.953 217.991 284.953 216.484C284.953 214.952 283.719 213.741 282.213 213.741Z" />
        <path d="M264.284 213.741C262.778 213.741 261.543 214.977 261.543 216.484C261.543 217.991 262.778 219.226 264.284 219.226C265.79 219.226 267.025 217.991 267.025 216.484C267 214.952 265.79 213.741 264.284 213.741Z" />
        <path d="M246.335 213.741C244.828 213.741 243.594 214.977 243.594 216.484C243.594 217.991 244.828 219.226 246.335 219.226C247.841 219.226 249.075 217.991 249.075 216.484C249.075 214.952 247.841 213.741 246.335 213.741Z" />
        <path d="M228.384 213.741C226.877 213.741 225.643 214.977 225.643 216.484C225.643 217.991 226.877 219.226 228.384 219.226C229.89 219.226 231.125 217.991 231.125 216.484C231.125 214.952 229.915 213.741 228.384 213.741Z" />
        <path d="M210.457 213.741C208.951 213.741 207.716 214.977 207.716 216.484C207.716 217.991 208.951 219.226 210.457 219.226C211.963 219.226 213.198 217.991 213.198 216.484C213.198 214.952 211.963 213.741 210.457 213.741Z" />
        <path d="M192.506 213.741C191 213.741 189.765 214.977 189.765 216.484C189.765 217.991 191 219.226 192.506 219.226C194.012 219.226 195.247 217.991 195.247 216.484C195.247 214.952 194.037 213.741 192.506 213.741Z" />
        <path d="M174.579 213.741C173.073 213.741 171.839 214.977 171.839 216.484C171.839 217.991 173.073 219.226 174.579 219.226C176.086 219.226 177.32 217.991 177.32 216.484C177.32 214.952 176.086 213.741 174.579 213.741Z" />
        <path d="M156.627 213.741C155.12 213.741 153.886 214.977 153.886 216.484C153.886 217.991 155.12 219.226 156.627 219.226C158.133 219.226 159.367 217.991 159.367 216.484C159.367 214.952 158.157 213.741 156.627 213.741Z" />
        <path d="M138.7 213.741C137.194 213.741 135.959 214.977 135.959 216.484C135.959 217.991 137.194 219.226 138.7 219.226C140.206 219.226 141.441 217.991 141.441 216.484C141.441 214.952 140.206 213.741 138.7 213.741Z" />
        <path d="M120.749 213.741C119.243 213.741 118.008 214.977 118.008 216.484C118.008 217.991 119.243 219.226 120.749 219.226C122.255 219.226 123.49 217.991 123.49 216.484C123.49 214.952 122.28 213.741 120.749 213.741Z" />
        <path d="M102.822 213.741C101.316 213.741 100.081 214.977 100.081 216.484C100.081 217.991 101.316 219.226 102.822 219.226C104.328 219.226 105.563 217.991 105.563 216.484C105.563 214.952 104.328 213.741 102.822 213.741Z" />
        <path d="M84.8695 213.741C83.3633 213.741 82.1287 214.977 82.1287 216.484C82.1287 217.991 83.3633 219.226 84.8695 219.226C86.3758 219.226 87.6104 217.991 87.6104 216.484C87.6104 214.952 86.4005 213.741 84.8695 213.741Z" />
        <path d="M66.9443 213.741C65.4381 213.741 64.2035 214.977 64.2035 216.484C64.2035 217.991 65.4381 219.226 66.9443 219.226C68.4506 219.226 69.6853 217.991 69.6853 216.484C69.6853 214.952 68.4506 213.741 66.9443 213.741Z" />
        <path d="M48.9921 213.741C47.4859 213.741 46.2512 214.977 46.2512 216.484C46.2512 217.991 47.4859 219.226 48.9921 219.226C50.4983 219.226 51.7329 217.991 51.7329 216.484C51.7329 214.952 50.4983 213.741 48.9921 213.741Z" />
        <path d="M31.0652 213.741C29.559 213.741 28.3244 214.977 28.3244 216.484C28.3244 217.991 29.559 219.226 31.0652 219.226C32.5715 219.226 33.8062 217.991 33.8062 216.484C33.8062 214.952 32.5715 213.741 31.0652 213.741Z" />
        <path d="M13.1139 213.741C11.6076 213.741 10.373 214.977 10.373 216.484C10.373 217.991 11.6076 219.226 13.1139 219.226C14.6201 219.226 15.8547 217.991 15.8547 216.484C15.8547 214.952 14.6201 213.741 13.1139 213.741Z" />
        <path d="M954.96 196.176C953.676 196.176 952.615 197.214 952.615 198.523C952.615 199.808 953.652 200.87 954.96 200.87C956.244 200.87 957.306 199.832 957.306 198.523C957.282 197.238 956.244 196.176 954.96 196.176Z" />
        <path d="M937.01 196.176C935.726 196.176 934.664 197.214 934.664 198.523C934.664 199.808 935.701 200.87 937.01 200.87C938.294 200.87 939.355 199.832 939.355 198.523C939.355 197.238 938.294 196.176 937.01 196.176Z" />
        <path d="M919.059 196.176C917.774 196.176 916.713 197.214 916.713 198.523C916.713 199.808 917.75 200.87 919.059 200.87C920.343 200.87 921.404 199.832 921.404 198.523C921.404 197.238 920.367 196.176 919.059 196.176Z" />
        <path d="M901.13 196.176C899.846 196.176 898.784 197.214 898.784 198.523C898.784 199.808 899.821 200.87 901.13 200.87C902.414 200.87 903.476 199.832 903.476 198.523C903.476 197.238 902.414 196.176 901.13 196.176Z" />
        <path d="M883.179 196.176C881.895 196.176 880.833 197.214 880.833 198.523C880.833 199.808 881.87 200.87 883.179 200.87C884.463 200.87 885.525 199.832 885.525 198.523C885.525 197.238 884.488 196.176 883.179 196.176Z" />
        <path d="M865.252 196.176C863.968 196.176 862.907 197.214 862.907 198.523C862.907 199.808 863.944 200.87 865.252 200.87C866.536 200.87 867.598 199.832 867.598 198.523C867.598 197.238 866.536 196.176 865.252 196.176Z" />
        <path d="M847.301 196.176C846.017 196.176 844.956 197.214 844.956 198.523C844.956 199.808 845.993 200.87 847.301 200.87C848.585 200.87 849.647 199.832 849.647 198.523C849.647 197.238 848.61 196.176 847.301 196.176Z" />
        <path d="M829.375 196.176C828.091 196.176 827.029 197.214 827.029 198.523C827.029 199.808 828.066 200.87 829.375 200.87C830.659 200.87 831.721 199.832 831.721 198.523C831.721 197.238 830.659 196.176 829.375 196.176Z" />
        <path d="M811.422 196.176C810.138 196.176 809.076 197.214 809.076 198.523C809.076 199.808 810.113 200.87 811.422 200.87C812.706 200.87 813.768 199.832 813.768 198.523C813.768 197.238 812.731 196.176 811.422 196.176Z" />
        <path d="M793.497 196.176C792.213 196.176 791.151 197.214 791.151 198.523C791.151 199.808 792.188 200.87 793.497 200.87C794.781 200.87 795.843 199.832 795.843 198.523C795.843 197.238 794.781 196.176 793.497 196.176Z" />
        <path d="M775.544 196.176C774.26 196.176 773.198 197.214 773.198 198.523C773.198 199.808 774.236 200.87 775.544 200.87C776.828 200.87 777.89 199.832 777.89 198.523C777.89 197.238 776.853 196.176 775.544 196.176Z" />
        <path d="M757.618 196.176C756.334 196.176 755.272 197.214 755.272 198.523C755.272 199.808 756.309 200.87 757.618 200.87C758.902 200.87 759.963 199.832 759.963 198.523C759.963 197.238 758.902 196.176 757.618 196.176Z" />
        <path d="M739.667 196.176C738.383 196.176 737.321 197.214 737.321 198.523C737.321 199.808 738.358 200.87 739.667 200.87C740.951 200.87 742.012 199.832 742.012 198.523C742.012 197.238 740.975 196.176 739.667 196.176Z" />
        <path d="M721.74 196.176C720.456 196.176 719.394 197.214 719.394 198.523C719.394 199.808 720.431 200.87 721.74 200.87C723.024 200.87 724.086 199.832 724.086 198.523C724.061 197.238 723.024 196.176 721.74 196.176Z" />
        <path d="M703.789 196.176C702.505 196.176 701.443 197.214 701.443 198.523C701.443 199.808 702.48 200.87 703.789 200.87C705.073 200.87 706.135 199.832 706.135 198.523C706.135 197.238 705.098 196.176 703.789 196.176Z" />
        <path d="M685.861 196.176C684.577 196.176 683.515 197.214 683.515 198.523C683.515 199.808 684.552 200.87 685.861 200.87C687.145 200.87 688.206 199.832 688.206 198.523C688.182 197.238 687.145 196.176 685.861 196.176Z" />
        <path d="M667.911 196.176C666.627 196.176 665.565 197.214 665.565 198.523C665.565 199.808 666.602 200.87 667.911 200.87C669.22 200.87 670.257 199.832 670.257 198.523C670.257 197.238 669.22 196.176 667.911 196.176Z" />
        <path d="M649.983 196.176C648.699 196.176 647.637 197.214 647.637 198.523C647.637 199.808 648.674 200.87 649.983 200.87C651.267 200.87 652.328 199.832 652.328 198.523C652.304 197.238 651.267 196.176 649.983 196.176Z" />
        <path d="M632.033 196.176C630.749 196.176 629.688 197.214 629.688 198.523C629.688 199.808 630.725 200.87 632.033 200.87C633.342 200.87 634.379 199.832 634.379 198.523C634.379 197.238 633.317 196.176 632.033 196.176Z" />
        <path d="M614.105 196.176C612.821 196.176 611.759 197.214 611.759 198.523C611.759 199.808 612.796 200.87 614.105 200.87C615.414 200.87 616.451 199.832 616.451 198.523C616.426 197.238 615.389 196.176 614.105 196.176Z" />
        <path d="M596.152 196.176C594.868 196.176 593.806 197.214 593.806 198.523C593.806 199.808 594.844 200.87 596.152 200.87C597.461 200.87 598.498 199.832 598.498 198.523C598.498 197.238 597.436 196.176 596.152 196.176Z" />
        <path d="M578.227 196.176C576.943 196.176 575.882 197.214 575.882 198.523C575.882 199.808 576.919 200.87 578.227 200.87C579.511 200.87 580.573 199.832 580.573 198.523C580.548 197.238 579.511 196.176 578.227 196.176Z" />
        <path d="M560.275 196.176C558.991 196.176 557.929 197.214 557.929 198.523C557.929 199.808 558.966 200.87 560.275 200.87C561.559 200.87 562.62 199.832 562.62 198.523C562.62 197.238 561.559 196.176 560.275 196.176Z" />
        <path d="M542.35 196.176C541.066 196.176 540.004 197.214 540.004 198.523C540.004 199.808 541.041 200.87 542.35 200.87C543.634 200.87 544.695 199.832 544.695 198.523C544.671 197.238 543.634 196.176 542.35 196.176Z" />
        <path d="M524.397 196.176C523.113 196.176 522.051 197.214 522.051 198.523C522.051 199.808 523.088 200.87 524.397 200.87C525.681 200.87 526.743 199.832 526.743 198.523C526.743 197.238 525.681 196.176 524.397 196.176Z" />
        <path d="M506.47 196.176C505.186 196.176 504.124 197.214 504.124 198.523C504.124 199.808 505.162 200.87 506.47 200.87C507.754 200.87 508.816 199.832 508.816 198.523C508.791 197.238 507.754 196.176 506.47 196.176Z" />
        <path d="M488.519 196.176C487.235 196.176 486.173 197.214 486.173 198.523C486.173 199.808 487.211 200.87 488.519 200.87C489.803 200.87 490.865 199.832 490.865 198.523C490.865 197.238 489.803 196.176 488.519 196.176Z" />
        <path d="M470.593 196.176C469.309 196.176 468.247 197.214 468.247 198.523C468.247 199.808 469.284 200.87 470.593 200.87C471.877 200.87 472.938 199.832 472.938 198.523C472.914 197.238 471.877 196.176 470.593 196.176Z" />
        <path d="M452.642 196.176C451.358 196.176 450.296 197.214 450.296 198.523C450.296 199.808 451.333 200.87 452.642 200.87C453.926 200.87 454.987 199.832 454.987 198.523C454.987 197.238 453.926 196.176 452.642 196.176Z" />
        <path d="M434.689 196.176C433.405 196.176 432.343 197.214 432.343 198.523C432.343 199.808 433.38 200.87 434.689 200.87C435.973 200.87 437.035 199.832 437.035 198.523C437.035 197.238 435.997 196.176 434.689 196.176Z" />
        <path d="M416.76 196.176C415.476 196.176 414.415 197.214 414.415 198.523C414.415 199.808 415.452 200.87 416.76 200.87C418.044 200.87 419.106 199.832 419.106 198.523C419.106 197.238 418.044 196.176 416.76 196.176Z" />
        <path d="M398.811 196.176C397.527 196.176 396.465 197.214 396.465 198.523C396.465 199.808 397.502 200.87 398.811 200.87C400.095 200.87 401.157 199.832 401.157 198.523C401.157 197.238 400.12 196.176 398.811 196.176Z" />
        <path d="M380.884 196.176C379.6 196.176 378.539 197.214 378.539 198.523C378.539 199.808 379.576 200.87 380.884 200.87C382.168 200.87 383.23 199.832 383.23 198.523C383.23 197.238 382.168 196.176 380.884 196.176Z" />
        <path d="M362.934 196.176C361.65 196.176 360.588 197.214 360.588 198.523C360.588 199.808 361.625 200.87 362.934 200.87C364.218 200.87 365.279 199.832 365.279 198.523C365.279 197.238 364.242 196.176 362.934 196.176Z" />
        <path d="M345.005 196.176C343.721 196.176 342.659 197.214 342.659 198.523C342.659 199.808 343.696 200.87 345.005 200.87C346.289 200.87 347.351 199.832 347.351 198.523C347.351 197.238 346.289 196.176 345.005 196.176Z" />
        <path d="M327.054 196.176C325.77 196.176 324.708 197.214 324.708 198.523C324.708 199.808 325.745 200.87 327.054 200.87C328.338 200.87 329.4 199.832 329.4 198.523C329.4 197.238 328.363 196.176 327.054 196.176Z" />
        <path d="M309.127 196.176C307.843 196.176 306.782 197.214 306.782 198.523C306.782 199.808 307.819 200.87 309.127 200.87C310.411 200.87 311.473 199.832 311.473 198.523C311.473 197.238 310.411 196.176 309.127 196.176Z" />
        <path d="M291.176 196.176C289.892 196.176 288.831 197.214 288.831 198.523C288.831 199.808 289.868 200.87 291.176 200.87C292.46 200.87 293.522 199.832 293.522 198.523C293.522 197.238 292.485 196.176 291.176 196.176Z" />
        <path d="M273.25 196.176C271.966 196.176 270.904 197.214 270.904 198.523C270.904 199.808 271.941 200.87 273.25 200.87C274.534 200.87 275.595 199.832 275.595 198.523C275.571 197.238 274.534 196.176 273.25 196.176Z" />
        <path d="M255.298 196.176C254.014 196.176 252.953 197.214 252.953 198.523C252.953 199.808 253.99 200.87 255.298 200.87C256.582 200.87 257.644 199.832 257.644 198.523C257.644 197.238 256.607 196.176 255.298 196.176Z" />
        <path d="M237.372 196.176C236.088 196.176 235.026 197.214 235.026 198.523C235.026 199.808 236.063 200.87 237.372 200.87C238.656 200.87 239.718 199.832 239.718 198.523C239.693 197.238 238.656 196.176 237.372 196.176Z" />
        <path d="M219.419 196.176C218.135 196.176 217.073 197.214 217.073 198.523C217.073 199.808 218.11 200.87 219.419 200.87C220.703 200.87 221.765 199.832 221.765 198.523C221.765 197.238 220.728 196.176 219.419 196.176Z" />
        <path d="M201.494 196.176C200.21 196.176 199.149 197.214 199.149 198.523C199.149 199.808 200.186 200.87 201.494 200.87C202.778 200.87 203.84 199.832 203.84 198.523C203.815 197.238 202.778 196.176 201.494 196.176Z" />
        <path d="M183.541 196.176C182.257 196.176 181.196 197.214 181.196 198.523C181.196 199.808 182.233 200.87 183.541 200.87C184.825 200.87 185.887 199.832 185.887 198.523C185.887 197.238 184.825 196.176 183.541 196.176Z" />
        <path d="M165.617 196.176C164.333 196.176 163.271 197.214 163.271 198.523C163.271 199.808 164.308 200.87 165.617 200.87C166.901 200.87 167.962 199.832 167.962 198.523C167.938 197.238 166.901 196.176 165.617 196.176Z" />
        <path d="M147.664 196.176C146.38 196.176 145.318 197.214 145.318 198.523C145.318 199.808 146.355 200.87 147.664 200.87C148.948 200.87 150.01 199.832 150.01 198.523C150.01 197.238 148.948 196.176 147.664 196.176Z" />
        <path d="M129.735 196.176C128.451 196.176 127.39 197.214 127.39 198.523C127.39 199.808 128.427 200.87 129.735 200.87C131.019 200.87 132.081 199.832 132.081 198.523C132.056 197.238 131.019 196.176 129.735 196.176Z" />
        <path d="M111.784 196.176C110.5 196.176 109.439 197.214 109.439 198.523C109.439 199.808 110.476 200.87 111.784 200.87C113.068 200.87 114.13 199.832 114.13 198.523C114.13 197.238 113.068 196.176 111.784 196.176Z" />
        <path d="M93.8576 196.176C92.5736 196.176 91.5118 197.214 91.5118 198.523C91.5118 199.808 92.5489 200.87 93.8576 200.87C95.1416 200.87 96.2034 199.832 96.2034 198.523C96.1787 197.238 95.1416 196.176 93.8576 196.176Z" />
        <path d="M75.9066 196.176C74.6226 196.176 73.5609 197.214 73.5609 198.523C73.5609 199.808 74.598 200.87 75.9066 200.87C77.1906 200.87 78.2524 199.832 78.2524 198.523C78.2524 197.238 77.1906 196.176 75.9066 196.176Z" />
        <path d="M57.9799 196.176C56.696 196.176 55.6343 197.214 55.6343 198.523C55.6343 199.808 56.6713 200.87 57.9799 200.87C59.2639 200.87 60.3257 199.832 60.3257 198.523C60.301 197.238 59.2639 196.176 57.9799 196.176Z" />
        <path d="M40.0292 196.176C38.7452 196.176 37.6835 197.214 37.6835 198.523C37.6835 199.808 38.7205 200.87 40.0292 200.87C41.3132 200.87 42.375 199.832 42.375 198.523C42.375 197.238 41.3132 196.176 40.0292 196.176Z" />
        <path d="M22.0781 196.176C20.794 196.176 19.7322 197.214 19.7322 198.523C19.7322 199.808 20.7693 200.87 22.0781 200.87C23.3621 200.87 24.4238 199.832 24.4238 198.523C24.4238 197.238 23.3868 196.176 22.0781 196.176Z" />
        <path d="M4.14955 196.176C2.86554 196.176 1.80373 197.214 1.80373 198.523C1.80373 199.808 2.84085 200.87 4.14955 200.87C5.43356 200.87 6.49536 199.832 6.49536 198.523C6.49536 197.238 5.43356 196.176 4.14955 196.176Z" />
        <path d="M945.974 178.241C944.69 178.241 943.628 179.278 943.628 180.588C943.628 181.872 944.665 182.935 945.974 182.935C947.258 182.935 948.32 181.897 948.32 180.588C948.32 179.278 947.282 178.241 945.974 178.241Z" />
        <path d="M928.045 178.241C926.761 178.241 925.7 179.278 925.7 180.588C925.7 181.872 926.737 182.935 928.045 182.935C929.329 182.935 930.391 181.897 930.391 180.588C930.366 179.278 929.329 178.241 928.045 178.241Z" />
        <path d="M910.094 178.241C908.81 178.241 907.749 179.278 907.749 180.588C907.749 181.872 908.786 182.935 910.094 182.935C911.378 182.935 912.44 181.897 912.44 180.588C912.44 179.278 911.403 178.241 910.094 178.241Z" />
        <path d="M892.168 178.241C890.884 178.241 889.822 179.278 889.822 180.588C889.822 181.872 890.859 182.935 892.168 182.935C893.452 182.935 894.513 181.897 894.513 180.588C894.489 179.278 893.452 178.241 892.168 178.241Z" />
        <path d="M874.217 178.241C872.933 178.241 871.871 179.278 871.871 180.588C871.871 181.872 872.908 182.935 874.217 182.935C875.501 182.935 876.562 181.897 876.562 180.588C876.562 179.278 875.501 178.241 874.217 178.241Z" />
        <path d="M856.29 178.241C855.006 178.241 853.944 179.278 853.944 180.588C853.944 181.872 854.981 182.935 856.29 182.935C857.574 182.935 858.636 181.897 858.636 180.588C858.611 179.278 857.574 178.241 856.29 178.241Z" />
        <path d="M838.337 178.241C837.053 178.241 835.991 179.278 835.991 180.588C835.991 181.872 837.028 182.935 838.337 182.935C839.621 182.935 840.683 181.897 840.683 180.588C840.683 179.278 839.621 178.241 838.337 178.241Z" />
        <path d="M820.412 178.241C819.128 178.241 818.066 179.278 818.066 180.588C818.066 181.872 819.104 182.935 820.412 182.935C821.696 182.935 822.758 181.897 822.758 180.588C822.733 179.278 821.696 178.241 820.412 178.241Z" />
        <path d="M802.46 178.241C801.176 178.241 800.114 179.278 800.114 180.588C800.114 181.872 801.151 182.935 802.46 182.935C803.744 182.935 804.805 181.897 804.805 180.588C804.805 179.278 803.744 178.241 802.46 178.241Z" />
        <path d="M784.535 178.241C783.251 178.241 782.189 179.278 782.189 180.588C782.189 181.872 783.226 182.935 784.535 182.935C785.819 182.935 786.88 181.897 786.88 180.588C786.856 179.278 785.819 178.241 784.535 178.241Z" />
        <path d="M766.582 178.241C765.298 178.241 764.236 179.278 764.236 180.588C764.236 181.872 765.273 182.935 766.582 182.935C767.866 182.935 768.928 181.897 768.928 180.588C768.928 179.278 767.866 178.241 766.582 178.241Z" />
        <path d="M748.653 178.241C747.369 178.241 746.308 179.278 746.308 180.588C746.308 181.872 747.345 182.935 748.653 182.935C749.937 182.935 750.999 181.897 750.999 180.588C750.974 179.278 749.937 178.241 748.653 178.241Z" />
        <path d="M730.702 178.241C729.418 178.241 728.357 179.278 728.357 180.588C728.357 181.872 729.394 182.935 730.702 182.935C732.011 182.935 733.048 181.897 733.048 180.588C733.048 179.278 731.986 178.241 730.702 178.241Z" />
        <path d="M712.777 178.241C711.493 178.241 710.432 179.278 710.432 180.588C710.432 181.872 711.469 182.935 712.777 182.935C714.061 182.935 715.123 181.897 715.123 180.588C715.098 179.278 714.061 178.241 712.777 178.241Z" />
        <path d="M694.825 178.241C693.541 178.241 692.479 179.278 692.479 180.588C692.479 181.872 693.516 182.935 694.825 182.935C696.109 182.935 697.17 181.897 697.17 180.588C697.17 179.278 696.109 178.241 694.825 178.241Z" />
        <path d="M676.874 178.241C675.59 178.241 674.528 179.278 674.528 180.588C674.528 181.872 675.565 182.935 676.874 182.935C678.158 182.935 679.219 181.897 679.219 180.588C679.219 179.278 678.182 178.241 676.874 178.241Z" />
        <path d="M658.945 178.241C657.661 178.241 656.599 179.278 656.599 180.588C656.599 181.872 657.637 182.935 658.945 182.935C660.229 182.935 661.291 181.897 661.291 180.588C661.291 179.278 660.229 178.241 658.945 178.241Z" />
        <path d="M640.996 178.241C639.712 178.241 638.65 179.278 638.65 180.588C638.65 181.872 639.687 182.935 640.996 182.935C642.28 182.935 643.342 181.897 643.342 180.588C643.342 179.278 642.305 178.241 640.996 178.241Z" />
        <path d="M623.068 178.241C621.784 178.241 620.722 179.278 620.722 180.588C620.722 181.872 621.759 182.935 623.068 182.935C624.376 182.935 625.413 181.897 625.413 180.588C625.413 179.278 624.352 178.241 623.068 178.241Z" />
        <path d="M605.117 178.241C603.833 178.241 602.771 179.278 602.771 180.588C602.771 181.872 603.808 182.935 605.117 182.935C606.425 182.935 607.462 181.897 607.462 180.588C607.462 179.278 606.425 178.241 605.117 178.241Z" />
        <path d="M587.19 178.241C585.906 178.241 584.844 179.278 584.844 180.588C584.844 181.872 585.881 182.935 587.19 182.935C588.499 182.935 589.536 181.897 589.536 180.588C589.536 179.278 588.474 178.241 587.19 178.241Z" />
        <path d="M569.239 178.241C567.955 178.241 566.893 179.278 566.893 180.588C566.893 181.872 567.93 182.935 569.239 182.935C570.523 182.935 571.585 181.897 571.585 180.588C571.585 179.278 570.548 178.241 569.239 178.241Z" />
        <path d="M551.312 178.241C550.028 178.241 548.966 179.278 548.966 180.588C548.966 181.872 550.003 182.935 551.312 182.935C552.596 182.935 553.658 181.897 553.658 180.588C553.658 179.278 552.596 178.241 551.312 178.241Z" />
        <path d="M533.361 178.241C532.077 178.241 531.015 179.278 531.015 180.588C531.015 181.872 532.052 182.935 533.361 182.935C534.645 182.935 535.707 181.897 535.707 180.588C535.707 179.278 534.67 178.241 533.361 178.241Z" />
        <path d="M515.434 178.241C514.15 178.241 513.089 179.278 513.089 180.588C513.089 181.872 514.126 182.935 515.434 182.935C516.718 182.935 517.78 181.897 517.78 180.588C517.755 179.278 516.718 178.241 515.434 178.241Z" />
        <path d="M497.483 178.241C496.199 178.241 495.138 179.278 495.138 180.588C495.138 181.872 496.175 182.935 497.483 182.935C498.767 182.935 499.829 181.897 499.829 180.588C499.829 179.278 498.792 178.241 497.483 178.241Z" />
        <path d="M479.555 178.241C478.271 178.241 477.209 179.278 477.209 180.588C477.209 181.872 478.246 182.935 479.555 182.935C480.839 182.935 481.901 181.897 481.901 180.588C481.876 179.278 480.839 178.241 479.555 178.241Z" />
        <path d="M461.604 178.241C460.32 178.241 459.258 179.278 459.258 180.588C459.258 181.872 460.295 182.935 461.604 182.935C462.888 182.935 463.95 181.897 463.95 180.588C463.95 179.278 462.913 178.241 461.604 178.241Z" />
        <path d="M443.677 178.241C442.393 178.241 441.332 179.278 441.332 180.588C441.332 181.872 442.369 182.935 443.677 182.935C444.961 182.935 446.023 181.897 446.023 180.588C445.999 179.278 444.961 178.241 443.677 178.241Z" />
        <path d="M425.726 178.241C424.442 178.241 423.381 179.278 423.381 180.588C423.381 181.872 424.418 182.935 425.726 182.935C427.01 182.935 428.072 181.897 428.072 180.588C428.072 179.278 427.01 178.241 425.726 178.241Z" />
        <path d="M407.8 178.241C406.516 178.241 405.454 179.278 405.454 180.588C405.454 181.872 406.491 182.935 407.8 182.935C409.084 182.935 410.145 181.897 410.145 180.588C410.121 179.278 409.084 178.241 407.8 178.241Z" />
        <path d="M389.847 178.241C388.563 178.241 387.501 179.278 387.501 180.588C387.501 181.872 388.538 182.935 389.847 182.935C391.131 182.935 392.193 181.897 392.193 180.588C392.193 179.278 391.131 178.241 389.847 178.241Z" />
        <path d="M371.92 178.241C370.636 178.241 369.574 179.278 369.574 180.588C369.574 181.872 370.611 182.935 371.92 182.935C373.204 182.935 374.266 181.897 374.266 180.588C374.241 179.278 373.204 178.241 371.92 178.241Z" />
        <path d="M353.969 178.241C352.685 178.241 351.623 179.278 351.623 180.588C351.623 181.872 352.66 182.935 353.969 182.935C355.253 182.935 356.315 181.897 356.315 180.588C356.315 179.278 355.253 178.241 353.969 178.241Z" />
        <path d="M336.042 178.241C334.758 178.241 333.697 179.278 333.697 180.588C333.697 181.872 334.734 182.935 336.042 182.935C337.327 182.935 338.388 181.897 338.388 180.588C338.364 179.278 337.327 178.241 336.042 178.241Z" />
        <path d="M318.092 178.241C316.807 178.241 315.746 179.278 315.746 180.588C315.746 181.872 316.783 182.935 318.092 182.935C319.376 182.935 320.437 181.897 320.437 180.588C320.437 179.278 319.376 178.241 318.092 178.241Z" />
        <path d="M300.165 178.241C298.881 178.241 297.819 179.278 297.819 180.588C297.819 181.872 298.856 182.935 300.165 182.935C301.449 182.935 302.51 181.897 302.51 180.588C302.486 179.278 301.449 178.241 300.165 178.241Z" />
        <path d="M282.214 178.241C280.93 178.241 279.868 179.278 279.868 180.588C279.868 181.872 280.905 182.935 282.214 182.935C283.498 182.935 284.56 181.897 284.56 180.588C284.56 179.278 283.498 178.241 282.214 178.241Z" />
        <path d="M264.287 178.241C263.003 178.241 261.941 179.278 261.941 180.588C261.941 181.872 262.978 182.935 264.287 182.935C265.571 182.935 266.633 181.897 266.633 180.588C266.608 179.278 265.571 178.241 264.287 178.241Z" />
        <path d="M246.334 178.241C245.05 178.241 243.989 179.278 243.989 180.588C243.989 181.872 245.026 182.935 246.334 182.935C247.618 182.935 248.68 181.897 248.68 180.588C248.68 179.278 247.618 178.241 246.334 178.241Z" />
        <path d="M228.383 178.241C227.099 178.241 226.038 179.278 226.038 180.588C226.038 181.872 227.075 182.935 228.383 182.935C229.667 182.935 230.729 181.897 230.729 180.588C230.729 179.278 229.692 178.241 228.383 178.241Z" />
        <path d="M210.457 178.241C209.173 178.241 208.111 179.278 208.111 180.588C208.111 181.872 209.148 182.935 210.457 182.935C211.741 182.935 212.802 181.897 212.802 180.588C212.802 179.278 211.741 178.241 210.457 178.241Z" />
        <path d="M192.504 178.241C191.22 178.241 190.158 179.278 190.158 180.588C190.158 181.872 191.195 182.935 192.504 182.935C193.788 182.935 194.85 181.897 194.85 180.588C194.85 179.278 193.813 178.241 192.504 178.241Z" />
        <path d="M174.577 178.241C173.293 178.241 172.232 179.278 172.232 180.588C172.232 181.872 173.269 182.935 174.577 182.935C175.861 182.935 176.923 181.897 176.923 180.588C176.923 179.278 175.861 178.241 174.577 178.241Z" />
        <path d="M156.626 178.241C155.342 178.241 154.28 179.278 154.28 180.588C154.28 181.872 155.317 182.935 156.626 182.935C157.91 182.935 158.972 181.897 158.972 180.588C158.972 179.278 157.935 178.241 156.626 178.241Z" />
        <path d="M138.7 178.241C137.416 178.241 136.354 179.278 136.354 180.588C136.354 181.872 137.391 182.935 138.7 182.935C139.984 182.935 141.045 181.897 141.045 180.588C141.045 179.278 139.984 178.241 138.7 178.241Z" />
        <path d="M120.749 178.241C119.465 178.241 118.403 179.278 118.403 180.588C118.403 181.872 119.44 182.935 120.749 182.935C122.033 182.935 123.094 181.897 123.094 180.588C123.094 179.278 122.057 178.241 120.749 178.241Z" />
        <path d="M102.822 178.241C101.538 178.241 100.476 179.278 100.476 180.588C100.476 181.872 101.513 182.935 102.822 182.935C104.106 182.935 105.168 181.897 105.168 180.588C105.168 179.278 104.106 178.241 102.822 178.241Z" />
        <path d="M84.8707 178.241C83.5867 178.241 82.5249 179.278 82.5249 180.588C82.5249 181.872 83.562 182.935 84.8707 182.935C86.1547 182.935 87.2166 181.897 87.2166 180.588C87.2166 179.278 86.1794 178.241 84.8707 178.241Z" />
        <path d="M66.9425 178.241C65.6585 178.241 64.5967 179.278 64.5967 180.588C64.5967 181.872 65.6338 182.935 66.9425 182.935C68.2265 182.935 69.2883 181.897 69.2883 180.588C69.2636 179.278 68.2265 178.241 66.9425 178.241Z" />
        <path d="M48.9933 178.241C47.7093 178.241 46.6475 179.278 46.6475 180.588C46.6475 181.872 47.6846 182.935 48.9933 182.935C50.2773 182.935 51.3391 181.897 51.3391 180.588C51.3391 179.278 50.302 178.241 48.9933 178.241Z" />
        <path d="M31.0665 178.241C29.7825 178.241 28.7207 179.278 28.7207 180.588C28.7207 181.872 29.7578 182.935 31.0665 182.935C32.3505 182.935 33.4124 181.897 33.4124 180.588C33.3877 179.278 32.3505 178.241 31.0665 178.241Z" />
        <path d="M13.1137 178.241C11.8297 178.241 10.7678 179.278 10.7678 180.588C10.7678 181.872 11.805 182.935 13.1137 182.935C14.3977 182.935 15.4595 181.897 15.4595 180.588C15.4595 179.278 14.4224 178.241 13.1137 178.241Z" />
        <path d="M954.961 160.724C953.924 160.724 953.06 161.564 953.06 162.627C953.06 163.664 953.899 164.529 954.961 164.529C956.023 164.529 956.862 163.689 956.862 162.627C956.838 161.589 955.998 160.724 954.961 160.724Z" />
        <path d="M937.01 160.724C935.973 160.724 935.109 161.564 935.109 162.627C935.109 163.664 935.948 164.529 937.01 164.529C938.072 164.529 938.911 163.689 938.911 162.627C938.911 161.589 938.047 160.724 937.01 160.724Z" />
        <path d="M919.057 160.724C918.02 160.724 917.156 161.564 917.156 162.627C917.156 163.664 917.996 164.529 919.057 164.529C920.094 164.529 920.959 163.689 920.959 162.627C920.959 161.589 920.119 160.724 919.057 160.724Z" />
        <path d="M901.131 160.724C900.094 160.724 899.229 161.564 899.229 162.627C899.229 163.664 900.069 164.529 901.131 164.529C902.192 164.529 903.032 163.689 903.032 162.627C903.032 161.589 902.168 160.724 901.131 160.724Z" />
        <path d="M883.18 160.724C882.143 160.724 881.278 161.564 881.278 162.627C881.278 163.664 882.118 164.529 883.18 164.529C884.217 164.529 885.081 163.689 885.081 162.627C885.081 161.589 884.241 160.724 883.18 160.724Z" />
        <path d="M865.253 160.724C864.216 160.724 863.352 161.564 863.352 162.627C863.352 163.664 864.191 164.529 865.253 164.529C866.29 164.529 867.154 163.689 867.154 162.627C867.154 161.589 866.29 160.724 865.253 160.724Z" />
        <path d="M847.3 160.724C846.263 160.724 845.399 161.564 845.399 162.627C845.399 163.664 846.238 164.529 847.3 164.529C848.337 164.529 849.202 163.689 849.202 162.627C849.202 161.589 848.362 160.724 847.3 160.724Z" />
        <path d="M829.375 160.724C828.338 160.724 827.474 161.564 827.474 162.627C827.474 163.664 828.313 164.529 829.375 164.529C830.412 164.529 831.276 163.689 831.276 162.627C831.252 161.589 830.412 160.724 829.375 160.724Z" />
        <path d="M811.422 160.724C810.385 160.724 809.521 161.564 809.521 162.627C809.521 163.664 810.361 164.529 811.422 164.529C812.46 164.529 813.324 163.689 813.324 162.627C813.324 161.589 812.484 160.724 811.422 160.724Z" />
        <path d="M793.497 160.724C792.46 160.724 791.596 161.564 791.596 162.627C791.596 163.664 792.436 164.529 793.497 164.529C794.559 164.529 795.399 163.689 795.399 162.627C795.374 161.589 794.535 160.724 793.497 160.724Z" />
        <path d="M775.545 160.724C774.508 160.724 773.643 161.564 773.643 162.627C773.643 163.664 774.483 164.529 775.545 164.529C776.606 164.529 777.446 163.689 777.446 162.627C777.446 161.589 776.606 160.724 775.545 160.724Z" />
        <path d="M757.618 160.724C756.581 160.724 755.717 161.564 755.717 162.627C755.717 163.664 756.556 164.529 757.618 164.529C758.655 164.529 759.519 163.689 759.519 162.627C759.495 161.589 758.655 160.724 757.618 160.724Z" />
        <path d="M739.667 160.724C738.63 160.724 737.766 161.564 737.766 162.627C737.766 163.664 738.605 164.529 739.667 164.529C740.729 164.529 741.568 163.689 741.568 162.627C741.568 161.589 740.729 160.724 739.667 160.724Z" />
        <path d="M721.74 160.724C720.703 160.724 719.839 161.564 719.839 162.627C719.839 163.664 720.679 164.529 721.74 164.529C722.802 164.529 723.642 163.689 723.642 162.627C723.617 161.589 722.777 160.724 721.74 160.724Z" />
        <path d="M703.789 160.724C702.752 160.724 701.888 161.564 701.888 162.627C701.888 163.664 702.728 164.529 703.789 164.529C704.826 164.529 705.691 163.689 705.691 162.627C705.691 161.589 704.851 160.724 703.789 160.724Z" />
        <path d="M685.861 160.724C684.824 160.724 683.96 161.564 683.96 162.627C683.96 163.664 684.799 164.529 685.861 164.529C686.898 164.529 687.762 163.689 687.762 162.627C687.738 161.589 686.898 160.724 685.861 160.724Z" />
        <path d="M667.91 160.724C666.873 160.724 666.009 161.564 666.009 162.627C666.009 163.664 666.848 164.529 667.91 164.529C668.972 164.529 669.811 163.689 669.811 162.627C669.811 161.589 668.947 160.724 667.91 160.724Z" />
        <path d="M649.983 160.724C648.946 160.724 648.082 161.564 648.082 162.627C648.082 163.664 648.922 164.529 649.983 164.529C651.02 164.529 651.885 163.689 651.885 162.627C651.86 161.589 651.02 160.724 649.983 160.724Z" />
        <path d="M632.032 160.724C630.995 160.724 630.131 161.564 630.131 162.627C630.131 163.664 630.97 164.529 632.032 164.529C633.069 164.529 633.934 163.689 633.934 162.627C633.934 161.589 633.069 160.724 632.032 160.724Z" />
        <path d="M614.105 160.724C613.068 160.724 612.204 161.564 612.204 162.627C612.204 163.664 613.044 164.529 614.105 164.529C615.167 164.529 616.007 163.689 616.007 162.627C615.982 161.589 615.143 160.724 614.105 160.724Z" />
        <path d="M596.153 160.724C595.116 160.724 594.252 161.564 594.252 162.627C594.252 163.664 595.091 164.529 596.153 164.529C597.215 164.529 598.054 163.689 598.054 162.627C598.054 161.589 597.19 160.724 596.153 160.724Z" />
        <path d="M578.228 160.724C577.191 160.724 576.326 161.564 576.326 162.627C576.326 163.664 577.166 164.529 578.228 164.529C579.265 164.529 580.129 163.689 580.129 162.627C580.104 161.589 579.265 160.724 578.228 160.724Z" />
        <path d="M560.275 160.724C559.238 160.724 558.374 161.564 558.374 162.627C558.374 163.664 559.213 164.529 560.275 164.529C561.312 164.529 562.177 163.689 562.177 162.627C562.177 161.589 561.312 160.724 560.275 160.724Z" />
        <path d="M542.348 160.724C541.311 160.724 540.447 161.564 540.447 162.627C540.447 163.664 541.287 164.529 542.348 164.529C543.386 164.529 544.25 163.689 544.25 162.627C544.225 161.589 543.386 160.724 542.348 160.724Z" />
        <path d="M524.397 160.724C523.36 160.724 522.496 161.564 522.496 162.627C522.496 163.664 523.336 164.529 524.397 164.529C525.434 164.529 526.299 163.689 526.299 162.627C526.299 161.589 525.434 160.724 524.397 160.724Z" />
        <path d="M506.471 160.724C505.434 160.724 504.569 161.564 504.569 162.627C504.569 163.664 505.409 164.529 506.471 164.529C507.508 164.529 508.372 163.689 508.372 162.627C508.347 161.589 507.508 160.724 506.471 160.724Z" />
        <path d="M488.52 160.724C487.483 160.724 486.618 161.564 486.618 162.627C486.618 163.664 487.458 164.529 488.52 164.529C489.581 164.529 490.421 163.689 490.421 162.627C490.421 161.589 489.557 160.724 488.52 160.724Z" />
        <path d="M470.591 160.724C469.554 160.724 468.69 161.564 468.69 162.627C468.69 163.664 469.53 164.529 470.591 164.529C471.653 164.529 472.493 163.689 472.493 162.627C472.468 161.589 471.628 160.724 470.591 160.724Z" />
        <path d="M452.64 160.724C451.603 160.724 450.739 161.564 450.739 162.627C450.739 163.664 451.579 164.529 452.64 164.529C453.677 164.529 454.542 163.689 454.542 162.627C454.542 161.589 453.677 160.724 452.64 160.724Z" />
        <path d="M434.689 160.724C433.652 160.724 432.788 161.564 432.788 162.627C432.788 163.664 433.628 164.529 434.689 164.529C435.726 164.529 436.591 163.689 436.591 162.627C436.591 161.589 435.751 160.724 434.689 160.724Z" />
        <path d="M416.764 160.724C415.727 160.724 414.863 161.564 414.863 162.627C414.863 163.664 415.703 164.529 416.764 164.529C417.801 164.529 418.666 163.689 418.666 162.627C418.641 161.589 417.801 160.724 416.764 160.724Z" />
        <path d="M398.812 160.724C397.775 160.724 396.91 161.564 396.91 162.627C396.91 163.664 397.75 164.529 398.812 164.529C399.849 164.529 400.713 163.689 400.713 162.627C400.713 161.589 399.873 160.724 398.812 160.724Z" />
        <path d="M380.885 160.724C379.848 160.724 378.984 161.564 378.984 162.627C378.984 163.664 379.823 164.529 380.885 164.529C381.922 164.529 382.786 163.689 382.786 162.627C382.762 161.589 381.922 160.724 380.885 160.724Z" />
        <path d="M362.932 160.724C361.895 160.724 361.031 161.564 361.031 162.627C361.031 163.664 361.871 164.529 362.932 164.529C363.994 164.529 364.834 163.689 364.834 162.627C364.834 161.589 363.994 160.724 362.932 160.724Z" />
        <path d="M345.006 160.724C343.968 160.724 343.104 161.564 343.104 162.627C343.104 163.664 343.944 164.529 345.006 164.529C346.043 164.529 346.907 163.689 346.907 162.627C346.882 161.589 346.043 160.724 345.006 160.724Z" />
        <path d="M327.054 160.724C326.017 160.724 325.153 161.564 325.153 162.627C325.153 163.664 325.993 164.529 327.054 164.529C328.116 164.529 328.956 163.689 328.956 162.627C328.956 161.589 328.116 160.724 327.054 160.724Z" />
        <path d="M309.128 160.724C308.091 160.724 307.226 161.564 307.226 162.627C307.226 163.664 308.066 164.529 309.128 164.529C310.165 164.529 311.029 163.689 311.029 162.627C311.004 161.589 310.165 160.724 309.128 160.724Z" />
        <path d="M291.175 160.724C290.138 160.724 289.274 161.564 289.274 162.627C289.274 163.664 290.113 164.529 291.175 164.529C292.212 164.529 293.076 163.689 293.076 162.627C293.076 161.589 292.237 160.724 291.175 160.724Z" />
        <path d="M273.248 160.724C272.211 160.724 271.347 161.564 271.347 162.627C271.347 163.664 272.187 164.529 273.248 164.529C274.285 164.529 275.15 163.689 275.15 162.627C275.125 161.589 274.285 160.724 273.248 160.724Z" />
        <path d="M255.297 160.724C254.26 160.724 253.396 161.564 253.396 162.627C253.396 163.664 254.236 164.529 255.297 164.529C256.334 164.529 257.199 163.689 257.199 162.627C257.199 161.589 256.359 160.724 255.297 160.724Z" />
        <path d="M237.371 160.724C236.334 160.724 235.469 161.564 235.469 162.627C235.469 163.664 236.309 164.529 237.371 164.529C238.408 164.529 239.272 163.689 239.272 162.627C239.247 161.589 238.408 160.724 237.371 160.724Z" />
        <path d="M219.42 160.724C218.383 160.724 217.518 161.564 217.518 162.627C217.518 163.664 218.358 164.529 219.42 164.529C220.457 164.529 221.321 163.689 221.321 162.627C221.321 161.589 220.481 160.724 219.42 160.724Z" />
        <path d="M201.491 160.724C200.454 160.724 199.59 161.564 199.59 162.627C199.59 163.664 200.429 164.529 201.491 164.529C202.528 164.529 203.393 163.689 203.393 162.627C203.368 161.589 202.528 160.724 201.491 160.724Z" />
        <path d="M183.542 160.724C182.505 160.724 181.641 161.564 181.641 162.627C181.641 163.664 182.48 164.529 183.542 164.529C184.579 164.529 185.443 163.689 185.443 162.627C185.443 161.589 184.579 160.724 183.542 160.724Z" />
        <path d="M165.614 160.724C164.576 160.724 163.712 161.564 163.712 162.627C163.712 163.664 164.552 164.529 165.614 164.529C166.651 164.529 167.515 163.689 167.515 162.627C167.49 161.589 166.651 160.724 165.614 160.724Z" />
        <path d="M147.664 160.724C146.627 160.724 145.763 161.564 145.763 162.627C145.763 163.664 146.603 164.529 147.664 164.529C148.701 164.529 149.566 163.689 149.566 162.627C149.566 161.589 148.701 160.724 147.664 160.724Z" />
        <path d="M129.736 160.724C128.699 160.724 127.834 161.564 127.834 162.627C127.834 163.664 128.674 164.529 129.736 164.529C130.773 164.529 131.637 163.689 131.637 162.627C131.613 161.589 130.773 160.724 129.736 160.724Z" />
        <path d="M111.787 160.724C110.75 160.724 109.885 161.564 109.885 162.627C109.885 163.664 110.725 164.529 111.787 164.529C112.824 164.529 113.688 163.689 113.688 162.627C113.688 161.589 112.824 160.724 111.787 160.724Z" />
        <path d="M93.8582 160.724C92.8211 160.724 91.9568 161.564 91.9568 162.627C91.9568 163.664 92.7964 164.529 93.8582 164.529C94.8953 164.529 95.7595 163.689 95.7595 162.627C95.7348 161.589 94.8953 160.724 93.8582 160.724Z" />
        <path d="M75.9072 160.724C74.8702 160.724 74.006 161.564 74.006 162.627C74.006 163.664 74.8455 164.529 75.9072 164.529C76.9443 164.529 77.8086 163.689 77.8086 162.627C77.8086 161.589 76.9443 160.724 75.9072 160.724Z" />
        <path d="M57.9807 160.724C56.9437 160.724 56.0795 161.564 56.0795 162.627C56.0795 163.664 56.919 164.529 57.9807 164.529C59.0178 164.529 59.8821 163.689 59.8821 162.627C59.8574 161.589 59.0178 160.724 57.9807 160.724Z" />
        <path d="M40.0296 160.724C38.9925 160.724 38.1283 161.564 38.1283 162.627C38.1283 163.664 38.9678 164.529 40.0296 164.529C41.0667 164.529 41.9309 163.689 41.9309 162.627C41.9309 161.589 41.0667 160.724 40.0296 160.724Z" />
        <path d="M22.0768 160.724C21.0397 160.724 20.1754 161.564 20.1754 162.627C20.1754 163.664 21.015 164.529 22.0768 164.529C23.1138 164.529 23.978 163.689 23.978 162.627C23.978 161.589 23.1385 160.724 22.0768 160.724Z" />
        <path d="M4.14991 160.724C3.11278 160.724 2.24854 161.564 2.24854 162.627C2.24854 163.664 3.08809 164.529 4.14991 164.529C5.18703 164.529 6.05127 163.689 6.05127 162.627C6.05127 161.589 5.18703 160.724 4.14991 160.724Z" />
        <path d="M945.972 142.986C945.034 142.986 944.293 143.752 944.293 144.666C944.293 145.605 945.059 146.346 945.972 146.346C946.886 146.346 947.651 145.58 947.651 144.666C947.676 143.752 946.911 142.986 945.972 142.986Z" />
        <path d="M928.046 142.986C927.107 142.986 926.367 143.752 926.367 144.666C926.367 145.605 927.132 146.346 928.046 146.346C928.959 146.346 929.749 145.58 929.749 144.666C929.725 143.752 928.984 142.986 928.046 142.986Z" />
        <path d="M910.095 142.986C909.156 142.986 908.415 143.752 908.415 144.666C908.415 145.605 909.181 146.346 910.095 146.346C911.008 146.346 911.774 145.58 911.774 144.666C911.798 143.752 911.033 142.986 910.095 142.986Z" />
        <path d="M892.168 142.986C891.23 142.986 890.489 143.752 890.489 144.666C890.489 145.605 891.255 146.346 892.168 146.346C893.082 146.346 893.847 145.58 893.847 144.666C893.847 143.752 893.107 142.986 892.168 142.986Z" />
        <path d="M874.215 142.986C873.277 142.986 872.536 143.752 872.536 144.666C872.536 145.605 873.301 146.346 874.215 146.346C875.129 146.346 875.894 145.58 875.894 144.666C875.919 143.752 875.153 142.986 874.215 142.986Z" />
        <path d="M856.29 142.986C855.352 142.986 854.611 143.752 854.611 144.666C854.611 145.605 855.377 146.346 856.29 146.346C857.204 146.346 857.994 145.58 857.994 144.666C857.969 143.752 857.229 142.986 856.29 142.986Z" />
        <path d="M838.339 142.986C837.401 142.986 836.635 143.752 836.635 144.666C836.635 145.605 837.401 146.346 838.339 146.346C839.277 146.346 840.018 145.58 840.018 144.666C840.043 143.752 839.277 142.986 838.339 142.986Z" />
        <path d="M820.411 142.986C819.473 142.986 818.707 143.752 818.707 144.666C818.707 145.605 819.473 146.346 820.411 146.346C821.349 146.346 822.115 145.58 822.115 144.666C822.09 143.752 821.324 142.986 820.411 142.986Z" />
        <path d="M802.46 142.986C801.522 142.986 800.781 143.752 800.781 144.666C800.781 145.605 801.546 146.346 802.46 146.346C803.398 146.346 804.164 145.58 804.164 144.666C804.164 143.752 803.398 142.986 802.46 142.986Z" />
        <path d="M784.532 142.986C783.593 142.986 782.853 143.752 782.853 144.666C782.853 145.605 783.618 146.346 784.532 146.346C785.47 146.346 786.211 145.58 786.211 144.666C786.211 143.752 785.445 142.986 784.532 142.986Z" />
        <path d="M766.582 142.986C765.644 142.986 764.878 143.752 764.878 144.666C764.878 145.605 765.644 146.346 766.582 146.346C767.52 146.346 768.261 145.58 768.261 144.666C768.286 143.752 767.52 142.986 766.582 142.986Z" />
        <path d="M748.654 142.986C747.715 142.986 746.975 143.752 746.975 144.666C746.975 145.605 747.74 146.346 748.654 146.346C749.592 146.346 750.358 145.58 750.358 144.666C750.333 143.752 749.567 142.986 748.654 142.986Z" />
        <path d="M730.704 142.986C729.766 142.986 729.025 143.752 729.025 144.666C729.025 145.605 729.791 146.346 730.704 146.346C731.618 146.346 732.383 145.58 732.383 144.666C732.408 143.752 731.643 142.986 730.704 142.986Z" />
        <path d="M712.776 142.986C711.838 142.986 711.073 143.752 711.073 144.666C711.073 145.605 711.838 146.346 712.776 146.346C713.715 146.346 714.455 145.58 714.455 144.666C714.455 143.752 713.69 142.986 712.776 142.986Z" />
        <path d="M694.825 142.986C693.887 142.986 693.146 143.752 693.146 144.666C693.146 145.605 693.911 146.346 694.825 146.346C695.763 146.346 696.529 145.58 696.529 144.666C696.529 143.752 695.763 142.986 694.825 142.986Z" />
        <path d="M676.874 142.986C675.936 142.986 675.195 143.752 675.195 144.666C675.195 145.605 675.96 146.346 676.874 146.346C677.812 146.346 678.553 145.58 678.553 144.666C678.578 143.752 677.812 142.986 676.874 142.986Z" />
        <path d="M658.946 142.986C658.008 142.986 657.267 143.752 657.267 144.666C657.267 145.605 658.032 146.346 658.946 146.346C659.884 146.346 660.625 145.58 660.625 144.666C660.625 143.752 659.884 142.986 658.946 142.986Z" />
        <path d="M640.996 142.986C640.058 142.986 639.317 143.752 639.317 144.666C639.317 145.605 640.083 146.346 640.996 146.346C641.935 146.346 642.675 145.58 642.675 144.666C642.7 143.752 641.935 142.986 640.996 142.986Z" />
        <path d="M623.068 142.986C622.13 142.986 621.389 143.752 621.389 144.666C621.389 145.605 622.155 146.346 623.068 146.346C623.982 146.346 624.747 145.58 624.747 144.666C624.747 143.752 624.007 142.986 623.068 142.986Z" />
        <path d="M605.117 142.986C604.178 142.986 603.438 143.752 603.438 144.666C603.438 145.605 604.203 146.346 605.117 146.346C606.03 146.346 606.796 145.58 606.796 144.666C606.82 143.752 606.055 142.986 605.117 142.986Z" />
        <path d="M587.191 142.986C586.252 142.986 585.512 143.752 585.512 144.666C585.512 145.605 586.277 146.346 587.191 146.346C588.104 146.346 588.87 145.58 588.87 144.666C588.87 143.752 588.129 142.986 587.191 142.986Z" />
        <path d="M569.237 142.986C568.299 142.986 567.558 143.752 567.558 144.666C567.558 145.605 568.324 146.346 569.237 146.346C570.176 146.346 570.916 145.58 570.916 144.666C570.941 143.752 570.176 142.986 569.237 142.986Z" />
        <path d="M551.313 142.986C550.375 142.986 549.609 143.752 549.609 144.666C549.609 145.605 550.375 146.346 551.313 146.346C552.251 146.346 552.992 145.58 552.992 144.666C552.992 143.752 552.251 142.986 551.313 142.986Z" />
        <path d="M533.36 142.986C532.421 142.986 531.681 143.752 531.681 144.666C531.681 145.605 532.446 146.346 533.36 146.346C534.298 146.346 535.063 145.58 535.063 144.666C535.063 143.752 534.298 142.986 533.36 142.986Z" />
        <path d="M515.433 142.986C514.495 142.986 513.754 143.752 513.754 144.666C513.754 145.605 514.52 146.346 515.433 146.346C516.372 146.346 517.113 145.58 517.113 144.666C517.113 143.752 516.372 142.986 515.433 142.986Z" />
        <path d="M497.482 142.986C496.544 142.986 495.803 143.752 495.803 144.666C495.803 145.605 496.568 146.346 497.482 146.346C498.396 146.346 499.161 145.58 499.161 144.666C499.186 143.752 498.42 142.986 497.482 142.986Z" />
        <path d="M479.554 142.986C478.616 142.986 477.875 143.752 477.875 144.666C477.875 145.605 478.64 146.346 479.554 146.346C480.468 146.346 481.233 145.58 481.233 144.666C481.233 143.752 480.492 142.986 479.554 142.986Z" />
        <path d="M461.604 142.986C460.666 142.986 459.925 143.752 459.925 144.666C459.925 145.605 460.69 146.346 461.604 146.346C462.518 146.346 463.283 145.58 463.283 144.666C463.308 143.752 462.542 142.986 461.604 142.986Z" />
        <path d="M443.676 142.986C442.738 142.986 441.973 143.752 441.973 144.666C441.973 145.605 442.738 146.346 443.676 146.346C444.615 146.346 445.355 145.58 445.355 144.666C445.355 143.752 444.615 142.986 443.676 142.986Z" />
        <path d="M425.727 142.986C424.788 142.986 424.023 143.752 424.023 144.666C424.023 145.605 424.788 146.346 425.727 146.346C426.665 146.346 427.406 145.58 427.406 144.666C427.43 143.752 426.665 142.986 425.727 142.986Z" />
        <path d="M407.799 142.986C406.86 142.986 406.12 143.752 406.12 144.666C406.12 145.605 406.885 146.346 407.799 146.346C408.737 146.346 409.478 145.58 409.478 144.666C409.478 143.752 408.737 142.986 407.799 142.986Z" />
        <path d="M389.849 142.986C388.91 142.986 388.17 143.752 388.17 144.666C388.17 145.605 388.935 146.346 389.849 146.346C390.762 146.346 391.528 145.58 391.528 144.666C391.553 143.752 390.787 142.986 389.849 142.986Z" />
        <path d="M371.921 142.986C370.982 142.986 370.242 143.752 370.242 144.666C370.242 145.605 371.007 146.346 371.921 146.346C372.834 146.346 373.6 145.58 373.6 144.666C373.6 143.752 372.834 142.986 371.921 142.986Z" />
        <path d="M353.969 142.986C353.031 142.986 352.29 143.752 352.29 144.666C352.29 145.605 353.056 146.346 353.969 146.346C354.883 146.346 355.649 145.58 355.649 144.666C355.673 143.752 354.908 142.986 353.969 142.986Z" />
        <path d="M336.043 142.986C335.105 142.986 334.364 143.752 334.364 144.666C334.364 145.605 335.13 146.346 336.043 146.346C336.957 146.346 337.722 145.58 337.722 144.666C337.722 143.752 336.957 142.986 336.043 142.986Z" />
        <path d="M318.092 142.986C317.154 142.986 316.413 143.752 316.413 144.666C316.413 145.605 317.178 146.346 318.092 146.346C319.005 146.346 319.771 145.58 319.771 144.666C319.796 143.752 319.03 142.986 318.092 142.986Z" />
        <path d="M300.166 142.986C299.227 142.986 298.487 143.752 298.487 144.666C298.487 145.605 299.252 146.346 300.166 146.346C301.079 146.346 301.845 145.58 301.845 144.666C301.845 143.752 301.079 142.986 300.166 142.986Z" />
        <path d="M282.214 142.986C281.276 142.986 280.535 143.752 280.535 144.666C280.535 145.605 281.3 146.346 282.214 146.346C283.128 146.346 283.893 145.58 283.893 144.666C283.918 143.752 283.152 142.986 282.214 142.986Z" />
        <path d="M264.286 142.986C263.348 142.986 262.607 143.752 262.607 144.666C262.607 145.605 263.373 146.346 264.286 146.346C265.225 146.346 265.965 145.58 265.965 144.666C265.965 143.752 265.2 142.986 264.286 142.986Z" />
        <path d="M246.335 142.986C245.397 142.986 244.631 143.752 244.631 144.666C244.631 145.605 245.397 146.346 246.335 146.346C247.273 146.346 248.014 145.58 248.014 144.666C248.014 143.752 247.273 142.986 246.335 142.986Z" />
        <path d="M228.382 142.986C227.444 142.986 226.703 143.752 226.703 144.666C226.703 145.605 227.468 146.346 228.382 146.346C229.32 146.346 230.061 145.58 230.061 144.666C230.086 143.752 229.32 142.986 228.382 142.986Z" />
        <path d="M210.456 142.986C209.517 142.986 208.777 143.752 208.777 144.666C208.777 145.605 209.542 146.346 210.456 146.346C211.394 146.346 212.135 145.58 212.135 144.666C212.135 143.752 211.394 142.986 210.456 142.986Z" />
        <path d="M192.504 142.986C191.566 142.986 190.825 143.752 190.825 144.666C190.825 145.605 191.591 146.346 192.504 146.346C193.418 146.346 194.183 145.58 194.183 144.666C194.208 143.752 193.442 142.986 192.504 142.986Z" />
        <path d="M174.576 142.986C173.638 142.986 172.897 143.752 172.897 144.666C172.897 145.605 173.663 146.346 174.576 146.346C175.515 146.346 176.255 145.58 176.255 144.666C176.255 143.752 175.515 142.986 174.576 142.986Z" />
        <path d="M156.626 142.986C155.688 142.986 154.947 143.752 154.947 144.666C154.947 145.605 155.713 146.346 156.626 146.346C157.565 146.346 158.306 145.58 158.306 144.666C158.33 143.752 157.565 142.986 156.626 142.986Z" />
        <path d="M138.7 142.986C137.762 142.986 137.021 143.752 137.021 144.666C137.021 145.605 137.786 146.346 138.7 146.346C139.614 146.346 140.379 145.58 140.379 144.666C140.379 143.752 139.638 142.986 138.7 142.986Z" />
        <path d="M120.749 142.986C119.811 142.986 119.07 143.752 119.07 144.666C119.07 145.605 119.835 146.346 120.749 146.346C121.662 146.346 122.428 145.58 122.428 144.666C122.453 143.752 121.687 142.986 120.749 142.986Z" />
        <path d="M102.821 142.986C101.882 142.986 101.142 143.752 101.142 144.666C101.142 145.605 101.907 146.346 102.821 146.346C103.734 146.346 104.5 145.58 104.5 144.666C104.5 143.752 103.759 142.986 102.821 142.986Z" />
        <path d="M84.8711 142.986C83.9327 142.986 83.192 143.752 83.192 144.666C83.192 145.605 83.9574 146.346 84.8711 146.346C85.8094 146.346 86.5502 145.58 86.5502 144.666C86.5749 143.752 85.8094 142.986 84.8711 142.986Z" />
        <path d="M66.9432 142.986C66.0049 142.986 65.2641 143.752 65.2641 144.666C65.2641 145.605 66.0296 146.346 66.9432 146.346C67.8815 146.346 68.6223 145.58 68.6223 144.666C68.6223 143.752 67.8815 142.986 66.9432 142.986Z" />
        <path d="M48.9915 142.986C48.0532 142.986 47.3125 143.752 47.3125 144.666C47.3125 145.605 48.0779 146.346 48.9915 146.346C49.9298 146.346 50.6953 145.58 50.6953 144.666C50.6953 143.752 49.9298 142.986 48.9915 142.986Z" />
        <path d="M31.0655 142.986C30.1272 142.986 29.3864 143.752 29.3864 144.666C29.3864 145.605 30.1519 146.346 31.0655 146.346C31.9791 146.346 32.7446 145.58 32.7446 144.666C32.7446 143.752 32.0038 142.986 31.0655 142.986Z" />
        <path d="M13.1143 142.986C12.176 142.986 11.4353 143.752 11.4353 144.666C11.4353 145.605 12.2007 146.346 13.1143 146.346C14.0279 146.346 14.7934 145.58 14.7934 144.666C14.8181 143.752 14.0526 142.986 13.1143 142.986Z" />
        <path d="M954.961 125.223C954.146 125.223 953.48 125.89 953.48 126.706C953.48 127.521 954.146 128.188 954.961 128.188C955.776 128.188 956.443 127.521 956.443 126.706C956.443 125.915 955.776 125.223 954.961 125.223Z" />
        <path d="M937.008 125.223C936.194 125.223 935.527 125.89 935.527 126.706C935.527 127.521 936.194 128.188 937.008 128.188C937.823 128.188 938.49 127.521 938.49 126.706C938.49 125.915 937.823 125.223 937.008 125.223Z" />
        <path d="M919.059 125.223C918.244 125.223 917.577 125.89 917.577 126.706C917.577 127.521 918.244 128.188 919.059 128.188C919.873 128.188 920.54 127.521 920.54 126.706C920.565 125.915 919.898 125.223 919.059 125.223Z" />
        <path d="M901.131 125.223C900.316 125.223 899.649 125.89 899.649 126.706C899.649 127.521 900.316 128.188 901.131 128.188C901.946 128.188 902.612 127.521 902.612 126.706C902.612 125.915 901.946 125.223 901.131 125.223Z" />
        <path d="M883.179 125.223C882.364 125.223 881.698 125.89 881.698 126.706C881.698 127.521 882.364 128.188 883.179 128.188C883.994 128.188 884.661 127.521 884.661 126.706C884.685 125.915 884.019 125.223 883.179 125.223Z" />
        <path d="M865.253 125.224C864.438 125.224 863.771 125.891 863.771 126.706C863.771 127.521 864.438 128.188 865.253 128.188C866.068 128.188 866.734 127.521 866.734 126.706C866.734 125.915 866.068 125.224 865.253 125.224Z" />
        <path d="M847.3 125.223C846.485 125.223 845.818 125.89 845.818 126.706C845.818 127.521 846.485 128.188 847.3 128.188C848.114 128.188 848.781 127.521 848.781 126.706C848.806 125.915 848.139 125.223 847.3 125.223Z" />
        <path d="M829.375 125.223C828.56 125.223 827.894 125.89 827.894 126.706C827.894 127.521 828.56 128.188 829.375 128.188C830.19 128.188 830.857 127.521 830.857 126.706C830.857 125.915 830.19 125.223 829.375 125.223Z" />
        <path d="M811.422 125.223C810.607 125.223 809.94 125.89 809.94 126.706C809.94 127.521 810.607 128.188 811.422 128.188C812.237 128.188 812.903 127.521 812.903 126.706C812.928 125.915 812.261 125.223 811.422 125.223Z" />
        <path d="M793.496 125.224C792.681 125.224 792.014 125.891 792.014 126.706C792.014 127.521 792.681 128.188 793.496 128.188C794.311 128.188 794.977 127.521 794.977 126.706C794.977 125.915 794.311 125.224 793.496 125.224Z" />
        <path d="M775.544 125.223C774.729 125.223 774.063 125.89 774.063 126.706C774.063 127.521 774.729 128.188 775.544 128.188C776.359 128.188 777.026 127.521 777.026 126.706C777.051 125.915 776.384 125.223 775.544 125.223Z" />
        <path d="M757.618 125.223C756.803 125.223 756.137 125.89 756.137 126.706C756.137 127.521 756.803 128.188 757.618 128.188C758.433 128.188 759.1 127.521 759.1 126.706C759.1 125.915 758.433 125.223 757.618 125.223Z" />
        <path d="M739.667 125.223C738.852 125.223 738.185 125.89 738.185 126.706C738.185 127.521 738.852 128.188 739.667 128.188C740.481 128.188 741.148 127.521 741.148 126.706C741.173 125.915 740.506 125.223 739.667 125.223Z" />
        <path d="M721.739 125.223C720.924 125.223 720.257 125.89 720.257 126.706C720.257 127.521 720.924 128.188 721.739 128.188C722.554 128.188 723.22 127.521 723.22 126.706C723.22 125.915 722.554 125.223 721.739 125.223Z" />
        <path d="M703.789 125.223C702.974 125.223 702.307 125.89 702.307 126.706C702.307 127.521 702.974 128.188 703.789 128.188C704.604 128.188 705.27 127.521 705.27 126.706C705.295 125.915 704.628 125.223 703.789 125.223Z" />
        <path d="M685.861 125.223C685.046 125.223 684.379 125.89 684.379 126.706C684.379 127.521 685.046 128.188 685.861 128.188C686.676 128.188 687.343 127.521 687.343 126.706C687.343 125.915 686.676 125.223 685.861 125.223Z" />
        <path d="M667.911 125.223C667.096 125.223 666.43 125.89 666.43 126.706C666.43 127.521 667.096 128.188 667.911 128.188C668.726 128.188 669.393 127.521 669.393 126.706C669.417 125.915 668.751 125.223 667.911 125.223Z" />
        <path d="M649.983 125.223C649.168 125.223 648.502 125.89 648.502 126.706C648.502 127.521 649.168 128.188 649.983 128.188C650.798 128.188 651.465 127.521 651.465 126.706C651.465 125.915 650.798 125.223 649.983 125.223Z" />
        <path d="M632.032 125.224C631.217 125.224 630.55 125.891 630.55 126.706C630.55 127.521 631.217 128.188 632.032 128.188C632.847 128.188 633.513 127.521 633.513 126.706C633.538 125.915 632.871 125.224 632.032 125.224Z" />
        <path d="M614.106 125.223C613.291 125.223 612.624 125.89 612.624 126.706C612.624 127.521 613.291 128.188 614.106 128.188C614.92 128.188 615.587 127.521 615.587 126.706C615.587 125.915 614.92 125.223 614.106 125.223Z" />
        <path d="M596.156 125.223C595.341 125.223 594.674 125.89 594.674 126.706C594.674 127.521 595.341 128.188 596.156 128.188C596.971 128.188 597.637 127.521 597.637 126.706C597.662 125.915 596.995 125.223 596.156 125.223Z" />
        <path d="M578.228 125.223C577.413 125.223 576.746 125.89 576.746 126.706C576.746 127.521 577.413 128.188 578.228 128.188C579.043 128.188 579.709 127.521 579.709 126.706C579.709 125.915 579.043 125.223 578.228 125.223Z" />
        <path d="M560.275 125.223C559.46 125.223 558.793 125.89 558.793 126.706C558.793 127.521 559.46 128.188 560.275 128.188C561.089 128.188 561.756 127.521 561.756 126.706C561.781 125.915 561.089 125.223 560.275 125.223Z" />
        <path d="M542.349 125.224C541.534 125.224 540.867 125.891 540.867 126.706C540.867 127.521 541.534 128.188 542.349 128.188C543.163 128.188 543.83 127.521 543.83 126.706C543.83 125.915 543.163 125.224 542.349 125.224Z" />
        <path d="M524.397 125.223C523.583 125.223 522.916 125.89 522.916 126.706C522.916 127.521 523.583 128.188 524.397 128.188C525.212 128.188 525.879 127.521 525.879 126.706C525.879 125.915 525.212 125.223 524.397 125.223Z" />
        <path d="M506.471 125.223C505.656 125.223 504.989 125.89 504.989 126.706C504.989 127.521 505.656 128.188 506.471 128.188C507.286 128.188 507.952 127.521 507.952 126.706C507.952 125.915 507.286 125.223 506.471 125.223Z" />
        <path d="M488.518 125.223C487.703 125.223 487.037 125.89 487.037 126.706C487.037 127.521 487.703 128.188 488.518 128.188C489.333 128.188 490 127.521 490 126.706C490 125.915 489.333 125.223 488.518 125.223Z" />
        <path d="M470.591 125.223C469.776 125.223 469.11 125.89 469.11 126.706C469.11 127.521 469.776 128.188 470.591 128.188C471.406 128.188 472.073 127.521 472.073 126.706C472.073 125.915 471.406 125.223 470.591 125.223Z" />
        <path d="M452.64 125.223C451.826 125.223 451.159 125.89 451.159 126.706C451.159 127.521 451.826 128.188 452.64 128.188C453.455 128.188 454.122 127.521 454.122 126.706C454.122 125.915 453.455 125.223 452.64 125.223Z" />
        <path d="M434.689 125.223C433.874 125.223 433.207 125.89 433.207 126.706C433.207 127.521 433.874 128.188 434.689 128.188C435.504 128.188 436.17 127.521 436.17 126.706C436.195 125.915 435.528 125.223 434.689 125.223Z" />
        <path d="M416.765 125.223C415.95 125.223 415.283 125.89 415.283 126.706C415.283 127.521 415.95 128.188 416.765 128.188C417.579 128.188 418.246 127.521 418.246 126.706C418.246 125.915 417.579 125.223 416.765 125.223Z" />
        <path d="M398.811 125.224C397.996 125.224 397.33 125.891 397.33 126.706C397.33 127.521 397.996 128.188 398.811 128.188C399.626 128.188 400.293 127.521 400.293 126.706C400.317 125.915 399.651 125.224 398.811 125.224Z" />
        <path d="M380.885 125.223C380.07 125.223 379.403 125.89 379.403 126.706C379.403 127.521 380.07 128.188 380.885 128.188C381.7 128.188 382.367 127.521 382.367 126.706C382.367 125.915 381.7 125.223 380.885 125.223Z" />
        <path d="M362.932 125.223C362.117 125.223 361.45 125.89 361.45 126.706C361.45 127.521 362.117 128.188 362.932 128.188C363.746 128.188 364.413 127.521 364.413 126.706C364.438 125.915 363.771 125.223 362.932 125.223Z" />
        <path d="M345.006 125.223C344.191 125.223 343.524 125.89 343.524 126.706C343.524 127.521 344.191 128.188 345.006 128.188C345.82 128.188 346.487 127.521 346.487 126.706C346.487 125.915 345.82 125.223 345.006 125.223Z" />
        <path d="M327.054 125.223C326.239 125.223 325.572 125.89 325.572 126.706C325.572 127.521 326.239 128.188 327.054 128.188C327.869 128.188 328.535 127.521 328.535 126.706C328.56 125.915 327.893 125.223 327.054 125.223Z" />
        <path d="M309.128 125.224C308.313 125.224 307.646 125.891 307.646 126.706C307.646 127.521 308.313 128.188 309.128 128.188C309.943 128.188 310.609 127.521 310.609 126.706C310.609 125.915 309.943 125.224 309.128 125.224Z" />
        <path d="M291.175 125.223C290.36 125.223 289.693 125.89 289.693 126.706C289.693 127.521 290.36 128.188 291.175 128.188C291.989 128.188 292.656 127.521 292.656 126.706C292.681 125.915 292.014 125.223 291.175 125.223Z" />
        <path d="M273.248 125.223C272.434 125.223 271.767 125.89 271.767 126.706C271.767 127.521 272.434 128.188 273.248 128.188C274.063 128.188 274.73 127.521 274.73 126.706C274.73 125.915 274.063 125.223 273.248 125.223Z" />
        <path d="M255.297 125.223C254.482 125.223 253.815 125.89 253.815 126.706C253.815 127.521 254.482 128.188 255.297 128.188C256.112 128.188 256.778 127.521 256.778 126.706C256.803 125.915 256.136 125.223 255.297 125.223Z" />
        <path d="M237.371 125.224C236.556 125.224 235.889 125.891 235.889 126.706C235.889 127.521 236.556 128.188 237.371 128.188C238.186 128.188 238.852 127.521 238.852 126.706C238.852 125.915 238.186 125.224 237.371 125.224Z" />
        <path d="M219.419 125.223C218.604 125.223 217.938 125.89 217.938 126.706C217.938 127.521 218.604 128.188 219.419 128.188C220.234 128.188 220.901 127.521 220.901 126.706C220.925 125.915 220.259 125.223 219.419 125.223Z" />
        <path d="M201.491 125.223C200.677 125.223 200.01 125.89 200.01 126.706C200.01 127.521 200.677 128.188 201.491 128.188C202.306 128.188 202.973 127.521 202.973 126.706C202.973 125.915 202.306 125.223 201.491 125.223Z" />
        <path d="M183.541 125.223C182.727 125.223 182.06 125.89 182.06 126.706C182.06 127.521 182.727 128.188 183.541 128.188C184.356 128.188 185.023 127.521 185.023 126.706C185.048 125.915 184.381 125.223 183.541 125.223Z" />
        <path d="M165.614 125.223C164.799 125.223 164.132 125.89 164.132 126.706C164.132 127.521 164.799 128.188 165.614 128.188C166.429 128.188 167.095 127.521 167.095 126.706C167.095 125.915 166.429 125.223 165.614 125.223Z" />
        <path d="M147.664 125.224C146.849 125.224 146.182 125.891 146.182 126.706C146.182 127.521 146.849 128.188 147.664 128.188C148.479 128.188 149.146 127.521 149.146 126.706C149.17 125.915 148.504 125.224 147.664 125.224Z" />
        <path d="M129.736 125.223C128.921 125.223 128.254 125.89 128.254 126.706C128.254 127.521 128.921 128.188 129.736 128.188C130.551 128.188 131.218 127.521 131.218 126.706C131.218 125.915 130.551 125.223 129.736 125.223Z" />
        <path d="M111.786 125.223C110.971 125.223 110.305 125.89 110.305 126.706C110.305 127.521 110.971 128.188 111.786 128.188C112.601 128.188 113.268 127.521 113.268 126.706C113.292 125.915 112.601 125.223 111.786 125.223Z" />
        <path d="M93.8584 125.223C93.0436 125.223 92.3769 125.89 92.3769 126.706C92.3769 127.521 93.0436 128.188 93.8584 128.188C94.6732 128.188 95.3398 127.521 95.3398 126.706C95.3398 125.915 94.6732 125.223 93.8584 125.223Z" />
        <path d="M75.9074 125.224C75.0925 125.224 74.4258 125.891 74.4258 126.706C74.4258 127.521 75.0925 128.188 75.9074 128.188C76.7222 128.188 77.3889 127.521 77.3889 126.706C77.3889 125.915 76.7222 125.224 75.9074 125.224Z" />
        <path d="M57.9806 125.223C57.1658 125.223 56.4991 125.89 56.4991 126.706C56.4991 127.521 57.1658 128.188 57.9806 128.188C58.7954 128.188 59.4622 127.521 59.4622 126.706C59.4622 125.915 58.7955 125.223 57.9806 125.223Z" />
        <path d="M40.0294 125.223C39.2146 125.223 38.5478 125.89 38.5478 126.706C38.5478 127.521 39.2146 128.188 40.0294 128.188C40.8443 128.188 41.511 127.521 41.511 126.706C41.511 125.915 40.8443 125.223 40.0294 125.223Z" />
        <path d="M22.0761 125.223C21.2613 125.223 20.5946 125.89 20.5946 126.706C20.5946 127.521 21.2613 128.188 22.0761 128.188C22.891 128.188 23.5577 127.521 23.5577 126.706C23.5824 125.915 22.9157 125.223 22.0761 125.223Z" />
        <path d="M4.15007 125.223C3.33525 125.223 2.66856 125.89 2.66856 126.706C2.66856 127.521 3.33525 128.188 4.15007 128.188C4.9649 128.188 5.63159 127.521 5.63159 126.706C5.63159 125.915 4.9649 125.223 4.15007 125.223Z" />
        <path d="M945.971 107.485C945.261 107.485 944.687 108.06 944.687 108.77C944.687 109.479 945.261 110.055 945.971 110.055C946.68 110.055 947.255 109.479 947.255 108.77C947.255 108.06 946.68 107.485 945.971 107.485Z" />
        <path d="M928.046 107.485C927.337 107.485 926.762 108.06 926.762 108.77C926.762 109.479 927.337 110.055 928.046 110.055C928.755 110.055 929.33 109.479 929.33 108.77C929.33 108.06 928.755 107.485 928.046 107.485Z" />
        <path d="M910.095 107.485C909.386 107.485 908.811 108.06 908.811 108.77C908.811 109.479 909.386 110.055 910.095 110.055C910.804 110.055 911.379 109.479 911.379 108.77C911.379 108.06 910.804 107.485 910.095 107.485Z" />
        <path d="M892.168 107.485C891.459 107.485 890.884 108.06 890.884 108.77C890.884 109.479 891.459 110.055 892.168 110.055C892.877 110.055 893.452 109.479 893.452 108.77C893.452 108.06 892.877 107.485 892.168 107.485Z" />
        <path d="M874.215 107.485C873.506 107.485 872.931 108.06 872.931 108.77C872.931 109.479 873.506 110.055 874.215 110.055C874.924 110.055 875.499 109.479 875.499 108.77C875.499 108.06 874.924 107.485 874.215 107.485Z" />
        <path d="M856.29 107.485C855.581 107.485 855.006 108.06 855.006 108.77C855.006 109.479 855.581 110.055 856.29 110.055C856.999 110.055 857.574 109.479 857.574 108.77C857.574 108.06 856.999 107.485 856.29 107.485Z" />
        <path d="M838.338 107.485C837.629 107.485 837.054 108.06 837.054 108.77C837.054 109.479 837.629 110.055 838.338 110.055C839.047 110.055 839.622 109.479 839.622 108.77C839.622 108.06 839.047 107.485 838.338 107.485Z" />
        <path d="M820.411 107.485C819.702 107.485 819.127 108.06 819.127 108.77C819.127 109.479 819.702 110.055 820.411 110.055C821.12 110.055 821.695 109.479 821.695 108.77C821.695 108.06 821.12 107.485 820.411 107.485Z" />
        <path d="M802.46 107.485C801.751 107.485 801.176 108.06 801.176 108.77C801.176 109.479 801.751 110.055 802.46 110.055C803.169 110.055 803.744 109.479 803.744 108.77C803.744 108.06 803.169 107.485 802.46 107.485Z" />
        <path d="M784.531 107.485C783.822 107.485 783.247 108.06 783.247 108.77C783.247 109.479 783.822 110.055 784.531 110.055C785.241 110.055 785.815 109.479 785.815 108.77C785.815 108.06 785.241 107.485 784.531 107.485Z" />
        <path d="M766.582 107.485C765.873 107.485 765.298 108.06 765.298 108.77C765.298 109.479 765.873 110.055 766.582 110.055C767.291 110.055 767.866 109.479 767.866 108.77C767.866 108.06 767.291 107.485 766.582 107.485Z" />
        <path d="M748.654 107.485C747.945 107.485 747.37 108.06 747.37 108.77C747.37 109.479 747.945 110.055 748.654 110.055C749.363 110.055 749.938 109.479 749.938 108.77C749.938 108.06 749.363 107.485 748.654 107.485Z" />
        <path d="M730.703 107.485C729.994 107.485 729.419 108.06 729.419 108.77C729.419 109.479 729.994 110.055 730.703 110.055C731.412 110.055 731.987 109.479 731.987 108.77C731.987 108.06 731.412 107.485 730.703 107.485Z" />
        <path d="M712.776 107.485C712.067 107.485 711.492 108.06 711.492 108.77C711.492 109.479 712.067 110.055 712.776 110.055C713.485 110.055 714.06 109.479 714.06 108.77C714.06 108.06 713.485 107.485 712.776 107.485Z" />
        <path d="M694.825 107.485C694.116 107.485 693.541 108.06 693.541 108.77C693.541 109.479 694.116 110.055 694.825 110.055C695.534 110.055 696.109 109.479 696.109 108.77C696.109 108.06 695.534 107.485 694.825 107.485Z" />
        <path d="M676.872 107.485C676.163 107.485 675.588 108.06 675.588 108.77C675.588 109.479 676.163 110.055 676.872 110.055C677.582 110.055 678.156 109.479 678.156 108.77C678.156 108.06 677.582 107.485 676.872 107.485Z" />
        <path d="M658.947 107.485C658.238 107.485 657.663 108.06 657.663 108.77C657.663 109.479 658.238 110.055 658.947 110.055C659.657 110.055 660.231 109.479 660.231 108.77C660.231 108.06 659.657 107.485 658.947 107.485Z" />
        <path d="M640.996 107.485C640.287 107.485 639.712 108.06 639.712 108.77C639.712 109.479 640.287 110.055 640.996 110.055C641.706 110.055 642.28 109.479 642.28 108.77C642.28 108.06 641.706 107.485 640.996 107.485Z" />
        <path d="M623.07 107.485C622.361 107.485 621.786 108.06 621.786 108.77C621.786 109.479 622.361 110.055 623.07 110.055C623.779 110.055 624.354 109.479 624.354 108.77C624.354 108.06 623.779 107.485 623.07 107.485Z" />
        <path d="M605.119 107.485C604.41 107.485 603.835 108.06 603.835 108.77C603.835 109.479 604.41 110.055 605.119 110.055C605.828 110.055 606.403 109.479 606.403 108.77C606.403 108.06 605.828 107.485 605.119 107.485Z" />
        <path d="M587.19 107.485C586.481 107.485 585.906 108.06 585.906 108.77C585.906 109.479 586.481 110.055 587.19 110.055C587.899 110.055 588.474 109.479 588.474 108.77C588.474 108.06 587.899 107.485 587.19 107.485Z" />
        <path d="M569.237 107.485C568.528 107.485 567.953 108.06 567.953 108.77C567.953 109.479 568.528 110.055 569.237 110.055C569.947 110.055 570.521 109.479 570.521 108.77C570.521 108.06 569.947 107.485 569.237 107.485Z" />
        <path d="M551.313 107.485C550.603 107.485 550.029 108.06 550.029 108.77C550.029 109.479 550.603 110.055 551.313 110.055C552.022 110.055 552.597 109.479 552.597 108.77C552.597 108.06 552.022 107.485 551.313 107.485Z" />
        <path d="M533.36 107.485C532.651 107.485 532.076 108.06 532.076 108.77C532.076 109.479 532.651 110.055 533.36 110.055C534.069 110.055 534.644 109.479 534.644 108.77C534.644 108.06 534.069 107.485 533.36 107.485Z" />
        <path d="M515.433 107.485C514.724 107.485 514.149 108.06 514.149 108.77C514.149 109.479 514.724 110.055 515.433 110.055C516.142 110.055 516.717 109.479 516.717 108.77C516.717 108.06 516.142 107.485 515.433 107.485Z" />
        <path d="M497.482 107.485C496.773 107.485 496.198 108.06 496.198 108.77C496.198 109.479 496.773 110.055 497.482 110.055C498.192 110.055 498.766 109.479 498.766 108.77C498.766 108.06 498.192 107.485 497.482 107.485Z" />
        <path d="M479.554 107.485C478.845 107.485 478.27 108.06 478.27 108.77C478.27 109.479 478.845 110.055 479.554 110.055C480.263 110.055 480.838 109.479 480.838 108.77C480.838 108.06 480.263 107.485 479.554 107.485Z" />
        <path d="M461.604 107.485C460.895 107.485 460.32 108.06 460.32 108.77C460.32 109.479 460.895 110.055 461.604 110.055C462.314 110.055 462.888 109.479 462.888 108.77C462.888 108.06 462.314 107.485 461.604 107.485Z" />
        <path d="M443.676 107.485C442.967 107.485 442.392 108.06 442.392 108.77C442.392 109.479 442.967 110.055 443.676 110.055C444.385 110.055 444.96 109.479 444.96 108.77C444.96 108.06 444.385 107.485 443.676 107.485Z" />
        <path d="M425.727 107.485C425.018 107.485 424.443 108.06 424.443 108.77C424.443 109.479 425.018 110.055 425.727 110.055C426.436 110.055 427.011 109.479 427.011 108.77C427.011 108.06 426.436 107.485 425.727 107.485Z" />
        <path d="M407.799 107.485C407.09 107.485 406.515 108.06 406.515 108.77C406.515 109.479 407.09 110.055 407.799 110.055C408.508 110.055 409.083 109.479 409.083 108.77C409.083 108.06 408.508 107.485 407.799 107.485Z" />
        <path d="M389.847 107.485C389.138 107.485 388.563 108.06 388.563 108.77C388.563 109.479 389.138 110.055 389.847 110.055C390.556 110.055 391.131 109.479 391.131 108.77C391.131 108.06 390.556 107.485 389.847 107.485Z" />
        <path d="M371.921 107.485C371.211 107.485 370.637 108.06 370.637 108.77C370.637 109.479 371.211 110.055 371.921 110.055C372.63 110.055 373.205 109.479 373.205 108.77C373.205 108.06 372.63 107.485 371.921 107.485Z" />
        <path d="M353.97 107.485C353.26 107.485 352.686 108.06 352.686 108.77C352.686 109.479 353.26 110.055 353.97 110.055C354.679 110.055 355.254 109.479 355.254 108.77C355.254 108.06 354.679 107.485 353.97 107.485Z" />
        <path d="M336.043 107.485C335.334 107.485 334.759 108.06 334.759 108.77C334.759 109.479 335.334 110.055 336.043 110.055C336.752 110.055 337.327 109.479 337.327 108.77C337.327 108.06 336.752 107.485 336.043 107.485Z" />
        <path d="M318.09 107.485C317.381 107.485 316.806 108.06 316.806 108.77C316.806 109.479 317.381 110.055 318.09 110.055C318.799 110.055 319.374 109.479 319.374 108.77C319.374 108.06 318.799 107.485 318.09 107.485Z" />
        <path d="M300.165 107.485C299.456 107.485 298.881 108.06 298.881 108.77C298.881 109.479 299.456 110.055 300.165 110.055C300.874 110.055 301.449 109.479 301.449 108.77C301.449 108.06 300.874 107.485 300.165 107.485Z" />
        <path d="M282.212 107.485C281.503 107.485 280.928 108.06 280.928 108.77C280.928 109.479 281.503 110.055 282.212 110.055C282.921 110.055 283.496 109.479 283.496 108.77C283.496 108.06 282.921 107.485 282.212 107.485Z" />
        <path d="M264.286 107.485C263.577 107.485 263.002 108.06 263.002 108.77C263.002 109.479 263.577 110.055 264.286 110.055C264.995 110.055 265.57 109.479 265.57 108.77C265.57 108.06 264.995 107.485 264.286 107.485Z" />
        <path d="M246.335 107.485C245.626 107.485 245.051 108.06 245.051 108.77C245.051 109.479 245.626 110.055 246.335 110.055C247.044 110.055 247.619 109.479 247.619 108.77C247.619 108.06 247.044 107.485 246.335 107.485Z" />
        <path d="M228.384 107.485C227.675 107.485 227.1 108.06 227.1 108.77C227.1 109.479 227.675 110.055 228.384 110.055C229.093 110.055 229.668 109.479 229.668 108.77C229.668 108.06 229.093 107.485 228.384 107.485Z" />
        <path d="M210.457 107.485C209.748 107.485 209.173 108.06 209.173 108.77C209.173 109.479 209.748 110.055 210.457 110.055C211.166 110.055 211.741 109.479 211.741 108.77C211.741 108.06 211.166 107.485 210.457 107.485Z" />
        <path d="M192.504 107.485C191.795 107.485 191.22 108.06 191.22 108.77C191.22 109.479 191.795 110.055 192.504 110.055C193.213 110.055 193.788 109.479 193.788 108.77C193.788 108.06 193.213 107.485 192.504 107.485Z" />
        <path d="M174.579 107.485C173.87 107.485 173.295 108.06 173.295 108.77C173.295 109.479 173.87 110.055 174.579 110.055C175.289 110.055 175.864 109.479 175.864 108.77C175.864 108.06 175.289 107.485 174.579 107.485Z" />
        <path d="M156.627 107.485C155.917 107.485 155.343 108.06 155.343 108.77C155.343 109.479 155.917 110.055 156.627 110.055C157.336 110.055 157.911 109.479 157.911 108.77C157.911 108.06 157.336 107.485 156.627 107.485Z" />
        <path d="M138.702 107.485C137.993 107.485 137.418 108.06 137.418 108.77C137.418 109.479 137.993 110.055 138.702 110.055C139.411 110.055 139.986 109.479 139.986 108.77C139.986 108.06 139.411 107.485 138.702 107.485Z" />
        <path d="M120.749 107.485C120.04 107.485 119.465 108.06 119.465 108.77C119.465 109.479 120.04 110.055 120.749 110.055C121.458 110.055 122.033 109.479 122.033 108.77C122.033 108.06 121.458 107.485 120.749 107.485Z" />
        <path d="M102.822 107.485C102.113 107.485 101.538 108.06 101.538 108.77C101.538 109.479 102.113 110.055 102.822 110.055C103.531 110.055 104.106 109.479 104.106 108.77C104.106 108.06 103.531 107.485 102.822 107.485Z" />
        <path d="M84.8696 107.485C84.1604 107.485 83.5856 108.06 83.5856 108.77C83.5856 109.479 84.1604 110.055 84.8696 110.055C85.5787 110.055 86.1536 109.479 86.1536 108.77C86.1536 108.06 85.5787 107.485 84.8696 107.485Z" />
        <path d="M66.9428 107.485C66.2337 107.485 65.6588 108.06 65.6588 108.77C65.6588 109.479 66.2337 110.055 66.9428 110.055C67.6519 110.055 68.2268 109.479 68.2268 108.77C68.2268 108.06 67.6519 107.485 66.9428 107.485Z" />
        <path d="M48.9917 107.485C48.2825 107.485 47.7077 108.06 47.7077 108.77C47.7077 109.479 48.2825 110.055 48.9917 110.055C49.7008 110.055 50.2756 109.479 50.2756 108.77C50.2756 108.06 49.7008 107.485 48.9917 107.485Z" />
        <path d="M31.065 107.485C30.3558 107.485 29.7809 108.06 29.7809 108.77C29.7809 109.479 30.3558 110.055 31.065 110.055C31.7742 110.055 32.3491 109.479 32.3491 108.77C32.3491 108.06 31.7742 107.485 31.065 107.485Z" />
        <path d="M13.1144 107.485C12.4052 107.485 11.8303 108.06 11.8303 108.77C11.8303 109.479 12.4052 110.055 13.1144 110.055C13.8235 110.055 14.3984 109.479 14.3984 108.77C14.3984 108.06 13.8235 107.485 13.1144 107.485Z" />
        <path d="M954.961 89.5495C954.252 89.5495 953.677 90.1247 953.677 90.8342C953.677 91.5437 954.252 92.1188 954.961 92.1188C955.67 92.1188 956.245 91.5437 956.245 90.8342C956.245 90.1247 955.67 89.5495 954.961 89.5495Z" />
        <path d="M937.008 89.5495C936.299 89.5495 935.724 90.1247 935.724 90.8342C935.724 91.5437 936.299 92.1188 937.008 92.1188C937.717 92.1188 938.292 91.5437 938.292 90.8342C938.292 90.1247 937.717 89.5495 937.008 89.5495Z" />
        <path d="M919.057 89.5495C918.348 89.5495 917.773 90.1247 917.773 90.8342C917.773 91.5437 918.348 92.1188 919.057 92.1188C919.766 92.1188 920.341 91.5437 920.341 90.8342C920.341 90.1247 919.766 89.5495 919.057 89.5495Z" />
        <path d="M901.131 89.5497C900.421 89.5497 899.847 90.1249 899.847 90.8344C899.847 91.5439 900.421 92.119 901.131 92.119C901.84 92.119 902.415 91.5439 902.415 90.8344C902.415 90.1249 901.84 89.5497 901.131 89.5497Z" />
        <path d="M883.18 89.5495C882.47 89.5495 881.896 90.1247 881.896 90.8342C881.896 91.5437 882.47 92.1188 883.18 92.1188C883.889 92.1188 884.464 91.5437 884.464 90.8342C884.464 90.1247 883.889 89.5495 883.18 89.5495Z" />
        <path d="M865.253 89.5495C864.544 89.5495 863.969 90.1247 863.969 90.8342C863.969 91.5437 864.544 92.1188 865.253 92.1188C865.962 92.1188 866.537 91.5437 866.537 90.8342C866.537 90.1247 865.962 89.5495 865.253 89.5495Z" />
        <path d="M847.302 89.5495C846.593 89.5495 846.018 90.1247 846.018 90.8342C846.018 91.5437 846.593 92.1188 847.302 92.1188C848.011 92.1188 848.586 91.5437 848.586 90.8342C848.586 90.1247 848.011 89.5495 847.302 89.5495Z" />
        <path d="M829.373 89.5495C828.664 89.5495 828.089 90.1247 828.089 90.8342C828.089 91.5437 828.664 92.1188 829.373 92.1188C830.082 92.1188 830.657 91.5437 830.657 90.8342C830.657 90.1247 830.082 89.5495 829.373 89.5495Z" />
        <path d="M811.422 89.5495C810.713 89.5495 810.138 90.1247 810.138 90.8342C810.138 91.5437 810.713 92.1188 811.422 92.1188C812.132 92.1188 812.706 91.5437 812.706 90.8342C812.706 90.1247 812.132 89.5495 811.422 89.5495Z" />
        <path d="M793.497 89.5495C792.788 89.5495 792.213 90.1247 792.213 90.8342C792.213 91.5437 792.788 92.1188 793.497 92.1188C794.207 92.1188 794.781 91.5437 794.781 90.8342C794.781 90.1247 794.207 89.5495 793.497 89.5495Z" />
        <path d="M775.545 89.5495C774.836 89.5495 774.261 90.1247 774.261 90.8342C774.261 91.5437 774.836 92.1188 775.545 92.1188C776.254 92.1188 776.829 91.5437 776.829 90.8342C776.829 90.1247 776.254 89.5495 775.545 89.5495Z" />
        <path d="M757.62 89.5495C756.911 89.5495 756.336 90.1247 756.336 90.8342C756.336 91.5437 756.911 92.1188 757.62 92.1188C758.329 92.1188 758.904 91.5437 758.904 90.8342C758.904 90.1247 758.329 89.5495 757.62 89.5495Z" />
        <path d="M739.667 89.5495C738.958 89.5495 738.383 90.1247 738.383 90.8342C738.383 91.5437 738.958 92.1188 739.667 92.1188C740.376 92.1188 740.951 91.5437 740.951 90.8342C740.951 90.1247 740.376 89.5495 739.667 89.5495Z" />
        <path d="M721.739 89.5495C721.029 89.5495 720.455 90.1247 720.455 90.8342C720.455 91.5437 721.029 92.1188 721.739 92.1188C722.448 92.1188 723.023 91.5437 723.023 90.8342C723.023 90.1247 722.448 89.5495 721.739 89.5495Z" />
        <path d="M703.789 89.5495C703.08 89.5495 702.505 90.1247 702.505 90.8342C702.505 91.5437 703.08 92.1188 703.789 92.1188C704.499 92.1188 705.073 91.5437 705.073 90.8342C705.073 90.1247 704.499 89.5495 703.789 89.5495Z" />
        <path d="M685.861 89.5495C685.152 89.5495 684.577 90.1247 684.577 90.8342C684.577 91.5437 685.152 92.1188 685.861 92.1188C686.57 92.1188 687.145 91.5437 687.145 90.8342C687.145 90.1247 686.57 89.5495 685.861 89.5495Z" />
        <path d="M667.91 89.5497C667.201 89.5497 666.626 90.1249 666.626 90.8344C666.626 91.5439 667.201 92.119 667.91 92.119C668.619 92.119 669.194 91.5439 669.194 90.8344C669.194 90.1249 668.619 89.5497 667.91 89.5497Z" />
        <path d="M649.983 89.5495C649.274 89.5495 648.699 90.1247 648.699 90.8342C648.699 91.5437 649.274 92.1188 649.983 92.1188C650.692 92.1188 651.267 91.5437 651.267 90.8342C651.267 90.1247 650.692 89.5495 649.983 89.5495Z" />
        <path d="M632.03 89.5495C631.321 89.5495 630.746 90.1247 630.746 90.8342C630.746 91.5437 631.321 92.1188 632.03 92.1188C632.74 92.1188 633.314 91.5437 633.314 90.8342C633.314 90.1247 632.74 89.5495 632.03 89.5495Z" />
        <path d="M614.106 89.5495C613.396 89.5495 612.822 90.1247 612.822 90.8342C612.822 91.5437 613.396 92.1188 614.106 92.1188C614.815 92.1188 615.39 91.5437 615.39 90.8342C615.39 90.1247 614.815 89.5495 614.106 89.5495Z" />
        <path d="M596.153 89.5495C595.444 89.5495 594.869 90.1247 594.869 90.8342C594.869 91.5437 595.444 92.1188 596.153 92.1188C596.862 92.1188 597.437 91.5437 597.437 90.8342C597.437 90.1247 596.862 89.5495 596.153 89.5495Z" />
        <path d="M578.228 89.5495C577.519 89.5495 576.944 90.1247 576.944 90.8342C576.944 91.5437 577.519 92.1188 578.228 92.1188C578.937 92.1188 579.512 91.5437 579.512 90.8342C579.512 90.1247 578.937 89.5495 578.228 89.5495Z" />
        <path d="M560.275 89.5495C559.566 89.5495 558.991 90.1247 558.991 90.8342C558.991 91.5437 559.566 92.1188 560.275 92.1188C560.984 92.1188 561.559 91.5437 561.559 90.8342C561.559 90.1247 560.984 89.5495 560.275 89.5495Z" />
        <path d="M542.348 89.5495C541.639 89.5495 541.064 90.1247 541.064 90.8342C541.064 91.5437 541.639 92.1188 542.348 92.1188C543.057 92.1188 543.632 91.5437 543.632 90.8342C543.632 90.1247 543.057 89.5495 542.348 89.5495Z" />
        <path d="M524.397 89.5495C523.688 89.5495 523.113 90.1247 523.113 90.8342C523.113 91.5437 523.688 92.1188 524.397 92.1188C525.107 92.1188 525.681 91.5437 525.681 90.8342C525.681 90.1247 525.107 89.5495 524.397 89.5495Z" />
        <path d="M506.471 89.5496C505.762 89.5496 505.187 90.1248 505.187 90.8343C505.187 91.5438 505.762 92.119 506.471 92.119C507.18 92.119 507.755 91.5438 507.755 90.8343C507.755 90.1248 507.18 89.5496 506.471 89.5496Z" />
        <path d="M488.52 89.5495C487.81 89.5495 487.236 90.1247 487.236 90.8342C487.236 91.5437 487.81 92.1188 488.52 92.1188C489.229 92.1188 489.804 91.5437 489.804 90.8342C489.804 90.1247 489.229 89.5495 488.52 89.5495Z" />
        <path d="M470.591 89.5495C469.882 89.5495 469.307 90.1247 469.307 90.8342C469.307 91.5437 469.882 92.1188 470.591 92.1188C471.3 92.1188 471.875 91.5437 471.875 90.8342C471.875 90.1247 471.3 89.5495 470.591 89.5495Z" />
        <path d="M452.64 89.5495C451.931 89.5495 451.356 90.1247 451.356 90.8342C451.356 91.5437 451.931 92.1188 452.64 92.1188C453.349 92.1188 453.924 91.5437 453.924 90.8342C453.924 90.1247 453.349 89.5495 452.64 89.5495Z" />
        <path d="M434.689 89.5496C433.98 89.5496 433.405 90.1248 433.405 90.8343C433.405 91.5438 433.98 92.119 434.689 92.119C435.398 92.119 435.973 91.5438 435.973 90.8343C435.973 90.1248 435.398 89.5496 434.689 89.5496Z" />
        <path d="M416.763 89.5496C416.054 89.5496 415.479 90.1248 415.479 90.8343C415.479 91.5438 416.054 92.119 416.763 92.119C417.472 92.119 418.047 91.5438 418.047 90.8343C418.047 90.1248 417.472 89.5496 416.763 89.5496Z" />
        <path d="M398.812 89.5495C398.102 89.5495 397.528 90.1247 397.528 90.8342C397.528 91.5437 398.102 92.1188 398.812 92.1188C399.521 92.1188 400.096 91.5437 400.096 90.8342C400.096 90.1247 399.521 89.5495 398.812 89.5495Z" />
        <path d="M380.885 89.5495C380.176 89.5495 379.601 90.1247 379.601 90.8342C379.601 91.5437 380.176 92.1188 380.885 92.1188C381.594 92.1188 382.169 91.5437 382.169 90.8342C382.169 90.1247 381.594 89.5495 380.885 89.5495Z" />
        <path d="M362.932 89.5495C362.223 89.5495 361.648 90.1247 361.648 90.8342C361.648 91.5437 362.223 92.1188 362.932 92.1188C363.641 92.1188 364.216 91.5437 364.216 90.8342C364.216 90.1247 363.641 89.5495 362.932 89.5495Z" />
        <path d="M345.007 89.5495C344.298 89.5495 343.723 90.1247 343.723 90.8342C343.723 91.5437 344.298 92.1188 345.007 92.1188C345.716 92.1188 346.291 91.5437 346.291 90.8342C346.291 90.1247 345.716 89.5495 345.007 89.5495Z" />
        <path d="M327.053 89.5495C326.344 89.5495 325.769 90.1247 325.769 90.8342C325.769 91.5437 326.344 92.1188 327.053 92.1188C327.762 92.1188 328.337 91.5437 328.337 90.8342C328.337 90.1247 327.762 89.5495 327.053 89.5495Z" />
        <path d="M309.128 89.5495C308.418 89.5495 307.844 90.1247 307.844 90.8342C307.844 91.5437 308.418 92.1188 309.128 92.1188C309.837 92.1188 310.412 91.5437 310.412 90.8342C310.412 90.1247 309.837 89.5495 309.128 89.5495Z" />
        <path d="M291.175 89.5495C290.466 89.5495 289.891 90.1247 289.891 90.8342C289.891 91.5437 290.466 92.1188 291.175 92.1188C291.884 92.1188 292.459 91.5437 292.459 90.8342C292.459 90.1247 291.884 89.5495 291.175 89.5495Z" />
        <path d="M273.248 89.5495C272.539 89.5495 271.964 90.1247 271.964 90.8342C271.964 91.5437 272.539 92.1188 273.248 92.1188C273.957 92.1188 274.532 91.5437 274.532 90.8342C274.532 90.1247 273.957 89.5495 273.248 89.5495Z" />
        <path d="M255.297 89.5495C254.588 89.5495 254.013 90.1246 254.013 90.8341C254.013 91.5436 254.588 92.1188 255.297 92.1188C256.006 92.1188 256.581 91.5436 256.581 90.8341C256.581 90.1246 256.006 89.5495 255.297 89.5495Z" />
        <path d="M237.371 89.5495C236.661 89.5495 236.087 90.1246 236.087 90.8341C236.087 91.5436 236.661 92.1188 237.371 92.1188C238.08 92.1188 238.655 91.5436 238.655 90.8341C238.655 90.1246 238.08 89.5495 237.371 89.5495Z" />
        <path d="M219.42 89.5495C218.71 89.5495 218.136 90.1246 218.136 90.8341C218.136 91.5436 218.71 92.1188 219.42 92.1188C220.129 92.1188 220.704 91.5436 220.704 90.8341C220.704 90.1246 220.129 89.5495 219.42 89.5495Z" />
        <path d="M201.493 89.5496C200.784 89.5496 200.209 90.1248 200.209 90.8343C200.209 91.5438 200.784 92.119 201.493 92.119C202.202 92.119 202.777 91.5438 202.777 90.8343C202.777 90.1248 202.202 89.5496 201.493 89.5496Z" />
        <path d="M183.542 89.5496C182.833 89.5496 182.258 90.1248 182.258 90.8343C182.258 91.5438 182.833 92.119 183.542 92.119C184.251 92.119 184.826 91.5438 184.826 90.8343C184.826 90.1248 184.251 89.5496 183.542 89.5496Z" />
        <path d="M165.615 89.5248C164.899 89.5248 164.331 90.093 164.331 90.8094C164.331 91.5258 164.899 92.0941 165.615 92.0941C166.331 92.0941 166.899 91.5258 166.899 90.8094C166.899 90.1177 166.331 89.5248 165.615 89.5248Z" />
        <path d="M147.664 89.5495C146.955 89.5495 146.38 90.1246 146.38 90.8341C146.38 91.5436 146.955 92.1188 147.664 92.1188C148.373 92.1188 148.948 91.5436 148.948 90.8341C148.948 90.1246 148.373 89.5495 147.664 89.5495Z" />
        <path d="M129.736 89.5495C129.027 89.5495 128.452 90.1246 128.452 90.8341C128.452 91.5436 129.027 92.1188 129.736 92.1188C130.445 92.1188 131.02 91.5436 131.02 90.8341C131.02 90.1246 130.445 89.5495 129.736 89.5495Z" />
        <path d="M111.785 89.5496C111.076 89.5496 110.501 90.1248 110.501 90.8343C110.501 91.5438 111.076 92.119 111.785 92.119C112.494 92.119 113.069 91.5438 113.069 90.8343C113.069 90.1248 112.494 89.5496 111.785 89.5496Z" />
        <path d="M93.8583 89.5496C93.1492 89.5496 92.5742 90.1248 92.5742 90.8343C92.5742 91.5438 93.1492 92.119 93.8583 92.119C94.5675 92.119 95.1423 91.5438 95.1423 90.8343C95.1423 90.1248 94.5675 89.5496 93.8583 89.5496Z" />
        <path d="M75.9071 89.5495C75.1979 89.5495 74.623 90.1246 74.623 90.8341C74.623 91.5436 75.1979 92.1188 75.9071 92.1188C76.6162 92.1188 77.1912 91.5436 77.1912 90.8341C77.1912 90.1246 76.6162 89.5495 75.9071 89.5495Z" />
        <path d="M57.9803 89.5495C57.2712 89.5495 56.6963 90.1246 56.6963 90.8341C56.6963 91.5436 57.2712 92.1188 57.9803 92.1188C58.6895 92.1188 59.2644 91.5436 59.2644 90.8341C59.2644 90.1246 58.6895 89.5495 57.9803 89.5495Z" />
        <path d="M40.0278 89.5495C39.3187 89.5495 38.7438 90.1246 38.7438 90.8341C38.7438 91.5436 39.3187 92.1188 40.0278 92.1188C40.7369 92.1188 41.3118 91.5436 41.3118 90.8341C41.3118 90.1246 40.7369 89.5495 40.0278 89.5495Z" />
        <path d="M22.0768 89.5496C21.3677 89.5496 20.7928 90.1248 20.7928 90.8343C20.7928 91.5438 21.3677 92.119 22.0768 92.119C22.786 92.119 23.3608 91.5438 23.3608 90.8343C23.3608 90.1248 22.786 89.5496 22.0768 89.5496Z" />
        <path d="M4.15035 89.5496C3.44121 89.5496 2.86637 90.1248 2.86637 90.8343C2.86637 91.5438 3.44121 92.119 4.15035 92.119C4.85948 92.119 5.43433 91.5438 5.43433 90.8343C5.43433 90.1248 4.85948 89.5496 4.15035 89.5496Z" />
        <path d="M945.974 72.0334C945.504 72.0334 945.134 72.404 945.134 72.8734C945.134 73.3428 945.504 73.7134 945.974 73.7134C946.443 73.7134 946.813 73.3428 946.813 72.8734C946.838 72.404 946.443 72.0334 945.974 72.0334Z" />
        <path d="M928.046 72.0334C927.577 72.0334 927.207 72.404 927.207 72.8734C927.207 73.3428 927.577 73.7134 928.046 73.7134C928.515 73.7134 928.886 73.3428 928.886 72.8734C928.886 72.404 928.515 72.0334 928.046 72.0334Z" />
        <path d="M910.094 72.0334C909.624 72.0334 909.254 72.404 909.254 72.8734C909.254 73.3428 909.624 73.7134 910.094 73.7134C910.563 73.7134 910.933 73.3428 910.933 72.8734C910.933 72.404 910.563 72.0334 910.094 72.0334Z" />
        <path d="M892.169 72.0334C891.699 72.0334 891.329 72.404 891.329 72.8734C891.329 73.3428 891.699 73.7134 892.169 73.7134C892.638 73.7134 893.008 73.3428 893.008 72.8734C893.008 72.404 892.638 72.0334 892.169 72.0334Z" />
        <path d="M874.216 72.0334C873.747 72.0334 873.376 72.404 873.376 72.8734C873.376 73.3428 873.747 73.7134 874.216 73.7134C874.685 73.7134 875.055 73.3428 875.055 72.8734C875.055 72.404 874.685 72.0334 874.216 72.0334Z" />
        <path d="M856.289 72.0334C855.82 72.0334 855.45 72.404 855.45 72.8734C855.45 73.3428 855.82 73.7134 856.289 73.7134C856.758 73.7134 857.129 73.3428 857.129 72.8734C857.129 72.404 856.758 72.0334 856.289 72.0334Z" />
        <path d="M838.338 72.0334C837.869 72.0334 837.499 72.404 837.499 72.8734C837.499 73.3428 837.869 73.7134 838.338 73.7134C838.807 73.7134 839.178 73.3428 839.178 72.8734C839.178 72.404 838.807 72.0334 838.338 72.0334Z" />
        <path d="M820.412 72.0334C819.942 72.0334 819.572 72.404 819.572 72.8734C819.572 73.3428 819.942 73.7134 820.412 73.7134C820.881 73.7134 821.251 73.3428 821.251 72.8734C821.251 72.404 820.881 72.0334 820.412 72.0334Z" />
        <path d="M802.46 72.0334C801.991 72.0334 801.621 72.404 801.621 72.8734C801.621 73.3428 801.991 73.7134 802.46 73.7134C802.93 73.7134 803.3 73.3428 803.3 72.8734C803.3 72.404 802.93 72.0334 802.46 72.0334Z" />
        <path d="M784.534 72.0334C784.065 72.0334 783.694 72.404 783.694 72.8734C783.694 73.3428 784.065 73.7134 784.534 73.7134C785.003 73.7134 785.373 73.3428 785.373 72.8734C785.373 72.404 785.003 72.0334 784.534 72.0334Z" />
        <path d="M766.581 72.0334C766.112 72.0334 765.741 72.404 765.741 72.8734C765.741 73.3428 766.112 73.7134 766.581 73.7134C767.05 73.7134 767.421 73.3428 767.421 72.8734C767.421 72.404 767.05 72.0334 766.581 72.0334Z" />
        <path d="M748.654 72.0334C748.185 72.0334 747.815 72.404 747.815 72.8734C747.815 73.3428 748.185 73.7134 748.654 73.7134C749.123 73.7134 749.494 73.3428 749.494 72.8734C749.494 72.404 749.099 72.0334 748.654 72.0334Z" />
        <path d="M730.703 72.0334C730.234 72.0334 729.864 72.404 729.864 72.8734C729.864 73.3428 730.234 73.7134 730.703 73.7134C731.172 73.7134 731.543 73.3428 731.543 72.8734C731.543 72.404 731.172 72.0334 730.703 72.0334Z" />
        <path d="M712.777 72.0334C712.308 72.0334 711.937 72.404 711.937 72.8734C711.937 73.3428 712.308 73.7134 712.777 73.7134C713.246 73.7134 713.616 73.3428 713.616 72.8734C713.616 72.404 713.221 72.0334 712.777 72.0334Z" />
        <path d="M694.824 72.0334C694.355 72.0334 693.984 72.404 693.984 72.8734C693.984 73.3428 694.355 73.7134 694.824 73.7134C695.293 73.7134 695.663 73.3428 695.663 72.8734C695.663 72.404 695.293 72.0334 694.824 72.0334Z" />
        <path d="M676.873 72.0334C676.404 72.0334 676.034 72.404 676.034 72.8734C676.034 73.3428 676.404 73.7134 676.873 73.7134C677.343 73.7134 677.713 73.3428 677.713 72.8734C677.738 72.404 677.343 72.0334 676.873 72.0334Z" />
        <path d="M658.946 72.0334C658.477 72.0334 658.107 72.404 658.107 72.8734C658.107 73.3428 658.477 73.7134 658.946 73.7134C659.415 73.7134 659.786 73.3428 659.786 72.8734C659.786 72.404 659.415 72.0334 658.946 72.0334Z" />
        <path d="M640.996 72.0334C640.527 72.0334 640.156 72.404 640.156 72.8734C640.156 73.3428 640.527 73.7134 640.996 73.7134C641.465 73.7134 641.835 73.3428 641.835 72.8734C641.86 72.404 641.465 72.0334 640.996 72.0334Z" />
        <path d="M623.069 72.0334C622.599 72.0334 622.229 72.404 622.229 72.8734C622.229 73.3428 622.599 73.7134 623.069 73.7134C623.538 73.7134 623.908 73.3428 623.908 72.8734C623.908 72.404 623.538 72.0334 623.069 72.0334Z" />
        <path d="M605.118 72.0334C604.649 72.0334 604.279 72.404 604.279 72.8734C604.279 73.3428 604.649 73.7134 605.118 73.7134C605.587 73.7134 605.958 73.3428 605.958 72.8734C605.982 72.404 605.587 72.0334 605.118 72.0334Z" />
        <path d="M587.191 72.0334C586.722 72.0334 586.351 72.404 586.351 72.8734C586.351 73.3428 586.722 73.7134 587.191 73.7134C587.66 73.7134 588.03 73.3428 588.03 72.8734C588.03 72.404 587.66 72.0334 587.191 72.0334Z" />
        <path d="M569.24 72.0334C568.771 72.0334 568.401 72.404 568.401 72.8734C568.401 73.3428 568.771 73.7134 569.24 73.7134C569.71 73.7134 570.08 73.3428 570.08 72.8734C570.105 72.404 569.71 72.0334 569.24 72.0334Z" />
        <path d="M551.313 72.0334C550.844 72.0334 550.474 72.404 550.474 72.8734C550.474 73.3428 550.844 73.7134 551.313 73.7134C551.782 73.7134 552.153 73.3428 552.153 72.8734C552.153 72.404 551.782 72.0334 551.313 72.0334Z" />
        <path d="M533.359 72.0334C532.89 72.0334 532.52 72.404 532.52 72.8734C532.52 73.3428 532.89 73.7134 533.359 73.7134C533.828 73.7134 534.199 73.3428 534.199 72.8734C534.224 72.404 533.828 72.0334 533.359 72.0334Z" />
        <path d="M515.434 72.0334C514.965 72.0334 514.594 72.404 514.594 72.8734C514.594 73.3428 514.965 73.7134 515.434 73.7134C515.903 73.7134 516.273 73.3428 516.273 72.8734C516.273 72.404 515.903 72.0334 515.434 72.0334Z" />
        <path d="M497.483 72.0334C497.014 72.0334 496.644 72.404 496.644 72.8734C496.644 73.3428 497.014 73.7134 497.483 73.7134C497.953 73.7134 498.323 73.3428 498.323 72.8734C498.348 72.404 497.953 72.0334 497.483 72.0334Z" />
        <path d="M479.554 72.0334C479.085 72.0334 478.715 72.404 478.715 72.8734C478.715 73.3428 479.085 73.7134 479.554 73.7134C480.023 73.7134 480.394 73.3428 480.394 72.8734C480.394 72.404 480.023 72.0334 479.554 72.0334Z" />
        <path d="M461.603 72.0334C461.134 72.0334 460.764 72.404 460.764 72.8734C460.764 73.3428 461.134 73.7134 461.603 73.7134C462.072 73.7134 462.443 73.3428 462.443 72.8734C462.443 72.404 462.072 72.0334 461.603 72.0334Z" />
        <path d="M443.677 72.0334C443.207 72.0334 442.837 72.404 442.837 72.8734C442.837 73.3428 443.207 73.7134 443.677 73.7134C444.146 73.7134 444.516 73.3428 444.516 72.8734C444.516 72.404 444.146 72.0334 443.677 72.0334Z" />
        <path d="M425.725 72.0334C425.256 72.0334 424.886 72.404 424.886 72.8734C424.886 73.3428 425.256 73.7134 425.725 73.7134C426.195 73.7134 426.565 73.3428 426.565 72.8734C426.565 72.404 426.195 72.0334 425.725 72.0334Z" />
        <path d="M407.799 72.0334C407.33 72.0334 406.959 72.404 406.959 72.8734C406.959 73.3428 407.33 73.7134 407.799 73.7134C408.268 73.7134 408.638 73.3428 408.638 72.8734C408.638 72.404 408.268 72.0334 407.799 72.0334Z" />
        <path d="M389.848 72.0334C389.379 72.0334 389.008 72.404 389.008 72.8734C389.008 73.3428 389.379 73.7134 389.848 73.7134C390.317 73.7134 390.687 73.3428 390.687 72.8734C390.687 72.404 390.317 72.0334 389.848 72.0334Z" />
        <path d="M371.921 72.0333C371.452 72.0333 371.082 72.4039 371.082 72.8733C371.082 73.3427 371.452 73.7134 371.921 73.7134C372.39 73.7134 372.761 73.3427 372.761 72.8733C372.761 72.4039 372.39 72.0333 371.921 72.0333Z" />
        <path d="M353.968 72.0334C353.499 72.0334 353.129 72.404 353.129 72.8734C353.129 73.3428 353.499 73.7134 353.968 73.7134C354.438 73.7134 354.808 73.3428 354.808 72.8734C354.808 72.404 354.438 72.0334 353.968 72.0334Z" />
        <path d="M336.044 72.0334C335.574 72.0334 335.204 72.404 335.204 72.8734C335.204 73.3428 335.574 73.7134 336.044 73.7134C336.513 73.7134 336.883 73.3428 336.883 72.8734C336.883 72.404 336.513 72.0334 336.044 72.0334Z" />
        <path d="M318.091 72.0334C317.622 72.0334 317.251 72.404 317.251 72.8734C317.251 73.3428 317.622 73.7134 318.091 73.7134C318.56 73.7134 318.93 73.3428 318.93 72.8734C318.93 72.404 318.56 72.0334 318.091 72.0334Z" />
        <path d="M300.164 72.0334C299.695 72.0334 299.325 72.404 299.325 72.8734C299.325 73.3428 299.695 73.7134 300.164 73.7134C300.633 73.7134 301.004 73.3428 301.004 72.8734C301.004 72.404 300.609 72.0334 300.164 72.0334Z" />
        <path d="M282.213 72.0334C281.744 72.0334 281.373 72.404 281.373 72.8734C281.373 73.3428 281.744 73.7134 282.213 73.7134C282.682 73.7134 283.052 73.3428 283.052 72.8734C283.052 72.404 282.682 72.0334 282.213 72.0334Z" />
        <path d="M264.286 72.0334C263.817 72.0334 263.447 72.404 263.447 72.8734C263.447 73.3428 263.817 73.7134 264.286 73.7134C264.756 73.7134 265.126 73.3428 265.126 72.8734C265.126 72.404 264.731 72.0334 264.286 72.0334Z" />
        <path d="M246.335 72.0333C245.866 72.0333 245.496 72.4039 245.496 72.8733C245.496 73.3427 245.866 73.7134 246.335 73.7134C246.804 73.7134 247.175 73.3427 247.175 72.8733C247.175 72.4039 246.804 72.0333 246.335 72.0333Z" />
        <path d="M228.383 72.0333C227.914 72.0333 227.544 72.4039 227.544 72.8733C227.544 73.3427 227.914 73.7134 228.383 73.7134C228.852 73.7134 229.223 73.3427 229.223 72.8733C229.247 72.4039 228.852 72.0333 228.383 72.0333Z" />
        <path d="M210.457 72.0333C209.988 72.0333 209.618 72.4039 209.618 72.8733C209.618 73.3427 209.988 73.7134 210.457 73.7134C210.927 73.7134 211.297 73.3427 211.297 72.8733C211.297 72.4039 210.927 72.0333 210.457 72.0333Z" />
        <path d="M192.504 72.0333C192.035 72.0333 191.664 72.4039 191.664 72.8733C191.664 73.3427 192.035 73.7134 192.504 73.7134C192.973 73.7134 193.343 73.3427 193.343 72.8733C193.368 72.4039 192.973 72.0333 192.504 72.0333Z" />
        <path d="M174.576 72.0333C174.107 72.0333 173.737 72.4039 173.737 72.8733C173.737 73.3427 174.107 73.7134 174.576 73.7134C175.046 73.7134 175.416 73.3427 175.416 72.8733C175.416 72.4039 175.046 72.0333 174.576 72.0333Z" />
        <path d="M156.626 72.0333C156.157 72.0333 155.787 72.4039 155.787 72.8733C155.787 73.3427 156.157 73.7134 156.626 73.7134C157.095 73.7134 157.466 73.3427 157.466 72.8733C157.49 72.4039 157.095 72.0333 156.626 72.0333Z" />
        <path d="M138.699 72.0333C138.23 72.0333 137.859 72.4039 137.859 72.8733C137.859 73.3427 138.23 73.7134 138.699 73.7134C139.168 73.7134 139.538 73.3427 139.538 72.8733C139.538 72.4039 139.168 72.0333 138.699 72.0333Z" />
        <path d="M120.748 72.0333C120.279 72.0333 119.909 72.4039 119.909 72.8733C119.909 73.3427 120.279 73.7134 120.748 73.7134C121.218 73.7134 121.588 73.3427 121.588 72.8733C121.613 72.4039 121.218 72.0333 120.748 72.0333Z" />
        <path d="M102.821 72.0333C102.352 72.0333 101.982 72.4039 101.982 72.8733C101.982 73.3427 102.352 73.7134 102.821 73.7134C103.29 73.7134 103.661 73.3427 103.661 72.8733C103.661 72.4039 103.29 72.0333 102.821 72.0333Z" />
        <path d="M84.8708 72.0333C84.4017 72.0333 84.0313 72.4039 84.0313 72.8733C84.0313 73.3427 84.4017 73.7134 84.8708 73.7134C85.34 73.7134 85.7103 73.3427 85.7103 72.8733C85.735 72.4039 85.34 72.0333 84.8708 72.0333Z" />
        <path d="M66.9432 72.0333C66.474 72.0333 66.1036 72.4039 66.1036 72.8733C66.1036 73.3427 66.474 73.7134 66.9432 73.7134C67.4123 73.7134 67.7827 73.3427 67.7827 72.8733C67.7827 72.4039 67.4123 72.0333 66.9432 72.0333Z" />
        <path d="M48.9931 72.0333C48.524 72.0333 48.1536 72.4039 48.1536 72.8733C48.1536 73.3427 48.524 73.7134 48.9931 73.7134C49.4623 73.7134 49.8326 73.3427 49.8326 72.8733C49.8573 72.4039 49.4623 72.0333 48.9931 72.0333Z" />
        <path d="M31.0657 72.0333C30.5966 72.0333 30.2262 72.4039 30.2262 72.8733C30.2262 73.3427 30.5966 73.7134 31.0657 73.7134C31.5349 73.7134 31.9053 73.3427 31.9053 72.8733C31.9053 72.4039 31.5349 72.0333 31.0657 72.0333Z" />
        <path d="M13.1148 72.0333C12.6456 72.0333 12.2752 72.4039 12.2752 72.8733C12.2752 73.3427 12.6456 73.7134 13.1148 73.7134C13.5839 73.7134 13.9543 73.3427 13.9543 72.8733C13.9543 72.4039 13.5839 72.0333 13.1148 72.0333Z" />
        <path d="M954.96 54.2953C954.605 54.2953 954.318 54.5829 954.318 54.9377C954.318 55.2924 954.605 55.58 954.96 55.58C955.314 55.58 955.602 55.2924 955.602 54.9377C955.602 54.5829 955.314 54.2953 954.96 54.2953Z" />
        <path d="M937.009 54.2953C936.654 54.2953 936.367 54.5829 936.367 54.9377C936.367 55.2924 936.654 55.58 937.009 55.58C937.363 55.58 937.651 55.2924 937.651 54.9377C937.651 54.5829 937.363 54.2953 937.009 54.2953Z" />
        <path d="M919.058 54.2953C918.703 54.2953 918.416 54.5829 918.416 54.9377C918.416 55.2924 918.703 55.58 919.058 55.58C919.412 55.58 919.7 55.2924 919.7 54.9377C919.7 54.5829 919.412 54.2953 919.058 54.2953Z" />
        <path d="M901.131 54.2953C900.776 54.2953 900.489 54.5829 900.489 54.9377C900.489 55.2924 900.776 55.58 901.131 55.58C901.485 55.58 901.773 55.2924 901.773 54.9377C901.773 54.5829 901.485 54.2953 901.131 54.2953Z" />
        <path d="M883.18 54.2955C882.825 54.2955 882.538 54.5831 882.538 54.9378C882.538 55.2926 882.825 55.5801 883.18 55.5801C883.534 55.5801 883.822 55.2926 883.822 54.9378C883.822 54.5831 883.534 54.2955 883.18 54.2955Z" />
        <path d="M865.253 54.2953C864.899 54.2953 864.611 54.5829 864.611 54.9377C864.611 55.2924 864.899 55.58 865.253 55.58C865.608 55.58 865.895 55.2924 865.895 54.9377C865.895 54.5829 865.608 54.2953 865.253 54.2953Z" />
        <path d="M847.3 54.2953C846.946 54.2953 846.658 54.5829 846.658 54.9377C846.658 55.2924 846.946 55.58 847.3 55.58C847.655 55.58 847.942 55.2924 847.942 54.9377C847.942 54.5829 847.655 54.2953 847.3 54.2953Z" />
        <path d="M829.375 54.2953C829.021 54.2953 828.733 54.5829 828.733 54.9377C828.733 55.2924 829.021 55.58 829.375 55.58C829.73 55.58 830.017 55.2924 830.017 54.9377C830.017 54.5829 829.73 54.2953 829.375 54.2953Z" />
        <path d="M811.423 54.2953C811.068 54.2953 810.781 54.5829 810.781 54.9377C810.781 55.2924 811.068 55.58 811.423 55.58C811.777 55.58 812.065 55.2924 812.065 54.9377C812.065 54.5829 811.777 54.2953 811.423 54.2953Z" />
        <path d="M793.496 54.2955C793.141 54.2955 792.854 54.5831 792.854 54.9378C792.854 55.2926 793.141 55.5801 793.496 55.5801C793.851 55.5801 794.138 55.2926 794.138 54.9378C794.138 54.5831 793.851 54.2955 793.496 54.2955Z" />
        <path d="M775.545 54.2953C775.191 54.2953 774.903 54.5829 774.903 54.9377C774.903 55.2924 775.191 55.58 775.545 55.58C775.9 55.58 776.187 55.2924 776.187 54.9377C776.187 54.5829 775.9 54.2953 775.545 54.2953Z" />
        <path d="M757.617 54.2953C757.262 54.2953 756.975 54.5829 756.975 54.9377C756.975 55.2924 757.262 55.58 757.617 55.58C757.971 55.58 758.259 55.2924 758.259 54.9377C758.259 54.5829 757.971 54.2953 757.617 54.2953Z" />
        <path d="M739.667 54.2953C739.313 54.2953 739.025 54.5829 739.025 54.9377C739.025 55.2924 739.313 55.58 739.667 55.58C740.022 55.58 740.309 55.2924 740.309 54.9377C740.309 54.5829 740.022 54.2953 739.667 54.2953Z" />
        <path d="M721.739 54.2953C721.384 54.2953 721.097 54.5829 721.097 54.9377C721.097 55.2924 721.384 55.58 721.739 55.58C722.094 55.58 722.381 55.2924 722.381 54.9377C722.381 54.5829 722.094 54.2953 721.739 54.2953Z" />
        <path d="M703.788 54.2953C703.433 54.2953 703.146 54.5829 703.146 54.9377C703.146 55.2924 703.433 55.58 703.788 55.58C704.142 55.58 704.43 55.2924 704.43 54.9377C704.43 54.5829 704.142 54.2953 703.788 54.2953Z" />
        <path d="M685.861 54.2953C685.507 54.2953 685.219 54.5829 685.219 54.9377C685.219 55.2924 685.507 55.58 685.861 55.58C686.216 55.58 686.503 55.2924 686.503 54.9377C686.503 54.5829 686.216 54.2953 685.861 54.2953Z" />
        <path d="M667.91 54.2953C667.556 54.2953 667.268 54.5829 667.268 54.9377C667.268 55.2924 667.556 55.58 667.91 55.58C668.265 55.58 668.552 55.2924 668.552 54.9377C668.552 54.5829 668.265 54.2953 667.91 54.2953Z" />
        <path d="M649.984 54.2953C649.629 54.2953 649.342 54.5829 649.342 54.9377C649.342 55.2924 649.629 55.58 649.984 55.58C650.338 55.58 650.626 55.2924 650.626 54.9377C650.626 54.5829 650.338 54.2953 649.984 54.2953Z" />
        <path d="M632.031 54.2953C631.676 54.2953 631.389 54.5829 631.389 54.9377C631.389 55.2924 631.676 55.58 632.031 55.58C632.385 55.58 632.673 55.2924 632.673 54.9377C632.673 54.5829 632.385 54.2953 632.031 54.2953Z" />
        <path d="M614.106 54.2953C613.751 54.2953 613.464 54.5829 613.464 54.9377C613.464 55.2924 613.751 55.58 614.106 55.58C614.461 55.58 614.748 55.2924 614.748 54.9377C614.748 54.5829 614.461 54.2953 614.106 54.2953Z" />
        <path d="M596.155 54.2953C595.8 54.2953 595.513 54.5829 595.513 54.9377C595.513 55.2924 595.8 55.58 596.155 55.58C596.509 55.58 596.797 55.2924 596.797 54.9377C596.797 54.5829 596.509 54.2953 596.155 54.2953Z" />
        <path d="M578.226 54.2953C577.872 54.2953 577.584 54.5829 577.584 54.9377C577.584 55.2924 577.872 55.58 578.226 55.58C578.581 55.58 578.868 55.2924 578.868 54.9377C578.868 54.5829 578.581 54.2953 578.226 54.2953Z" />
        <path d="M560.275 54.2954C559.921 54.2954 559.633 54.583 559.633 54.9378C559.633 55.2925 559.921 55.5801 560.275 55.5801C560.63 55.5801 560.917 55.2925 560.917 54.9378C560.917 54.583 560.63 54.2954 560.275 54.2954Z" />
        <path d="M542.349 54.2953C541.994 54.2953 541.707 54.5829 541.707 54.9377C541.707 55.2924 541.994 55.58 542.349 55.58C542.703 55.58 542.991 55.2924 542.991 54.9377C542.991 54.5829 542.703 54.2953 542.349 54.2953Z" />
        <path d="M524.398 54.2953C524.043 54.2953 523.756 54.5829 523.756 54.9377C523.756 55.2924 524.043 55.58 524.398 55.58C524.752 55.58 525.04 55.2924 525.04 54.9377C525.04 54.5829 524.752 54.2953 524.398 54.2953Z" />
        <path d="M506.471 54.2953C506.116 54.2953 505.829 54.5829 505.829 54.9377C505.829 55.2924 506.116 55.58 506.471 55.58C506.826 55.58 507.113 55.2924 507.113 54.9377C507.113 54.5829 506.826 54.2953 506.471 54.2953Z" />
        <path d="M488.52 54.2954C488.165 54.2954 487.878 54.583 487.878 54.9378C487.878 55.2925 488.165 55.5801 488.52 55.5801C488.875 55.5801 489.162 55.2925 489.162 54.9378C489.162 54.583 488.875 54.2954 488.52 54.2954Z" />
        <path d="M470.593 54.2953C470.239 54.2953 469.951 54.5829 469.951 54.9377C469.951 55.2924 470.239 55.58 470.593 55.58C470.948 55.58 471.235 55.2924 471.235 54.9377C471.235 54.5829 470.948 54.2953 470.593 54.2953Z" />
        <path d="M452.639 54.2953C452.284 54.2953 451.997 54.5829 451.997 54.9377C451.997 55.2924 452.284 55.58 452.639 55.58C452.993 55.58 453.281 55.2924 453.281 54.9377C453.281 54.5829 452.993 54.2953 452.639 54.2953Z" />
        <path d="M434.69 54.2953C434.335 54.2953 434.048 54.5829 434.048 54.9377C434.048 55.2924 434.335 55.58 434.69 55.58C435.044 55.58 435.332 55.2924 435.332 54.9377C435.332 54.5829 435.044 54.2953 434.69 54.2953Z" />
        <path d="M416.761 54.2953C416.407 54.2953 416.119 54.5829 416.119 54.9377C416.119 55.2924 416.407 55.58 416.761 55.58C417.116 55.58 417.403 55.2924 417.403 54.9377C417.403 54.5829 417.116 54.2953 416.761 54.2953Z" />
        <path d="M398.812 54.2953C398.457 54.2953 398.17 54.5829 398.17 54.9377C398.17 55.2924 398.457 55.58 398.812 55.58C399.166 55.58 399.454 55.2924 399.454 54.9377C399.454 54.5829 399.166 54.2953 398.812 54.2953Z" />
        <path d="M380.884 54.2953C380.529 54.2953 380.241 54.5829 380.241 54.9377C380.241 55.2924 380.529 55.58 380.884 55.58C381.238 55.58 381.526 55.2924 381.526 54.9377C381.526 54.5829 381.238 54.2953 380.884 54.2953Z" />
        <path d="M362.932 54.2953C362.578 54.2953 362.29 54.5829 362.29 54.9377C362.29 55.2924 362.578 55.58 362.932 55.58C363.287 55.58 363.574 55.2924 363.574 54.9377C363.574 54.5829 363.287 54.2953 362.932 54.2953Z" />
        <path d="M345.006 54.2953C344.651 54.2953 344.364 54.5829 344.364 54.9377C344.364 55.2924 344.651 55.58 345.006 55.58C345.36 55.58 345.648 55.2924 345.648 54.9377C345.648 54.5829 345.36 54.2953 345.006 54.2953Z" />
        <path d="M327.055 54.2954C326.7 54.2954 326.413 54.583 326.413 54.9378C326.413 55.2925 326.7 55.5801 327.055 55.5801C327.409 55.5801 327.697 55.2925 327.697 54.9378C327.697 54.583 327.409 54.2954 327.055 54.2954Z" />
        <path d="M309.128 54.2954C308.774 54.2954 308.486 54.583 308.486 54.9378C308.486 55.2925 308.774 55.5801 309.128 55.5801C309.483 55.5801 309.77 55.2925 309.77 54.9378C309.77 54.583 309.483 54.2954 309.128 54.2954Z" />
        <path d="M291.177 54.2953C290.822 54.2953 290.535 54.5829 290.535 54.9377C290.535 55.2924 290.822 55.58 291.177 55.58C291.532 55.58 291.819 55.2924 291.819 54.9377C291.819 54.5829 291.532 54.2953 291.177 54.2953Z" />
        <path d="M273.25 54.2953C272.896 54.2953 272.608 54.5829 272.608 54.9377C272.608 55.2924 272.896 55.58 273.25 55.58C273.605 55.58 273.892 55.2924 273.892 54.9377C273.892 54.5829 273.605 54.2953 273.25 54.2953Z" />
        <path d="M255.298 54.2954C254.943 54.2954 254.656 54.583 254.656 54.9378C254.656 55.2925 254.943 55.5801 255.298 55.5801C255.652 55.5801 255.94 55.2925 255.94 54.9378C255.94 54.583 255.652 54.2954 255.298 54.2954Z" />
        <path d="M237.371 54.2954C237.016 54.2954 236.729 54.583 236.729 54.9378C236.729 55.2925 237.016 55.5801 237.371 55.5801C237.726 55.5801 238.013 55.2925 238.013 54.9378C238.013 54.583 237.726 54.2954 237.371 54.2954Z" />
        <path d="M219.42 54.2952C219.065 54.2952 218.778 54.5828 218.778 54.9376C218.778 55.2924 219.065 55.5799 219.42 55.5799C219.775 55.5799 220.062 55.2924 220.062 54.9376C220.062 54.5828 219.775 54.2952 219.42 54.2952Z" />
        <path d="M201.493 54.2952C201.139 54.2952 200.851 54.5828 200.851 54.9376C200.851 55.2924 201.139 55.5799 201.493 55.5799C201.848 55.5799 202.135 55.2924 202.135 54.9376C202.135 54.5828 201.848 54.2952 201.493 54.2952Z" />
        <path d="M183.541 54.2952C183.186 54.2952 182.899 54.5828 182.899 54.9376C182.899 55.2924 183.186 55.5799 183.541 55.5799C183.895 55.5799 184.183 55.2924 184.183 54.9376C184.183 54.5828 183.895 54.2952 183.541 54.2952Z" />
        <path d="M165.614 54.2952C165.268 54.2952 164.972 54.5917 164.972 54.9376C164.972 55.2835 165.268 55.5799 165.614 55.5799C165.96 55.5799 166.256 55.2835 166.256 54.9376C166.256 54.567 165.96 54.2952 165.614 54.2952Z" />
        <path d="M147.663 54.2952C147.308 54.2952 147.021 54.5828 147.021 54.9376C147.021 55.2924 147.308 55.5799 147.663 55.5799C148.017 55.5799 148.305 55.2924 148.305 54.9376C148.305 54.5828 148.017 54.2952 147.663 54.2952Z" />
        <path d="M129.736 54.2952C129.382 54.2952 129.094 54.5828 129.094 54.9376C129.094 55.2924 129.382 55.5799 129.736 55.5799C130.091 55.5799 130.378 55.2924 130.378 54.9376C130.378 54.5828 130.091 54.2952 129.736 54.2952Z" />
        <path d="M111.787 54.2952C111.432 54.2952 111.145 54.5828 111.145 54.9376C111.145 55.2924 111.432 55.5799 111.787 55.5799C112.141 55.5799 112.429 55.2924 112.429 54.9376C112.429 54.5828 112.141 54.2952 111.787 54.2952Z" />
        <path d="M93.8585 54.2952C93.504 54.2952 93.2165 54.5828 93.2165 54.9376C93.2165 55.2924 93.504 55.5799 93.8585 55.5799C94.2131 55.5799 94.5005 55.2924 94.5005 54.9376C94.5005 54.5828 94.2131 54.2952 93.8585 54.2952Z" />
        <path d="M75.9073 54.2952C75.5527 54.2952 75.2652 54.5828 75.2652 54.9376C75.2652 55.2924 75.5527 55.5799 75.9073 55.5799C76.2619 55.5799 76.5493 55.2924 76.5493 54.9376C76.5493 54.5828 76.2619 54.2952 75.9073 54.2952Z" />
        <path d="M57.9808 54.2952C57.6262 54.2952 57.3388 54.5828 57.3388 54.9376C57.3388 55.2924 57.6262 55.5799 57.9808 55.5799C58.3353 55.5799 58.6228 55.2924 58.6228 54.9376C58.6228 54.5828 58.3353 54.2952 57.9808 54.2952Z" />
        <path d="M40.0279 54.2952C39.6734 54.2952 39.3859 54.5828 39.3859 54.9376C39.3859 55.2924 39.6734 55.5799 40.0279 55.5799C40.3825 55.5799 40.6699 55.2924 40.6699 54.9376C40.6699 54.5828 40.3825 54.2952 40.0279 54.2952Z" />
        <path d="M22.077 54.2954C21.7225 54.2954 21.435 54.583 21.435 54.9378C21.435 55.2925 21.7225 55.5801 22.077 55.5801C22.4316 55.5801 22.719 55.2925 22.719 54.9378C22.719 54.583 22.4316 54.2954 22.077 54.2954Z" />
        <path d="M4.15019 54.2954C3.79562 54.2954 3.50815 54.583 3.50815 54.9378C3.50815 55.2925 3.79562 55.5801 4.15019 55.5801C4.50477 55.5801 4.79224 55.2925 4.79224 54.9378C4.79224 54.583 4.50477 54.2954 4.15019 54.2954Z" />
        <path d="M945.972 36.5818C945.75 36.5818 945.577 36.7547 945.577 36.977C945.577 37.1994 945.75 37.3723 945.972 37.3723C946.194 37.3723 946.367 37.1994 946.367 36.977C946.367 36.7547 946.194 36.5818 945.972 36.5818Z" />
        <path d="M928.047 36.5818C927.824 36.5818 927.652 36.7547 927.652 36.977C927.652 37.1994 927.824 37.3723 928.047 37.3723C928.269 37.3723 928.442 37.1994 928.442 36.977C928.442 36.7547 928.269 36.5818 928.047 36.5818Z" />
        <path d="M910.094 36.5818C909.872 36.5818 909.699 36.7547 909.699 36.977C909.699 37.1994 909.872 37.3723 910.094 37.3723C910.316 37.3723 910.489 37.1994 910.489 36.977C910.489 36.7547 910.316 36.5818 910.094 36.5818Z" />
        <path d="M892.169 36.5818C891.947 36.5818 891.774 36.7547 891.774 36.977C891.774 37.1994 891.947 37.3723 892.169 37.3723C892.391 37.3723 892.564 37.1994 892.564 36.977C892.564 36.7547 892.391 36.5818 892.169 36.5818Z" />
        <path d="M874.216 36.5818C873.994 36.5818 873.821 36.7547 873.821 36.977C873.821 37.1994 873.994 37.3723 874.216 37.3723C874.439 37.3723 874.611 37.1994 874.611 36.977C874.611 36.7547 874.439 36.5818 874.216 36.5818Z" />
        <path d="M856.29 36.5818C856.067 36.5818 855.895 36.7547 855.895 36.977C855.895 37.1994 856.067 37.3723 856.29 37.3723C856.512 37.3723 856.685 37.1994 856.685 36.977C856.685 36.7547 856.512 36.5818 856.29 36.5818Z" />
        <path d="M838.337 36.5818C838.115 36.5818 837.942 36.7547 837.942 36.977C837.942 37.1994 838.115 37.3723 838.337 37.3723C838.559 37.3723 838.732 37.1994 838.732 36.977C838.732 36.7547 838.559 36.5818 838.337 36.5818Z" />
        <path d="M820.41 36.5818C820.188 36.5818 820.015 36.7547 820.015 36.977C820.015 37.1994 820.188 37.3723 820.41 37.3723C820.633 37.3723 820.805 37.1994 820.805 36.977C820.805 36.7547 820.633 36.5818 820.41 36.5818Z" />
        <path d="M802.459 36.5818C802.237 36.5818 802.064 36.7547 802.064 36.977C802.064 37.1994 802.237 37.3723 802.459 37.3723C802.681 37.3723 802.854 37.1994 802.854 36.977C802.854 36.7547 802.681 36.5818 802.459 36.5818Z" />
        <path d="M784.532 36.5818C784.31 36.5818 784.137 36.7547 784.137 36.977C784.137 37.1994 784.31 37.3723 784.532 37.3723C784.755 37.3723 784.927 37.1994 784.927 36.977C784.927 36.7547 784.73 36.5818 784.532 36.5818Z" />
        <path d="M766.582 36.5818C766.359 36.5818 766.186 36.7547 766.186 36.977C766.186 37.1994 766.359 37.3723 766.582 37.3723C766.804 37.3723 766.977 37.1994 766.977 36.977C766.977 36.7547 766.804 36.5818 766.582 36.5818Z" />
        <path d="M748.655 36.5818C748.433 36.5818 748.26 36.7547 748.26 36.977C748.26 37.1994 748.433 37.3723 748.655 37.3723C748.877 37.3723 749.05 37.1994 749.05 36.977C749.05 36.7547 748.852 36.5818 748.655 36.5818Z" />
        <path d="M730.702 36.5818C730.48 36.5818 730.307 36.7547 730.307 36.977C730.307 37.1994 730.48 37.3723 730.702 37.3723C730.924 37.3723 731.097 37.1994 731.097 36.977C731.097 36.7547 730.924 36.5818 730.702 36.5818Z" />
        <path d="M712.777 36.5818C712.555 36.5818 712.382 36.7547 712.382 36.977C712.382 37.1994 712.555 37.3723 712.777 37.3723C712.999 37.3723 713.172 37.1994 713.172 36.977C713.172 36.7547 712.975 36.5818 712.777 36.5818Z" />
        <path d="M694.824 36.5818C694.602 36.5818 694.429 36.7547 694.429 36.977C694.429 37.1994 694.602 37.3723 694.824 37.3723C695.047 37.3723 695.219 37.1994 695.219 36.977C695.219 36.7547 695.047 36.5818 694.824 36.5818Z" />
        <path d="M676.873 36.5818C676.651 36.5818 676.478 36.7547 676.478 36.977C676.478 37.1994 676.651 37.3723 676.873 37.3723C677.095 37.3723 677.268 37.1994 677.268 36.977C677.293 36.7547 677.095 36.5818 676.873 36.5818Z" />
        <path d="M658.947 36.5818C658.724 36.5818 658.552 36.7547 658.552 36.977C658.552 37.1994 658.724 37.3723 658.947 37.3723C659.169 37.3723 659.342 37.1994 659.342 36.977C659.342 36.7547 659.169 36.5818 658.947 36.5818Z" />
        <path d="M640.995 36.5818C640.773 36.5818 640.6 36.7547 640.6 36.977C640.6 37.1994 640.773 37.3723 640.995 37.3723C641.218 37.3723 641.39 37.1994 641.39 36.977C641.415 36.7547 641.218 36.5818 640.995 36.5818Z" />
        <path d="M623.069 36.5818C622.847 36.5818 622.674 36.7547 622.674 36.977C622.674 37.1994 622.847 37.3723 623.069 37.3723C623.291 37.3723 623.464 37.1994 623.464 36.977C623.464 36.7547 623.291 36.5818 623.069 36.5818Z" />
        <path d="M605.116 36.5818C604.894 36.5818 604.721 36.7547 604.721 36.977C604.721 37.1994 604.894 37.3723 605.116 37.3723C605.338 37.3723 605.511 37.1994 605.511 36.977C605.536 36.7547 605.338 36.5818 605.116 36.5818Z" />
        <path d="M587.191 36.5818C586.969 36.5818 586.796 36.7547 586.796 36.977C586.796 37.1994 586.969 37.3723 587.191 37.3723C587.414 37.3723 587.586 37.1994 587.586 36.977C587.586 36.7547 587.414 36.5818 587.191 36.5818Z" />
        <path d="M569.239 36.5818C569.016 36.5818 568.843 36.7547 568.843 36.977C568.843 37.1994 569.016 37.3723 569.239 37.3723C569.461 37.3723 569.634 37.1994 569.634 36.977C569.634 36.7547 569.461 36.5818 569.239 36.5818Z" />
        <path d="M551.312 36.5818C551.09 36.5818 550.917 36.7547 550.917 36.977C550.917 37.1994 551.09 37.3723 551.312 37.3723C551.534 37.3723 551.707 37.1994 551.707 36.977C551.707 36.7547 551.534 36.5818 551.312 36.5818Z" />
        <path d="M533.361 36.5818C533.139 36.5818 532.966 36.7547 532.966 36.977C532.966 37.1994 533.139 37.3723 533.361 37.3723C533.583 37.3723 533.756 37.1994 533.756 36.977C533.756 36.7547 533.583 36.5818 533.361 36.5818Z" />
        <path d="M515.434 36.5818C515.212 36.5818 515.039 36.7547 515.039 36.977C515.039 37.1994 515.212 37.3723 515.434 37.3723C515.656 37.3723 515.829 37.1994 515.829 36.977C515.829 36.7547 515.656 36.5818 515.434 36.5818Z" />
        <path d="M497.483 36.5818C497.261 36.5818 497.088 36.7547 497.088 36.977C497.088 37.1994 497.261 37.3723 497.483 37.3723C497.705 37.3723 497.878 37.1994 497.878 36.977C497.878 36.7547 497.705 36.5818 497.483 36.5818Z" />
        <path d="M479.556 36.5818C479.334 36.5818 479.161 36.7547 479.161 36.977C479.161 37.1994 479.334 37.3723 479.556 37.3723C479.778 37.3723 479.951 37.1994 479.951 36.977C479.951 36.7547 479.778 36.5818 479.556 36.5818Z" />
        <path d="M461.604 36.5818C461.381 36.5818 461.209 36.7547 461.209 36.977C461.209 37.1994 461.381 37.3723 461.604 37.3723C461.826 37.3723 461.999 37.1994 461.999 36.977C461.999 36.7547 461.826 36.5818 461.604 36.5818Z" />
        <path d="M443.677 36.5818C443.455 36.5818 443.282 36.7547 443.282 36.977C443.282 37.1994 443.455 37.3723 443.677 37.3723C443.899 37.3723 444.072 37.1994 444.072 36.977C444.072 36.7547 443.899 36.5818 443.677 36.5818Z" />
        <path d="M425.726 36.5818C425.504 36.5818 425.331 36.7547 425.331 36.977C425.331 37.1994 425.504 37.3723 425.726 37.3723C425.948 37.3723 426.121 37.1994 426.121 36.977C426.121 36.7547 425.948 36.5818 425.726 36.5818Z" />
        <path d="M407.799 36.5818C407.577 36.5818 407.404 36.7547 407.404 36.977C407.404 37.1994 407.577 37.3723 407.799 37.3723C408.022 37.3723 408.194 37.1994 408.194 36.977C408.194 36.7547 408.022 36.5818 407.799 36.5818Z" />
        <path d="M389.847 36.5818C389.624 36.5818 389.452 36.7547 389.452 36.977C389.452 37.1994 389.624 37.3723 389.847 37.3723C390.069 37.3723 390.242 37.1994 390.242 36.977C390.242 36.7547 390.069 36.5818 389.847 36.5818Z" />
        <path d="M371.922 36.5818C371.7 36.5818 371.527 36.7547 371.527 36.977C371.527 37.1994 371.7 37.3723 371.922 37.3723C372.144 37.3723 372.317 37.1994 372.317 36.977C372.317 36.7547 372.144 36.5818 371.922 36.5818Z" />
        <path d="M353.969 36.5818C353.747 36.5818 353.574 36.7547 353.574 36.977C353.574 37.1994 353.747 37.3723 353.969 37.3723C354.191 37.3723 354.364 37.1994 354.364 36.977C354.364 36.7547 354.191 36.5818 353.969 36.5818Z" />
        <path d="M336.042 36.5818C335.82 36.5818 335.647 36.7547 335.647 36.977C335.647 37.1994 335.82 37.3723 336.042 37.3723C336.264 37.3723 336.437 37.1994 336.437 36.977C336.437 36.7547 336.24 36.5818 336.042 36.5818Z" />
        <path d="M318.091 36.5818C317.869 36.5818 317.696 36.7547 317.696 36.977C317.696 37.1994 317.869 37.3723 318.091 37.3723C318.314 37.3723 318.486 37.1994 318.486 36.977C318.486 36.7547 318.314 36.5818 318.091 36.5818Z" />
        <path d="M300.164 36.5818C299.942 36.5818 299.769 36.7547 299.769 36.977C299.769 37.1994 299.942 37.3723 300.164 37.3723C300.387 37.3723 300.56 37.1994 300.56 36.977C300.56 36.7547 300.362 36.5818 300.164 36.5818Z" />
        <path d="M282.214 36.5818C281.991 36.5818 281.819 36.7547 281.819 36.977C281.819 37.1994 281.991 37.3723 282.214 37.3723C282.436 37.3723 282.609 37.1994 282.609 36.977C282.609 36.7547 282.436 36.5818 282.214 36.5818Z" />
        <path d="M264.287 36.5818C264.065 36.5818 263.892 36.7547 263.892 36.977C263.892 37.1994 264.065 37.3723 264.287 37.3723C264.509 37.3723 264.682 37.1994 264.682 36.977C264.682 36.7547 264.484 36.5818 264.287 36.5818Z" />
        <path d="M246.334 36.5817C246.112 36.5817 245.939 36.7546 245.939 36.9769C245.939 37.1993 246.112 37.3723 246.334 37.3723C246.556 37.3723 246.729 37.1993 246.729 36.9769C246.729 36.7546 246.556 36.5817 246.334 36.5817Z" />
        <path d="M228.383 36.5817C228.161 36.5817 227.988 36.7546 227.988 36.9769C227.988 37.1993 228.161 37.3723 228.383 37.3723C228.605 37.3723 228.778 37.1993 228.778 36.9769C228.803 36.7546 228.605 36.5817 228.383 36.5817Z" />
        <path d="M210.456 36.5817C210.234 36.5817 210.061 36.7546 210.061 36.9769C210.061 37.1993 210.234 37.3723 210.456 37.3723C210.679 37.3723 210.852 37.1993 210.852 36.9769C210.852 36.7546 210.679 36.5817 210.456 36.5817Z" />
        <path d="M192.505 36.5817C192.283 36.5817 192.11 36.7546 192.11 36.9769C192.11 37.1993 192.283 37.3723 192.505 37.3723C192.727 37.3723 192.9 37.1993 192.9 36.9769C192.925 36.7546 192.727 36.5817 192.505 36.5817Z" />
        <path d="M174.579 36.5817C174.357 36.5817 174.184 36.7546 174.184 36.9769C174.184 37.1993 174.357 37.3723 174.579 37.3723C174.801 37.3723 174.974 37.1993 174.974 36.9769C174.974 36.7546 174.801 36.5817 174.579 36.5817Z" />
        <path d="M156.627 36.5817C156.405 36.5817 156.232 36.7546 156.232 36.9769C156.232 37.1993 156.405 37.3723 156.627 37.3723C156.85 37.3723 157.023 37.1993 157.023 36.9769C157.047 36.7546 156.85 36.5817 156.627 36.5817Z" />
        <path d="M138.701 36.5817C138.479 36.5817 138.306 36.7546 138.306 36.9769C138.306 37.1993 138.479 37.3723 138.701 37.3723C138.923 37.3723 139.096 37.1993 139.096 36.9769C139.096 36.7546 138.923 36.5817 138.701 36.5817Z" />
        <path d="M120.747 36.5817C120.524 36.5817 120.352 36.7546 120.352 36.9769C120.352 37.1993 120.524 37.3723 120.747 37.3723C120.969 37.3723 121.142 37.1993 121.142 36.9769C121.142 36.7546 120.969 36.5817 120.747 36.5817Z" />
        <path d="M102.822 36.5817C102.599 36.5817 102.426 36.7546 102.426 36.9769C102.426 37.1993 102.599 37.3723 102.822 37.3723C103.044 37.3723 103.217 37.1993 103.217 36.9769C103.217 36.7546 103.044 36.5817 102.822 36.5817Z" />
        <path d="M84.8705 36.5817C84.6482 36.5817 84.4754 36.7546 84.4754 36.9769C84.4754 37.1993 84.6482 37.3723 84.8705 37.3723C85.0927 37.3723 85.2656 37.1993 85.2656 36.9769C85.2656 36.7546 85.0927 36.5817 84.8705 36.5817Z" />
        <path d="M66.9438 36.5817C66.7216 36.5817 66.5487 36.7546 66.5487 36.9769C66.5487 37.1993 66.7216 37.3723 66.9438 37.3723C67.166 37.3723 67.3389 37.1993 67.3389 36.9769C67.3389 36.7546 67.166 36.5817 66.9438 36.5817Z" />
        <path d="M48.9929 36.5817C48.7706 36.5817 48.5978 36.7546 48.5978 36.9769C48.5978 37.1993 48.7706 37.3723 48.9929 37.3723C49.2151 37.3723 49.3879 37.1993 49.3879 36.9769C49.3879 36.7546 49.2151 36.5817 48.9929 36.5817Z" />
        <path d="M31.0648 36.5817C30.8426 36.5817 30.6697 36.7546 30.6697 36.9769C30.6697 37.1993 30.8426 37.3723 31.0648 37.3723C31.2871 37.3723 31.46 37.1993 31.46 36.9769C31.46 36.7546 31.2871 36.5817 31.0648 36.5817Z" />
        <path d="M13.1132 36.5817C12.891 36.5817 12.7181 36.7546 12.7181 36.9769C12.7181 37.1993 12.891 37.3723 13.1132 37.3723C13.3354 37.3723 13.5083 37.1993 13.5083 36.9769C13.5083 36.7546 13.3354 36.5817 13.1132 36.5817Z" />
        <path d="M954.96 18.8441C954.861 18.8441 954.763 18.9429 954.763 19.0417C954.763 19.1405 954.861 19.2393 954.96 19.2393C955.059 19.2393 955.158 19.1405 955.158 19.0417C955.158 18.9182 955.059 18.8441 954.96 18.8441Z" />
        <path d="M937.009 18.8441C936.91 18.8441 936.812 18.9429 936.812 19.0417C936.812 19.1405 936.91 19.2393 937.009 19.2393C937.108 19.2393 937.207 19.1405 937.207 19.0417C937.207 18.9182 937.108 18.8441 937.009 18.8441Z" />
        <path d="M919.059 18.8441C918.961 18.8441 918.862 18.9429 918.862 19.0417C918.862 19.1405 918.961 19.2393 919.059 19.2393C919.158 19.2393 919.257 19.1405 919.257 19.0417C919.282 18.9182 919.183 18.8441 919.059 18.8441Z" />
        <path d="M901.131 18.8441C901.033 18.8441 900.934 18.9429 900.934 19.0417C900.934 19.1405 901.033 19.2393 901.131 19.2393C901.23 19.2393 901.329 19.1405 901.329 19.0417C901.329 18.9182 901.23 18.8441 901.131 18.8441Z" />
        <path d="M883.178 18.8441C883.08 18.8441 882.981 18.9429 882.981 19.0417C882.981 19.1405 883.08 19.2393 883.178 19.2393C883.277 19.2393 883.376 19.1405 883.376 19.0417C883.401 18.9182 883.302 18.8441 883.178 18.8441Z" />
        <path d="M865.254 18.8441C865.155 18.8441 865.056 18.9429 865.056 19.0417C865.056 19.1405 865.155 19.2393 865.254 19.2393C865.352 19.2393 865.451 19.1405 865.451 19.0417C865.451 18.9182 865.352 18.8441 865.254 18.8441Z" />
        <path d="M847.301 18.8441C847.202 18.8441 847.103 18.9429 847.103 19.0417C847.103 19.1405 847.202 19.2393 847.301 19.2393C847.4 19.2393 847.499 19.1405 847.499 19.0417C847.499 18.9182 847.424 18.8441 847.301 18.8441Z" />
        <path d="M829.374 18.8441C829.275 18.8441 829.177 18.9429 829.177 19.0417C829.177 19.1405 829.275 19.2393 829.374 19.2393C829.473 19.2393 829.572 19.1405 829.572 19.0417C829.572 18.9182 829.473 18.8441 829.374 18.8441Z" />
        <path d="M811.423 18.8441C811.325 18.8441 811.226 18.9429 811.226 19.0417C811.226 19.1405 811.325 19.2393 811.423 19.2393C811.522 19.2393 811.621 19.1405 811.621 19.0417C811.621 18.9182 811.547 18.8441 811.423 18.8441Z" />
        <path d="M793.497 18.8441C793.398 18.8441 793.299 18.9429 793.299 19.0417C793.299 19.1405 793.398 19.2393 793.497 19.2393C793.595 19.2393 793.694 19.1405 793.694 19.0417C793.694 18.9182 793.595 18.8441 793.497 18.8441Z" />
        <path d="M775.545 18.8441C775.447 18.8441 775.348 18.9429 775.348 19.0417C775.348 19.1405 775.447 19.2393 775.545 19.2393C775.644 19.2393 775.743 19.1405 775.743 19.0417C775.743 18.9182 775.669 18.8441 775.545 18.8441Z" />
        <path d="M757.617 18.8441C757.518 18.8441 757.42 18.9429 757.42 19.0417C757.42 19.1405 757.518 19.2393 757.617 19.2393C757.716 19.2393 757.815 19.1405 757.815 19.0417C757.815 18.9182 757.716 18.8441 757.617 18.8441Z" />
        <path d="M739.666 18.8441C739.567 18.8441 739.469 18.9429 739.469 19.0417C739.469 19.1405 739.567 19.2393 739.666 19.2393C739.765 19.2393 739.864 19.1405 739.864 19.0417C739.864 18.9182 739.79 18.8441 739.666 18.8441Z" />
        <path d="M721.739 18.8441C721.641 18.8441 721.542 18.9429 721.542 19.0417C721.542 19.1405 721.641 19.2393 721.739 19.2393C721.838 19.2393 721.937 19.1405 721.937 19.0417C721.937 18.9182 721.838 18.8441 721.739 18.8441Z" />
        <path d="M703.788 18.8441C703.69 18.8441 703.591 18.9429 703.591 19.0417C703.591 19.1405 703.69 19.2393 703.788 19.2393C703.887 19.2393 703.986 19.1405 703.986 19.0417C703.986 18.9182 703.912 18.8441 703.788 18.8441Z" />
        <path d="M685.862 18.8441C685.763 18.8441 685.664 18.9429 685.664 19.0417C685.664 19.1405 685.763 19.2393 685.862 19.2393C685.961 19.2393 686.059 19.1405 686.059 19.0417C686.059 18.9182 685.961 18.8441 685.862 18.8441Z" />
        <path d="M667.909 18.8441C667.81 18.8441 667.711 18.9429 667.711 19.0417C667.711 19.1405 667.81 19.2393 667.909 19.2393C668.008 19.2393 668.107 19.1405 668.107 19.0417C668.107 18.9182 668.033 18.8441 667.909 18.8441Z" />
        <path d="M649.984 18.8441C649.885 18.8441 649.787 18.9429 649.787 19.0417C649.787 19.1405 649.885 19.2393 649.984 19.2393C650.083 19.2393 650.182 19.1405 650.182 19.0417C650.182 18.9182 650.083 18.8441 649.984 18.8441Z" />
        <path d="M632.031 18.8441C631.933 18.8441 631.834 18.9429 631.834 19.0417C631.834 19.1405 631.933 19.2393 632.031 19.2393C632.13 19.2393 632.229 19.1405 632.229 19.0417C632.229 18.9182 632.155 18.8441 632.031 18.8441Z" />
        <path d="M614.106 18.8441C614.008 18.8441 613.909 18.9429 613.909 19.0417C613.909 19.1405 614.008 19.2393 614.106 19.2393C614.205 19.2393 614.304 19.1405 614.304 19.0417C614.304 18.9182 614.205 18.8441 614.106 18.8441Z" />
        <path d="M596.154 18.844C596.055 18.844 595.956 18.9428 595.956 19.0416C595.956 19.1404 596.055 19.2392 596.154 19.2392C596.252 19.2392 596.351 19.1404 596.351 19.0416C596.351 18.9181 596.277 18.844 596.154 18.844Z" />
        <path d="M578.227 18.844C578.128 18.844 578.029 18.9428 578.029 19.0416C578.029 19.1404 578.128 19.2392 578.227 19.2392C578.326 19.2392 578.424 19.1404 578.424 19.0416C578.424 18.9181 578.326 18.844 578.227 18.844Z" />
        <path d="M560.274 18.844C560.175 18.844 560.077 18.9428 560.077 19.0416C560.077 19.1404 560.175 19.2392 560.274 19.2392C560.373 19.2392 560.472 19.1404 560.472 19.0416C560.472 18.9181 560.398 18.844 560.274 18.844Z" />
        <path d="M542.347 18.844C542.249 18.844 542.15 18.9428 542.15 19.0416C542.15 19.1404 542.249 19.2392 542.347 19.2392C542.446 19.2392 542.545 19.1404 542.545 19.0416C542.545 18.9181 542.446 18.844 542.347 18.844Z" />
        <path d="M524.398 18.844C524.299 18.844 524.201 18.9428 524.201 19.0416C524.201 19.1404 524.299 19.2392 524.398 19.2392C524.497 19.2392 524.596 19.1404 524.596 19.0416C524.596 18.9181 524.522 18.844 524.398 18.844Z" />
        <path d="M506.47 18.844C506.371 18.844 506.272 18.9428 506.272 19.0416C506.272 19.1404 506.371 19.2392 506.47 19.2392C506.569 19.2392 506.667 19.1404 506.667 19.0416C506.667 18.9181 506.569 18.844 506.47 18.844Z" />
        <path d="M488.519 18.844C488.42 18.844 488.321 18.9428 488.321 19.0416C488.321 19.1404 488.42 19.2392 488.519 19.2392C488.618 19.2392 488.716 19.1404 488.716 19.0416C488.716 18.9181 488.618 18.844 488.519 18.844Z" />
        <path d="M470.592 18.844C470.493 18.844 470.395 18.9428 470.395 19.0416C470.395 19.1404 470.493 19.2392 470.592 19.2392C470.691 19.2392 470.79 19.1404 470.79 19.0416C470.79 18.9181 470.691 18.844 470.592 18.844Z" />
        <path d="M452.639 18.844C452.541 18.844 452.442 18.9428 452.442 19.0416C452.442 19.1404 452.541 19.2392 452.639 19.2392C452.738 19.2392 452.837 19.1404 452.837 19.0416C452.837 18.9181 452.738 18.844 452.639 18.844Z" />
        <path d="M434.69 18.844C434.591 18.844 434.492 18.9428 434.492 19.0416C434.492 19.1404 434.591 19.2392 434.69 19.2392C434.789 19.2392 434.887 19.1404 434.887 19.0416C434.912 18.9181 434.813 18.844 434.69 18.844Z" />
        <path d="M416.762 18.844C416.663 18.844 416.564 18.9428 416.564 19.0416C416.564 19.1404 416.663 19.2392 416.762 19.2392C416.86 19.2392 416.959 19.1404 416.959 19.0416C416.959 18.9181 416.86 18.844 416.762 18.844Z" />
        <path d="M398.811 18.844C398.712 18.844 398.613 18.9428 398.613 19.0416C398.613 19.1404 398.712 19.2392 398.811 19.2392C398.909 19.2392 399.008 19.1404 399.008 19.0416C399.008 18.9181 398.934 18.844 398.811 18.844Z" />
        <path d="M380.884 18.844C380.785 18.844 380.686 18.9428 380.686 19.0416C380.686 19.1404 380.785 19.2392 380.884 19.2392C380.983 19.2392 381.081 19.1404 381.081 19.0416C381.081 18.9181 380.983 18.844 380.884 18.844Z" />
        <path d="M362.935 18.844C362.836 18.844 362.737 18.9428 362.737 19.0416C362.737 19.1404 362.836 19.2392 362.935 19.2392C363.033 19.2392 363.132 19.1404 363.132 19.0416C363.132 18.9181 363.058 18.844 362.935 18.844Z" />
        <path d="M345.006 18.844C344.908 18.844 344.809 18.9428 344.809 19.0416C344.809 19.1404 344.908 19.2392 345.006 19.2392C345.105 19.2392 345.204 19.1404 345.204 19.0416C345.204 18.9181 345.105 18.844 345.006 18.844Z" />
        <path d="M327.053 18.844C326.955 18.844 326.856 18.9428 326.856 19.0416C326.856 19.1404 326.955 19.2392 327.053 19.2392C327.152 19.2392 327.251 19.1404 327.251 19.0416C327.251 18.9181 327.177 18.844 327.053 18.844Z" />
        <path d="M309.129 18.844C309.03 18.844 308.931 18.9428 308.931 19.0416C308.931 19.1404 309.03 19.2392 309.129 19.2392C309.227 19.2392 309.326 19.1404 309.326 19.0416C309.326 18.9181 309.227 18.844 309.129 18.844Z" />
        <path d="M291.176 18.844C291.077 18.844 290.978 18.9428 290.978 19.0416C290.978 19.1404 291.077 19.2392 291.176 19.2392C291.275 19.2392 291.374 19.1404 291.374 19.0416C291.374 18.9181 291.299 18.844 291.176 18.844Z" />
        <path d="M273.251 18.844C273.152 18.844 273.053 18.9428 273.053 19.0416C273.053 19.1404 273.152 19.2392 273.251 19.2392C273.35 19.2392 273.448 19.1404 273.448 19.0416C273.448 18.9181 273.35 18.844 273.251 18.844Z" />
        <path d="M255.298 18.844C255.199 18.844 255.101 18.9428 255.101 19.0416C255.101 19.1404 255.199 19.2392 255.298 19.2392C255.397 19.2392 255.496 19.1404 255.496 19.0416C255.496 18.9181 255.421 18.844 255.298 18.844Z" />
        <path d="M237.372 18.844C237.273 18.844 237.174 18.9428 237.174 19.0416C237.174 19.1404 237.273 19.2392 237.372 19.2392C237.47 19.2392 237.569 19.1404 237.569 19.0416C237.569 18.9181 237.47 18.844 237.372 18.844Z" />
        <path d="M219.42 18.844C219.322 18.844 219.223 18.9428 219.223 19.0416C219.223 19.1404 219.322 19.2392 219.42 19.2392C219.519 19.2392 219.618 19.1404 219.618 19.0416C219.618 18.9181 219.544 18.844 219.42 18.844Z" />
        <path d="M201.492 18.844C201.393 18.844 201.295 18.9428 201.295 19.0416C201.295 19.1404 201.393 19.2392 201.492 19.2392C201.591 19.2392 201.69 19.1404 201.69 19.0416C201.69 18.9181 201.591 18.844 201.492 18.844Z" />
        <path d="M183.543 18.844C183.444 18.844 183.345 18.9428 183.345 19.0416C183.345 19.1404 183.444 19.2392 183.543 19.2392C183.641 19.2392 183.74 19.1404 183.74 19.0416C183.74 18.9181 183.666 18.844 183.543 18.844Z" />
        <path d="M165.614 18.844C165.515 18.844 165.417 18.9428 165.417 19.0416C165.417 19.1404 165.515 19.2392 165.614 19.2392C165.713 19.2392 165.812 19.1404 165.812 19.0416C165.812 18.9181 165.713 18.844 165.614 18.844Z" />
        <path d="M147.663 18.844C147.564 18.844 147.466 18.9428 147.466 19.0416C147.466 19.1404 147.564 19.2392 147.663 19.2392C147.762 19.2392 147.861 19.1404 147.861 19.0416C147.861 18.9181 147.787 18.844 147.663 18.844Z" />
        <path d="M129.737 18.844C129.638 18.844 129.539 18.9428 129.539 19.0416C129.539 19.1404 129.638 19.2392 129.737 19.2392C129.835 19.2392 129.934 19.1404 129.934 19.0416C129.934 18.9181 129.835 18.844 129.737 18.844Z" />
        <path d="M111.784 18.844C111.685 18.844 111.586 18.9428 111.586 19.0416C111.586 19.1404 111.685 19.2392 111.784 19.2392C111.883 19.2392 111.981 19.1404 111.981 19.0416C111.981 18.9181 111.907 18.844 111.784 18.844Z" />
        <path d="M93.8589 18.844C93.7602 18.844 93.6614 18.9428 93.6614 19.0416C93.6614 19.1404 93.7602 19.2392 93.8589 19.2392C93.9577 19.2392 94.0564 19.1404 94.0564 19.0416C94.0564 18.9181 93.9577 18.844 93.8589 18.844Z" />
        <path d="M75.9062 18.844C75.8074 18.844 75.7086 18.9428 75.7086 19.0416C75.7086 19.1404 75.8074 19.2392 75.9062 19.2392C76.0049 19.2392 76.1038 19.1404 76.1038 19.0416C76.1038 18.9181 76.0296 18.844 75.9062 18.844Z" />
        <path d="M57.9794 18.844C57.8807 18.844 57.7819 18.9428 57.7819 19.0416C57.7819 19.1404 57.8807 19.2392 57.9794 19.2392C58.0782 19.2392 58.177 19.1404 58.177 19.0416C58.177 18.9181 58.0782 18.844 57.9794 18.844Z" />
        <path d="M40.0285 18.844C39.9297 18.844 39.831 18.9428 39.831 19.0416C39.831 19.1404 39.9297 19.2392 40.0285 19.2392C40.1273 19.2392 40.2261 19.1404 40.2261 19.0416C40.2261 18.9181 40.1273 18.844 40.0285 18.844Z" />
        <path d="M22.0755 18.844C21.9767 18.844 21.8779 18.9428 21.8779 19.0416C21.8779 19.1404 21.9767 19.2392 22.0755 19.2392C22.1743 19.2392 22.273 19.1404 22.273 19.0416C22.2977 18.9181 22.199 18.844 22.0755 18.844Z" />
        <path d="M4.15087 18.844C4.05213 18.844 3.95342 18.9428 3.95342 19.0416C3.95342 19.1404 4.05213 19.2392 4.15087 19.2392C4.24961 19.2392 4.34839 19.1404 4.34839 19.0416C4.34839 18.9181 4.24961 18.844 4.15087 18.844Z" />
        <path d="M945.972 0.883391C945.873 0.883391 945.774 0.982179 945.774 1.081C945.774 1.17982 945.873 1.27869 945.972 1.27869C946.07 1.27869 946.169 1.17982 946.169 1.081C946.169 0.982179 946.095 0.883391 945.972 0.883391Z" />
        <path d="M928.047 0.883391C927.948 0.883391 927.849 0.982179 927.849 1.081C927.849 1.17982 927.948 1.27869 928.047 1.27869C928.145 1.27869 928.244 1.17982 928.244 1.081C928.244 0.982179 928.145 0.883391 928.047 0.883391Z" />
        <path d="M910.094 0.883391C909.995 0.883391 909.896 0.982179 909.896 1.081C909.896 1.17982 909.995 1.27869 910.094 1.27869C910.193 1.27869 910.291 1.17982 910.291 1.081C910.291 0.982179 910.217 0.883391 910.094 0.883391Z" />
        <path d="M892.167 0.883391C892.068 0.883391 891.97 0.982179 891.97 1.081C891.97 1.17982 892.068 1.27869 892.167 1.27869C892.266 1.27869 892.365 1.17982 892.365 1.081C892.365 0.982179 892.266 0.883391 892.167 0.883391Z" />
        <path d="M874.216 0.883391C874.118 0.883391 874.019 0.982179 874.019 1.081C874.019 1.17982 874.118 1.27869 874.216 1.27869C874.315 1.27869 874.414 1.17982 874.414 1.081C874.414 0.982179 874.34 0.883391 874.216 0.883391Z" />
        <path d="M856.29 0.883391C856.191 0.883391 856.092 0.982179 856.092 1.081C856.092 1.17982 856.191 1.27869 856.29 1.27869C856.388 1.27869 856.487 1.17982 856.487 1.081C856.487 0.982179 856.388 0.883391 856.29 0.883391Z" />
        <path d="M838.338 0.883391C838.24 0.883391 838.141 0.982179 838.141 1.081C838.141 1.17982 838.24 1.27869 838.338 1.27869C838.437 1.27869 838.536 1.17982 838.536 1.081C838.536 0.982179 838.462 0.883391 838.338 0.883391Z" />
        <path d="M820.412 0.883391C820.313 0.883391 820.214 0.982179 820.214 1.081C820.214 1.17982 820.313 1.27869 820.412 1.27869C820.51 1.27869 820.609 1.17982 820.609 1.081C820.609 0.982179 820.51 0.883391 820.412 0.883391Z" />
        <path d="M802.459 0.883391C802.36 0.883391 802.261 0.982179 802.261 1.081C802.261 1.17982 802.36 1.27869 802.459 1.27869C802.558 1.27869 802.656 1.17982 802.656 1.081C802.656 0.982179 802.582 0.883391 802.459 0.883391Z" />
        <path d="M784.532 0.883391C784.434 0.883391 784.335 0.982179 784.335 1.081C784.335 1.17982 784.434 1.27869 784.532 1.27869C784.631 1.27869 784.73 1.17982 784.73 1.081C784.73 0.982179 784.631 0.883391 784.532 0.883391Z" />
        <path d="M766.581 0.883391C766.483 0.883391 766.384 0.982179 766.384 1.081C766.384 1.17982 766.483 1.27869 766.581 1.27869C766.68 1.27869 766.779 1.17982 766.779 1.081C766.779 0.982179 766.705 0.883391 766.581 0.883391Z" />
        <path d="M748.655 0.883391C748.556 0.883391 748.457 0.982179 748.457 1.081C748.457 1.17982 748.556 1.27869 748.655 1.27869C748.753 1.27869 748.852 1.17982 748.852 1.081C748.852 0.982179 748.753 0.883391 748.655 0.883391Z" />
        <path d="M730.702 0.883391C730.603 0.883391 730.504 0.982179 730.504 1.081C730.504 1.17982 730.603 1.27869 730.702 1.27869C730.801 1.27869 730.9 1.17982 730.9 1.081C730.9 0.982179 730.801 0.883391 730.702 0.883391Z" />
        <path d="M712.775 0.883391C712.676 0.883391 712.578 0.982179 712.578 1.081C712.578 1.17982 712.676 1.27869 712.775 1.27869C712.874 1.27869 712.973 1.17982 712.973 1.081C712.973 0.982179 712.874 0.883391 712.775 0.883391Z" />
        <path d="M694.824 0.883391C694.725 0.883391 694.627 0.982179 694.627 1.081C694.627 1.17982 694.725 1.27869 694.824 1.27869C694.923 1.27869 695.022 1.17982 695.022 1.081C695.022 0.982179 694.923 0.883391 694.824 0.883391Z" />
        <path d="M676.873 0.883391C676.774 0.883391 676.676 0.982179 676.676 1.081C676.676 1.17982 676.774 1.27869 676.873 1.27869C676.972 1.27869 677.071 1.17982 677.071 1.081C677.071 0.982179 676.997 0.883391 676.873 0.883391Z" />
        <path d="M658.946 0.883391C658.848 0.883391 658.749 0.982179 658.749 1.081C658.749 1.17982 658.848 1.27869 658.946 1.27869C659.045 1.27869 659.144 1.17982 659.144 1.081C659.144 0.982179 659.045 0.883391 658.946 0.883391Z" />
        <path d="M640.996 0.883391C640.897 0.883391 640.798 0.982179 640.798 1.081C640.798 1.17982 640.897 1.27869 640.996 1.27869C641.094 1.27869 641.193 1.17982 641.193 1.081C641.193 0.982179 641.119 0.883391 640.996 0.883391Z" />
        <path d="M623.069 0.883391C622.97 0.883391 622.871 0.982179 622.871 1.081C622.871 1.17982 622.97 1.27869 623.069 1.27869C623.168 1.27869 623.266 1.17982 623.266 1.081C623.266 0.982179 623.168 0.883391 623.069 0.883391Z" />
        <path d="M605.118 0.883391C605.019 0.883391 604.92 0.982179 604.92 1.081C604.92 1.17982 605.019 1.27869 605.118 1.27869C605.217 1.27869 605.315 1.17982 605.315 1.081C605.315 0.982179 605.241 0.883391 605.118 0.883391Z" />
        <path d="M587.191 0.883391C587.092 0.883391 586.994 0.982179 586.994 1.081C586.994 1.17982 587.092 1.27869 587.191 1.27869C587.29 1.27869 587.389 1.17982 587.389 1.081C587.389 0.982179 587.29 0.883391 587.191 0.883391Z" />
        <path d="M569.238 0.883391C569.14 0.883391 569.041 0.982179 569.041 1.081C569.041 1.17982 569.14 1.27869 569.238 1.27869C569.337 1.27869 569.436 1.17982 569.436 1.081C569.436 0.982179 569.362 0.883391 569.238 0.883391Z" />
        <path d="M551.312 0.883391C551.213 0.883391 551.114 0.982179 551.114 1.081C551.114 1.17982 551.213 1.27869 551.312 1.27869C551.41 1.27869 551.509 1.17982 551.509 1.081C551.509 0.982179 551.41 0.883391 551.312 0.883391Z" />
        <path d="M533.361 0.883391C533.262 0.883391 533.163 0.982179 533.163 1.081C533.163 1.17982 533.262 1.27869 533.361 1.27869C533.46 1.27869 533.558 1.17982 533.558 1.081C533.558 0.982179 533.484 0.883391 533.361 0.883391Z" />
        <path d="M515.436 0.883391C515.337 0.883391 515.238 0.982179 515.238 1.081C515.238 1.17982 515.337 1.27869 515.436 1.27869C515.535 1.27869 515.633 1.17982 515.633 1.081C515.633 0.982179 515.535 0.883391 515.436 0.883391Z" />
        <path d="M497.483 0.883391C497.384 0.883391 497.286 0.982179 497.286 1.081C497.286 1.17982 497.384 1.27869 497.483 1.27869C497.582 1.27869 497.681 1.17982 497.681 1.081C497.681 0.982179 497.607 0.883391 497.483 0.883391Z" />
        <path d="M479.555 0.883391C479.456 0.883391 479.357 0.982179 479.357 1.081C479.357 1.17982 479.456 1.27869 479.555 1.27869C479.653 1.27869 479.752 1.17982 479.752 1.081C479.752 0.982179 479.653 0.883391 479.555 0.883391Z" />
        <path d="M461.605 0.883391C461.507 0.883391 461.408 0.982179 461.408 1.081C461.408 1.17982 461.507 1.27869 461.605 1.27869C461.704 1.27869 461.803 1.17982 461.803 1.081C461.803 0.982179 461.729 0.883391 461.605 0.883391Z" />
        <path d="M443.677 0.883391C443.578 0.883391 443.479 0.982179 443.479 1.081C443.479 1.17982 443.578 1.27869 443.677 1.27869C443.776 1.27869 443.874 1.17982 443.874 1.081C443.874 0.982179 443.776 0.883391 443.677 0.883391Z" />
        <path d="M425.726 0.883391C425.627 0.883391 425.528 0.982179 425.528 1.081C425.528 1.17982 425.627 1.27869 425.726 1.27869C425.825 1.27869 425.923 1.17982 425.923 1.081C425.923 0.982179 425.849 0.883391 425.726 0.883391Z" />
        <path d="M407.799 0.883391C407.7 0.883391 407.602 0.982179 407.602 1.081C407.602 1.17982 407.7 1.27869 407.799 1.27869C407.898 1.27869 407.997 1.17982 407.997 1.081C407.997 0.982179 407.898 0.883391 407.799 0.883391Z" />
        <path d="M389.847 0.883391C389.748 0.883391 389.649 0.982179 389.649 1.081C389.649 1.17982 389.748 1.27869 389.847 1.27869C389.945 1.27869 390.044 1.17982 390.044 1.081C390.044 0.982179 389.97 0.883391 389.847 0.883391Z" />
        <path d="M371.922 0.883391C371.823 0.883391 371.724 0.982179 371.724 1.081C371.724 1.17982 371.823 1.27869 371.922 1.27869C372.02 1.27869 372.119 1.17982 372.119 1.081C372.119 0.982179 372.02 0.883391 371.922 0.883391Z" />
        <path d="M353.969 0.883391C353.87 0.883391 353.771 0.982179 353.771 1.081C353.771 1.17982 353.87 1.27869 353.969 1.27869C354.068 1.27869 354.166 1.17982 354.166 1.081C354.166 0.982179 354.092 0.883391 353.969 0.883391Z" />
        <path d="M336.044 0.883391C335.945 0.883391 335.846 0.982179 335.846 1.081C335.846 1.17982 335.945 1.27869 336.044 1.27869C336.143 1.27869 336.241 1.17982 336.241 1.081C336.241 0.982179 336.143 0.883391 336.044 0.883391Z" />
        <path d="M318.091 0.883391C317.992 0.883391 317.893 0.982179 317.893 1.081C317.893 1.17982 317.992 1.27869 318.091 1.27869C318.19 1.27869 318.289 1.17982 318.289 1.081C318.289 0.982179 318.215 0.883391 318.091 0.883391Z" />
        <path d="M300.163 0.883391C300.064 0.883391 299.965 0.982179 299.965 1.081C299.965 1.17982 300.064 1.27869 300.163 1.27869C300.261 1.27869 300.36 1.17982 300.36 1.081C300.36 0.982179 300.261 0.883391 300.163 0.883391Z" />
        <path d="M282.213 0.883391C282.115 0.883391 282.016 0.982179 282.016 1.081C282.016 1.17982 282.115 1.27869 282.213 1.27869C282.312 1.27869 282.411 1.17982 282.411 1.081C282.411 0.982179 282.312 0.883391 282.213 0.883391Z" />
        <path d="M264.285 0.883391C264.186 0.883391 264.087 0.982179 264.087 1.081C264.087 1.17982 264.186 1.27869 264.285 1.27869C264.384 1.27869 264.482 1.17982 264.482 1.081C264.482 0.982179 264.384 0.883391 264.285 0.883391Z" />
        <path d="M246.336 0.88333C246.237 0.88333 246.138 0.982118 246.138 1.08094C246.138 1.17976 246.237 1.27863 246.336 1.27863C246.434 1.27863 246.533 1.17976 246.533 1.08094C246.533 0.982118 246.434 0.88333 246.336 0.88333Z" />
        <path d="M228.383 0.88333C228.284 0.88333 228.185 0.982118 228.185 1.08094C228.185 1.17976 228.284 1.27863 228.383 1.27863C228.482 1.27863 228.581 1.17976 228.581 1.08094C228.581 0.982118 228.506 0.88333 228.383 0.88333Z" />
        <path d="M210.456 0.88333C210.357 0.88333 210.259 0.982118 210.259 1.08094C210.259 1.17976 210.357 1.27863 210.456 1.27863C210.555 1.27863 210.654 1.17976 210.654 1.08094C210.654 0.982118 210.555 0.88333 210.456 0.88333Z" />
        <path d="M192.505 0.88333C192.407 0.88333 192.308 0.982118 192.308 1.08094C192.308 1.17976 192.407 1.27863 192.505 1.27863C192.604 1.27863 192.703 1.17976 192.703 1.08094C192.703 0.982118 192.629 0.88333 192.505 0.88333Z" />
        <path d="M174.579 0.88333C174.48 0.88333 174.381 0.982118 174.381 1.08094C174.381 1.17976 174.48 1.27863 174.579 1.27863C174.677 1.27863 174.776 1.17976 174.776 1.08094C174.776 0.982118 174.677 0.88333 174.579 0.88333Z" />
        <path d="M156.628 0.88333C156.529 0.88333 156.43 0.982118 156.43 1.08094C156.43 1.17976 156.529 1.27863 156.628 1.27863C156.726 1.27863 156.825 1.17976 156.825 1.08094C156.825 0.982118 156.751 0.88333 156.628 0.88333Z" />
        <path d="M138.701 0.88333C138.602 0.88333 138.503 0.982118 138.503 1.08094C138.503 1.17976 138.602 1.27863 138.701 1.27863C138.8 1.27863 138.898 1.17976 138.898 1.08094C138.898 0.982118 138.8 0.88333 138.701 0.88333Z" />
        <path d="M120.75 0.88333C120.651 0.88333 120.552 0.982118 120.552 1.08094C120.552 1.17976 120.651 1.27863 120.75 1.27863C120.849 1.27863 120.947 1.17976 120.947 1.08094C120.947 0.982118 120.873 0.88333 120.75 0.88333Z" />
        <path d="M102.821 0.88333C102.723 0.88333 102.624 0.982118 102.624 1.08094C102.624 1.17976 102.723 1.27863 102.821 1.27863C102.92 1.27863 103.019 1.17976 103.019 1.08094C103.019 0.982118 102.92 0.88333 102.821 0.88333Z" />
        <path d="M84.8703 0.88333C84.7715 0.88333 84.6728 0.982118 84.6728 1.08094C84.6728 1.17976 84.7715 1.27863 84.8703 1.27863C84.9691 1.27863 85.0679 1.17976 85.0679 1.08094C85.0679 0.982118 84.9938 0.88333 84.8703 0.88333Z" />
        <path d="M66.9437 0.88333C66.8449 0.88333 66.7462 0.982118 66.7462 1.08094C66.7462 1.17976 66.8449 1.27863 66.9437 1.27863C67.0425 1.27863 67.1414 1.17976 67.1414 1.08094C67.1414 0.982118 67.0425 0.88333 66.9437 0.88333Z" />
        <path d="M48.9909 0.88333C48.8922 0.88333 48.7934 0.982118 48.7934 1.08094C48.7934 1.17976 48.8922 1.27863 48.9909 1.27863C49.0897 1.27863 49.1885 1.17976 49.1885 1.08094C49.1885 0.982118 49.1144 0.88333 48.9909 0.88333Z" />
        <path d="M31.0659 0.88333C30.9671 0.88333 30.8683 0.982118 30.8683 1.08094C30.8683 1.17976 30.9671 1.27863 31.0659 1.27863C31.1646 1.27863 31.2634 1.17976 31.2634 1.08094C31.2634 0.982118 31.1646 0.88333 31.0659 0.88333Z" />
        <path d="M13.1133 0.88333C13.0145 0.88333 12.9157 0.982118 12.9157 1.08094C12.9157 1.17976 13.0145 1.27863 13.1133 1.27863C13.2121 1.27863 13.3108 1.17976 13.3108 1.08094C13.3108 0.982118 13.2367 0.88333 13.1133 0.88333Z" />
      </g>
    </svg>
  );
}
