"use client";
import clsx from "clsx";

import DepthTracker from "shared-components/components/depthTracker";
import FullWidthElement from "shared-components/components/fullWidthElement";

import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import SectionHeader from "@/components/SectionHeader";
import Ad from "@/components/ad";
import BackgroundDots from "@/components/brand/RecipeReader/components/backgroundDots";
import HugeWideRecipeCard from "@/components/brand/RecipeReader/components/hugeWideRecipeCard";
import SmallRecipeCard from "@/components/brand/RecipeReader/components/smallRecipeCard";
import TopRecipeCard from "@/components/brand/RecipeReader/components/topRecipeCard";

function BrandedSection({
  className = "",
  black = false,
  name,
}: {
  className?: string;
  black?: boolean;
  name: string;
}) {
  return (
    <div
      className={clsx(
        className,
        "mb-8 pb-[3px]",
        "border-b-[1px]",
        black ? "border-black" : "border-theme-secondary",
      )}
    >
      <SectionHeader
        name={name}
        className={clsx(
          "mb-1 w-full border-b-[1px] pb-2 text-center font-serif text-2xl font-bold md:text-left print:hidden",
          black ? "border-black" : "border-theme-secondary text-theme-primary",
        )}
      />
    </div>
  );
}

export default function RecipeReaderHome({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const allRecipes = [...mainRecipes, ...supplementalRecipes];
  const topRecipe = allRecipes[0];
  const recipesOfTheWeek = allRecipes.slice(1, 5);
  const whatsNewRecipes = allRecipes.slice(5, 11);
  const trendingRecipes = allRecipes.slice(11, 15);
  const bigRecipe = allRecipes[15];
  const favoriteRecipes = allRecipes.slice(16, 24);

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_MH_HP"
          className="mx-auto mb-8 max-h-[290px] min-h-[60px] min-w-[310px] max-w-[336px] md:hidden"
        />

        <Section className="py-8">
          {topRecipe && (
            <div className="w-full md:mb-12 md:mt-6">
              {/* mobile */}
              <div className="w-full pb-2 md:hidden">
                <SmallRecipeCard recipe={topRecipe} priority />
              </div>

              {/* desktop */}
              <div className="hidden w-full md:block">
                <TopRecipeCard recipe={topRecipe} priority />
              </div>
            </div>
          )}
        </Section>

        <Section className="py-8">
          <BrandedSection name="Recipes of the Week" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            recipes={recipesOfTheWeek}
            trackingName="home-page"
            cardHoverBorder
            cardHoverBorderColor="bg-theme-secondary"
            cardClassName="border border-theme-secondary"
            cardTitleColor="text-black group-hover:text-theme-primary group-hover:no-underline"
          />
        </Section>

        <Ad
          id="Desktop_INR1_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR1_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        <Section className="relative bg-theme-primary py-8">
          <BackgroundDots className="absolute inset-x-0 bottom-0 hidden opacity-10 md:block" />
          <BrandedSection className="z-10" black name="What's New" />
          <RevisedRecipeGrid
            pageType="/"
            round
            recipes={whatsNewRecipes}
            trackingName="home-page"
            cardTitleColor="text-theme-primary-dark"
          />
        </Section>
        <Section className="py-8">
          <BrandedSection name="Trending" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            recipes={trendingRecipes}
            trackingName="home-page"
            cardHoverBorder
            cardHoverBorderColor="bg-theme-secondary"
            cardClassName="border border-theme-secondary"
            cardTitleColor="text-black group-hover:text-theme-primary group-hover:no-underline"
          />
        </Section>

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        {bigRecipe && <HugeWideRecipeCard recipe={bigRecipe} />}

        <Section className="py-8">
          <BrandedSection black name="Favorites" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            recipes={favoriteRecipes}
            trackingName="home-page"
            cardHoverBorder
            cardHoverBorderColor="bg-theme-secondary"
            cardClassName="border border-theme-secondary"
            cardTitleColor="text-black group-hover:text-theme-primary group-hover:no-underline"
          />
        </Section>

        <DepthTracker viewedItem="footer" className="hidden md:block" />
      </FullWidthElement>
    </div>
  );
}
