import DepthTracker from "shared-components/components/depthTracker";

type Props = {
  name: string;
  level?: number;
  className?: string;
  id?: string;
};

export default function SectionHeader({
  name,
  level = 2,
  children = null,
  className = "",
  id = "",
}: React.PropsWithChildren<Props>) {
  return (
    <div id={id} className={className}>
      {children ? (
        children
      ) : (
        <span role="heading" aria-level={level}>
          {name}
        </span>
      )}
      <DepthTracker
        viewedItem={name.toLowerCase().replaceAll(" ", "_")}
        triggerOnce
      />
    </div>
  );
}
