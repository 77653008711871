import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import RecipeImage from "@/components/recipe/image";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";
import { getPlaintextDescription } from "@/lib/helpers/recipeFunctions";

export default function HugeRecipeCard({
  className = "",
  recipe = {} as Recipe,
  index = 0,
  priority = false,
  onClick = () => {
    return;
  },
}) {
  return (
    <Link
      href={getRecipeLink(recipe)}
      onClick={() => {
        onClick();

        gtag.event({
          action: TRACKING_EVENTS.recipe_tile_click,
          category: TRACKING_CATEGORIES.navigation,
          label: index.toString(),
        });

        logStatsig(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });

        ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });
      }}
      className={clsx(className, "group relative mx-auto block")}
    >
      <div className="h-full w-full">
        <RecipeImage
          priority={priority}
          recipe={recipe}
          size="large"
          noRounding
          landscape
          noZoom
          noWash
        />
      </div>

      <div className="absolute bottom-12 left-24 bg-white md:w-[500px]">
        <div className="max-w-xl p-2">
          <div className="flex uppercase tracking-widest text-theme-secondary">
            {recipe.category?.name}
          </div>

          <div className="inline-block pt-1 text-5xl transition-all duration-300 group-hover:text-theme-primary">
            <SymbolizedElement title={recipe.title} />
            <span className="mt-1 block h-0.5 w-full bg-white transition-colors duration-300 group-hover:bg-theme-primary" />
          </div>

          <p className="my-2 line-clamp-3 text-sm text-theme-gray-300">
            <SymbolizedElement title={getPlaintextDescription(recipe)} />
          </p>
        </div>
      </div>
    </Link>
  );
}
