"use client";
import clsx from "clsx";

import DepthTracker from "shared-components/components/depthTracker";
import FullWidthElement from "shared-components/components/fullWidthElement";

import RevisedRecipeGrid from "@/components/RevisedRecipeGrid";
import Section from "@/components/Section";
import SectionHeader from "@/components/SectionHeader";
import SquareRecipeCard from "@/components/SquareRecipeCard";
import Ad from "@/components/ad";
import LargeRecipeCard from "@/components/brand/CommandCooking/components/largeRecipeCard";
import SmallRecipeCard from "@/components/brand/CommandCooking/components/smallRecipeCard";

function BrandedSection({
  className = "",
  name = "",
}: {
  className?: string;
  name?: string;
}) {
  return (
    <SectionHeader
      name={name}
      className={clsx(
        className,
        "w-full border-b-[1px] border-dashed border-theme-primary bg-theme-primary bg-opacity-10 py-1 text-center font-serif text-2xl font-bold text-theme-primary print:hidden",
      )}
    />
  );
}

export default function CommandCookingHome({
  className = "",
  mainRecipes = [] as Recipe[],
  supplementalRecipes = [] as Recipe[],
}) {
  const allRecipes = [...mainRecipes, ...supplementalRecipes];
  const firstRecipeCardRecipe = allRecipes[0];
  const topRecipes = allRecipes.slice(1, 5);
  const winningRecipes = allRecipes.slice(5, 9);
  const whatsNewRecipes = allRecipes.slice(9, 15);
  const secondRecipeCardRecipe = allRecipes[15];
  const favoriteRecipes = allRecipes.slice(16, 24);

  return (
    <div className={clsx(className, "main-content relative")}>
      <FullWidthElement>
        <Ad
          id="Desktop_MH_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[740px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_MH_HP"
          className="mx-auto mb-8 max-h-[290px] min-h-[60px] min-w-[310px] max-w-[336px] md:hidden"
        />

        {firstRecipeCardRecipe && (
          <Section className="mb-8 bg-gray-100 py-8">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <SquareRecipeCard
                recipe={firstRecipeCardRecipe}
                imageSize={"large"}
                showDescription
                descriptionColor="text-white"
                titleColor="text-white"
                categoryColor="text-white"
                boxStyle="bg-theme-primary"
                size="large"
                zoom={false}
                priority
              />
              <RevisedRecipeGrid
                pageType="/"
                gridClasses="grid-cols-1 sm:grid-cols-2 gap-8 !px-0 !py-0"
                cardClassName="sm:max-w-auto mx-auto max-w-xs"
                cardBoxStyle="bg-theme-primary bg-opacity-20 group-hover:bg-opacity-90"
                recipes={topRecipes}
                trackingName="home-page"
                cardCategoryColor="text-black"
                cardTitleColor="text-black group-hover:text-white group-hover:no-underline"
                priority
              />
            </div>
          </Section>
        )}

        <Ad
          id="Desktop_INR1_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR1_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        <Section className="py-8">
          <BrandedSection name="Winning Recipes" className="mb-8" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-1 sm:grid-cols-2 md:grid-cols-4"
            cardClassName="w-full mx-auto max-w-xs"
            cardBoxStyle="border-b border-l border-r border-theme-gray-200"
            recipes={winningRecipes}
            trackingName="home-page"
            cardAlignment="text-left"
            cardTitleColor="text-black"
          />
        </Section>

        <Section className="py-8">
          <BrandedSection name="What's New" className="mb-8" />
          <RevisedRecipeGrid
            pageType="/"
            round
            recipes={whatsNewRecipes}
            trackingName="home-page"
            cardAlignment="text-left"
            cardTitleColor="text-black"
          />
        </Section>

        <Section className="bg-theme-primary bg-opacity-20 py-8">
          <div className="hidden md:block">
            <LargeRecipeCard recipe={secondRecipeCardRecipe} />
          </div>

          <div className="md:hidden">
            <FullWidthElement>
              <SmallRecipeCard recipe={secondRecipeCardRecipe} />
            </FullWidthElement>
          </div>
        </Section>

        <Section className="py-8">
          <BrandedSection name="Favorites" className="mb-8" />
          <RevisedRecipeGrid
            pageType="/"
            gridClasses="grid-cols-2 md:grid-cols-4"
            cardBoxStyle="border-b border-l border-r border-theme-gray-200"
            recipes={favoriteRecipes}
            trackingName="home-page"
            cardAlignment="text-left"
            cardTitleColor="text-black"
          />
        </Section>

        <Ad
          id="Desktop_INR2_HP"
          className="mx-auto mb-8 hidden max-h-[290px] min-h-[100px] min-w-[310px] max-w-[980px] md:block"
        />
        <Ad
          id="Mobile_INR2_HP"
          className="mx-auto mb-8 max-h-[610px] min-h-[60px] min-w-[130px] max-w-[336px] md:hidden"
        />

        <DepthTracker viewedItem="footer" className="hidden md:block" />
      </FullWidthElement>
    </div>
  );
}
