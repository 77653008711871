"use client";
import { useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";

export default function FullWidthElement({
  className = "",
  background = false,
  children = null,
}: {
  className?: string;
  background?: boolean;
  children?: React.ReactNode;
}) {
  const [startPos, setStartPos] = useState(0);
  const [width, setWidth] = useState(0);
  const measureRef = useRef<HTMLDivElement>(null);

  const setWidthAndPosition = useCallback(() => {
    // Can't just use 100-vw because it doesn't account for scrollbar
    setWidth(document.body.clientWidth);

    if (measureRef && measureRef.current) {
      setStartPos(measureRef.current.getBoundingClientRect().left);
      // console.log(measureRef.current.getBoundingClientRect().left);
    }
  }, []);

  useEffect(() => {
    setWidthAndPosition();
  }, [setWidthAndPosition]);

  useEffect(() => {
    window.addEventListener("resize", setWidthAndPosition);

    return () => {
      window.removeEventListener("resize", setWidthAndPosition);
    };
  }, [setWidthAndPosition]);

  return (
    <div ref={measureRef}>
      <div
        className={clsx(className, background && "absolute h-full")}
        style={{
          transform: `translateX(${-1 * startPos}px`,
          width: `${width}px`,
        }}
      >
        {children}
      </div>
    </div>
  );
}
