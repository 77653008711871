import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import RecipeImage from "@/components/recipe/image";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";
import { getPlaintextDescription } from "@/lib/helpers/recipeFunctions";

export default function SmallRecipeCard({
  className = "",
  recipe = {} as Recipe,
  index = 0,
  priority = false,
  onClick = () => {
    return;
  },
}) {
  return (
    <Link
      href={getRecipeLink(recipe)}
      onClick={() => {
        onClick();

        gtag.event({
          action: TRACKING_EVENTS.recipe_tile_click,
          category: TRACKING_CATEGORIES.navigation,
          label: index.toString(),
        });

        logStatsig(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });

        ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });
      }}
      className={clsx(
        className,
        "group relative mx-auto flex border-spacing-y-8 flex-col",
      )}
    >
      <div className="h-full w-full">
        <RecipeImage
          priority={priority}
          recipe={recipe}
          size="large"
          noRounding
        />
      </div>

      <div className="z-10 mx-4 -mt-36 mb-8 bg-theme-primary p-4">
        <div className="flex uppercase tracking-widest text-white">
          {recipe.category?.name}
        </div>

        <div className="pt-1 font-serif text-3xl text-white decoration-2 transition-all duration-300 group-hover:underline">
          <SymbolizedElement title={recipe.title} />
        </div>

        <p className="text-md my-2 line-clamp-5 text-white">
          {recipe.richDescription && (
            <SymbolizedElement title={getPlaintextDescription(recipe)} />
          )}
        </p>
      </div>
    </Link>
  );
}
