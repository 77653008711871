import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import HighlightBar from "@/components/brand/HolyRecipe/components/highlightBar";
import RecipeImage from "@/components/recipe/image";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";
import { getPlaintextDescription } from "@/lib/helpers/recipeFunctions";

export default function HugeRecipeCard({
  className = "",
  recipe = {} as Recipe,
  index = 0,
  priority = false,
  onClick = () => {
    return;
  },
}) {
  return (
    <Link
      href={getRecipeLink(recipe)}
      onClick={() => {
        onClick();

        gtag.event({
          action: TRACKING_EVENTS.recipe_tile_click,
          category: TRACKING_CATEGORIES.navigation,
          label: index.toString(),
        });

        logStatsig(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });

        ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });
      }}
      className={clsx(
        className,
        "group mx-auto flex flex-col items-center md:flex-row",
      )}
    >
      <div className="h-full w-full md:w-1/2">
        <RecipeImage
          priority={priority}
          recipe={recipe}
          size="large"
          noRounding
          noZoom
          noWash
        />
      </div>

      <div className="z-10 mt-4 bg-white px-8 md:mt-0 md:w-1/2">
        <div className="flex h-full flex-col justify-center space-y-4 p-2 text-center md:max-w-md md:space-y-6">
          <div className="w-full text-sm uppercase tracking-widest text-theme-primary md:text-base">
            {recipe.category?.name}
          </div>

          <div className="w-full text-4xl text-theme-primary decoration-2 transition-all duration-300 group-hover:underline">
            <SymbolizedElement title={recipe.title} />
          </div>

          <p className="mt-3 line-clamp-5 text-theme-gray-300 md:line-clamp-3">
            {recipe.richDescription && getPlaintextDescription(recipe)}
          </p>
          <HighlightBar />
        </div>
      </div>
    </Link>
  );
}
