import clsx from "clsx";

import SectionHeader from "@/components/SectionHeader";

export default function BrandedSection({
  className = "",
  name,
}: {
  className?: string;
  name: string;
}) {
  return (
    <SectionHeader
      name={name}
      className={clsx(
        className,
        "mb-8 border-b-2 border-dashed border-theme-primary pb-1 text-center text-2xl font-black uppercase text-theme-primary print:hidden",
      )}
    />
  );
}
