import clsx from "clsx";

type Props = {
  fullWidth?: boolean;
  className?: string;
  innerClassName?: string;
};

export default function Section({
  children,
  className = "",
  fullWidth = false,
  innerClassName = "",
}: React.PropsWithChildren<Props>) {
  return (
    <section className={className}>
      <div className={clsx(!fullWidth && "mx-auto max-w-5xl", innerClassName)}>
        {children}
      </div>
    </section>
  );
}
