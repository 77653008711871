import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import RecipeImage from "@/components/recipe/image";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";
import { getPlaintextDescription } from "@/lib/helpers/recipeFunctions";

export default function HugeRecipeCard({
  className = "",
  recipe = {} as Recipe,
  index = 0,
  priority = false,
  onClick = () => {
    return;
  },
}) {
  return (
    <Link
      href={getRecipeLink(recipe)}
      onClick={() => {
        onClick();

        gtag.event({
          action: TRACKING_EVENTS.recipe_tile_click,
          category: TRACKING_CATEGORIES.navigation,
          label: index.toString(),
        });

        logStatsig(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });

        ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
          index: index.toString(),
        });
      }}
      className={clsx(className, "group mx-auto flex")}
    >
      <div className="h-full w-1/2">
        <RecipeImage
          priority={priority}
          recipe={recipe}
          size="large"
          noRounding
          noZoom
          noWash
        />
      </div>

      <div className="z-10 w-1/2 bg-white px-8">
        <div className="flex h-full max-w-md flex-col justify-center p-2">
          <div className="flex uppercase tracking-widest text-theme-primary">
            {recipe.category?.name}
          </div>

          <div className="inline-block text-4xl decoration-2 transition-all duration-300 group-hover:text-theme-primary group-hover:underline">
            <SymbolizedElement title={recipe.title} />
          </div>

          <p className="mt-3 line-clamp-6 text-theme-gray-300">
            <SymbolizedElement title={getPlaintextDescription(recipe)} />
          </p>
          <div className="mt-3 border-b-2 border-dotted border-theme-primary" />
        </div>
      </div>
    </Link>
  );
}
