import clsx from "clsx";

import DepthTracker from "shared-components/components/depthTracker";

import BrandedRecipeListItemWide from "@/components/brand/EasierEats/components/brandedRecipeListItemWide";

export default function BrandedRecipeList({
  className = "",
  recipes = [] as Recipe[],
  priority = false,
  depthTrackItems = [] as string[],
}) {
  return (
    <div className={clsx(className, "flex flex-col gap-y-8")}>
      {recipes.map((recipe, index) => (
        <div key={(recipe.title, index)} className="flex flex-col p-2">
          {depthTrackItems.length > index && (
            <DepthTracker
              viewedItem={depthTrackItems[index]}
              className="hidden md:block"
            />
          )}
          <BrandedRecipeListItemWide priority={priority} recipe={recipe} />
        </div>
      ))}
    </div>
  );
}
