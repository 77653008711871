import Link from "next/link";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import RecipeImage from "@/components/recipe/image";
import { getRecipeLink } from "@/lib/helpers/frontendFunctions";

type Props = {
  recipe: Recipe;
  priority?: boolean;
  onClick?: () => void;
};

export default function InsetRecipeCard({
  recipe,
  priority = false,
  onClick = () => {
    return;
  },
}: Props) {
  return (
    <Link
      href={getRecipeLink(recipe)}
      className="group relative block aspect-square md:aspect-auto lg:aspect-square"
      onClick={() => onClick}
    >
      <RecipeImage
        priority={priority}
        recipe={recipe}
        noRounding
        noWash
        noZoom
      />
      <div
        className={clsx(
          "absolute bottom-0 left-1/2 -translate-x-1/2",
          "w-11/12",
          "p-4",
          "border-b-[10px] border-theme-primary",
          "bg-white",
        )}
      >
        <div className="text-lg font-medium uppercase text-theme-primary">
          {recipe.category?.name}
        </div>
        <div className="text-4xl font-bold group-hover:text-theme-primary group-hover:underline">
          <SymbolizedElement title={recipe.title} />
        </div>
        <div className="line-clamp-3 text-lg font-normal">
          {recipe.richDescription?.json &&
            documentToReactComponents(recipe.richDescription.json as any)}
        </div>
      </div>
    </Link>
  );
}
