import Link from "next/link";

import clsx from "clsx";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";

import SymbolizedElement from "shared-components/components/SymbolizedElement";

import { getRecipeLink } from "@/lib/helpers/frontendFunctions";

import RecipeThumb from "./RecipeThumb";

type Props = {
  recipe: Recipe;
  trackingLabel?: string;
  bgColor?: string;
  categoryColor?: string;
  categoryFontFace?: string;
  titleColor?: string;
  titleFontFace?: string;
  className?: string;
  zoom?: boolean;
  alignment?: string;
  onClick?: () => void;
  hideCategory?: boolean;
  priority?: boolean;
};

export default function RoundRecipeCard({
  recipe,
  trackingLabel = "",
  bgColor = "bg-transparent",
  categoryColor = "text-theme-gray-300",
  categoryFontFace = "font-sans",
  titleColor = "text-theme-primary group-hover:text-theme-primary",
  titleFontFace = "font-serif",
  className = "",
  zoom = true,
  alignment = "text-center",
  hideCategory = false,
  priority = false,
  onClick = () => {
    return;
  },
}: Props) {
  return (
    <Link
      href={getRecipeLink(recipe)}
      className={clsx("group relative px-8 py-4 lg:px-0", bgColor, className)}
      onClick={() => {
        gtag.event({
          action: TRACKING_EVENTS.recipe_tile_click,
          category: TRACKING_CATEGORIES.navigation,
          label: trackingLabel,
        });

        logStatsig(TRACKING_EVENTS.recipe_tile_click, {
          index: trackingLabel,
        });

        ClickServer.track(TRACKING_EVENTS.recipe_tile_click, {
          index: trackingLabel,
        });
        onClick();
      }}
    >
      <div className="px-8 md:px-4">
        <RecipeThumb priority={priority} round recipe={recipe} zoom={zoom} />
      </div>

      {!hideCategory && (
        <div
          className={clsx(
            alignment,
            alignment === "text-left" && "ml-4",
            "pt-2 text-sm font-medium uppercase tracking-widest",
            categoryColor,
            categoryFontFace,
          )}
        >
          {recipe.category?.name}
        </div>
      )}
      <div
        className={clsx(
          alignment,
          alignment === "text-left" && "ml-4",
          "text-xl font-bold",
          "group-hover:underline",
          titleColor,
          titleFontFace,
        )}
      >
        <SymbolizedElement title={recipe.title} />
      </div>
    </Link>
  );
}
