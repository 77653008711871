import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/ad.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/BigRecipe/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/CommandCooking/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/CookingProfessionally/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/EasierEats/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/FussyKitchen/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/HeartlandCooking/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/HolyRecipe/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/LazyCooking/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/MamasCooking/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/RecipeReader/pages/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/recipes/components/brand/RecipeSaver/pages/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/EzoicHandler.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/LandingRedirector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/recipes/components/RevisedRecipeGrid.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shared-components/components/depthTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/shared-components/components/fullWidthElement.tsx");
