"use client";
import { PropsWithChildren, useState } from "react";

import Image from "next/image";

import clsx from "clsx";

import { getImageDimensions } from "shared-lib/helpers/utilityFunctions";

import { getSquareThumbRecipeImageURL } from "@/lib/helpers/backendFunctions";

type Props = {
  recipe: Recipe;
  urlSrc?: string;
  priority?: boolean;
  size?: "small" | "medium" | "large";
  zoom?: boolean;
  round?: boolean;
  className?: string;
};

export default function RecipeThumb({
  recipe = {} as Recipe,
  urlSrc = "",
  priority = false,
  size = "medium",
  zoom = false,
  round = false,
  className = "",
}: PropsWithChildren<Props>) {
  const [isLoading, setLoading] = useState(true);
  const [width, height] = getImageDimensions(size);
  const src = urlSrc || getSquareThumbRecipeImageURL(recipe, width, height);
  return (
    <div
      className={clsx(
        "aspect-square w-full overflow-hidden",
        round && "rounded-full",
        className,
      )}
    >
      <Image
        key={recipe.title}
        priority={priority}
        src={src}
        width={width < height ? width : height}
        height={width < height ? width : height}
        alt={`${recipe.title} image`}
        onLoad={() => setLoading(false)}
        unoptimized
        className={clsx(
          "w-full object-cover duration-300 ease-in-out",
          isLoading
            ? "scale-110 blur-2xl grayscale"
            : "scale-100 blur-0 grayscale-0",
          zoom && "group-hover:scale-110",
        )}
      />
    </div>
  );
}
